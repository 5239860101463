import React from 'react';
import {Card, CardBody, CardTitle, CardToolbar} from '../../../components/card';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {Contract, ContractStatus} from '../../../api/contract-api/Contract';
import {ValueWithLabel} from '../../base/base-card-components/value-with-label';
import {Currency} from '../../../api/DTOs/Transaction';
import {InputCheckbox} from '../../../components/Inputs/InputCheckbox';
import {CALLBACK} from '../../../consts';
import {useIntl} from '../../../hooks/use-intl';

type Props = {
  contract: Contract;
};

export const ContractCard: React.FC<Props> = ({contract}) => {
  const intl = useIntl();
  return (
    <Card hideFooter>
      <CardTitle>
        {intl.formatMessage({id: 'CONTRACT'})}:{' '}
        <Link to={AdminRoutes.getSpecifyContractRoute(contract.id)}>№{contract.composite_number}</Link>
        <BadgeCustom
          className={'ml-2 font-size-sm'}
          variant={contract.status === ContractStatus.ACTIVE ? 'success' : 'danger'}>
          {intl.formatMessage({id: 'CONTRACT_STATUS_' + contract.status})}
        </BadgeCustom>
      </CardTitle>
      <CardToolbar>
        <BadgeCustom className={'font-size-sm mr-2'} variant={'success'}>
          {intl.formatDate(contract?.started_at, {month: 'long', year: 'numeric', day: 'numeric'})}
        </BadgeCustom>
        <BadgeCustom className={'font-size-sm'} variant={'danger'}>
          {contract?.expires_at
            ? intl.formatDate(contract.expires_at, {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              })
            : intl.formatMessage({id: 'NOT_APPLICABLE'})}
        </BadgeCustom>
      </CardToolbar>
      <CardBody>
        <ValueWithLabel
          title={intl.formatMessage({id: 'CONTRACTOR'})}
          value={
            <>
              <Link to={AdminRoutes.getSpecifyContractorRoute(contract.contractor_id)}>{contract.contractor_name}</Link>
            </>
          }
        />
        <ValueWithLabel
          title={intl.formatMessage({id: 'IS_OUR_COMPANY'})}
          value={
            <>
              <Link to={AdminRoutes.getSpecifyContractorRoute(contract.our_contractor_id)}>
                {contract.our_contractor_name}
              </Link>
            </>
          }
        />
        <ValueWithLabel
          title={intl.formatMessage({id: 'CURRENCY'})}
          value={
            contract.currency === Currency.RUB
              ? `${intl.formatMessage({id: 'RUBLE'})} (₽)`
              : `${intl.formatMessage({id: 'DOLLAR'})} ($)`
          }
        />
        <InputCheckbox
          disabled
          label={intl.formatMessage({id: 'IS_SIGNED'})}
          value={contract.is_signed}
          onChange={CALLBACK.EMPTY}
        />
      </CardBody>
    </Card>
  );
};
