import React, {CSSProperties} from 'react';
import {Card, CardBody} from '../../components/card';
import cn from 'classnames';

type Props = {
  image: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  buttons?: JSX.Element;
  className?: {
    card?: string;
    body?: string;
  };
  style?: {
    card?: CSSProperties;
    body?: CSSProperties;
  };
};

export const MarketingBlock: React.FC<Props> = ({image, title, buttons, style, className, description}) => {
  return (
    <>
      <Card hideFooter hideHeader className={{body: cn('p-0'), card: cn(className?.card)}} style={style?.card}>
        <CardBody>
          <div className={cn('card-px text-center pt-20 mt-10', className?.body)} style={style?.body}>
            <h2 className='font-weight-boldest mb-10'>{title}</h2>
            <p className='text-gray-400 font-weight-bolder font-size-lg mb-10' style={{whiteSpace: 'pre-wrap'}}>
              {description}
            </p>
            {buttons && buttons}
          </div>
          <div className='text-center px-4 pb-10'>
            <img className={'mw-100 mh-45vh '} src={image} alt='nones' />
          </div>
        </CardBody>
      </Card>
    </>
  );
};
