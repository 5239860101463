import {BaseEntity, DateAsString, EntityId} from '../base/BaseEntity';
import {BootstrapColors} from '../../../styles/bootstap-colors';
import {Feed} from './Feed';

export interface Video extends BaseEntity {
  code: string;
  y_video_id: string | null;

  title: string;
  description: string;
  duration: number | string;
  user_id: EntityId;
  contractor_id: EntityId;
  contractor_code?: string;
  is_explicit: boolean;
  age_limit: number;
  status: VideoStatus;

  feeds: Array<Feed>;
  image_file_id: EntityId | null;
  original_video_file_id: EntityId | null;
  watermarked_image_file_id: EntityId | null;
  watermarked_video_file_id: EntityId | null;

  authors: string | null;
  director: string | null;
  artists_ids: Array<EntityId>;
  countries_ids: Array<EntityId>;
  genres_ids: Array<EntityId>;

  rights_expiration_at: DateAsString;
}

export enum VideoStatus {
  DRAFT = 'DRAFT',
  WAITING_PREPARING = 'WAITING_PREPARING',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  PREPARING = 'PREPARING',
  PREPARING_ERROR = 'PREPARING_ERROR',
  PUBLISHED = 'PUBLISHED',
}

export interface VideoHistoryRecord extends BaseEntity {
  action: VideoActionType;
  comment: string | null;
  video_id: EntityId;
  user_id: EntityId | null;
}

export enum VideoActionType {
  CREATE = 'CREATE',
  SEND_TO_PUBLISH = 'SEND_TO_PUBLISH',
  SYSTEM_CREATE_WATERMARKED_FILES = 'SYSTEM_CREATE_WATERMARKED_FILES',
  ADMIN_UPDATE_WATERMARKED_FILES = 'ADMIN_UPDATE_WATERMARKED_FILES',
  ADMIN_CONFIRM_PUBLISH = 'ADMIN_CONFIRM_PUBLISH',
  SYSTEM_CREATE_SUP_AGREEMENT = 'SYSTEM_CREATE_SUP_AGREEMENT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADMIN_REJECT_PUBLISH = 'ADMIN_REJECT_PUBLISH',
  SYSTEM_FAULT_WHEN_CREATE_WATERMARKED_FILES = 'SYSTEM_FAULT_WHEN_CREATE_WATERMARKED_FILES',
}

export const VIDEO_ACTION_COLOR_MAP: Record<VideoActionType, BootstrapColors> = {
  [VideoActionType.CREATE]: BootstrapColors.SUCCESS,
  [VideoActionType.SYSTEM_CREATE_WATERMARKED_FILES]: BootstrapColors.LIGHT_SUCCESS,
  [VideoActionType.SEND_TO_PUBLISH]: BootstrapColors.PRIMARY,
  [VideoActionType.ADMIN_CONFIRM_PUBLISH]: BootstrapColors.PRIMARY,
  [VideoActionType.UPDATE]: BootstrapColors.SECONDARY,
  [VideoActionType.ADMIN_UPDATE_WATERMARKED_FILES]: BootstrapColors.INFO,
  [VideoActionType.DELETE]: BootstrapColors.DANGER,
  [VideoActionType.ADMIN_REJECT_PUBLISH]: BootstrapColors.DANGER,
  [VideoActionType.SYSTEM_FAULT_WHEN_CREATE_WATERMARKED_FILES]: BootstrapColors.DANGER,
  [VideoActionType.SYSTEM_CREATE_SUP_AGREEMENT]: BootstrapColors.PRIMARY,
};
