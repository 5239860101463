import React, {useEffect, useState} from 'react';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {KnowledgeBaseApi} from '../../../api/knowledge-base-api/knowledge-base-api';
import {useIntl} from '../../../hooks/use-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ArticleStatus, IArticleDto} from '../../../api/knowledge-base-api/IArticleDto';
import {ActiveSidebarSectionInfo} from './sidebar/sidebar';
import {Routes} from '../../../../configs/routes';
import {EditArticleForm} from './edit-article-form';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {ValidationErrorsType} from '../../../utils/utils';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {FormattedMessage} from 'react-intl';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';

type Props = {
  fetchSidebarInfo: () => Promise<any>;
  setActiveSidebarSection: (data: ActiveSidebarSectionInfo) => void;
};

export const CreateArticleSection: React.FC<Props> = ({fetchSidebarInfo, setActiveSidebarSection}) => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const matchedChapterId = Number(match.params.id);
  const {resetBreadcrumb} = useCustomBreadcrumbs();
  const api = new KnowledgeBaseApi();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>(null);

  const [article, setArticle] = useState<Partial<IArticleDto>>({
    chapter_id: matchedChapterId,
    status: ArticleStatus.DRAFT,
  });

  useEffect(() => {
    setActiveSidebarSection({type: 'create', payload: {chapterId: matchedChapterId}});
  }, []);

  useEffect(() => {
    if (article) {
      // В разделе создания статьи не принципиально выводить в хлебных крошках название раздела.
      resetBreadcrumb();
    }
  }, [article.id]);

  const updateArticle = (key: keyof IArticleDto, value: any) => {
    setArticle(prevState => ({...prevState, [key]: value}));
  };

  const createArticle = async () => {
    try {
      const result = await api.createArticle({...article});
      history.push(Routes.getSpecifyArticleRoute(result.data.item.id));
      await fetchSidebarInfo();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return (
    <div className={'card card-custom'}>
      <div className={'card-header'}>
        <div className={'card-title'}>
          <FormattedMessage id={'CREATE_ARTICLE'} />
        </div>
      </div>
      <div className={'card-body'}>
        <EditArticleForm validationErrors={validationErrors} article={article} update={updateArticle} />
      </div>
      <div className={'card-footer'}>
        <Toolbar
          items={[
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'SAVE'}),
              className: 'btn btn-light-success',
              onClick: createArticle,
            },
          ]}
        />
      </div>
    </div>
  );
};
