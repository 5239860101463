import React, {CSSProperties} from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {DropdownCustomButton} from '../dropdown';
import './toolbar.scss';
import {InputSwitch} from '../Inputs/InputSwitch';
import {BootstrapColors, BootstrapSizes} from '../../../styles/bootstap-colors';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';

export type ToolBarItem =
  | DropdownToolBarItem
  | ButtonToolBarItem
  | LinkToolBarItem
  | SwitchToolBarItem
  | CustomToolBarItem
  | IconToolBarItem;

interface Props {
  visible?: boolean;
  className?: string;
  items: Array<ToolBarItem>;
}

type GeneralToolBarItem = {
  title: string | JSX.Element;
  disabled?: boolean;
  visible?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
} & {icon?: string; iconColor?: BootstrapColors; iconSize?: BootstrapSizes};

export type DropdownToolBarItemAction<T> = {
  title: string;
  value: T;
};

type DropdownToolBarItem<T extends string = any> = {
  type: 'DROPDOWN';
  title?: string | JSX.Element;
  actions: Array<DropdownToolBarItemAction<T>>;
  asToggle?: React.ForwardedRef<any>;
  asMenu?: React.ForwardedRef<any>;
  onSelect: (selectValue: T) => void;
} & Omit<GeneralToolBarItem, 'title'>;

type ButtonToolBarItem = {
  type: 'BUTTON';
  onClick: ((e: React.MouseEvent<any>) => void) | null;
} & GeneralToolBarItem;

type LinkToolBarItem = {
  type: 'LINK';
  to: string;
} & GeneralToolBarItem;

type CustomToolBarItem = {
  type: 'CUSTOM';
  render: () => JSX.Element | null;
} & Pick<GeneralToolBarItem, 'visible'>;

type SwitchToolBarItem = {
  type: 'SWITCH';
  checked: boolean;
  onChange: () => void;
  size?: 'sm' | 'lg';
  titles?: [string, string];
} & Omit<GeneralToolBarItem, 'title'>;

type IconToolBarItem = {
  type: 'ICON';
  icon: string;
  color?: BootstrapColors;
  size?: BootstrapSizes;
  spin?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
} & Omit<GeneralToolBarItem, 'title' | 'icon' | 'iconSize' | 'iconColor'>;

export const Toolbar: React.FC<Props> = ({items: toolBarItems, className, visible}) => {
  const renderButton = (button: ButtonToolBarItem) => {
    return (
      <button disabled={button.disabled} onClick={button?.onClick ?? undefined} className={button.className}>
        {button.icon && !button.loading && <Svg src={button.icon} color={button.iconColor} size={button.iconSize} />}
        {button.loading && <Svg src={ICONS.SPINNER} color={button.iconColor} size={button.iconSize} spin />}
        <span>{button.title}</span>
      </button>
    );
  };

  const renderLink = (link: LinkToolBarItem) => {
    return (
      <Link to={link.to} className={link.className}>
        {link.icon && <Svg src={link.icon} size={link.iconSize} className={'mr-2'} />}
        {link.title}
      </Link>
    );
  };

  const renderDropdown = (dropdown: DropdownToolBarItem) => {
    return (
      <DropdownCustomButton
        loading={dropdown.loading}
        label={dropdown.title ?? ''}
        className={dropdown.className}
        values={dropdown.actions}
        onSelect={dropdown.onSelect}
        asToggle={dropdown.asToggle}
        asMenu={dropdown.asMenu}
      />
    );
  };

  const renderSwitch = (switchItem: SwitchToolBarItem) => {
    return (
      <InputSwitch
        size={switchItem.size || 'sm'}
        color={BootstrapColors.PRIMARY}
        checked={switchItem.checked}
        labels={switchItem.titles}
        className={switchItem.className}
        onChange={switchItem.onChange}
      />
    );
  };

  const renderIconToolBarItem = (iconToolBarItem: IconToolBarItem) => {
    return (
      <span
        onClick={iconToolBarItem.onClick}
        className={cn('svg-icon', iconToolBarItem.className, {
          'svg-spin': iconToolBarItem.spin,
          [`svg-icon-${iconToolBarItem.color}`]: iconToolBarItem.color,
          [`svg-icon-${iconToolBarItem.size}`]: iconToolBarItem.size,
        })}>
        <SVG src={iconToolBarItem.icon} />
      </span>
    );
  };

  const renderCustomToolBarItem = (toolBarItem: CustomToolBarItem) => {
    return toolBarItem.render();
  };

  if (visible !== undefined && !visible) {
    return null;
  }

  return (
    <div className={cn('toolbar', className)}>
      {toolBarItems.map((toolBarItem, index) => {
        if (toolBarItem.visible !== undefined && !toolBarItem.visible) {
          return null;
        }

        switch (toolBarItem.type) {
          case 'BUTTON':
            return <React.Fragment key={index}>{renderButton(toolBarItem)}</React.Fragment>;
          case 'DROPDOWN':
            return <React.Fragment key={index}>{renderDropdown(toolBarItem)}</React.Fragment>;
          case 'LINK':
            return <React.Fragment key={index}>{renderLink(toolBarItem)}</React.Fragment>;
          case 'SWITCH':
            return <React.Fragment key={index}>{renderSwitch(toolBarItem)}</React.Fragment>;
          case 'ICON':
            return <React.Fragment key={index}>{renderIconToolBarItem(toolBarItem)}</React.Fragment>;
          case 'CUSTOM':
            return <React.Fragment key={index}>{renderCustomToolBarItem(toolBarItem)}</React.Fragment>;
          default:
            return null;
        }
      })}
    </div>
  );
};
