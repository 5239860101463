import React, {useMemo} from 'react';
import {Genre} from '../../../api/DTOs/Genre';
import {DefaultTable} from '../../../components/default-table';
import {useIntl} from '../../../hooks/use-intl';
import {RowActionButton} from '../../../components/table-actions-button';
import {FormattedMessage} from 'react-intl';
import {GenreAction} from './useGenreHandlerActions';
import {TableUtils} from '../../../components/table-utils';

interface GenresTableProps {
  genres: Array<Genre>;
  onAction: (genre: Genre, action: GenreAction) => Promise<unknown>;
}

export const GenresTable = ({genres, onAction}: GenresTableProps) => {
  const intl = useIntl();
  const data = genres;

  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        forceApplySize: true,
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: Genre) => br.id,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderBaseContent(value.toString());
        },
      },
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 450,
        minWidth: 300,
        accessor: (br: Genre) => br.name,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(value);
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Genre) => br,
        Cell: ({value}: {value: Genre}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.created_at)} {intl.formatTime(value.created_at)}
            </>,
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Genre) => br,
        Cell: ({value}: {value: Genre}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
            </>,
          );
        },
      },
      {
        id: 'Action',
        maxWidth: 50,
        accessor: (br: Genre) => br,
        Cell: ({value}: {value: Genre}) => {
          const actions = [
            {title: intl.formatMessage({id: 'EDIT'}), value: GenreAction.EDIT},
            {title: intl.formatMessage({id: 'DELETE'}), value: GenreAction.DELETE},
          ];
          return TableUtils.renderActionButton(
            <RowActionButton rowDto={value} actions={actions} onSelect={onAction} />,
          );
        },
      },
    ];
  }, []);

  if (genres.length === 0) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return <DefaultTable data={data} columns={columns} />;
};
