import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {CloseModalEvent, CloseModalReason, OpenModalPayload} from '../base-modal/CloseModalEvent';
import {EntityId} from '../../../api/base/BaseEntity';
import {Artist} from '../../../api/DTOs/Artist';
import {ModalCreateEditArtist} from './create-edit-artist-modal';
import {PartialNullable} from '../../../../types/types';
import {AttachmentMetadata} from '../../file-uploader';
import {useSelectApi} from '../../../hooks/use-select-api';
import {useAuth} from '../../../hooks/use-auth';
import {revokeUrlCreatedFromBlob} from '../../../utils/file-utils';
import {useLoading} from '../../../hooks/use-loading';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useArtistsApi} from '../../../hooks/apis/use-artists-api';

interface IModalCreateEditArtistProps {
  modalCreateEditArtistVisible: boolean;

  showCreateEditArtistModal(payload: OpenModalPayload<Artist>): Promise<CloseModalEvent<Artist>>;
}

// @ts-ignore
const ModalCreateEditArtistContext = createContext<IModalCreateEditArtistProps>();

let closeResolver: ((data: CloseModalEvent<Artist>) => unknown) | null = null;
export const ModalCreateEditArtistProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const api = useArtistsApi();
  const {fetchSelectValuesByKey, selectsOptions, optionsLoadings} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    modal: false,
    preview: false,
  });
  const [error, setError] = useState<string | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const {
    privileges: {can_select_contractor_for_artist},
    contractor,
  } = useAuth();
  const [previewMetadata, setPreviewMetadata] = useState<AttachmentMetadata | null>(null);
  const [artistImage, setArtistImage] = useState<File | null>(null);
  const [entity, setEntity] = useState<PartialNullable<Artist>>({contractor_id: contractor?.id});
  const [openPayload, setOpenPayload] = useState<OpenModalPayload<Artist> | null>(null);

  const showModal = async (openPayload: OpenModalPayload<Artist>) => {
    if (openPayload.type === 'EDIT') {
      setEntity(openPayload.data.entity);
    } else if (openPayload.data) {
      setEntity(openPayload.data.entity);
    }

    setVisible(true);
    setOpenPayload(openPayload);
    if (can_select_contractor_for_artist) {
      await fetchSelectValuesByKey('contractors');
    }
    return new Promise<CloseModalEvent<Artist>>(resolve => {
      closeResolver = resolve;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!entity.id) {
        return;
      }
      try {
        startLoading('modal');
        const result = await api.get(entity.id);
        setEntity(result.item);
        if (result.relations.imageFile) {
          setPreviewMetadata({
            filename: result.relations.imageFile.original_file_name,
            size: result.relations.imageFile.size,
            url: result.relations.imageFile.url,
          });
        }
      } catch (e) {
        const err = e as ApiRequestException;
        toast.error(err.errorMessage || e.message);
      } finally {
        stopLoading('preview');
        stopLoading('modal');
      }
    };
    fetchData().then();
  }, [entity.id]);

  useEffect(() => {
    if (artistImage === null) {
      setEntity(prev => ({...prev, image_file_id: null}));
    } else {
      fetchFile(artistImage).then();
    }
  }, [artistImage, previewMetadata]);

  const fetchFile = async (file: File) => {
    try {
      startLoading('preview');
      const createdFileDto = await api.uploadToFileStorage(file);
      setEntity(prevState => ({...prevState, image_file_id: createdFileDto.id}));
    } finally {
      stopLoading('preview');
    }
  };

  const handleHideModal = () => {
    setVisible(false);
    setEntity({});
    setOpenPayload(null);
    setError(undefined);
    api.setValidationErrors(null);
    if (previewMetadata) {
      revokeUrlCreatedFromBlob(previewMetadata.url);
      setPreviewMetadata(null);
    }
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    setError(null);
    let artist: Artist | null = null;
    if (openPayload?.type === 'EDIT') {
      artist = (await api.update(entity?.id as EntityId, entity)) as Artist;
    } else if (openPayload?.type === 'CREATE') {
      artist = await api.create(entity);
    }

    if (closeResolver) {
      closeResolver({reason: CloseModalReason.OK, payload: artist as Artist});
      closeResolver = null;
    }
    await handleHideModal();
  };

  const value: IModalCreateEditArtistProps = {
    modalCreateEditArtistVisible: visible,
    showCreateEditArtistModal: showModal,
  };

  const handleChangeImage = (file: File | null, metadata: AttachmentMetadata | null) => {
    setArtistImage(file);
    setPreviewMetadata(metadata);
  };

  return (
    <ModalCreateEditArtistContext.Provider value={value}>
      {children}
      <ModalCreateEditArtist
        openType={openPayload?.type ?? 'CREATE'}
        loadings={Object.assign(loadings, {contractors: optionsLoadings.contractors})}
        metadata={previewMetadata}
        contractors={selectsOptions.contractors}
        canSelectContractor={can_select_contractor_for_artist}
        onChangeArtistImage={handleChangeImage}
        visible={visible}
        errors={error}
        validationErrors={api.validationErrors}
        entity={entity}
        onChange={(key, value) => setEntity(prevState => ({...prevState, [key]: value}))}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditArtistContext.Provider>
  );
};

export const useModalCreateEditArtist = () => {
  return useContext(ModalCreateEditArtistContext);
};
