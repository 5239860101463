import React from 'react';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, prepareDate, ValidationErrorsType} from '../../../../../../utils/utils';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {DateFormat, InputDatepicker} from '../../../../../../components/Inputs/InputDatepicker';
import {useIntl} from '../../../../../../hooks/use-intl';
import {PartialNullable, UpdateEntity} from '../../../../../../../types/types';
import {excludeMask} from '../../../../../../utils/form-utils';
import {InputCheckbox} from '../../../../../../components/Inputs/InputCheckbox';
import {Separator} from '../../../../../../components/separator';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateEntity<Contractor>;
};

export const IndividualFields: React.FC<Props> = ({contractor, validationErrors, updateFields}) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('individual_name_last', validationErrors)}>
        <InputText
          required
          value={contractor?.individual_name_last}
          label={intl.formatMessage({id: 'LAST_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('individual_name_last', validationErrors)}
          onChange={e => updateFields({individual_name_last: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LAST_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('individual_name_first', validationErrors)}>
            <InputText
              required
              value={contractor?.individual_name_first}
              label={intl.formatMessage({id: 'FIRST_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_name_first', validationErrors)}
              onChange={e => updateFields({individual_name_first: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('individual_name_middle', validationErrors)}>
            <InputText
              value={contractor?.individual_name_middle}
              label={intl.formatMessage({id: 'MIDDLE_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_name_middle', validationErrors)}
              onChange={e => updateFields({individual_name_middle: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>

      <ValidateErrorWrapper message={getValidationErrorMessage('type', validationErrors)}>
        <InputCheckbox
          label={intl.formatMessage({id: 'SELF_EMPLOYED'})}
          value={contractor?.individual_is_self_employed ?? false}
          onChange={() => updateFields({individual_is_self_employed: !contractor?.individual_is_self_employed})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('individual_date_of_birth', validationErrors)}>
        <InputDatepicker
          hasError={!!getValidationErrorMessage<Contractor>('individual_date_of_birth', validationErrors)}
          label={intl.formatMessage({id: 'DATE_OF_BIRTH'})}
          value={contractor?.individual_date_of_birth}
          placeholder={intl.formatMessage({id: DateFormat.DD_MM_YYYY})}
          onChange={date => updateFields({individual_date_of_birth: prepareDate(date)})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('individual_registration_address', validationErrors)}>
        <InputText
          value={contractor?.individual_registration_address}
          label={intl.formatMessage({id: 'PASSPORT_REGISTRATION_ADDRESS'})}
          hasError={!!getValidationErrorMessage<Contractor>('individual_registration_address', validationErrors)}
          onChange={e => updateFields({individual_registration_address: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'PASSPORT_REGISTRATION_ADDRESS'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('individual_passport_number', validationErrors)}>
        <InputText
          value={contractor?.individual_passport_number}
          label={intl.formatMessage({id: 'PASSPORT_NUMBER'})}
          hasError={!!getValidationErrorMessage<Contractor>('individual_passport_number', validationErrors)}
          onChange={e => updateFields({individual_passport_number: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'PASSPORT_NUMBER'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('individual_passport_issued_by', validationErrors)}>
        <InputText
          value={contractor?.individual_passport_issued_by}
          label={intl.formatMessage({id: 'PASSPORT_ISSUED_BY'})}
          hasError={!!getValidationErrorMessage<Contractor>('individual_passport_issued_by', validationErrors)}
          onChange={e => updateFields({individual_passport_issued_by: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'PASSPORT_ISSUED_BY'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-8'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('individual_passport_issued_date', validationErrors)}>
            <InputDatepicker
              value={contractor?.individual_passport_issued_date}
              label={intl.formatMessage({id: 'PASSPORT_ISSUED_DATE'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_passport_issued_date', validationErrors)}
              placeholder={intl.formatMessage({id: DateFormat.DD_MM_YYYY})}
              onChange={date => updateFields({individual_passport_issued_date: prepareDate(date)})}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-4'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('individual_passport_division_code', validationErrors)}>
            <InputText
              mask={'999-999'}
              value={contractor?.individual_passport_division_code}
              label={intl.formatMessage({id: 'PASSPORT_DIVISION_CODE'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_passport_division_code', validationErrors)}
              onChange={e => updateFields({individual_passport_division_code: excludeMask(e.currentTarget.value)})}
              placeholder={intl.formatMessage({id: 'PASSPORT_DIVISION_CODE'})}
              classNames={`form-control min-w-auto`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>
      <Separator className={'my-5'}/>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('individual_inn', validationErrors)}>
            <InputText
              mask={'999999999999'}
              value={contractor?.individual_inn}
              label={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_inn', validationErrors)}
              onChange={e => updateFields({individual_inn: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>

        <div className={'col-6'}>
          <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('individual_snils', validationErrors)}>
            <InputText
              mask={'999-999-999 99'}
              value={contractor?.individual_snils}
              label={intl.formatMessage({id: 'SNILS'})}
              hasError={!!getValidationErrorMessage<Contractor>('individual_snils', validationErrors)}
              onChange={e => updateFields({individual_snils: excludeMask(e.currentTarget.value)})}
              placeholder={intl.formatMessage({id: 'SNILS'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>
    </>
  );
};
