import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {useArtistsApi} from '../../../hooks/apis/use-artists-api';
import {ModalSetChartmetric, SetChartMetricState} from './modal-set-chartmetric';
import {EntityId} from '../../../api/base/BaseEntity';
import {isValidationException} from '../../../api/api-utils';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {useThrottledCallback} from 'use-debounce';
import {SelectOptions} from '../../Inputs/InputSelect';

interface IModalCreateEditProps {
  modalCreateEditVisible: boolean;

  showSetChartMetricModal(
    artistId: EntityId,
    chartmetricId: null | number,
    artistTitle: null | string,
  ): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateEditContext = createContext<IModalCreateEditProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalSetChartMetricProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const api = useArtistsApi();
  const [error, setError] = useState<string | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const [entity, , updateEntity] = useAdvancedState<SetChartMetricState>({});
  const [defaultOptions, setDefaultOptions] = useState<SelectOptions>([]);

  const fetchArtist = useThrottledCallback<(title: string) => Promise<SelectOptions>>(async (title: string) => {
    const res = await api.getSearchArtist(title);
    return res.items.map(artist => ({
      label: artist.name,
      value: artist.id,
      icon: artist.image_url,
    })) as SelectOptions;
  }, 500);

  const showModal = async (artistId: EntityId, chartmetricId: null | number, artistTitle: string | null) => {
    setVisible(true);
    updateEntity({
      artistId,
      id: chartmetricId,
    });

    if (chartmetricId && artistTitle) {
      setDefaultOptions([{value: chartmetricId, label: artistTitle}]);
    }
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(undefined);
    setDefaultOptions([]);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    setError(null);
    try {
      await api.setChartMetricId(entity.artistId as EntityId, entity.id as string);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      if (!isValidationException(e)) {
        setError(e.message);
      }
    }
  };

  const value: IModalCreateEditProps = {
    modalCreateEditVisible: visible,
    showSetChartMetricModal: showModal,
  };

  return (
    <ModalCreateEditContext.Provider value={value}>
      {children}
      <ModalSetChartmetric
        fetchOptions={fetchArtist}
        visible={visible}
        errors={error}
        defaultOptions={defaultOptions}
        entity={entity}
        onChange={updateEntity}
        validationErrors={api.validationErrors}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditContext.Provider>
  );
};

export const useModalSetChartMetric = () => {
  return useContext(ModalCreateEditContext);
};
