import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {SupAgreement} from '../../api/DTOs/SupAgreement';
import {Contract} from '../../api/contract-api/Contract';
import {EntityId} from '../../api/base/BaseEntity';
import config from '../../../configs/config';
import {isValidationException} from '../../utils/api-utils';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {Dispatch, useRef} from 'react';
import {ValidationErrorsType} from '../../utils/utils';
import {ContractualDocument} from './use-contractual-documents-api';

export type SupAgreementRelations = {
  contract: Contract;
  contractualDocumentFiles: ContractualDocument[];
};

export type SupAgreementPrivileges = {
  can_edit: boolean;
  can_delete: boolean;
};

export function useSubAgreementsApi() {
  const {current: params} = useRef<EntityApiHookParams>({
    prefixUrl: 'sup-agreements',
    isAdmin: false,
  });
  const entityApi = useEntityApi<SupAgreement, SupAgreementRelations, SupAgreementPrivileges>(params);
  return {
    ...entityApi,
    ...useAdditionalRoutes(params, entityApi.setValidationErrors),
  };
}

function useAdditionalRoutes(params: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) {
  const api = useBaseEndpointApi();
  const generateContractTemplate = async (id: EntityId) => {
    try {
      return await api.getFile(`${config.BASE_API_URL}/${params.prefixUrl}/${id}/generate-template`);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };
  return {
    generateContractTemplate,
  };
}
