import {Artist} from '../../../api/DTOs/Artist';
import {InputText} from '../../../components/Inputs/InputText';
import {InputRange} from '../../../components/Inputs/InputRange';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useHistory} from 'react-router-dom';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {getValidationErrorMessage, prepareDate, tryGetDateOrNull} from '../../../utils/utils';
import {InputMultiSelect} from '../../../components/Inputs/InputMultiSelect';
import {
  useModalCreateEditArtist
} from '../../../components/modals/create-edit-artist-modal/create-edit-artist-modal-context';
import {useLoading} from '../../../hooks/use-loading';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {ICONS} from '../../../images/images';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {BaseCardPageView} from '../../base/base-card-page-view';
import {ExternalRoutes, Routes} from '../../../../configs/routes';
import {InputSwitch} from '../../../components/Inputs/InputSwitch';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../../api/axios-instance';
import {Video, VideoStatus} from '../../../api/DTOs/Video';
import React, {useCallback, useEffect, useState} from 'react';
import {InputTextArea} from '../../../components/Inputs/InputTextArea';
import {InputCreatableMultiSelect} from '../../../components/Inputs/InputCreatableMultiSelect';
import {useIntl} from '../../../hooks/use-intl';
import {FileDto} from '../../../api/file-api/IFileDto';
import {EntityId} from '../../../api/base/BaseEntity';
import {useSelectApi} from '../../../hooks/use-select-api';
import {useVideosApi} from '../../../hooks/apis/use-videos-api';
import {AttachmentMetadata} from '../../../components/file-uploader';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {BootstrapColors, BootstrapSizes} from '../../../../styles/bootstap-colors';
import {useAuth} from '../../../hooks/use-auth';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {PartialNullable} from '../../../../types/types';
import {FeedsBlock} from '../feeds/feeds-block';
import {LabelWithDescription} from '../../../components/Inputs/LabelWithDescription';
import {AttachmentType} from './attachment-block';
import {CreatedFile} from '../../../api/file-api/files-api';
import {UploadImageBlock} from './upload-image-block';
import {UploadVideoBlock} from './upload-video-block';
import {MultipartUploaderErrorsPayloads} from '../../../services/MultipartUploader';
import {useDebouncedCallback} from 'use-debounce';
import {Feed} from '../../../api/DTOs/Feed';
import {DateFormat, InputDatepicker} from '../../../components/Inputs/InputDatepicker';

type Props = {
  openPagePayload: CreateUploadType | EditUploadType;
};

type CreateUploadType = {
  uploadType: 'CREATE';
};

type EditUploadType = {
  uploadType: 'EDIT';
  entity: Video;
  relations: {
    originalVideoFile: FileDto;
    imageFile: FileDto | null;
  };
};

export const UploadVideoPage: React.FC<Props> = ({openPagePayload}) => {
  const intl = useIntl();
  const history = useHistory();
  const api = useVideosApi();
  const {
    privileges: {can_select_contractor_for_video},
    contractor,
  } = useAuth();
  const {selectsOptions, optionsLoadings, fetchSelectValuesByKey} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    video: false,
    preview: false,
    save: false,
    generateCode: false,
    cancel: false,
  });

  const [entity, , setEntity] = useAdvancedState<PartialNullable<Video>>(
    openPagePayload.uploadType === 'EDIT'
      ? openPagePayload.entity
      : {
        age_limit: 0,
        is_explicit: false,
        status: VideoStatus.DRAFT,
        contractor_id: contractor?.id,
        rights_expiration_at: null,
      },
  );

  const [attachedVideoMetadata] = useState<AttachmentMetadata | null>(
    openPagePayload.uploadType === 'EDIT'
      ? {
        filename: openPagePayload.relations.originalVideoFile.original_file_name,
        size: openPagePayload.relations.originalVideoFile.size,
        url: openPagePayload.relations.originalVideoFile.url,
      }
      : null,
  );

  const [attachedPreviewMetadata] = useState<AttachmentMetadata | null>(
    openPagePayload.uploadType === 'EDIT' && openPagePayload.relations.imageFile !== null
      ? {
        filename: openPagePayload.relations.imageFile.original_file_name,
        size: openPagePayload.relations.imageFile.size,
        url: openPagePayload.relations.imageFile.url,
      }
      : null,
  );

  const [needFetchAutoCode, setNeedFetchAutoCode] = useState(false);
  const [fieldsForAutoCode, setFieldsForAutoCode] = useState<Array<keyof Video>>([]);
  const {showCreateEditArtistModal} = useModalCreateEditArtist();

  const handleEntityChange = (props: { [key in keyof PartialNullable<Video>]: PartialNullable<Video>[key] }) => {
    if (Object.keys(props).some(k => fieldsForAutoCode.includes(k as keyof Video))) {
      setNeedFetchAutoCode(true);
    }
    setEntity(props);
    api.setValidationErrors(prev => ({
      ...prev,
      ...Object.keys(props).reduce((acc, k) => {
        return {...acc, [k]: null};
      }, {}),
    }));
  };

  const debouncedFetchAutoCode = useDebouncedCallback(() => {
    fetchAutoCode().then();
  }, 1000);

  useEffect(() => {
    if (needFetchAutoCode) {
      setNeedFetchAutoCode(false);
      debouncedFetchAutoCode();
    }
  }, [entity]);

  useEffect(() => {
    const fetchAll = async () => {
      // noinspection ES6MissingAwait
      const promises = [
        fetchSelectValuesByKey('genres'),
        fetchSelectValuesByKey('artists'),
        fetchSelectValuesByKey('countries'),
      ];
      if (can_select_contractor_for_video) {
        promises.push(fetchSelectValuesByKey('contractors'));
      }
      await Promise.all(promises);
    };
    fetchAll().then();
  }, []);

  useEffect(() => {
    const fetchCodeFields = async () => {
      try {
        const result = await api.getAutoCodeFields();
        setFieldsForAutoCode(result.item);
      } catch (e) {
        if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
          toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        }
      }
    };
    fetchCodeFields().then();
  }, []);

  const fetchAutoCode = async () => {
    if (loadings.generateCode || openPagePayload.uploadType === 'EDIT') {
      return;
    }

    try {
      startLoading('generateCode');
      const fields = fieldsForAutoCode.reduce((acc, f) => ({...acc, [f]: entity[f]}), {});
      const res = await api.generateAutoCode(fields);
      handleEntityChange({code: res.item.code});
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('generateCode');
    }
  };

  const handleUploadEntity = async () => {
    try {
      startLoading('save');
      const updatedEntity = {
        ...entity,
        rights_expiration_at: prepareDate(tryGetDateOrNull(entity.rights_expiration_at)),
      };
      const result =
        openPagePayload.uploadType === 'CREATE'
          ? await api.create(updatedEntity)
          : await api.update(entity.id as EntityId, updatedEntity);
      history.push(Routes.getSpecifyVideosRoute((result as Video).id));
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('save');
    }
  };

  const handleCreateSelectArtist = async (inputValue: string) => {
    const modalResult = await showCreateEditArtistModal({type: 'CREATE', data: {entity: {name: inputValue}}});
    if (modalResult.reason === CloseModalReason.OK) {
      const createdArtist = modalResult.payload as Artist;
      handleEntityChange({
        artists_ids: Array.isArray(entity.artists_ids) ? [...entity.artists_ids, createdArtist.id] : [createdArtist.id],
      });
      await fetchSelectValuesByKey('artists');
    }
  };

  const handleSwitchFeed = (feedId: Feed, status: boolean) => {
    if (status) {
      setEntity({feeds: [...(entity.feeds || []), feedId]});
    } else {
      setEntity({feeds: entity.feeds?.filter(f => f !== feedId)});
    }
  };

  const handleStartUpload = useCallback(
    async (file: File, type: AttachmentType, progressCb?: (progress: number) => void) => {
      if (type === AttachmentType.IMAGE) {
        return await api.uploadToFileStorage(file);
      } else if (type === AttachmentType.VIDEO) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return await api.multipartUploadToFileStorage(file, progressCb ?? (() => {
        }));
      }

      throw new Error();
    },
    [],
  );

  const handleTryAgainUpload = useCallback(
    async (file: File, error: MultipartUploaderErrorsPayloads, progressCb: (progress: number) => void) => {
      return await api.tryAgainMultipartUploadToFileStorage(file, error, progressCb);
    },
    [],
  );

  const handleCreateFile = useCallback((file: CreatedFile, type: AttachmentType) => {
    if (type === AttachmentType.IMAGE) {
      setEntity({image_file_id: file.id});
    } else if (type === AttachmentType.VIDEO) {
      setEntity({original_video_file_id: file.id});
    }
  }, []);

  const handleCancelUpload = useCallback((promiseResolver: (value: any | PromiseLike<any>) => void) => {
    api.cancelMultipartUpload(promiseResolver);
  }, []);

  const renderVideoFieldsBlock = () => {
    return (
      <>
        <div className='form-group mb-5'>
          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('title', api.validationErrors)}>
            <InputText
              required
              label={intl.formatMessage({id: 'TITLE'})}
              descriptionLabel={intl.formatMessage({id: 'VIDEO_TITLE_NOTE'})}
              hasError={!!getValidationErrorMessage<Video>('title', api.validationErrors)}
              value={entity.title}
              classNames={'form-control py-3 px-6 mr-2'}
              onChange={e => handleEntityChange({title: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('description', api.validationErrors)}>
            <InputTextArea
              required
              height={250}
              label={intl.formatMessage({id: 'DESCRIPTION'})}
              descriptionLabel={intl.formatMessage({id: 'VIDEO_DESCRIPTION_NOTE'})}
              hasError={!!getValidationErrorMessage<Video>('description', api.validationErrors)}
              value={entity.description}
              className={'form-control py-3 px-6 mr-2'}
              onChange={e => handleEntityChange({description: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('artists_ids', api.validationErrors)}>
            <InputCreatableMultiSelect
              required
              isLoading={optionsLoadings.artists ?? false}
              label={intl.formatMessage({id: 'ARTISTS'})}
              placeholder={''}
              options={selectsOptions['artists'] ?? []}
              hasError={!!getValidationErrorMessage('artists_ids', api.validationErrors)}
              defaultValues={entity.artists_ids ?? null}
              onChange={items => handleEntityChange({artists_ids: items?.map(i => i.value)})}
              onCreate={handleCreateSelectArtist}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('genres_ids', api.validationErrors)}>
            <InputMultiSelect
              required
              placeholder={''}
              isLoading={optionsLoadings.genres}
              label={intl.formatMessage({id: 'GENRES'})}
              defaultValues={entity.genres_ids ?? null}
              options={selectsOptions['genres'] ?? []}
              hasError={!!getValidationErrorMessage('genres_ids', api.validationErrors)}
              onChange={items => handleEntityChange({genres_ids: items?.map(i => i.value)})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('countries_ids', api.validationErrors)}>
            <InputMultiSelect
              required
              placeholder={''}
              label={intl.formatMessage({id: 'COUNTRIES'})}
              descriptionLabel={intl.formatMessage({id: 'VIDEO_COUNTRIES_NOTE'})}
              isLoading={optionsLoadings.countries}
              defaultValues={entity.countries_ids ?? null}
              options={selectsOptions['countries'] ?? []}
              hasError={!!getValidationErrorMessage('genres_ids', api.validationErrors)}
              onChange={items => handleEntityChange({countries_ids: items?.map(i => i.value)})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('authors', api.validationErrors)}>
            <InputText
              label={intl.formatMessage({id: 'AUTHORS'})}
              value={entity.authors}
              hasError={!!getValidationErrorMessage('authors', api.validationErrors)}
              classNames={'form-control py-3 px-6 mr-2'}
              onChange={e => handleEntityChange({authors: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('director', api.validationErrors)}>
            <InputText
              label={intl.formatMessage({id: 'DIRECTOR'})}
              value={entity.director}
              hasError={!!getValidationErrorMessage('director', api.validationErrors)}
              classNames={'form-control py-3 px-6 mr-2'}
              onChange={e => handleEntityChange({director: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>

          <AlertCustom
            visible
            iconSize={BootstrapSizes.X2}
            text={intl.formatMessage(
              {id: 'VIDEO_AGE_LIMIT_NOTICE'},
              {
                redirect: str => (
                  <a
                    target={'_blank'}
                    href={ExternalRoutes.getReadMoreAboutAgeLimitRoute()}
                    className={'text-underline'}
                    rel='noreferrer'>
                    {str}
                  </a>
                ),
              },
            )}
            type={BootstrapColors.LIGHT}
          />

          <ValidateErrorWrapper
            className={'mb-0'}
            message={getValidationErrorMessage<Video>('age_limit', api.validationErrors)}>
            <InputRange
              label={intl.formatMessage({id: 'AGE_LIMIT'}) + `: ${entity.age_limit || 0}+`}
              min={0}
              max={18}
              step={1}
              value={entity.age_limit || 0}
              onChange={value => handleEntityChange({age_limit: value})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('is_explicit', api.validationErrors)}>
            <InputSwitch
              size={'sm'}
              color={BootstrapColors.PRIMARY}
              type={'outline'}
              labels={['', intl.formatMessage({id: 'EXPLICIT'})]}
              checked={entity.is_explicit ?? false}
              onChange={() => handleEntityChange({is_explicit: !entity.is_explicit})}
            />
          </ValidateErrorWrapper>

          <LabelWithDescription required label={intl.formatMessage({id: 'FEEDS'})}/>
          <AlertCustom
            visible
            className={'mb-0'}
            iconSize={BootstrapSizes.X2}
            text={intl.formatMessage({id: 'FEEDS_NOTICE'})}
            type={BootstrapColors.LIGHT}
          />
          <ValidateErrorWrapper message={getValidationErrorMessage<Video>('feeds', api.validationErrors)}>
            <div className={'py-3 rounded'}>
              <FeedsBlock selectedFeeds={entity.feeds ?? []} onSelectFeed={handleSwitchFeed}/>
            </div>
          </ValidateErrorWrapper>
        </div>
      </>
    );
  };

  return (
    <BaseCardPageView pageTitle={intl.formatMessage({id: 'UPLOAD_VIDEO'})}>
      <div className={'row'} style={{maxWidth: '1120px'}}>
        <div className={'col-12 col-xl-7'}>{renderVideoFieldsBlock()}</div>
        <div className={'col-12 col-xl-4'}>
          <div className={'d-flex flex-column'}>
            <ValidateErrorWrapper message={getValidationErrorMessage<Video>('code', api.validationErrors)}>
              <InputText
                disabled
                label={intl.formatMessage({id: 'CODE'})}
                descriptionLabel={intl.formatMessage({id: 'VIDEO_CODE_NOTE'})}
                hasError={!!getValidationErrorMessage<Video>('code', api.validationErrors)}
                value={entity.code}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => handleEntityChange({code: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>
            <ValidateErrorWrapper message={getValidationErrorMessage<Video>('contractor_code', api.validationErrors)}>
              <InputText
                label={intl.formatMessage({id: 'CONTRACTOR_EXTERNAL_ID'})}
                descriptionLabel={intl.formatMessage({id: 'CONTRACTOR_EXTERNAL_ID_NOTE'})}
                hasError={!!getValidationErrorMessage<Video>('contractor_code', api.validationErrors)}
                value={entity.contractor_code}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => handleEntityChange({contractor_code: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>
            {can_select_contractor_for_video && openPagePayload.uploadType !== 'EDIT' && (
              <ValidateErrorWrapper message={getValidationErrorMessage<Video>('contractor_id', api.validationErrors)}>
                <InputSelect
                  required
                  isLoading={optionsLoadings.contractors}
                  placeholder={''}
                  label={intl.formatMessage({id: 'CONTRACTOR'})}
                  value={entity.contractor_id}
                  options={selectsOptions['contractors'] ?? []}
                  onChange={contractorId => handleEntityChange({contractor_id: contractorId})}
                />
              </ValidateErrorWrapper>
            )}
            <ValidateErrorWrapper
              message={getValidationErrorMessage<Video>('rights_expiration_at', api.validationErrors)}>
              <InputDatepicker
                label={intl.formatMessage({id: 'RIGHTS_EXPIRATION'})}
                hasError={!!getValidationErrorMessage<Video>('rights_expiration_at', api.validationErrors)}
                value={entity.rights_expiration_at}
                placeholder={intl.formatMessage({id: DateFormat.DD_MM_YYYY})}
                onChange={date => handleEntityChange({rights_expiration_at: prepareDate(date)})}
              />
            </ValidateErrorWrapper>
            <LabelWithDescription required label={intl.formatMessage({id: 'PREVIEW_FILE'})}/>
            <UploadImageBlock
              validationError={getValidationErrorMessage('image_file_id', api.validationErrors)}
              metadata={attachedPreviewMetadata}
              onStartUpload={image => handleStartUpload(image, AttachmentType.IMAGE)}
              onCreateFile={file => handleCreateFile(file, AttachmentType.IMAGE)}
              onDelete={() => setEntity({image_file_id: null})}
            />
            <hr/>
            <LabelWithDescription required label={intl.formatMessage({id: 'VIDEO_FILE'})}/>
            <UploadVideoBlock
              validationError={getValidationErrorMessage('original_video_file_id', api.validationErrors)}
              metadata={attachedVideoMetadata}
              onCancelUpload={handleCancelUpload}
              onStartUpload={(video, tickCb) => handleStartUpload(video, AttachmentType.VIDEO, tickCb)}
              onCreateFile={file => handleCreateFile(file, AttachmentType.VIDEO)}
              onTryAgainUpload={handleTryAgainUpload}
              onDelete={() => setEntity({original_video_file_id: null})}
            />
          </div>
        </div>
        <Toolbar
          className={'d-flex justify-content-center mt-10 w-100'}
          items={[
            {
              type: 'BUTTON',
              loading: loadings.save,
              disabled: loadings.save,
              title: intl.formatMessage({id: 'SAVE'}),
              icon: ICONS.DOWNLOAD,
              className: 'btn btn-light-primary text-uppercase',
              onClick: handleUploadEntity,
            },
          ]}
        />
      </div>
    </BaseCardPageView>
  );
};
