import React, {useEffect, useState} from 'react';
import {UploadVideoPage} from './upload-and-create/upload-video-page';
import {useParams} from 'react-router-dom';
import {Video} from '../../api/DTOs/Video';
import {Preloader} from '../../components/preloader';
import {useIntl} from '../../hooks/use-intl';
import {ErrorStub} from '../../components/error-stub';
import {UnboxPromise} from '../../../types/types';
import {useVideosApi} from '../../hooks/apis/use-videos-api';
import {useCustomBreadcrumbs} from '../../components/breadcrumbs/breadcrumbs-context';

export const EditVideoPage: React.FC = () => {
  const intl = useIntl();
  const {id} = useParams<{id: string}>();
  const api = useVideosApi();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [fetchedVideo, setFetchedVideo] = useState<Video | null>(null);
  const [relations, setRelations] = useState<Pick<UnboxPromise<ReturnType<typeof api.get>>, 'relations'> | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const result = await api.get(id);
        setFetchedVideo(result.item);
        setRelations({relations: result.relations});
        setBreadcrumb(result.item.title);
        setError(null);
      } catch (e) {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      } finally {
        setLoading(false);
      }
    };

    fetch().then();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <UploadVideoPage
      openPagePayload={{uploadType: 'EDIT', entity: fetchedVideo as Video, relations: relations?.relations as any}}
    />
  );
};
