import {Artist} from '../../api/DTOs/Artist';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../api/axios-instance';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {useBaseListPage} from '../base/base-list-page-context';
import {useIntl} from '../../hooks/use-intl';
import {EntityId} from '../../api/base/BaseEntity';
import {
  useModalCreateEditArtist
} from '../../components/modals/create-edit-artist-modal/create-edit-artist-modal-context';
import {PartialNullable} from '../../../types/types';
import {useArtistsApi} from '../../hooks/apis/use-artists-api';
import {useModalSetChartMetric} from '../../components/modals/set-chartmetric-id-modal/modal-set-chartmetric-context';

export enum ArtistAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SET_CHARTMETRIC_ID = 'SET_CHARTMETRIC_ID',
}

export type HandleArtistActionPayload =
  HandleArtistCreateAction
  | HandleArtistEditAction
  | HandleArtistDeleteAction
  | HandleArtistSetChartMetricIdAction;
export type HandleArtistCreateAction = {
  type: ArtistAction.CREATE;
};

export type HandleArtistSetChartMetricIdAction = {
  type: ArtistAction.SET_CHARTMETRIC_ID;
  entity: PartialNullable<Artist>;
  chartMetricTitle?: string | null;
};

export type HandleArtistEditAction = {
  type: ArtistAction.EDIT;
  entity: PartialNullable<Artist>;
};

export type HandleArtistDeleteAction = {
  type: ArtistAction.DELETE;
  entityId: EntityId;
};

export function useArtistActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useArtistsApi();
  const {showCreateEditArtistModal} = useModalCreateEditArtist();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showSetChartMetricModal} = useModalSetChartMetric();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleArtistActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case ArtistAction.CREATE: {
          const modalResult = await showCreateEditArtistModal({type: 'CREATE'});
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case ArtistAction.EDIT: {
          const modalResult = await showCreateEditArtistModal({
            type: 'EDIT',
            data: {entity: payload.entity},
          });
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case ArtistAction.SET_CHARTMETRIC_ID: {
          const modalResult = await showSetChartMetricModal(
            payload.entity.id as EntityId,
            payload.entity.chartmetric_id ?? null,
            payload.chartMetricTitle ?? null
          );
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case ArtistAction.DELETE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_ARTIST'}))) {
            try {
              await api.remove(payload.entityId);
              fetch?.(appliedQueryParams);
            } catch (e) {
              if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
                toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
              }
            }
          }
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };
  return {
    handleArtistActions: handle,
  };
}
