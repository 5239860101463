import React, {useCallback, useEffect, useState} from 'react';
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import SVG from 'react-inlinesvg';
import {useModalPaymentRequest} from '../../components/modals/create-payment-request-modal/payment-request-modal-context';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {CustomFormatter} from '../../../localization/custom-formatter';
import {useLoading} from '../../hooks/use-loading';
import {ICONS} from '../../images/images';
import {FinancialAccount, FinancialAccountType} from '../../api/DTOs/FinancialAccount';
import {useAccountsApi} from '../../hooks/apis/use-accounts-api';
import {Operations} from './operations';
import cn from 'classnames';
import {Spinner} from 'react-bootstrap';
import {useAuth} from '../../hooks/use-auth';
import {useLocation} from 'react-router';
import {Card, CardBody, CardTitle} from '../../components/card';
import {BadgeCustom} from '../../components/badge-custom';
import {PaymentRequest, PaymentRequestDestination, PaymentRequestStatus} from '../../api/DTOs/PaymentRequest';
import {BaseListPage} from '../base/base-list-page';
import {usePaymentsRequestsApi} from '../../hooks/apis/use-payments-requests-api';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../api/Paginator';
import {Currency} from '../../api/DTOs/Transaction';

export const InnerRevenuesPage: React.FC = () => {
  const intl = useIntl();
  const {search} = useLocation();
  const {
    privileges: {can_create_payment_requests},
  } = useAuth();
  const api = useAccountsApi();
  const paymentsRequestsApi = usePaymentsRequestsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    account: true,
    paymentRequests: true,
  });

  const [selectedAccount, setSelectedAccount] = useState<FinancialAccount | null>(null);
  const [paymentsRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
  const [paymentRequestsPaginationInfo, setPaymentRequestsPaginationInfo] = useState<IPaginationInfo | null>(null);

  const {showPaymentRequestModal} = useModalPaymentRequest();

  useEffect(() => {
    startLoading('account');
    api
      .getMyAccounts()
      .then(res => {
        const innerAccount = res.data.items.find(
          ac => ac.type === FinancialAccountType.INNER && ac.currency === Currency.USD,
        );
        if (innerAccount != null) {
          setSelectedAccount(innerAccount);
        }
      })
      .finally(() => stopLoading('account'));
  }, []);

  useEffect(() => {
    const withdrawalAmount = new URLSearchParams(search).get('mc_pay_withdrawal_amount');
    if (withdrawalAmount != null) {
      handleCreatePaymentRequest(withdrawalAmount as any).then();
    }
  }, []);

  const fetchPaymentsRequests = useCallback(async (filters: IQueryParams) => {
    startLoading('paymentRequests');
    paymentsRequestsApi
      .getAllAvailablePaymentRequests({...filters, page: filters?.paymentRequestPage})
      .then(res => {
        setPaymentRequests(res.data.items);
        setPaymentRequestsPaginationInfo(res.data.paginator);
      })
      .catch(() => toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'})))
      .finally(() => stopLoading('paymentRequests'));
  }, []);

  const handleCreatePaymentRequest = async (mcPayWithdrawalAmount: number | string | null = null) => {
    const result = await showPaymentRequestModal({
      totalAvailableWithdrawalAmount:
        selectedAccount?.withdrawalAvailableBalance != null
          ? Math.floor(selectedAccount.withdrawalAvailableBalance * 100) / 100
          : null,
      mcPayWithdrawalAmount,
    });

    if (result.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_PAYMENT_REQUEST'}));
      await fetchPaymentsRequests({});
    }
  };

  const getStatusColor = useCallback((status: PaymentRequestStatus) => {
    if (status === PaymentRequestStatus.IN_WORK) {
      return 'primary';
    }

    if (status === PaymentRequestStatus.COMPLETE) {
      return 'success';
    }

    if (status === PaymentRequestStatus.REJECT) {
      return 'danger';
    }
  }, []);

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className='d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap'>
            <div className='d-flex flex-column mr-5'>
              <div className='h4 mb-1 d-flex align-items-center'>
                {loadings.account ? (
                  <span className={'ml-3'}>
                    {' '}
                    <Spinner animation='border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </Spinner>{' '}
                  </span>
                ) : (
                  <div className={'d-flex flex-column'}>
                    <div className={'mb-2'}>
                      <FormattedMessage id={'CURRENT_BALANCE'} />:
                      <span className={'ml-2 font-weight-boldest'}>
                        {CustomFormatter.formatMoney(selectedAccount?.balance, selectedAccount?.currency)}
                      </span>
                    </div>
                    <div>
                      <FormattedMessage id={'UNCONFIRMED_BALANCE'} />:{' '}
                      <span className={'ml-2 font-weight-boldest'}>
                        {CustomFormatter.formatMoney(selectedAccount?.waitingConfirmBalance, selectedAccount?.currency)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {can_create_payment_requests && (
              <div>
                <button onClick={() => handleCreatePaymentRequest()} className={'btn btn-light-primary'}>
                  <span className='svg-icon svg-icon-light-info mr-1'>
                    <SVG src={ICONS.MONEY} />
                  </span>
                  <FormattedMessage id={'PAYMENT_REQUEST'} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {(paymentsRequests?.length > 0 || loadings.paymentRequests) && (
        <BaseListPage
          pageKey={'paymentRequestPage'}
          fetchData={fetchPaymentsRequests}
          loading={loadings?.paymentRequests}
          className={{cardBody: cn('bg-light')}}
          pageTitle={intl.formatMessage({id: 'PAYMENT_REQUESTS'})}
          pagination={{info: paymentRequestsPaginationInfo}}>
          {paymentsRequests.map(pr => {
            return (
              <Card
                key={pr.id}
                className={{
                  card: cn('border'),
                  body: 'd-flex align-items-center justify-content-between p-6',
                }}
                hideHeader
                hideFooter>
                <CardBody>
                  <div className={'d-flex align-items-center'}>
                    <div className={'h2 mb-0 mr-3 font-weight-boldest'}>
                      {CustomFormatter.customFormatMoney({sum: pr.value})}
                    </div>
                    <BadgeCustom variant={pr.destination === PaymentRequestDestination.TO_MCPAY ? 'info' : 'primary'}>
                      {intl.formatMessage({id: 'PAYMENT_REQUEST_DESTINATION_' + pr.destination})}
                    </BadgeCustom>

                    <BadgeCustom className={'ml-3'} variant={getStatusColor(pr.status)}>
                      {intl.formatMessage({id: 'PAYMENT_REQUEST_STATUS_' + pr.status})}
                    </BadgeCustom>

                    {pr.reject_reason && <div className={'ml-3 text-danger'}>{pr.reject_reason}</div>}
                  </div>
                  <div>
                    <BadgeCustom variant={'secondary'}>
                      <FormattedDate value={pr.created_at} dateStyle={'medium'} />
                    </BadgeCustom>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </BaseListPage>
      )}

      <Card>
        <CardTitle>{intl.formatMessage({id: 'TRANSACTION_OPERATIONS'})}</CardTitle>
        <CardBody>
          <Operations accountId={selectedAccount?.id ?? null} />
        </CardBody>
      </Card>
    </>
  );
};
