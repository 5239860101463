import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseContractor, IResponseContractors} from './contractors-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';
import {EntityId} from '../base/BaseEntity';
import {PartialNullable} from '../../../types/types';
import {IJurisdictionResponse} from "./contractors-jurisdictions-response-contracts";

export class ContractorsApi extends BaseEndpoint {
  public async getContractors(filters?: IQueryParams): Promise<IResponseContractors> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponseContractors>(`${config.BASE_API_URL}/contractors?${query}`);
    return response.data;
  }

  public async getContractorInfo(contractorId: EntityId): Promise<IResponseContractor> {
    const response = await this.get<IResponseContractor>(`${config.BASE_API_URL}/contractors/${contractorId}`);
    return response.data;
  }

  public async getJurisdictions(): Promise<IJurisdictionResponse> {
    const response = await this.get<IJurisdictionResponse>(
      `${config.BASE_API_URL}/jurisdictions`,
    );
    return response.data;
  }

  public async editContractor(
    contractorId: EntityId,
    payload: PartialNullable<{
      name: string;
      code: string;
    }>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/contractors/${contractorId}`, {
      fields: payload,
    });

    return response.data;
  }
}
