import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ReportFileUploader} from '../../../components/report-file-uploader';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {FinancialReportSource, FinancialReportType} from '../../../api/financial-report-api/FinancialBulkReports';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {ReportsInfo} from './reports-info';
import {useHistory} from 'react-router-dom';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {AdminRoutes} from '../../../../configs/routes';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {getValidationErrorMessage} from '../../../utils/utils';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {getEnumKeys} from '../../../../types/types';
import {ReportPeriod} from '../../../api/DTOs/ReportPeriod';

export const ReportsUploadPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const api = new FinancialReportApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    upload: false,
    periods: true,
  });

  const [files, setFiles] = useState<{[key: string]: File}>({});

  // Показываем Alert только если ошибка не связана с валидацией.
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: string[]}>({});
  const [alertVisible, setAlertVisible] = useState(false);

  const [selectedSource, setSelectedSource] = useState<FinancialReportSource>(FinancialReportSource.YOUTUBE);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [periods, setPeriods] = useState<Array<ReportPeriod>>([]);

  useEffect(() => {
    resetFieldValidation('period');
  }, [selectedPeriod]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchPeriods();
  }, []);

  const fetchPeriods = async () => {
    try {
      startLoading('periods');
      const response = await api.getReportPeriodsForUpload();
      setPeriods(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('periods');
    }
  };

  const handleSubmit = async () => {
    try {
      startLoading('upload');
      setError(null);
      setValidationError({});
      setAlertVisible(false);
      await api.sendFinancialReport(selectedPeriod, files, selectedSource);
      setFiles({});
      setAlertVisible(true);
      setTimeout(() => {
        history.push(AdminRoutes.getFinancialReportsRoute());
      }, 1000);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        setAlertVisible(true);
      }
    } finally {
      stopLoading('upload');
    }
  };

  const resetFieldValidation = (type: FinancialReportType | string) => {
    if (validationErrors[type]) {
      const errorWithoutDeleted = {...validationErrors};
      delete errorWithoutDeleted[type];
      setValidationError(errorWithoutDeleted);
    }
  };

  const handleFileSelected = (type: FinancialReportType, selectedFile: File) => {
    setFiles({...files, [type]: selectedFile});
    resetFieldValidation(type);
  };

  const handleDeleteFile = (type: FinancialReportType) => {
    const copyFiles = {...files};
    delete copyFiles[type];
    setFiles({...copyFiles});
    resetFieldValidation(type);
  };

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        <div className={'card-header'}>
          <div className={'card-title'}>
            <h3 className='card-label font-weight-bolder text-dark'>
              <FormattedMessage id={'REPORTS_UPLOAD'} />
            </h3>
          </div>
        </div>

        <div className={'card-body'}>
          <AlertCustom
            dismissible
            visible={alertVisible}
            type={error != null ? BootstrapColors.LIGHT_DANGER : BootstrapColors.LIGHT_SUCCESS}
            iconClassName={error != null ? 'svg-icon-danger' : 'svg-icon-success'}
            onClose={() => setAlertVisible(false)}
            text={intl.formatMessage({
              id: !error ? 'SUCCESS_UPLOAD_REPORTS' : 'ERROR_UPLOAD_REPORTS',
            })}
          />

          <ValidateErrorWrapper message={getValidationErrorMessage('source', validationErrors)}>
            <InputSelect
              required
              value={selectedSource}
              label={intl.formatMessage({id: 'REPORT_SOURCE'})}
              hasError={!!getValidationErrorMessage('source', validationErrors)}
              isClearable={false}
              ariaLabel={'arial-label'}
              onChange={(value: any) => setSelectedSource(value)}
              options={getEnumKeys(FinancialReportSource).map(p => ({
                value: p,
                label: intl.formatMessage({id: `FINANCIAL_REPORT_SOURCE_${p}`}),
              }))}
              placeholder={intl.formatMessage({id: 'REPORT_SOURCE'})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage('report_period_id', validationErrors)}>
            <InputSelect
              required
              value={selectedPeriod}
              label={intl.formatMessage({id: 'LABEL_PERIOD'})}
              hasError={!!getValidationErrorMessage('report_period_id', validationErrors)}
              isClearable={true}
              ariaLabel={'arial-label'}
              isLoading={loadings.periods}
              onChange={(value: any) => setSelectedPeriod(value)}
              options={periods.map(p => ({value: p.id, label: `${p.month < 10 ? '0' : ''}${p.month}.${p.year}`}))}
              placeholder={intl.formatMessage({id: 'REPORT_PERIOD'})}
            />
          </ValidateErrorWrapper>

          <div className={'row mb-5'}>
            {ReportsInfo.map(reportInfo => {
              if (reportInfo.reportSource != selectedSource) {
                return null;
              }
              return (
                <ReportFileUploader
                  key={reportInfo.type}
                  acceptFileExtension={reportInfo.acceptFileExtension}
                  type={reportInfo.type}
                  file={files[reportInfo.type]}
                  required={reportInfo.isRequired}
                  reportFileMask={reportInfo.fileMask}
                  reportDescription={intl.formatMessage({id: reportInfo.humanDescription})}
                  serverError={validationErrors[reportInfo.type]?.join(', ')}
                  className={`col-lg-${
                    reportInfo.reportSource == FinancialReportSource.YOUTUBE ? '6' : '12'
                  } col-md-12 col-sm-12`}
                  onDelete={handleDeleteFile}
                  onSelect={handleFileSelected}
                />
              );
            })}
          </div>

          <div className='form-group d-flex flex-wrap flex-center'>
            <Toolbar
              items={[
                {
                  type: 'BUTTON',
                  loading: loadings.upload,
                  title: intl.formatMessage({id: 'SUBMIT'}),
                  className: 'btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4',
                  onClick: handleSubmit,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
