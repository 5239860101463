import {STRING} from '../../app/consts';

declare global {
  interface String {
    isEmptyOrNull(): boolean;
  }
}

String.prototype.isEmptyOrNull = function () {
  return Object.isNullOrUndefined(this) || this == STRING.EMPTY;
};

export {};
