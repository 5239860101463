import React, {useState} from 'react';
import {DropdownCustomButton} from './dropdown';

interface IActionButtonProps {
  id?: string | number;
  actions: Array<{title: string; value: any; visible?: boolean; disabled?: boolean}>;

  onSelect(action: any, id?: string | number): Promise<unknown>;
}

export const ActionButton = ({onSelect, actions, id}: IActionButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async (action: any) => {
    setLoading(true);
    await onSelect(action, id);
    setLoading(false);
  };

  return (
    <DropdownCustomButton
      loading={loading}
      label={'•••'}
      className={'btn btn-sm btn-secondary font-weight-bolder'}
      onSelect={action => handleChange(action)}
      values={actions}
    />
  );
};

type Action<T extends string> = {
  title: string;
  value: T;
  disabled?: boolean;
};

type Props<T, Y extends string> = {
  rowDto: T;
  actions: Array<Action<Y>>;
  onSelect: (rowDto: T, action: Y) => Promise<any>;
};

export function RowActionButton<T, Y extends string>({rowDto, actions, onSelect}: Props<T, Y>) {
  const handleChange = async (action: Y) => {
    await onSelect(rowDto, action);
  };

  if (actions.length === 0) {
    return null;
  }

  return (
    <DropdownCustomButton
      label={'•••'}
      className={'btn btn-sm btn-secondary font-weight-bolder'}
      onSelect={visibleActions => handleChange(visibleActions as any)}
      values={actions}
    />
  );
}
