import React from 'react';
import SVG from 'react-inlinesvg';
import {IYoutubeVideoDto} from '../../../api/DTOs/IYoutubeVideoDto';
import {FormattedNumber} from 'react-intl';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {ExternalRoutes} from '../../../../configs/routes';
import {IMAGES} from '../../../images/images';
import {ImageWithPreloader} from '../../../components/image-with-preloader';

const COMMENTS_SVG = require('../../../images/svg/comments.svg');
const VISIBLE_SVG = require('../../../images/svg/Visible.svg');
const DISLIKE_SVG = require('../../../images/svg/Dislike.svg');
const LIKE_SVG = require('../../../images/svg/Like.svg');

interface YoutubeVideoCardProps {
  video: IYoutubeVideoDto;
}

export const YoutubeVideoCard = ({video}: YoutubeVideoCardProps) => {
  const renderMetric = (icon: string, value: number, style: string) => {
    return (
      <div className={'d-flex align-items-center mr-3'}>
        <div className={`svg-icon svg-icon-lg svg-icon-${style}`}>
          <SVG src={icon} />
        </div>
        &nbsp;
        <span className={'font-weight-bolder'}>
          <FormattedNumber value={value} notation={'compact'} />
        </span>
      </div>
    );
  };
  return (
    <div className='col-xxxl-3 col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
      <div className='gutter-b card-stretch border card card-custom overlay'>
        <div className='card-body p-0'>
          <div className='overlay-wrapper'>
            <a target={'_blank'} href={ExternalRoutes.getExternalYoutubeVideoRoute(video.id)} rel='noreferrer'>
              <ImageWithPreloader
                containerHeight={'200px'}
                errorStub={IMAGES.VIDEO_PREVIEW_STUB}
                imageProps={{
                  src: tryGetPreviewOrStub(video.thumbnails).url as string,
                  alt: video.title,
                  className: 'w-100 rounded',
                }}
              />
            </a>
          </div>
          <div className={'my-1 p-3'}>
            <a target={'_blank'} href={ExternalRoutes.getExternalYoutubeVideoRoute(video.id)} rel='noreferrer'>
              <span className='text-dark-75 font-size-lg font-weight-bolder'>{video.title}</span>
            </a>
          </div>
        </div>
        <div className='card-footer p-2'>
          <div className={'d-flex flex-wrap'}>
            {renderMetric(LIKE_SVG, video.likeCount, 'success')}
            {renderMetric(DISLIKE_SVG, video.dislikeCount, 'danger svg-icon-flip-mirror')}
            {renderMetric(VISIBLE_SVG, video.viewCount, 'primary')}
            {renderMetric(COMMENTS_SVG, video.commentCount, 'secondary')}
          </div>
        </div>
      </div>
    </div>
  );
};
