import {BaseEntity} from '../base/BaseEntity';

export interface Transaction extends BaseEntity {
  description: string | null;
  status: TransactionStatus;
  reject_reason: string | null;
  cancel_reason: string | null;
}

export enum TransactionStatus {
  WAITING_CONFIRM = 'WAITING_CONFIRM',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum Currency {
  USD = 'USD',
  RUB = 'RUB',
}
