import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {AlertCustom} from '../component/alert';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ApiRequestException} from '../../../api/axios-instance';
import {Routes} from '../../../../configs/routes';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {useAuth} from '../../../hooks/use-auth';
import {useIntl} from '../../../hooks/use-intl';
import {useAuthApi} from '../../../hooks/apis/auth-api';

export const Login = () => {
  const intl = useIntl();
  const {setCurrentUser, setCurrentContractor, setCurrentGlobalPrivileges} = useAuth();
  const authApi = useAuthApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: Array<string>} | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const sendLoginRequest = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      const result = await authApi.login({email, password});
      setCurrentContractor(result.data.relations.contractor);
      setCurrentGlobalPrivileges(result.data.privileges);
      setCurrentUser(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else if (err.errorType === EXCEPTION_TYPE.UNAUTHENTICATED_EXCEPTION) {
        setError(intl.formatMessage({id: 'LOGIN_ERROR'}));
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.LOGIN.SUB_TITLE' />
        </p>
      </div>

      <form className='form fv-plugins-bootstrap fv-plugins-framework'>
        <AlertCustom
          text={error}
          type={BootstrapColors.LIGHT_DANGER}
          iconClassName={'svg-icon-danger'}
          visible={error != null}
        />

        <ValidateErrorWrapper message={validationErrors && validationErrors['email']}>
          <InputText
            name={'email'}
            type={'email'}
            value={email}
            hasError={validationErrors?.email != undefined}
            onChange={e => setEmail(e.currentTarget.value)}
            label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={validationErrors && validationErrors['password']}>
          <InputText
            name={'password'}
            type={'password'}
            value={password}
            hasError={validationErrors?.password != undefined}
            onChange={e => setPassword(e.currentTarget.value)}
            label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link to={Routes.getForgotPasswordRoute()} className='text-dark-50 text-hover-primary my-3 mr-2'>
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button
            onClick={sendLoginRequest}
            type='submit'
            disabled={loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
            <span>
              <FormattedMessage id='AUTH.LOGIN.BUTTON' />
            </span>
            {loading && <span className='ml-3 spinner spinner-white' />}
          </button>
        </div>
      </form>
    </div>
  );
};
