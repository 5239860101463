import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {useIntl} from '../../../hooks/use-intl';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {toast} from 'react-toastify';
import {useLoading} from '../../../hooks/use-loading';
import {InputMultiSelect} from '../../Inputs/InputMultiSelect';
import {ReportPeriod} from '../../../api/DTOs/ReportPeriod';
import {EntityId} from '../../../api/base/BaseEntity';
import {useSelectApi} from '../../../hooks/use-select-api';
import {InputSelect} from '../../Inputs/InputSelect';
import {ContractType} from '../../../api/contract-api/Contract';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {isValidationException} from '../../../utils/api-utils';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';

interface Props {
  contractId?: EntityId;
  visible: boolean;
  onHide: () => any;
}

export const GenerateDetailedExcelReportModal: React.FC<Props> = ({contractId, visible, onHide}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    downloadReport: false,
    reportPeriods: true,
  });
  const api = new FinancialReportApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [selectedContractId, setSelectedContractId] = useState<EntityId | null>(null);
  const [reportPeriods, setReportPeriods] = useState<ReportPeriod[]>([]);
  const [selectedReportPeriods, setSelectedReportPeriods] = useState<EntityId[]>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>(null);
  useEffect(() => {
    if (visible) {
      const promises = [fetchReportPeriods()];
      if (contractId == null) {
        promises.push(fetchSelectValuesByKey('selfContracts', {type: ContractType.YOUTUBE_PARTNER}));
      }

      Promise.all(promises).then();
    }
  }, [visible]);

  const handleCloseClick = () => {
    setReportPeriods([]);
    setSelectedReportPeriods([]);
    setSelectedContractId(null);
    setValidationErrors(null);
    onHide();
  };

  const fetchReportPeriods = useCallback(async () => {
    try {
      startLoading('reportPeriods');
      const res = await api.getReportPeriodsForDownload();
      setReportPeriods(res.data.items);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('reportPeriods');
    }
  }, []);

  const handleSuccessClick = async () => {
    try {
      startLoading('downloadReport');
      await api.postDetailedExcelReport(contractId != null ? contractId : selectedContractId, selectedReportPeriods);
      toast.success(intl.formatMessage({id: 'GENERATE_DETAILED_EXCEL_REPORT_SUCCESS'}));
      onHide();
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors((e.innerException as ValidationException).error_data.messages);
      } else {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('downloadReport');
    }
  };

  return (
    <>
      {/*@ts-ignore*/}
      <BaseModal size={'lg'} visible={visible} onSuccess={handleSuccessClick} onHide={handleCloseClick}>
        <ModalHeaderSlot>
          <Modal.Title>{intl.formatMessage({id: 'GENERATE_DETAILED_EXCEL_REPORT_MODAL_TITLE'})}</Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <div>
            <ValidateErrorWrapper message={getValidationErrorMessage('report_period_ids', validationErrors)}>
              <InputMultiSelect
                required
                label={intl.formatMessage({id: 'REPORT_PERIOD'})}
                placeholder={''}
                isLoading={loadings.reportPeriods}
                defaultValues={selectedReportPeriods}
                hasError={!!getValidationErrorMessage('report_period_ids', validationErrors)}
                options={reportPeriods.map(r => ({
                  value: r.id,
                  label: intl.formatDate(new Date(r.year, r.month - 1), {month: 'long', year: 'numeric'}),
                }))}
                className={'mb-3'}
                onChange={items => setSelectedReportPeriods((items ?? []).map(x => x.value))}
              />
            </ValidateErrorWrapper>

            {contractId == null && (
              <ValidateErrorWrapper message={getValidationErrorMessage('contract_id', validationErrors)}>
                <InputSelect
                  required
                  label={intl.formatMessage({id: 'CONTRACT'})}
                  placeholder={''}
                  hasError={!!getValidationErrorMessage('contract_id', validationErrors)}
                  isLoading={optionsLoadings.selfContracts}
                  value={selectedContractId}
                  options={selectsOptions.selfContracts}
                  onChange={setSelectedContractId}
                />
              </ValidateErrorWrapper>
            )}
          </div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
