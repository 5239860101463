import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {UserActionInSystem} from '../../../api/user-api/UserActionInSystem';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {CommonRoutes} from '../../../../configs/routes';

interface UserActionProps {
  action: UserActionInSystem;
}

export const UserAction: React.FC<UserActionProps> = ({action}) => {
  const intl = useIntl();

  const [collapsed, setCollapse] = useState<boolean>(true);

  const handleCollapseClick = () => {
    setCollapse(!collapsed);
  };

  const ribbonStyle =
    action.responseStatusCode > 204 ? 'danger' : action.method === 'GET' ? 'secondary text-dark-50' : 'success';

  const urlSearchParams = new URLSearchParams(action?.queryString?.replaceAll('"', '') ?? '');
  const filters = Array.from(urlSearchParams.entries());
  return (
    <div className={`card card-custom border gutter-b ribbon ribbon-top`}>
      <div
        className={cn(`ribbon-target font-weight-bolder bg-primary bg-${ribbonStyle}`)}
        style={{top: '0px', right: '0px', borderTopRightRadius: '0.42rem'}}>
        {action.method}
      </div>
      <div className={`card-body`}>
        <div className={'d-flex flex-column align-items-start flex-wrap'}>
          <div className={'d-flex align-items-center my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-h5'}>{action.url}</span>
            </div>
          </div>

          <div className={'item d-flex align-items-center my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span
                className={`font-weight-bolder font-size-h5 ${
                  action.responseStatusCode > 204 ? 'text-danger' : 'text-success'
                }`}>
                {action.responseStatusCode}
              </span>
            </div>
          </div>

          <div className={'d-flex flex-row flex-wrap mt-2'}>
            {action.userName && (
              <>
                <div className={'item d-flex align-items-center mr-5 my-1'}>
                  <div className={'d-flex flex-column text-dark-75'}>
                    <span className={'font-weight-bolder font-size-sm'}>
                      <FormattedMessage id={'USER'} />
                    </span>
                    <Link
                      className={'font-weight-bolder'}
                      to={CommonRoutes.getSpecifyUserManagementRoute(action.userId)}>
                      {action.userName}
                    </Link>
                  </div>
                </div>
              </>
            )}

            <div className={'d-flex align-items-center my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>Time</span>
                <span className={'font-weight-bolder'}>{`${intl.formatDate(action.createdAt)} ${intl.formatTime(
                  action.createdAt,
                )}`}</span>
              </div>
            </div>
          </div>
          <div className={'font-size-sm text-success cursor-pointer'} onClick={handleCollapseClick}>
            {collapsed ? (
              <>
                <FormattedMessage id={'SHOW_MORE'} /> {filters.length > 0 ? `(${filters.length})` : ''}
              </>
            ) : (
              <FormattedMessage id={'CLOSE'} />
            )}
          </div>
          <div className={`${collapsed ? 'd-none' : ''}`}>
            <div className={'d-flex flex-row flex-wrap mt-2'}>
              {action.ip && (
                <>
                  <div className={'item d-flex align-items-center my-1'}>
                    <div className={'d-flex flex-column text-muted'}>
                      <span className={'font-weight-bolder font-size-sm'}>IP</span>
                      <span className={'font-weight-bolder'}>{action.ip}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={'d-flex flex-row flex-wrap mt-2'}>
              {action.userAgent && (
                <>
                  <div className={'item d-flex align-items-center my-1'}>
                    <div className={'d-flex flex-column text-muted'}>
                      <span className={'font-weight-bolder font-size-sm'}>
                        <FormattedMessage id={'AGENT'} />
                      </span>
                      <span className={'font-weight-bolder'}>{action.userAgent}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={'d-flex flex-column my-2'}>
              {filters.map(([key, value]) => {
                return (
                  <div key={key}>
                    <span className={'font-weight-bolder'}>{key}</span>: {value}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
