import React, {useMemo, useState} from 'react';
import {StringParam, withDefault} from 'use-query-params';
import {YoutubeChannelsTable} from './youtube-channels-table';
import {useIntl} from 'react-intl';
import {IPaginationInfo} from '../../../api/Paginator';
import {YoutubeChannel} from '../../../api/DTOs/YoutubeChannel';
import {YoutubeChannelsApi} from '../../../api/youtube-channels-api';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {YoutubeChannelCard} from './youtube-channel-card';
import {ErrorStub} from '../../../components/error-stub';
import {ConnectYoutubeChannelWidget} from './connect-youtube-channel-widget';
import {ApiRequestException} from '../../../api/axios-instance';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import cn from 'classnames';
import {useAuth} from '../../../hooks/use-auth';
import {EmptyListState} from '../../../components/empty-list-state';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {YoutubeChannelRelation} from '../../../api/response-contracts/yotutube-channel-contracts';

const defaultSortOptions: ISortDto = {
  field: SortField.TITLE,
  direction: SortDirection.ASC,
};

export const YoutubeChannelsPage = () => {
  const intl = useIntl();
  const isAdmin = useAuth().isAdmin;

  const [enabledCardMode, setEnableCardMode] = useState(true);

  const api = new YoutubeChannelsApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [channels, setChannels] = useState<Array<YoutubeChannel>>([]);
  const [relations, setRelations] = useState<ManyRelations<YoutubeChannelRelation> | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: A-z`,
          value: {field: SortField.TITLE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: Z-a`,
          value: {field: SortField.TITLE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'VIDEOS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.VIDEO, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'VIDEOS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.VIDEO, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'SUBSCRIBERS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.SUBSCRIBER, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'SUBSCRIBERS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.SUBSCRIBER, direction: SortDirection.DESC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      filters: [
        {
          type: FilterType.TEXT,
          name: 'title',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          visible: isAdmin,
          type: FilterType.SELECT,
          name: 'active_contract',
          queryConfig: {
            name: 'active_contract',
            type: withDefault(StringParam, ''),
          },
          placeholder: intl.formatMessage({id: 'CONTRACT_EXISTENCE'}),
          options: [
            {
              value: 'HAS_CONTRACT',
              label: intl.formatMessage({id: 'HAS_CONTRACT'}),
            },
            {
              value: 'HAS_NO_CONTRACT',
              label: intl.formatMessage({id: 'HAS_NO_CONTRACT'}),
            },
          ],
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          queryConfig: {
            name: 'status',
            type: withDefault(StringParam, 'ACTIVE'),
          },
          placeholder: intl.formatMessage({id: 'ACCESSIBILITY'}),
          options: [
            {
              value: 'ACTIVE',
              label: intl.formatMessage({id: 'ACTIVE'})
            },
            {
              value: 'INACTIVE',
              label: intl.formatMessage({id: 'INACTIVE'}),
            },
            {
              value: 'ALL',
              label: intl.formatMessage({id: 'SHOW_ALL'}),
            },
          ],
        },
      ],
    });
  }, []);

  const fetchChannels = async (opt?: IQueryParams) => {
    try {
      setLoading(true);
      const response = await api.getChannelList(opt);
      setRelations(response.data.relations);
      setPaginationInfo(response.data.paginator);
      setChannels(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const renderChannels = () => {
    if (enabledCardMode) {
      return (
        <>
          {channels.length > 0 ? (
            <div className='row'>
              {channels.map(channel => (
                <div key={channel.id} className='col-xl-3 col-lg-12 col-md-12 col-sm-12'>
                  <YoutubeChannelCard channel={channel} />
                </div>
              ))}
            </div>
          ) : (
            <EmptyListState />
          )}
        </>
      );
    }

    return <YoutubeChannelsTable channels={channels} relations={relations} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      <ConnectYoutubeChannelWidget />
      <BaseListPage
        loading={loading}
        filtersConfig={filterConfig}
        sortsConfig={sortConfig}
        pagination={{info: paginationInfo}}
        fetchData={fetchChannels}
        toolbarConfig={[
          {
            type: 'SWITCH',
            size: 'sm',
            titles: [intl.formatMessage({id: 'CARDS'}), intl.formatMessage({id: 'TABLE'})],
            checked: !enabledCardMode,
            onChange: () => setEnableCardMode(prevState => !prevState),
          },
        ]}
        className={{cardBody: cn({'bg-light': enabledCardMode})}}>
        {renderChannels()}
      </BaseListPage>
    </>
  );
};
