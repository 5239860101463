export enum Feed {
  BEELINE = 'BEELINE',
  YOUTUBE = 'YOUTUBE',
  YANDEX_ZEN = 'YANDEX_ZEN',
  RUTUBE = 'RUTUBE',
  OK = 'OK',
  VK = 'VK',
  TVIGLE = 'TVIGLE',
  MTS = 'MTS',
  MEGAFON = 'MEGAFON',
}
