import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseConnectChannelRequest, IResponseConnectChannelRequests} from './connect-channels-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';

export class ConnectChannelsApi extends BaseEndpoint {
  //#region Admin endpoints
  public async getUsersConnectChannelRequests(filter?: IQueryParams): Promise<IResponseConnectChannelRequests> {
    const query = this.applyQueryParams(filter);
    const response = await this.get<IResponseConnectChannelRequests>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests?${query}`,
    );
    return response.data;
  }

  public async getUserConnectChannelRequest(id: number): Promise<IResponseConnectChannelRequest> {
    const response = await this.get<IResponseConnectChannelRequest>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}`,
    );
    return response.data;
  }

  public async approve(id: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}/approve`,
      {},
    );
    return response.data;
  }

  public async reject(id: number, reject_reason: string | null): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}/reject`,
      {
        fields: {
          reject_reason,
        },
      },
    );
    return response.data;
  }

  //#endregion Admin endpoints

  //#region User endpoints
  public async getSelfConnectChannelRequest(): Promise<IResponseConnectChannelRequests> {
    const response = await this.get<IResponseConnectChannelRequests>(
      `${config.BASE_API_URL}/youtube-channels-requests`,
    );
    return response.data;
  }

  //#endregion User endpoints
}
