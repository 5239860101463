import React, {useEffect, useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../../api/DTOs/IFilterDtos';
import {Contract, ContractType} from '../../../../api/contract-api/Contract';
import {IPaginationInfo} from '../../../../api/Paginator';
import {useIntl} from 'react-intl';
import {ContractsTable} from './contracts-table';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../../configs/routes';
import {useLoading} from '../../../../hooks/use-loading';
import {Filter, FilterType} from '../../../../components/filters/filters';
import {FilterBuilder} from '../../../../components/filters/filter-builder';
import {BaseListPage} from '../../../base/base-list-page';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {useContractsApi} from '../../../../hooks/apis/use-contracts-api';
import {getEnumKeys} from '../../../../../types/types';

const ICONS = {
  PLUS: require('../../../../images/svg/Plus.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const ContractsPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = useContractsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: false,
  });

  const {selectsOptions, optionsLoadings, fetchSelectValuesByKey} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [contracts, setContracts] = useState<Array<Contract>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'contracts_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          selectKey: 'contractors',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'our_contractor_id',
          selectKey: 'ourContractors',
          placeholder: intl.formatMessage({id: 'IS_OUR_COMPANY'}),
        },
        {
          type: FilterType.SELECT,
          name: 'type',
          options: getEnumKeys(ContractType).map(type => {
            return {
              value: type,
              label: intl.formatMessage({id: `CONTRACT_TYPE_${type}`}),
            };
          }),
          placeholder: intl.formatMessage({id: 'TYPE'}),
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);

  useEffect(() => {
    Promise.all([
      fetchSelectValuesByKey('ourContractors'),
      fetchSelectValuesByKey('users'),
      fetchSelectValuesByKey('contractors'),
    ]).then();
  }, []);

  const fetchContracts = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getAll(filters);
      setContracts(result.items);
      setPaginationInfo(result.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchContracts}
      toolbarConfig={[
        {
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE_CONTRACT'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: () => history.push(AdminRoutes.getCreateContractRoute()),
        },
      ]}>
      <ContractsTable contracts={contracts} />
    </BaseListPage>
  );
};
