import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import {useIntl} from 'react-intl';
import {useHtmlClassService} from '../../../_metronic/layout/_core/MetronicLayout';

const APP_LOGO_LOGO = require('../../images/svg/starpro-logo.svg');
const ANGLE_DOUBLE_LEFT = require('../../images/svg/Angle-double-left.svg');

export function Brand() {
  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id='kt_brand'>
        <Link to='' className='brand-logo'>
          <img alt='StarPro' src={APP_LOGO_LOGO} />
        </Link>

        <button className='brand-toggle btn btn-sm px-0' id='kt_aside_toggle'>
          <span className='svg-icon svg-icon-xl'>
            <SVG title={intl.formatMessage({id: 'TOGGLE_MENU'})} src={ANGLE_DOUBLE_LEFT} />
          </span>
        </button>
      </div>
    </>
  );
}
