import React, {createContext, PropsWithChildren, useCallback, useContext, useState} from 'react';
import {ModalUploadFiles} from './upload-watermarked-files-modal';
import {CloseModalEvent, CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {FileDto} from '../../../api/file-api/IFileDto';
import {AttachmentMetadata} from '../../../components/file-uploader';
import {AttachmentType} from '../upload-and-create/attachment-block';
import {useVideosApi} from '../../../hooks/apis/use-videos-api';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {EntityId} from '../../../api/base/BaseEntity';
import {CreatedFile} from '../../../api/file-api/files-api';
import {PartialNullable} from '../../../../types/types';
import {toast} from 'react-toastify';
import {MultipartUploaderErrorsPayloads} from '../../../services/MultipartUploader';

type OpenPayload = {
  videoId: EntityId;
  videoFile: FileDto | null;
  imageFile: FileDto | null;
};

interface IModalCreateEditProps {
  modalCreateEditVisible: boolean;

  showUploadFilesModal(payload: OpenPayload): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateEditContext = createContext<IModalCreateEditProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalUploadFilesProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const api = useVideosApi();
  const [visible, setVisible] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<EntityId | null>(null);
  const [videoMetadata, setVideoMetadata] = useState<AttachmentMetadata | null>(null);
  const [imageMetadata, setImageMetadata] = useState<AttachmentMetadata | null>(null);
  const [entity, setEntity, updateEntityFields] = useAdvancedState<
    PartialNullable<Record<'watermarked_image_file_id' | 'watermarked_video_file_id', EntityId>>
  >({});
  const showModal = async (openPayload: OpenPayload) => {
    setVisible(true);
    setVideoId(openPayload.videoId);
    if (openPayload?.videoFile) {
      updateEntityFields({watermarked_video_file_id: openPayload.videoFile.id});
      setVideoMetadata({
        url: openPayload.videoFile.url,
        size: openPayload.videoFile.size,
        filename: openPayload.videoFile.original_file_name,
      });
    }
    if (openPayload?.imageFile) {
      updateEntityFields({watermarked_image_file_id: openPayload.imageFile.id});
      setImageMetadata({
        url: openPayload.imageFile.url,
        size: openPayload.imageFile.size,
        filename: openPayload.imageFile.original_file_name,
      });
    }
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setImageMetadata(null);
    setVideoMetadata(null);
    setEntity({});
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      await api.updateWatermarkedFiles(videoId as EntityId, entity);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleStartUpload = useCallback(
    async (file: File, type: AttachmentType, progressCb?: (progress: number) => void) => {
      if (type === AttachmentType.IMAGE) {
        return await api.uploadToFileStorage(file);
      } else if (type === AttachmentType.VIDEO) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return await api.multipartUploadToFileStorage(file, progressCb ?? (() => {}));
      }

      throw new Error();
    },
    [],
  );

  const handleTryAgainUpload = useCallback(
    async (file: File, error: MultipartUploaderErrorsPayloads, progressCb: (progress: number) => void) => {
      return await api.tryAgainMultipartUploadToFileStorage(file, error, progressCb);
    },
    [],
  );

  const handleCreateFile = useCallback((file: CreatedFile, type: AttachmentType) => {
    if (type === AttachmentType.IMAGE) {
      updateEntityFields({watermarked_image_file_id: file.id});
    } else if (type === AttachmentType.VIDEO) {
      updateEntityFields({watermarked_video_file_id: file.id});
    }
  }, []);

  const handleCancelUpload = useCallback((promiseResolver: (value: any | PromiseLike<any>) => void) => {
    api.cancelMultipartUpload(promiseResolver);
  }, []);

  const handleDelete = useCallback((type: AttachmentType) => {
    if (type === AttachmentType.IMAGE) {
      updateEntityFields({watermarked_image_file_id: null});
    } else if (type === AttachmentType.VIDEO) {
      updateEntityFields({watermarked_video_file_id: null});
    }
  }, []);

  const value: IModalCreateEditProps = {
    modalCreateEditVisible: visible,
    showUploadFilesModal: showModal,
  };

  return (
    <ModalCreateEditContext.Provider value={value}>
      {children}
      <ModalUploadFiles
        visible={visible}
        imageMetadata={imageMetadata}
        videoMetadata={videoMetadata}
        onStartUpload={handleStartUpload}
        onCreateFile={handleCreateFile}
        onDelete={handleDelete}
        onCancelUpload={handleCancelUpload}
        onTryAgainUpload={handleTryAgainUpload}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalCreateEditContext.Provider>
  );
};

export const useModalUploadFiles = () => {
  return useContext(ModalCreateEditContext);
};
