import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {Preloader} from '../../preloader';
import {InputText} from '../../Inputs/InputText';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

interface IModalProps {
  visible: boolean;

  error?: string | null;
  filterPattern?: string;
  loadingUsers: boolean;
  users: Array<ISelectValueDto>;
  selectedUsers: Array<ISelectValueDto>;

  onHide(): void;

  onFilterChange(filter: string): void;

  onSelectUser(selectedUser: ISelectValueDto): void;

  onSelectAll(): void;

  onOkClick(): any;
}

export const ModalChooseUsers: React.FC<IModalProps> = ({
  loadingUsers,
  filterPattern,
  users,
  selectedUsers,
  error,
  visible,
  onSelectUser,
  onSelectAll,
  onFilterChange,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const renderUsers = () => {
    if (users.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <>
        {(filterPattern === '' || filterPattern == undefined) && (
          <InputCheckbox
            key={'all'}
            label={intl.formatMessage({id: 'CHOOSE_ALL'})}
            value={selectedUsers.length === users.length}
            onChange={onSelectAll}
          />
        )}

        {users.map(user => {
          return (
            <div key={user.id} id={user.id}>
              <InputCheckbox
                label={user.title}
                value={selectedUsers.includes(user)}
                onChange={() => onSelectUser(user)}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={`${intl.formatMessage({id: 'SEND'})} (${selectedUsers.length})`}
        disabledButtons={loading || selectedUsers.length === 0}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CHOOSE_USERS'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />

            <InputText
              value={filterPattern}
              label={intl.formatMessage({id: 'EMAIL'})}
              classNames={'form-control mb-8'}
              onChange={e => onFilterChange(e.currentTarget.value)}
            />

            <div>{loadingUsers ? <Preloader /> : renderUsers()}</div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
