import React, {useEffect, useLayoutEffect} from 'react';
import {ContractorFields} from '../../../../admin/contractors/contractor-page/edit/contractor-fields';
import {useAdvancedState} from '../../../../../hooks/use-advanced-state';
import {Contractor, ContractorDataValidationStatus, Currency} from '../../../../../api/contractor-api/Contractor';
import {useUserApi} from '../../../../../hooks/apis/use-user-api';
import {useAuth} from '../../../../../hooks/use-auth';
import {toast} from 'react-toastify';
import {useIntl} from '../../../../../hooks/use-intl';
import {AlertCustom} from '../../../../../modules/Auth/component/alert';
import {BootstrapColors} from '../../../../../../styles/bootstap-colors';

type Props = {
  title: string;
  match: ContractorDataValidationStatus[];
  setNexCallback: (cb: CallableFunction) => void;
};

export const StepFillContractorFields: React.FC<Props> = ({setNexCallback}) => {
  const intl = useIntl();
  const {setCurrentContractor, contractor: currentContractor} = useAuth();
  const api = useUserApi();
  const [contractor, , updateContractorFields] = useAdvancedState<Contractor>(
    currentContractor ?? {
      bank_account_currency: Currency.RUB,
      individual_is_self_employed: false,
    },
    api.setValidationErrors,
  );

  const handleNextClick = async () => {
    try {
      const result = await api.updateContractorFields(contractor);

      setCurrentContractor(result.data.item);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  useLayoutEffect(() => {
    setNexCallback(handleNextClick);
  }, [handleNextClick]);

  return (
    <>
      <div className={'max-w-1200px'}>
        <AlertCustom
          className={'font-size-lg'}
          text={intl.formatMessage({id: 'REGISTRATION_WELCOME_NOTICE'})}
          type={BootstrapColors.LIGHT_INFO}
          iconClassName={'svg-icon-info'}
          visible={currentContractor?.data_validation_status === ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES}
        />

        <AlertCustom
          className={'font-size-lg'}
          text={`${intl.formatMessage({id: 'REGISTRATION_FIX_REQUIREMENTS_NOTICE'})}:\n${
            contractor.data_validation_requirements
          }`}
          type={BootstrapColors.LIGHT_DANGER}
          iconClassName={'svg-icon-danger'}
          visible={currentContractor?.data_validation_status === ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS}
        />
      </div>
      <ContractorFields
        hideCode={true}
        contractor={contractor}
        validationErrors={api.validationErrors}
        updateFields={updateContractorFields}
      />
    </>
  );
};
