import React, {useCallback} from 'react';
import {EntityTable} from '../../../components/tables/entity-table';
import {YoutubeChannelInContract} from '../../../api/contract-api/Contract';
import {RowEntity} from './contract-page/create-edit-contract-page/edit-contract-block/row';
import {PartialNullable, UpdateEntity} from '../../../../types/types';
import cn from 'classnames';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';
import {DataSetValidationErrors} from '../../../utils/utils';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputNumeric} from '../../../components/Inputs/InputNumeric';
import {InputCheckbox} from '../../../components/Inputs/InputCheckbox';

type Props = {
  channels: RowEntity<YoutubeChannelInContract>[];
  onSave: (item: RowEntity<YoutubeChannelInContract>) => boolean;
  privileges?: {canDelete?: boolean; canEditRatePercent?: boolean; canEdit?: boolean};
  validationErrors?: DataSetValidationErrors<YoutubeChannelInContract> | null;
};

export const ChannelTable: React.FC<Props> = ({channels, privileges, validationErrors, onSave}) => {
  const handleChangeRatePercent = useCallback(
    (
      value: string | number | null,
      state: RowEntity<YoutubeChannelInContract>,
      updateState: UpdateEntity<RowEntity<YoutubeChannelInContract>>,
    ) => {
      let updatedState = {} as
        | RowEntity<YoutubeChannelInContract>
        | PartialNullable<RowEntity<YoutubeChannelInContract>>;
      updateState(prevState => {
        updatedState = {
          ...prevState,
          rate_percent: value as any,
          isChanged: state.prevState.rate_percent !== value,
        };
        return updatedState;
      });

      onSave(updatedState as any);
    },
    [onSave],
  );

  const handleChangeIsReferral = useCallback(
    (
      value: boolean | null,
      state: RowEntity<YoutubeChannelInContract>,
      updateState: UpdateEntity<RowEntity<YoutubeChannelInContract>>,
    ) => {
      let updatedState = {} as
        | RowEntity<YoutubeChannelInContract>
        | PartialNullable<RowEntity<YoutubeChannelInContract>>;
      updateState(prevState => {
        updatedState = {
          ...prevState,
          is_referral: value as any,
          isChanged: state.prevState.is_referral !== value,
        };
        return updatedState;
      });

      onSave(updatedState as any);
    },
    [onSave],
  );

  const handleSave = useCallback(
    (item: RowEntity<YoutubeChannelInContract>, updateState: UpdateEntity<RowEntity<YoutubeChannelInContract>>) => {
      onSave({...item, isEdit: false});
      updateState({isEdit: false});
    },
    [onSave],
  );

  const handleDelete = useCallback(
    (item: RowEntity<YoutubeChannelInContract>) => {
      onSave({...item, isDeleted: true});
    },
    [onSave],
  );

  return (
    <EntityTable
      entityKey={'y_channel_id'}
      items={channels}
      columns={[
        {
          id: 'Channel',
          headerDefinition: {
            title: 'YOUTUBE_CHANNEL',
            width: '90%',
          },
          value: (rowState, updateState, setState, index) => {
            const errors =
              validationErrors?.[index]?.filter(v => v.fieldKey === 'y_channel_id').map(v => v.message) ?? null;
            return (
              <ValidateErrorWrapper message={errors} className={'mb-0'}>
                <Link to={Routes.getYoutubeSpecifyChannelRoute(rowState.y_channel_id)}>{rowState.y_channel_title}</Link>
              </ValidateErrorWrapper>
            );
          },
        },
        {
          id: 'RatePercent',
          headerDefinition: {
            title: '%',
          },
          value: (rowState, updateState, setState, index) => {
            const errors =
              validationErrors?.[index]?.filter(v => v.fieldKey === 'rate_percent').map(v => v.message) ?? null;
            return (
              <ValidateErrorWrapper message={errors} className={'mb-0'}>
                <InputNumeric
                  step={0.5}
                  maxNumber={100}
                  minNumber={0}
                  disabled={
                    !rowState.isEdit &&
                    (privileges == undefined ||
                      privileges?.canEditRatePercent == undefined ||
                      !privileges.canEditRatePercent)
                  }
                  hasError={errors != null && errors.length != 0}
                  value={rowState?.rate_percent as any}
                  onChange={e => handleChangeRatePercent(e, rowState, updateState)}
                />
              </ValidateErrorWrapper>
            );
          },
        },
        {
          id: 'IsReferral',
          headerDefinition: {
            title: 'SHORT_REFERRAL',
          },
          value: (rowState, updateState, setState, index) => {
            const errors =
              validationErrors?.[index]?.filter(v => v.fieldKey === 'is_referral').map(v => v.message) ?? null;
            return (
              <div className={'d-flex justify-content-center align-items-center'}>
                <ValidateErrorWrapper message={errors} className={'mb-0'}>
                  <InputCheckbox
                    value={rowState.is_referral}
                    disabled={!rowState.isEdit}
                    onChange={e => handleChangeIsReferral(e.currentTarget.checked, rowState, updateState)}
                  />
                </ValidateErrorWrapper>
              </div>
            );
          },
        },
        {
          id: 'Actions',
          headerDefinition: {
            title: null,
          },
          visible: privileges?.canEdit == null || privileges.canEdit,
          className: cn('bg-white'),
          value: (state, updateState) => {
            return (
              <div className={'d-flex justify-content-center align-items-center'}>
                {state.isEdit ? (
                  <>
                    {state.isDeleted ? (
                      <Svg
                        src={ICONS.RESET}
                        color={BootstrapColors.INFO}
                        // @ts-ignore
                        onClick={() => updateState({isDeleted: false})}
                      />
                    ) : (
                      (privileges == undefined || privileges.canDelete == undefined || privileges.canDelete) && (
                        <Svg
                          src={ICONS.BIN}
                          color={BootstrapColors.DANGER}
                          // @ts-ignore
                          onClick={() => handleDelete(state)}
                        />
                      )
                    )}
                    <Svg
                      src={ICONS.CHECK}
                      color={BootstrapColors.SUCCESS}
                      onClick={() => handleSave(state, updateState)}
                    />
                  </>
                ) : (
                  (privileges == undefined || privileges.canEdit == undefined || privileges.canEdit) && (
                    // @ts-ignore
                    <Svg src={ICONS.EDIT} onClick={() => updateState({isEdit: true})} />
                  )
                )}
              </div>
            );
          },
        },
      ]}
    />
  );
};
