import React, {useLayoutEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';
import {LayoutSplashScreen} from '../../../../_metronic/layout/_core/MetronicSplashScreen';
import {useAuthApi} from '../../../hooks/apis/auth-api';
import {useIntl} from '../../../hooks/use-intl';
import {useAuth} from '../../../hooks/use-auth';

export const Logout: React.FC = () => {
  const intl = useIntl();
  const {user, logout} = useAuth();
  const api = useAuthApi();
  useLayoutEffect(() => {
    logout();
    api
      .logout()
      .then()
      .catch(() => {
        intl.formatMessage({id: 'UNEXPECTED_ERROR'});
      });
  }, []);

  return <>{user ? <LayoutSplashScreen /> : <Redirect to={Routes.getLoginRoute()} />}</>;
};
