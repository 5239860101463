import React, {useCallback, useEffect, useState} from 'react';
import {IChapterDto} from '../../../../api/knowledge-base-api/IChapterDto';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {useIntl} from '../../../../hooks/use-intl';
import {IArticleSimplified} from '../../../../api/knowledge-base-api/IArticleDto';
import './chapter-sidebar.scss';
import {useModalCreateEditChapter} from '../../../../components/modals/create-edit-chapter-modal/modal-create-edit-chapter-context';
import {CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {ChapterSidebarItem} from './chapter-sidebar-item';
import {BlockUi} from '../../../../components/block-ui';
import {useModalConfirmAction} from '../../../../components/modals/confirm-modal/modal-confirm-action-context';
import {KnowledgeBaseApi} from '../../../../api/knowledge-base-api/knowledge-base-api';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {changeArrayPosition} from '../../../../utils/utils';
import {useDeepCompareEffect} from '../../../../hooks/use-deep-compare-effect';
import {useAuth} from '../../../../hooks/use-auth';

const ICONS = {
  ARROW: require('../../../../images/svg/Arrow-right.svg'),
  PLUS: require('../../../../images/svg/Plus.svg'),
};

type Props = {
  loading: boolean;
  chapters: Array<IChapterDto>;
  articles: Array<IArticleSimplified>;
  activeSidebarSectionInfo: ActiveSidebarSectionInfo | null;
  setActiveSidebarSectionInfo: (selectedItemInfo: ActiveSidebarSectionInfo) => void;
  fetchSidebarInfo: () => Promise<any>;
};

export type ActiveSidebarSectionInfo = {
  type: 'view' | 'create' | 'edit' | 'default';
  payload: {
    chapterId: number;
    articleId?: number;
  };
};

export const Sidebar: React.FC<Props> = ({
  loading,
  activeSidebarSectionInfo,
  chapters: chaptersFromProps,
  articles,
  setActiveSidebarSectionInfo,
  fetchSidebarInfo,
}) => {
  const intl = useIntl();
  const {isAdmin} = useAuth();
  const api = new KnowledgeBaseApi();
  const [blocking, setBlocking] = useState(false);
  const [expandedStatuses, setExpandedStatuses] = useState<Record<number, boolean>>([]);
  const [chapters, setChapters] = useState<Array<IChapterDto>>(chaptersFromProps);
  const {showCreateEditChapterModal} = useModalCreateEditChapter();
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    if (activeSidebarSectionInfo?.payload.chapterId) {
      setExpandedStatuses(prevState => ({...prevState, [activeSidebarSectionInfo.payload.chapterId]: true}));
    }
  }, [activeSidebarSectionInfo]);

  useDeepCompareEffect(() => {
    setChapters(chaptersFromProps);
  }, [chaptersFromProps]);

  const handleCreateChapter = async () => {
    const modalResult = await showCreateEditChapterModal({type: 'CREATE'});
    if (modalResult.reason === CloseModalReason.OK) {
      await fetchSidebarInfo();
    }
  };

  const handleChapterClick = (accordionId: number) => {
    setExpandedStatuses(prevState => ({
      ...prevState,
      [accordionId]: !prevState[accordionId],
    }));
  };

  const handleEditChapter = async (chapterId: number) => {
    const closeModalResult = await showCreateEditChapterModal({
      type: 'EDIT',
      data: {chapter: chapters.find(ch => ch.id === chapterId) as IChapterDto},
    });
    if (closeModalResult.reason === CloseModalReason.OK) {
      await fetchSidebarInfo();
    }
  };

  const handleDeleteChapter = async (chapterId: number) => {
    if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_CHAPTER'}))) {
      try {
        await api.deleteChapter(chapterId);
        await fetchSidebarInfo();
      } catch (e) {
        const err = e as ApiRequestException;
        toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const changeChapterPosition = useCallback(
    (chapterId: number, toChapterId: number) => {
      const fromIndex = chapters.findIndex(ch => ch.id === chapterId);
      const toIndex = chapters.findIndex(ch => ch.id === toChapterId);
      setChapters(changeArrayPosition(fromIndex, toIndex, chapters));
    },
    [chapters],
  );

  const handleDragChapter = async (chapterId: number, toChapterId: number) => {
    changeChapterPosition(chapterId, toChapterId);
  };

  const handleDropChapter = async (chapterId: number) => {
    try {
      setBlocking(true);
      await api.updateChapterPosition(
        chapterId,
        chapters.findIndex(ch => ch.id === chapterId),
      );
      await fetchSidebarInfo();
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setBlocking(false);
    }
  };
  const renderCreateChapterButton = () => {
    return (
      <Toolbar
        visible={isAdmin}
        className={'w-100'}
        items={[
          {
            title: intl.formatMessage({id: 'CREATE_NEW_CHAPTER'}),
            type: 'BUTTON',
            icon: ICONS.PLUS,
            className: 'btn btn-sm w-100 my-2 font-weight-bolder text-uppercase btn-light-primary',
            onClick: handleCreateChapter,
          },
        ]}
      />
    );
  };

  return (
    <div className={'card chapters-sidebar'}>
      <div className={'card-body p-5'}>
        <div className={'accordion accordion-light accordion-light-borderless'}>
          <BlockUi blocked={loading || blocking} />
          {renderCreateChapterButton()}
          {chapters.map(chapter => {
            const nestedArticles = articles.filter(article => article.chapter_id === chapter.id);
            if (nestedArticles.length === 0 && !isAdmin) {
              return null;
            }
            return (
              <ChapterSidebarItem
                key={chapter.id}
                isExpanded={expandedStatuses[chapter.id]}
                chapter={chapter}
                articles={nestedArticles}
                activeSidebarSectionInfo={activeSidebarSectionInfo}
                setActiveSidebarSectionInfo={setActiveSidebarSectionInfo}
                onChapterClick={handleChapterClick}
                onEditChapter={handleEditChapter}
                onDeleteChapter={handleDeleteChapter}
                onDragChapter={handleDragChapter}
                onDropChapter={handleDropChapter}
                blockSidebar={setBlocking}
                fetchSidebarInfo={fetchSidebarInfo}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
