import React, {PropsWithChildren} from 'react';

export type StepProps<T> = {
  title: string;
  match: T[];
  setNexCallback?: (cb: CallableFunction) => void;
};

type Props<T> = {
  active: T;
};

export function Steps<T>({children, active}: PropsWithChildren<Props<T>>): JSX.Element | null {
  if (Array.isArray(children)) {
    return (children.find(el => (el as JSX.Element).props.match.includes(active)) as JSX.Element) || null;
  }

  return <>{children}</>;
}
