import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {Preloader} from '../../../components/preloader';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useLoading} from '../../../hooks/use-loading';
import {McPayAuthState, McPayTransactionState, useMcPayStatesApi} from '../../../hooks/apis/useMcPayStatesApi';
import {Card, CardBody, CardFooter, CardTitle} from '../../../components/card';
import {TransactionOperation} from '../../../api/DTOs/TransactionOperation';
import {Transaction} from '../../../api/DTOs/Transaction';
import {FinancialOperationsTable} from '../transactions/financial-operations-table';
import {useIntl} from '../../../hooks/use-intl';
import {McPayAuthStateStepsDescription} from './mc-pay-auth-state-steps-description';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';

export const McPayAuthStatePage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();

  const api = useMcPayStatesApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
  });

  const [authState, setAuthState] = useState<McPayAuthState | null>(null);
  const [operations, setOperations] = useState<TransactionOperation[]>([]);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [transactionState, setTransactionState] = useState<McPayTransactionState | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchStates().then();
  }, []);

  useEffect(() => {
    setBreadcrumb(authState?.id);
  }, [authState]);

  const fetchStates = async () => {
    try {
      startLoading('page');
      const result = await api.getAuthState(match.params.id);
      setAuthState(result.data.item);
      setOperations(result.data?.operations ?? []);
      setTransaction(result.data?.transaction ?? null);
      setTransactionState(result.data.transaction_state ?? null);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (authState === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardTitle>{intl.formatMessage({id: 'MC_PAY_AUTH_STATE'})}</CardTitle>
        <CardBody>
          {loadings.page ? (
            <Preloader />
          ) : (
            <>
              <McPayAuthStateStepsDescription
                item={authState}
                transaction={transaction}
                transactionState={transactionState}
                className={'mb-10'}
              />
              {transaction != null && (
                <div className={'my-3'}>
                  <div className={'font-size-h6 font-weight-boldest'}>
                    <FormattedMessage id={'TRANSACTION'} />:{' '}
                    <FormattedMessage id={'TRANSACTION_STATUS_' + transaction?.status} />
                  </div>
                  {transaction.description && (
                    <div>
                      <FormattedMessage id={'DESCRIPTION'} />: {transaction.description}
                    </div>
                  )}
                  {transaction.reject_reason && (
                    <div>
                      <FormattedMessage id={'REJECT_REASON'} />: {transaction.reject_reason}
                    </div>
                  )}
                  {transaction.cancel_reason && (
                    <div>
                      <FormattedMessage id={'CANCEL_REASON'} />: {transaction.cancel_reason}
                    </div>
                  )}
                </div>
              )}
              {operations != null && operations?.length !== 0 && (
                <>
                  <div className={'font-size-h6 font-weight-boldest'}>
                    <FormattedMessage id={'ACCOUNT_OPERATIONS'} />:
                  </div>
                  <FinancialOperationsTable
                    transactionsOperations={operations}
                    transactionsOperationsRelations={null}
                  />
                </>
              )}
            </>
          )}
        </CardBody>

        <CardFooter></CardFooter>
      </Card>
    </>
  );
};
