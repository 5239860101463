import React, {useCallback, useEffect, useState} from 'react';
import {useSubscriptionApi} from '../../../hooks/apis/use-subscription-api';
import {Artist} from '../../../api/DTOs/Artist';
import {useLoading} from '../../../hooks/use-loading';
import {EntityId} from '../../../api/base/BaseEntity';
import {Subscription} from '../../../api/DTOs/Subscription';
import {isNotFoundException} from '../../../api/api-utils';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {match} from '../../../utils/utils';
import cn from 'classnames';
import {ICONS} from '../../../images/images';

type Props = {
  artist: Artist;
};

export const SubscribeToArtistNewsletter: React.FC<Props> = ({artist}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    fetch: true,
    action: false,
  });

  const api = useSubscriptionApi();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (artist.chartmetric_id) {
      fetchSubscription(artist.id).then();
    }
  }, [artist]);

  const fetchSubscription = useCallback(async (artistId: EntityId) => {
    try {
      startLoading('fetch');
      const response = await api.getByArtistId(artistId);
      setSubscription(response.data.item);
    } catch (e) {
      if (!isNotFoundException(e)) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
      setSubscription(null);
    } finally {
      stopLoading('fetch');
    }
  }, []);

  const handleClickSubscribe = useCallback(async () => {
    startLoading('action');
    try {
      if (subscription) {
        await api.remove(subscription.id);
      } else {
        await api.create({artist_id: artist.id});
      }
      fetchSubscription(artist.id).then();
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('action');
    }
  }, [subscription]);

  return (
    <>
      <Toolbar
        items={[
          {
            type: 'BUTTON',
            disabled: loadings.fetch || loadings.action,
            title: match(true, [
              [loadings.fetch, null],
              [subscription == null, intl.formatMessage({id: 'SUBSCRIBE_TO_CHARTMETRIC_NEWSLETTER'})],
              [subscription != null, intl.formatMessage({id: 'UNSUBSCRIBE_FROM_CHARTMETRIC_NEWSLETTER'})],
            ]),
            icon: match(true, [
              [!loadings.fetch && subscription == null, ICONS.PLUS],
              [!loadings.fetch && subscription != null, ICONS.MINUS],
            ]),
            className: cn('btn btn-sm font-weight-bolder', {
              'btn-light-danger': !loadings.fetch && subscription != null,
              'btn-light-success': !loadings.fetch && subscription == null,
              'btn-default': loadings.fetch || loadings.action,
            }),
            loading: loadings.action,
            onClick: handleClickSubscribe,
          },
        ]}
      />
    </>
  );
};
