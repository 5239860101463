import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Preloader} from '../../../components/preloader';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {Transaction, TransactionStatus} from '../../../api/DTOs/Transaction';
import {useLoading} from '../../../hooks/use-loading';
import {TransactionPrivileges, useTransactionsApi} from '../../../hooks/apis/use-transactions-api';
import {useIntl} from '../../../hooks/use-intl';
import {FormattedMessage} from 'react-intl';
import {TransactionOperation} from '../../../api/DTOs/TransactionOperation';
import {FinancialOperationsTable} from './financial-operations-table';
import {ICONS} from '../../../images/images';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useTransactionActionHandler} from './use-transactions-action-handler';
import {TransactionAction} from './transaction-action-types';
import {BaseListPage} from '../../base/base-list-page';
import {useLocalPaginator} from '../../../hooks/use-local-paginator';

export const TransactionPage: React.FC<any> = () => {
  const intl = useIntl();

  const {operationId, transactionId} = useParams<{transactionId: string; operationId: string}>();

  const api = useTransactionsApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    action: false,
  });

  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [privileges, setPrivileges] = useState<TransactionPrivileges | null>(null);

  const [allOperations, setAllOperations] = useState<Array<TransactionOperation>>([]);
  const {getPageData: fetchOperations, currentPageItems, currentPaginationInfo} = useLocalPaginator(allOperations);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchTransactionInfo().then();
  }, []);

  useEffect(() => {
    if (transaction && allOperations) {
      // TODO: когда на сервере появится генерируемое имя для операции надо вывести его здесь.
      setBreadcrumb([
        `${intl.formatMessage({id: 'FINANCIAL_OPERATION'})} №${allOperations
          .find(op => op.id == operationId)
          ?.id.toString()}`,
        `${intl.formatMessage({id: 'TRANSACTION'})} №${transaction.id}`,
      ]);
    }
  }, [transaction, allOperations]);

  const fetchTransactionInfo = useCallback(async () => {
    try {
      startLoading('page');
      const result = await api.get(transactionId);
      setPrivileges(result.privileges);
      setAllOperations(result.relations.operations);
      setTransaction(result.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  }, [setPrivileges, setAllOperations, setTransaction]);

  const {handleTransactionAction} = useTransactionActionHandler(fetchTransactionInfo);
  const handleAction = useCallback(
    (action: TransactionAction.CANCEL | TransactionAction.CONFIRM | TransactionAction.REJECT) => {
      return () => handleTransactionAction({type: action, data: {entityId: transactionId}});
    },
    [handleTransactionAction],
  );

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (transaction === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  let statusTextColorVariant;
  switch (transaction.status) {
    case TransactionStatus.CANCELED:
      statusTextColorVariant = 'warning';
      break;
    case TransactionStatus.CONFIRMED:
      statusTextColorVariant = 'success';
      break;
    case TransactionStatus.WAITING_CONFIRM:
      statusTextColorVariant = 'primary';
      break;
    case TransactionStatus.REJECTED:
      statusTextColorVariant = 'danger';
      break;
  }

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap mb-5'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h5 font-weight-bolder mb-1'}>
                <FormattedMessage id={'STATUS'} />:{' '}
                <span className={'text-' + statusTextColorVariant}>
                  <FormattedMessage id={'TRANSACTION_STATUS_' + transaction?.status} />
                </span>
              </p>

              {transaction?.description && (
                <p className={'font-size-md font-weight-bolder mb-0'}>
                  <FormattedMessage id={'DESCRIPTION'} />: <span>{transaction.description}</span>
                </p>
              )}

              {transaction.reject_reason && (
                <p className={'font-size-md font-weight-bolder mt-3'}>
                  <FormattedMessage id={'REJECT_REASON'} />:{' '}
                  <span className={'text-muted'}>{transaction.reject_reason}</span>
                </p>
              )}

              {transaction.cancel_reason && (
                <p className={'font-size-md font-weight-bolder mt-3'}>
                  <FormattedMessage id={'CANCEL_REASON'} />:{' '}
                  <span className={'text-muted'}>{transaction.cancel_reason}</span>
                </p>
              )}
            </div>
            <div>
              <div className={'d-flex justify-content-center flex-column'}>
                <div className={'font-weight-bolder'}>
                  <FormattedMessage id={'CREATED_AT'} />
                </div>
                <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                  {intl.formatDate(transaction?.created_at, {
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className={'separator separator-solid my-7'} />
          <Toolbar
            items={[
              {
                title: intl.formatMessage({id: 'PERFORM'}),
                type: 'BUTTON',
                icon: ICONS.CHECK,
                disabled: loadings.action || !privileges?.can_reject,
                className: 'btn btn-light-success font-weight-bolder',
                onClick: handleAction(TransactionAction.CONFIRM),
              },
              {
                title: intl.formatMessage({id: 'REJECT'}),
                type: 'BUTTON',
                icon: ICONS.CROSS,
                className: 'btn btn-light-danger font-weight-bolder',
                disabled: loadings.action || !privileges?.can_reject,
                onClick: handleAction(TransactionAction.REJECT),
              },
              {
                title: intl.formatMessage({id: 'CANCEL'}),
                type: 'BUTTON',
                icon: ICONS.UNDO,
                className: 'btn btn-light-warning font-weight-bolder',
                disabled: loadings.action || !privileges?.can_cancel,
                onClick: handleAction(TransactionAction.CANCEL),
              },
            ]}
          />
          <div className={'separator separator-solid my-7'} />
          <div className={'font-size-h4 font-weight-boldest my-3'}>
            {intl.formatMessage({id: 'TRANSACTION_OPERATIONS'})}
          </div>
          <BaseListPage
            hideHeader
            loading={loadings.page}
            pagination={{info: currentPaginationInfo}}
            className={{
              card: 'box-shadow-none',
              cardBody: 'p-0',
            }}
            fetchData={fetchOperations}>
            <FinancialOperationsTable
              transactionsOperations={currentPageItems}
              transactionsOperationsRelations={null}
            />
          </BaseListPage>
        </div>
      </div>
    </>
  );
};
