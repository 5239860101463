import React, {PropsWithChildren, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {trimStringWithEllipsis} from '../../utils/utils';
import {Video} from '../../api/DTOs/Video';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapColors, BootstrapSizes} from '../../../styles/bootstap-colors';
import {Toolbar, ToolBarItem} from '../../components/card-toolbar/Toolbar';
import {Artist} from '../../api/DTOs/Artist';

interface Props {
  video: Video;
  artists?: Array<Artist>;
  toolBarConfig?: Array<ToolBarItem>;
}

const MAX_DESCRIPTION_LENGTH = 200;
export const VideoPreview: React.FC<PropsWithChildren<Props>> = ({video, toolBarConfig}) => {
  const [isCutted, setCutted] = useState<boolean>(true);

  const renderButtons = () => {
    if (toolBarConfig == null || toolBarConfig.length === 0 || !toolBarConfig.some(conf => conf.visible === true)) {
      return null;
    }

    return (
      <>
        <div className={'separator separator-solid mb-7'} />
        <Toolbar items={toolBarConfig} />
        <div className={'separator separator-solid my-7'} />
      </>
    );
  };

  return (
    <div className={`card card-custom gutter-b`}>
      <div className='card-header d-flex border-0 w-100 pt-6'>
        <div className={'font-weight-bolder font-size-h1'}>
          {video.is_explicit && <Svg src={ICONS.EXPLICIT} color={BootstrapColors.DARK} size={BootstrapSizes.X2} />}
          {video.title} <span className={'text-muted font-size-h6'}>({video.age_limit}+)</span>
        </div>
      </div>
      <div className='card-body pt-0'>
        {renderButtons()}
        {video.description.trim().length !== 0 && (
          <p className='text-muted font-weight-bold font-size-lg save-line-and-tabulation'>
            {isCutted ? trimStringWithEllipsis(video.description, MAX_DESCRIPTION_LENGTH) : video.description}
            &nbsp;
            {video.description.length >= MAX_DESCRIPTION_LENGTH && (
              <a href='#' onClick={() => setCutted(prevState => !prevState)}>
                <FormattedMessage id={isCutted ? 'SHOW_ALL' : 'HIDE'} />
              </a>
            )}
          </p>
        )}
      </div>
    </div>
  );
};
