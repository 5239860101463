import {useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {BaseEntity, EntityId} from '../../api/base/BaseEntity';

export interface ContractualDocument extends BaseEntity {
  id: EntityId;
  file_id: EntityId;
  entity_id: EntityId;
  contractor_id: EntityId;
  entity_type: ContractualDocumentType;
  comment: string | null;
}

export enum ContractualDocumentType {
  CONTRACT = 'CONTRACT',
  SUP_AGREEMENT = 'SUP_AGREEMENT'
}


export function useContractualDocumentsApi() {
  const config = useRef<EntityApiHookParams>({
    prefixUrl: 'contractual-document-files', isAdmin: false,
    uploadConfig: {
      signedUrlRoute: 'docs-pre-signed-url',
    },
  });
  const api = useEntityApi<ContractualDocument>(config.current);
  return {
    ...api,
  };
}
