import React from 'react';
import {Card, CardBody} from '../../components/card';
import cn from 'classnames';
import {useIntl} from '../../hooks/use-intl';
import {ILLUSTRATIONS} from '../../images/images';

export const RevenuesMarketingBlock: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <Card hideFooter hideHeader className={{body: 'p-0'}} style={{boxShadow: 'none'}}>
        <CardBody>
          <div className={cn('card-px text-center')}>
            <h2 className='font-weight-boldest mb-10'>
              {intl.formatMessage({id: 'FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_TITLE'})}
            </h2>
            <p className='text-gray-400 font-weight-bolder font-size-lg mb-10' style={{whiteSpace: 'pre-wrap'}}>
              {intl.formatMessage({id: 'FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_DESCRIPTION'})}
            </p>
          </div>
          <div className='text-center'>
            <img className={'mw-100 mh-45vh '} src={ILLUSTRATIONS.CREDIT_CARD} alt='nones' />
          </div>
        </CardBody>
      </Card>
    </>
  );
};
