import {BaseEntity, EntityId, float} from '../base/BaseEntity';
import {BootstrapColors} from '../../../styles/bootstap-colors';

export enum ContractorType {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL = 'LEGAL',
  ENTREPRENEUR = 'ENTREPRENEUR',
}

export enum Jurisdiction {
  RUSSIA = 'RUSSIA',
  USA = 'USA',
  UKRAINE = 'UKRAINE',
  BELARUS = 'BELARUS',
  KAZAKHSTAN = 'KAZAKHSTAN',
  ARMENIA = 'ARMENIA',
  GEORGIA = 'GEORGIA',
  ABKHAZIA = 'ABKHAZIA',
  AZERBAIJAN = 'AZERBAIJAN',
  CANADA = 'CANADA',
  GERMANY = 'GERMANY',
  ESTONIA = 'ESTONIA',
  FRANCE = 'FRANCE',
  GREATBRITAIN = 'GREATBRITAIN',
  ISRAEL = 'ISRAEL',
  KYRGYZSTAN = 'KYRGYZSTAN',
  LITHUANIA = 'LITHUANIA',
  LATVIA = 'LATVIA',
  MOLDAVIA = 'MOLDAVIA',
  SOUTHOSSETIA = 'SOUTHOSSETIA',
  TAJIKISTAN = 'TAJIKISTAN',
  TURKMENISTAN = 'TURKMENISTAN',
  UZBEKISTAN = 'UZBEKISTAN',
}

export enum ContractorDataValidationStatus {
  NEED_FILL_CONTRACTOR_REQUISITES = 'NEED_FILL_CONTRACTOR_REQUISITES',
  WAITING_ADMINISTRATOR_CONFIRM = 'WAITING_ADMINISTRATOR_CONFIRM',
  NEED_FIX_REQUIREMENTS = 'NEED_FIX_REQUIREMENTS',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export const UPDATE_CONTRACTOR_COLOR_MAP = {
  [ContractorDataValidationStatus.APPROVED]: BootstrapColors.SUCCESS,
  [ContractorDataValidationStatus.REJECTED]: BootstrapColors.DANGER,
  [ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS]: BootstrapColors.WARNING,
  [ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES]: BootstrapColors.INFO,
  [ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM]: BootstrapColors.PRIMARY,
};

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
}

export interface Contractor extends BaseEntity {
  code: string;
  type: ContractorType;
  calculated_name: string;
  jurisdiction: Jurisdiction;

  is_our: boolean;
  owner_user_id: EntityId | null;

  individual_is_self_employed: boolean;
  individual_name_last: string | null;
  individual_name_last_en: string | null;
  individual_name_first: string | null;
  individual_name_first_en: string | null;
  individual_name_middle: string | null;
  individual_name_middle_en: string | null;
  individual_date_of_birth: string | null;
  individual_passport_issued_by: string | null;
  individual_passport_issued_date: string | null;
  individual_passport_division_code: string | null;
  individual_passport_number: string | null;
  individual_registration_address: string | null;
  individual_registration_address_en: string | null;
  individual_inn: string | null;
  individual_snils: string | null;

  entrepreneur_name_last: string | null;
  entrepreneur_name_last_en: string | null;
  entrepreneur_name_first: string | null;
  entrepreneur_name_first_en: string | null;
  entrepreneur_name_middle: string | null;
  entrepreneur_name_middle_en: string | null;
  entrepreneur_ogrnip: string | null;
  entrepreneur_inn: string | null;
  entrepreneur_address: string | null;
  entrepreneur_address_en: string | null;

  legal_name: string | null;
  legal_name_en: string | null;
  legal_inn: string | null;
  legal_kpp: string | null;
  legal_ogrn: string | null;
  legal_address: string | null;
  legal_address_en: string | null;
  legal_signatory_name_last: string | null;
  legal_signatory_name_last_en: string | null;
  legal_signatory_name_first: string | null;
  legal_signatory_name_first_en: string | null;
  legal_signatory_name_middle: string | null;
  legal_signatory_name_middle_en: string | null;
  legal_signatory_position: string | null;
  legal_signatory_acts_by: string | null;

  bank_account_currency: Currency | null;
  bank_info: string | null;

  rub_bank_account_number: string | null;
  rub_bank_correspondent_account_number: string | null;
  rub_bank_name: string | null;
  rub_bank_name_en: string | null;
  rub_bank_address: string | null;
  rub_bank_address_en: string | null;
  rub_bank_number: string | null;
  rub_bank_inn: string | null;

  usd_ben_name: string | null;
  usd_ben_bank_account: string | null;
  usd_ben_bank_transit_account: string | null;
  usd_ben_bank_name: string | null;
  usd_ben_bank_swift: string | null;
  usd_ben_bank_address: string | null;
  usd_intermediate_name: string | null;
  usd_intermediate_swift: string | null;
  usd_intermediate_account: string | null;

  vat: float | null;
  vat_payer: boolean | null;

  data_validation_status: ContractorDataValidationStatus;
  data_validation_requirements: string | null;
  data_validation_reject_reason: string | null;
}
