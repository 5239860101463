import React, {useState} from 'react';
import {Renderer} from '../../types/types';
import cn from 'classnames';

export type TabProps = {
  key: string;
  title: string | Renderer;
  value: Renderer;
};

type Props = {
  tabs: TabProps[];
};

export const TabsComponent: React.FC<Props> = ({tabs}) => {
  const [currentKey, setCurrentKey] = useState<string | null>(tabs[0].key);

  return (
    <>
      <ul className='nav nav-tabs' style={{border: 'none'}}>
        {tabs.map(a => {
          return (
            <li key={a.key} onClick={() => setCurrentKey(a.key)} className='nav-item cursor-pointer'>
              <div
                className={cn('nav-link', {
                  active: currentKey === a.key,
                })}>
                <span className={cn('nav-text font-weight-bolder')}>{a.title}</span>
              </div>
            </li>
          );
        })}
      </ul>
      <div
        style={{
          boxShadow: 'none',
          borderStartStartRadius: '0px',
          // HACK, поскольку нормально borderTop не убирается
          borderLeft: '1px solid #EBEDF3',
          borderRight: '1px solid #EBEDF3',
          borderBottom: '1px solid #EBEDF3',
          borderTop: '1px solid #EBEDF3',
          padding: '2rem',
        }}>
        {tabs
          .filter(({key}) => key === currentKey)
          .map(v => {
            return <React.Fragment key={v.key}>{v.value}</React.Fragment>;
          })}
      </div>
    </>
  );
};
