import React from 'react';
import {DtoFieldInput} from '../../Inputs/DtoFieldInput';
import {InputSelect, SelectOptions} from '../../Inputs/InputSelect';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {InputText} from '../../Inputs/InputText';
import {CurrencyPicker} from '../../currency-picker';
import {DateFormat, InputDatepicker} from '../../Inputs/InputDatepicker';
import {InputTextArea} from '../../Inputs/InputTextArea';
import {
  CreateReturnYoutubeTaxToYoutubeContractorFields,
  CreateTransactionToOurOrganizationFields,
} from '../../../hooks/apis/use-transactions-api';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {useIntl} from '../../../hooks/use-intl';
import {PartialNullable} from '../../../../types/types';
import {createSlot} from 'react-slotify';

type Props = {
  hideContracts?: boolean;
  hideContractors?: boolean;
  entity: PartialNullable<CreateReturnYoutubeTaxToYoutubeContractorFields | CreateTransactionToOurOrganizationFields>;
  onChange: UpdateFields<CreateReturnYoutubeTaxToYoutubeContractorFields | CreateTransactionToOurOrganizationFields>;
  validationErrors: ValidationErrorsType;
  error?: string | null;
  contractors?: SelectOptions;
  contracts?: SelectOptions;
  loadings: {contractors: boolean; contracts: boolean};
};

export const AdditionalFields = createSlot();
export const CreateTransactionFields: React.FC<Props> = ({
  hideContractors,
  hideContracts,
  entity,
  validationErrors,
  loadings,
  contracts,
  contractors,
  onChange,
  children,
}) => {
  const intl = useIntl();
  return (
    <>
      <div className='form-group'>
        {!hideContractors && (
          <DtoFieldInput
            dto={entity}
            dtoKey={'contractor_id'}
            component={InputSelect}
            validationsErrors={validationErrors}
            innerProps={{
              required: true,
              placeholder: '',
              isClearable: true,
              isLoading: loadings.contractors,
              label: intl.formatMessage({id: 'CONTRACTOR'}),
              className: 'mb-2',
              value: entity.contractor_id,
              options: contractors ?? [],
              onChange: value => onChange({contractor_id: value}),
            }}
          />
        )}

        {!hideContracts && (
          <DtoFieldInput
            dto={entity}
            dtoKey={'contract_id'}
            component={InputSelect}
            validationsErrors={validationErrors}
            visible={!!entity.contractor_id}
            innerProps={{
              required: true,
              placeholder: '',
              isClearable: true,
              isLoading: loadings.contracts,
              label: intl.formatMessage({id: 'CONTRACT'}),
              className: 'mb-2',
              value: entity.contract_id,
              options: contracts ?? [],
              onChange: value => onChange({contract_id: value}),
            }}
          />
        )}

        <AdditionalFields.Renderer childs={children} />

        <CurrencyPicker
          validationError={getValidationErrorMessage('currency', validationErrors)}
          currency={entity.currency}
          label={intl.formatMessage({id: 'CURRENCY'})}
          onChange={currency => onChange({currency})}
        />

        <ValidateErrorWrapper message={getValidationErrorMessage('value', validationErrors)}>
          <InputText
            required
            type={'number'}
            defaultValue={0}
            label={`${intl.formatMessage({id: 'SUM'})} ${entity?.currency ? `(${entity.currency})` : ''}`}
            hasError={!!getValidationErrorMessage('value', validationErrors)}
            classNames={'form-control'}
            onChange={e => onChange({value: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={getValidationErrorMessage(['period_month', 'period_year'][0], validationErrors)}>
          <InputDatepicker
            required
            showMonthYearPicker
            format={DateFormat.MM_YYYY}
            hasError={!!getValidationErrorMessage(['period_month', 'period_year'][0], validationErrors)}
            value={
              entity?.period_year != null && entity.period_month != null
                ? new Date(entity?.period_year, entity?.period_month)
                : null
            }
            label={intl.formatMessage({id: 'PERIOD'})}
            className={'mb-2'}
            onChange={value => {
              onChange({
                period_month: value?.getMonth() ?? null,
                period_year: value?.getFullYear() ?? null,
              });
            }}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={getValidationErrorMessage('description', validationErrors)}>
          <InputTextArea
            required
            defaultValue={''}
            label={intl.formatMessage({id: 'DESCRIPTION'})}
            hasError={!!getValidationErrorMessage('description', validationErrors)}
            className={'form-control'}
            onChange={e => onChange({description: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>
      </div>
    </>
  );
};
