import {BaseEntity, EntityId} from '../base/BaseEntity';

export enum UserType {
  ADMIN = 'ADMIN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export interface User extends BaseEntity {
  email: string;
  phone: string | null;
  phone_country: string | null;
  name_last: string | null;
  name_first: string | null;
  name_middle: string | null;

  position: string | null;

  type: UserType;
  is_system: boolean;
  contractor_id: EntityId | null;
  y_channel_id: string | null;

  hasPaymentRequisites: boolean;
  hasMcPayCredentials: boolean;
  hasSignature: boolean;
  last_language_code: string;

  blocked_at: string | null;
}
