import React from 'react';
import cn from 'classnames';

export enum SeparatorType {
  SOLID = 'solid',
  DASHED = 'dashed',
}

type Props = {
  position?: 'horizontal' | 'vertical';
  lineType?: SeparatorType;
  className?: string;
};

export const Separator: React.FC<Props> = ({lineType, position, className}) => {
  return (
    <>
      {position && position === 'vertical' ? (
        <div style={{borderLeft: '.5px solid #EBEDF3', borderRadius: '2rem'}} />
      ) : (
        <div
          className={cn('separator', className, {
            'separator-solid': lineType === SeparatorType.SOLID || lineType === undefined,
            'separator-dashed': lineType === SeparatorType.DASHED,
          })}
        />
      )}
    </>
  );
};
