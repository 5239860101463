import _ from 'lodash';

/**
 * Симуляция серверной пагинации на клиенте, чтобы не выводить на клиенте огромное кол-во записей сразу.
 * @param data
 * @param itemsPerPage
 */
import {useCallback, useEffect, useMemo, useState} from 'react';
import {IPaginationInfo} from '../api/Paginator';
import {IQueryParams} from '../api/DTOs/IFilterDtos';
import {useDeepCompareEffect} from './use-deep-compare-effect';

export function useLocalPaginator<T>(data: Array<T>, itemsPerPage = 50) {
  const [storedData, setStoredData] = useState<Array<T>>(data ?? []);

  const pages = useMemo(() => _.chunk(storedData, itemsPerPage), [storedData]);
  const totalItemsCount = useMemo(() => storedData.length, [storedData]);
  const totalPagesCount = useMemo(() => {
    return pages.length;
  }, [pages]);

  const [currentParams, setCurrentParams] = useState<IQueryParams | null>(null);
  const [currentPageItems, setCurrentPageItems] = useState<Array<T>>([]);
  const [currentPaginationInfo, setCurrentPaginationInfo] = useState<IPaginationInfo | null>(null);

  useDeepCompareEffect(() => {
    setStoredData(data);
  }, [data]);

  useEffect(() => {
    fetchPageItems(currentParams).then();
  }, [pages]);

  const fetchPageItems = useCallback(
    (params?: IQueryParams | null) => {
      let pageNumber = params?.page ?? 1;
      return new Promise<{items: Array<T>; paginationInfo: IPaginationInfo}>(resolve => {
        if (pageNumber - 1 < 0) {
          pageNumber = 1;
        }

        if (pageNumber - 1 > totalPagesCount || pageNumber > totalPagesCount) {
          pageNumber = totalPagesCount;
        }

        const items = pages[pageNumber - 1] ?? ([] as unknown as T[]);
        const paginationInfo = {
          perPage: itemsPerPage,
          currentPageNumber: pageNumber,
          totalPagesCount,
          totalItemsCount,
          aggregationValues: null,
        };

        setCurrentParams(params ?? null);
        setCurrentPageItems(items);
        setCurrentPaginationInfo(paginationInfo);
        resolve({items, paginationInfo});
      });
    },
    [itemsPerPage, storedData],
  );

  return {
    allItems: storedData,
    getPageData: fetchPageItems,
    totalItemsCount,
    totalPagesCount,

    currentPageItems,
    currentPaginationInfo,
  };
}
