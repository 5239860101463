import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';
import {LabelWithDescription} from './Inputs/LabelWithDescription';
import {createUrlFromBlob} from '../utils/file-utils';
import {useIntl} from '../hooks/use-intl';

interface IFileUploaderProps {
  label?: string;
  descriptionLabel?: string;
  acceptFileExtension?: string | string[];
  className?: string;
  required?: boolean;
  error?: string;
  validateFile?: (file: File) => Promise<any>;

  onSelect(file: File, metadata: AttachmentMetadata): void;
}

export type AttachmentMetadata = {
  filename: string;
  size: number;
  url: string;
};

export const FileUploader = ({
  onSelect,
  label,
  descriptionLabel,
  required,
  acceptFileExtension,
  validateFile,
  className,
  error,
}: IFileUploaderProps) => {
  const intl = useIntl();
  const [validationError, setValidationError] = useState<string | null>(null);
  const fileValidator = async (file: File) => {
    if (validateFile) {
      return await validateFile(file);
    }

    return null;
  };

  const handleDropAccepted = async (files: Array<File>) => {
    const file = files[0];
    try {
      await fileValidator(file);
      setValidationError(null);
      onSelect(file, {filename: file.name, size: file.size, url: createUrlFromBlob(file)});
    } catch (e) {
      setValidationError(intl.formatMessage({id: e.message}));
    }
  };

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: acceptFileExtension,
    onDropAccepted: handleDropAccepted,
  });

  return (
    <div className={cn(className)}>
      <LabelWithDescription required={required} label={label} description={descriptionLabel} />
      <div
        {...getRootProps()}
        className={cn('dropzone dropzone-default ribbon ribbon-top', {
          'dropzone-primary': error === undefined || validationError === null,
          'dropzone-danger': error || validationError,
        })}>
        {
          <div className='dropzone-msg dz-message needsclick d-flex align-items-center justify-content-center'>
            <span className='dropzone-msg-title'>
              <FormattedMessage id={'DROP_FILES'} />
            </span>
            <input {...getInputProps()} />
          </div>
        }
      </div>
      <div>{error && <span className={'text-danger'}>{error}</span>}</div>
      <div>{validationError && <span className={'text-danger'}>{validationError}</span>}</div>
    </div>
  );
};
