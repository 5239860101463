import React, {useEffect, useMemo, useState} from 'react';
import {PartialNullable, UpdateEntity} from '../../../../../types/types';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {useIntl} from '../../../../hooks/use-intl';
import {AvailableContractChange, YoutubeChannelInContract} from '../../../../api/contract-api/Contract';
import cn from 'classnames';
import {ChannelsBlock} from '../channels-block';
import {EntityId} from '../../../../api/base/BaseEntity';
import {DataSetValidationErrorsMap, getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {AlertCustom} from '../../../../modules/Auth/component/alert';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';

type Props = {
  supAgreement: PartialNullable<SupAgreement>;
  onUpdate?: UpdateEntity<PartialNullable<SupAgreement>> | null;
  channelsFromContractState?: YoutubeChannelInContract[];
  viewModeEnable?: boolean;
  validationErrors?: ValidationErrorsType<SupAgreement>;
  dataSetValidationErrors?: DataSetValidationErrorsMap<SupAgreement, YoutubeChannelInContract>;
};

export const SupAgreementChannels: React.FC<Props> = ({
  supAgreement,
  dataSetValidationErrors,
  validationErrors,
  viewModeEnable,
  channelsFromContractState,
  onUpdate,
}) => {
  const intl = useIntl();
  const [currentEditChannelMode, setEditChannelMode] = useState<AvailableContractChange | null>(null);

  const tabs = useMemo(() => {
    return [
      {
        key: AvailableContractChange.is_change_youtube_channels,
        hasError:
          dataSetValidationErrors?.change_youtube_channels != null || validationErrors?.change_youtube_channels != null,
        count: supAgreement?.change_youtube_channels?.length ?? 0,
      },
      {
        key: AvailableContractChange.is_add_youtube_channels,
        hasError:
          dataSetValidationErrors?.add_youtube_channels != null || validationErrors?.add_youtube_channels != null,
        count: supAgreement?.add_youtube_channels?.length ?? 0,
      },
      {
        key: AvailableContractChange.is_delete_youtube_channels,
        hasError:
          dataSetValidationErrors?.delete_youtube_channels != null || validationErrors?.delete_youtube_channels != null,
        count: supAgreement?.delete_youtube_channels?.length ?? 0,
      },
    ].filter(s => supAgreement[s.key as keyof SupAgreement]);
  }, [supAgreement, dataSetValidationErrors]);

  const channelsRatePercentMap = useMemo(() => {
    const map: Record<EntityId, any> = {};
    channelsFromContractState?.forEach(i => {
      map[i.y_channel_id] = i.rate_percent;
    });

    return map;
  }, [channelsFromContractState]);

  useEffect(() => {
    if (tabs.length != 0) {
      if (currentEditChannelMode == null) {
        setEditChannelMode(tabs[0].key);
      }

      if (currentEditChannelMode != null && tabs.find(s => s.key === currentEditChannelMode) == null) {
        setEditChannelMode(tabs[0].key);
      }
    }
  }, [tabs, currentEditChannelMode, supAgreement]);

  return (
    <>
      {tabs.length > 0 && (
        <>
          <ul className='nav nav-tabs' style={{border: 'none'}}>
            {tabs.map(a => {
              return (
                <li key={a.key} onClick={() => setEditChannelMode(a.key)} className='nav-item cursor-pointer'>
                  <div className={cn('nav-link', {active: currentEditChannelMode === a.key})}>
                    <span
                      className={cn('nav-text font-weight-bolder', {
                        'text-danger': a.hasError,
                      })}>
                      {intl.formatMessage({id: 'SUP_AGREEMENTS_CHANGES_' + a.key})} ({a.count})
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            style={{
              boxShadow: 'none',
              borderStartStartRadius: '0px',
              // HACK, поскольку нормально borderTop не убирается
              borderLeft: '1px solid #EBEDF3',
              borderRight: '1px solid #EBEDF3',
              borderBottom: '1px solid #EBEDF3',
              borderTop: '1px solid #EBEDF3',
              padding: '2rem',
            }}>
            {supAgreement.is_add_youtube_channels &&
              currentEditChannelMode === AvailableContractChange.is_add_youtube_channels && (
                <>
                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'YOUTUBE_CHANNELS'})}</h3>
                  <AlertCustom
                    text={getValidationErrorMessage('add_youtube_channels', validationErrors)}
                    type={BootstrapColors.LIGHT_DANGER}
                    iconClassName={'svg-icon-danger'}
                  />
                  <ChannelsBlock
                    channels={supAgreement.add_youtube_channels ?? []}
                    validationErrors={dataSetValidationErrors?.add_youtube_channels ?? null}
                    onSave={items => onUpdate?.({add_youtube_channels: items})}
                    privileges={{
                      canDelete: viewModeEnable == null || !viewModeEnable,
                      canEdit: viewModeEnable == null || !viewModeEnable,
                      canCreate: viewModeEnable == null || !viewModeEnable,
                    }}
                  />
                </>
              )}

            {supAgreement.is_change_youtube_channels &&
              currentEditChannelMode === AvailableContractChange.is_change_youtube_channels && (
                <>
                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'YOUTUBE_CHANNELS'})}</h3>
                  <AlertCustom
                    text={getValidationErrorMessage('change_youtube_channels', validationErrors)}
                    type={BootstrapColors.LIGHT_DANGER}
                    iconClassName={'svg-icon-danger'}
                  />
                  <ChannelsBlock
                    showAdditionalButtons={!viewModeEnable}
                    channels={supAgreement.change_youtube_channels ?? []}
                    ratePercentsMap={channelsRatePercentMap}
                    validationErrors={dataSetValidationErrors?.change_youtube_channels ?? null}
                    onSave={items => onUpdate?.({change_youtube_channels: items})}
                    privileges={{
                      canDelete: viewModeEnable == null || !viewModeEnable,
                      canEdit: viewModeEnable == null || !viewModeEnable,
                      canCreate: viewModeEnable == null || !viewModeEnable,
                    }}
                  />
                </>
              )}

            {supAgreement.is_delete_youtube_channels &&
              currentEditChannelMode === AvailableContractChange.is_delete_youtube_channels && (
                <>
                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'YOUTUBE_CHANNELS'})}</h3>
                  <AlertCustom
                    text={getValidationErrorMessage('delete_youtube_channels', validationErrors)}
                    type={BootstrapColors.LIGHT_DANGER}
                    iconClassName={'svg-icon-danger'}
                  />
                  <ChannelsBlock
                    channels={supAgreement.delete_youtube_channels ?? []}
                    validationErrors={dataSetValidationErrors?.delete_youtube_channels ?? null}
                    onSave={items => onUpdate?.({delete_youtube_channels: items})}
                    privileges={{
                      canDelete: viewModeEnable == null || !viewModeEnable,
                      canEdit: viewModeEnable == null || !viewModeEnable,
                      canCreate: viewModeEnable == null || !viewModeEnable,
                    }}
                  />
                </>
              )}
          </div>
        </>
      )}
    </>
  );
};
