import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalRejectReason, ModalRejectReasonConfig} from './modal-reject-reason';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ValidationException} from '../../../api/exceptions/ValidationException';

type ShowModalPayload = {
  modalConfiguration?: ModalRejectReasonConfig;
  onSubmit: (rejectReason: string) => Promise<any>;
};

interface IModalRejectReasonContext {
  modalRejectReasonVisible: boolean;

  showRejectReasonModal(arg: ShowModalPayload): Promise<CloseModalEvent<string>>;
}

// @ts-ignore
const ModalRejectReasonContext = createContext<IModalRejectReasonContext>();

let closeResolver: ((data: CloseModalEvent<string>) => any) | null = null;
export const ModalRejectReasonProvider = ({children}: any) => {
  const intl = useIntl();

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [config, setConfig] = useState<ShowModalPayload | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const showModal = async (payload: ShowModalPayload) => {
    setConfig(payload);
    setVisible(true);
    return new Promise<CloseModalEvent<string>>(resolve => {
      closeResolver = resolve;
    });
  };

  const resetModalState = () => {
    setReason('');
    setError(null);
    setValidationError(null);
    setConfig(null);
  };

  const handleHideModal = () => {
    setVisible(false);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    if (!config) {
      throw new Error('Config should be filled');
    }

    try {
      setError(null);
      setValidationError(null);
      await config.onSubmit(reason);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalRejectReasonContext = {
    modalRejectReasonVisible: visible,
    showRejectReasonModal: showModal,
  };

  return (
    <ModalRejectReasonContext.Provider value={value}>
      {children}
      <ModalRejectReason
        error={error}
        reason={reason}
        visible={visible}
        validationErrors={validationErrors}
        modalConfiguration={config?.modalConfiguration}
        onExited={resetModalState}
        onReasonChange={r => setReason(r)}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalRejectReasonContext.Provider>
  );
};

export const useModalRejectReason = () => {
  return useContext(ModalRejectReasonContext);
};
