import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import store, {persistor} from './redux/store';
import './index.scss';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './types/extensions/index';
import 'react-datepicker/dist/react-datepicker.css';
import {MetronicI18nProvider} from './localization';
import {startCheckingForServerUpdates} from './updater/updater';
import {MetronicLayoutProvider} from './_metronic/layout/_core/MetronicLayout';
import {MetronicSubheaderProvider} from './_metronic/layout/_core/MetronicSubheader';
import {MetronicSplashScreenProvider} from './_metronic/layout/_core/MetronicSplashScreen';

const {PUBLIC_URL} = process.env;

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root'),
);

// noinspection JSIgnoredPromiseFromCall
startCheckingForServerUpdates();
