import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {PartialNullable} from '../../../types/types';
import {ApplicationSettings} from '../../api/DTOs/ApplicationSettings';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';
import {useRef} from 'react';
import {EntityApiHookParams} from '../use-entity-api';

export function useApplicationSettingsApi() {
  const config = useRef({prefixUrl: 'application-settings', isAdmin: false});
  return useRoutes(config.current);
}

function useRoutes(params: EntityApiHookParams) {
  const api = useBaseEndpointApi();
  const update = async (
    payload: PartialNullable<ApplicationSettings>,
  ): Promise<BaseItemResponse<ApplicationSettings>> => {
    return await api.post(`${params.isAdmin ? 'admin/' : ''}${params.prefixUrl}`, {
      fields: {
        ...payload,
      },
    });
  };

  const getAll = async () => {
    return await api.get<BaseItemResponse<ApplicationSettings>>(`${params.isAdmin ? 'admin/' : ''}${params.prefixUrl}`);
  };

  return {
    update,
    getAll,
  };
}
