import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, FormattedTime, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {PaymentRequest, PaymentRequestDestination, PaymentRequestStatus} from '../../../api/DTOs/PaymentRequest';
import {DefaultTable} from '../../../components/default-table';
import {AdminRoutes, CommonRoutes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {TableUtils} from '../../../components/table-utils';

interface IUsersTableProps {
  paymentRequests: Array<PaymentRequest>;
}

export const PaymentRequestsTable = ({paymentRequests}: IUsersTableProps) => {
  const intl = useIntl();
  const data = paymentRequests;
  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder';
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: PaymentRequest) => br.id,
        Cell: ({value}: {value: number}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyPaymentRequestRoute(value)}>{value}</Link>
            </div>
          );
        },
      },
      {
        id: 'Email',
        Header: intl.formatMessage({id: 'EMAIL'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: PaymentRequest) => br,
        Cell: ({value}: {value: PaymentRequest}) => {
          return (
            <div className={defaultClassName}>
              <Link to={CommonRoutes.getSpecifyUserManagementRoute(value.user_id)}>{value.user_name}</Link>
            </div>
          );
        },
      },
      {
        id: 'Sum',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUM'})),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: PaymentRequest) => br,
        Cell: ({value}: {value: PaymentRequest}) => {
          return TableUtils.renderNumericContent(
            <div className={defaultClassName}>
              <FormattedNumber value={value.value} style={'currency'} currency={value.currency} />
            </div>,
          );
        },
      },
      {
        id: 'Destination',
        Header: intl.formatMessage({id: 'ACCOUNT'}),
        maxWidth: 100,
        accessor: (br: PaymentRequest) => br.destination,
        Cell: ({value}: {value: PaymentRequestDestination}) => {
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={value === PaymentRequestDestination.TO_MCPAY ? 'info' : 'primary'}>
              <FormattedMessage id={'PAYMENT_REQUEST_DESTINATION_' + value} />
            </BadgeCustom>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 100,
        accessor: (br: PaymentRequest) => br.status,
        Cell: ({value}: {value: PaymentRequestStatus}) => {
          const colorVariant =
            value === PaymentRequestStatus.REJECT
              ? 'danger'
              : value === PaymentRequestStatus.COMPLETE
              ? 'success'
              : 'primary';
          return (
            <div className={defaultClassName}>
              <BadgeCustom variant={colorVariant}>
                <FormattedMessage id={'PAYMENT_REQUEST_STATUS_' + value} />
              </BadgeCustom>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        accessor: (br: PaymentRequest) => br.created_at,
        Cell: ({value}: {value: string}) => {
          return (
            <div className={defaultClassName}>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
