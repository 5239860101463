import React, {useCallback, useState} from 'react';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {ImageUploader} from '../../../components/image-uploader';
import {AttachmentMetadata} from '../../../components/file-uploader';
import {useIntl} from '../../../hooks/use-intl';
import {CreatedFile} from '../../../api/file-api/files-api';
import {useLoading} from '../../../hooks/use-loading';
import {AttachmentBlock, AttachmentType} from './attachment-block';
import {ImageRatios, revokeUrlCreatedFromBlob, validateRatioOfSide} from '../../../utils/file-utils';

type Props = {
  validationError: null | string | Array<string>;
  metadata: AttachmentMetadata | null;

  onDelete: () => void;
  onStartUpload: (image: File) => Promise<CreatedFile>;
  onCreateFile: (file: CreatedFile) => void;
};

const AVAILABLE_PREVIEW_FORMATS = ['.jpeg', '.jpg'];
export const UploadImageBlock: React.FC<Props> = ({
  metadata: metadataFromProps,
  validationError,
  onDelete,
  onStartUpload,
  onCreateFile,
}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    upload: false,
    cancel: false,
  });
  const [image, setImage] = useState<File | null>(null);
  const [successUpload, setSuccessUpload] = useState(metadataFromProps !== null);
  const [metadata, setMetadata] = useState<AttachmentMetadata | null>(metadataFromProps);
  const [uploadError, setUploadError] = useState<Error | null>(null);

  const handleStartUpload = useCallback(async (img: File) => {
    try {
      startLoading('upload');
      setUploadError(null);
      const result = await onStartUpload(img);
      onCreateFile(result);
      setSuccessUpload(true);
      setSuccessUpload(true);
    } catch (e) {
      setUploadError(e);
      setSuccessUpload(false);
    } finally {
      stopLoading('upload');
    }
  }, []);

  const handleSelectImage = useCallback(
    async (image: File | null, newMetadata: AttachmentMetadata | null) => {
      setImage(image);
      setSuccessUpload(false);
      if (metadata?.url) {
        revokeUrlCreatedFromBlob(metadata.url);
      }

      setMetadata(newMetadata);
      if (image !== null) {
        await handleStartUpload(image);
      } else {
        onDelete();
      }
    },
    [setImage, setMetadata],
  );

  return (
    <>
      <AttachmentBlock
        metadata={metadata}
        type={AttachmentType.IMAGE}
        uploadInfo={{
          loading: loadings.upload,
          successUpload,
          errorUpload: uploadError !== null,
          loadingCancel: loadings.cancel,
        }}
        toolBar={{
          showTryAgain: uploadError !== null,
          onTryAgainClick: () => handleStartUpload(image as File),
          onDeleteClick: () => handleSelectImage(null, null),
        }}
      />
      {metadata === null && (
        <ValidateErrorWrapper className={'mb-0'} message={validationError}>
          <ImageUploader
            required
            descriptionLabel={intl.formatMessage({id: 'VIDEO_PREVIEW_NOTE'})}
            showPreview={false}
            previewUrl={null}
            acceptFileExtension={AVAILABLE_PREVIEW_FORMATS}
            dropdownZoneClassName={'w-100'}
            onUploadImage={handleSelectImage}
            validateFile={file => validateRatioOfSide(file, ImageRatios.RATIO_16_9)}
          />
        </ValidateErrorWrapper>
      )}
    </>
  );
};
