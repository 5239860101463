import {useCallback, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Contract} from '../../api/contract-api/Contract';
import {TransactionOperation} from '../../api/DTOs/TransactionOperation';
import {FinancialAccount} from '../../api/DTOs/FinancialAccount';
import {Transaction} from '../../api/DTOs/Transaction';
import {Video} from '../../api/DTOs/Video';
import {YoutubeChannel} from '../../api/DTOs/YoutubeChannel';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import config from '../../../configs/config';
import {EntityId} from '../../api/base/BaseEntity';
import {BaseResponse} from '../../api/base/base-crud-api-response';
import {
  ModalChannelTransactionsIncomeReportFormState
} from '../../components/modals/channel-transaction-income-report-modal/modal-channel-transactions-income-report';
import {PartialNullable} from '../../../types/types';

export type TransactionOperationRelation = {
  contract: Contract;
  fromAccount: FinancialAccount;
  toAccount: FinancialAccount;
  transaction: Transaction;
  video: Video;
  youtubeChannel: YoutubeChannel;
};

export function useTransactionsOperationApi() {
  const config = useRef({prefixUrl: 'transaction-operations', isAdmin: false});
  return {
    ...useEntityApi<TransactionOperation, TransactionOperationRelation>(config.current),
    ...useAdditionalRoutes(config.current),
  };
}

const useAdditionalRoutes = (conf: EntityApiHookParams) => {
  const api = useBaseEndpointApi();

  const generateChannelTransactionIncomeReport = useCallback(async (fields: PartialNullable<ModalChannelTransactionsIncomeReportFormState>) => {
    return await api.post<BaseResponse>(
      `${conf.prefixUrl}/channel-transactions-excel-report`,
      {
        fields: fields
      },);
  }, []);
  const downloadOperationsReport = useCallback(async (params: IQueryParams) => {
    return await api.get<BaseResponse>(`${conf.prefixUrl}/excel-report?${api.applyFilters(params)}`);
  }, []);
  const downloadPaymentDocuments = useCallback(async (id: EntityId) => {
    return await api.getFile(`${config.BASE_API_URL}/${conf.prefixUrl}/${id}/payment-documents`);
  }, []);

  return {
    downloadOperationsReport,
    downloadPaymentDocuments,
    generateChannelTransactionIncomeReport
  };
};
