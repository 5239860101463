import {toast} from 'react-toastify';
import {Video} from '../../api/DTOs/Video';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {useBaseListPage} from '../base/base-list-page-context';
import {useIntl} from '../../hooks/use-intl';
import {EntityId} from '../../api/base/BaseEntity';
import {ApiRequestException} from '../../api/axios-instance';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../configs/routes';
import {useVideosApi} from '../../hooks/apis/use-videos-api';
import {PartialNullable} from '../../../types/types';
import {FileDto} from '../../api/file-api/IFileDto';
import {useModalUploadFiles} from './video-page/upload-watermarked-file-modal-context';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {useModalRejectReason} from '../../components/modals/reject-reason/modal-reject-reason-base-context';

export enum VideoAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  UPLOAD_UPDATED_FILES = 'UPLOAD_UPDATED_FILES',
  CONFIRM_PUBLICATION = 'CONFIRM_PUBLICATION',
  REJECT_PUBLICATION = 'REJECT_PUBLICATION',
}

export type HandleVideoActionPayload =
  | HandleVideoCreateAction
  | HandleVideoEditAction
  | HandleVideoDeleteAction
  | HandleVideoReadyToPublish
  | HandleVideoUploadUpdatedFiles
  | HandleVideoRejectPublication
  | HandleVideoConfirmPublication;

export type HandleVideoConfirmPublication = {
  type: VideoAction.CONFIRM_PUBLICATION;
  entityId: EntityId;
};

export type HandleVideoRejectPublication = {
  type: VideoAction.REJECT_PUBLICATION;
  entityId: EntityId;
};

export type HandleVideoCreateAction = {
  type: VideoAction.CREATE;
};

export type HandleVideoEditAction = {
  type: VideoAction.EDIT;
  entity: PartialNullable<Video>;
};

export type HandleVideoDeleteAction = {
  type: VideoAction.DELETE;
  entityId: EntityId;
  needRedirectToList?: boolean;
};

export type HandleVideoReadyToPublish = {
  type: VideoAction.READY_TO_PUBLISH;
  entityId: EntityId;
};

export type HandleVideoUploadUpdatedFiles = {
  type: VideoAction.UPLOAD_UPDATED_FILES;
  entityId: EntityId;
  imageWatermarkedFile: FileDto | null;
  videoWatermarkedFile: FileDto | null;
};

export function useVideoActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const history = useHistory();
  const api = useVideosApi();
  const {showRejectReasonModal} = useModalRejectReason();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showUploadFilesModal} = useModalUploadFiles();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleVideoActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case VideoAction.CREATE: {
          history.push(Routes.getUploadVideoRoute());
          break;
        }
        case VideoAction.EDIT: {
          history.push(Routes.getEditUploadVideoRoute(payload.entity.id as EntityId));
          break;
        }
        case VideoAction.REJECT_PUBLICATION: {
          const modalResult = await showRejectReasonModal({
            onSubmit: rejectReason => api.rejectPublication(payload.entityId, rejectReason),
          });

          if (modalResult.reason === CloseModalReason.OK) {
            toast.success(intl.formatMessage({id: 'REJECT_PUBLISH_SUCCESS'}));
            fetch?.({});
          }
          break;
        }
        case VideoAction.CONFIRM_PUBLICATION: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_VIDEO_PUBLICATION'}))) {
            try {
              await api.confirmPublication(payload.entityId);
              toast.success(intl.formatMessage({id: 'CONFIRM_PUBLISH_SUCCESS'}));
              fetch?.({});
            } catch (e) {
              toast.error(e.errorMessage ?? intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
            }
          }
          break;
        }
        case VideoAction.READY_TO_PUBLISH: {
          try {
            await api.readyToPublish(payload.entityId);
            fetch?.({});
            toast.success(intl.formatMessage({id: 'READY_TO_PUBLISH_SUCCESS'}));
          } catch (e) {
            toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
          }
          break;
        }
        case VideoAction.UPLOAD_UPDATED_FILES: {
          const modalResult = await showUploadFilesModal({
            videoId: payload.entityId,
            imageFile: payload.imageWatermarkedFile,
            videoFile: payload.videoWatermarkedFile,
          });

          if (modalResult.reason === CloseModalReason.OK) {
            fetch?.({});
          }
          break;
        }
        case VideoAction.DELETE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_UPLOADED_VIDEO'}))) {
            try {
              await api.remove(payload.entityId);
              if (payload.needRedirectToList) {
                history.push(Routes.getVideosRoute());
              }
              fetch?.(appliedQueryParams);
            } catch (e) {
              if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
                toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
              }
            }
          }
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    handleVideoActions: handle,
  };
}
