import React, {useState} from 'react';
import {Genre} from '../../../api/DTOs/Genre';
import {BaseListPage} from '../../base/base-list-page';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {ICONS} from '../../../images/images';
import {useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {GenreAction, useGenreActionHandler} from './useGenreHandlerActions';
import {GenresTable} from './genres-table';
import {useLoading} from '../../../hooks/use-loading';
import {useGenresApi} from '../../../hooks/apis/use-genres-api';

export const GenresPage: React.FC = () => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    genres: true,
  });
  const api = useGenresApi();
  const [genres, setGenres] = useState<Array<Genre>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const fetchGenres = async (params?: IQueryParams) => {
    try {
      startLoading('genres');
      const result = await api.getAll(params);
      setGenres(result.items);
      setPaginationInfo(result.paginator);
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('genres');
    }
  };
  const {handleGenreActions} = useGenreActionHandler(fetchGenres);

  return (
    <>
      <BaseListPage
        pageTitle={intl.formatMessage({id: 'GENRE'})}
        loading={loadings.genres}
        pagination={{info: paginationInfo}}
        fetchData={fetchGenres}
        toolbarConfig={[
          {
            type: 'BUTTON',
            title: intl.formatMessage({id: 'CREATE'}),
            icon: ICONS.PLUS,
            className: 'btn font-weight-bolder text-uppercase btn-light-primary',
            onClick: () => handleGenreActions({type: GenreAction.CREATE}),
          },
        ]}>
        <GenresTable
          genres={genres}
          onAction={async (genre, action) => {
            if (action === GenreAction.EDIT) {
              await handleGenreActions({type: action, genre});
            } else if (action === GenreAction.DELETE) {
              await handleGenreActions({type: action, genreId: genre.id});
            }
          }}
        />
      </BaseListPage>
    </>
  );
};
