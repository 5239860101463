import React, {createContext, useContext, useEffect, useState} from 'react';
import {EntityId} from '../../api/base/BaseEntity';
import {useDownloadsApi} from '../../hooks/apis/use-downloads-api';
import {
  BaseModal,
  ModalBodySlot,
  ModalFooterButtonsSlot,
  ModalHeaderSlot,
} from '../../components/modals/base-modal/base-modal';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {Preloader} from '../../components/preloader';
import {useIntl} from '../../hooks/use-intl';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../configs/routes';
import {useLoading} from '../../hooks/use-loading';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapColors, BootstrapSizes} from '../../../styles/bootstap-colors';

interface ContextProps {
  showDownloadFileModal(downloadId: EntityId): Promise<void>;
}

// @ts-ignore
const ContextDownloadFile = createContext<ContextProps>();

let closeResolver: (() => void) | null = null;
export const ModalDownloadFileProvider = ({children}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const api = useDownloadsApi();
  const [visible, setVisible] = useState<boolean>(false);
  const [downloadId, setDownloadId] = useState<EntityId | null>(null);
  const [downloadStatus, setDownloadStatus] = useState<'success' | 'error' | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    downloading: false,
  });
  useEffect(() => {
    if (downloadId) {
      downloadFile(downloadId).then();
    }
  }, [downloadId]);

  const showModal = async (id: EntityId) => {
    setVisible(true);
    setDownloadId(id);
    return new Promise<void>(resolve => {
      closeResolver = resolve;
    });
  };

  const downloadFile = async (id: EntityId) => {
    try {
      setDownloadStatus(null);
      startLoading('downloading');
      const {content, fileName} = await api.get(id);
      saveAs(content, fileName);
      setDownloadStatus('success');
    } catch (e) {
      setDownloadStatus('error');
    } finally {
      stopLoading('downloading');
    }
  };

  const handleHideModal = () => {
    setVisible(false);
    setDownloadId(null);
    if (closeResolver) {
      closeResolver();
    }
    history.push(Routes.getDownloadsRoute());
  };

  const value: ContextProps = {
    showDownloadFileModal: showModal,
  };

  return (
    <ContextDownloadFile.Provider value={value}>
      {children}
      <BaseModal
        backdrop={'static'}
        disabledButtons={loadings.downloading}
        hideDismissButton={loadings.downloading}
        closeButton={false}
        visible={visible}
        onHide={handleHideModal}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'DOWNLOADING'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          {loadings.downloading && <Preloader />}
          {!loadings.downloading && (
            <div className={'d-flex align-items-center'}>
              {downloadStatus === 'success' ? (
                <>
                  <Svg src={ICONS.CHECK} color={BootstrapColors.SUCCESS} size={BootstrapSizes.X5} />
                  <span className={'text-success font-size-h6 font-weight-bolder'}>
                    {intl.formatMessage({id: 'SUCCESS_DOWNLOAD_FILE'})}
                  </span>
                </>
              ) : (
                <>
                  <Svg src={ICONS.CROSS} color={BootstrapColors.DANGER} size={BootstrapSizes.X5} />
                  <span className={'text-danger font-size-h6 font-weight-bolder'}>
                    {intl.formatMessage({id: 'UNEXPECTED_ERROR'})}
                  </span>
                </>
              )}
            </div>
          )}
        </ModalBodySlot>
        <ModalFooterButtonsSlot>
          {downloadStatus === 'error' && (
            <button onClick={() => downloadFile(downloadId as EntityId)} className={'btn btn-light-danger'}>
              {intl.formatMessage({id: 'TRY_DOWNLOAD_AGAIN'})}
            </button>
          )}
        </ModalFooterButtonsSlot>
      </BaseModal>
    </ContextDownloadFile.Provider>
  );
};

export const useModalDownloadFile = () => {
  return useContext(ContextDownloadFile);
};
