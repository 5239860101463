import React, {useCallback, useState} from 'react';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {AttachmentMetadata, FileUploader} from '../../../components/file-uploader';
import {useIntl} from '../../../hooks/use-intl';
import {CreatedFile} from '../../../api/file-api/files-api';
import {useLoading} from '../../../hooks/use-loading';
import {AttachmentBlock, AttachmentType} from './attachment-block';
import {revokeUrlCreatedFromBlob} from '../../../utils/file-utils';
import {
  MultipartUploaderCancelNotify,
  MultipartUploaderErrorCompleteUpload,
  MultipartUploaderErrorCreateFile,
  MultipartUploaderErrorsPayloads,
  MultipartUploaderErrorUploadParts,
} from '../../../services/MultipartUploader';
import {useEffect} from 'react';

type Props = {
  validationError: null | string | Array<string>;
  metadata: AttachmentMetadata | null;

  onDelete: () => void;
  onCancelUpload: (promiseResolver: (value: any | PromiseLike<any>) => void) => void;
  onStartUpload: (video: File, tickCb?: (progress: number) => void) => Promise<CreatedFile>;
  onTryAgainUpload: (
    video: File,
    lastError: MultipartUploaderErrorsPayloads,
    tickCb: (progress: number) => void,
  ) => Promise<CreatedFile>;
  onCreateFile: (file: CreatedFile) => void;
};

const AVAILABLE_VIDEO_FORMATS = ['.mp4', '.mov', '.wmv', '.mpeg', '.avi'];
export const UploadVideoBlock: React.FC<Props> = ({
  metadata: metadataFromProps,
  validationError,
  onStartUpload,
  onDelete,
  onCancelUpload,
  onTryAgainUpload,
  onCreateFile,
}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    upload: false,
    cancel: false,
  });

  useEffect(() => {
    if (loadings.upload) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [loadings]);

  const handleBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    const returnValue = ' ';
    return (e.returnValue = returnValue);
  }, []);

  const [video, setVideo] = useState<File | null>(null);
  const [successUpload, setSuccessUpload] = useState(metadataFromProps !== null);
  const [metadata, setMetadata] = useState<AttachmentMetadata | null>(metadataFromProps);
  const [uploadError, setUploadError] = useState<MultipartUploaderErrorsPayloads | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const handleStartUpload = useCallback(async (video: File) => {
    try {
      startLoading('upload');
      const result = await onStartUpload(video, value => setProgress(value * 100));
      onCreateFile(result);
      setProgress(0);
      setSuccessUpload(true);
      setUploadError(null);
      setSuccessUpload(true);
    } catch (e) {
      if (e instanceof MultipartUploaderCancelNotify) {
        return;
      }

      if (
        e instanceof MultipartUploaderErrorUploadParts ||
        e instanceof MultipartUploaderErrorCompleteUpload ||
        e instanceof MultipartUploaderErrorCreateFile
      ) {
        setUploadError(e.payload);
      }
      setSuccessUpload(false);
    } finally {
      stopLoading('upload');
    }
  }, []);

  const handleSelectVideo = useCallback(
    async (video: File | null, newMetadata: AttachmentMetadata | null) => {
      setVideo(video);
      setSuccessUpload(false);
      if (metadata?.url) {
        revokeUrlCreatedFromBlob(metadata.url);
      }

      setMetadata(newMetadata);
      if (video !== null) {
        await handleStartUpload(video);
      } else {
        onDelete();
      }
    },
    [setVideo, setMetadata],
  );

  const handleCancelUpload = async () => {
    try {
      startLoading('cancel');
      await new Promise(onCancelUpload);
      await handleSelectVideo(null, null);
    } finally {
      stopLoading('cancel');
    }
  };

  const handleTryAgainUpload = async (videoFile: File) => {
    if (!video) {
      throw new Error('Please, select video');
    }

    if (uploadError === null) {
      throw new Error('Not found error');
    }
    const lastErrorPayload = uploadError ? {...uploadError} : null;
    try {
      startLoading('upload');
      setUploadError(null);
      const file = await onTryAgainUpload(videoFile, uploadError, value => setProgress(value * 100));
      onCreateFile(file);
      setProgress(0);
      setSuccessUpload(true);
      setUploadError(null);
      setSuccessUpload(true);
    } catch (e) {
      if (e instanceof MultipartUploaderCancelNotify) {
        setUploadError(lastErrorPayload);
        return;
      }

      if (
        e instanceof MultipartUploaderErrorUploadParts ||
        e instanceof MultipartUploaderErrorCompleteUpload ||
        e instanceof MultipartUploaderErrorCreateFile
      ) {
        setUploadError(e.payload);
      }
    } finally {
      stopLoading('upload');
    }
  };

  return (
    <>
      <AttachmentBlock
        metadata={metadata}
        type={AttachmentType.VIDEO}
        uploadInfo={{
          loading: loadings.upload,
          successUpload,
          errorUpload: uploadError !== null,
          progressPercent: progress,
          loadingCancel: loadings.cancel,
        }}
        toolBar={{
          showTryAgain: uploadError !== null,
          onTryAgainClick: () => handleTryAgainUpload(video as File),
          onDeleteClick: () => handleSelectVideo(null, null),
          onCancelUploadClick: handleCancelUpload,
        }}
      />
      {metadata === null && (
        <ValidateErrorWrapper className={'mb-0'} message={validationError}>
          <FileUploader
            required
            descriptionLabel={intl.formatMessage({id: 'VIDEO_CONTENT_NOTE'})}
            acceptFileExtension={AVAILABLE_VIDEO_FORMATS}
            onSelect={handleSelectVideo}
          />
        </ValidateErrorWrapper>
      )}
    </>
  );
};
