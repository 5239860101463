import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {PartialNullable} from '../../../types/types';
import {ICurrentUserResponse, IRegisterResponse} from '../../api/response-contracts/auth-response-contracts';
import {IResponseBase} from '../../api/response-contracts/base-response';

export function useAuthApi() {
  return useRoutes();
}

function useRoutes() {
  const api = useBaseEndpointApi();

  const transliterate = async (value: string) => {
    return await api.post(`transliterate`, {
      fields: {
        string: value,
      },
    });
  };

  const register = async (payload: PartialNullable<Record<string, any>>): Promise<IRegisterResponse> => {
    return await api.post<IRegisterResponse>(`register`, {
      fields: {
        ...payload,
      },
    });
  };

  const registerOnInvite = async (
    inviteToken: string,
    payload: PartialNullable<Record<string, any>>,
  ): Promise<IRegisterResponse> => {
    return await api.post<IRegisterResponse>(`register-after-invite/${inviteToken}`, {
      fields: {
        ...payload,
      },
    });
  };

  const checkCanRegister = async (payload: PartialNullable<Record<string, any>>): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`check-can-register`, {
      fields: {
        ...payload,
      },
    });
  };

  const login = async (payload: {email: string; password: string}): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`login`, {
      fields: {
        ...payload,
      },
    });
  };

  const logout = async (): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`logout`);
  };

  const resetRequestPassword = async (payload: {email: string}): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`request_reset_password`, {
      fields: {
        ...payload,
      },
    });
  };

  const resetPassword = async (payload: {
    reset_password_token: string;
    new_password: string;
    new_password_confirmation: string;
  }): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`reset_password`, {
      fields: {
        ...payload,
      },
    });
  };

  const changePassword = async (payload: {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
  }): Promise<IResponseBase> => {
    return await api.post<IResponseBase>(`user/change_password`, {
      fields: {
        ...payload,
      },
    });
  };

  const getAuthData = async (): Promise<ICurrentUserResponse> => {
    return await api.get<IResponseBase>(`user`);
  };

  const loadCsrfToCookie = async (): Promise<IResponseBase> => {
    return await api.get<IResponseBase>(`csrf`);
  };

  return {
    login,
    logout,

    register,
    registerOnInvite,
    checkCanRegister,

    resetPassword,
    resetRequestPassword,
    changePassword,

    loadCsrfToCookie,
    getAuthData,
    transliterate,
  };
}
