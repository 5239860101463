import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './InputDatepicker.scss';
import {useIntl} from 'react-intl';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import {LabelWithDescription} from './LabelWithDescription';

registerLocale('en', en);
registerLocale('ru', ru);

interface IInputDatePickerProps {
  disabled?: boolean;
  className?: string;
  label?: string;
  descriptionLabel?: string;
  required?: boolean;
  hasError?: boolean;
  showMonthYearPicker?: boolean;
  calendarStartDay?: number;
  placeholder?: string;
  startDate?: Date | null;
  endDate?: Date | null;

  onChange(range: [Date | null, Date | null]): void;
}

export const InputDatepickerRange: React.FC<IInputDatePickerProps> = ({
  required,
  disabled,
  label,
  descriptionLabel,
  hasError,
  className,
  showMonthYearPicker,
  calendarStartDay,
  placeholder,
  endDate,
  startDate,
  onChange,
}) => {
  const intl = useIntl();

  const handleChange = (date: [Date | null, Date | null]) => {
    onChange(date);
  };

  const datePickerClassName = `form-control w-100 ${hasError ? 'has-error' : ''}`;
  return (
    <div className={className}>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <DatePicker
        selectsRange={true}
        disabled={disabled}
        isClearable
        endDate={endDate}
        startDate={startDate}
        locale={intl.locale}
        dateFormat={intl.locale === 'ru' ? 'dd.MM.yyyy' : undefined}
        placeholderText={placeholder}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showMonthYearPicker}
        calendarStartDay={calendarStartDay}
        className={datePickerClassName}
        onChange={handleChange}
      />
    </div>
  );
};
