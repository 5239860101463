import React, {useCallback, useMemo, useState} from 'react';
import {useContractorDocumentsApi} from '../../hooks/apis/use-contractor-documents-api';
import {BaseListPage} from '../base/base-list-page';
import {useLoading} from '../../hooks/use-loading';
import {ContractorDocumentCard} from './contractor-document-card';
import {useIntl} from '../../hooks/use-intl';
import {saveAs} from 'file-saver';
import {toast} from 'react-toastify';
import {EntityId} from '../../api/base/BaseEntity';
import {MarketingBlock} from '../marketing-blocks/marketing-block';
import {ILLUSTRATIONS} from '../../images/images';
import {ContractorDocument, ContractorDocumentType} from '../../api/DTOs/ContractorDocument';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {EmptyListState} from '../../components/empty-list-state';
import {IPaginationInfo} from '../../api/Paginator';

export const ContractorDocumentsPage: React.FC = () => {
  const intl = useIntl();

  const api = useContractorDocumentsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    fetchDocuments: true,
  });

  const [documents, setDocuments] = useState<Array<ContractorDocument>>();
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      filters: [
        {
          type: FilterType.SELECT,
          name: 'type',
          placeholder: intl.formatMessage({id: 'TYPE'}),
          options: [
            {
              label: intl.formatMessage({id: 'CONTRACTOR_DOC_TYPE_' + ContractorDocumentType.CONTRACT}),
              value: ContractorDocumentType.CONTRACT,
            },
            {
              label: intl.formatMessage({id: 'CONTRACTOR_DOC_TYPE_' + ContractorDocumentType.SUP_AGREEMENT}),
              value: ContractorDocumentType.SUP_AGREEMENT,
            },
            {
              label: intl.formatMessage({id: 'CONTRACTOR_DOC_TYPE_' + ContractorDocumentType.FINANCIAL_DETAILS_REPORT}),
              value: ContractorDocumentType.FINANCIAL_DETAILS_REPORT,
            },
          ],
        },
      ],
    });
  }, []);

  const fetchDocuments = useCallback(async (params?: IQueryParams) => {
    try {
      startLoading('fetchDocuments');
      const res = await api.getAll(params);
      setDocuments(res.items);
      setPaginationInfo(res.paginator);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('fetchDocuments');
    }
  }, []);

  const handleDownloadDocument = async (documentId: EntityId) => {
    try {
      const res = await api.downloadDocument(documentId);
      saveAs(res.content, res.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  return (
    <BaseListPage
      fetchData={fetchDocuments}
      showMarketing={documents?.length === 0 && !loadings.fetchDocuments}
      filtersConfig={filterConfig}
      pagination={{info: paginationInfo}}
      renderMarketing={() => (
        <MarketingBlock
          image={ILLUSTRATIONS.CONTRACTS}
          title={intl.formatMessage({id: 'MY_DOCUMENTS'})}
          description={intl.formatMessage({id: 'MY_DOCUMENTS_MARKETING_DESCRIPTION'})}
        />
      )}
      loading={loadings.fetchDocuments}
      className={{cardBody: 'bg-light'}}>
      {documents?.length === 0 && <EmptyListState />}
      {documents?.map(doc => {
        return <ContractorDocumentCard key={doc.id} item={doc} onDownload={handleDownloadDocument} />;
      })}
    </BaseListPage>
  );
};
