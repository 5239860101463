import React from 'react';
import {BaseModal, ModalBodySlot} from '../base-modal/base-modal';
import {useIntl} from 'react-intl';

export type ConfirmModalOptions = {
  successButtonTitle?: string;
  icon?: string | null;
  hideDismissButton?: boolean;
};

interface IModalRejectReasonProps {
  error?: string | null;
  withoutDismiss?: boolean;
  visible: boolean;
  confirmationMessage: string;
  description?: string;
  options?: ConfirmModalOptions;

  onHide(): void;

  onOkClick(): any;
}

const ILLUSTRATIONS = {
  QUESTION: require('../../../images/illustration/Question.png'),
};

export const ModalConfirmAction: React.FC<IModalRejectReasonProps> = ({
  visible,
  confirmationMessage,
  description,
  options,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const handleOkClick = () => {
    onOkClick();
  };

  return (
    <>
      <BaseModal
        hideHeader
        hideDismissButton={options?.hideDismissButton}
        backdrop={'static'}
        closeButton={false}
        successButtonTitle={options?.successButtonTitle || intl.formatMessage({id: 'CONFIRM'})}
        visible={visible}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalBodySlot>
          <p className={'font-size-h4 font-weight-bolder m-0'}>{confirmationMessage}</p>

          {description && <p className={'text-muted font-size-lg mb-0 mt-4'}>{description}</p>}

          <div className='text-center'>
            <img style={{maxWidth: '70%'}} src={options?.icon || ILLUSTRATIONS.QUESTION} alt='Question Picture' />
          </div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
