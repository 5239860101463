import {ArtistChartMetricId} from '../Artist';
import {DateAsString, float} from '../../base/BaseEntity';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

export enum ChartMetricDomain {
  SPOTIFY = 'spotify',
  ITUNES = 'itunes',
  AMAZON = 'amazon',
  YOUTUBE = 'youtube',
  WIKI = 'wikipedia',
  SOUNDCLOUD = 'soundcloud',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
}

export const ChartMetricDomainColorMap: Record<ChartMetricDomain, [BootstrapColors, string | null]> = {
  [ChartMetricDomain.SPOTIFY]: [BootstrapColors.SUCCESS, ChartMetricDomain.SPOTIFY],
  [ChartMetricDomain.ITUNES]: [BootstrapColors.DANGER, ChartMetricDomain.ITUNES],
  [ChartMetricDomain.YOUTUBE]: [BootstrapColors.DANGER, ChartMetricDomain.YOUTUBE],
  [ChartMetricDomain.SOUNDCLOUD]: [BootstrapColors.WARNING, ChartMetricDomain.SOUNDCLOUD],
  [ChartMetricDomain.INSTAGRAM]: [BootstrapColors.LIGHT_PRIMARY, ChartMetricDomain.INSTAGRAM],
  [ChartMetricDomain.FACEBOOK]: [BootstrapColors.PRIMARY, ChartMetricDomain.FACEBOOK],
  [ChartMetricDomain.AMAZON]: [BootstrapColors.DARK, ChartMetricDomain.AMAZON],
  [ChartMetricDomain.WIKI]: [BootstrapColors.DARK, 'wikipedia-w'],
};

export type ImageUrl = string;
export type ChartMetricSocialStreamingUrl = {
  domain: ChartMetricDomain;
  url: Array<string> | [string];
};

export type ChartMetricTag = {id: number; name: string};
export type ChartMetricGenre = {name: string; rank: number | null; percentile: number | null};
export type ChartMetricStatistic = {
  name: string | null;
  code2: string | null;
  listeners: number | null;

  genreRank: ChartMetricGenre | [];
  subGenreRanks: Array<ChartMetricGenre> | null;
  countryRank: {country: string; rank: number | null} | null;

  cm_artist_rank: number | null;
  cm_artist_rank_percentile: number | null;

  fan_base_rank: number | null;
  fan_base_rank_percentile: number | null;

  engagement_rank: number | null;
  engagement_rank_percentile: number | null;

  deezer_fans: number | null;
  deezer_fans_rank: number | null;

  sp_followers: number | null;
  sp_popularity: number | null;
  sp_monthly_listeners: number | null;
  sp_followers_rank: number | null;
  sp_popularity_rank: number | null;
  sp_monthly_listeners_rank: number | null;
  sp_where_people_listen: Array<{code2: string; listeners: number | null; name: string}> | null;

  ins_followers: number | null;
  ins_followers_rank: number | null;

  ycs_subscribers: number | null;
  ycs_subscribers_rank: number | null;
  ycs_views: number | null;
  ycs_views_rank: number | null;

  tiktok_followers: number | null;
  tiktok_followers_rank: number | null;
  tiktok_likes: number | null;
  tiktok_likes_rank: number | null;
  tiktok_top_video_views: number | null;
  tiktok_top_video_views_rank: number | null;
  tiktok_track_posts: number | null;
  tiktok_track_posts_rank: number | null;

  youtube_daily_video_views: number | null;
  youtube_daily_video_views_rank: number | null;
  youtube_monthly_video_views: number | null;
  youtube_monthly_video_views_rank: number | null;

  twitch_followers: number | null;
  twitch_views: number | null;
  twitch_monthly_viewer_hours: number | null;
  twitch_weekly_viewer_hours: number | null;

  num_sp_playlists: number | null;
  num_sp_editorial_playlists: number | null;
  sp_playlist_total_reach: number | null;
  sp_editorial_playlist_total_reach: number | null;

  num_am_playlists: number | null;
  num_am_editorial_playlists: number | null;

  num_de_editorial_playlists: number | null;
  num_de_playlists: number | null;
  de_playlist_total_reach: number | null;
  de_editorial_playlist_total_reach: number | null;

  num_az_editorial_playlists: number | null;
  num_az_playlists: number | null;

  num_yt_playlists: number | null;
  num_yt_editorial_playlists: number | null;
  yt_playlist_total_reach: number | null;
  yt_editorial_playlist_total_reach: number | null;

  shazam_count: number | null;
  artist_score: float;
};

export type ChartMetricStats = ChartMetricStatistic & {
  weekly_diff: ChartMetricStatisticDiff;
  weekly_diff_percent: ChartMetricStatisticDiff;
  monthly_diff: ChartMetricStatisticDiff;
  monthly_diff_percent: ChartMetricStatisticDiff;
  bimonthly_diff: ChartMetricStatisticDiff;
  bimonthly_diff_percent: ChartMetricStatisticDiff;
};

export type ChartMetricTopTrack = {
  album: Array<{id: number; name: string; release_date: DateAsString}>;
  artist: Array<{id: number; name: string}>;
  artist_track_name: string;
  name: string;
  image_url: ImageUrl;
  isrc: number;
  doc_id: number;
  latest: {
    youtube_likes: number | null;
    youtube_views: number | null;
    spotify_plays: number | null;
    spotify_popularity: number | null;
  };
  score: float;
};
export type ChartMetricStatisticDiff = Omit<
  ChartMetricStatistic,
  | 'timestamp'
  | 'sp_popularity_rank'
  | 'deezer_fans_rank'
  | 'sp_followers_rank'
  | 'sp_monthly_listeners_rank'
  | 'ins_followers_rank'
  | 'ycs_subscribers_rank'
  | 'ycs_views_rank'
  | 'tiktok_followers_rank'
  | 'tiktok_track_posts_rank'
  | 'tiktok_likes_rank'
  | 'tiktok_top_video_views_rank'
  | 'line_music_artist_likes_rank'
  | 'line_music_likes_rank'
  | 'line_music_plays_rank'
  | 'line_music_mv_plays_rank'
  | 'melon_artist_fans_rank'
  | 'melon_likes_rank'
  | 'melon_video_likes_rank'
  | 'melon_video_views_rank'
  | 'youtube_daily_video_views_rank'
  | 'youtube_monthly_video_views_rank'
  | 'twitch_followers_rank'
  | 'twitch_views_rank'
  | 'twitch_monthly_viewer_hours_rank'
  | 'twitch_weekly_viewer_hours_rank'
  | 'pandora_listeners_28_day_rank'
  | 'pandora_lifetime_stations_added_rank'
  | 'pandora_lifetime_streams_rank'
  | 'boomplay_ranking_current_rank'
  | 'boomplay_favorites_rank'
  | 'boomplay_shares_rank'
  | 'boomplay_comments_rank'
  | 'boomplay_plays_rank'
  | 'weekly_diff'
  | 'weekly_diff_percent'
  | 'monthly_diff'
  | 'monthly_diff_percent'
  | 'bimonthly_diff'
  | 'bimonthly_diff_percent'
>;

export type ChartmetricSearchableArtist = {
  image_url: string | null;
  name: string;
  id: string;
};

export type ChartMetricArtistMetadata = {
  id: ArtistChartMetricId;
  name: string;
  created_at: DateAsString;
  gender: string | null;
  code2: string | null;
  isni: string | null;
  cm_artist_rank: number | null;
  cm_artist_score: number | null;
  rank_eg: number | null;
  rank_fb: number | null;
  cover_url: ImageUrl | null;
  image_url: ImageUrl | null;
  hometown_city: string;
  current_city: string;
  topSongwriterCollaborators: Array<string>;
  description: string | null;
  // Жанры
  tags: Array<ChartMetricTag>;
  topTracks: Array<ChartMetricTopTrack>;
  cm_statistics: ChartMetricStats;
};
