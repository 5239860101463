import React, {useState} from 'react';
import {YoutubeMetricsPanel, YoutubeMetricsPanelItem} from './youtube-metrics-panel';
import {createSlot} from 'react-slotify';
import {trimStringWithEllipsis} from '../utils/utils';
import {FormattedMessage} from 'react-intl';
import {AlertCustom} from '../modules/Auth/component/alert';
import {BootstrapColors} from '../../styles/bootstap-colors';
import {Toolbar, ToolBarItem} from "./card-toolbar/Toolbar";
import {useIntl} from "../hooks/use-intl";

interface Props {
  title: string;
  redirectTo: string;
  description: string;
  metrics: Array<YoutubeMetricsPanelItem>;
  toolBarConfig?: Array<ToolBarItem>;
  notice?: string | null | React.ReactNode;
  yandexZenChannelId: string | null;
}

export const PreviewImage = createSlot();
const MAX_DESCRIPTION_LENGTH = 200;
export const YoutubeChannelOrVideoPreview: React.FC<Props> = ({
                                                                title,
                                                                description,
                                                                redirectTo,
                                                                metrics,
                                                                notice,
                                                                children,
                                                                toolBarConfig,
                                                                yandexZenChannelId
                                                              }) => {
  const intl = useIntl();

  const renderButtons = () => {
    if (!toolBarConfig) {
      return null;
    }
    return (
      <>
        <Toolbar items={toolBarConfig}/>
      </>
    );
  };

  const [isCutted, setCutted] = useState<boolean>(true);
  return (
    <div className={`card card-custom gutter-b`}>
      <div className='card-header d-flex border-0 w-100 pt-6'>
        <AlertCustom
          text={notice || ''}
          iconClassName={'svg-icon-danger'}
          type={BootstrapColors.LIGHT_DANGER}
          className={'save-line-and-tabulation mb-6 w-100'}
          visible={!!notice}
        />
        <div className={'d-flex flex-column flex-xl-row w-100'}>
          <PreviewImage.Renderer childs={children}/>
          <div className='d-flex justify-content-between flex-column w-100'>
            <div>
              <a href={redirectTo} target={'_blank'} className='font-weight-bolder font-size-h1' rel='noreferrer'>
                {title}
              </a>
            </div>
            <div className={'row max-w-700px'}>
              <YoutubeMetricsPanel items={metrics}/>
            </div>
          </div>
        </div>
        <div style={{marginBottom: '30px', marginTop: '20px'}}>
          {renderButtons()}
        </div>
      </div>
      <div className='card-body'>
        {yandexZenChannelId && (
          <p>
            <span className='font-weight-bolder font-size-sm'>
              {intl.formatMessage({id: 'YANDEX_ZEN_CHANNEL_ID'})}:
            </span>
            <span className='font-size-sm'> {yandexZenChannelId}</span>
          </p>
        )}
        {description.trim().length !== 0 && (
          <p className='text-muted mt-3 pr-5 font-weight-bold font-size-lg save-line-and-tabulation'>
            {isCutted ? trimStringWithEllipsis(description, MAX_DESCRIPTION_LENGTH) : description}
            &nbsp;
            {description.length >= MAX_DESCRIPTION_LENGTH && (
              <a href='#' onClick={() => setCutted(prevState => !prevState)}>
                <FormattedMessage id={isCutted ? 'SHOW_ALL' : 'HIDE'}/>
              </a>
            )}
          </p>
        )}
      </div>
    </div>
  );
};
