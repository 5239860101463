import React from 'react';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {capitalizeFirstLetter} from '../../../utils/utils';
import cn from 'classnames';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ChartMetricDomainColorMap, ChartMetricSocialStreamingUrl} from '../../../api/DTOs/Chartmetric/CMArtistMetadata';

type Props = {
  urls: Array<ChartMetricSocialStreamingUrl>;
};

export const SocialStreamingUrls: React.FC<Props> = ({urls}) => {
  return (
    <>
      <Toolbar
        items={urls.map(item => {
          const [color, icon] = ChartMetricDomainColorMap[item.domain] ?? [BootstrapColors.SECONDARY, null];
          return item.url.length === 1
            ? {
                type: 'BUTTON',
                title: (
                  <span>
                    {icon !== null && <i className={`fab fa-${icon}`} />} {capitalizeFirstLetter(item.domain)}
                  </span>
                ),
                className: cn('btn font-weight-bolder btn-sm', `btn-${color}`),
                onClick: () => window.open(item.url[0], '_blank'),
              }
            : {
                type: 'DROPDOWN',
                title: (
                  <span>
                    {icon !== null && <i className={`fab fa-${icon}`} />} {capitalizeFirstLetter(item.domain)}
                  </span>
                ),
                className: cn('btn btn-sm font-weight-bolder', `btn-${color}`),
                actions: item.url.map((url, index) => {
                  return {title: 'URL #' + (index + 1), value: url};
                }),
                onSelect: value => window.open(value, '_blank'),
              };
        })}
      />
    </>
  );
};
