import React, {useCallback} from 'react';
import {InputText} from './InputText';

type Props = {
  label?: string;
  disabled?: boolean;
  hasError?: boolean;
  maxNumber?: number;
  minNumber?: number;
  step?: number;
  value: string | number | null;
  onChange: (value: string | number | null) => any;
};
export const InputNumeric: React.FC<Props> = ({
  label,
  disabled,
  hasError,
  maxNumber,
  minNumber,
  step,
  value,
  onChange,
}) => {
  const handleChangeRatePercent = useCallback((value: string | number | null) => {
    if (value == '' && value == null) {
      onChange(null as any);
      return;
    }

    let preparedValue: string;
    const parts = value?.toString().split('.') ?? [];
    if (parts.length === 2) {
      preparedValue = parts[0] + '.' + parts[1].slice(0, 1);
    } else {
      preparedValue = parts[0];
    }

    onChange(preparedValue as any);
  }, []);

  return (
    <InputText
      label={label}
      disabled={disabled}
      hasError={hasError}
      maxNumber={maxNumber}
      minNumber={minNumber}
      step={step}
      type={'number'}
      classNames={'w-100 form-control'}
      value={value}
      onChange={e => handleChangeRatePercent(e.currentTarget.value)}
    />
  );
};
