import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

import {UpdateFields} from '../../../../hooks/use-advanced-state';
import {CreateTransactionToOurOrganizationFields} from '../../../../hooks/apis/use-transactions-api';
import {PartialNullable} from '../../../../../types/types';
import {SelectOptions} from '../../../Inputs/InputSelect';
import {ValidationErrorsType} from '../../../../utils/utils';
import {AlertCustom} from '../../../../modules/Auth/component/alert';
import {useIntl} from '../../../../hooks/use-intl';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../base-modal/base-modal';
import {TransactionAction} from '../../../../pages/admin/transactions/transaction-action-types';
import {CreateTransactionFields} from '../create-transaction-fields';
import {FormattedMessage} from 'react-intl';

type Props = {
  visible: boolean;
  error?: string | null;
  validationErrors?: ValidationErrorsType;
  contractors: SelectOptions;
  contracts: SelectOptions | null;
  loadings: {
    contractors: boolean;
    contracts: boolean;
  };

  entity: PartialNullable<CreateTransactionToOurOrganizationFields>;
  onChange: UpdateFields<CreateTransactionToOurOrganizationFields>;

  onHide(): void;
  onOkClick(): any;
};

export const ModalCreateToOurOrganizationTransaction: React.FC<Props> = ({
  loadings,
  visible,
  contracts,
  entity,
  contractors,
  error,
  validationErrors,
  onChange,
  onOkClick,
  onHide,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={handleOkClick} onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>
          {intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION})}
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <div className={'font-weight-bolder mb-5'}>
          <FormattedMessage id={`CREATE_TRANSACTION_DESCRIPTION_${TransactionAction.CREATE_TO_OUR_ORGANIZATION}`} />
        </div>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <CreateTransactionFields
            entity={entity}
            onChange={onChange}
            validationErrors={validationErrors}
            error={error}
            contractors={contractors}
            contracts={contracts ?? []}
            loadings={loadings}
          />
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
