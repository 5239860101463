import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IQueryParams} from '../DTOs/IFilterDtos';
import {IResponseBase, IResponseMultipleContent} from '../response-contracts/base-response';
import {IMailingDto} from './IMailingDto';
import {IMailingPreviewDto} from './IMailingPreviewDto';

export class MailingsApi extends BaseEndpoint {
  public async getMailingList(queryParams?: IQueryParams): Promise<IResponseMultipleContent<IMailingDto>> {
    const query = this.applyQueryParams(queryParams);
    const response = await this.get(`${config.BASE_API_URL}/mailings?${query}`);
    return response.data;
  }

  public async getMailing(mailingId: number): Promise<IResponseBase<{item: IMailingDto}>> {
    const response = await this.get(`${config.BASE_API_URL}/mailings/${mailingId}`);
    return response.data;
  }

  public async getPreview(mailing: IMailingDto): Promise<IResponseBase<{item: IMailingPreviewDto}>> {
    const response = await this.post(`${config.BASE_API_URL}/mailings/preview`, {
      fields: {
        ...mailing,
      },
    });

    return response.data;
  }

  public async createMailing(mailing: IMailingDto): Promise<IResponseBase<{item: IMailingDto}>> {
    const response = await this.post(`${config.BASE_API_URL}/mailings`, {
      fields: {
        ...mailing,
      },
    });

    return response.data;
  }

  public async sendMailing(mailingId: number, userIds: Array<string>): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/mailings/${mailingId}/send`, {
      fields: {
        users_ids: userIds,
      },
    });

    return response.data;
  }

  public async deleteMailing(mailingId: number): Promise<IResponseBase> {
    const response = await this.delete(`${config.BASE_API_URL}/mailings/${mailingId}`);
    return response.data;
  }

  public async updateMailing(mailing: IMailingDto): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/mailings/${mailing.id}`, {
      fields: {
        ...mailing,
      },
    });

    return response.data;
  }
}
