import React from 'react';
import {ArtistAction} from './useArtistHandlerActions';
import {Artist} from '../../api/DTOs/Artist';
import {useIntl} from '../../hooks/use-intl';
import {RowActionButton} from '../../components/table-actions-button';
import {TableUtils} from '../../components/table-utils';
import {ManyPrivileges, ManyRelations} from '../../api/base/base-crud-api-response';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapSizes} from '../../../styles/bootstap-colors';
import {Link} from 'react-router-dom';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {ArtistPrivileges, ArtistRelation} from '../../hooks/apis/use-artists-api';
import {Table} from '../../components/table';
import {useAuth} from '../../hooks/use-auth';

interface ArtistsTableProps {
  items: Array<Artist>;
  relations: ManyRelations<ArtistRelation> | null;
  privileges: ManyPrivileges<ArtistPrivileges> | null;
  onAction: (item: Artist, action: ArtistAction) => Promise<unknown>;
}

export const ArtistsTable = ({items, relations, privileges, onAction}: ArtistsTableProps) => {
  const {isAdmin} = useAuth();
  const intl = useIntl();
  return (
    <Table
      data={items}
      columns={[
        {
          id: 'Preview',
          forceApplySize: true,
          width: 80,
          maxWidth: 80,
          Cell: ({value}) => {
            if (!relations?.imageFile?.[value.id]?.url) {
              return <Svg src={ICONS.USER} size={BootstrapSizes.X4} />;
            }
            return (
              <img
                width={50}
                height={50}
                src={relations?.imageFile?.[value.id]?.url}
                className={'rounded'}
                alt={intl.formatMessage({id: 'PREVIEW'})}
              />
            );
          },
        },
        {
          id: 'Title',
          Header: intl.formatMessage({id: 'TITLE'}),
          maxWidth: 450,
          minWidth: 300,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(<Link to={Routes.getSpecifyArtistsRoute(value.id)}>{value.name}</Link>);
          },
        },
        {
          id: 'Contractor',
          Header: intl.formatMessage({id: 'CONTRACTOR'}),
          visible: isAdmin,
          maxWidth: 450,
          minWidth: 300,
          Cell: ({value}) => {
            if (!value.contractor_id || relations?.contractor?.[value.contractor_id] == null) {
              return null;
            }

            const contractor = relations?.contractor?.[value.contractor_id];
            return TableUtils.renderBaseContent(
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{contractor.calculated_name}</Link>,
            );
          },
        },
        {
          id: 'Created_At',
          Header: intl.formatMessage({id: 'CREATED_AT'}),
          maxWidth: 100,
          minWidth: 100,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <>
                {intl.formatDate(value.created_at)} {intl.formatTime(value.created_at)}
              </>,
            );
          },
        },
        {
          id: 'Updated_at',
          Header: intl.formatMessage({id: 'UPDATED_AT'}),
          maxWidth: 100,
          minWidth: 100,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <>
                {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
              </>,
            );
          },
        },
        {
          id: 'Action',
          maxWidth: 50,
          Cell: ({value}) => {
            const actions = [];
            if (privileges?.[value.id]?.can_edit) {
              actions.push({title: intl.formatMessage({id: 'EDIT'}), value: ArtistAction.EDIT});
            }

            if (privileges?.[value.id]?.can_delete) {
              actions.push({title: intl.formatMessage({id: 'DELETE'}), value: ArtistAction.DELETE});
            }

            return TableUtils.renderActionButton(
              <RowActionButton rowDto={value} actions={actions} onSelect={onAction} />,
            );
          },
        },
      ]}
    />
  );
};
