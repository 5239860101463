import React from 'react';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {useIntl} from '../../hooks/use-intl';

import {EntityId} from '../../api/base/BaseEntity';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {ICONS} from '../../images/images';
import {
  HandleRegistrationModerateActionPayload,
  ModerateRegistrationAction,
  useRegistrationModerateActionHandler,
} from './useRegistractionModerateActionsHandler.ts';

type Props = {
  contractorId: EntityId;
  fetch: (params: IQueryParams) => Promise<void>;
  className?: string;
};

export const DataValidationToolBar: React.FC<Props> = ({contractorId, fetch, className}) => {
  const intl = useIntl();
  const {handleActions} = useRegistrationModerateActionHandler(fetch);
  const handle = (action: HandleRegistrationModerateActionPayload) => {
    return () => handleActions(action);
  };
  return (
    <Toolbar
      className={className}
      items={[
        {
          type: 'BUTTON',
          icon: ICONS.CHECK,
          className: 'btn btn-light-success font-weight-bolder',
          title: intl.formatMessage({id: 'CONFIRM'}),
          onClick: handle({contractorId, type: ModerateRegistrationAction.CONFIRM}),
        },
        {
          type: 'BUTTON',
          icon: ICONS.CROSS,
          className: 'btn btn-light-danger font-weight-bolder',
          title: intl.formatMessage({id: 'REJECT'}),
          onClick: handle({contractorId, type: ModerateRegistrationAction.REJECT}),
        },
        {
          type: 'BUTTON',
          icon: ICONS.EDIT,
          className: 'btn btn-light-primary font-weight-bolder',
          title: intl.formatMessage({id: 'REQUEST_FIX_REQUIREMENTS'}),
          onClick: handle({contractorId, type: ModerateRegistrationAction.NEED_FIX_REQUIREMENTS}),
        },
      ]}
    />
  );
};
