import React from 'react';
import SVG from 'react-inlinesvg';
import {FinancialReportType} from '../api/financial-report-api/FinancialBulkReports';
import {FormattedMessage} from 'react-intl';
import {UploadedFileMetadata} from './report-file-uploader';
import {formatBytes} from '../utils/utils';

const ICONS = {
  FILE: require('../images/svg/File.svg'),
};

interface IUploadedFileProps {
  title: string;
  type: FinancialReportType;
  className?: string;
  fileInfo?: UploadedFileMetadata;

  onDelete?(type: FinancialReportType): void;
}

export const UploadedFile = ({title, onDelete, className, fileInfo, type}: IUploadedFileProps) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDelete) onDelete(type);
  };

  const renderCloseRibbon = () => {
    if (!onDelete) {
      return null;
    }

    return (
      <>
        <div onClick={handleClick} className='ribbon-target bg-danger' style={{top: '-1px', right: '0px'}}>
          <i className='ki ki-close text-light' />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`card card-custom card-stretch bg-secondary ribbon ribbon-top mb-8 mb-lg-0 ${className}`}>
        {renderCloseRibbon()}
        <div className='card-body'>
          <div className='d-flex align-items-center h-100'>
            <div className='mr-6'>
              <span className={`svg-icon svg-icon-secondary svg-icon-4x`}>
                <SVG src={ICONS.FILE} />
              </span>
            </div>
            <div className='d-flex flex-column p-1 overflow-auto text-left'>
              <div className='text-dark text-hover-primary font-weight-bold font-size-h6 mw-100 mb-3 '>{title}</div>
              <div className='text-dark-75'>
                {fileInfo && (
                  <>
                    <div>
                      <span className={'font-weight-bolder'}>
                        <FormattedMessage id={'SIZE'} />
                      </span>
                      : {formatBytes(fileInfo.SIZE)}
                    </div>
                    <div>
                      <span className={'font-weight-bolder'}>
                        <FormattedMessage id={'REPORT_TYPE'} />
                      </span>
                      : {fileInfo.REPORT_TYPE}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
