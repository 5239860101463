import React, {useEffect, useState} from 'react';
import {UpdateFields, useAdvancedState} from '../../../../../hooks/use-advanced-state';
import {useIntl} from '../../../../../hooks/use-intl';
import {useContractsApi} from '../../../../../hooks/apis/use-contracts-api';
import {useSelectApi} from '../../../../../hooks/use-select-api';
import {CreateEditContractForm} from './create-edit-contract-form';
import {useHistory, useParams} from 'react-router-dom';
import {AdminRoutes} from '../../../../../../configs/routes';
import {isValidationException} from '../../../../../utils/api-utils';
import {ApiRequestException} from '../../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {Card, CardBody, CardFooter, CardTitle} from '../../../../../components/card';
import {ICONS} from '../../../../../images/images';
import {Toolbar} from '../../../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../../../hooks/use-loading';
import {useContractorsApi} from '../../../../../hooks/apis/use-contractors-api';
import {SelectOptions} from '../../../../../components/Inputs/InputSelect';
import {Preloader} from '../../../../../components/preloader';
import {Contract} from '../../../../../api/contract-api/Contract';
import {ARRAY} from '../../../../../consts';
import {useCustomBreadcrumbs} from '../../../../../components/breadcrumbs/breadcrumbs-context';
import {prepareDate, tryGetDateOrNull} from '../../../../../utils/utils';
import {
  prepareChannelDataFromResponse,
  prepareChannelDataToRequest,
} from '../../sup-agreements/create-edit-sup-agreement-page';

export const EditContractPage: React.FC = () => {
  const intl = useIntl();
  const {id: contractId} = useParams<{id: string}>();
  const history = useHistory();
  const api = useContractsApi();
  const contractorApi = useContractorsApi();
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const [loadings, startLoading, stopLoading] = useLoading({submit: false, ourContractor: true, contractor: true});
  const {selectsOptions, fetchSelectValuesByKey, optionsLoadings} = useSelectApi();
  const [fields, setFields, updateFields] = useAdvancedState<Contract>({is_signed: false}, api.setValidationErrors);

  const [ourContractors, setOurContractors] = useState<SelectOptions>(ARRAY.EMPTY);

  useEffect(() => {
    if (fields.id) {
      setBreadcrumb(fields.composite_number ?? '');
    }
  }, [fields.id]);

  useEffect(() => {
    fetchSelectValuesByKey('contractors').then();
    contractorApi
      .getAll({filters: {is_our: true}})
      .then(res => {
        setOurContractors(res.items.map(c => ({value: c.id, label: c.legal_name as string})));
      })
      .finally(() => stopLoading('ourContractor'));
  }, []);

  useEffect(() => {
    fetchContract().then();
  }, [contractId]);

  const fetchContract = async () => {
    try {
      startLoading('contractor');
      const result = await api.get(contractId);
      setFields({
        ...result.item,
        add_youtube_channels: prepareChannelDataFromResponse(result.item.add_youtube_channels),
      });
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('contractor');
    }
  };

  const handleEdit: UpdateFields<Contract> = updatedFields => {
    if (updatedFields instanceof Function) {
      updateFields(updatedFields);
    } else if (updatedFields.contractor_id) {
      updateFields({...updatedFields, add_videos: ARRAY.EMPTY});
    } else {
      updateFields(updatedFields);
    }
  };

  const handleSubmit = async () => {
    try {
      startLoading('submit');
      const createdContract = await api.update(contractId, {
        ...fields,
        add_youtube_channels: prepareChannelDataToRequest(fields.add_youtube_channels ?? []),
        started_at: prepareDate(tryGetDateOrNull(fields.started_at)),
        expires_at: prepareDate(tryGetDateOrNull(fields.expires_at)),
      });
      history.push(AdminRoutes.getSpecifyContractRoute(createdContract.id));
    } catch (e) {
      if (!isValidationException(e as ApiRequestException)) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submit');
    }
  };

  return (
    <Card>
      <CardTitle>{intl.formatMessage({id: 'EDIT_CONTRACT'})}</CardTitle>
      <CardBody>
        {loadings.contractor ? (
          <Preloader />
        ) : (
          <div style={{maxWidth: '1420px'}}>
            <CreateEditContractForm
              fields={fields}
              updateFields={handleEdit}
              contractors={selectsOptions.contractors}
              loadingContractors={optionsLoadings.contractors}
              validationErrors={api.validationErrors}
              ourContractors={ourContractors}
              loadingOurContractors={loadings.ourContractor}
            />
          </div>
        )}
      </CardBody>
      <CardFooter>
        <Toolbar
          items={[
            {
              type: 'BUTTON',
              loading: loadings.submit,
              disabled: loadings.submit,
              title: intl.formatMessage({id: 'SAVE'}),
              className: 'btn btn-light-primary',
              icon: ICONS.DOWNLOAD,
              onClick: handleSubmit,
            },
          ]}
        />
      </CardFooter>
    </Card>
  );
};
