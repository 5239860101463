import React, {createContext, useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {SupportTicketsApi} from '../../../api/support-tickets-api/support-tickets-api';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalCreateSupportTicket} from './modal-create-support-ticket';
import {Routes} from '../../../../configs/routes';
import {SupportTicketType} from '../../../api/support-tickets-api/ISupportTicketDto';
import {ValidationException} from '../../../api/exceptions/ValidationException';

interface ICreateSupportTicketContextValues {
  modalCreateSupportTicketVisible: boolean;

  showCreateSupportTicketModal(): Promise<CloseModalEvent<number>>;
}

// @ts-ignore
const ModalCreateSupportTicketContext = createContext<ICreateSupportTicketContextValues>();

let closeResolver: ((data: CloseModalEvent<number>) => any) | null = null;
export const ModalCreateSupportTicketProvider = ({children}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const api = new SupportTicketsApi();

  const [visible, setVisible] = useState<boolean>(false);
  const [topic, setTopic] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [type, setType] = useState<SupportTicketType>(SupportTicketType.QUESTION);

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const showModal = async () => {
    setVisible(true);

    return new Promise<CloseModalEvent<number>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setTopic('');
    setText('');
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleSendButtonClick = async () => {
    try {
      setError(null);
      const response = await api.createTicket(topic, text, type);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      handleHideModal();
      history.push(Routes.getSupportTicketRoute(response.data.item.id));
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleChangeTicketTheme = (theme: string) => {
    setTopic(theme);
  };

  const handleChangeTicketText = (text: string) => {
    setText(text);
  };

  const handleChangeTicketType = (type: SupportTicketType) => {
    setType(type);
  };

  const value: ICreateSupportTicketContextValues = {
    modalCreateSupportTicketVisible: visible,
    showCreateSupportTicketModal: showModal,
  };

  return (
    <ModalCreateSupportTicketContext.Provider value={value}>
      <ModalCreateSupportTicket
        visible={visible}
        topic={topic}
        ticketType={type}
        message={text}
        error={error}
        validationErrors={validationErrors}
        onCloseModal={handleHideModal}
        onSendButtonClick={handleSendButtonClick}
        onChangeTicketTheme={handleChangeTicketTheme}
        onChangeTicketText={handleChangeTicketText}
        onChangeTicketType={handleChangeTicketType}
      />
      {children}
    </ModalCreateSupportTicketContext.Provider>
  );
};

export const useModalCreateSupportTicket = () => {
  return useContext(ModalCreateSupportTicketContext);
};
