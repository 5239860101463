import React, {CSSProperties, PropsWithChildren} from 'react';
import cn from 'classnames';
import {createSlot} from 'react-slotify';

export const CardTitle = createSlot();
export const CardToolbar = createSlot();
export const CardBody = createSlot();
export const CardFooter = createSlot();

export type CardProps = {
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideBody?: boolean;
  style?: CSSProperties;
  className?: {
    card?: string;
    header?: string;
    body?: string;
    footer?: string;
  };
};

export const Card = ({children, className, hideFooter, hideHeader, hideBody, style}: PropsWithChildren<CardProps>) => {
  return (
    <>
      <div className={cn('card card-custom gutter-b', className?.card)} style={style}>
        {(hideHeader === undefined || !hideHeader) && (
          <div className={cn('card-header', className?.header)} style={{flexWrap: 'nowrap'}}>
            <div className={'card-title'}>
              <div className={'mr-4'}>
                <CardTitle.Renderer childs={children} />
              </div>
            </div>
            <div className={'card-toolbar justify-content-center'}>
              <CardToolbar.Renderer childs={children} />
            </div>
          </div>
        )}

        {(hideBody == null || !hideBody) && (
          <div className={cn('card-body', className?.body)}>
            <CardBody.Renderer childs={children} />
          </div>
        )}

        {(hideFooter === undefined || !hideFooter) && (
          <div className={cn('card-footer', className?.footer)}>
            <CardFooter.Renderer childs={children} />
          </div>
        )}
      </div>
    </>
  );
};
