import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {Routes} from '../../../../configs/routes';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {useLoading} from '../../../hooks/use-loading';
import {RegistrationConfirmation} from './registration-confirmation';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {RegistrationForm, RegistrationFormFields} from './registration-form';
import {ValidationErrorsType} from '../../../utils/utils';
import {useAuth} from '../../../hooks/use-auth';
import {useAuthApi} from '../../../hooks/apis/auth-api';

export const Registration = () => {
  const intl = useIntl();
  const {setCurrentContractor, setCurrentGlobalPrivileges, setCurrentUser} = useAuth();
  const authApi = useAuthApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    submitRegister: false,
    submitConfirmationCode: false,
  });

  const [error, setError] = useState<string | null>(null);
  const [errorRegisterConfirmation, setRegisterConfirmationError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>(null);
  const [formFields, , updateFields] = useAdvancedState<RegistrationFormFields>({}, setValidationErrors);

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);

  const sendRegisterRequest = async (e: React.MouseEvent<HTMLElement>, isAgain = false) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      startLoading('submitRegister');
      setValidationErrors(null);
      setError(null);
      await authApi.checkCanRegister(formFields);
      setShowCodeConfirmation(true);
    } catch (e) {
      const err = e as ApiRequestException;
      if (isAgain) {
        setErrorForCodeBlock(err);
        return;
      }
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submitRegister');
    }
  };

  const sendConfirmationCode = async (confirmationCode: string) => {
    try {
      startLoading('submitConfirmationCode');
      setValidationErrors(null);
      setRegisterConfirmationError(null);
      const result = await authApi.register({...formFields, code: confirmationCode});
      setCurrentGlobalPrivileges(result.data.privileges);
      setCurrentContractor(result.data.relations.contractor);
      setCurrentUser(result.data.item);
    } catch (e) {
      setErrorForCodeBlock(e as ApiRequestException);
    } finally {
      stopLoading('submitConfirmationCode');
    }
  };

  const setErrorForCodeBlock = (err: ApiRequestException) => {
    if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
      if (Object.keys((err.innerException as ValidationException).error_data.messages).includes('code')) {
        setRegisterConfirmationError(intl.formatMessage({id: 'CONFIRMATION_CODE_IS_INVALID'}));
      } else {
        setRegisterConfirmationError(intl.formatMessage({id: 'CREDENTIALS_ARE_OUT_OF_DATE'}));
      }
    } else {
      setRegisterConfirmationError(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const handleCloseConfirmRegistrationBlock = () => {
    setError(null);
    setValidationErrors(null);
    setRegisterConfirmationError(null);
    updateFields({password: null, password_confirmation: null});
    setShowCodeConfirmation(false);
  };

  const renderRegistrationBlock = () => {
    return (
      <>
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1'>
            <FormattedMessage id='AUTH.REGISTER.TITLE' />
          </h3>
          <p className='text-muted font-weight-bold'>
            <FormattedMessage id='AUTH.REGISTER.DESC' />
          </p>
        </div>
        <RegistrationForm
          error={error}
          validationErrors={validationErrors}
          fields={formFields}
          acceptTerms={acceptTerms}
          onAcceptTerms={setAcceptTerms}
          onChangeField={updateFields}
        />
        <div className='form-group d-flex flex-wrap flex-center'>
          <button
            type='submit'
            onClick={sendRegisterRequest}
            disabled={loadings.submitRegister || !acceptTerms}
            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'>
            <span>
              <FormattedMessage id='BUTTONS.SUBMIT' />
            </span>
            {loadings.submitRegister && <span className='ml-3 spinner spinner-white' />}
          </button>

          <Link to={Routes.getLoginRoute()}>
            <button type='button' className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
              <FormattedMessage id='BUTTONS.CANCEL' />
            </button>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className='login-form login-signin' style={{display: 'block'}}>
      {showCodeConfirmation ? (
        <RegistrationConfirmation
          loading={loadings.submitConfirmationCode || loadings.submitRegister}
          error={errorRegisterConfirmation}
          email={formFields?.email ?? null}
          onChange={() => setRegisterConfirmationError(null)}
          onClose={handleCloseConfirmRegistrationBlock}
          onSendCode={sendConfirmationCode}
          onSendCodeToMailAgain={e => sendRegisterRequest(e, true)}
        />
      ) : (
        renderRegistrationBlock()
      )}
    </div>
  );
};
