import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {AlertCustom} from '../component/alert';
import {ApiRequestException} from '../../../api/axios-instance';
import {Routes} from '../../../../configs/routes';
import {toast} from 'react-toastify';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {getValidationErrorMessage} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {useAuthApi} from '../../../hooks/apis/auth-api';

interface IRouteMatchParams {
  token: string;
}

export const ResetPassword: React.FC = () => {
  const intl = useIntl();
  const match = useRouteMatch<IRouteMatchParams>();

  const api = useAuthApi();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{
    [key: string]: Array<string>;
  } | null>(null);

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  useEffect(() => {
    if (getValidationErrorMessage('reset_password_token', validationErrors) !== null) {
      setError(intl.formatMessage({id: 'RESET_PASSWORD_TOKEN_EXPIRED'}));
    }
  }, [validationErrors]);

  const sendResetPasswordRequest = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setError(null);
      setValidationError(null);
      await api.resetPassword({
        reset_password_token: match.params.token,
        new_password: password,
        new_password_confirmation: passwordConfirmation,
      });
      toast.success(intl.formatMessage({id: 'SUCCESS_CHANGE_PASSWORD'}));
      setTimeout(() => {
        redirectToLoginPage();
      }, 200);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const redirectToLoginPage = () => {
    window.location.replace(Routes.getLoginRoute());
  };

  return (
    <>
      <div className='login-form login-forgot' style={{display: 'block'}}>
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1'>
            <FormattedMessage id='CREATE_NEW_PASSWORD' />
          </h3>
        </div>

        <form className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
          <AlertCustom
            visible={error != null}
            text={error}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
          />

          <ValidateErrorWrapper message={validationErrors && validationErrors['new_password']}>
            <InputText
              name={'new_password'}
              type={'password'}
              value={password}
              hasError={validationErrors?.new_password != undefined}
              onChange={e => setPassword(e.currentTarget.value)}
              label={intl.formatMessage({id: 'NEW_PASSWORD'})}
              placeholder={intl.formatMessage({id: 'NEW_PASSWORD'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={validationErrors && validationErrors['new_password_confirmation']}>
            <InputText
              name={'new_password_confirmation'}
              type={'password'}
              label={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
              value={passwordConfirmation}
              hasError={validationErrors?.new_password_confirmation != undefined}
              onChange={e => setPasswordConfirmation(e.currentTarget.value)}
              placeholder={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>

          <div className='form-group d-flex flex-wrap flex-center'>
            <button
              onClick={sendResetPasswordRequest}
              type='submit'
              className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
              disabled={loading}>
              <FormattedMessage id={'SUBMIT'} />
              {loading && <span className='ml-3 spinner spinner-white' />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
