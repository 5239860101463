import React, {useEffect, useMemo, useState} from 'react';
import {BaseListPage} from '../base/base-list-page';
import {IQueryParams, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {useIntl} from '../../hooks/use-intl';
import {useLoading} from '../../hooks/use-loading';
import {ICONS} from '../../images/images';
import {Video} from '../../api/DTOs/Video';
import {useVideoActionHandler, VideoAction} from './use-video-handler-actions';
import {VideosTable} from './videos-table';
import {useVideosApi, VideoPrivileges, VideoRelations} from '../../hooks/apis/use-videos-api';
import {ManyPrivileges, ManyRelations} from '../../api/base/base-crud-api-response';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {useSelectApi} from '../../hooks/use-select-api';
import {useAuth} from '../../hooks/use-auth';
import {VIDEO_STATUS_COLOR_MAP} from './video-page/video-page';
import {useToggle} from '../../hooks/use-toggle';
import {VideoCard} from './video-card';
import cn from 'classnames';
import {getEnumKeys} from '../../../types/types';
import {Feed} from '../../api/DTOs/Feed';
import {StringParam, withDefault} from 'use-query-params';
import {EmptyListState} from '../../components/empty-list-state';
import {IPaginationInfo} from '../../api/Paginator';

export const VideosPage: React.FC = () => {
  const intl = useIntl();
  const api = useVideosApi();
  const [enableCardView, toggleView] = useToggle(false);
  const {optionsLoadings, selectsOptions, fetchSelectValuesByKey} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    videos: true,
  });

  const {
    privileges: {can_select_contractor_for_video},
  } = useAuth();
  const isAdmin = useAuth().isAdmin;
  const [videos, setVideos] = useState<Array<Video>>([]);
  const [relations, setRelations] = useState<ManyRelations<VideoRelations> | null>(null);
  const [privileges, setPrivileges] = useState<ManyPrivileges<VideoPrivileges>>({});
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'videos-sort',
      defaultOptions: {
        field: SortField.CREATED_AT,
        direction: SortDirection.DESC,
      },
      options: [
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: A-z`,
          value: {field: SortField.TITLE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: Z-a`,
          value: {field: SortField.TITLE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_FIRST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_LAST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      allSelectValues: selectsOptions,
      loadingSelect: optionsLoadings,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'title',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          type: FilterType.TEXT,
          name: 'code',
          placeholder: intl.formatMessage({id: 'CODE'}),
        },
        {
          type: FilterType.SELECT,
          name: 'artist_id',
          selectKey: 'artists',
          placeholder: intl.formatMessage({id: 'ARTISTS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'genre_id',
          selectKey: 'genres',
          placeholder: intl.formatMessage({id: 'GENRES'}),
        },
        {
          type: FilterType.SELECT,
          name: 'feed',
          options: getEnumKeys(Feed).map(f => ({label: intl.formatMessage({id: 'FEED_' + f}), value: f})),
          placeholder: intl.formatMessage({id: 'FEEDS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          options: Object.keys(VIDEO_STATUS_COLOR_MAP).map(status => ({
            value: status,
            label: intl.formatMessage({id: `VIDEO_STATUS_${status}`}),
          })),
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
        {
          visible: can_select_contractor_for_video,
          type: FilterType.SELECT,
          name: 'contractor_id',
          selectKey: 'contractors',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          visible: isAdmin,
          type: FilterType.SELECT,
          name: 'active_contract',
          queryConfig: {
            name: 'active_contract',
            type: withDefault(StringParam, ''),
          },
          placeholder: intl.formatMessage({id: 'CONTRACT_EXISTENCE'}),
          options: [
            {
              value: 'HAS_CONTRACT',
              label: intl.formatMessage({id: 'HAS_CONTRACT'}),
            },
            {
              value: 'HAS_NO_CONTRACT',
              label: intl.formatMessage({id: 'HAS_NO_CONTRACT'}),
            },
          ],
        },
        {
          visible: isAdmin,
          type: FilterType.SELECT,
          name: 'rights_expiration',
          queryConfig: {
            name: 'rights_expiration',
            type: withDefault(StringParam, ''),
          },
          placeholder: intl.formatMessage({id: 'RIGHTS'}),
          options: [
            {
              value: 'ACTIVE_RIGHTS',
              label: intl.formatMessage({id: 'ACTIVE_RIGHTS'}),
            },
            {
              value: 'EXPIRED_RIGHTS',
              label: intl.formatMessage({id: 'EXPIRED_RIGHTS'}),
            },
            {
              value: 'DOESNT_HAVE_EXPIRED_DATE_RIGHTS',
              label: intl.formatMessage({id: 'DOESNT_HAVE_EXPIRED_DATE_RIGHTS'}),
            },
          ],
        },
      ],
    });
  }, [selectsOptions, can_select_contractor_for_video, optionsLoadings]);

  useEffect(() => {
    const fetchSelects = async () => {
      // noinspection ES6MissingAwait
      const promises = [fetchSelectValuesByKey('artists'), fetchSelectValuesByKey('genres')];
      if (can_select_contractor_for_video) {
        promises.push(fetchSelectValuesByKey('contractors'));
      }
      await Promise.all(promises);
    };

    fetchSelects().then();
  }, []);

  const fetchVideos = async (params?: IQueryParams) => {
    try {
      startLoading('videos');
      const result = await api.getAll(params);
      result.privileges[0]?.can_edit;
      setRelations(result.relations);
      setPaginationInfo(result.paginator);
      setPrivileges(result.privileges);
      setVideos(result.items);
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('videos');
    }
  };
  const {handleVideoActions} = useVideoActionHandler(fetchVideos);

  const handleTableAction = async (video: Video, action: VideoAction) => {
    switch (action) {
      case VideoAction.CREATE:
        await handleVideoActions({type: VideoAction.CREATE});
        break;
      case VideoAction.DELETE:
        await handleVideoActions({type: VideoAction.DELETE, entityId: video.id});
        break;
      case VideoAction.EDIT:
        await handleVideoActions({type: VideoAction.EDIT, entity: video});
        break;
    }
  };

  return (
    <BaseListPage
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      loading={loadings.videos}
      fetchData={fetchVideos}
      className={{cardBody: cn({'bg-light': enableCardView})}}
      toolbarConfig={[
        {
          type: 'SWITCH',
          size: 'sm',
          titles: [intl.formatMessage({id: 'TABLE'}), intl.formatMessage({id: 'CARDS'})],
          checked: enableCardView,
          onChange: toggleView,
        },
        {
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-primary',
          onClick: () => handleVideoActions({type: VideoAction.CREATE}),
        },
      ]}>
      {enableCardView ? (
        <div className='row'>
          {videos.length > 0 ? (
            videos.map(video => {
              let url = '';
              if (
                video.watermarked_image_file_id &&
                relations?.watermarkedImageFile?.[video.watermarked_image_file_id]?.url != null
              ) {
                url = relations?.watermarkedImageFile?.[video.watermarked_image_file_id]?.url as string;
              } else if (video.image_file_id && relations?.imageFile?.[video.image_file_id]?.url != null) {
                url = relations?.imageFile?.[video.image_file_id]?.url as string;
              }

              return (
                <div key={video.id} className='col-xxxl-3 col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <VideoCard
                    video={video}
                    artists={video.artists_ids
                      .map(a => relations?.artists?.[a]?.name)
                      .filter(a => !!a)
                      .join(', ')}
                    previewUrl={url}
                  />
                </div>
              );
            })
          ) : (
            <EmptyListState />
          )}
        </div>
      ) : (
        <VideosTable items={videos} relations={relations} privileges={privileges} onAction={handleTableAction} />
      )}
    </BaseListPage>
  );
};
