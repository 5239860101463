import React, {useCallback, useState} from 'react';
import {FileUploader} from '../../../../components/file-uploader';
import {
  ContractualDocument,
  ContractualDocumentType,
  useContractualDocumentsApi,
} from '../../../../hooks/apis/use-contractual-documents-api';
import {Contract} from '../../../../api/contract-api/Contract';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {useLoading} from '../../../../hooks/use-loading';
import {Preloader} from '../../../../components/preloader';
import {useFilesApi} from '../../../../hooks/apis/use-files-api';
import {EntityId} from '../../../../api/base/BaseEntity';
import {Card, CardBody} from '../../../../components/card';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {useIntl} from '../../../../hooks/use-intl';
import {ICONS} from '../../../../images/images';
import {Svg} from '../../../../images/svg';
import {BootstrapColors, BootstrapSizes} from '../../../../../styles/bootstap-colors';
import {InputTextArea} from '../../../../components/Inputs/InputTextArea';
import {toast} from 'react-toastify';

type Props = {
  type: ContractualDocumentType;
  parent: Contract | SupAgreement;
  documents: ContractualDocument[];
};

export const ContractualDocuments: React.FC<Props> = ({type, parent, documents}) => {
  const intl = useIntl();
  const api = useContractualDocumentsApi();
  const fileApi = useFilesApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    create: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const [comment, setComment] = useState<string>('');
  const [contractualDocuments, setContractorDocuments] = useState<ContractualDocument[]>(documents);

  const handleCreateDocument = useCallback(
    async (file: File | null, comment: string) => {
      if (file === null) {
        return;
      }
      try {
        startLoading('create');
        const res = await api.uploadToFileStorage(file);
        const created = await api.create({
          file_id: res.id,
          entity_id: parent.id,
          entity_type: type,
          comment: comment,
        });
        setFile(null);
        setComment('');
        setContractorDocuments(prevState => [...prevState, created]);
      } catch (e) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      } finally {
        stopLoading('create');
      }
    },
    [parent, type],
  );

  const handleDownloadSource = useCallback(async (fileId: EntityId) => {
    try {
      const file = await fileApi.get(fileId);
      window.open(file.item.url);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  const handleDeleteSource = useCallback(async (id: EntityId) => {
    try {
      await api.remove(id);
      setContractorDocuments(prevState => {
        const removeIndex = prevState.findIndex(d => d.id === id);
        return [...prevState.slice(0, removeIndex), ...prevState.slice(removeIndex + 1)];
      });
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  return (
    <>
      <div>
        <div>
          {contractualDocuments.map(doc => {
            return (
              <Card
                hideFooter
                hideHeader
                className={{body: 'd-flex border-1 align-items-center justify-content-between'}}
                style={{border: '1px solid gainsboro'}}
                key={doc.id}>
                <CardBody>
                  <div className={'d-flex align-items-center'}>
                    <Svg src={ICONS.FILE} color={BootstrapColors.SECONDARY} size={BootstrapSizes.X3} />
                    <div className={'ml-2 d-flex flex-column'}>
                      <div className={'font-weight-bolder'}>
                        {intl.formatDate(doc.created_at, {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </div>
                      <div>{doc.comment}</div>
                    </div>
                  </div>
                  <div>
                    <Toolbar
                      items={[
                        {
                          type: 'BUTTON',
                          title: intl.formatMessage({id: 'DOWNLOAD'}),
                          onClick: () => handleDownloadSource(doc.file_id),
                          icon: ICONS.DOWNLOAD,
                          className: 'btn btn-sm btn-light-success',
                        },
                        {
                          type: 'BUTTON',
                          title: intl.formatMessage({id: 'DELETE'}),
                          onClick: () => handleDeleteSource(doc.id),
                          icon: ICONS.BIN,
                          className: 'btn btn-sm btn-light-danger',
                        },
                      ]}
                    />
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
        {loadings.create ? (
          <Preloader />
        ) : (
          <>
            {file == null ? (
              <FileUploader
                required
                label={intl.formatMessage({id: 'ATTACH_DOCUMENT'})}
                className={'mb-5'}
                onSelect={setFile}
              />
            ) : (
              <div>
                <div className={'d-inline-flex align-items-center'}>
                  <span className={'font-weight-bolder mr-5'}>{file.name}</span>
                  <Toolbar
                    items={[
                      {
                        type: 'BUTTON',
                        title: intl.formatMessage({id: 'CANCEL'}),
                        onClick: () => setFile(null),
                        icon: ICONS.RESET,
                        className: 'btn btn-secondary',
                      },
                    ]}
                  />
                </div>
              </div>
            )}

            <InputTextArea
              value={comment}
              label={intl.formatMessage({id: 'COMMENT'})}
              className={'w-100 min-h-100px'}
              onChange={e => setComment(e.currentTarget.value)}
            />

            <Toolbar
              className={'mt-5 w-100 justify-content-end'}
              items={[
                {
                  type: 'BUTTON',
                  disabled: file === null,
                  title: intl.formatMessage({id: 'SUBMIT'}),
                  onClick: () => handleCreateDocument(file, comment),
                  className: 'btn btn-light-primary',
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};
