import React, {createContext, useContext, useState} from 'react';
import {EntityId} from '../../../../api/base/BaseEntity';
import {ModalSetYandexZenChannelId} from './modal-set-yandex-zen-channel-id';
import {ValidationErrorsType} from '../../../../utils/utils';
import {CloseModalEvent, CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../../api/exceptions/BaseException';
import {ValidationException} from '../../../../api/exceptions/ValidationException';
import {toast} from 'react-toastify';
import {useIntl} from '../../../../hooks/use-intl';
import {useLoading} from '../../../../hooks/use-loading';
import {YoutubeChannelsApi} from "../../../../api/youtube-channels-api";

interface ContextProps {
  showSetYandexZenChannelIdModal(channelId: EntityId): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalSetYandexZenChannelIdContext = createContext<ContextProps>();

let closeResolver: ((value: CloseModalEvent<null>) => void) | null = null;
export const ModalSetYandexZenChannelIdProvider = ({children}: any) => {
  const intl = useIntl();
  const api = new YoutubeChannelsApi();
  const [loadings, startLoading, stopLoading] = useLoading({submit: false});
  const [visible, setVisible] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<EntityId | null>(null);
  const [yandexZenChannelId, setYandexZenChannelId] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<ValidationErrorsType>(null);

  const showModal = async (channelId: EntityId) => {
    setVisible(true);
    setChannelId(channelId);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setChannelId(null);
    setYandexZenChannelId(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      startLoading('submit');
      await api.setYandexZenChannelId(channelId as string, yandexZenChannelId as string);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        toast.error(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submit');
    }
  };

  const handleYandexZenChannelIdChange = (value: string) => {
    setValidationError(null);
    setYandexZenChannelId(value);
  };

  const value: ContextProps = {
    showSetYandexZenChannelIdModal: showModal,
  };

  return (
    <ModalSetYandexZenChannelIdContext.Provider value={value}>
      {children}
      <ModalSetYandexZenChannelId
        loading={loadings.submit}
        yandexZenChannelId={yandexZenChannelId}
        onYandexZenChannelIdChange={handleYandexZenChannelIdChange}
        validationErrors={validationErrors}
        visible={visible}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalSetYandexZenChannelIdContext.Provider>
  );
};

export const useSetYandexZenChannelIdModal = () => {
  return useContext(ModalSetYandexZenChannelIdContext);
};
