import React, {createContext, useContext, useEffect, useState} from 'react';
import {EntityId} from '../../../../api/base/BaseEntity';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {CloseModalEvent, CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../../api/exceptions/BaseException';
import {ValidationException} from '../../../../api/exceptions/ValidationException';
import {toast} from 'react-toastify';
import {useIntl} from '../../../../hooks/use-intl';
import {useLoading} from '../../../../hooks/use-loading';
import {YoutubeChannelsApi} from '../../../../api/youtube-channels-api';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../../../components/modals/base-modal/base-modal';
import {Modal} from 'react-bootstrap';
import {ValidateErrorWrapper} from '../../../../components/Inputs/ValidateErrorWrapper';
import {InputSelect} from '../../../../components/Inputs/InputSelect';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {STRING} from '../../../../consts';

interface ContextProps {
  showLinkChannelWithArtistModal(channelId: string, linkedArtistId?: EntityId | null): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalLinkChannelWithArtistContext = createContext<ContextProps>();

let closeResolver: ((value: CloseModalEvent<null>) => void) | null = null;
export const ModalLinkChannelWithArtistProvider = ({children}: any) => {
  const intl = useIntl();
  const api = new YoutubeChannelsApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({submit: false});
  const [visible, setVisible] = useState<boolean>(false);
  const [artistId, setArtistId] = useState<EntityId | null>(null);
  const [channelId, setChannelId] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<ValidationErrorsType>(null);

  useEffect(() => {
    if (visible) {
      fetchSelectValuesByKey('artists').then();
    }
  }, [visible]);

  const showModal = async (channelId: string, linkedArtistId?: EntityId | null) => {
    setVisible(true);
    setChannelId(channelId);
    if (linkedArtistId) {
      setArtistId(linkedArtistId);
    }
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setChannelId(null);
    setArtistId(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      startLoading('submit');
      await api.linkWithArtist(channelId as string, artistId as string);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        toast.error(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submit');
    }
  };

  const handleSelectedArtistChange = (value: string) => {
    setValidationError(null);
    setArtistId(value);
  };

  const value: ContextProps = {
    showLinkChannelWithArtistModal: showModal,
  };

  return (
    <ModalLinkChannelWithArtistContext.Provider value={value}>
      {children}
      <BaseModal
        loading={loadings.submit}
        disabledButtons={loadings.submit}
        visible={visible}
        onSuccess={handleOkClick}
        onHide={handleHideModal}>
        <ModalHeaderSlot>
          <Modal.Title>{intl.formatMessage({id: 'LINK_WITH_ARTIST'})}</Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <div className={'form-group'}>
              <ValidateErrorWrapper message={getValidationErrorMessage('artist_id', validationErrors)}>
                <InputSelect
                  required
                  isClearable
                  isLoading={optionsLoadings?.artists}
                  hasError={!!getValidationErrorMessage('artist_id', validationErrors)}
                  options={selectsOptions?.artists ?? []}
                  label={intl.formatMessage({id: 'ARTIST'})}
                  placeholder={STRING.EMPTY}
                  value={artistId}
                  onChange={handleSelectedArtistChange}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </ModalLinkChannelWithArtistContext.Provider>
  );
};

export const useLinkChannelWithArtistModal = () => {
  return useContext(ModalLinkChannelWithArtistContext);
};
