import React from 'react';
import {Pagination} from 'react-bootstrap';
import {IPaginationInfo} from '../api/Paginator';
import {FormattedMessage, FormattedNumber} from 'react-intl';

export type AggregationValues = Record<string, string | null | number | undefined>;

interface IPaginationProps {
  threshold: number;
  paginator: IPaginationInfo;
  aggregationValues?: AggregationValues;

  onChange(page: number): any;
}

export const PaginationComponent = ({paginator, threshold, aggregationValues, onChange}: IPaginationProps) => {
  const handleChangePage = (e: React.MouseEvent<HTMLElement>) => {
    const type = e.currentTarget.id;
    if (type === 'next') {
      if (paginator.currentPageNumber === paginator.totalPagesCount) {
        return;
      }
      onChange(paginator.currentPageNumber + 1);
    } else if (type === 'last') {
      onChange(paginator.totalPagesCount);
    } else if (type === 'prev') {
      if (paginator.currentPageNumber === 1) {
        return;
      }
      onChange(paginator.currentPageNumber - 1);
    } else if (type === 'first') {
      onChange(1);
    } else {
      if (Number.isNaN(Number(e.currentTarget.innerText))) {
        return;
      }
      onChange(Number(e.currentTarget.innerText));
    }
  };

  const items: any = [];
  const initial = paginator.currentPageNumber - threshold > 0 ? paginator.currentPageNumber - threshold : 1;
  for (let i = initial; i <= paginator.currentPageNumber + threshold; i++) {
    if (i === 1) continue;

    if (i > paginator.totalPagesCount) break;

    items.push(
      <Pagination.Item key={i} value={i} onClick={handleChangePage} active={i === paginator?.currentPageNumber}>
        {i}
      </Pagination.Item>,
    );
  }

  const renderAggregationInfos = () => {
    if (!aggregationValues) {
      return null;
    }

    return (
      <div className={'row mb-3'}>
        {Object.keys(aggregationValues).map(title => {
          if (aggregationValues[title] != undefined && aggregationValues[title] != null) {
            return (
              <div key={title} className={'font-weight-bolder'}>
                <span className={'font-size-lg'}>
                  {title}: {aggregationValues[title]}
                </span>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <div className={'mr-2'}>
        <Pagination className={'p-0 m-0'}>
          <Pagination.First id={'first'} onClick={handleChangePage} />
          <Pagination.Prev id={'prev'} onClick={handleChangePage} />
          <Pagination.Item active={1 === paginator?.currentPageNumber} onClick={handleChangePage}>
            1
          </Pagination.Item>
          {paginator.currentPageNumber != 1 && paginator.currentPageNumber - threshold >= 1 && <Pagination.Ellipsis />}
          {items}

          {paginator.currentPageNumber != paginator.totalPagesCount &&
            paginator.currentPageNumber + threshold < paginator.totalPagesCount && (
              <>
                <Pagination.Ellipsis />
                <Pagination.Item
                  active={paginator.totalPagesCount === paginator?.currentPageNumber}
                  onClick={handleChangePage}>
                  {paginator.totalPagesCount}
                </Pagination.Item>
              </>
            )}

          <Pagination.Next id={'next'} onClick={handleChangePage} />
          <Pagination.Last id={'last'} onClick={handleChangePage} />
        </Pagination>
      </div>
    );
  };

  const renderRecordCount = () => {
    if (paginator.totalItemsCount === 0) {
      return null;
    }

    return (
      <span className={'font-weight-bolder'}>
        {paginator.currentPageNumber != paginator.totalPagesCount ? (
          <>
            <FormattedNumber value={paginator.currentPageNumber * paginator.perPage - paginator.perPage + 1} />–
            <FormattedNumber value={paginator.currentPageNumber * paginator.perPage} />
          </>
        ) : (
          <>
            <FormattedNumber value={(paginator.currentPageNumber - 1) * paginator.perPage + 1} />–
            <FormattedNumber value={paginator.totalItemsCount} />
          </>
        )}

        <span className={'mx-1'}>
          <FormattedMessage id={'OF'} />
        </span>
        <FormattedNumber value={paginator.totalItemsCount} />
      </span>
    );
  };

  return (
    <div>
      {renderAggregationInfos()}
      <div className={'d-flex align-items-center'}>
        {paginator.totalPagesCount > 1 && renderPagination()}
        {renderRecordCount()}
      </div>
    </div>
  );
};
