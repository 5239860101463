import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {EntityId} from '../../../api/base/BaseEntity';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useModalCreateEditGenre} from '../../../components/modals/create-edit-genre/create-edit-genre-context';
import {useBaseListPage} from '../../base/base-list-page-context';
import {Genre} from '../../../api/DTOs/Genre';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {PartialNullable} from '../../../../types/types';
import {useGenresApi} from '../../../hooks/apis/use-genres-api';

export enum GenreAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type HandleGenreActionPayload = HandleGenreCreateAction | HandleGenreEditAction | HandleGenreDeleteAction;
export type HandleGenreCreateAction = {
  type: GenreAction.CREATE;
};

export type HandleGenreEditAction = {
  type: GenreAction.EDIT;
  genre: PartialNullable<Genre>;
};

export type HandleGenreDeleteAction = {
  type: GenreAction.DELETE;
  genreId: EntityId;
};

export function useGenreActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useGenresApi();
  const {showCreateEditGenreModal} = useModalCreateEditGenre();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleGenreActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case GenreAction.CREATE: {
          const modalResult = await showCreateEditGenreModal({type: 'CREATE'});
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case GenreAction.EDIT: {
          const modalResult = await showCreateEditGenreModal({
            type: 'EDIT',
            data: {entity: payload.genre},
          });
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case GenreAction.DELETE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_GENRE'}))) {
            try {
              await api.remove(payload.genreId);
              fetch?.(appliedQueryParams);
            } catch (e) {
              if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
                toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
              }
            }
          }
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    handleGenreActions: handle,
  };
}
