import React from 'react';
import {Contractor} from '../../../../../api/contractor-api/Contractor';
import {Contract, ContractStatus} from '../../../../../api/contract-api/Contract';
import {FormattedMessage} from 'react-intl';
import {LocalizationKey, useIntl} from '../../../../../hooks/use-intl';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../../../configs/routes';
import cn from 'classnames';

type Props = {
  contractor: Contractor;
  ourContractor: Contractor;
  contract: Contract;
};

export const ContractInformation: React.FC<Props> = ({contract, ourContractor, contractor}) => {
  const intl = useIntl();

  const renderPartOfInformation = (title: LocalizationKey, value: string | JSX.Element, textClassName?: string) => {
    return (
      <div className={'item d-flex align-items-center mr-5'}>
        <div className={'d-flex flex-column text-dark-75'}>
          <span className={'font-weight-bolder font-size-sm'}>
            <FormattedMessage id={title} />
          </span>
          <span className={cn('font-weight-bolder font-size-lg', textClassName)}>{value}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={'row'} style={{maxWidth: '1120px'}}>
        <div className={'col-12 d-flex align-items-center flex-wrap mb-5'}>
          {renderPartOfInformation(
            'STATUS',
            intl.formatMessage({id: 'CONTRACT_STATUS_' + contract.status}),
            cn({
              'text-success': contract.status === ContractStatus.ACTIVE,
              'text-danger': contract.status === ContractStatus.EXPIRED,
            }),
          )}
          {renderPartOfInformation('CURRENCY', contract?.currency ?? 'N/A')}
        </div>
        <div className={'col-12 d-flex align-items-center flex-wrap mb-5'}>
          {renderPartOfInformation(
            'CONTRACTOR_NAME',
            <Link to={contract?.contractor_id ? AdminRoutes.getSpecifyContractorRoute(contract.contractor_id) : '#'}>
              {contractor.calculated_name || 'N/A'}
            </Link>,
          )}
          {renderPartOfInformation(
            'CONTRACTOR_TYPE',
            contractor?.type ? intl.formatMessage({id: 'CONTRACTOR_TYPE_' + contractor.type}) : 'N/A',
          )}
        </div>
        <div className={'col-12 d-flex align-items-center flex-wrap mb-5'}>
          {renderPartOfInformation('BANK_NAME', contractor?.rub_bank_name ?? 'N/A')}
          {renderPartOfInformation('BANK_ACCOUNT_NUMBER', contractor?.rub_bank_account_number ?? 'N/A')}
          {renderPartOfInformation('CURRENCY', contractor?.bank_account_currency ?? 'N/A')}
        </div>

        <div className={'col-12 d-flex align-items-center flex-wrap mb-5'}>
          {renderPartOfInformation(
            'IS_OUR_COMPANY',
            <Link to={contract?.contractor_id ? AdminRoutes.getSpecifyContractorRoute(ourContractor.id) : '#'}>
              {ourContractor.calculated_name || 'N/A'}
            </Link>,
          )}
          {renderPartOfInformation('CURRENCY', ourContractor?.bank_account_currency ?? 'N/A')}
        </div>
      </div>
    </>
  );
};
