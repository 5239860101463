import React, {useMemo} from 'react';
import {ChartMetricStats} from '../../../api/DTOs/Chartmetric/CMArtistMetadata';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {useIntl} from '../../../hooks/use-intl';
import {FormattedNumber} from 'react-intl';

type Props = {
  statistics: ChartMetricStats;
};

export const ChartMetricSummaryInfo: React.FC<Props> = ({statistics}) => {
  const intl = useIntl();

  const x = useMemo(() => {
    return [
      {
        title: (
          <>
            <i className={'fab fa-spotify text-success'} /> Spotify
          </>
        ),
        rows: [
          [
            'SUBSCRIBERS',
            statistics?.sp_followers ? <FormattedNumber value={statistics?.sp_followers} notation={'compact'} /> : null,
          ],
          ['POPULARITY', <>{statistics?.sp_popularity}/100</>],
          [
            'MONTHLY_LISTENERS',
            statistics?.sp_monthly_listeners ? (
              <FormattedNumber value={statistics?.sp_monthly_listeners} notation={'compact'} />
            ) : null,
          ],
          [
            'PLAYLIST_REACH',
            statistics?.sp_playlist_total_reach ? (
              <FormattedNumber value={statistics?.sp_playlist_total_reach} notation={'compact'} />
            ) : null,
          ],
        ],
      },
      {
        title: (
          <>
            <i className={'fab fa-youtube text-danger'} /> YouTube
          </>
        ),
        rows: [
          [
            'SUBSCRIBERS',
            statistics?.ycs_subscribers ? (
              <FormattedNumber value={statistics?.ycs_subscribers} notation={'compact'} />
            ) : null,
          ],
          [
            'VIEWS',
            statistics?.ycs_views ? <FormattedNumber value={statistics?.ycs_views} notation={'compact'} /> : null,
          ],
        ],
      },
      {
        title: (
          <>
            <i className={'fab fa-instagram'} style={{color: 'purple'}} /> Instagram
          </>
        ),
        rows: [
          [
            'SUBSCRIBERS',
            statistics?.ins_followers ? (
              <FormattedNumber value={statistics?.ins_followers} notation={'compact'} />
            ) : null,
          ],
        ],
      },
      {
        title: <span>TikTok</span>,
        rows: [
          [
            'SUBSCRIBERS',
            statistics?.tiktok_followers ? (
              <FormattedNumber value={statistics?.tiktok_followers} notation={'compact'} />
            ) : null,
          ],
          [
            'LIKES',
            statistics?.tiktok_likes ? <FormattedNumber value={statistics?.tiktok_likes} notation={'compact'} /> : null,
          ],
        ],
      },
      {
        title: <>Deezer</>,
        rows: [
          [
            'SUBSCRIBERS',
            statistics?.deezer_fans ? <FormattedNumber value={statistics?.deezer_fans} notation={'compact'} /> : null,
          ],
        ],
      },
    ];
  }, []);

  return (
    <Card hideFooter className={{body: 'd-flex flex-wrap'}}>
      <CardTitle>{intl.formatMessage({id: 'SUMMARY_STATISTICS'})}</CardTitle>
      <CardBody>
        {x.map((item, index) => {
          return (
            <div key={`sum_table_${index}`} className={'mr-15 mb-5'}>
              <table>
                <thead>
                  <tr className={'border-bottom'}>
                    <th style={{minWidth: '160px'}}>{item.title}</th>
                    <th />
                  </tr>
                </thead>
                <tbody className={'py-1'}>
                  {item.rows.map(([title, value], index) => {
                    return (
                      <tr key={index}>
                        <td className={'font-weight-boldest'}>{intl.formatMessage({id: title as string})}</td>
                        <td className={'font-weight-bolder text-right'}>
                          {value != null ? value : intl.formatMessage({id: 'NOT_APPLICABLE'})}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};
