import React, {useMemo} from 'react';
import {DefaultTable} from '../../../../components/default-table';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {TableUtils} from '../../../../components/table-utils';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../../configs/routes';
import {EntityId} from '../../../../api/base/BaseEntity';
import {getEnumValues} from '../../../../../types/types';
import {AvailableContractChange} from '../../../../api/contract-api/Contract';
import {Svg} from '../../../../images/svg';
import {ICONS} from '../../../../images/images';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';
import {useIntl} from '../../../../hooks/use-intl';
import {SupAgreementAction} from './use-sup-agreement-actions-handler';

interface FinancialAccountsTableProps {
  contractId: EntityId;
  supAgreements: Array<SupAgreement>;
  onAction: (supAgreement: SupAgreement, action: SupAgreementAction) => Promise<unknown>;
}

export const SupAgreementsTable = ({contractId, supAgreements}: FinancialAccountsTableProps) => {
  const intl = useIntl();
  const data = supAgreements;

  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder';
    return [
      {
        id: 'Number',
        Header: '№',
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: SupAgreement) => br,
        Cell: ({value}: {value: SupAgreement}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifySupAgreementRoute(contractId, value.id)}>{value.number}</Link>,
          );
        },
      },
      {
        id: 'Changes',
        Header: intl.formatMessage({id: 'SUP_AGREEMENT_CHANGES'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: SupAgreement) => br,
        Cell: ({value}: {value: SupAgreement}) => {
          return TableUtils.renderBaseContent(
            Object.keys(value)
              .filter(key => getEnumValues(AvailableContractChange).includes(key))
              .filter(key => value[key as keyof SupAgreement])
              .map(key => {
                console.log(key, value);
                return (
                  intl.formatMessage({id: 'SUP_AGREEMENTS_CHANGES_' + key}) +
                  ' ' +
                  (key === AvailableContractChange.IS_CHANGE_VIDEO_RATE_PERCENT
                    ? `(${value[key.replace('is_', '') as keyof SupAgreement] as number * 100}%)`
                    : `(${(value[key.replace('is_', '') as keyof SupAgreement] as Array<any>)?.length})`)
                );
              })
              .join(', '),
          );
        },
      },
      {
        id: 'Signed',
        Header: intl.formatMessage({id: 'SUP_AGREEMENT_SIGNED_STATUS'}),
        maxWidth: 80,
        minWidth: 80,
        accessor: (br: SupAgreement) => br,
        Cell: ({value}: {value: SupAgreement}) => {
          return (
            <div className={'font-weight-bolder'}>
              {value.is_signed ? <Svg src={ICONS.CHECK} color={BootstrapColors.SUCCESS} /> : <Svg src={ICONS.MINUS} />}
            </div>
          );
        },
      },
      {
        id: 'Validity',
        Header: intl.formatMessage({id: 'CONTRACT_START_DATE'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: SupAgreement) => br,
        Cell: ({value}: {value: SupAgreement}) => {
          return <div className={defaultClassName}>{intl.formatDate(value?.started_at)}</div>;
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
