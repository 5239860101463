import React, {useEffect, useMemo, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

import {Video, VideoStatus} from '../../../api/DTOs/Video';
import {ErrorStub} from '../../../components/error-stub';
import {Preloader} from '../../../components/preloader';
import {useLoading} from '../../../hooks/use-loading';
import {VideoPreview} from '../video-prerview';
import {useVideoActionHandler, VideoAction} from '../use-video-handler-actions';
import {ICONS, IMAGES} from '../../../images/images';
import {useVideosApi, VideoPrivileges, VideoRelations} from '../../../hooks/apis/use-videos-api';
import {useModalPlayVideo} from '../../../components/modals/play-video/modal-play-video-context';
import SVG from 'react-inlinesvg';
import {Card, CardBody} from '../../../components/card';
import {useIntl} from '../../../hooks/use-intl';
import {Svg} from '../../../images/svg';
import cn from 'classnames';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {VideoHistoryBlock} from './video-history-block';
import {OtherVideos} from './other-videos';
import {prepareDate, tryGetDateOrNull} from '../../../utils/utils';
import {DateLocalizedFormats} from '../../../components/Inputs/InputDatepicker';
import {AppLocale} from '../../../../localization';
import {FEED_ICONS_MAP} from '../../../api/DTOs/FeedIconsMap';
import './video-page.scss';
import {useAuth} from '../../../hooks/use-auth';
import {AdminRoutes} from '../../../../configs/routes';
import {ContractCard} from '../../youtube-channel/channel-page/contract-card';

export const VIDEO_STATUS_COLOR_MAP: Record<VideoStatus, BootstrapColors> = {
  [VideoStatus.DRAFT]: BootstrapColors.SECONDARY,
  [VideoStatus.WAITING_PREPARING]: BootstrapColors.INFO,
  [VideoStatus.READY_TO_PUBLISH]: BootstrapColors.PRIMARY,
  [VideoStatus.PREPARING_ERROR]: BootstrapColors.DANGER,
  [VideoStatus.PUBLISHED]: BootstrapColors.SUCCESS,
  [VideoStatus.PREPARING]: BootstrapColors.SUCCESS,
};

export const VideoPage: React.FC = () => {
  const intl = useIntl();
  const {id: videoId} = useParams<{id: string}>();
  const api = useVideosApi();
  const {isAdmin} = useAuth();
  const [loadings, startLoading, stopLoading] = useLoading({
    video: true,
  });
  const [error, setError] = useState<string | null>(null);
  const [video, setVideo] = useState<Video | null>(null);
  const [privileges, setPrivileges] = useState<VideoPrivileges | null>(null);
  const [relations, setRelations] = useState<VideoRelations | null>(null);
  const [previewMode, setPreviewMode] = useState<'RAW' | 'PREPARED'>('RAW');
  const {showPlayVideoModal} = useModalPlayVideo();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.all([fetchVideo()]);
    };
    fetchAll().then();
  }, [videoId]);

  const info = useMemo(() => {
    return [
      {
        title: intl.formatMessage({id: 'STATUS'}),
        value: (
          <span
            className={cn('font-weight-boldest', {
              [`text-${VIDEO_STATUS_COLOR_MAP[video?.status || VideoStatus.DRAFT]}`]: video?.status,
            })}>
            {intl.formatMessage({id: `VIDEO_STATUS_${video?.status}`})}
          </span>
        ),
      },
      {
        title: intl.formatMessage({id: 'CODE'}),
        value: video?.code,
        className: video?.y_video_id ? '' : 'mb-15',
      },
      {
        title: intl.formatMessage({id: 'YOUTUBE_VIDEO_ID'}),
        value: video?.y_video_id,
        className: video?.contractor_code ? '' : 'mb-15',
      },
      {
        title: intl.formatMessage({id: 'CONTRACTOR_EXTERNAL_ID'}),
        value: video?.contractor_code,
        className: 'mb-15',
      },
      {
        title: intl.formatMessage({id: 'USER'}),
        visible: isAdmin,
        value: relations?.user?.id ? (
          <Link to={AdminRoutes.getSpecifyUserManagementRoute(relations?.user?.id)}>{relations?.user?.email}</Link>
        ) : (
          '--'
        ),
        icon: ICONS.USER,
      },
      {
        title: intl.formatMessage({id: 'CONTRACTOR'}),
        visible: isAdmin,
        value: relations?.contractor.id ? (
          <Link to={AdminRoutes.getSpecifyContractorRoute(relations?.contractor.id)}>
            {relations?.contractor.calculated_name}
          </Link>
        ) : (
          '--'
        ),
        icon: ICONS.CONTRACTORS,
      },
      {
        title: intl.formatMessage({id: 'ARTISTS'}),
        value: relations?.artists?.map(a => a.name).join(', '),
        icon: ICONS.USERS,
      },
      {
        title: intl.formatMessage({id: 'GENRES'}),
        value: relations?.genres?.map(g => g.name).join(', '),
        icon: ICONS.GENRE,
      },
      {
        title: intl.formatMessage({id: 'COUNTRIES'}),
        value: relations?.countries?.map(c => c.name).join(', '),
        icon: ICONS.FLAG,
      },
      {
        title: intl.formatMessage({id: 'DIRECTOR'}),
        value: video?.director,
      },
      {
        title: intl.formatMessage({id: 'AUTHORS'}),
        value: video?.authors,
      },
      {
        title: intl.formatMessage({id: 'AGE_LIMIT'}),
        value: video?.age_limit ? `${video.age_limit}+` : null,
      },
      {
        title: intl.formatMessage({id: 'RIGHTS_EXPIRATION'}),
        value: video?.rights_expiration_at
          ? prepareDate(
              tryGetDateOrNull(video?.rights_expiration_at),
              DateLocalizedFormats.DD_MM_YYYY[intl.locale as AppLocale],
            )
          : 'N/A',
      },
      {
        title: intl.formatMessage({id: 'APPROVED_FEEDS'}),
        value: (
          <div className={'mt-2'}>
            {video?.feeds.map(feed => {
              return (
                <div key={feed} className={'d-flex align-items-center mb-2 mr-8'}>
                  <img src={FEED_ICONS_MAP[feed]} width={24} height={24} alt={'logo'} className={'rounded mr-2 ml-0'} />
                  <div className={'font-weight-bolder font-size-md'}>{intl.formatMessage({id: 'FEED_' + feed})}</div>
                </div>
              );
            })}
          </div>
        ),
      },
    ];
  }, [video, relations]);

  const fetchVideo = async () => {
    try {
      startLoading('video');
      const {item, relations, privileges} = await api.get(videoId);
      setRelations(relations);
      setPrivileges(privileges);
      setVideo(item);
      setBreadcrumb(item.title);
    } catch (e) {
      setError(e);
    } finally {
      stopLoading('video');
    }
  };
  const {handleVideoActions} = useVideoActionHandler(fetchVideo);

  if (loadings.video) {
    return <Preloader />;
  }

  if (error || video === null) {
    return <ErrorStub error={error} />;
  }

  const renderVideoThumbnail = () => {
    if (!relations) {
      return null;
    }

    const url =
      previewMode === 'RAW'
        ? relations?.imageFile?.url ?? relations.watermarkedImageFile?.url
        : relations.watermarkedImageFile?.url ?? relations?.imageFile?.url;
    return (
      <div style={{maxWidth: '320px', border: '1px solid gray', borderRadius: '0.42rem'}}>
        <div className={'preview-overlay'}>
          <img src={url ?? IMAGES.VIDEO_PREVIEW_STUB} alt='preview' className={'w-100 overlay-wrapper rounded'} />
          <span
            className={`overlay-layer cursor-pointer svg-icon svg-icon-8x svg-icon-white`}
            onClick={() =>
              showPlayVideoModal(
                (previewMode === 'RAW' ? relations?.originalVideoFile?.url : relations.watermarkedVideoFile?.url) || '',
                video?.title,
                'video',
              )
            }>
            <SVG src={ICONS.PLAY} />
          </span>
        </div>
      </div>
    );
  };

  const renderPreview = () => {
    if (!video) {
      return null;
    }

    return (
      <VideoPreview
        video={video}
        artists={relations?.artists}
        toolBarConfig={[
          {
            visible: privileges?.can_delete ?? false,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'DELETE'}),
            icon: ICONS.DELETE,
            className: 'btn btn-sm btn-light-danger',
            onClick: () =>
              handleVideoActions({type: VideoAction.DELETE, entityId: video?.id, needRedirectToList: true}),
          },
          {
            visible: privileges?.can_edit ?? false,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'EDIT'}),
            icon: ICONS.EDIT,
            className: 'btn btn-sm btn-secondary',
            onClick: () => handleVideoActions({type: VideoAction.EDIT, entity: video}),
          },
          {
            visible: privileges?.can_update_watermarked_files ?? false,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'UPLOAD_WATERMARK_FILES'}),
            icon: ICONS.VIDEO,
            className: 'btn btn-sm btn-light-info',
            onClick: () =>
              handleVideoActions({
                type: VideoAction.UPLOAD_UPDATED_FILES,
                entityId: video?.id,
                videoWatermarkedFile: relations?.watermarkedVideoFile ?? null,
                imageWatermarkedFile: relations?.watermarkedImageFile ?? null,
              }),
          },
          {
            visible: privileges?.can_ready_to_publish,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'PUBLISH'}),
            icon: ICONS.UP,
            className: 'btn btn-sm btn-light-primary',
            onClick: () => handleVideoActions({type: VideoAction.READY_TO_PUBLISH, entityId: video?.id}),
          },
          {
            visible: privileges?.can_reject_publish,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'REJECT_PUBLISH'}),
            icon: ICONS.CROSS,
            className: 'btn btn-sm btn-light-danger',
            onClick: () => handleVideoActions({type: VideoAction.REJECT_PUBLICATION, entityId: video?.id}),
          },
          {
            visible: privileges?.can_confirm_publish,
            type: 'BUTTON',
            title: intl.formatMessage({id: 'CONFIRM_PUBLISH'}),
            icon: ICONS.CHECK,
            className: 'btn btn-sm btn-light-success',
            onClick: () => handleVideoActions({type: VideoAction.CONFIRM_PUBLICATION, entityId: video?.id}),
          },
        ]}
      />
    );
  };

  const handleTogglePreviewMode = (mode: 'RAW' | 'PREPARED') => {
    return () => setPreviewMode(mode);
  };

  return (
    <div className={'row'} style={{maxWidth: '1520px'}}>
      <div className={'col-12 col-xl-9'}>
        {renderPreview()}
        {relations?.activeContract && <ContractCard contract={relations?.activeContract} />}
        <VideoHistoryBlock videoId={video?.id} />
        <OtherVideos
          currentVideoId={video.id}
          artistsAsString={relations?.artists.map(a => a.name).join(', ') || ''}
          artists_ids={video.artists_ids}
        />
      </div>

      <div className={'col-12 col-xl-3'}>
        <Card hideFooter hideHeader>
          <CardBody>
            <div>
              {video.watermarked_video_file_id && (
                <div className={'d-inline-flex mb-4 font-weight-bolder'}>
                  <div
                    className={cn('cursor-pointer', {'text-primary text-underline': previewMode !== 'RAW'})}
                    onClick={handleTogglePreviewMode('RAW')}>
                    {intl.formatMessage({id: 'ORIGINAL'})}
                  </div>
                  <span className={'mx-2'}>/</span>
                  <div
                    className={cn('cursor-pointer', {'text-primary text-underline': previewMode !== 'PREPARED'})}
                    onClick={handleTogglePreviewMode('PREPARED')}>
                    {intl.formatMessage({id: 'PREPARED'})}
                  </div>
                </div>
              )}

              <div className={'mb-4'}>{renderVideoThumbnail()}</div>
              {info.map((item, index) => {
                if (!item.value || (item.visible !== undefined && !item.visible)) {
                  return null;
                }
                return (
                  <div key={index} className={cn('mb-5', item.className)}>
                    <div className={'d-flex align-items-center mb-1'}>
                      <span className={'font-weight-bolder font-size-lg'}>{item.title}</span>
                      {item.icon && <Svg src={item.icon} className={'ml-1'} />}
                    </div>
                    {item.value}
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
