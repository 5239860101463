import React, {useCallback, useRef} from 'react';
import {Card, CardBody, CardFooter, CardTitle} from '../../components/card';
import {ContractorDataValidationStatus} from '../../api/contractor-api/Contractor';
import {useIntl} from '../../hooks/use-intl';
import {useAuth} from '../../hooks/use-auth';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {useLoading} from '../../hooks/use-loading';
import {Wizard} from './wizard/wizard';
import {ICONS} from '../../images/images';
import {StepFirst} from './wizard/steps/step-first/step-first';
import {StepSecond} from './wizard/steps/step-second/step-second';

export const MyOrganizationEditPage: React.FC = () => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    next: false,
  });
  const {contractor} = useAuth();
  const nextStepCallback = useRef<CallableFunction | null>(null);

  const handleNextStep = useCallback(async () => {
    if (nextStepCallback.current) {
      try {
        startLoading('next');
        await nextStepCallback.current();
      } finally {
        stopLoading('next');
      }
    }
  }, [nextStepCallback]);

  return (
    <Card>
      <CardTitle>{intl.formatMessage({id: 'EDIT'})}</CardTitle>
      <CardBody>
        <Wizard active={contractor?.data_validation_status}>
          <StepFirst
            title={intl.formatMessage({id: 'WIZARD_STEP_FILL_FIELDS'})}
            match={[
              ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES,
              ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS,
              ContractorDataValidationStatus.APPROVED,
            ]}
            setNexCallback={cb => (nextStepCallback.current = cb)}
          />
          <StepSecond
            title={intl.formatMessage({id: 'WIZARD_STEP_WAITING_ADMINISTRATOR_CONFIRM'})}
            match={[ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM, ContractorDataValidationStatus.REJECTED]}
          />
        </Wizard>
      </CardBody>
      <CardFooter>
        <Toolbar
          items={[
            {
              visible: ![
                ContractorDataValidationStatus.REJECTED,
                ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM,
              ].includes(contractor?.data_validation_status as ContractorDataValidationStatus),
              type: 'BUTTON',
              loading: loadings.next,
              disabled: loadings.next,
              icon: ICONS.ARROW,
              title: intl.formatMessage({id: 'WIZARD_NEXT'}),
              className: 'btn btn-light-primary',
              onClick: handleNextStep,
            },
          ]}
        />
      </CardFooter>
    </Card>
  );
};
