import {useSelector} from 'react-redux';
import {IApplicationStore} from '../redux/rootReducer';
import {useEffect} from 'react';
import {useModalConfirmAction} from '../app/components/modals/confirm-modal/modal-confirm-action-context';
import {useIntl} from 'react-intl';

const ILLUSTRATION = {
  GEAR: require('../app/images/illustration/Gear.png'),
};

export const useUpdater = () => {
  const intl = useIntl();
  const needReloadForGetUpdate = useSelector<IApplicationStore, boolean>(({updater}) => updater.needReloadForGetUpdate);
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    if (needReloadForGetUpdate) {
      // noinspection JSIgnoredPromiseFromCall
      showModal();
    }
  }, [needReloadForGetUpdate]);

  const showModal = async () => {
    if (
      process.env.NODE_ENV === 'development' ||
      (await showConfirmActionModal(intl.formatMessage({id: 'UPDATE_CLIENT_NOTICE'}), null, {
        icon: ILLUSTRATION.GEAR,
        hideDismissButton: true,
        successButtonTitle: intl.formatMessage({id: 'REFRESH'}),
      }))
    ) {
      window.location.reload();
    }
  };
};
