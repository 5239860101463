import React from 'react';
import './widget.scss';
import {WidgetBase} from './widget-base';
import {Preloader} from '../preloader';

interface WidgetMultiDataProps {
  title: string;
  data: Array<{title: string; value: string | number}>;
  className?: string;
  loading?: boolean;
  noStretch?: boolean;

  onClick?: () => void;
}

export const WidgetMultiData: React.FC<WidgetMultiDataProps> = ({
  noStretch,
  data,
  loading,
  title,
  className,
  onClick,
}) => {
  return (
    <>
      <WidgetBase noStretch={noStretch} className={className} onClick={onClick}>
        <div className={'card-header'}>
          <div className={'card-title'}>
            <h3 className={'font-weight-bolder m-0 p-0'}>{title}</h3>
          </div>
        </div>
        <div className='card-spacer bg-white card-rounded flex-grow-1'>
          <div className={'row m-0'}>
            {loading ? (
              <Preloader />
            ) : (
              data.map((item, index) => {
                return (
                  <div key={index} className={'col-md-12 col-xl-4 mb-3'}>
                    <div className={'font-size-h5 text-dark-50 font-weight-bolder'}>{item.title}</div>
                    <div className={'font-size-h4 font-weight-boldest'}>{item.value}</div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </WidgetBase>
    </>
  );
};
