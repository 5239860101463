import React, {CSSProperties} from 'react';
import {BootstrapColors, BootstrapSizes} from '../../styles/bootstap-colors';
import cn from 'classnames';
import SVG from 'react-inlinesvg';

type Props = {
  src: string;
  visible?: boolean;
  spin?: boolean;
  color?: BootstrapColors;
  size?: BootstrapSizes;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

export function Svg({src, color, size, spin, className, visible, style, onClick}: Props) {
  if (visible !== undefined && !visible) {
    return null;
  }

  return (
    <span
      onClick={onClick}
      style={style}
      className={cn('svg-icon', className, {
        [`svg-icon-${color}`]: color,
        [`svg-icon-${size}`]: size,
        [`svg-spin`]: spin,
      })}>
      <SVG src={src} />
    </span>
  );
}
