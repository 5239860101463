import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Artist} from '../../api/DTOs/Artist';
import {FileDto} from '../../api/file-api/IFileDto';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {Dispatch, useCallback, useRef} from 'react';
import {isValidationException} from '../../api/api-utils';
import {ValidationErrorsType} from '../../utils/utils';
import { DateAsString, EntityId } from "../../api/base/BaseEntity";
import {BaseItemResponse, BaseItemsResponse} from '../../api/base/base-crud-api-response';
import {
  ChartMetricArtistMetadata,
  ChartmetricSearchableArtist,
  ChartMetricSocialStreamingUrl,
} from '../../api/DTOs/Chartmetric/CMArtistMetadata';
import {Contractor} from '../../api/contractor-api/Contractor';

export type BaseChartMetricResponse<T = any> = {
  obj: T;
};

export type ArtistRelation = {
  imageFile: FileDto;
  contractor: Contractor;
};

export type ArtistPrivileges = {
  can_delete: boolean;
  can_edit: boolean;
};

export enum PlaylistPlatform {
  SPOTIFY = 'spotify',
  YOUTUBE = 'youtube',
  APPLE = 'applemusic',
}

export type ChartMetricArtistQueryParams = {
  offset: number;
  limit: number;
  platform: PlaylistPlatform;
  status: 'current' | 'past';
};

export type ChartMetricPlaylist = {
  id: string;
  name: string;
  playlist_id: string;
  num_track: number;
  image_url: string | null;
  owner_name: string;
  position: number;
  followers?: number | null;
  views?: number | null;
  vdiff_month?: number | null;
  fdiff_month?: number | null;
  description: string | null;
  added_at: DateAsString;
};

export type ChartMetricPlaylistTrack = {
  id: string;
  name: string;
  image_url: string[] | null[];
  artists_names: Array<string>;
  score: number;
};

export function useArtistsApi() {
  const {current: conf} = useRef<EntityApiHookParams>({
    prefixUrl: 'artists',
    isAdmin: false,
    uploadConfig: {
      signedUrlRoute: 'artist-image-pre-signed-url',
    },
  });
  const api = useEntityApi<Artist, ArtistRelation, ArtistPrivileges>(conf);

  return {
    ...api,
    ...useAdditionalRoute(conf, api.setValidationErrors),
  };
}

function useAdditionalRoute(conf: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) {
  const api = useBaseEndpointApi();

  const setChartMetricId = useCallback(async (artistId: EntityId, chartmetric_id: number | string | null) => {
    try {
      return await api.post(`${conf.prefixUrl}/${artistId}/chartmetric-artist-id`, {fields: {chartmetric_id}});
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  }, []);

  const getArtistMetaData = useCallback(async (id: EntityId) => {
    return (await api.get<BaseItemResponse<ChartMetricArtistMetadata>>(`chartmetric/${id}/metadata`)).data.item;
  }, []);

  const getArtistSocialStreamingUrls = useCallback(async (id: EntityId) => {
    return (await api.get<BaseItemsResponse<ChartMetricSocialStreamingUrl>>(`chartmetric/${id}/socials-urls`)).data;
  }, []);

  const getArtistPlaylists = useCallback(async (id: EntityId, params: ChartMetricArtistQueryParams) => {
    return (
      await api.get<BaseItemsResponse<{playlist: ChartMetricPlaylist; track: ChartMetricPlaylistTrack}>>(
        `chartmetric/${id}/playlists`,
        {
          params,
        },
      )
    ).data;
  }, []);

  const getSearchArtist = useCallback(async (title: string) => {
    return (await api.get<BaseItemsResponse<ChartmetricSearchableArtist>>(`chartmetric/search-artist/${title}`)).data;
  }, []);

  return {
    setChartMetricId,
    getArtistMetaData,
    getSearchArtist,
    getArtistSocialStreamingUrls,
    getArtistPlaylists,
  };
}
