import {useAdvancedState} from '../../hooks/use-advanced-state';
import {Artist} from '../../api/DTOs/Artist';
import {useCallback, useEffect} from 'react';
import {useLoading} from '../../hooks/use-loading';
import {
  ChartMetricArtistMetadata,
  ChartMetricDomainColorMap,
  ChartMetricSocialStreamingUrl,
} from '../../api/DTOs/Chartmetric/CMArtistMetadata';
import {useArtistsApi} from '../../hooks/apis/use-artists-api';

export function useChartMetricMetadata(artist: Artist | null, preFetchCb?: () => void, afterFetchCb?: () => void) {
  const [loadings, startLoading, stopLoading] = useLoading({
    artistMetadata: true,
    socialStreamingUrls: true,
  });

  const api = useArtistsApi();
  const [chartMetricData, setChartMetricData, updateChartMetricData] = useAdvancedState<{
    artistMetadata: ChartMetricArtistMetadata;
    socialStreamingUrls: Array<ChartMetricSocialStreamingUrl>;
  }>({});

  useEffect(() => {
    if (artist?.chartmetric_id) {
      startLoading('artistMetadata');
      if (preFetchCb) {
        preFetchCb();
      }
      api
        .getArtistMetaData(artist.id)
        .then(res => {
          updateChartMetricData({artistMetadata: res});
        })
        .finally(() => {
          stopLoading('artistMetadata');
          if (afterFetchCb) {
            afterFetchCb();
          }
        });

      startLoading('socialStreamingUrls');
      api
        .getArtistSocialStreamingUrls(artist.id)
        .then(res => {
          updateChartMetricData({socialStreamingUrls: sortSocialStreamingUrls(res.items)});
        })
        .finally(() => stopLoading('socialStreamingUrls'));
    } else {
      setChartMetricData({});
    }
  }, [artist?.chartmetric_id]);

  const sortSocialStreamingUrls = useCallback((urls: Array<ChartMetricSocialStreamingUrl>) => {
    return urls.sort((a, b) => {
      const keys = Object.keys(ChartMetricDomainColorMap).reverse();
      const q = keys.findIndex(item => item === b.domain);
      const w = keys.findIndex(item => item === a.domain);
      if (q > w) {
        return 1;
      }

      if (q < w) {
        return -1;
      }
      return 0;
    });
  }, []);

  return {chartMetricData, loadings};
}
