import React, {useEffect, useMemo, useState} from 'react';
import {IPaginationInfo} from '../../../api/Paginator';
import {FinancialOperationsTable} from './financial-operations-table';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {
  TransactionOperationRelation,
  useTransactionsOperationApi,
} from '../../../hooks/apis/use-transactions-operation-api';
import {TransactionOperation, TransactionOperationType} from '../../../api/DTOs/TransactionOperation';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {DropdownToolBarItemAction} from '../../../components/card-toolbar/Toolbar';
import {useTransactionActionHandler} from './use-transactions-action-handler';
import {TransactionAction} from './transaction-action-types';
import {useSelectApi} from '../../../hooks/use-select-api';
import {JsonParam} from 'use-query-params';
import {useIntl} from '../../../hooks/use-intl';
import {getEnumKeys} from '../../../../types/types';
import {Feed} from '../../../api/DTOs/Feed';
import {DateFormat} from '../../../components/Inputs/InputDatepicker';
import moment from 'moment';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const FinancialOperations: React.FC<any> = () => {
  const intl = useIntl();
  const api = useTransactionsOperationApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
    download: false,
  });

  const {optionsLoadings, selectsOptions, fetchSelectValuesByKey} = useSelectApi();
  const [transactionsOperations, setTransactionsOperations] = useState<Array<TransactionOperation>>([]);
  const [relations, setRelations] = useState<ManyRelations<TransactionOperationRelation> | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const dropdownCommonActions = useMemo<Array<DropdownToolBarItemAction<TransactionAction>>>(
    () =>
      [
        TransactionAction.CREATE_WITHDRAWAL,
        TransactionAction.CREATE_TO_OUR_ORGANIZATION,
        TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE,
      ]
        .map(action => ({
          value: action,
          title: intl.formatMessage({id: 'CREATE_TRANSACTION_' + action}),
        }))
        .concat([
          {
            value: TransactionAction.DOWNLOAD_REPORT,
            title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
          },
          {
            value: TransactionAction.INCOME_REPORT,
            title: intl.formatMessage({id: 'CHANNEL_TRANSACTIONS_INCOME_REPORT'}),
          },
          {
            value: TransactionAction.DOWNLOAD_ACCOUNTS_BALANCE_REPORT,
            title: intl.formatMessage({id: 'DOWNLOAD_ACCOUNTS_BALANCE_REPORT'}),
          },
        ]),
    [],
  );

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'transactions_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'ID'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.ID, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'ID'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.ID, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'type',
          placeholder: intl.formatMessage({id: 'TYPE'}),
          options: [
            TransactionOperationType.INNER_TRANSFER,
            TransactionOperationType.PAYMENT_FOR_SERVICE,
            TransactionOperationType.WITHDRAWAL,
            TransactionOperationType.WITHDRAWAL_TO_MCPAY,
            TransactionOperationType.WITHDRAWAL_TO_CACHE,
            TransactionOperationType.ADJUSTMENT,
            TransactionOperationType.RETURN_YOUTUBE_TAX,
          ].map(t => ({value: t, label: intl.formatMessage({id: 'OPERATION_TYPE_' + t})})),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          selectKey: 'contractors',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'y_channel_id',
          selectKey: 'youtubeChannels',
          placeholder: intl.formatMessage({id: 'CHANNELS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'platform',
          options: getEnumKeys(Feed).map(f => ({
            value: f,
            label: intl.formatMessage({id: 'FEED_' + f}),
          })),
          placeholder: intl.formatMessage({id: 'FEEDS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'is_referral',
          placeholder: intl.formatMessage({id: 'IS_REFERRAL_PAYMENT_OPERATION'}),
          options: [
            {value: '1', label: intl.formatMessage({id: 'WITH_REFERRAL'})},
            {value: '0', label: intl.formatMessage({id: 'WITHOUT_REFERRAL'})},
          ],
        },
        {
          type: FilterType.DATE,
          name: 'month_period',
          showMonthYearPicker: true,
          format: DateFormat.MM_YYYY,
          placeholder: intl.formatMessage({id: 'PERIOD'}),
          queryConfig: {
            name: 'month_period',
            type: {
              encode: (value: Date) => {
                return value ? moment(value).format('DD-MM-YYYY') : undefined;
              },
              decode: value => {
                return value ? moment.utc(value as string, 'DD-MM-YYYY').toISOString() : null;
              },
            },
          },
        },
        {
          type: FilterType.PERIOD,
          name: 'created_at_period',
          placeholder: intl.formatMessage({id: 'CREATED_AT_PERIOD'}),
          extractFields: true,
          queryConfig: {
            name: 'created_at_period',
            type: JsonParam,
          },
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);

  useEffect(() => {
    Promise.all([fetchSelectValuesByKey('youtubeChannels'), fetchSelectValuesByKey('contractors')]).then();
  }, []);

  const fetchTransactions = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getAll(filters);
      setTransactionsOperations(result.items);
      setRelations(result.relations);
      setPaginationInfo(result.paginator as any);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };
  const {handleTransactionAction, loadings: actionLoadings} = useTransactionActionHandler(fetchTransactions);
  const handleDropdownSelect = async (action: TransactionAction) => {
    switch (action) {
      case TransactionAction.CREATE_TO_OUR_ORGANIZATION:
      case TransactionAction.CREATE_WITHDRAWAL:
      case TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE:
        await handleTransactionAction({type: action});
        break;
      case TransactionAction.DOWNLOAD_REPORT:
      case TransactionAction.DOWNLOAD_ACCOUNTS_BALANCE_REPORT:
        await handleTransactionAction({type: action as any});
        break;
      case TransactionAction.INCOME_REPORT:
        await handleTransactionAction({type: TransactionAction.INCOME_REPORT});
    }
  };
  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      sortsConfig={sortConfig}
      filtersConfig={filterConfig}
      toolbarConfig={[
        {
          title: intl.formatMessage({id: 'ACTIONS'}),
          type: 'DROPDOWN',
          actions: dropdownCommonActions,
          loading: actionLoadings.report,
          className: 'font-weight-bolder text-uppercase btn-light-success',
          onSelect: handleDropdownSelect,
        },
      ]}
      pagination={{
        info: paginationInfo,
      }}
      fetchData={fetchTransactions}>
      <FinancialOperationsTable
        transactionsOperationsRelations={relations}
        transactionsOperations={transactionsOperations}
      />
    </BaseListPage>
  );
};
