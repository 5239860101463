import React from 'react';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../../../components/modals/base-modal/base-modal';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {ValidateErrorWrapper} from '../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../components/Inputs/InputText';
import {Modal} from 'react-bootstrap';
import {useIntl} from '../../../../hooks/use-intl';

interface IModalRejectReasonProps {
  visible: boolean;
  loading: boolean;
  validationErrors: ValidationErrorsType;
  error?: string | null;
  yandexZenChannelId: string | null;
  onYandexZenChannelIdChange: (value: string) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalSetYandexZenChannelId: React.FC<IModalRejectReasonProps> = ({
                                                                                visible,
                                                                                loading,
                                                                                validationErrors,
                                                                                yandexZenChannelId,
                                                                                onYandexZenChannelIdChange,
                                                                                onHide,
                                                                                onOkClick,
                                                                              }) => {
  const intl = useIntl();

  return (
    <>
      <BaseModal loading={loading} disabledButtons={loading} visible={visible} onSuccess={onOkClick} onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>{intl.formatMessage({id: 'SET_YANDEX_ZEN_CHANNEL_ID'})}</Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <div className={'form-group'}>
              <ValidateErrorWrapper message={getValidationErrorMessage('yandex_zen_id', validationErrors)}>
                <InputText
                  required
                  hasError={!!getValidationErrorMessage('yandex_zen_id', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  label={intl.formatMessage({id: 'YANDEX_ZEN_CHANNEL_ID'})}
                  value={yandexZenChannelId}
                  onChange={e => onYandexZenChannelIdChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
