import React, {useEffect} from 'react';
import {Artist} from '../../../api/DTOs/Artist';
import {useChartMetricMetadata} from '../use-chartmetric-metadata';
import {Preloader} from '../../../components/preloader';
import {SocialStreamingUrls} from './social-streaming-urls';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {FormattedMessage} from 'react-intl';
import {SocialsStreamingRankings} from './socials-streaming-rankings';
import {ChartMetricSummaryInfo} from './chart-metric-summary-info';
import {ChartMetricTopTracks} from './chart-metric-top-tracks';
import {EmptyListState} from '../../../components/empty-list-state';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {Playlists} from './playlists';
import {PlaylistPlatform} from '../../../hooks/apis/use-artists-api';

type Props = {
  artist: Artist;
  setChartMetricData: UpdateFields<{title: string | null; artistCmId: string | number | null; loading?: boolean}>;
};

export const ChartMetricArtistInfo: React.FC<Props> = ({artist, setChartMetricData}) => {
  const {chartMetricData, loadings} = useChartMetricMetadata(
    artist,
    () => setChartMetricData({loading: true}),
    () => setChartMetricData({loading: false}),
  );

  useEffect(() => {
    if (chartMetricData.artistMetadata?.name) {
      setChartMetricData({
        title: chartMetricData.artistMetadata.name,
        artistCmId: chartMetricData.artistMetadata.id,
      });
    } else {
      setChartMetricData({title: null, artistCmId: null});
    }
  }, [chartMetricData.artistMetadata?.name]);

  return (
    <>
      {chartMetricData?.artistMetadata && chartMetricData.artistMetadata?.cm_statistics && (
        <ChartMetricSummaryInfo statistics={chartMetricData?.artistMetadata?.cm_statistics} />
      )}

      {chartMetricData?.artistMetadata && (
        <SocialsStreamingRankings statistics={chartMetricData?.artistMetadata?.cm_statistics} />
      )}

      {chartMetricData?.artistMetadata?.topTracks && (
        <ChartMetricTopTracks topTracks={chartMetricData?.artistMetadata?.topTracks} />
      )}

      <Card hideFooter>
        <CardTitle>
          <FormattedMessage id={'ARTIST_DATA_SOURCES'} />
        </CardTitle>
        <CardBody>
          {loadings?.socialStreamingUrls ? (
            <Preloader />
          ) : (
            <div>
              {chartMetricData?.socialStreamingUrls == null ||
                (chartMetricData?.socialStreamingUrls?.length === 0 && <EmptyListState />)}
              {chartMetricData?.socialStreamingUrls && (
                <SocialStreamingUrls urls={chartMetricData.socialStreamingUrls} />
              )}
            </div>
          )}
        </CardBody>
      </Card>

      <Playlists artistId={artist.id} platform={PlaylistPlatform.SPOTIFY} />
      <Playlists artistId={artist.id} platform={PlaylistPlatform.YOUTUBE} />
      <Playlists artistId={artist.id} platform={PlaylistPlatform.APPLE} />
    </>
  );
};
