import React, {useMemo} from 'react';
import {CreatedFileAction} from './use-file-handler-actions';
import {useIntl} from '../../hooks/use-intl';
import {CreatedFile} from '../../api/file-api/files-api';
import {DefaultTable} from '../../components/default-table';
import {ManyPrivileges, ManyRelations} from '../../api/base/base-crud-api-response';
import {FilePrivileges, FileRelations} from '../../hooks/apis/use-files-api';
import {TableUtils} from '../../components/table-utils';
import {formatBytes} from '../../utils/utils';
import {RowActionButton} from '../../components/table-actions-button';
import {FormattedMessage} from 'react-intl';
import {CommonRoutes, Routes} from '../../../configs/routes';
import {Link} from 'react-router-dom';
import {IMG_EXTENSIONS, VIDEO_EXTENSIONS} from '../../utils/file-utils';
import {Svg} from '../../images/svg';
import {BootstrapColors, BootstrapSizes} from '../../../styles/bootstap-colors';
import {ICONS} from '../../images/images';

interface CreatedFilesTableProps {
  items: Array<CreatedFile>;
  relations: ManyRelations<FileRelations> | null;
  privileges: ManyPrivileges<FilePrivileges> | null;
  onAction: (item: CreatedFile, action: CreatedFileAction) => Promise<unknown>;
}

export const CreatedFilesTable = ({items, relations, privileges, onAction}: CreatedFilesTableProps) => {
  const intl = useIntl();
  const data = items;

  const columns = useMemo(() => {
    const defaultClassName = 'd-flex align-items-center w-100 h-100 font-weight-bolder justify-content-start';
    return [
      {
        id: 'Preview',
        forceApplySize: true,
        width: 80,
        maxWidth: 80,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          if (VIDEO_EXTENSIONS.includes(value.extension.toLowerCase())) {
            return (
              <div className={'text-center'}>
                <Svg color={BootstrapColors.PRIMARY} src={ICONS.VIDEO} size={BootstrapSizes.X4} />
              </div>
            );
          }

          if (IMG_EXTENSIONS.includes(value.extension.toLowerCase())) {
            return <img alt={'content'} src={value.url} style={{maxHeight: '120px', maxWidth: '67px'}} />;
          }

          return <Svg color={BootstrapColors.DARK} src={ICONS.FILE} size={BootstrapSizes.X3} />;
        },
      },
      {
        id: 'RelationEntity',
        Header: intl.formatMessage({id: 'RELATION'}),
        maxWidth: 150,
        minWidth: 100,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          if (value.artist_id !== null) {
            return TableUtils.renderBaseContent(
              <Link to={Routes.getSpecifyArtistsRoute(value.artist_id)}>
                {intl.formatMessage({id: 'ARTIST'})}: {relations?.artist?.[value.artist_id]?.name}
              </Link>,
            );
          }

          if (value.video_id !== null) {
            return TableUtils.renderBaseContent(
              <Link to={Routes.getSpecifyVideosRoute(value.video_id)}>
                {intl.formatMessage({id: 'VIDEOS'})}: {relations?.video?.[value.video_id]?.title}
              </Link>,
            );
          }
          return TableUtils.renderBaseContent('-');
        },
      },
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 300,
        minWidth: 300,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          return TableUtils.renderBaseContent(
            <a target={'_blank'} download={value.original_file_name} href={value.url} rel='noreferrer'>
              {value.original_file_name}
            </a>,
          );
        },
      },
      {
        id: 'Size',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SIZE'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          return TableUtils.renderNumericContent(formatBytes(value.size));
        },
      },
      {
        id: 'Bucket',
        Header: intl.formatMessage({id: 'BUCKET'}),
        maxWidth: 120,
        minWidth: 120,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          return TableUtils.renderBaseContent(value.bucket);
        },
      },
      {
        id: 'User',
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 120,
        minWidth: 120,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          return TableUtils.renderBaseContent(
            <Link to={CommonRoutes.getSpecifyUserManagementRoute(value.user_id)}>
              {relations?.user?.[value.user_id]?.email}
            </Link>,
          );
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          return (
            <div className={defaultClassName}>
              {intl.formatDate(value.created_at)} {intl.formatTime(value.created_at)}
            </div>
          );
        },
      },
      {
        id: 'Action',
        maxWidth: 50,
        accessor: (br: CreatedFile) => br,
        Cell: ({value}: {value: CreatedFile}) => {
          const actions = [{title: intl.formatMessage({id: 'DOWNLOAD'}), value: CreatedFileAction.DOWNLOAD}];
          if (privileges?.[value.id]?.can_edit) {
            actions.push({title: intl.formatMessage({id: 'EDIT'}), value: CreatedFileAction.EDIT});
          }

          if (privileges?.[value.id]?.can_delete) {
            actions.push({title: intl.formatMessage({id: 'DELETE'}), value: CreatedFileAction.DELETE});
          }

          return TableUtils.renderActionButton(
            <RowActionButton rowDto={value} actions={actions} onSelect={onAction} />,
          );
        },
      },
    ];
  }, [relations, privileges]);

  if (items.length === 0) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return <DefaultTable data={data} columns={columns} />;
};
