import React, {useMemo} from 'react';
import {AsideMenu} from './aside-menu';
import {Brand} from './brand';
import {useHtmlClassService} from '../../../_metronic/layout/_core/MetronicLayout';
import {useAuth} from '../../hooks/use-auth';

export function Aside() {
  const uiService = useHtmlClassService();
  const {isArtist} = useAuth();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
    };
  }, [uiService]);

  return (
    <>
      {isArtist ? null : (
        <div
          id='kt_aside'
          className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
          <Brand />
          <div id='kt_aside_menu_wrapper' className='aside-menu-wrapper flex-column-fluid'>
            <AsideMenu />
          </div>
        </div>
      )}
    </>
  );
}
