import React from 'react';
import {LabelWithDescription} from './Inputs/LabelWithDescription';
import {ValidateErrorWrapper} from './Inputs/ValidateErrorWrapper';
import {Currency} from '../api/contractor-api/Contractor';
import {InputSelect} from './Inputs/InputSelect';
import cn from 'classnames';
import {useIntl} from '../hooks/use-intl';

type Props = {
  validationError: string | Array<string> | null;
  currency?: Currency | null;
  label: string | null;
  className?: string;
  onChange: (currency: Currency | null) => void;
};

export const CurrencyPicker: React.FC<Props> = ({validationError, className, label, onChange, currency}) => {
  const intl = useIntl();
  return (
    <>
      {label && <LabelWithDescription label={label} />}
      <ValidateErrorWrapper message={validationError}>
        <InputSelect
          isClearable
          hasError={!!validationError}
          placeholder={intl.formatMessage({id: 'CHOOSE_CURRENCY'})}
          className={cn(className)}
          value={currency}
          options={[
            {value: Currency.USD, label: `${intl.formatMessage({id: 'DOLLAR'})} ($)`},
            {value: Currency.RUB, label: `${intl.formatMessage({id: 'RUBLE'})} (₽)`},
          ]}
          onChange={onChange}
        />
      </ValidateErrorWrapper>
    </>
  );
};
