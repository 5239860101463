import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

import {UpdateFields} from '../../../../hooks/use-advanced-state';
import {CreateReturnYoutubeTaxToYoutubeContractorFields} from '../../../../hooks/apis/use-transactions-api';
import {PartialNullable} from '../../../../../types/types';
import {InputSelect, SelectOptions} from '../../../Inputs/InputSelect';
import {ValidationErrorsType} from '../../../../utils/utils';
import {useIntl} from '../../../../hooks/use-intl';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../base-modal/base-modal';
import {TransactionAction} from '../../../../pages/admin/transactions/transaction-action-types';
import {AdditionalFields, CreateTransactionFields} from '../create-transaction-fields';
import {DtoFieldInput} from '../../../Inputs/DtoFieldInput';
import {AlertCustom} from '../../../../modules/Auth/component/alert';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';
import {FormattedMessage} from 'react-intl';

type Props = {
  visible: boolean;
  error?: string | null;
  validationErrors?: ValidationErrorsType;
  contractors: SelectOptions;
  channels: SelectOptions;
  loadings: {
    contractors: boolean;
    channels: boolean;
    contracts: boolean;
  };

  entity: PartialNullable<CreateReturnYoutubeTaxToYoutubeContractorFields>;
  onChange: UpdateFields<CreateReturnYoutubeTaxToYoutubeContractorFields>;

  onHide(): void;
  onOkClick(): any;
};

export const ModalCreateReturnTaxToYoutubeTransaction: React.FC<Props> = ({
  loadings,
  visible,
  entity,
  contractors,
  channels,
  error,
  validationErrors,
  onChange,
  onOkClick,
  onHide,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={handleOkClick} onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>
          {intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE})}
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <div className={'font-weight-bolder mb-5'}>
          <FormattedMessage id={`CREATE_TRANSACTION_DESCRIPTION_${TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE}`} />
        </div>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <CreateTransactionFields
            hideContractors
            hideContracts
            entity={entity}
            onChange={onChange}
            validationErrors={validationErrors}
            error={error}
            contractors={contractors}
            loadings={loadings}>
            <AdditionalFields>
              <DtoFieldInput
                dto={entity}
                dtoKey={'y_channel_id'}
                component={InputSelect}
                validationsErrors={validationErrors}
                innerProps={{
                  required: true,
                  placeholder: '',
                  isClearable: true,
                  isLoading: loadings.channels,
                  label: intl.formatMessage({id: 'CHANNEL'}),
                  className: 'mb-2',
                  value: entity.y_channel_id,
                  options: channels,
                  onChange: value => onChange({y_channel_id: value}),
                }}
              />
            </AdditionalFields>
          </CreateTransactionFields>
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
