import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {User} from '../../api/DTOs/User';
import {LogoutAction, SetContractorAction, SetPrivilegesAction, SetUserAction} from './_redux/auth-redux';
import {Contractor} from '../../api/contractor-api/Contractor';
import {Privileges} from '../../api/response-contracts/auth-response-contracts';
import {LayoutSplashScreen} from '../../../_metronic/layout/_core/MetronicSplashScreen';
import {useAuthApi} from '../../hooks/apis/auth-api';

interface MapDispatchToProps {
  logout(): void;

  setUser(user: User): void;

  setContractor(contractor: Contractor): void;

  setPrivileges(privileges: Privileges): void;
}

const AuthInit = ({
  children,
  logout,
  setUser,
  setContractor,
  setPrivileges,
}: MapDispatchToProps & PropsWithChildren<any>) => {
  const didRequest = useRef(false);
  const authApi = useAuthApi();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  /**
   * Если есть персистентный токен, то с ним нужно сходить на сервер и забрать текущего пользователя.
   */
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {
            data: {item, relations, privileges},
          } = await authApi.getAuthData();
          setContractor(relations.contractor);
          setPrivileges(privileges);
          setUser(item);
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    requestUser();
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

const mapDispatchToProps = {
  setUser: SetUserAction,
  logout: LogoutAction,
  setContractor: SetContractorAction,
  setPrivileges: SetPrivilegesAction,
};

export default connect(null, mapDispatchToProps)(AuthInit);
