import React, {CSSProperties} from 'react';
import cn from 'classnames';

interface WidgetProps {
  children: any;
  noStretch?: boolean;
  className?: string;
  style?: CSSProperties;

  onClick?(): void;
}

export const WidgetBase: React.FC<WidgetProps> = ({noStretch, className, style, onClick, children}) => {
  return (
    <>
      <div
        className={cn('widget card card-custom gutter-b', className, {
          'cursor-pointer': onClick,
          'card-stretch': !noStretch,
        })}
        style={style}
        onClick={onClick}>
        {children}
      </div>
    </>
  );
};
