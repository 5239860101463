import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useIntl} from '../../../../hooks/use-intl';
import {
  SupAgreementPrivileges,
  SupAgreementRelations,
  useSubAgreementsApi,
} from '../../../../hooks/apis/use-sub-agreements-api';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {useLoading} from '../../../../hooks/use-loading';
import {Card, CardBody} from '../../../../components/card';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {Preloader} from '../../../../components/preloader';
import {FormattedMessage} from 'react-intl';
import {CustomFormatter} from '../../../../../localization/custom-formatter';
import {AdminRoutes} from '../../../../../configs/routes';
import {FeedsBlock} from '../../../videos/feeds/feeds-block';
import {CALLBACK} from '../../../../consts';
import {ICONS} from '../../../../images/images';
import cn from 'classnames';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {SupAgreementAction, useSupAgreementActionHandler} from './use-sup-agreement-actions-handler';
import {ContractualDocuments} from '../contract-page/contractual-documents';
import {ContractualDocumentType} from '../../../../hooks/apis/use-contractual-documents-api';
import {ContractType} from '../../../../api/contract-api/Contract';
import {SupAgreementChannels} from './sup-agreement-channels';
import {SupAgreementVideos} from './sup-agreement-videos';
import {prepareChannelDataFromResponse} from './create-edit-sup-agreement-page';

export const SupAgreementPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {supAgreementId} = useParams<{supAgreementId: string}>();
  const [loadings, startLoading, stopLoading] = useLoading({fetchAgreement: true});
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const api = useSubAgreementsApi();
  const [supAgreement, setSupAgreement] = useState<SupAgreement | null>(null);
  const [relations, setRelations] = useState<SupAgreementRelations | null>(null);
  const [privileges, setPrivileges] = useState<SupAgreementPrivileges | null>(null);

  useEffect(() => {
    startLoading('fetchAgreement');
    api
      .get(supAgreementId)
      .then(res => {
        setRelations(res.relations);
        setPrivileges(res.privileges);
        setSupAgreement({
          ...res.item,
          add_youtube_channels: prepareChannelDataFromResponse(res.item.add_youtube_channels ?? []),
          change_youtube_channels: prepareChannelDataFromResponse(res.item.change_youtube_channels ?? []),
          delete_youtube_channels: prepareChannelDataFromResponse(res.item.delete_youtube_channels ?? []),
        });
      })
      .finally(() => stopLoading('fetchAgreement'));
  }, []);

  useEffect(() => {
    setBreadcrumb([
      relations?.contract?.composite_number,
      supAgreement?.number ? `${intl.formatMessage({id: 'SUP_AGREEMENT'})}: №${supAgreement.number}` : null,
    ]);
  }, [supAgreement, relations?.contract?.composite_number]);
  const {handleSupAgreementActions, loadings: actionsLoadings} = useSupAgreementActionHandler();

  return (
    <Card hideHeader hideFooter>
      <CardBody>
        {loadings.fetchAgreement ? (
          <Preloader />
        ) : (
          <div>
            <div className={'d-flex align-items-center justify-content-between flex-wrap'}>
              <div>
                <div className={'d-flex align-items-center'}>
                  <span className={'font-size-h4 font-weight-boldest'}>
                    {intl.formatMessage({id: 'SUP_AGREEMENT'})}: №{supAgreement?.number}
                  </span>
                </div>
                <div
                  className={cn('font-weight-bolder', {
                    'text-success': supAgreement?.is_signed,
                    'text-danger': !supAgreement?.is_signed,
                  })}>
                  {intl.formatMessage({id: supAgreement?.is_signed ? 'IS_SIGNED' : 'IS_NOT_SIGNED'})}
                </div>
                <Toolbar
                  className={'mt-5'}
                  items={[
                    {
                      type: 'BUTTON',
                      loading: actionsLoadings.generateTemplate,
                      title: intl.formatMessage({id: 'DOWNLOAD_DOCX_SUP_AGREEMENT_TEMPLATE'}),
                      icon: ICONS.DOWNLOAD,
                      className: 'btn btn-light-success btn-sm font-weight-bolder text-uppercase',
                      onClick: () =>
                        handleSupAgreementActions({
                          type: SupAgreementAction.DOWNLOAD,
                          // @ts-ignore
                          entity: supAgreement,
                        }),
                    },
                    {
                      type: 'BUTTON',
                      title: intl.formatMessage({id: 'EDIT'}),
                      icon: ICONS.EDIT,
                      disabled: privileges?.can_edit === false,
                      className: 'btn btn-secondary btn-sm font-weight-bolder text-uppercase',
                      onClick: () =>
                        history.push(
                          AdminRoutes.getEditSupAgreementRoute(
                            supAgreement?.contract_id as string,
                            supAgreement?.id as string,
                          ),
                        ),
                    },
                    {
                      type: 'BUTTON',
                      loading: actionsLoadings.delete,
                      title: intl.formatMessage({id: 'DELETE'}),
                      icon: ICONS.BIN,
                      disabled: privileges?.can_edit === false,
                      className: 'btn btn-light-danger btn-sm font-weight-bolder text-uppercase',
                      onClick: () =>
                        handleSupAgreementActions({
                          type: SupAgreementAction.DELETE,
                          // @ts-ignore
                          entity: supAgreement,
                          afterAction: {
                            type: 'redirect',
                            to: AdminRoutes.getSpecifyContractRoute((supAgreement as SupAgreement).contract_id),
                          },
                        }),
                    },
                  ]}
                />
              </div>
              <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
                <div className={'d-flex flex-wrap align-items-center py-2'}>
                  <div className={'d-flex align-items-center'}>
                    <div className={'d-flex justify-content-center flex-column mr-6'}>
                      <div className={'font-weight-bolder'}>
                        <FormattedMessage id={'CONTRACT_START_DATE'} />
                      </div>
                      <span className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                        {intl.formatDate(supAgreement?.started_at, {month: 'long', year: 'numeric', day: 'numeric'})}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'separator separator-solid my-7'} />

            <div style={{maxWidth: '1120px'}}>
              {supAgreement?.is_change_expires_at && (
                <div className={'mb-10'}>
                  <h3 className={'font-weight-bolder text-muted'}>
                    {intl.formatMessage({id: 'SUP_AGREEMENT_ACTUAL_EXPIRED_AT'})}
                  </h3>
                  <p className={'font-size-lg font-weight-boldest'}>
                    {CustomFormatter.formatDateWithFullMonth(supAgreement?.change_expires_at)}
                  </p>
                </div>
              )}

              {supAgreement?.is_change_video_rate_percent && (
                <div className={'mb-10'}>
                  <h3 className={'font-weight-bolder text-muted'}>
                    {intl.formatMessage({id: 'SUP_AGREEMENT_ACTUAL_VIDEO_RATE_PERCENT'})}
                  </h3>
                  <p className={'font-size-lg font-weight-boldest'}>
                    {CustomFormatter.formatPercent(supAgreement?.change_video_rate_percent)}
                  </p>
                </div>
              )}
              {relations?.contract?.type === ContractType.YOUTUBE_PARTNER && supAgreement != null && (
                <SupAgreementChannels viewModeEnable supAgreement={supAgreement} />
              )}
              {relations?.contract?.type === ContractType.PUBLISH_VIDEO && supAgreement != null && (
                <SupAgreementVideos viewModeEnable supAgreement={supAgreement} />
              )}
              {supAgreement?.is_change_feeds && (
                <div className={'mb-10'}>
                  <h3 className={'font-weight-bolder text-muted'}>
                    {intl.formatMessage({id: 'SUP_AGREEMENT_ACTUAL_FEEDS'})}
                  </h3>
                  <FeedsBlock disabled selectedFeeds={supAgreement?.change_feeds} onSelectFeed={CALLBACK.EMPTY} />
                </div>
              )}
              <div className={'separator separator-solid my-7'} />
            </div>

            <div className={'font-size-h4 font-weight-boldest mb-5'}>
              <FormattedMessage id={'CONTRACTUAL_DOCUMENTS'} />
            </div>
            {supAgreement && (
              <ContractualDocuments
                type={ContractualDocumentType.SUP_AGREEMENT}
                parent={supAgreement}
                documents={relations?.contractualDocumentFiles ?? []}
              />
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};
