import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useLoading} from '../../../../../hooks/use-loading';
import {Contractor, ContractorType, Currency} from '../../../../../api/contractor-api/Contractor';
import {toast} from 'react-toastify';
import {useIntl} from '../../../../../hooks/use-intl';
import {Preloader} from '../../../../../components/preloader';
import {useContractorsApi} from '../../../../../hooks/apis/use-contractors-api';
import {useAdvancedState} from '../../../../../hooks/use-advanced-state';
import {AdminRoutes} from '../../../../../../configs/routes';
import {ApiRequestException} from '../../../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../../../api/exceptions/BaseException';
import {Card, CardBody, CardFooter, CardTitle} from '../../../../../components/card';
import {Toolbar} from '../../../../../components/card-toolbar/Toolbar';
import {ICONS} from '../../../../../images/images';
import {ContractorFields} from './contractor-fields';
import {useCustomBreadcrumbs} from '../../../../../components/breadcrumbs/breadcrumbs-context';

export const CreateEditContractorPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {id: contractorId} = useParams<{id?: string}>();

  const api = useContractorsApi();
  const [contractor, , updateFields] = useAdvancedState<Contractor>(
    {
      type: ContractorType.INDIVIDUAL,
      individual_is_self_employed: false,
      is_our: false,
      bank_account_currency: Currency.RUB,
    },
    api.setValidationErrors,
  );
  const [loading, startLoading, stopLoading] = useLoading({
    contractor: !!contractorId,
  });
  const {needFetchBreadcrumbsParams, setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    if (contractorId) {
      fetchContractor().then();
    }
  }, []);

  useEffect(() => {
    if (contractor) {
      if (needFetchBreadcrumbsParams) {
        setBreadcrumb(contractor.calculated_name);
      }
    }
  }, [contractor]);

  const fetchContractor = async () => {
    if (!contractorId) {
      throw new Error('');
    }
    try {
      startLoading('contractor');
      const result = await api.get(contractorId);
      updateFields({
        ...result.item,
        individual_is_self_employed: result.item.individual_is_self_employed ?? false,
        is_our: result.item.is_our ?? false,
      });
    } catch (e) {
      toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('contractor');
    }
  };

  const handleSaveContractor = async () => {
    try {
      if (contractorId) {
        await api.update(contractorId, contractor);
        history.push(AdminRoutes.getSpecifyContractorRoute(contractorId));
      } else {
        const createdContractor = await api.create(contractor);
        history.push(AdminRoutes.getSpecifyContractorRoute(createdContractor.id));
      }
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  if (loading.contractor) {
    return <Preloader />;
  }

  return (
    <Card>
      <CardTitle>{intl.formatMessage({id: contractorId ? 'EDIT_CONTRACTOR' : 'CREATE_CONTRACTOR'})}</CardTitle>
      <CardBody>
        <ContractorFields contractor={contractor} updateFields={updateFields} validationErrors={api.validationErrors} />
      </CardBody>
      <CardFooter>
        <Toolbar
          items={[
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'SAVE'}),
              className: 'btn btn-light-primary',
              icon: ICONS.DOWNLOAD,
              onClick: handleSaveContractor,
            },
          ]}
        />
      </CardFooter>
    </Card>
  );
};
