import React, {createContext, useContext, useMemo} from 'react';
import {useAuth} from '../app/hooks/use-auth';
import {
  buildAvailablePages,
  GroupPagesConfiguration,
  PageConfiguration,
  PageConfigurations,
} from './pages-configuration';

type PagesConfigurationWithFlattenGroup = Omit<PageConfigurations, 'pages'> & {pages: Array<PageConfiguration>};

type PagesConfigurationsContextProps = {
  pagesConfigurations: PageConfigurations;
  pagesConfigurationsWithFlattenGroups: PagesConfigurationWithFlattenGroup;
};

// @ts-ignore
const PagesConfigurationContext = createContext<PagesConfigurationsContextProps>();

function isGroupGuard(item: PageConfiguration | GroupPagesConfiguration) {
  return !!(item as GroupPagesConfiguration)?.pages;
}

export const PagesConfigurationsProvider = ({children}: any) => {
  const {privileges, isAdmin, contractor, user} = useAuth();
  const configurations = useMemo(
    () => buildAvailablePages(isAdmin, privileges, contractor, user),
    [privileges, isAdmin],
  );

  /**
   * Для регистрации роутов нам абсолютно не важна группировка роутов.
   */
  const configurationWithFlattenGroups = useMemo(() => {
    const configurations = buildAvailablePages(isAdmin, privileges, contractor, user);
    const groups = [...configurations.pages].filter(page => isGroupGuard(page)) as Array<GroupPagesConfiguration>;
    configurations.pages = configurations.pages.filter(page => !isGroupGuard(page));
    groups.forEach(gr => {
      configurations.pages.push(...gr.pages);
    });
    return configurations as PagesConfigurationWithFlattenGroup;
  }, [privileges, isAdmin]);
  return (
    <PagesConfigurationContext.Provider
      value={{
        pagesConfigurations: configurations,
        pagesConfigurationsWithFlattenGroups: configurationWithFlattenGroups,
      }}>
      {children}
    </PagesConfigurationContext.Provider>
  );
};

export const usePagesConfiguration = () => {
  return useContext(PagesConfigurationContext);
};
