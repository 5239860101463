import React from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {YoutubeChannel} from '../../../api/DTOs/YoutubeChannel';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import cn from 'classnames';
import {OverlayTrigger, Popover} from 'react-bootstrap';

interface YoutubeChannelCardProps {
  channel: YoutubeChannel;
}

export const YoutubeChannelCard: React.FC<YoutubeChannelCardProps> = ({channel}) => {
  const renderHiddenRibbon = () => {
    if (channel.hiddenFromYoutubeAt === null) {
      return null;
    }

    // noinspection RequiredAttributes
    return (
      <OverlayTrigger
        placement='bottom'
        trigger={['hover', 'focus']}
        overlay={
          <Popover id={''}>
            <Popover.Title as='h3' className={'text-danger font-weight-bolder'}>
              <FormattedMessage id={'CHANNEL_NOT_AVAILABLE'} />
            </Popover.Title>
          </Popover>
        }>
        <div className='ribbon-target bg-danger' style={{top: '-1px', right: '0px', borderTopRightRadius: '0.42rem'}}>
          <i className='ki ki-outline-info text-light mr-1' /> <FormattedMessage id={'NOTICE'} />
        </div>
      </OverlayTrigger>
    );
  };

  const renderMetric = (title: string, value: number) => {
    return (
      <div className='d-flex justify-content-between align-items-center'>
        <span className='text-dark-75 font-weight-bolder mr-2'>
          <FormattedMessage id={title} />
        </span>
        <span className='text-muted font-weight-bold'>
          <FormattedNumber value={value} />
        </span>
      </div>
    );
  };

  return (
    <div
      className={cn('card card-custom border gutter-b card-stretch ribbon ribbon-top', {
        'border border-danger': channel.hiddenFromYoutubeAt !== null,
      })}>
      {renderHiddenRibbon()}
      <div className='card-body text-center pt-4'>
        <div className='mt-7'>
          <Link to={Routes.getYoutubeSpecifyChannelRoute(channel.id)}>
            <div className='symbol symbol-sm-150 symbol-lg-150'>
              <img src={tryGetPreviewOrStub(channel.thumbnails).url} alt='image' />
            </div>
          </Link>
        </div>
        <div className='my-2 mb-7'>
          <Link
            to={Routes.getYoutubeSpecifyChannelRoute(channel.id)}
            className='text-dark font-weight-bold text-hover-primary font-size-h4'>
            {channel.title}
          </Link>
        </div>
        <div className='mb-7 font-size-lg'>
          {renderMetric('VIDEOS', channel.videoCount)}
          {renderMetric('VIEWS', channel.viewCount)}
          {renderMetric('SUBSCRIBERS', channel.subscriberCount)}
        </div>
      </div>
    </div>
  );
};
