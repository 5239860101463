import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {AlertCustom} from '../component/alert';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {useAuthApi} from '../../../hooks/apis/auth-api';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';

export const ChangePassword: React.FC = () => {
  const intl = useIntl();

  const api = useAuthApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  const sendChangePasswordRequest = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setValidationError(null);
      setError(null);
      await api.changePassword({
        current_password: password,
        new_password: newPassword,
        new_password_confirmation: passwordConfirmation,
      });
      toast.success(intl.formatMessage({id: 'SUCCESS_CHANGE_PASSWORD'}));
      setPassword('');
      setNewPassword('');
      setPasswordConfirmation('');
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header py-3'}>
          <div className='card-title'>
            <h3 className='card-label font-weight-bolder text-dark'>
              <FormattedMessage id={'CHANGE_PASSWORD'} />
            </h3>
          </div>
        </div>
        <div className={`card-body`}>
          <form
            className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp pb-0 mb-0'>
            <AlertCustom
              text={error}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != null}
            />

            <ValidateErrorWrapper message={validationErrors && validationErrors['current_password']}>
              <InputText
                name={'current_password'}
                type={'password'}
                value={password}
                hasError={validationErrors?.current_password != undefined}
                onChange={e => setPassword(e.currentTarget.value)}
                label={intl.formatMessage({id: 'CURRENT_PASSWORD'})}
                placeholder={intl.formatMessage({id: 'CURRENT_PASSWORD'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={validationErrors && validationErrors['new_password']}>
              <InputText
                name={'new_password'}
                type={'password'}
                value={newPassword}
                hasError={validationErrors?.new_password != undefined}
                onChange={e => setNewPassword(e.currentTarget.value)}
                label={intl.formatMessage({id: 'NEW_PASSWORD'})}
                placeholder={intl.formatMessage({id: 'NEW_PASSWORD'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={validationErrors && validationErrors['new_password_confirmation']}>
              <InputText
                name={'new_password_confirmation'}
                type={'password'}
                label={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
                value={passwordConfirmation}
                hasError={validationErrors?.new_password_confirmation != undefined}
                onChange={e => setPasswordConfirmation(e.currentTarget.value)}
                placeholder={intl.formatMessage({id: 'CONFIRM_NEW_PASSWORD'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <Toolbar
              className='form-group d-flex flex-wrap flex-center mb-0 pb-0'
              items={[
                {
                  loading: loading,
                  disabled: loading,
                  type: 'BUTTON',
                  title: intl.formatMessage({id: 'SAVE'}),
                  className: 'btn btn-light-primary btn-sm font-weight-bold',
                  onClick: sendChangePasswordRequest,
                },
              ]}
            />
          </form>
        </div>
      </div>
    </>
  );
};
