import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ContractorsApi} from '../../../api/contractor-api/contractors-api';
import {ModalEditContractor} from './modal-edit-contractor';
import {CloseModalEvent, CloseModalReason, OpenModalPayload} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {Contractor} from '../../../api/contractor-api/Contractor';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {PartialNullable} from '../../../../types/types';
import {EntityId} from '../../../api/base/BaseEntity';

interface IModalEditContractorContext {
  modalEditContractorVisible: boolean;

  showEditContractorModal(contractor: OpenModalPayload<Contractor>): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditContractorContext = createContext<IModalEditContractorContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalEditContractorProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new ContractorsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [contractor, setContractor, updateContractorFields] = useAdvancedState<PartialNullable<Contractor>>(
    {},
  );

  const showModal = async (payload: OpenModalPayload<Contractor>) => {
    setVisible(true);
    setContractor((payload.data?.entity as Contractor) || {});
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      await api.editContractor(contractor?.id as EntityId, contractor);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditContractorContext = {
    modalEditContractorVisible: visible,
    showEditContractorModal: showModal,
  };

  return (
    <ModalEditContractorContext.Provider value={value}>
      {children}
      <ModalEditContractor
        visible={visible}
        error={error}
        entity={contractor}
        validationErrors={validationErrors}
        onChange={(field, value) => updateContractorFields({[field]: value})}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditContractorContext.Provider>
  );
};

export const useModalEditContractor = () => {
  return useContext(ModalEditContractorContext);
};
