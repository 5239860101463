import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IResponseBase} from '../response-contracts/base-response';
import {IFileDto} from './IFileDto';
import {EntityId, BaseEntity} from '../base/BaseEntity';
import {BaseItemResponse} from '../base/base-crud-api-response';

export type SignedUrlPayload = {
  url: string;
  bucket: string;
};

export type CreateFilePayload = {
  original_file_name: string;
  extension: string;
  size: number;
} & SignedUrlPayload;

export interface CreatedFile extends BaseEntity {
  original_file_name: string;
  url: string;
  bucket: string;
  extension: string;
  size: number;
  user_id: EntityId;
  artist_id: EntityId | null;
  video_id: EntityId | null;
  feed_id: EntityId | null;
}

export type UploadedPartMetadata = {
  ETag: string;
  PartNumber: number;
};

export class FilesApi extends BaseEndpoint {
  public async uploadPublicFile(file: File): Promise<IResponseBase<{item: IFileDto}>> {
    const formData = new FormData();
    formData.append('fields[file]', file);
    const response = await this.postFile(`${config.BASE_API_URL}/admin/files/upload-public-file`, formData);
    return response.data;
  }

  public async createFile(payload: CreateFilePayload): Promise<IResponseBase<{item: CreatedFile}>> {
    const result = await this.post(`${config.BASE_API_URL}/files/`, {
      fields: {
        ...payload,
      },
    });
    return result.data;
  }

  public async getS3SignedUrl(urlPrefix: string, extension: string): Promise<IResponseBase<{item: SignedUrlPayload}>> {
    const result = await this.post(`${config.BASE_API_URL}/files/${urlPrefix}`, {
      fields: {
        extension,
      },
    });

    return result.data;
  }

  public async uploadFileToS3BySignedUrl(url: string, file: File | Blob) {
    const res = await this.putFile(url, file);
    return res.data;
  }

  public async partUploadFileToS3BySignedUrl(url: string, file: File | Blob): Promise<string> {
    const res = await this.putFile(url, file);
    return res.headers.etag;
  }

  public async startMultipartUpload(
    urlPrefix: string,
    payload: {
      extension: string;
      file_name: string;
      counts: number;
    },
  ): Promise<IResponseBase<{item: {upload_id: number; urls: Array<string>; file_name: string}}>> {
    const result = await this.post(`${config.BASE_API_URL}/files/${urlPrefix}`, {
      fields: {
        ...payload,
      },
    });
    return result.data;
  }

  public async getPreSignedUrlsForMultipartUploading(
    urlPrefix: string,
    payload: {file_name: string; upload_id: number; counts: number},
  ) {
    const result = await this.post(`${config.BASE_API_URL}/files/${urlPrefix}`, {
      fields: {
        ...payload,
      },
    });
    return result.data;
  }

  public async completeMultipartUpload(
    urlPrefix: string,
    payload: {
      file_name: string;
      upload_id: number;
      parts_info: Array<UploadedPartMetadata>;
    },
  ): Promise<BaseItemResponse<{url: string; bucket: string}>> {
    const result = await this.post<any>(`${config.BASE_API_URL}/files/${urlPrefix}`, {
      fields: {
        ...payload,
      },
    });
    return result.data;
  }
}
