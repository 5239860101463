export const VIDEO_EXTENSIONS = ['mp4', 'mov', 'avi', 'mkv'];
export const IMG_EXTENSIONS = ['png', 'jpg', 'jpeg', 'svg', 'ico'];

export function createUrlFromBlob(file: File | Blob) {
  return URL.createObjectURL(file);
}

export function revokeUrlCreatedFromBlob(url: string) {
  URL.revokeObjectURL(url);
}

export enum ImageRatios {
  RATIO_16_9 = 16 / 9,
  RATIO_4_3 = 4 / 3,
  RATIO_1_1 = 1,
}

export async function validateRatioOfSide(file: File, ratio: Array<ImageRatios> | ImageRatios) {
  if (!file.type.includes('image')) {
    throw new Error('Please select image file');
  }

  if (!Array.isArray(ratio)) {
    ratio = [ratio];
  }

  const url = createUrlFromBlob(file);
  const image = new Image();
  image.src = url;
  return new Promise((resolve, reject) => {
    image.onload = () => {
      if ((ratio as Array<ImageRatios>).includes(image.naturalWidth / image.naturalHeight)) {
        revokeUrlCreatedFromBlob(url);
        return resolve(null);
      } else {
        revokeUrlCreatedFromBlob(url);
        return reject(new Error('IMAGE_RATIOS_INCORRECT'));
      }
    };
  });
}
