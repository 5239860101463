import React from 'react';
import {ContractorDataValidationStatus} from '../../../../../api/contractor-api/Contractor';
import {FormattedMessage} from 'react-intl';

type Props = {
  title: string;
  match: ContractorDataValidationStatus[];
};

const ILLUSTRATIONS = {
  ZOOM: require('../../../../../images/illustration/Zoom.png'),
};

export const StepWaitingModerate: React.FC<Props> = () => {
  return (
    <>
      <div className='text-center'>
        <h2 className='font-weight-boldest mt-10'>
          <FormattedMessage id={'REGISTRATION_WAITING_MODERATION_NOTICE'} />
        </h2>
        <p className='text-gray-400 font-weight-bolder mb-10 save-line-and-tabulation'>
          <FormattedMessage id={'REGISTRATION_WAITING_MODERATION_SUB_NOTICE'} />
        </p>
      </div>
      <div className='text-center px-4'>
        <img className={'mw-100 mh-45vh'} src={ILLUSTRATIONS.ZOOM} alt='nones' />
      </div>
    </>
  );
};
