import {Dispatch, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Contractor} from '../../api/contractor-api/Contractor';
import {FinancialAccount} from '../../api/DTOs/FinancialAccount';
import {Contract} from '../../api/contract-api/Contract';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {EntityId} from '../../api/base/BaseEntity';
import {IResponseBase} from '../../api/response-contracts/base-response';
import {ValidationErrorsType} from '../../utils/utils';
import {isValidationException} from '../../utils/api-utils';
import {User} from "../../api/DTOs/User";

export type ContractorRelations = {
  accounts: Array<FinancialAccount>;
  contracts: Array<Contract>;
  users: Array<User>;
  ownerUser: User | null;
};

export function useContractorsApi() {
  const config = useRef({prefixUrl: 'contractors', isAdmin: false});
  const api = useEntityApi<Contractor, ContractorRelations>(config.current);
  return {
    ...api,
    ...useAdditionalRoutes(config.current, api.setValidationErrors),
  };
}

const useAdditionalRoutes = (params: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) => {
  const api = useBaseEndpointApi();
  const registerConfirm = async (id: EntityId) => {
    return (await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-confirm`)).data;
  };

  const registerReject = async (id: EntityId, reject_reason: string) => {
    try {
      return (
        await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-reject`, {
          fields: {
            reject_reason,
          },
        })
      ).data;
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  const registerNeedRequirements = async (id: EntityId, requirements: string) => {
    try {
      return (
        await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-need-fix-requirements`, {
          fields: {
            requirements,
          },
        })
      ).data;
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  return {
    registerConfirm,
    registerReject,
    registerNeedRequirements,
  };
};
