import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';

export const useTransliterateApi = () => {
  const api = useBaseEndpointApi();

  const transliterate = async (value: string) => {
    return await api.post<BaseItemResponse<string>>(`transliterate`, {
      fields: {
        string: value,
      },
    });
  };

  return {
    transliterate,
  };
};
