import React from 'react';
import {FinancialAccount} from '../../../api/DTOs/FinancialAccount';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {ValueWithLabel} from '../../base/base-card-components/value-with-label';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import cn from 'classnames';
import {useIntl} from '../../../hooks/use-intl';

type Props = {
  fromAccount: FinancialAccount | null;
  toAccount: FinancialAccount | null;
};
export const AccountsInfo: React.FC<Props> = ({toAccount, fromAccount}) => {
  const intl = useIntl();
  const renderAccountInfo = (account: FinancialAccount | null) => {
    if (!account) {
      return null;
    }
    return (
      <>
        <ValueWithLabel
          title={'CURRENT_BALANCE'}
          value={CustomFormatter.formatMoney(account.balance, account.currency)}
          className={cn('mb-0')}
        />
        <ValueWithLabel title={'ACCOUNT_TYPE'} value={account.type} className={cn('mb-0')} />
        <ValueWithLabel title={'ACCOUNT_CURRENCY'} value={account.currency} className={cn('mb-0')} />
        {account.contractorId && (
          <ValueWithLabel
            title={'CONTRACTOR'}
            value={
              <Link to={AdminRoutes.getSpecifyContractorRoute(account.contractorId)}>{account.contractorName}</Link>
            }
            className={cn('mb-0')}
          />
        )}
        {account.description && <ValueWithLabel title={'DESCRIPTION'} value={account.description} />}
      </>
    );
  };
  return (
    <>
      <div className={'row'}>
        <div className={'col-md-6 col-sm-12 my-1 mb-sm-5'}>
          <div className={'font-weight-boldest font-size-lg mb-1'}>{intl.formatMessage({id: 'FROM'})}:</div>
          {renderAccountInfo(fromAccount)}
        </div>
        <div className={'col-md-6 col-sm-12 my-1'}>
          <div className={'font-weight-boldest font-size-lg mb-1'}>{intl.formatMessage({id: 'TO'})}:</div>
          {renderAccountInfo(toAccount)}
        </div>
      </div>
    </>
  );
};
