import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {Contractor} from '../../../api/contractor-api/Contractor';
import {PartialNullable} from '../../../../types/types';

interface IModalCreateContractProps {
  visible: boolean;

  error?: string | null;
  validationErrors?: { [key: string]: Array<string> } | null;
  entity: PartialNullable<Contractor>;
  onChange: (key: keyof Contractor, value: any) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalEditContractor: React.FC<IModalCreateContractProps> = (
  {
    visible,
    error,
    entity,
    validationErrors,
    onHide,
    onOkClick,
    onChange,
  }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'EDIT'})}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'EDIT_CONTRACTOR'}/>
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('name', validationErrors)}>
                <InputText
                  required
                  value={entity.calculated_name}
                  label={intl.formatMessage({id: 'NAME'})}
                  hasError={!!getValidationErrorMessage('name', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onChange('calculated_name', e.currentTarget.value)}

                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('code', validationErrors)}>
                <InputText
                  required
                  value={entity.code}
                  label={intl.formatMessage({id: 'CODE'})}
                  hasError={!!getValidationErrorMessage('code', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onChange('code', e.currentTarget.value)}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
