import React from 'react';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: (props: {[P in keyof Contractor]?: Contractor[P]}) => void;
};

export const SignatoryFieldsEn: React.FC<Props> = ({validationErrors, updateFields, contractor}) => {
  const intl = useIntl();

  return (
    <>
      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('legal_signatory_name_last_en', validationErrors)}>
        <InputText
          value={contractor?.legal_signatory_name_last_en}
          label={intl.formatMessage({id: 'LAST_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_last_en', validationErrors)}
          onChange={e => updateFields({legal_signatory_name_last_en: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LAST_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('legal_signatory_name_first_en', validationErrors)}>
            <InputText
              value={contractor?.legal_signatory_name_first_en}
              label={intl.formatMessage({id: 'FIRST_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_first_en', validationErrors)}
              onChange={e => updateFields({legal_signatory_name_first_en: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('legal_signatory_name_middle_en', validationErrors)}>
            <InputText
              value={contractor?.legal_signatory_name_middle_en}
              label={intl.formatMessage({id: 'MIDDLE_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_middle_en', validationErrors)}
              onChange={e => updateFields({legal_signatory_name_middle_en: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>
    </>
  );
};
