import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason, OpenModalPayload} from '../base-modal/CloseModalEvent';
import {ModalCreateEditGenre} from './create-edit-genre-modal';
import {useEntityApi} from '../../../hooks/use-entity-api';
import {Genre} from '../../../api/DTOs/Genre';
import {EntityId} from '../../../api/base/BaseEntity';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {PartialNullable} from '../../../../types/types';

interface IModalCreateEditGenreProps {
  modalCreateEditGenreVisible: boolean;

  showCreateEditGenreModal(payload: OpenModalPayload<Genre>): Promise<CloseModalEvent<Genre>>;
}

// @ts-ignore
const ModalCreateEditGenreContext = createContext<IModalCreateEditGenreProps>();

let closeResolver: ((data: CloseModalEvent<Genre>) => unknown) | null = null;
export const ModalCreateEditGenreProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const api = useEntityApi<Genre>({isAdmin: false, prefixUrl: 'genres'});
  const [error, setError] = useState<string | null>();
  const [visible, setVisible] = useState<boolean>(false);

  const [genre, setGenre] = useState<PartialNullable<Genre>>({});
  const [openPayload, setOpenPayload] = useState<OpenModalPayload<Genre> | null>(null);

  const showModal = async (openPayload: OpenModalPayload<Genre>) => {
    setVisible(true);
    setOpenPayload(openPayload);
    if (openPayload.type === 'EDIT') {
      setGenre(openPayload.data.entity);
    } else {
      if (openPayload.data) {
        setGenre(openPayload.data.entity);
      }
    }

    return new Promise<CloseModalEvent<Genre>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setGenre({});
    setOpenPayload(null);
    setError(undefined);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      let loadedEntity: Genre | null = null;
      if (openPayload?.type === 'EDIT') {
        loadedEntity = (await api.update(genre?.id as EntityId, genre)) as Genre;
      } else if (openPayload?.type === 'CREATE') {
        loadedEntity = await api.create(genre);
      }

      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: loadedEntity as Genre});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setError(e.message || e.errorMessage);
      }
    }
  };

  const value: IModalCreateEditGenreProps = {
    modalCreateEditGenreVisible: visible,
    showCreateEditGenreModal: showModal,
  };

  return (
    <ModalCreateEditGenreContext.Provider value={value}>
      {children}
      <ModalCreateEditGenre
        visible={visible}
        title={genre.name || null}
        errors={error}
        validationErrors={api.validationErrors}
        onChangeTitle={newTitle => setGenre(prevState => ({...prevState, name: newTitle}))}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditGenreContext.Provider>
  );
};

export const useModalCreateEditGenre = () => {
  return useContext(ModalCreateEditGenreContext);
};
