import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FinancialReportType} from '../../../api/financial-report-api/FinancialBulkReports';
import './financial-reports-table.scss';
import {ReportsInfo} from './reports-info';
import {Spinner} from 'react-bootstrap';
import {DefaultTable} from '../../../components/default-table';
import {BadgeCustom} from '../../../components/badge-custom';

type Record = {
  fileName: string;
  type: FinancialReportType;
};

interface IFinancialReportsTableProps {
  reports: Array<Record>;

  onDownloadClick(type: FinancialReportType): boolean;
}

export const FinancialReportsTable = ({reports, onDownloadClick}: IFinancialReportsTableProps) => {
  const intl = useIntl();
  const data = reports;
  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder d-flex justify-content-center align-items-center h-100';
    return [
      {
        id: 'FileName',
        Header: intl.formatMessage({id: 'FILE_NAME'}),
        maxWidth: 500,
        width: 400,
        minWidth: 400,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          return (
            <div className={'file-name-cell font-weight-bolder'}>
              <span>{value.fileName}</span>
            </div>
          );
        },
      },
      {
        id: 'ReportType',
        Header: intl.formatMessage({id: 'REPORT_TYPE'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          const reportDescription = ReportsInfo.find(r => {
            return r.type === value.type;
          });

          if (!reportDescription) {
            return 'N/A';
          }

          return (
            <div className={defaultClassName}>
              <div className={'w-100'}>
                <FormattedMessage tagName={'div'} id={reportDescription.humanDescription} />
                <BadgeCustom variant={'secondary'}>
                  <span className={'text-muted'}>{value.type}</span>
                </BadgeCustom>
              </div>
            </div>
          );
        },
      },
      {
        id: 'Download',
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          return <ActionButton type={value.type} onDownloadClick={onDownloadClick} />;
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};

interface IActionButtonProps {
  type: FinancialReportType;

  onDownloadClick(type: FinancialReportType): boolean;
}

const ActionButton: React.FC<IActionButtonProps> = ({type, onDownloadClick}) => {
  const [loading, setLoading] = useState(false);
  const handleDownloadClick = async () => {
    try {
      setLoading(true);
      onDownloadClick(type);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'font-weight-bolder d-flex justify-content-center align-items-center h-100'}>
        <button onClick={handleDownloadClick} className={'btn btn-secondary btn-sm'}>
          {loading ? (
            <Spinner className={'mr-1'} size={'sm'} animation={'border'} />
          ) : (
            <i className='flaticon-download icon-sm' />
          )}
          <FormattedMessage id={'DOWNLOAD'} />
        </button>
      </div>
    </>
  );
};
