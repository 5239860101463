import React, {useMemo, useState} from 'react';
import {
  ChartMetricGenre,
  ChartMetricStatisticDiff,
  ChartMetricStats,
} from '../../../api/DTOs/Chartmetric/CMArtistMetadata';
import {useIntl} from '../../../hooks/use-intl';
import {InputSelect, SelectOptions} from '../../../components/Inputs/InputSelect';
import {capitalizeFirstLetter} from '../../../utils/utils';
import {Card, CardBody, CardTitle, CardToolbar} from '../../../components/card';
import {SocialStreamingRank} from './social-streaming-rank';

type Props = {
  statistics: ChartMetricStats;
};

export enum ChartMetricArtistRankingPeriod {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  BIMONTHLY = 'bimonthly',
}

type RankInfo = {
  title: string;
  value: number | null;
  diff: number | null;

  valuePostfix?: string;
  diffPostfix?: string;
};
export const SocialsStreamingRankings: React.FC<Props> = ({statistics}) => {
  const intl = useIntl();

  const [diffMode, setDiffMode] = useState<'diff' | 'diff_percent'>('diff');
  const [period, setPeriod] = useState<ChartMetricArtistRankingPeriod>(ChartMetricArtistRankingPeriod.WEEKLY);
  const periodSelectValues = useMemo<SelectOptions>(
    () => [
      {
        label: intl.formatMessage({id: 'ARTIST_RANK_PERIOD_' + ChartMetricArtistRankingPeriod.WEEKLY}),
        value: ChartMetricArtistRankingPeriod.WEEKLY,
      },
      {
        label: intl.formatMessage({id: 'ARTIST_RANK_PERIOD_' + ChartMetricArtistRankingPeriod.MONTHLY}),
        value: ChartMetricArtistRankingPeriod.MONTHLY,
      },
      {
        label: intl.formatMessage({id: 'ARTIST_RANK_PERIOD_' + ChartMetricArtistRankingPeriod.BIMONTHLY}),
        value: ChartMetricArtistRankingPeriod.BIMONTHLY,
      },
    ],
    [],
  );

  const rankMetrics = useMemo<RankInfo[]>(() => {
    function isRankGuard(genre: ChartMetricGenre | null | [] | undefined) {
      return !Array.isArray(genre) && genre?.name;
    }

    function setStats(genre: ChartMetricGenre, diffCb: () => number | null): RankInfo {
      return {
        title: `${intl.formatMessage({id: 'CM_RANK_GENRE'})}: ${genre.name
          .split(' ')
          .map(word => capitalizeFirstLetter(word))
          .join(' ')}`,
        value: genre.rank,
        diff: diffCb(),
        diffPostfix: intl.formatMessage({id: 'RANK_POSITIONS'}),
      };
    }

    const rankMetrics = [];

    (['cm_artist_rank', 'engagement_rank'] as Array<keyof ChartMetricStats>).forEach(metric => {
      const diff = (statistics[`${period}_${diffMode}` as keyof ChartMetricStats] as ChartMetricStatisticDiff)[
        metric as keyof ChartMetricStatisticDiff
      ] as number;
      rankMetrics.push({
        title: intl.formatMessage({id: `CM_RANK_${metric}`}),
        value: statistics[metric] as number,
        diff: diff != null && metric.includes('rank') ? diff * -1 : diff,
        diffPostfix: intl.formatMessage({id: 'RANK_POSITIONS'}),
      });
    });

    if (statistics?.countryRank) {
      rankMetrics.push({
        title: `${intl.formatMessage({id: 'COUNTRY'})}: ${statistics?.countryRank?.country}`,
        value: statistics?.countryRank?.rank,
        diff: (statistics[`${period}_${diffMode}` as keyof ChartMetricStats] as ChartMetricStatisticDiff)?.countryRank
          ?.rank as number,
        diffPostfix: intl.formatMessage({id: 'RANK_POSITIONS'}),
      });
    }

    if (statistics?.genreRank) {
      if (isRankGuard(statistics.genreRank)) {
        const genre = statistics.genreRank as ChartMetricGenre;
        rankMetrics.push(
          setStats(genre, () => {
            const diffRank = (statistics[`${period}_${diffMode}` as keyof ChartMetricStats] as ChartMetricStatisticDiff)
              .genreRank;
            const diff = isRankGuard(diffRank) ? (diffRank as ChartMetricGenre).rank : null;
            return diff != null ? diff * -1 : diff;
          }),
        );
      }
    }

    if (statistics?.subGenreRanks) {
      statistics?.subGenreRanks.forEach((genre, index) => {
        rankMetrics.push(
          setStats(genre, () => {
            const diffRank = (statistics[`${period}_${diffMode}` as keyof ChartMetricStats] as ChartMetricStatisticDiff)
              .subGenreRanks?.[index];
            const diff = isRankGuard(diffRank) ? (diffRank as ChartMetricGenre).rank : null;
            return diff != null ? diff * -1 : diff;
          }),
        );
      });
    }

    return rankMetrics;
  }, [period, diffMode, statistics]);

  const socialStreamingRanks = useMemo(() => {
    function setMetric({
      key,
      valuePostfix,
      diffPostfix,
    }: {
      key: keyof ChartMetricStatisticDiff;
      valuePostfix?: string;
      diffPostfix?: string;
    }): RankInfo {
      return {
        title: intl.formatMessage({id: `CM_RANK_${key}`}),
        value: statistics[key] as number,
        diff: (statistics[`${period}_${diffMode}` as keyof ChartMetricStats] as ChartMetricStatisticDiff)[
          key
        ] as number,
        diffPostfix,
        valuePostfix,
      };
    }

    const metrics = [];
    metrics.push(setMetric({key: 'sp_followers', diffPostfix: intl.formatMessage({id: 'SUBSCRIBERS'})}));
    metrics.push(setMetric({key: 'sp_popularity', diffPostfix: intl.formatMessage({id: 'PERCENT'})}));
    metrics.push(setMetric({key: 'sp_monthly_listeners', diffPostfix: intl.formatMessage({id: 'LISTENERS'})}));
    metrics.push(setMetric({key: 'tiktok_likes', diffPostfix: intl.formatMessage({id: 'LIKES'})}));
    metrics.push(setMetric({key: 'tiktok_followers', diffPostfix: intl.formatMessage({id: 'SUBSCRIBERS'})}));
    metrics.push(setMetric({key: 'youtube_monthly_video_views', diffPostfix: intl.formatMessage({id: 'VIEWS'})}));
    metrics.push(setMetric({key: 'shazam_count'}));

    metrics.push(
      setMetric({
        key: 'num_sp_editorial_playlists',
        diffPostfix: intl.formatMessage({id: 'PLAYLISTS'}),
      }),
    );
    metrics.push(
      setMetric({
        key: 'num_am_editorial_playlists',
        diffPostfix: intl.formatMessage({id: 'PLAYLISTS'}),
      }),
    );
    metrics.push(
      setMetric({
        key: 'num_az_editorial_playlists',
        diffPostfix: intl.formatMessage({id: 'PLAYLISTS'}),
      }),
    );
    metrics.push(
      setMetric({
        key: 'num_de_editorial_playlists',
        diffPostfix: intl.formatMessage({id: 'PLAYLISTS'}),
      }),
    );
    return metrics;
  }, [period, diffMode, statistics]);

  return (
    <Card hideFooter className={{body: 'bg-light'}}>
      <CardTitle>{intl.formatMessage({id: 'ARTIST_RANKING'})}</CardTitle>
      <CardToolbar>
        <InputSelect
          value={diffMode}
          options={[
            {label: intl.formatMessage({id: 'PERCENT'}), value: 'diff_percent'},
            {label: intl.formatMessage({id: 'ABSOLUTE_VALUES'}), value: 'diff'},
          ]}
          className={'mr-3'}
          onChange={diff => setDiffMode(diff as any)}
        />
        <InputSelect
          value={period}
          options={periodSelectValues}
          onChange={period =>
            setPeriod((period as ChartMetricArtistRankingPeriod) ?? ChartMetricArtistRankingPeriod.WEEKLY)
          }
        />
      </CardToolbar>
      <CardBody>
        <div className={'row'}>
          {rankMetrics.concat(socialStreamingRanks).map((item, index) => (
            <div key={index} className={'col-md-4 col-xl-3'}>
              <SocialStreamingRank
                title={item.title}
                rank={item.value}
                diff={item.diff}
                diffPostfix={item.diffPostfix}
                diffStyle={diffMode === 'diff_percent' ? 'percent' : undefined}
              />
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};
