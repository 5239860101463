import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {EntityId} from '../../api/base/BaseEntity';
import {isValidationException} from '../../utils/api-utils';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {Dispatch, useCallback, useRef} from 'react';
import {ValidationErrorsType} from '../../utils/utils';
import {ReportPeriod} from '../../api/DTOs/ReportPeriod';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';

export type ReportsPeriodsPrivileges = {
  can_close: boolean;
  can_open: boolean;
};

export function useReportsPeriodsApi() {
  const {current: params} = useRef<EntityApiHookParams>({
    prefixUrl: 'report-periods',
    isAdmin: false,
  });
  const entityApi = useEntityApi<ReportPeriod, unknown, ReportsPeriodsPrivileges>(params);
  return {
    ...entityApi,
    ...useAdditionalRoutes(params, entityApi.setValidationErrors),
  };
}

function useAdditionalRoutes(params: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) {
  const api = useBaseEndpointApi();

  const open = useCallback(async (id: EntityId) => {
    try {
      return await api.post<BaseItemResponse<ReportPeriod>>(`${params.prefixUrl}/${id}/open`);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  }, []);

  const close = useCallback(async (id: EntityId) => {
    try {
      return await api.post<BaseItemResponse<ReportPeriod>>(`${params.prefixUrl}/${id}/close`);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  }, []);

  return {
    open,
    close,
  };
}
