import {useIntl} from '../../../../hooks/use-intl';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../../base-modal/CloseModalEvent';
import {PartialNullable} from '../../../../../types/types';
import {EXCEPTION_TYPE} from '../../../../api/exceptions/BaseException';
import {ValidationException} from '../../../../api/exceptions/ValidationException';
import {useAdvancedState} from '../../../../hooks/use-advanced-state';
import {ApiRequestException} from '../../../../api/axios-instance';
import {
  CreateTransactionToOurOrganizationFields,
  useTransactionsApi,
} from '../../../../hooks/apis/use-transactions-api';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {EntityId} from '../../../../api/base/BaseEntity';
import {ModalCreateToOurOrganizationTransaction} from './modal-create-to-our-organization-transaction';
import {Contract} from '../../../../api/contract-api/Contract';
import {useContractsApi} from '../../../../hooks/apis/use-contracts-api';
import {useLoading} from '../../../../hooks/use-loading';

export type PartOfContractorForCreateTransaction = Pick<Contractor, 'id' | 'calculated_name'>;

interface ContextProps {
  showCreateToOurOrganizationTransactionModal(
    partOfContractor?: PartOfContractorForCreateTransaction,
  ): Promise<CloseModalEvent<EntityId>>;
}

// @ts-ignore
const ModalCreateTransactionContext = React.createContext<ContextProps>();

let closeResolver: ((data: CloseModalEvent<EntityId>) => unknown) | null = null;
export const ModalCreateToOurOrganizationTransactionProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    contracts: true,
    accounts: false,
  });
  const api = useTransactionsApi();
  const contractsApi = useContractsApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [entity, , updateEntityFields, resetEntity] = useAdvancedState<
    PartialNullable<CreateTransactionToOurOrganizationFields>
  >({}, api.setValidationErrors);
  const [contracts, setContracts] = useState<Array<Contract> | null>(null);

  useEffect(() => {
    if (entity.contractor_id) {
      startLoading('contracts');
      contractsApi
        .getAll({filters: {contractor_id: entity.contractor_id}})
        .then(res => {
          updateEntityFields({contract_id: res.items[0].id});
          setContracts(res.items);
        })
        .finally(() => stopLoading('contracts'));
    } else {
      setContracts(null);
    }
  }, [entity.contractor_id]);

  const showModal = async (partOfContractor?: PartOfContractorForCreateTransaction) => {
    setVisible(true);
    if (partOfContractor) {
      updateEntityFields({contractor_id: partOfContractor.id});
    }

    return new Promise<CloseModalEvent<EntityId>>(resolve => {
      fetchSelectValuesByKey('contractors');
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    resetEntity();
    setContracts([]);
    api.setValidationErrors(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      api.setValidationErrors(null);
      const result = await api.createTransactionToOurOrganization(entity);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        api.setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const contextValue: ContextProps = {
    showCreateToOurOrganizationTransactionModal: showModal,
  };

  return (
    <ModalCreateTransactionContext.Provider value={contextValue}>
      {children}
      <ModalCreateToOurOrganizationTransaction
        visible={visible}
        loadings={{contracts: loadings.contracts, contractors: optionsLoadings.contractors}}
        error={error}
        contracts={contracts?.map(c => ({value: c.id, label: c.composite_number})) ?? null}
        validationErrors={api.validationErrors}
        contractors={selectsOptions.contractors}
        entity={entity}
        onChange={updateEntityFields}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalCreateTransactionContext.Provider>
  );
};

export const useModalCreateToOurOrganizationTransaction = (): ContextProps => {
  return useContext(ModalCreateTransactionContext);
};
