import {YoutubeVideosApi} from '../../../api/youtube-videos-api';
import {useIntl} from 'react-intl';
import {YoutubeVideoCard} from './yotube-video-card';
import {StringParam, withDefault} from 'use-query-params';
import {YoutubeVideosTable} from './youtube-videos-table';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import React, {useEffect, useMemo, useState} from 'react';
import {IYoutubeVideoDto} from '../../../api/DTOs/IYoutubeVideoDto';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import cn from 'classnames';
import {useSelectApi} from '../../../hooks/use-select-api';

interface IYoutubeVideosProps {
  forChannelId?: string;
}

const defaultSortOptions: ISortDto = {
  field: SortField.PUBLISHED_AT,
  direction: SortDirection.DESC,
};

export const YoutubeVideos = ({forChannelId}: IYoutubeVideosProps) => {
  const intl = useIntl();

  const [enabledCardMode, setEnableCardMode] = useState(true);

  const videoApi = new YoutubeVideosApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [videos, setVideos] = useState<Array<IYoutubeVideoDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.PUBLISHED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.PUBLISHED_AT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'LIKES'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.LIKE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'LIKES'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.LIKE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'DISLIKES'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.DISLIKE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'DISLIKES'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.DISLIKE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'COMMENTS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.COMMENT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'COMMENTS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.COMMENT, direction: SortDirection.DESC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      allSelectValues: selectsOptions,
      loadingSelect: optionsLoadings,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'title',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          type: FilterType.SELECT,
          name: 'channel_id',
          selectKey: 'youtubeChannels',
          visible: !forChannelId,
          placeholder: intl.formatMessage({id: 'CHANNELS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          className: 'mr-2',
          queryConfig: {
            name: 'status',
            type: withDefault(StringParam, 'ACTIVE'),
          },
          placeholder: intl.formatMessage({id: 'ACCESSIBILITY'}),
          options: [
            {value: 'ACTIVE', label: intl.formatMessage({id: 'ACTIVE_VIDEO'})},
            {
              value: 'INACTIVE',
              label: intl.formatMessage({id: 'INACTIVE_VIDEO'}),
            },
          ],
        },
      ],
    });
  }, [selectsOptions, optionsLoadings, forChannelId]);

  useEffect(() => {
    Promise.all([fetchSelectValuesByKey('youtubeChannels')]).then();
  }, []);

  const fetchVideos = async (queryParams?: IQueryParams) => {
    try {
      setLoading(true);

      if (forChannelId != null) {
        queryParams = {
          ...queryParams,
          filters: {
            ...queryParams?.filters,
            channel_id: forChannelId,
          },
        };
      }

      const response = await videoApi.getVideoList(queryParams);
      setVideos(response.data.items);
      setPaginationInfo(response.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchVideos}
      toolbarConfig={[
        {
          type: 'SWITCH',
          size: 'sm',
          titles: [intl.formatMessage({id: 'CARDS'}), intl.formatMessage({id: 'TABLE'})],
          checked: !enabledCardMode,
          onChange: () => setEnableCardMode(prevState => !prevState),
        },
      ]}
      className={{cardBody: cn({'bg-light': enabledCardMode})}}>
      {!enabledCardMode ? (
        <YoutubeVideosTable videos={videos} />
      ) : (
        <div className='row'>
          {videos.map(video => (
            <YoutubeVideoCard key={video.id} video={video} />
          ))}
        </div>
      )}
    </BaseListPage>
  );
};
