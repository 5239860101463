import React, {createContext, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {User} from '../../../api/DTOs/User';
import {EditUserFormFields, ModalEditUser} from './modal-edit-user';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {useUserApi} from '../../../hooks/apis/use-user-api';
import {isValidationException} from '../../../utils/api-utils';
import {useSelectApi} from "../../../hooks/use-select-api";

interface IModalEditUserContext {
  modalEditUserVisible: boolean;

  showEditUserModal(user: User): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditUserContext = createContext<IModalEditUserContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalEditUserProvider = ({children}: any) => {
  const intl = useIntl();

  const api = useUserApi();
  const {fetchSelectValuesByKey, selectsOptions, optionsLoadings} = useSelectApi();
  const [error, setError] = useState<string | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [fields, , updateFields, resetFields] = useAdvancedState<EditUserFormFields>({}, api.setValidationErrors);

  const showModal = async (user: User) => {
    setVisible(true);
    updateFields(user);
    fetchSelectValuesByKey('contractors').then();
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    resetFields();
    api.setValidationErrors(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (!fields.id) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('User ID must be specify');
      }
      setError(null);
      await api.update(fields.id, fields);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (!isValidationException(err)) {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditUserContext = {
    modalEditUserVisible: visible,
    showEditUserModal: showModal,
  };

  return (
    <ModalEditUserContext.Provider value={value}>
      {children}
      <ModalEditUser
        visible={visible}
        fields={fields}
        updateFields={updateFields}
        error={error}
        validationErrors={api.validationErrors}
        selectOptionsLoading={optionsLoadings}
        options={selectsOptions}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditUserContext.Provider>
  );
};

export const useModalEditUser = () => {
  return useContext(ModalEditUserContext);
};
