import {useCallback} from 'react';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseResponseWithData} from '../../api/base/base-crud-api-response';
import {Feed} from '../../api/DTOs/Feed';

export function usePartnerApi() {
  const api = useBaseEndpointApi();
  const getPartnerList = useCallback(async (): Promise<BaseResponseWithData<Record<Feed, string>>> => {
    return await api.get(`partner/list`);
  }, []);

  return {
    getPartnerList,
  };
}
