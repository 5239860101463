import React from 'react';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';
import {useAuth} from '../../../../../../hooks/use-auth';
import {InputCheckbox} from '../../../../../../components/Inputs/InputCheckbox';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: (props: { [P in keyof Contractor]?: Contractor[P] }) => void;
};

export const LegalFields: React.FC<Props> = ({validationErrors, updateFields, contractor}) => {
  const intl = useIntl();
  const {
    privileges: {can_set_is_our_for_contractor},
  } = useAuth();

  return (
    <>
      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('legal_name', validationErrors)}>
        <InputText
          required
          value={contractor?.legal_name}
          label={intl.formatMessage({id: 'LEGAL_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_name', validationErrors)}
          onChange={e => updateFields({legal_name: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LEGAL_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      {can_set_is_our_for_contractor && (
        <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('is_our', validationErrors)}>
          <InputCheckbox
            label={intl.formatMessage({id: 'IS_OUR_COMPANY'})}
            value={contractor.is_our ?? false}
            onChange={() => updateFields({is_our: !(contractor.is_our ?? false)})}
          />
        </ValidateErrorWrapper>
      )}

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('legal_ogrn', validationErrors)}>
        <InputText
          value={contractor?.legal_ogrn}
          label={intl.formatMessage({id: 'LEGAL_OGRN'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_ogrn', validationErrors)}
          onChange={e => updateFields({legal_ogrn: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LEGAL_OGRN'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('legal_inn', validationErrors)}>
        <InputText
          mask={'9999999999'}
          value={contractor?.legal_inn}
          label={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_inn', validationErrors)}
          onChange={e => updateFields({legal_inn: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('legal_kpp', validationErrors)}>
        <InputText
          mask={'999999999'}
          value={contractor?.legal_kpp}
          label={intl.formatMessage({id: 'TAX_PAYER_KPP'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_kpp', validationErrors)}
          onChange={e => updateFields({legal_kpp: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'TAX_PAYER_KPP'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <div style={{marginBottom: '10px'}}>
        <InputCheckbox
          label={intl.formatMessage({id: 'VAT_PAYER'})}
          value={contractor.vat != null}
          onChange={() => updateFields({
            vat_payer: !(contractor.vat_payer ?? false),
            vat: null,
          })}
        />
      </div>

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('vat', validationErrors)}>
        <InputText
          value={contractor?.vat != null ? (contractor?.vat * 100).toString() : null}
          type={'number'}
          inputMode={'numeric'}
          disabled={!contractor.vat_payer}
          step={1}
          minNumber={0}
          maxNumber={100}
          hasError={!!getValidationErrorMessage<Contractor>('vat', validationErrors)}
          onChange={e => {
            updateFields({vat: e.currentTarget.value / 100});
          }}
          placeholder={intl.formatMessage({id: 'VAT'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('legal_address', validationErrors)}>
        <InputText
          value={contractor?.legal_address}
          label={intl.formatMessage({id: 'LEGAL_ADDRESS'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_address', validationErrors)}
          onChange={e => updateFields({legal_address: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LEGAL_ADDRESS'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
    </>
  );
};
