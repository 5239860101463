import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {ModalPaymentRequestField} from './admin-payment-request-modal-context';
import {InputSelect} from '../../Inputs/InputSelect';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {PartialNullable} from '../../../../types/types';

interface IModalPaymentRequestProps {
  loadings: any;
  state: PartialNullable<ModalPaymentRequestField>;

  updateState: UpdateFields<ModalPaymentRequestField>;

  onHide(): void;

  onOkClick(): any;
}

export const ModalAdminPaymentRequest: React.FC<IModalPaymentRequestProps> = ({
  state,
  loadings,
  updateState,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSum = (e: any) => {
    updateState({value: e.currentTarget.value});
  };

  return (
    <>
      <BaseModal
        visible={state.visible ?? false}
        backdrop={'static'}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'MC_PAY_PAYMENT_REQUEST'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={state.error || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={state.error != null}
            />

            <div>
              <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', state.validationErrors)}>
                <InputSelect
                  value={state.contractor_id}
                  label={intl.formatMessage({id: 'CONTRACTORS'})}
                  placeholder={''}
                  options={state.contractors ?? []}
                  onChange={value => updateState({contractor_id: value})}
                />
              </ValidateErrorWrapper>
            </div>

            {state.contractor_id != null && (
              <p className={'font-weight-bolder mb-7'}>
                {loadings.balance ? (
                  <span className='ml-2 spinner spinner-secondary' />
                ) : (
                  <>
                    <FormattedMessage id={'BALANCE'} />: {CustomFormatter.formatMoney(state.balance?.balance)}
                    <br />
                    <FormattedMessage id={'UNCONFIRMED_BALANCE'} />:{' '}
                    {CustomFormatter.formatMoney(state.balance?.unconfirmedBalance)}
                    <br />
                    <FormattedMessage id={'WITHDRAWAL_AVAILABLE_BALANCE'} />:{' '}
                    {CustomFormatter.formatMoney(state.balance?.withdrawalAvailableBalance)}
                  </>
                )}
              </p>
            )}

            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('value', state.validationErrors)}>
                <InputText
                  required
                  value={state.value}
                  type={'number'}
                  label={intl.formatMessage({id: 'SUM'}) + ' ($)'}
                  hasError={!!getValidationErrorMessage('value', state.validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={handleChangeSum}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
