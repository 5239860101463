import React, {useCallback, useEffect, useState} from "react";
import {ContractorDocument, ContractorDocumentType} from "../../api/DTOs/ContractorDocument";
import {useLoading} from "../../hooks/use-loading";
import {EntityId} from "../../api/base/BaseEntity";
import {IPaginationInfo} from "../../api/Paginator";
import {FormattedMessage} from "react-intl";
import {Preloader} from "../preloader";
import {EmptyListState} from "../empty-list-state";
import {ContractorDocumentCard} from "../../pages/contractor-documents/contractor-document-card";
import {PaginationComponent} from "../pagination";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";
import {useContractorDocumentsApi} from "../../hooks/apis/use-contractor-documents-api";
import {useIntl} from "../../hooks/use-intl";

type Props = {
  contractor_id: EntityId;
  type: 'contractor'
} | {
  contract_id: EntityId;
  type: 'contract'
}

export const FinancialReportsDocuments: React.FC<Props> = (props) => {
  const intl = useIntl();
  const contractorDocumentsApi = useContractorDocumentsApi();
  const [contractorDocuments, setContractorDocuments] = useState<Array<ContractorDocument>>([]);
  const [currentPage, setCurrentPage] = useState<number | null>(0);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const [loadings, startLoading, stopLoading] = useLoading({
    documents: true,
  })

  useEffect(() => {
    if (currentPage != null) {
      fetchContractorDocuments().then();
    }
  }, [currentPage]);

  const handleDownloadDocument = async (documentId: EntityId) => {
    try {
      const res = await contractorDocumentsApi.downloadDocument(documentId);
      saveAs(res.content, res.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const fetchContractorDocuments = useCallback(async () => {
    try {
      startLoading('documents');
      const filters: any = {type: ContractorDocumentType.FINANCIAL_DETAILS_REPORT};
      if (props.type === 'contractor') {
        filters['contractor_id'] = props.contractor_id;
      }

      if (props.type === 'contract') {
        filters['contract_id'] = props.contract_id;
      }

      const res = await contractorDocumentsApi.getAll({
        filters,
        page: currentPage ?? 0,
      });
      setContractorDocuments(res.items);
      setPaginationInfo(res.paginator);
    } finally {
      stopLoading('documents');
    }
  }, [currentPage]);
  return <>
    <div className={'font-size-h4 font-weight-boldest mb-3'}>
      <FormattedMessage id={'FINANCIAL_REPORTS'}/>:
    </div>
    {loadings.documents ? (
      <Preloader/>
    ) : (
      <>
        {contractorDocuments?.length === 0 && <EmptyListState/>}
        {contractorDocuments?.map(doc => {
          return (
            <ContractorDocumentCard
              key={doc.id}
              item={doc}
              cardProps={{
                className: {
                  body: 'px-0 py-1',
                },
                style: {boxShadow: 'none'},
              }}
              onDownload={handleDownloadDocument}
            />
          );
        })}
        {paginationInfo !== null && (
          <PaginationComponent
            threshold={4}
            paginator={paginationInfo}
            aggregationValues={paginationInfo.aggregationValues}
            onChange={page => setCurrentPage(page)}
          />
        )}
      </>
    )}
  </>
}
