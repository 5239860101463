import React, {useMemo} from 'react';
import {FormattedNumber, useIntl} from 'react-intl';
import {FinancialAccount} from '../../../../api/DTOs/FinancialAccount';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';

interface FinancialAccountsTableProps {
  accounts: Array<FinancialAccount>;
}

export const FinancialAccountsTable = ({accounts}: FinancialAccountsTableProps) => {
  const intl = useIntl();
  const data = accounts;

  const columns = useMemo(() => {
    const defaultClassName = 'd-flex align-items-center w-100 h-100 font-weight-bolder justify-content-start';
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          return (
            <div className={'font-weight-bolder'}>
              <Link to={AdminRoutes.getSpecifyFinancialAccountsRoute(value.contractorId, value.id)}>{value.id}</Link>
            </div>
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 450,
        minWidth: 300,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          if (!value.contractorId) {
            return null;
          }

          return (
            <div className={'font-weight-bolder'}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractorId)}>{value.contractorName}</Link>
              <div className={'text-muted font-weight-normal'}>
                ({intl.formatMessage({id: `ACCOUNT_TYPE_${value.type}_${value.currency}`})})
              </div>
            </div>
          );
        },
      },
      {
        id: 'BALANCE',
        Header: intl.formatMessage({id: 'BALANCE'}),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          const balanceColor = value.balance < 0 ? 'text-danger' : value.balance > 0 ? 'text-success' : '';

          return (
            <div className={defaultClassName}>
              <p className={'m-0 p-0 ' + balanceColor}>
                <FormattedNumber value={value.balance} style={'currency'} currency={value.currency} />
              </p>
            </div>
          );
        },
      },
      {
        id: 'UnconfirmedBalance',
        Header: intl.formatMessage({id: 'UNCONFIRMED_BALANCE'}),
        maxWidth: 300,
        minWidth: 100,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          const balanceColor =
            value.waitingConfirmBalance < 0 ? 'text-danger' : value.waitingConfirmBalance > 0 ? 'text-success' : '';

          return (
            <div className={defaultClassName}>
              <p className={'m-0 p-0 ' + balanceColor}>
                <FormattedNumber value={value.waitingConfirmBalance} style={'currency'} currency={value.currency} />
              </p>
            </div>
          );
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          return (
            <div className={defaultClassName}>
              {intl.formatDate(value.created_at)} {intl.formatTime(value.created_at)}
            </div>
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: FinancialAccount) => br,
        Cell: ({value}: {value: FinancialAccount}) => {
          return (
            <div className={defaultClassName}>
              {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
