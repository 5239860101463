import React from 'react';
import {Route} from 'react-router-dom';
import {Content} from './Content';

interface ContentRouteProps {
  path: string;
  children?: React.ReactNode;
  component?: any;
  exact?: boolean;
  render?(args: any): any;

  isMatch?: (match: any) => boolean;
}

export function ContentRoute({children, component, exact, render, path, isMatch, ...props}: ContentRouteProps) {
  return (
    <Route exact={exact} {...props}>
      {routeProps => {
        if (typeof children === 'function') {
          return <Content>{children(routeProps)}</Content>;
        }

        if ((isMatch && !isMatch(routeProps.match)) || !routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return <Content>{React.createElement(component, routeProps as any)}</Content>;
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
