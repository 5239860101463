import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {VideoInContract} from '../../../api/contract-api/Contract';
import {
  EntityToEditableRowConverter,
  RowEntity,
} from './contract-page/create-edit-contract-page/edit-contract-block/row';
import {SelectOption} from '../../../components/Inputs/InputMultiSelect';
import {InputText} from '../../../components/Inputs/InputText';
import {useIntl} from '../../../hooks/use-intl';
import {useLocalPaginator} from '../../../hooks/use-local-paginator';
import {useSelectApi} from '../../../hooks/use-select-api';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {PaginationComponent} from '../../../components/pagination';
import {IPaginationInfo} from '../../../api/Paginator';
import {VideoTable} from './video-table';
import {DataSetValidationErrors} from '../../../utils/utils';
import cn from 'classnames';
import {useComponentDidMount} from '../../../hooks/use-component-did-mount';

type Props = {
  videos: VideoInContract[];
  onSave?: (items: RowEntity<VideoInContract>[]) => void;
  privileges?: {canCreate?: boolean; canDelete?: boolean; canEdit?: boolean};
  validationErrors?: DataSetValidationErrors<VideoInContract> | null;
  className?: string;
};
export const VideosBlock: React.FC<Props> = ({videos, privileges, validationErrors, className, onSave}) => {
  const intl = useIntl();
  const {fetchSelectValuesByKey, selectsOptions} = useSelectApi();

  const [initialValues, setInitialValues] = useState<RowEntity<VideoInContract>[]>(
    EntityToEditableRowConverter.convertAll(videos),
  );

  const [filter, setFilter] = useState<string | null>(null);
  const [selectCreateYoutubeChannel, setSelectCreateYoutubeChannel] = useState<SelectOption | null>(null);

  const filteredItems = useMemo<RowEntity<VideoInContract>[]>(() => {
    if (filter == null || filter === '') {
      return initialValues;
    }

    return initialValues.filter(item =>
      item.video_title.toLocaleLowerCase().includes(filter?.toLocaleLowerCase() ?? ''),
    );
  }, [initialValues, filter]);

  const availableSelectOptions = useMemo(() => {
    if (selectsOptions?.videos == null) {
      return null;
    }

    return selectsOptions?.videos?.filter(s => !initialValues.some(v => (v.video_id as any) == s.value));
  }, [initialValues, selectsOptions?.videos]);

  const {currentPageItems, currentPaginationInfo, getPageData, totalPagesCount} = useLocalPaginator(filteredItems, 10);

  useEffect(() => {
    if (selectsOptions?.videos == null) {
      fetchSelectValuesByKey('videos').then();
    }
  }, []);

  const isComponentDidMount = useComponentDidMount();
  // При изменении массива отправляем состояние в родительский компонент.
  useEffect(() => {
    if (onSave != null && isComponentDidMount) {
      onSave(initialValues.filter(c => !c.isDeleted));
    }
  }, [initialValues]);

  useEffect(() => {
    getPageData({page: 1}).then();
  }, [filter]);

  const handleAddChannel = useCallback(
    (selectOption: SelectOption) => {
      if (selectOption == null) {
        return;
      }

      setSelectCreateYoutubeChannel(null);
      setInitialValues(prev => [
        ...(prev ?? []),
        EntityToEditableRowConverter.convert(
          {
            video_id: selectOption.value,
            video_title: selectOption.label,
          },
          {isCreated: true},
        ),
      ]);
      getPageData({page: totalPagesCount + 1}).then();
    },
    [totalPagesCount],
  );

  const handleChangeSelectCreatedItem = useCallback(
    (value: number | string | null) => {
      if (value === null) {
        setSelectCreateYoutubeChannel(null);
        return;
      }

      const res = selectsOptions?.videos?.find(f => f.value === value) ?? null;
      setSelectCreateYoutubeChannel(res);
      if (res != null) {
        setTimeout(() => handleAddChannel(res), 0);
      }
    },
    [selectsOptions?.videos],
  );

  const handleSaveRow = useCallback(
    (item: RowEntity<VideoInContract>) => {
      const findIndex = initialValues.findIndex(c => c.video_id == item.video_id);
      if (findIndex == null || findIndex === -1) {
        return false;
      }

      if (item.isDeleted) {
        setInitialValues(prevState => [
          ...((prevState ?? []).slice(0, findIndex) ?? []),
          ...((prevState ?? []).slice(findIndex + 1) ?? []),
        ]);
      } else {
        setInitialValues(prevState => [
          ...(prevState ?? []).slice(0, findIndex),
          {
            ...item,
          },
          ...(prevState ?? []).slice(findIndex + 1),
        ]);
      }
      return true;
    },
    [initialValues],
  );

  return (
    <div className={cn(className)}>
      <InputText
        placeholder={intl.formatMessage({id: 'SEARCH'})}
        value={filter}
        classNames={'my-2 w-100'}
        onChange={e => setFilter(e.currentTarget.value)}
      />
      <VideoTable
        videos={currentPageItems}
        privileges={privileges}
        validationErrors={validationErrors}
        onSave={handleSaveRow}
      />
      {(privileges?.canCreate === true || privileges?.canCreate == null) && (
        <div className={'d-inline-flex w-100 my-2 align-items-center'}>
          <InputSelect
            className={'w-100 mr-2'}
            isLoading={selectsOptions?.videos == null}
            placeholder={intl.formatMessage({id: 'ADD'})}
            value={selectCreateYoutubeChannel?.value}
            options={availableSelectOptions ?? []}
            onChange={handleChangeSelectCreatedItem}
          />
        </div>
      )}

      {currentPaginationInfo != null && (
        <div className={'my-2'}>
          <PaginationComponent
            threshold={2}
            paginator={currentPaginationInfo as IPaginationInfo}
            onChange={page => getPageData({page})}
          />
        </div>
      )}
    </div>
  );
};
