import React, {useEffect, useMemo, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {YoutubeChannel} from '../../../api/DTOs/YoutubeChannel';
import {YoutubeChannelsApi} from '../../../api/youtube-channels-api';
import './youtube-channel.scss';
import {YoutubeVideos} from '../../youtube-video/videos-page/youtube-videos';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {Preloader} from '../../../components/preloader';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import {YoutubeMetricsPanelItem} from '../../../components/youtube-metrics-panel';
import {PreviewImage, YoutubeChannelOrVideoPreview} from '../../../components/YoutubeChannelOrVideoPreview';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {useSetYandexZenChannelIdModal} from './modals/modal-set-yandex-zen-channel-id-context';
import {ChannelRevenues} from './channel-revenues';
import {useAuth} from '../../../hooks/use-auth';
import {ICONS} from '../../../images/images';
import {useLinkChannelWithArtistModal} from './modals/modal-link-channel-with-artist';
import {ChartMetricArtistInfo} from '../../artists/chartmetric/chart-metric-artist-info';
import {Artist} from '../../../api/DTOs/Artist';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {SubscribeToArtistNewsletter} from '../../artists/chartmetric/subscribe-to-artist-newsletter';
import {Contract} from '../../../api/contract-api/Contract';
import {useIntl} from '../../../hooks/use-intl';
import {ContractCard} from './contract-card';

interface IMatchParams {
  channelId: string;
}

const SUBSCRIBER_SVG = require('../../../images/svg/Group.svg');
const VIDEO_LANE_SVG = require('../../../images/svg/Video-Lane.svg');
const VISIBLE_SVG = require('../../../images/svg/Visible.svg');

export const YoutubeChannelPage = () => {
  const intl = useIntl();
  const match = useRouteMatch<IMatchParams>();
  const channelId = match.params.channelId;
  const {
    isAdmin,
    privileges: {can_link_channel_with_artist},
  } = useAuth();
  const {showSetYandexZenChannelIdModal} = useSetYandexZenChannelIdModal();
  const {showLinkChannelWithArtistModal} = useLinkChannelWithArtistModal();

  const api = new YoutubeChannelsApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [channelInfo, setChannelInfo] = useState<YoutubeChannel>();
  const [artist, setArtist] = useState<Artist | null>(null);
  const [contract, setContract] = useState<Contract | null>(null);

  const [, , updateChartMetricData] = useAdvancedState<{
    title: string | null;
    artistCmId: string | number | null;
    loading?: boolean;
  }>({loading: true});

  useEffect(() => {
    api.getAvailableRevenueFeeds(channelId).then(res => api.getRevenueByMonth(channelId, res.data.items[0]));
  }, []);

  const {setBreadcrumb} = useCustomBreadcrumbs();
  const metrics = useMemo<YoutubeMetricsPanelItem[]>(() => {
    if (!channelInfo) {
      return [];
    }

    return [
      {
        title: 'VIEWS',
        value: channelInfo?.viewCount,
        icon: VISIBLE_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-success',
      },
      {
        title: 'SUBSCRIBERS',
        value: channelInfo.subscriberCount,
        icon: SUBSCRIBER_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-primary',
      },
      {
        title: 'VIDEOS',
        value: channelInfo.videoCount,
        icon: VIDEO_LANE_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-warning',
      },
    ];
  }, [channelInfo]);

  useEffect(() => {
    const fetchAllStats = async () => {
      await fetchChannelInfo();
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchAllStats();
  }, []);

  useEffect(() => {
    if (channelInfo?.title) {
      setBreadcrumb(channelInfo.title, 40);
    }
  }, [channelInfo?.title]);

  const fetchChannelInfo = async () => {
    try {
      setLoading(true);
      const result = await api.getChannelInfo(match.params.channelId);
      if (result?.data.relations?.artist) {
        setArtist(result.data.relations.artist);
      }

      if (result?.data?.relations?.contract) {
        setContract(result.data.relations.contract);
      }

      setChannelInfo(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const changeYandexZenChannelId = async (): Promise<any> => {
    if ((await showSetYandexZenChannelIdModal(channelId)).reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'YANDEX_ZEN_CHANNEL_ID_SUCCESS_UPDATED'}));
      await fetchChannelInfo();
    }
  };

  const handleLinkChannelWithArtist = async () => {
    if ((await showLinkChannelWithArtistModal(channelId, channelInfo?.artist_id)).reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_LINKED_WITH_ARTIST'}));
      await fetchChannelInfo();
    }
  };

  const renderPreview = () => {
    if (!channelInfo) {
      return null;
    }

    return (
      <YoutubeChannelOrVideoPreview
        title={channelInfo.title}
        redirectTo={Routes.getExternalYoutubeChannelRoute(channelInfo.id)}
        description={channelInfo.description}
        metrics={metrics}
        yandexZenChannelId={channelInfo.yandexZenId}
        toolBarConfig={[
          {
            type: 'BUTTON',
            visible: isAdmin,
            title: intl.formatMessage({id: 'SET_YANDEX_ZEN_CHANNEL_ID'}),
            icon: ICONS.YANDEX_ZEN,
            className: 'btn btn-sm btn-secondary text-uppercase font-weight-bolder',
            onClick: changeYandexZenChannelId,
          },
          {
            type: 'BUTTON',
            visible: can_link_channel_with_artist,
            title: intl.formatMessage({id: 'LINK_WITH_ARTIST'}),
            icon: ICONS.SINGER,
            className: 'btn btn-sm btn-secondary text-uppercase font-weight-bolder',
            onClick: handleLinkChannelWithArtist,
          },
          {
            type: 'CUSTOM',
            visible: artist?.chartmetric_id != null,
            render: () => {
              return artist && <SubscribeToArtistNewsletter artist={artist} />;
            },
          },
        ]}
        notice={
          channelInfo?.hiddenFromYoutubeAt &&
          intl.formatMessage(
            {id: 'CHANNEL_HIDDEN_FROM_YOUTUBE'},
            {
              redirect: str => (
                <Link to={Routes.getFinancialReportsRoute()} className={'text-danger text-underline'}>
                  {str}
                </Link>
              ),
            },
          )
        }>
        <PreviewImage>
          <div className='symbol symbol-md-100 symbol-lg-150 mr-7'>
            <img src={tryGetPreviewOrStub(channelInfo.thumbnails).url} alt='image' className={'w-100 rounded'} />
          </div>
        </PreviewImage>
      </YoutubeChannelOrVideoPreview>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      <div className={'row'}>
        <div className={'col-12'}>{renderPreview()}</div>
        {contract && (
          <div className={'col-12'}>
            <ContractCard contract={contract} />
          </div>
        )}

        <div className={'col-12'}>
          <ChannelRevenues youtubeChannelId={channelId} />
        </div>
        <div className={'col-12'}>
          {artist && artist.chartmetric_id && (
            <ChartMetricArtistInfo artist={artist} setChartMetricData={updateChartMetricData} />
          )}
        </div>
      </div>
      {<YoutubeVideos forChannelId={channelId} />}
    </>
  );
};
