import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {useReportsPeriodsApi} from '../../../hooks/apis/use-reports-periods-api';
import {EntityId} from '../../../api/base/BaseEntity';

export enum ReportPeriodAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export type HandleReportPeriodActionPayload = HandleReportPeriodCloseAction | HandleReportPeriodOpenAction;

export type HandleReportPeriodOpenAction = {
  type: ReportPeriodAction.OPEN;
  entityId: EntityId;
};

export type HandleReportPeriodCloseAction = {
  type: ReportPeriodAction.CLOSE;
  entityId: EntityId;
};

export function useReportPeriodActionHandler(fetch?: (params?: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useReportsPeriodsApi();
  const {showConfirmActionModal} = useModalConfirmAction();
  const handle = async (payload: HandleReportPeriodActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case ReportPeriodAction.OPEN: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_OPEN_REPORTING_PERIOD'}))) {
            await api.open(payload.entityId);
            await fetch?.();
          }
          break;
        }
        case ReportPeriodAction.CLOSE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_CLOSE_REPORTING_PERIOD'}))) {
            await api.close(payload.entityId);
            await fetch?.();
          }
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };
  return {
    handleReportPeriodActions: handle,
  };
}
