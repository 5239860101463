import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {SetContractorAction, SetPrivilegesAction, SetUserAction} from '../_redux/auth-redux';
import {IApplicationStore} from '../../../../redux/rootReducer';
import {User} from '../../../api/DTOs/User';
import {useLoading} from '../../../hooks/use-loading';
import {Contractor} from '../../../api/contractor-api/Contractor';
import {Link, useParams} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import './registration.scss';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {useIntl} from '../../../hooks/use-intl';
import {connect} from 'react-redux';
import {RegistrationForm, RegistrationFormFields} from './registration-form';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {useAuthApi} from '../../../hooks/apis/auth-api';
import {Privileges} from '../../../api/response-contracts/auth-response-contracts';

interface MapStateToProps {
  user: User | null;
}

interface MapDispatchToProps {
  intl: any;

  setUser(user: User): void;

  setContractor(contractor: Contractor): void;

  setPrivileges(privileges: Privileges): void;
}

const InviteRegistration = (props: MapStateToProps & MapDispatchToProps) => {
  const {token: inviteToken} = useParams<{token: string}>();
  const intl = useIntl();
  const authApi = useAuthApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    submitRegister: false,
  });

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>(null);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [formFields, , updateFields] = useAdvancedState<RegistrationFormFields>({}, setValidationErrors);

  useEffect(() => {
    if (getValidationErrorMessage('token', validationErrors) !== null) {
      setError(intl.formatMessage({id: 'INVITE_TOKEN_EXPIRED'}));
    }
  }, [validationErrors]);

  const handleSubmitButton = async () => {
    try {
      startLoading('submitRegister');
      const res = await authApi.registerOnInvite(inviteToken, formFields);
      props.setContractor(res.data.relations.contractor);
      props.setPrivileges(res.data.privileges);
      props.setUser(res.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submitRegister');
    }
  };

  return (
    <>
      <div className='login-form login-signin' style={{display: 'block'}}>
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1'>
            <FormattedMessage id='AUTH.REGISTER.TITLE' />
          </h3>
          <p className='text-muted font-weight-bold'>
            <FormattedMessage id='AUTH.REGISTER.DESC' />
          </p>
        </div>
        <RegistrationForm
          hideEmailInput
          hideContractorTypeInput
          error={error}
          validationErrors={validationErrors}
          fields={formFields}
          acceptTerms={acceptTerms}
          onAcceptTerms={setAcceptTerms}
          onChangeField={updateFields}
        />
        <div className='form-group d-flex flex-wrap flex-center'>
          <button
            type='submit'
            onClick={handleSubmitButton}
            disabled={loadings.submitRegister || !acceptTerms}
            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'>
            <span>
              <FormattedMessage id='BUTTONS.SUBMIT' />
            </span>
            {loadings.submitRegister && <span className='ml-3 spinner spinner-white' />}
          </button>

          <Link to={Routes.getLoginRoute()}>
            <button type='button' className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
              <FormattedMessage id='BUTTONS.CANCEL' />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({auth}: IApplicationStore) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = {
  setUser: SetUserAction,
  setContractor: SetContractorAction,
  setPrivileges: SetPrivilegesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteRegistration);
