import React from 'react';
import {NavLink} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {checkIsActive} from '../../../../_metronic/_helpers';
import {useLocation} from 'react-router';
import {FormattedMessage} from 'react-intl';
import {BaseAsidePageConfiguration} from '../../../../configs/pages-configuration';
import {ImageKey} from '../../../images/images';

const defaultPath = '/';

export interface MenuItemProps {
  title: string;
  exact?: boolean;
  icon?: ImageKey;
  disabled?: boolean;
  items: Array<BaseAsidePageConfiguration>;
}

const AsideMenuGroupItem: React.FC<MenuItemProps> = ({exact, title, icon, disabled, items}: MenuItemProps) => {
  const location = useLocation();
  const getClassForActiveItem = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url, exact)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const getClassForActiveGroup = () => {
    return items.some(i => checkIsActive(location, i.to, i.exact))
      ? `menu-item-active menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const handleClickLink = (e: React.SyntheticEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <>
      <li
        className={`menu-item menu-item-submenu ${getClassForActiveGroup()}`}
        aria-haspopup='true'
        data-menu-toggle='hover'>
        <NavLink className='menu-link menu-toggle' to={'#'}>
          {icon && icon.length > 0 && (
            <span className='svg-icon menu-icon'>
              <SVG src={icon} />
            </span>
          )}
          <span className='menu-text'>
            <FormattedMessage id={title} />
          </span>
          <i className='menu-arrow' />
        </NavLink>
        <div className='menu-submenu '>
          <ul className='menu-subnav'>
            {items.map(element => {
              return (
                <li
                  key={element.to}
                  aria-haspopup='true'
                  className={`menu-item menu-item-submenu ${getClassForActiveItem(element.to, true)}`}>
                  <NavLink onClick={handleClickLink} className='menu-link' to={element.to || defaultPath}>
                    {icon && icon.length > 0 && (
                      <span className='svg-icon menu-icon'>
                        <SVG src={element.icon} />
                      </span>
                    )}
                    <span className='menu-text'>
                      <FormattedMessage id={element.title} />
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    </>
  );
};

export default AsideMenuGroupItem;
