import React from 'react';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {getEnumValues, PartialNullable, UpdateEntity} from '../../../../../types/types';
import {AvailableContractChange} from '../../../../api/contract-api/Contract';
import {ValidateErrorWrapper} from '../../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {InputCheckbox} from '../../../../components/Inputs/InputCheckbox';
import {useIntl} from '../../../../hooks/use-intl';

type Props = {
  validationErrors: ValidationErrorsType<SupAgreement>;
  availableChanges: Array<AvailableContractChange>;
  supAgreement: PartialNullable<SupAgreement>;
  updateFields: UpdateEntity<PartialNullable<SupAgreement>>;
};

export const AvailableChangesCheckboxes: React.FC<Props> = ({
  supAgreement,
  availableChanges,
  validationErrors,
  updateFields,
}) => {
  const intl = useIntl();
  return (
    <>
      {getEnumValues(AvailableContractChange)
        .filter(v => availableChanges.includes(v as AvailableContractChange))
        .map(v => {
          return (
            <ValidateErrorWrapper key={v} message={getValidationErrorMessage(v as any, validationErrors)}>
              <InputCheckbox
                label={intl.formatMessage({id: 'SUP_AGREEMENTS_CHANGES_' + v})}
                value={(supAgreement[v as keyof SupAgreement] as boolean) ?? false}
                onChange={() => updateFields(prev => ({[v]: !prev[v as keyof SupAgreement]}))}
              />
            </ValidateErrorWrapper>
          );
        })}
    </>
  );
};
