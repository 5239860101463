import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {PartialNullable} from '../../../../types/types';
import {EntityId} from '../../../api/base/BaseEntity';
import {InputAsyncSelect} from '../../Inputs/InputAsyncSelect';
import {SelectOptions} from '../../Inputs/InputSelect';
import {ImageWithPreloader} from '../../image-with-preloader';

export type SetChartMetricState = {id: string | null | number; title: string; artistId: EntityId};

interface IModalProps {
  visible: boolean;
  errors?: string | null;
  defaultOptions?: SelectOptions;
  validationErrors?: ValidationErrorsType;
  fetchOptions: (inputValue: string, callback: (options: SelectOptions) => void) => Promise<SelectOptions> | void;
  entity: PartialNullable<SetChartMetricState>;
  onChange: UpdateFields<SetChartMetricState>;

  onHide(): void;

  onOkClick(): any;
}

export const ModalSetChartmetric: React.FC<IModalProps> = ({
  visible,
  errors,
  entity,
  defaultOptions,
  fetchOptions,
  onChange,
  validationErrors,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'SET_CHARTMETRIC_ID'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={errors || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={errors != undefined}
            />

            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('chartmetric_id', validationErrors)}>
                <InputAsyncSelect
                  isClearable
                  value={entity.id}
                  placeholder={intl.formatMessage({id: 'ARTIST'})}
                  loadOptions={fetchOptions}
                  defaultValue={entity.id}
                  defaultOptions={defaultOptions}
                  onChange={option => onChange({id: option})}
                  formatOptionLabel={(opt, metadata) => {
                    return (
                      <div className={'d-flex align-items-center'}>
                        {opt.icon && metadata.context !== 'value' && (
                          <ImageWithPreloader
                            containerHeight={50}
                            containerWidth={80}
                            imageProps={{width: 80, className: 'mr-2', src: opt.icon, alt: 'alt'}}
                          />
                        )}
                        <span>{opt.label}</span>
                      </div>
                    );
                  }}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
