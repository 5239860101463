import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../redux/rootReducer';
import {User, UserType} from './api/DTOs/User';
import {Redirect} from 'react-router-dom';
import {Routes} from '../configs/routes';
import {ContentRoute} from '../_metronic/layout/components/content/ContentRoute';

interface PrivateRouteProps {
  path: string;
  component?: any;
  render?: (args: any) => any;
  redirectTo?: string | null;
}

export const AdminRoute: React.FC<PrivateRouteProps> = ({component, path, render, ...props}) => {
  const user = useSelector<IApplicationStore, User | null>(({auth}) => auth.user, shallowEqual);

  const hasAccess = user === null || user.type === UserType.ADMIN;
  if (!hasAccess) {
    return <Redirect to={Routes.getNotFoundRoute()} />;
  }

  return <ContentRoute path={path} component={component} render={render} {...props} />;
};
