import React, {useMemo} from 'react';
import {useIntl} from '../../../hooks/use-intl';
import {McPayAuthState, McPayAuthStateStatus, McPayTransactionState} from '../../../hooks/apis/useMcPayStatesApi';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import cn from 'classnames';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {getEnumKeys} from '../../../../types/types';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {Transaction, TransactionStatus} from '../../../api/DTOs/Transaction';

type Props = {
  item: McPayAuthState;
  transaction: Transaction | null;
  transactionState: McPayTransactionState | null;
  className?: string;
};

type Step = {
  index: number;
  status: McPayAuthStateStatus;
  render?: (item: McPayAuthState) => JSX.Element;
};

export const McPayAuthStateStepsDescription: React.FC<Props> = ({item, transaction, transactionState, className}) => {
  const intl = useIntl();
  const currentStepIndex = useMemo<number>(() => {
    return getEnumKeys(McPayAuthStateStatus).findIndex(s => s === item.state);
  }, [item]);
  const stepsConfiguration = useMemo<Step[]>(() => {
    return [
      {
        index: 0,
        status: McPayAuthStateStatus.INIT,
        render: el => (
          <ul>
            <li>RequestUrl: {el.request_url}</li>
            <li>QueryAuthCode: {el.query_code}</li>
            <li>QueryAuthState: {el.query_state}</li>
          </ul>
        ),
      },
      {
        index: 1,
        status: McPayAuthStateStatus.PARSE_URL_STATE,
        render: () => <div>&nbsp;</div>,
      },
      {
        index: 2,
        status: McPayAuthStateStatus.FETCH_USER_FROM_DB,
        render: el => (
          <>
            {el?.user != null ? (
              <Link to={AdminRoutes.getSpecifyUserManagementRoute(el.user.id)}>
                {el.user.name_first} {el.user.name_last} {el.user.name_middle} ({el.user.email})
              </Link>
            ) : (
              'User Not Found'
            )}
          </>
        ),
      },
      {
        index: 3,
        status: McPayAuthStateStatus.VALIDATE_PASSPHRASE_STATE,
        render: el => (
          <ul>
            <li>QueryAuthState: {el.query_state}</li>
          </ul>
        ),
      },
      {
        index: 4,
        status: McPayAuthStateStatus.INIT_MC_PAY_USER_CREDENTIALS,
        render: el => (
          <ul>
            <li>QueryAuthCode: {el.query_code}</li>
          </ul>
        ),
      },
      {
        index: 5,
        status: McPayAuthStateStatus.AUTHORIZE_USER_ON_MC_PAY,
        render: el => (
          <ul>
            <li>Access Token: {el.mc_pay_access_token}</li>
          </ul>
        ),
      },
      {
        index: 6,
        status: McPayAuthStateStatus.FETCH_MC_PAY_USER_INFORMATION,
        render: el => (
          <ul>
            <li>MC Pay ID: {el.mc_pay_id}</li>
            <li>MC Pay Email: {el.mc_pay_email}</li>
            <li>MC Pay Wallet ID: {el.mc_pay_wallet_id}</li>
          </ul>
        ),
      },
      {
        index: 8,
        status: McPayAuthStateStatus.SUCCESS,
      },
    ];
  }, [item, transactionState]);
  return (
    <>
      <div className={cn('timeline timeline-5', className)}>
        <div className={'timeline-items'}>
          {stepsConfiguration?.map(step => {
            const isCurrentActive = step.index === currentStepIndex;
            const isCompleted = step.index < currentStepIndex;
            const isUncompleted = step.index > currentStepIndex;
            const isFaulted =
              (isCurrentActive && item.error_message != null) ||
              (step.status === McPayAuthStateStatus.CREATE_INIT_BALANCE_TRANSACTION &&
                transaction != null &&
                transaction.status === TransactionStatus.REJECTED) ||
              (step.status === McPayAuthStateStatus.CREATE_INIT_BALANCE_TRANSACTION && transaction == null);

            let icon;
            let color;
            if (isCurrentActive) {
              icon = ICONS.ARROW;
              color = BootstrapColors.PRIMARY;
            }

            if (isCompleted) {
              icon = ICONS.CHECK;
              color = BootstrapColors.SUCCESS;
            }

            if (isFaulted) {
              icon = ICONS.CROSS;
              color = BootstrapColors.DANGER;
            }

            if (step.status === McPayAuthStateStatus.SUCCESS) {
              icon = ICONS.FLAG;
            }
            return (
              <div key={step.index} className='timeline-item'>
                <div
                  className={cn('timeline-media', {
                    'bg-light-success': isCompleted,
                    'bg-light-secondary': isUncompleted,
                    'bg-light-primary': isCurrentActive,
                    'bg-light-danger': isFaulted,
                  })}>
                  <Svg src={icon} color={color} />
                </div>
                <div className='timeline-desc'>
                  <div className={'d-flex align-items-center justify-content-between'}>
                    <span className='mr-3 font-weight-bolder'>
                      {intl.formatMessage({id: 'MC_PAY_AUTH_STATE_' + step.status})}
                    </span>
                  </div>
                  {step.render != null && <div className={'text-muted font-size-sm mt-1'}>{step.render(item)}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
