import React, {SyntheticEvent} from 'react';
import {Col, Form, Row} from 'react-bootstrap';

interface RadioItem<T extends string> {
  label: string;
  value: T;
}

interface Props<T extends string> {
  selected: string | null;
  items: Array<RadioItem<T>>;
  label?: string;
  required?: boolean;

  onChange(value: string): void;
}

export function InputGroupRadio<T extends string>({items, selected, label, required, onChange}: Props<T>) {
  const renderLabel = (label: string, id: string) => {
    return (
      <label onClick={() => handleClickLabel(id)} className={'font-weight-bolder'}>
        {label}
      </label>
    );
  };

  const handleClickLabel = (id: string) => {
    onChange(id);
  };

  const handleChange = (e: SyntheticEvent) => {
    onChange(e.currentTarget.id);
  };

  return (
    <Form.Group as={Row} className={'mb-0'}>
      <Col sm={10}>
        {label && (
          <Form.Label column className={'font-weight-bolder ml-0 pl-0'}>
            {label} {required && <span className={'text-danger'}>*</span>}
          </Form.Label>
        )}
        {items.map(radio => {
          return (
            <Form.Check
              key={radio.value}
              type='radio'
              className={'radio-success'}
              checked={selected === radio.value}
              onChange={handleChange}
              label={renderLabel(radio.label, radio.value)}
              name='formHorizontalRadios'
              id={radio.value}
            />
          );
        })}
      </Col>
    </Form.Group>
  );
}
