import React from 'react';
import SVG from 'react-inlinesvg';
import {FormattedMessage} from 'react-intl';
import './widget.scss';
import {WidgetBase} from './widget-base';

interface IWidgetProps {
  title: string;
  isSuccess: boolean;
  className?: string;

  onClick?: () => void;
}

const ICONS = {
  CHECK: require('../../images/svg/Check.svg'),
  CROSS: require('../../images/svg/Close.svg'),
};

export const WidgetBoolean: React.FC<IWidgetProps> = ({title, isSuccess, className, onClick}) => {
  const style = isSuccess ? 'success' : 'danger';
  const icon = isSuccess ? ICONS.CHECK : ICONS.CROSS;

  return (
    <>
      <WidgetBase className={className} onClick={onClick}>
        <div className={'card-body'}>
          <span className={`svg-icon svg-icon-3x svg-icon-${style} ml-n2`}>
            <SVG src={icon} />
          </span>
          <div className={`text-${style} font-weight-bolder font-size-h3 mt-3`}>
            <FormattedMessage id={title} />
          </div>
        </div>
      </WidgetBase>
    </>
  );
};
