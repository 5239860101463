import React, {Dispatch} from 'react';
import {LocalizationKey, useIntl} from '../../hooks/use-intl';
import {Row} from '../../pages/admin/contracts/contract-page/create-edit-contract-page/edit-contract-block/row';
import {UpdateFields} from '../../hooks/use-advanced-state';
import {PartialNullable, Renderer} from '../../../types/types';
import cn from 'classnames';
import {EmptyListState} from '../empty-list-state';

export type ColumnDefinition<T> = {
  id: string;
  headerDefinition: TableHeaderDefinition;
  visible?: boolean;
  className?: string | ((rowState: T) => string);
  style?: React.CSSProperties;
  value: (
    rowState: T,
    updateState: UpdateFields<T>,
    setState: Dispatch<PartialNullable<T>>,
    rowIndex: number,
  ) => Renderer;
};

export type TableHeaderDefinition = {
  title: string | LocalizationKey | null;
  width?: string;
  style?: React.CSSProperties;
  className?: string;
};

type Props<T> = {
  entityKey: keyof T;
  items: T[];
  rowClassName?: (rowState: T, index: number) => string;
  columns: ColumnDefinition<T>[];
};

export function EntityTable<T>(props: Props<T>) {
  const intl = useIntl();
  return (
    <div className={'border'}>
      <table className={'table m-0 table-middle table-bordered rounded'}>
        <thead>
          <tr>
            {props.columns
              .filter(({visible}) => visible == null || visible)
              .map(({headerDefinition}, index) => {
                return (
                  <th
                    key={'table-header-' + index}
                    className={cn(headerDefinition.className)}
                    style={{...headerDefinition.style, width: headerDefinition.width}}>
                    {headerDefinition.title != null && headerDefinition.title.length != 0
                      ? intl.formatMessage({id: headerDefinition.title})
                      : ''}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, index) => {
            return (
              <Row
                index={index}
                key={item[props.entityKey] as any}
                className={props.rowClassName}
                item={item}
                columnDefinitions={props.columns}
              />
            );
          })}
        </tbody>
      </table>
      {props.items.length === 0 && <EmptyListState className={'my-5'} />}
    </div>
  );
}
