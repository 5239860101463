import React from 'react';

export const STRING = {
  EMPTY: '',
  RIGHT_ARROW: '→',
};

export const CHAR: Record<string, string> = {
  USD: '$',
  RUB: '₽',
};

export const ARRAY = {
  EMPTY: [] as any[],
};

export const HTML = {
  CLOSE_SYMBOL: <>&times;</>,
};

export const CALLBACK = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  EMPTY: (() => {}) as any,
};
