import React from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage} from 'react-intl';
import {useFitIframe} from '../../../hooks/use-fit-iframe';

interface IModalPlayVideoProps {
  visible: boolean;
  htmlContent: string | null;

  onHide(): void;
}

export const ModalPreviewHtmlContent: React.FC<IModalPlayVideoProps> = ({visible, htmlContent, onHide}) => {
  const [ref, handleFrameLoaded, frameContentHeight] = useFitIframe();

  return (
    <>
      <BaseModal dialogClassName={'modal-w90'} visible={visible} onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'PREVIEW'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <div className={'d-flex'} style={{height: frameContentHeight}}>
            <iframe
              ref={ref}
              onLoad={handleFrameLoaded as any}
              frameBorder={'none'}
              width={'100%'}
              srcDoc={htmlContent || ''}
            />
          </div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
