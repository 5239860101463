import {User} from '../../api/DTOs/User';
import React, {useCallback, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {ValidationErrorsType} from '../../utils/utils';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {PartialNullable} from '../../../types/types';
import {Contractor} from '../../api/contractor-api/Contractor';
import {BaseItemResponse, BaseResponse} from '../../api/base/base-crud-api-response';
import {ApiRequestException} from '../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {ValidationException} from '../../api/exceptions/ValidationException';
import {IUsersActionsResponse, UserMcPayOAuthData} from '../../api/user-api/users-response-contracts';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {IResponseBase} from '../../api/response-contracts/base-response';
import {EntityId} from '../../api/base/BaseEntity';
import {isValidationException} from '../../utils/api-utils';
import {InviteUserRequest} from '../../components/modals/invite-user/create-edit-invite-user-request-modal-context';

export type UserRelations = {
  contractor: Contractor | null;
};

export type UserPrivileges = {
  can_block: boolean;
  can_delete: boolean;
  can_reset_password: boolean;
  can_unblock: boolean;
  can_view_detailed_report: boolean;
  can_update: boolean;
};

export function useUserApi() {
  const {current: config} = useRef({
    prefixUrl: 'users',
    isAdmin: false,
  });
  const entityApi = useEntityApi<User, UserRelations, UserPrivileges>(config);
  const additionalRoutes = useAdditionalRoutes(config, entityApi.setValidationErrors);
  return {...entityApi, ...additionalRoutes};
}

function useAdditionalRoutes(params: EntityApiHookParams, setValidationErrors: React.Dispatch<ValidationErrorsType>) {
  const api = useBaseEndpointApi();

  const updateSelfFields = useCallback(async (user: PartialNullable<User>) => {
    try {
      return await api.post<BaseResponse>(`user/update`, {
        fields: user,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      }
      throw e;
    }
  }, []);

  const getMcPayOAuthData = useCallback(async () => {
    return await api.get<UserMcPayOAuthData>('user/mc-pay-oauth-data');
  }, []);

  const updateContractorFields = useCallback(async (contractor: PartialNullable<Contractor>) => {
    try {
      return await api.post<BaseItemResponse<Contractor>>(`user/update-current-contractor-requisites`, {
        fields: contractor,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException).error_data.messages);
      }
      throw e;
    }
  }, []);

  const getUsersActions = async (queryParams?: IQueryParams): Promise<IUsersActionsResponse> => {
    return await api.get<IUsersActionsResponse>(`users-actions?${api.applyFilters(queryParams)}`);
  };

  const block = async (id: EntityId): Promise<IResponseBase> => {
    return await api.post<IUsersActionsResponse>(`${params.prefixUrl}/${id}/block`);
  };

  const unblock = async (id: EntityId): Promise<IResponseBase> => {
    return await api.post<IUsersActionsResponse>(`${params.prefixUrl}/${id}/unblock`);
  };

  const resetPassword = async (id: EntityId): Promise<IResponseBase> => {
    return await api.post<IUsersActionsResponse>(`${params.prefixUrl}/${id}/reset_password`);
  };

  const sendInviteToEmail = async (payload: PartialNullable<InviteUserRequest>): Promise<IResponseBase> => {
    try {
      return await api.post<IUsersActionsResponse>(`invite`, {
        fields: payload,
      });
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors((e.innerException as ValidationException).error_data.messages);
      }
      throw e;
    }
  };

  return {
    updateContractorFields,
    updateSelfFields,
    getUsersActions,
    getMcPayOAuthData,
    block,
    unblock,
    resetPassword,
    sendInviteToEmail,
  };
}
