import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  Contractor,
  ContractorDataValidationStatus,
  ContractorType,
  UPDATE_CONTRACTOR_COLOR_MAP,
} from '../../../../api/contractor-api/Contractor';
import {FinancialAccount} from '../../../../api/DTOs/FinancialAccount';
import {Contract} from '../../../../api/contract-api/Contract';
import {FinancialAccountsTable} from '../../financial-accounts/financial-accounts/financial-accounts-table';
import {ContractsTable} from '../../contracts/contracts-page/contracts-table';
import {FormattedMessage} from 'react-intl';
import {Preloader} from '../../../../components/preloader';
import SVG from 'react-inlinesvg';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {AdminRoutes} from '../../../../../configs/routes';
import {useContractorsApi} from '../../../../hooks/apis/use-contractors-api';
import {LegalInfo} from './legal-info';
import {useIntl} from '../../../../hooks/use-intl';
import {PassportInfo} from './passport-info';
import {BankInfo} from './bank-info';
import {ICONS} from '../../../../images/images';
import {DataValidationToolBar} from '../../../my-organization/data-validation-tool-bar';
import {EntrepreneurInfo} from './entrepreneur-info';
import {ContractorInfoBlock} from './contractor-info-block';
import {useLoading} from '../../../../hooks/use-loading';
import {Svg} from '../../../../images/svg';
import {FinancialReportsDocuments} from '../../../../components/documents/financial-reports-documents';
import {User} from '../../../../api/DTOs/User';
import {UsersTable} from '../../user-management/users-table';

export const ContractorPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const history = useHistory();
  const api = useContractorsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    info: true,
    documents: true,
  });
  const [error, setError] = useState<string | null>(null);

  const [contracts, setContracts] = useState<Array<Contract>>([]);
  const [accounts, setAccounts] = useState<Array<FinancialAccount>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [ownerUser, setOwnerUser] = useState<User | null>(null);
  const [contractorInfo, setContractorInfo] = useState<Contractor | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    Promise.all([fetchContractorInfo()]).then();
  }, []);

  useEffect(() => {
    if (contractorInfo?.calculated_name) {
      setBreadcrumb(contractorInfo.calculated_name);
    }
  }, [contractorInfo?.calculated_name]);

  const fetchContractorInfo = async () => {
    try {
      startLoading('info');
      const result = await api.get(match.params.id);
      setUsers(result.relations.users);
      setOwnerUser(result.relations.ownerUser);
      setAccounts(result.relations.accounts);
      setContracts(result.relations.contracts);
      setContractorInfo(result.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('info');
    }
  };

  const handleEditContractorClick = async () => {
    history.push(AdminRoutes.getEditContractorRoute(contractorInfo?.id || ''));
  };

  const handleCreateContractClick = useCallback(() => {
    history.push(AdminRoutes.getCreateContractRoute({contractorId: contractorInfo?.id}));
  }, [contractorInfo?.id]);

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (loadings.info) {
    return <Preloader />;
  }

  const renderWithLoadingOrEmpty = (text: string | null | undefined) => {
    if (loadings.info) {
      return <Preloader />;
    }

    if (!text) {
      return 'N/A';
    }

    return text;
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div>
              <div className={'font-size-h4 font-weight-boldest'}>
                <p className={'mb-0'}>{renderWithLoadingOrEmpty(contractorInfo?.calculated_name)}</p>
                <p className={'mb-0 font-size-h6 font-weight-bolder'}>
                  {intl.formatMessage({id: 'CODE'})}: {contractorInfo?.code}
                </p>
              </div>
            </div>
            <div className={'d-flex align-items-center'}>
              <button
                onClick={handleEditContractorClick}
                className='btn font-weight-bolder text-uppercase btn-secondary'>
                <span className={`svg-icon svg-icon-secondary svg-icon-2x mr-2`}>
                  <SVG src={ICONS.EDIT} />
                </span>
                <FormattedMessage id={'EDIT'} />
              </button>
            </div>
          </div>

          {contractorInfo?.type === ContractorType.LEGAL && (
            <ContractorInfoBlock
              className={{content: 'mt-5', container: 'my-5'}}
              title={intl.formatMessage({id: 'INFO_ABOUT_LEGAL'})}>
              <LegalInfo contractor={contractorInfo || {}} />
            </ContractorInfoBlock>
          )}

          {contractorInfo?.type === ContractorType.ENTREPRENEUR && (
            <ContractorInfoBlock
              className={{content: 'mt-5', container: 'my-5'}}
              title={intl.formatMessage({id: 'INFO_ABOUT_ENTREPRENEUR'})}>
              <EntrepreneurInfo contractor={contractorInfo || {}} />
            </ContractorInfoBlock>
          )}

          {contractorInfo?.type === ContractorType.INDIVIDUAL && (
            <ContractorInfoBlock
              className={{content: 'mt-5', container: 'my-5'}}
              title={intl.formatMessage({id: 'INFO_ABOUT_INDIVIDUAL'})}>
              <PassportInfo contractor={contractorInfo || {}} />
            </ContractorInfoBlock>
          )}

          {contractorInfo && (
            <ContractorInfoBlock
              className={{content: 'mt-5', container: 'my-5'}}
              title={intl.formatMessage({id: 'INFO_ABOUT_BANK'})}>
              <BankInfo contractor={contractorInfo || {}} />
            </ContractorInfoBlock>
          )}

          <div className={'separator separator-solid mt-7 mb-5'} />
          <div className={'font-size-h4 font-weight-boldest'}>
            <FormattedMessage id={'CONTRACTOR_DATA_VALIDATION_STATUS'} />:{' '}
            <span
              className={`text-${
                UPDATE_CONTRACTOR_COLOR_MAP[contractorInfo?.data_validation_status as ContractorDataValidationStatus]
              }`}>
              {intl.formatMessage({id: 'UPDATE_CONTRACTOR' + contractorInfo?.data_validation_status})}
            </span>
          </div>
          {contractorInfo?.data_validation_status === ContractorDataValidationStatus.REJECTED && (
            <p className={'text-danger font-weight-bolder save-line-and-tabulation'}>
              <FormattedMessage id={'REJECT_REASON'} />:<br />
              {contractorInfo?.data_validation_reject_reason}
            </p>
          )}

          {contractorInfo?.data_validation_status === ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS && (
            <p className={'text-warning font-weight-bolder save-line-and-tabulation'}>
              <FormattedMessage id={'REQUIREMENTS'} />:<br />
              {contractorInfo?.data_validation_requirements}
            </p>
          )}

          {contractorInfo?.data_validation_status === ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM && (
            <DataValidationToolBar className={'mt-5'} fetch={fetchContractorInfo} contractorId={match.params.id} />
          )}

          <div className={'separator separator-solid my-7'} />

          {users && (
            <>
              <div className={'font-size-h4 font-weight-boldest mb-1'}>
                <FormattedMessage id={'USERS'} />:
              </div>
              <UsersTable users={users} ownerUser={ownerUser} privileges={null} relations={null} />
            </>
          )}

          <div className={'separator separator-solid my-7'} />

          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'CONTRACTS'} />:
          </div>
          <button
            onClick={handleCreateContractClick}
            className='btn btn-sm my-2 font-weight-bolder text-uppercase btn-light-success'>
            <Svg src={ICONS.PLUS} className={'mr-2'} />
            <FormattedMessage id={'ADD_CONTRACT'} />
          </button>
          {loadings.info ? <Preloader /> : <ContractsTable contracts={contracts} />}

          <div className={'separator separator-solid my-7'} />

          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'ACCOUNTS'} />:
          </div>
          {loadings.info ? <Preloader /> : <FinancialAccountsTable accounts={accounts} />}

          <div className={'separator separator-solid my-7'} />
          <FinancialReportsDocuments contractor_id={match.params.id} type={'contractor'} />
        </div>
      </div>
    </>
  );
};
