import React, {useCallback, useState} from 'react';
import {BaseListPage} from '../../base/base-list-page';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {useLoading} from '../../../hooks/use-loading';
import {ReportsPeriodsPrivileges, useReportsPeriodsApi} from '../../../hooks/apis/use-reports-periods-api';
import {ReportPeriod, ReportPeriodStatus} from '../../../api/DTOs/ReportPeriod';
import {ReportingPeriodsTable} from './reporting-periods-table';
import {ManyPrivileges} from '../../../api/base/base-crud-api-response';
import {ReportPeriodAction, useReportPeriodActionHandler} from './use-reporting-periods-action-handler';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

export const REPORT_PERIODS_STATUS_COLOR_MAP: Record<ReportPeriodStatus, BootstrapColors> = {
  [ReportPeriodStatus.OPEN]: BootstrapColors.SUCCESS,
  [ReportPeriodStatus.OPENING]: BootstrapColors.PRIMARY,
  [ReportPeriodStatus.CLOSING]: BootstrapColors.PRIMARY,
  [ReportPeriodStatus.CLOSED]: BootstrapColors.DANGER,
};

export const ReportingPeriodsPage: React.FC = () => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    periods: true,
  });
  const api = useReportsPeriodsApi();
  const [periods, setPeriods] = useState<Array<ReportPeriod>>([]);
  const [privileges, setPrivileges] = useState<ManyPrivileges<ReportsPeriodsPrivileges>>({});
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const fetchPeriods = async (params?: IQueryParams) => {
    try {
      startLoading('periods');
      const result = await api.getAll(params);
      setPrivileges(result.privileges);
      setPeriods(result.items);
      setPaginationInfo(result.paginator);
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('periods');
    }
  };

  const {handleReportPeriodActions} = useReportPeriodActionHandler(fetchPeriods);

  const handleTableActions = useCallback(async (period: ReportPeriod, action: ReportPeriodAction) => {
    await handleReportPeriodActions({type: action, entityId: period.id});
  }, []);

  return (
    <>
      <BaseListPage
        pageTitle={intl.formatMessage({id: 'REPORTING_PERIODS'})}
        loading={loadings.periods}
        pagination={{info: paginationInfo}}
        fetchData={fetchPeriods}>
        <ReportingPeriodsTable privileges={privileges} data={periods} onAction={handleTableActions} />
      </BaseListPage>
    </>
  );
};
