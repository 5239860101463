import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ChartMetricArtistQueryParams,
  ChartMetricPlaylist,
  ChartMetricPlaylistTrack,
  PlaylistPlatform,
  useArtistsApi,
} from '../../../hooks/apis/use-artists-api';
import {EntityId} from '../../../api/base/BaseEntity';
import {Card, CardBody, CardTitle, CardToolbar} from '../../../components/card';
import {capitalizeFirstLetter} from '../../../utils/utils';
import {Table} from '../../../components/table';
import {ImageWithPreloader} from '../../../components/image-with-preloader';
import {useIntl} from '../../../hooks/use-intl';
import {TableUtils} from '../../../components/table-utils';
import {FormattedNumber} from 'react-intl';
import {useLoading} from '../../../hooks/use-loading';
import {toast} from 'react-toastify';
import {Preloader} from '../../../components/preloader';
import cn from 'classnames';
import {useToggle} from '../../../hooks/use-toggle';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ICONS} from '../../../images/images';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {ChartMetricDomain} from '../../../api/DTOs/Chartmetric/CMArtistMetadata';

type Props = {
  artistId: EntityId;
  platform: PlaylistPlatform;
};

export const ChartMetricPlatformColorMap: Record<PlaylistPlatform, [BootstrapColors, string | null, string]> = {
  [PlaylistPlatform.SPOTIFY]: [BootstrapColors.SUCCESS, PlaylistPlatform.SPOTIFY, 'Spotify'],
  [PlaylistPlatform.APPLE]: [BootstrapColors.DANGER, ChartMetricDomain.ITUNES, 'Apple Music'],
  [PlaylistPlatform.YOUTUBE]: [BootstrapColors.DANGER, PlaylistPlatform.YOUTUBE, 'YouTube'],
};

export const Playlists: React.FC<Props> = ({artistId, platform}) => {
  const [loadings, startLoading, stopLoading] = useLoading({
    fetch: true,
  });
  const api = useArtistsApi();
  const intl = useIntl();
  const [isOpen, toggleOpen] = useToggle(false);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [playlists, setPlaylists] = useState<Array<{playlist: ChartMetricPlaylist; track: ChartMetricPlaylistTrack}>>(
    [],
  );

  useEffect(() => {
    if (isOpen) {
      fetchPlaylists({platform, offset: 0, limit: 25, status: 'current'}).then();
    } else {
      setPlaylists([]);
    }
  }, [isOpen]);

  const fetchPlaylists = useCallback(async (params: Partial<ChartMetricArtistQueryParams>) => {
    try {
      startLoading('fetch');
      const res = await api.getArtistPlaylists(artistId, {
        platform: platform,
        ...params,
      } as any);

      if (res.items.length < (params?.limit ?? 0)) {
        setCanFetchMore(false);
      }
      setPlaylists(prevState => [...prevState, ...res.items]);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('fetch');
    }
  }, []);

  const fetchMore = useCallback(async () => {
    fetchPlaylists({status: 'current', limit: 25, offset: playlists.length}).then();
  }, [playlists]);

  const platformStyle = useMemo(() => {
    return ChartMetricPlatformColorMap[platform];
  }, [platform]);

  const renderDynamic = useCallback((value: number | null | undefined) => {
    if (value == null) {
      return intl.formatMessage({id: 'NOT_APPLICABLE'});
    }
    return (
      <div className={'ml-2 font-size-sm'}>
        (
        <span
          className={cn({
            'text-success': value > 0,
            'text-danger': value < 0,
          })}>
          <FormattedNumber value={value} notation={'compact'} />
        </span>
        <i
          className={cn(`ml-1 fa fa-caret-${value > 0 ? 'up' : 'down'}`, {
            'text-success': value > 0,
            'text-danger': value < 0,
          })}
        />
        )
      </div>
    );
  }, []);

  return (
    <>
      <Card hideFooter hideBody={!isOpen}>
        <CardTitle>
          <i className={cn('mr-2', 'fab fa-lg', `fa-${platformStyle[1]}`, {[`text-${platformStyle[0]}`]: true})} />
          {intl.formatMessage({id: 'PLAYLISTS'})}: {capitalizeFirstLetter(platformStyle[2])}
        </CardTitle>
        <CardToolbar>
          <Toolbar
            items={[
              {
                visible: !isOpen,
                title: intl.formatMessage({id: 'SHOW'}),
                type: 'BUTTON',
                className: 'btn btn-light-primary btn-sm text-uppercase font-weight-bolder',
                onClick: toggleOpen,
              },
              {
                visible: isOpen && !loadings.fetch,
                title: intl.formatMessage({id: 'HIDE'}),
                type: 'BUTTON',
                className: 'btn btn-secondary btn-sm text-uppercase font-weight-bolder',
                onClick: toggleOpen,
              },
            ]}
          />
        </CardToolbar>
        {isOpen && (
          <CardBody>
            {loadings.fetch && playlists.length === 0 ? (
              <Preloader />
            ) : (
              <Table
                emptyText={intl.formatMessage({id: 'NOT_FOUND_PLAYLISTS_INFO'})}
                columns={[
                  {
                    id: 'image',
                    Header: intl.formatMessage({id: 'PLAYLISTS'}),
                    forceApplySize: true,
                    maxWidth: 100,
                    Cell: ({value}) => {
                      return (
                        <ImageWithPreloader
                          containerHeight={35}
                          imageProps={{
                            src: value.playlist.image_url ?? '',
                            className: 'w-100 rounded',
                          }}
                        />
                      );
                    },
                  },
                  {
                    id: 'Title',
                    Cell: ({value}) => {
                      return TableUtils.renderBaseContent(<>{value.playlist.name}</>);
                    },
                  },
                  {
                    id: 'Track',
                    Header: intl.formatMessage({id: 'TRACKS'}),
                    Cell: ({value}) => {
                      return TableUtils.renderBaseContent(<>{value.track.name}</>);
                    },
                  },
                  {
                    id: 'Rank',
                    maxWidth: 150,
                    Header: intl.formatMessage({id: 'CM_SCORE'}),
                    Cell: ({value}) => {
                      return TableUtils.renderBaseContent(
                        <>
                          <div className={'d-flex flex-column'}>
                            <div>
                              {intl.formatMessage({id: 'TRACK'})}: {value.track.score}
                            </div>
                            <div>
                              {intl.formatMessage({id: 'PLAYLIST'})}: {value.playlist.position}
                            </div>
                          </div>
                        </>,
                      );
                    },
                  },
                  {
                    id: 'Date',
                    maxWidth: 150,
                    Header: intl.formatMessage({id: 'ADD_DATE'}),
                    Cell: ({value}) => {
                      return TableUtils.renderBaseContent(
                        <>
                          <div className={'d-flex flex-column'}>{intl.formatDate(value.playlist.added_at)}</div>
                        </>,
                      );
                    },
                  },
                  {
                    id: 'Followers',
                    Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUBSCRIBERS'})),
                    visible: platform === PlaylistPlatform.SPOTIFY,
                    maxWidth: 150,
                    Cell: ({value}) => {
                      return TableUtils.renderNumericContent(
                        <div
                          className={
                            'd-flex flex-row align-items-center justify-content-end font-size-lg font-weight-boldest'
                          }>
                          {value.playlist.followers != null ? (
                            <FormattedNumber value={value.playlist.followers} notation={'compact'} />
                          ) : (
                            intl.formatMessage({id: 'NOT_APPLICABLE'})
                          )}
                          {renderDynamic(value.playlist.fdiff_month)}
                        </div>,
                      );
                    },
                  },
                  {
                    id: 'Views',
                    Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'VIEWS'})),
                    visible: platform === PlaylistPlatform.YOUTUBE,
                    maxWidth: 150,
                    Cell: ({value}) => {
                      return TableUtils.renderNumericContent(
                        <div
                          className={
                            'd-flex flex-row align-items-center justify-content-end font-size-lg font-weight-boldest'
                          }>
                          {value.playlist.views != null ? (
                            <FormattedNumber value={value.playlist.views} notation={'compact'} />
                          ) : (
                            intl.formatMessage({id: 'NOT_APPLICABLE'})
                          )}
                          {renderDynamic(value.playlist.vdiff_month)}
                        </div>,
                      );
                    },
                  },
                ]}
                data={playlists}
              />
            )}

            <Toolbar
              className={'w-100 d-flex justify-content-center'}
              items={[
                {
                  visible: playlists.length !== 0 && canFetchMore,
                  loading: loadings.fetch,
                  disabled: loadings.fetch,
                  title: intl.formatMessage({id: 'LOAD_MORE'}),
                  type: 'BUTTON',
                  icon: ICONS.DOWNLOAD,
                  className: 'btn btn-sm btn-secondary text-uppercase font-weight-bolder',
                  onClick: fetchMore,
                },
              ]}
            />
          </CardBody>
        )}
      </Card>
    </>
  );
};
