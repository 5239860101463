import {Dispatch, useCallback, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Currency, Transaction} from '../../api/DTOs/Transaction';
import {ValidationErrorsType} from '../../utils/utils';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {EntityId, float} from '../../api/base/BaseEntity';
import {isValidationException} from '../../utils/api-utils';
import {TransactionOperation} from '../../api/DTOs/TransactionOperation';
import {PartialNullable} from '../../../types/types';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';

export type CreateTransactionRequestPayload =
  | CreateWithdrawalTransactionsFields
  | CreateTransactionToOurOrganizationFields
  | CreateReturnYoutubeTaxToYoutubeContractorFields;

export type CreateWithdrawalTransactionsFields = {
  contractor_id: EntityId;
  value: float;
  currency: Currency;
  description: string;
};

export type CreateReturnYoutubeTaxToYoutubeContractorFields = {
  contractor_id: EntityId;
  contract_id: EntityId;
  y_channel_id: EntityId;
  period_month: number;
  period_year: number;
  value: float;
  currency: Currency;
  description: string;
};

export type CreateTransactionToOurOrganizationFields = {
  contractor_id: EntityId;
  contract_id: EntityId;
  value: float;
  currency: Currency;
  period_month: number;
  period_year: number;
  description: string;
};

export type TransactionRelation = {
  operations: Array<TransactionOperation>;
};

export type TransactionPrivileges = {
  can_confirm: boolean;
  can_cancel: boolean;
  can_reject: boolean;
};

export function useTransactionsApi() {
  const config = useRef({prefixUrl: 'transactions', isAdmin: false});
  const api = useEntityApi<Transaction, TransactionRelation, TransactionPrivileges>(config.current);
  return {
    ...api,
    ...useAdditionalRoutes(config.current, api.setValidationErrors),
  };
}

const useAdditionalRoutes = (params: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) => {
  const api = useBaseEndpointApi();
  const handleValidationsError = useCallback(
    async <T>(callback: () => Promise<T>) => {
      try {
        return await callback();
      } catch (e) {
        if (isValidationException(e)) {
          setValidationErrors(e.innerException.error_data.messages);
        }
        throw e;
      }
    },
    [setValidationErrors],
  );

  const confirmTransaction = useCallback(async (transactionId: EntityId) => {
    return handleValidationsError(async () => await api.post(`${params.prefixUrl}/${transactionId}/confirm`));
  }, []);

  const rejectTransaction = useCallback(async (transactionId: EntityId, reject_reason: string) => {
    return handleValidationsError(
      async () =>
        await api.post(`${params.prefixUrl}/${transactionId}/reject`, {
          fields: {
            reject_reason: reject_reason,
          },
        }),
    );
  }, []);

  const cancelTransaction = useCallback(async (transactionId: EntityId, reject_reason: string) => {
    return handleValidationsError(
      async () =>
        await api.post(`${params.prefixUrl}/${transactionId}/cancel`, {
          fields: {
            cancel_reason: reject_reason,
          },
        }),
    );
  }, []);

  const createTransactionWithdrawal = useCallback(
    async (payload: PartialNullable<CreateWithdrawalTransactionsFields>) => {
      return handleValidationsError<BaseItemResponse<Transaction>>(
        async () =>
          await api.post(`${params.prefixUrl}/create-transaction-withdrawal`, {
            fields: payload,
          }),
      );
    },
    [],
  );

  const createTransactionToOurOrganization = useCallback(
    async (payload: PartialNullable<CreateTransactionToOurOrganizationFields>) => {
      return handleValidationsError<BaseItemResponse<Transaction>>(
        async () =>
          await api.post(`${params.prefixUrl}/create-transaction-to-our-organization`, {
            fields: payload,
          }),
      );
    },
    [],
  );

  const createTransactionReturnYoutubeTaxToYoutube = useCallback(
    async (payload: PartialNullable<CreateReturnYoutubeTaxToYoutubeContractorFields>) => {
      return handleValidationsError<BaseItemResponse<Transaction>>(
        async () =>
          await api.post(`${params.prefixUrl}/create-transaction-return-youtube-tax-to-youtube`, {
            fields: payload,
          }),
      );
    },
    [],
  );

  return {
    createTransactionReturnYoutubeTaxToYoutube,
    createTransactionToOurOrganization,
    createTransactionWithdrawal,

    confirmTransaction,
    cancelTransaction,
    rejectTransaction,
  };
};
