import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../../components/modals/base-modal/base-modal';
import {FormattedMessage} from 'react-intl';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import React, {useState} from 'react';
import {useIntl} from '../../../hooks/use-intl';
import {Modal} from 'react-bootstrap';
import {AttachmentMetadata} from '../../../components/file-uploader';
import {UploadImageBlock} from '../upload-and-create/upload-image-block';
import {AttachmentType} from '../upload-and-create/attachment-block';
import {CreatedFile} from '../../../api/file-api/files-api';
import {UploadVideoBlock} from '../upload-and-create/upload-video-block';
import {LabelWithDescription} from '../../../components/Inputs/LabelWithDescription';
import {MultipartUploaderErrorsPayloads} from '../../../services/MultipartUploader';

interface IModalProps {
  visible: boolean;
  videoMetadata: AttachmentMetadata | null;
  imageMetadata: AttachmentMetadata | null;
  onDelete: (type: AttachmentType) => void;
  onStartUpload: (file: File, type: AttachmentType, tickCb?: (progress: number) => void) => Promise<CreatedFile>;
  onCancelUpload: (promiseResolver: (value: any | PromiseLike<any>) => void) => void;
  onTryAgainUpload: (video: File, lastError: MultipartUploaderErrorsPayloads, tickCb: (progress: number) => void) => Promise<CreatedFile>;
  onCreateFile: (file: CreatedFile, type: AttachmentType) => void;
  errors?: string | null;
  validationErrors?: ValidationErrorsType;

  onHide(): void;

  onOkClick(): any;
}

export const ModalUploadFiles: React.FC<IModalProps> = ({
  visible,
  videoMetadata,
  imageMetadata,
  validationErrors,
  onStartUpload,
  onDelete,
  onCreateFile,
  onCancelUpload,
  onTryAgainUpload,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'UPLOAD_WATERMARK_FILES'} />s
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <LabelWithDescription
              required
              label={intl.formatMessage({id: 'PREVIEW_FILE'})}
              className={{label: 'mb-0'}}
            />
            <UploadImageBlock
              metadata={imageMetadata}
              validationError={getValidationErrorMessage('watermarked_image_file_id', validationErrors)}
              onStartUpload={file => onStartUpload(file, AttachmentType.IMAGE)}
              onCreateFile={file => onCreateFile(file, AttachmentType.IMAGE)}
              onDelete={() => onDelete(AttachmentType.IMAGE)}
            />
            <hr />
            <LabelWithDescription required label={intl.formatMessage({id: 'VIDEO_FILE'})} className={{label: 'mb-0'}} />
            <UploadVideoBlock
              metadata={videoMetadata}
              validationError={getValidationErrorMessage('watermarked_video_file_id', validationErrors)}
              onCancelUpload={onCancelUpload}
              onStartUpload={(file, tickCb) => onStartUpload(file, AttachmentType.VIDEO, tickCb)}
              onCreateFile={file => onCreateFile(file, AttachmentType.VIDEO)}
              onTryAgainUpload={onTryAgainUpload}
              onDelete={() => onDelete(AttachmentType.VIDEO)}
            />
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
