import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {SelectOption} from './InputMultiSelect';
import './InputSelect.scss';
import {LabelWithDescription} from './LabelWithDescription';

interface Props {
  options: Array<SelectOption>;
  defaultValues: Array<string | number> | null;
  id?: string;
  label?: string;
  descriptionLabel?: string;
  ariaLabel?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;

  onChange(items: Array<SelectOption>): void;

  onCreate(inputValue: string): Promise<void>;
}

export const InputCreatableMultiSelect: React.FC<Props> = ({
  options,
  defaultValues,
  isClearable,
  isLoading,
  placeholder,
  ariaLabel,
  onChange,
  className,
  hasError,
  label,
  descriptionLabel,
  required,
  disabled,
  onCreate,
}) => {
  const onChangeInternal = (e: any) => {
    const result = e as Array<SelectOption>;
    onChange(result);
  };

  const handleCreate = async (inputValue: string) => {
    await onCreate(inputValue);
  };

  return (
    <div className={className + ' select-container'}>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <CreatableSelect
        isMulti
        value={
          defaultValues?.map(value => ({
            value: value,
            label: options.find(o => o.value == value)?.label ?? 'loading...',
          })) ?? null
        }
        closeMenuOnSelect={false}
        classNamePrefix={'react-select_2'}
        aria-label={ariaLabel}
        options={options as any}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={disabled}
        onCreateOption={handleCreate}
        onChange={onChangeInternal}
        styles={{
          control: styles => ({
            ...styles,
            ...(hasError
              ? {
                  borderColor: '#DC3545 !important',
                  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
                }
              : {}),
          }),
        }}
      />
    </div>
  );
};
