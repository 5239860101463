import {Reducer} from 'redux';

export const UPDATE = 'UPDATE';

export type UpdaterState = {
  needReloadForGetUpdate: boolean;
};

export interface IBaseAction {
  type: string;
}

export type IUpdateAction = IBaseAction;

export function UpdateAction(): IUpdateAction {
  return {
    type: UPDATE,
  };
}

const initialAuthState: UpdaterState = {
  needReloadForGetUpdate: false,
};

export const UpdateReducer: Reducer<UpdaterState, IUpdateAction> = (
  state = initialAuthState,
  action: IUpdateAction,
) => {
  switch (action.type) {
    case UPDATE: {
      return {...state, needReloadForGetUpdate: true};
    }

    default:
      return state;
  }
};
