import {
  FinancialReportSource,
  FinancialReportType,
  ReportsProcessingStatus,
} from '../../app/api/financial-report-api/FinancialBulkReports';
import {
  AvailableContractChange,
  ContractStatus,
  ContractType,
  ReportFrequency,
} from '../../app/api/contract-api/Contract';
import {PaymentRequestDestination, PaymentRequestStatus} from '../../app/api/DTOs/PaymentRequest';
import {FilterRequestStatus} from '../../app/api/connect-channel-api/FilterRequestStatus';
import {Currency, TransactionStatus} from '../../app/api/DTOs/Transaction';
import {SupportTicketStatus, SupportTicketType} from '../../app/api/support-tickets-api/ISupportTicketDto';
import {AdsStatus} from '../../app/api/advertisement-api/IAdvertisementRequestDto';
import {ArticleStatus} from '../../app/api/knowledge-base-api/IArticleDto';
import {VideoActionType, VideoStatus} from '../../app/api/DTOs/Video';
import {ContractorDataValidationStatus, ContractorType} from '../../app/api/contractor-api/Contractor';
import {DateFormat} from '../../app/components/Inputs/InputDatepicker';
import {Feed} from '../../app/api/DTOs/Feed';
import {TransactionAction} from '../../app/pages/admin/transactions/transaction-action-types';
import {TransactionOperationType} from '../../app/api/DTOs/TransactionOperation';
import {ReportPeriodStatus} from '../../app/api/DTOs/ReportPeriod';
import {FinancialAccountType} from '../../app/api/DTOs/FinancialAccount';
import {ContractorDocumentType} from '../../app/api/DTOs/ContractorDocument';
import {ChartMetricArtistRankingPeriod} from '../../app/pages/artists/chartmetric/socials-streaming-rankings';

export default {
  'BUTTONS.SUBMIT': 'Submit',
  'BUTTONS.CANCEL': 'Cancel',
  'AUTH.GENERAL.WELCOME': 'Welcome to StarPro',
  'AUTH.GENERAL.WELCOME_SUBTITLE': 'The World Biggest Ecosystem for Creators',
  'AUTH.GENERAL.NO_ACCOUNT': "Don't have an account?",
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Sign Up',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Forgot Password',
  'AUTH.GENERAL.BACK_BUTTON': 'Back',
  'AUTH.GENERAL.ALREADY_HAVE_ACCOUNT': 'Already have account?',
  'AUTH.LOGIN.TITLE': 'Login Account',
  'AUTH.LOGIN.BUTTON': 'Sign In',
  'AUTH.FORGOT.TITLE': 'Forgotten Password?',
  'AUTH.LOGIN.SUB_TITLE': 'Enter your email and password',
  'AUTH.FORGOT.DESC': 'Enter your email to reset your password',
  'AUTH.REGISTER.TITLE': 'Sign Up',
  'AUTH.REGISTER.DESC': 'Enter your details to create your account',
  'AUTH.REGISTER.AGREEMENT': 'I agree the Terms & Conditions',
  'AUTH.REGISTER.UNEXPECTED_ERROR': 'Sorry, something was wrong. Please repeat again.',
  'AUTH.INPUT.EMAIL': 'Email',
  'AUTH.INPUT.PASSWORD': 'Password',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Confirm Password',

  ID: 'ID',
  NAME: 'Name',
  USER: 'User',
  ADMIN: 'Admin',
  USER_PROFILE: 'Profile',
  LOGIN_ERROR: 'Password or email is wrong',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  NOT_FOUND: 'Empty',
  DELETE: 'Delete',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
  DOWNLOAD: 'Download',
  TRY_AGAIN: 'Try again',
  TRY_DOWNLOAD_AGAIN: 'Try download again',
  CREATE_CONTRACT: 'Create contract',
  CLOSE: 'Close',
  EDIT: 'Edit',
  CREATE: 'Create',
  CLEAR: 'Clear',

  YOUTUBE_CHANNELS: 'YouTube channels',
  YOUTUBE_CHANNEL: 'YouTube channel',
  YOUTUBE_ALL_VIDEOS: 'YouTube all videos',
  USER_MANAGEMENT: 'Users',
  KNOWLEDGE_BASE: 'FAQ',
  ACCOUNT: 'Account',
  PAYOUTS: 'Payouts',
  REVENUES: 'Revenues',
  CONTRACTS: 'Contracts',
  CONTRACT: 'Contract',

  CARDS: 'Cards',
  TABLE: 'Table',
  SORT_FIELD: 'Sort',
  CHANNELS: 'Channels',

  TITLE: 'Title',
  LIKES: 'Likes',
  DISLIKES: 'Dislikes',
  VIEWS: 'Views',
  DYNAMICS: 'Dynamic',
  COMMENTS: 'Comments',
  SUBSCRIBERS: 'Subscribers',
  NEW_FIRST: 'New first',
  OLD_FIRST: 'Old first',
  UPDATED_FIRST: 'Updated first',
  UPDATED_LAST: 'Updated last',
  INNER_FIRST: 'Inner first',
  CACHE_FIRST: 'Cache first',

  ASC: 'ascending',
  DESC: 'descending',

  VIDEOS: 'Videos',
  METRICS: 'Metrics',

  LAST7DAYS: 'Last 7 days',
  LAST14DAYS: 'Last 14 days',
  LAST28DAYS: 'Last 28 days',
  LAST90DAYS: 'Last 90 days',

  WEEK: 'Week',
  QUARTER: 'Quarter',
  MONTH: 'Month',
  YEAR: 'Year',

  SUPPORT: 'Support',
  LOGOUT: 'Logout',

  RESET_PASSWORD: 'Reset password',
  SUCCESS_SENT_RESET_PASSWORD_EMAIL:
    'An email with instructions for password recovery has been sent to the email address you specified',
  SUCCESS_SEND_GENERATE_REPORT_REQUEST: 'The report will be generated and sent to your email shortly',
  NOT_FOUND_THIS_ACCOUNT: "Couldn't find your account",
  UNEXPECTED_ERROR: 'Sorry, something was wrong. Please repeat again.',

  CREATE_NEW_PASSWORD: 'Create new password',
  PROFILE: 'Profile',
  CHANGE_PASSWORD: 'Change password',
  SUCCESS_CHANGE_PASSWORD: 'Password was successful change',
  NEW_PASSWORD: 'New password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CURRENT_PASSWORD: 'Current password',

  CONNECT: 'Connect',
  CONNECTED: 'Connected',
  INTEGRATE_WITH_YOUTUBE: 'YouTube Integration',
  INTEGRATE_WITH_YOUTUBE_DESCRIPTION: 'Provide access to your google account in order to activate basic functionality',
  SUCCESS_COMPLETE_YOUTUBE_INTEGRATION: 'Integration successfully completed',
  ERROR_COMPLETE_YOUTUBE_INTEGRATION: 'Failed to integrate',

  SUCCESS_UPLOAD_REPORTS: 'Upload reports successfully completed',
  ERROR_UPLOAD_REPORTS: 'An error occurred while uploading the reports',

  ADMINISTRATE: 'Administrate',
  YOUTUBE_INTEGRATION: 'YouTube integration',
  TRANSACTIONS: 'Transactions',
  FINANCIAL_OPERATIONS: 'Financial operations',
  FINANCIAL_OPERATION: 'Financial operation',
  DASHBOARD: 'Dashboard',

  EXPIRED: 'Expired',
  TOKEN_TYPE: 'Token type',
  JURISDICTION: 'Registered in accordance with the laws of the',
  JURISDICTION_LEGAL: 'Contractor registration',
  JURISDICTION_ENTREPRENEUR: 'Contractor registration',
  JURISDICTION_INDIVIDUAL: 'Citizenship',

  FINANCIAL_REPORTS: 'Financial reports',
  FINANCIAL_REPORT: 'Financial report',
  REPORTS_UPLOAD: 'Upload reports',
  YOUTUBE_FINANCIAL_REPORTS: 'Import financial reports',
  REPORT_PERIOD: 'Upload period',
  LABEL_PERIOD: 'Select period',
  DROP_FILES: 'Drop files here or click to upload',
  GO: 'Go',
  GO_BACK: 'Go back',

  FILE_NAME: 'File name',
  SIZE: 'Size',
  REPORT_TYPE: 'Type',
  CREATED_AT: 'Created at',
  UPDATED_AT: 'Updated at',
  BLOCKED_AT: 'Blocked at',
  USER_TYPE: 'Type',
  EMAIL: 'Email',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  ACTION: 'Action',
  EVENT: 'Event',
  GET_USERS_ERROR: 'Unexpected error when receiving users',
  CONFIRM_DELETE_USER: 'Are you sure you want to delete the user?',
  CONFIRM_BLOCK_USER: 'Are you sure you want to block the user?',
  CONFIRM_UNBLOCK_USER: 'Are you sure you want to unblock the user?',
  CONFIRM_DELETE_GENRE: 'Are you sure you want to delete the genre?',
  CONFIRM_RESET_PASSWORD_USER: 'Are you sure you want to reset password the user?',
  SUCCESS_COMPLETE_ACTION_WITH_USER: 'Action successfully completed',
  ERROR_COMPLETE_ACTION_WITH_USER: 'Failed to action',

  CONTRACTOR: 'Contractor',
  CONTRACTORS: 'Contractors',
  USERS: 'Users',
  ACCOUNTS: 'Accounts',
  SUM: 'Sum',
  FROM: 'From',
  TO: 'To',

  TOGGLE_MENU: 'Show/Hide Menu',

  CURRENT_BALANCE: 'Current balance',
  DESCRIPTION: 'Description',
  ORIGINAL_DESCRIPTION: 'Original description',
  FINANCIAL_ACCOUNTS: 'Financial accounts',
  BALANCE: 'Balance',
  ACCOUNT_TYPE: 'Account type',
  INNER_ACCOUNT: 'Inner account',
  CACHE_ACCOUNT: 'Cache account',
  CONTRACT_NUMBER: 'Number',
  VALIDITY_PERIOD: 'Validity period',
  CONTRACT_START_DATE: 'Start date',
  CONTRACT_END_DATE: 'End date',
  CONTRACT_NUMBER_BE_AUTOGENERATED: 'The contract number will be generated automatically',
  CHANNEL_NAME: 'Channel name',
  CHANNEL: 'Channel',
  SUP_AGREEMENT_NUMBER: 'Number',
  ADDENDUM_LIST: 'Addendum list',
  RATE_PERCENT: 'Rate percent',
  CREATE_ADDENDUM: 'Create addendum',
  ADDENDUM: 'Addendum',
  SOURCE: 'Source',
  CONTRACTOR_EMAIL: 'Contractor email',
  CONTRACTOR_NAME: 'Contractor name',
  USER_NAME: 'User name',
  EDIT_CONTRACT: 'Edit contract',
  EDIT_CONTRACTOR: 'Edit contractor',
  CREATE_USER: 'Create user',
  EDIT_USER: 'Edit user',
  PASSWORD: 'Password',
  PASSWORD_CONFIRMATION: 'Confirm Password',
  ADMIN_PRIVILEGES: 'Admin privileges',
  USERS_ACTIONS: 'Users actions',
  STATUS_CODE: 'Status code',
  SHOW_MORE: 'Show more',
  AGENT: 'Agent',
  TIME: 'Time',
  TYPE: 'Type',
  REPORT_FREQUENCY: 'Report frequency',
  PAYMENT_REQUEST: 'Payment request',
  PAYMENT_REQUESTS: 'Payment requests',
  SUCCESS_PAYMENT_REQUEST: 'Payment request successfully created',
  SEND: 'Send',
  PERFORM: 'Perform',
  REJECT: 'Reject',
  REJECT_REASON: 'Reject reason',
  CANCEL_REASON: 'Cancel reason',
  OPERATION_WAS_CANCELED: 'This operation was canceled',
  DESCRIBE_REJECT_REASON: 'Describe the reject reason',
  DESCRIBE_CANCEL_REASON: 'Describe the cancel reason',
  ADD_YOUTUBE_CHANNEL: 'Adding a YouTube Channel',
  ADD_YOUTUBE_CHANNEL_DESCRIPTION: 'Provide access to StarPro services to help your YouTube channel grow.',
  ADD: 'Add',
  ADD_CHANNEL_REQUESTS: 'Requests to connect YouTube channels',
  PUBLISHED_AT: 'Published at',
  PUBLISH: 'Publish',
  APPROVE: 'Approve',
  PERIOD: 'Period',
  ACCOUNT_OPERATIONS: 'Account operations',

  MIN_PAYOUT_AMOUNT_IS: 'The min payout amount ',
  OF: 'of',
  DRAW_SIGNATURE: 'Draw signature',
  SIGN: 'Sign',
  SIGNATURE: 'Signature',
  REQUISITES: 'Requisites',
  REPORT: 'Report',
  PAYMENT_REQUISITES: 'Payment requisites',
  SWIFT_NUMBER: 'SWIFT/BIC/Routing',
  ACCOUNT_NUMBER: 'Account number',
  MONTHS: 'Months',
  GENERATE_REPORT: 'Generation financial report',
  GENERATE: 'Generate',
  EDIT_PAYMENT_REQUISITES: 'Edit payment requisites',
  ADDRESS: 'Address',
  SHOW_REQUESTS: 'Show my requests',
  SUCCESS_SENT_CONNECT_CHANNEL_REQUEST:
    'The channel connection request has been successfully created. It will be processed by the administrator soon.',
  ERROR_SENT_CONNECT_CHANNEL_REQUEST:
    'The channel connection failed. Make sure the channel has not been connected before, or try again later.',
  NOTICE_EDIT_RESTRICTED: 'Editing of some fields is blocked because there are transactions on this addendum.',
  EDIT_ADDENDUM: 'Edit addendum',
  TRANSACTION_SUM_PREVIOUS_MONTH: 'The amount of transactions for the previous month',
  TRANSACTION_SUM_CURRENT_MONTH: 'The amount of transactions for the current month',
  NOTICE_ACCRUALS_IN_USD: 'To calculate income, you need to specify a USD account',
  UNCONFIRMED_BALANCE: 'Unconfirmed balance',
  WITHDRAWAL_AVAILABLE_BALANCE: 'Withdrawal available balance',
  CREDIT_BALANCE: 'Credit balance',
  DOWNLOAD_XLSX_REPORT: 'Download XLSX report',
  GENERATE_INCOME_REPORT: 'Download Channel Revenue Report',
  CHANNEL_TRANSACTIONS_INCOME_REPORT: 'Generate channel income report',
  REQUESTS: 'Requests',

  CONFIRM_ALL_TRANSACTIONS: 'Confirm all transactions',
  CREATE_TRANSACTION: 'Create transaction',
  CREATE_ACTION_TRANSACTION: 'Create Withdrawing transaction',
  CREATE_ACTION_TAX_TRANSACTION: 'Create Tax transaction',
  ADVERTISEMENT: 'Placement of advertising',
  CREATE_ACTION_DEBIT_TRANSACTION: 'Create Debit transaction',
  CONFIRM_TRANSACTION: 'Are you sure you want to confirm the transaction?',
  CONFIRM_TRANSACTION_DESCRIPTION: 'The user will receive a notification about the accrual to his account.',
  CONFIRM_TRANSACTION_SUCCESS: 'The transaction was successfully completed',
  REJECT_TRANSACTION_SUCCESS: 'The transaction was successfully rejected',
  CANCEL_TRANSACTION_SUCCESS: 'The transaction was successfully canceled',

  DASHBOARD_WITHOUT_ACTIVE_CONTRACT: 'Без активного договора',
  DASHBOARD_VIDEOS_COUNT: 'Videos count',
  DASHBOARD_CHANNELS_COUNT: 'Channels count',
  DASHBOARD_CHANNEL_CONNECT_REQUESTS: 'Connect channel requests count',
  DASHBOARD_HAS_SIGNATURE: 'Signature available',
  DASHBOARD_HASNT_SIGNATURE: 'Signature not available',
  DASHBOARD_HAS_PAYMENT_REQUISITES: 'Payment requisites available',
  DASHBOARD_HASNT_PAYMENT_REQUISITES: 'Payment requisites not available',
  DASHBOARD_USERS_COUNT: 'Users count',
  DASHBOARD_TODAY_COUNT: 'Today',
  DASHBOARD_IN_WORK_COUNT: 'In work',
  DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM: 'Current amount of requests',
  DASHBOARD_CHANNELS_WITHOUT_ADDENDUM: 'Channels unrelated to the application',
  DASHBOARD_ERRORS_COUNT: 'Number of errors today',
  DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT: 'Number of transactions awaiting confirmation',
  DASHBOARD_TRANSACTION_MANAGEMENT: 'Transaction management',
  DASHBOARD_PAYMENTS_MANAGEMENT: 'Payments management',
  DASHBOARD_USERS_CURRENT_MONTH_COUNT: 'This month',
  INSTRUCTIONS: 'Instructions',
  MAILINGS: 'Mailings',
  COMPLETE_REGISTRATION: 'Complete registration',
  OOPS: 'Oops',
  SOMETHING_WENT_WRONG: 'Something went wrong here',
  PAGE_NOT_FOUND: 'Page not found',
  EMPTY_REPORT_CANNOT_CREATE_FINANCIAL_REPORT:
    'Due to the lack of contracts it is not possible to upload the financial report',
  TOTAL_TRANSACTIONS_SUM: 'Total transactions sum',
  SAVE: 'Save',
  SUBJECT: 'Subject',
  CHOOSE_USERS: 'Choose users',
  CHOOSE_ALL: 'Choose all',
  CREATE_MAILING: 'Create mailing',
  MULTILINGUAL: 'Multilingual',
  MARKDOWN: 'Markdown',
  HTML: 'HTML',
  PREVIEW: 'Preview',
  SUCCESS_SEND_MAILING: 'The mailing has been successfully completed',
  EDIT_MAILING: 'Edit mailing',
  CONFIRM_DELETE_MAILING: 'Are you sure you want to delete the newsletter?',
  EMPTY: 'Empty',

  CREATE_TICKET: 'Create an appeal',
  TICKET_STATUS: 'Appeal staus',
  OPEN_TICKETS: 'Open',
  CLOSED_TICKETS: 'Closed',
  TICKET_TOPIC: 'Appeal theme',
  TICKET_TEXT: 'Appeal text',
  TICKET_AUTHOR: 'Author',
  OPEN_SUPPORT_TICKET: 'Open appeal',
  TICKETS_NOT_FOUND: 'You have no open support appeals',
  CREATE_TICKET_MARKETING_DESCRIPTION:
    'If you have a question, problem or suggestion\nContact us by creating an appeal.\nWe will certainly consider it.',
  SUPPORT_TICKET_REASON: 'Reason for opening an appeal',
  WRITE_MESSAGE: 'Write message',
  MESSAGE_COUNT: 'Message count',
  TICKET_OPENED_AT: 'Opened',
  CONFIRM_CLOSE_APPEAL: 'Are you sure you want to close the appeal?',
  CLOSE_APPEAL: 'Close the appeal',
  APPEAL_WAS_CLOSED: 'The appeal was closed',
  CONFIRM: 'Confirm',
  ATTENTION: 'Attention',
  UPDATE_CLIENT_NOTICE: 'The site has been updated. To get changes, refresh the page.',
  REFRESH: 'Refresh',
  REVOKE_ACCESS: 'Revoke access to the YouTube account',
  REVOKE_ACCESS_DESCRIPTION:
    'If you want to revoke access to your YouTube account, you can do so on <permissonslink>the Google Account Access Control page</permissonslink>.',
  SHOW_ALL: 'Show all',
  HIDE: 'Hide',
  CHANNEL_HIDDEN_FROM_YOUTUBE:
    'This channel is no longer on the multichannel network.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  HIDDEN_CHANNELS_AND_VIDEO_FROM_YOUTUBE:
    'This channel is not on a multichannel network, and the video has been hidden.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  VIDEO_HIDDEN_FROM_YOUTUBE:
    'This video has been hidden.\nTo upload your income report, go to <redirect>Financial Reports.</redirect>',
  NOTICE: 'Notice',
  CHANNEL_NOT_AVAILABLE: 'This channel is not available',
  RESET_PASSWORD_TOKEN_EXPIRED: 'The password reset token is out of date. Please request a new password reset.\n',
  REGISTRATION_CODE_CONFIRMATION: 'Registration confirmation',
  REGISTRATION_CODE_CONFIRMATION_DESC: 'An email <email></email> with a confirmation code has been sent to you',
  CODE_DIDNT_COME: "Didn't you get the code?",
  SEND_AGAIN: 'Send again',
  BACK_TO_REGISTER: 'Back to registration',
  CONFIRMATION_CODE_IS_INVALID: 'Confirmation code is invalid',
  CREDENTIALS_ARE_OUT_OF_DATE: 'Your account information is out of date, Please re-register',
  ACCESSIBILITY: 'Accessibility',
  CONTRACT_EXISTENCE: 'Contract existence',
  HAS_CONTRACT: 'Has contract',
  HAS_NO_CONTRACT: 'Has no contract',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ACTIVE_VIDEO: 'Active',
  INACTIVE_VIDEO: 'Inactive',
  PERIOD_START_DATE: 'Period started at',
  PERIOD_END_DATE: 'Period finish at',
  TAX_PERIOD: 'Tax period',
  SAVE_AS_DRAFT: 'Save as draft',
  DRAFT: 'Draft',
  EDIT_CHAPTER: 'Edit chapter',
  CREATE_CHAPTER: 'Create chapter',
  CREATE_NEW_CHAPTER: 'Create new chapter',
  CREATE_ARTICLE: 'Create article',
  CONFIRM_DELETE_ARTICLE: 'Are you sure you want to delete the article?',
  CONFIRM_DELETE_CHAPTER: 'Are you sure you want to delete the chapter?',
  CONFIRM_DELETE_COUNTRY: 'Are you sure you want to delete the country?',
  KNOWLEDGE_BASE_DESCRIPTION:
    'Here are the answers to the top questions about the StarPro Personal Area and your opportunities in the ecosystem.\n' +
    "If you can't find the question you're looking for in the list, or if you don't find the answer you need, please contact <supportlink>support directly</supportlink> .\n" +
    'We are always happy to help you!',
  GENRE: 'Genre',
  GENRES: 'Genres',
  COUNTRY: 'Country',
  COUNTRIES: 'Countries',
  COUNTRY_CODE: 'Country code',
  ARTISTS: 'Artists',
  ARTIST: 'Artist',
  ARTIST_LOGO: "Artist's logo",
  CREATE_ARTIST: 'Create an artist',
  EDIT_ARTIST: 'Edit an artist',
  CONFIRM_DELETE_ARTIST: 'Are you sure you want to remove the artist?',

  HAS_TAX_ON_PROFITS: 'Check if you work with VAT',
  INTEGRATION_ENABLE: 'Integration enable',
  INTEGRATION_PRICE: 'Integration price',
  ADVANCED_INTEGRATION_ENABLE: 'Advanced integration enable',
  ADVANCED_INTEGRATION_PRICE: 'Advanced integration price',
  POST_ENABLE: 'Post enable',
  POST_PRICE: 'Post price',
  PRE_ROLL_ENABLE: 'Pre roll enable',
  PRE_ROLL_PRICE: 'Pre roll price',
  PRODUCT_PLACEMENT_ENABLE: 'Product placement enable',
  PRODUCT_PLACEMENT_PRICE: 'Product placement price',
  SPECIAL_PROJECT_ENABLE: 'Special project enable',
  SPECIAL_PROJECT_PRICE: 'Special project price',
  EXPECTED_VIEWS_COUNT: 'Expected views count',
  TAX: 'Income tax',
  RELEASE_DATE: 'Release at',
  COMMENT: 'Comment',
  CHOSEN: 'Chosen',
  CONFIRM_DELETE_ADS_REQUEST: 'Are you sure you want to delete the ads request?',
  ADVERTISEMENT_REQUESTS: 'Ads requests',
  SUCCESS_ADS_REQUEST_REJECT: 'Request for advertising has been successfully rejected',
  SUCCESS_ADS_REQUEST_CONFIRM: 'Request for advertising has been successfully approved',
  PRICE: 'Price',
  PRICE_WITHOUT_TAX: 'Cost excluding tax',
  CREATE_ADS_REQUEST: 'Create an ad request',
  ADS_REQUEST_COMMENT_PLACEHOLDER:
    'Tell us more about future content:\n\nPlanned topic of the video, Invited guests\nExpected number of views, Other',
  ADS_REQUESTS_NOT_FOUND: 'Find advertisers for your content!',
  CONFIRM_APPROVE_ADS_REQUEST: 'Are you sure you want to approve the advertising request?',
  CREATE_ADS_REQUEST_MARKETING_DESCRIPTION:
    'This section is designed to let us know about free advertising slots.\n\n' +
    'Together with <playersteamlink>Players</playersteamlink> and other partners, we will help you find sponsors \n' +
    'for upcoming content, as well as place information in the <playerscontentbotlink>Players Content Bot.</playerscontentbotlink>',
  COOKIE_NOTICE: 'We use cookies to make the site more user-friendly.',
  I_AGREE: 'I agree',
  SPECIFY_HEIGHT: 'Specify height',
  SPECIFY_WIDTH:
    'Specify the width (by default it is set in px, to set the value in percent, specify "%" at the end of the value)',
  LABELS: 'Labels',
  LABEL: 'Label',
  INVITE_TOKEN_EXPIRED: 'This invitation is out of date, ask your counterparty to send it again',
  INVITE_USER: 'Invite user',
  ALLOW_ACCESS_TO_FINANCE: 'Allow access to finances',
  INVITE_WAS_SUCCESS_SENT: 'The invitation was successfully sent',
  UPLOAD_VIDEO: 'Upload video',
  UPLOAD: 'Upload',
  CONFIRM_DELETE_UPLOADED_VIDEO: 'Are you sure you want to delete the uploaded video? (this action is irreversible)',
  VIDEO_FILE: 'Video file',
  PREVIEW_FILE: 'Preview file',
  VIDEO_SUCCESSFUL_UPLOAD: 'Video has been successfully uploaded',
  VIDEO_FAILED_UPLOAD: 'An error occurred while loading the video',
  IMAGE_SUCCESSFUL_UPLOAD: 'Image has been successfully uploaded',
  IMAGE_FAILED_UPLOAD: 'An error occurred during the download',
  EXPLICIT: 'Explicit',
  CODE: 'Code',
  CONTRACTOR_CODE: 'Contractor code',
  VIDEO_CODE_NOTE: 'Generation occurs automatically',
  VIDEO_COUNTRIES_NOTE: 'Specify the countries where the video will be available after publication',
  AGE_LIMIT: 'Age limit',
  DIRECTOR: 'Director',
  AUTHORS: 'Authors',
  VIDEO_TITLE_NOTE: 'No artist name or quotes',
  VIDEO_DESCRIPTION_NOTE:
    'The specified text will be displayed in the section with the same name when you view the video in Youtube',
  VIDEO_PREVIEW_NOTE: 'Picture format - JPEG (*.jpg, *.jpeg), size - at least 1280px X 720px (in each dimension)',
  VIDEO_CONTENT_NOTE: 'The file must be in one of the following formats: MP4, MOV, WMV, MPEG, AVI',
  WAIT_CANCEL_UPLOAD_VIDEO_MESSAGE: 'Video upload canceling, please wait',
  VIDEO_AGE_LIMIT_NOTICE:
    'Videos for viewers over 18 are not shown in all areas of YouTube, and may have limited or no ads. <redirect>Read more...</redirect>',
  FEEDS_NOTICE:
    'Select the platform for which uploaded content will be available. Once content is released, you will be able to find it on selected sites',
  UPLOAD_WATERMARK_FILES: 'Upload files with watermark',
  ARTIST_IMAGE_UPLOAD_NOTE: 'Image format - JPEG (*.jpg, *.jpeg), aspect ratio - 1:1',
  CONTRACTOR_EXTERNAL_ID: 'External ID',
  CONTRACTOR_EXTERNAL_ID_NOTE: 'Own identifier',
  CREATE_CONTRACTOR: 'Create contractor',
  FEED_LOGO: 'Picture of the feed',
  CREATE_FEED: 'Create a feed',
  EDIT_FEED: 'Edit a feed',
  FEED: 'Feed',
  FEEDS: 'Feeds',
  APPROVED_FEEDS: 'Approved feeds',
  READY_TO_PUBLISH_SUCCESS:
    'A request to prepare for publication has been successfully sent, the video will be ready for publication shortly',
  ORIGINAL: 'Raw',
  PREPARED: 'Prepared',
  UPLOAD_FILES_WITH_WATERMARKS: 'Upload files with watermarks',
  ACTIVITY_HISTORY: 'Activity history',
  ACTIVITY_DATE: 'Date',
  OTHER_ARTISTS_VIDEOS: 'Other artists videos',
  IMAGE_RATIOS_INCORRECT: 'The aspect ratio of the image does not meet the requirements',
  REJECT_PUBLISH: 'Reject publication',
  CONFIRM_PUBLISH: 'Confirm publication',
  REJECT_PUBLISH_SUCCESS: 'Publication request successfully rejected',
  CONFIRM_PUBLISH_SUCCESS: 'Publication request successfully approved',
  CONFIRM_VIDEO_PUBLICATION: 'Are you sure you want to approve this video?',
  SYSTEM: 'System',
  FILES: 'Files',
  CONFIRM_DELETE_FILE: 'Are you sure you want to delete the file?',
  FILE_SUCCESS_DELETED: 'File deleted successfully',
  FILE_FAILED_DELETED: 'Failed to delete file',
  BUCKET: 'Bucket',
  STORAGE_CAPACITY: 'Storage capacity',
  FILES_SIZES: 'Total files size',
  FILES_COUNT: 'Total count of files',
  RELATION: 'Relation',
  RELATIONS: 'Relations',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  MIDDLE_NAME: 'Middle name',
  PHONE_NUMBER: 'Phone number',
  CONTRACTOR_TYPE: 'Contractor type',
  YOU_REGISTERED_AS: 'You register as',
  DOLLAR: 'US Dollar',
  RUBLE: 'Ruble',
  CHOOSE_CURRENCY: 'Choose a currency',
  LEGAL_NAME: 'Name of organization',
  DATE_OF_BIRTH: 'Date of birth',
  TAX_PAYER_NUMBER: 'TIN',
  TAX_PAYER_KPP: 'TRRC',
  VAT: 'VAT, %',
  VAT_PAYER: 'VAT payer',

  SNILS: 'SNILS',
  SELF_EMPLOYED: 'Self-Employed',
  INFO_ABOUT_LEGAL: 'Legal entity information',
  INFO_ABOUT_INDIVIDUAL: 'Individual information',
  INFO_ABOUT_ENTREPRENEUR: 'Entrepreneur information',
  INFO_ABOUT_LEGAL_SIGNATORY: 'Signatory information',
  LEGAL_OGRN: 'OGRN',
  LEGAL_ADDRESS: 'The legal address of the organization',
  OGRNIP: 'OGRNIP',
  LEGAL_SIGNATORY_POSITION: 'Signatory position',
  LEGAL_SIGNATORY_ACTS_BY: 'Acts by',
  TRANSLIT: 'Translit',

  INFO_ABOUT_PASSPORT: 'Passport data',
  PASSPORT_DIVISION_CODE: 'Subdivision Code',
  PASSPORT_ISSUED_BY: 'Issued by',
  PASSPORT_NUMBER: 'Number',
  PASSPORT_ISSUED_DATE: 'Issue date',
  PASSPORT_REGISTRATION_ADDRESS: 'Address of registration',

  INFO_ABOUT_BANK: 'Bank details',
  BANK_NAME: 'Bank name',
  BANK_ACCOUNT_NUMBER: 'Bank account number',
  BANK_CORRESPONDENT_ACCOUNT_NUMBER: 'Correspondent account number',
  BANK_INN: 'Bank TIN',
  BANK_NUMBER: 'Bank number',
  BANK_ADDRESS: 'Bank address',

  BENEFICIARY_NAME: 'Beneficiary name',
  BENEFICIARY_ACCOUNT_NUMBER: 'Beneficiary account',
  BENEFICIARY_TRANSIT_ACCOUNT_NUMBER: 'Beneficiary transit account',

  INTERMEDIARY_NAME: 'Intermediary name',
  INTERMEDIARY_SWIFT: 'Intermediary SWIFT/BIC/Routing',
  INTERMEDIARY_ACCOUNT_NUMBER: "Intermediary's account",

  CONTRACTOR_DATA_VALIDATION_STATUS: 'Data validation status',
  REGISTRATION_REJECTED_NOTICE: 'The administrator denied registration',
  REGISTRATION_REJECTED_SUB_NOTICE: 'After verifying your information, the administrator blocked the registration',
  WIZARD_STEP_FILL_FIELDS: 'Filling Fields',
  WIZARD_STEP_WAITING_ADMINISTRATOR_CONFIRM: 'Checking Data',
  WIZARD_STEP_SUCCESS: 'Completed',
  REGISTRATION_WELCOME_NOTICE:
    'Welcome to the Star Pro Personal Area!\nIn order to continue using it, you will need to fill in your information and wait for the administrator to verify your information',
  REGISTRATION_FIX_REQUIREMENTS_NOTICE:
    'The administrator has verified your information, you will need to correct the following comments in order to continue using it.',
  REGISTRATION_WAITING_MODERATION_NOTICE: 'Your data has been sent for review',
  REGISTRATION_WAITING_MODERATION_SUB_NOTICE:
    'In order for you to be able to fully use Personal Area, we will need to verify your data.\n' +
    'This procedure may take a long time. After verification, you will receive a notification to your email',
  WIZARD_NEXT: 'Next',
  CONFIRM_APPROVE_REGISTRATION: 'Are you sure you want to confirm the counterparty registration?',
  APPROVE_REGISTRATION_SUCCESS: 'Counterparty registration confirmation successfully completed',
  DESCRIBE_REQUIREMENTS_REASON: 'Describe the requirements',
  REQUEST_FIX_REQUIREMENTS: 'Request fulfillment of requirements',
  REQUIREMENTS: 'Requirements',
  REJECT_REGISTRATION_SUCCESS: 'Counterparty registration successfully rejected',
  REQUEST_FIX_REQUIREMENTS_REGISTRATION_SUCCESS: 'Registration successfully pending counterparty compliance',
  IS_OUR_COMPANY: 'The organization that represents StarPro',
  ACCOUNT_CURRENCY: 'Account currency',
  RIGHTS_EXPIRATION: 'Date of expiry of rights',
  YOUTUBE_VIDEO_ID: 'Youtube video identifier',
  YOUTUBE_VIDEO_ID_SUCCESS_UPDATED: 'Youtube video identifier successfully updated',
  SET_YOUTUBE_VIDEO_ID: 'Set an identifier from the YouTube network',
  SET_YANDEX_ZEN_CHANNEL_ID: 'Set an channel identifier from the Yandex Zen network',
  YANDEX_ZEN_CHANNEL_ID: 'Yandex Zen network channel identifier',
  YANDEX_ZEN_CHANNEL_ID_SUCCESS_UPDATED: 'Yandex Zen network channel identifier successfully updated',
  CURRENCY: 'Currency',
  IS_SIGNED: 'Is signed',
  IS_NOT_SIGNED: 'Is not signed',
  CONTRACT_UPDATE_STATE_ON_DATE: 'Get the state of the contract as of the date of the sup agreement',

  SUP_AGREEMENTS: 'Supplementary Agreements',
  CREATE_SUP_AGREEMENT: 'Create supplementary agreement',
  EDIT_SUP_AGREEMENT: 'Edit supplementary agreement',
  SUP_AGREEMENT: 'Supplementary Agreement',
  SUP_AGREEMENT_STARTED_AT: 'Started at',
  SUP_AGREEMENT_EXPIRES_AT: 'Expires at',
  SUP_AGREEMENT_CHANGE_CHANNELS: 'Change YouTube channels',
  SUP_AGREEMENT_CHANGE_VIDEOS: 'Change videos',
  SUP_AGREEMENT_CHANGE_EXPIRES_AT: 'Change the expires date of the contract',
  SUP_AGREEMENT_CHANGE_VIDEOS_RATE_PERCENT: 'Change the rate percent video',
  SUP_AGREEMENT_CHANGE_FEEDS: 'Change the feeds',
  SUP_AGREEMENT_CHANGES: 'Changes',
  SUP_AGREEMENT_SIGNED_STATUS: 'Signed',
  SUP_AGREEMENT_ACTUAL_VIDEOS: 'Video changes',
  SUP_AGREEMENT_ACTUAL_CHANNELS: 'YouTube channels changes',
  SUP_AGREEMENT_ACTUAL_FEEDS: 'Feed changes',
  SUP_AGREEMENT_ACTUAL_EXPIRED_AT: 'Expires date of the contract changes',
  SUP_AGREEMENT_ACTUAL_VIDEO_RATE_PERCENT: 'Changing the rate percent of videos',
  DOWNLOAD_DOCX_CONTRACT_TEMPLATE: "Download contract's DOCX-template",
  DOWNLOAD_DOCX_SUP_AGREEMENT_TEMPLATE: "Download sup.agreement's DOCX-template",
  DOWNLOADS: 'Downloads',
  DOWNLOADING: 'Downloading',
  SUCCESS_DOWNLOAD_FILE: 'File downloaded successfully',
  MY_DOCUMENTS: 'My documents',
  MY_DOCUMENTS_MARKETING_DESCRIPTION:
    'In this section you will be able to download templates for contracts and supplementary agreements,\n' +
    'also scans of signed documents will be available here',

  APPLICATION_SETTINGS: 'Application settings',
  SUCCESS_UPDATE_SETTINGS: 'Settings was updated',
  ADS_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about new advertising requests',
  CONNECT_CHANNEL_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about connection requests',
  CHANGE_PAYMENT_REQUISITES_NOTICE_RECIPIENT: 'Recipient of notifications about changes in payment registers',
  PAYMENT_REQUEST_NOTICE_RECIPIENT: 'Recipient of notifications about payment requests',
  EXPIRING_CONTRACT_NOTIFY_USER: 'Recipient of expiring contracts notifications',
  SUPPORT_TICKET_NOTICE_RECIPIENT: 'Recipient of notifications about new support tickets',
  MC_PAY_INTEGRATION_NOTICE_RECIPIENT: 'Recipient of notifications on interaction with MC PAY',
  PUBLISH_VIDEOS_NOTICE_RECIPIENT: 'New video notification recipient',
  NEED_SEND_TRANSACTION_EMAIL_NOTIFICATION: 'Notifies users when transactions are created',
  TRANSACTION_OPERATIONS: 'Operations',
  TO_TRANSACTION: 'To transaction',
  PAYMENT_DOCUMENTS: 'Payment documents',
  CREATED_AT_PERIOD: 'Created at period',
  MY_ORGANIZATION: 'My Organization',
  SOME_ACTIONS_UNAVAILABLE: 'Some actions are currently unavailable',
  NEED_FILL_CONTRACTOR_FIELDS:
    'To take advantage of all the features of the platform, go to fill out the data of the organization.',
  SUP_AGREEMENT_SUCCESS_DELETED: 'Supplementary agreement has been successfully deleted',

  REPORTING_PERIODS: 'Reporting periods',
  REPORTING_PERIOD_STARTED_AT: 'Started at',
  REPORTING_PERIOD_EXPIRES_AT: 'Expires at',
  CONFIRM_OPEN_REPORTING_PERIOD: 'Are you sure you want to open a reporting period?',
  CONFIRM_CLOSE_REPORTING_PERIOD: 'Are you sure you want to close a reporting period?',
  OPEN: 'Open',
  USER_AGREEMENT: 'Terms of use',
  OTHER: 'Other',
  FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_TITLE: 'No financial transactions found',
  FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_DESCRIPTION:
    'When you have your first income or outcome, it will be displayed here',
  USERS_NOT_FOUND_MARKETING_TITLE: 'Users not found',
  USERS_NOT_FOUND_MARKETING_DESCRIPTION:
    'Send invitations encouraging you to join the StarPro network.\nRegistered users will be displayed here.',
  VIDEO_HISTORY_NOT_FOUND_MARKETING_TITLE: 'Not found',
  VIDEO_HISTORY_NOT_FOUND_MARKETING_DESCRIPTION: 'All actions that take place with the video will be displayed here',
  INFO: 'Info',
  POSITION: 'Position',
  SUCCESS_UPDATE_DATA: 'Data has been successfully updated',
  CHARTMETRIC_ID: 'Artist ID on Chartmetric.com',
  TOP_SONGWRITERS: 'Top songwriters',
  ARTIST_DATA_SOURCES: 'Artist Data Sources',
  ARTIST_RANKING: 'Artist Rankings',
  CM_RANK_shazam_count: 'Shazam',
  CM_RANK_GENRE: 'Genre',
  CM_RANK_cm_artist_rank: 'Artist rank',
  CM_RANK_engagement_rank: 'Engagement rank',
  CM_RANK_sp_followers: 'Spotify followers',
  CM_RANK_sp_popularity: 'Spotify popularity',
  CM_RANK_sp_monthly_listeners: 'Spotify monthly listeners',
  CM_RANK_youtube_monthly_video_views: 'YouTube monthly views',
  CM_RANK_tiktok_likes: 'TikTok likes',
  CM_RANK_tiktok_followers: 'TikTok subscribers',
  CM_RANK_twitch_monthly_viewer_hours: 'Twitch monthly streaming hours',
  NOT_APPLICABLE: 'N/A',
  SUMMARY_STATISTICS: 'Summary Statistics',
  MONTHLY_LISTENERS: 'Monthly listeners',
  POPULARITY: 'Popularity',
  PLAYLIST_REACH: 'Playlist reach',
  RANK_POSITIONS: 'Positions',
  LISTENERS: 'Listeners',
  PERCENT: 'Percent',
  ABSOLUTE_VALUES: 'Absolute values',
  CM_RANK_num_sp_editorial_playlists: 'Spotify playlists',
  CM_RANK_num_am_editorial_playlists: 'Apple Music playlists',
  CM_RANK_num_az_editorial_playlists: 'Amazon playlists',
  CM_RANK_num_de_editorial_playlists: 'Deezer playlists',
  TOP_TRACKS: 'Top tracks',
  PLAYLISTS: 'Playlists',
  PLAYLIST: 'Playlist',
  TRACKS: 'Tracks',
  TRACK: 'Track',
  SET_CHARTMETRIC_ID: 'Link the artist to Chartmetric',
  PARTNER_INTEGRATION: 'Integrations',

  NOT_FOUND_SELECT_VIDEO_WITH_ACTIVE_CONTRACT: 'Not found videos without an active contract',
  ADS: 'Ads',
  SUBSCRIPTION: 'YouTube Premium',
  TOTAL: 'Total',
  SUBSCRIBE_TO_CHARTMETRIC_NEWSLETTER: 'Subscribe to our weekly newsletter',
  UNSUBSCRIBE_FROM_CHARTMETRIC_NEWSLETTER: 'Unsubscribe from the weekly newsletter',
  LINK_WITH_ARTIST: 'Link to the artist',
  SUCCESS_LINKED_WITH_ARTIST: 'Link with the artist has been successfully updated',
  ADD_CONTRACT: 'Add contract',
  CONTRACTUAL_DOCUMENTS: 'Contractual documents',
  ATTACH_DOCUMENT: 'Attach document',
  CM_SCORE: 'Popularity score',
  LOAD_MORE: 'Load more',
  PLAYLISTS_INFO: 'Playlists info',
  SHOW: 'Show',
  NOT_FOUND_PLAYLISTS_INFO: "No information about the artist's playlists",
  BACKGROUND_PROCESSES: 'Background Processes',
  CONTRACTOR_OWNER: 'Contractor owner',
  DOWNLOAD_ACCOUNTS_BALANCE_REPORT: 'Generate Balance Report',
  SUCCESS_START_GENERATE_BALANCE_REPORT:
    'The report began to generate. An email will be sent to you when the generation is complete.',
  MEDIA_CUBE_PAY_PARTNER_MODAL_TITLE: 'mcpay.io - strong financial partner',
  MC_PAY_ERROR_CANT_GET_OAUTH_USER_INFORMATION:
    'Failed to get the data required to switch to mcpay.io, try again later',
  CASH_OUT: 'Cash out',
  SUCCESS: 'Success',
  MC_PAY_FILTER: 'MC PAY affiliation',
  MC_PAY: 'mcpay.io',
  FOR_MC_PAY: 'Integration with MCPAY',
  MC_PAY_STATUS: ' MCPAY request status',
  MC_PAY_STATUS_WAITING_SEND_TO_MC_PAY: 'Waiting send',
  NOT_FOR_MC_PAY: 'No integration with MCPAY',
  MC_PAY_STATUS_SUCCESS: 'Success',
  MC_PAY_STATUS_ERROR: 'Faulted',
  MC_PAY_REGISTER: 'Start using mcpay.io',
  MC_PAY_DESCRIPTION: 'Reliable payment system mcpay.io',
  MC_PAY_DESCRIPTION_LIST_ITEM_ONE: 'Instant money withdrawal',
  MC_PAY_DESCRIPTION_LIST_ITEM_SECOND: 'Access to the entire transaction history',
  MC_PAY_DESCRIPTION_LIST_ITEM_THIRD:
    'Withdrawal of funds in 12 different ways, including bank account and phone number',

  RIGHTS: 'Rights',
  ACTIVE_RIGHTS: 'Active Rights',
  EXPIRED_RIGHTS: 'Expired rights',
  DOESNT_HAVE_EXPIRED_DATE_RIGHTS: 'Unexpired rights',
  SEARCH: 'Search',

  MC_PAY_WITHDRAWAL_REQUEST: 'Confirmation of withdrawal to MC PAY',
  CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE: 'Are you sure you want to confirm sending transactions to MC PAY?',
  SUCCESS_CONFIRM_MC_PAY_OPERATIONS: 'Transactions will soon be sent to MC PAY',
  WITHDRAWAL_TO_MC_PAY_NO_OWNER_USER_ATTENTION: 'Sorry, interaction with the mcpay.io is only available to the manager',
  MC_PAY_INTEGRATION_TEMP_UNAVAILABLE:
    'Technical work is underway. Integration with mcpay.io is temporarily unavailable, try later',
  MC_PAY_INTEGRATION_ENABLE: 'Integration mcpay.io',
  INITIAL_CONTRACT_STATE: 'Initial',
  CURRENT_CONTRACT_STATE: 'Current',
  CONTRACT_IS_EXPIRED: 'Contract is expired',
  CONTRACT_STATE: 'Contract state',

  PAYMENT_REQUEST_DESTINATION: 'Destination',
  MC_PAY_DESCRIPTION_LIST_ITEM_FOURTH: 'Provider charges may apply',
  PN_TAX_INFO: 'Transfer fee',
  INPUT_PLACEHOLDER_PAYMENT_REQUEST_DESTINATION: 'Select the destination to which the money will go',
  SUCCESS_CONFIRM_PAYMENT_REQUEST: 'Payment request successfully approved',
  SUCCESS_REJECT_PAYMENT_REQUEST: 'Payment request successfully rejected',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.BY_REQUISITES]: 'By requisites',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.TO_MCPAY]: 'To mcpay.io',
  MC_PAY_ALREADY_REGISTER: 'Success Authorized',
  WITHDRAWAL_SUM_WITHOUT_FEE: 'Coming to your account',
  SP_TAX_INFO: 'Transfer fee',
  MC_PAY_PAYMENT_REQUEST: 'Payment request to mcpay.io',
  DETAILED_EXCEL_REPORT: 'Detailed report',
  GENERATE_DETAILED_EXCEL_REPORT_MODAL_TITLE: 'Generate detailed excel report',
  ADD_DATE: 'Added date',
  FETCH_CONTRACT_CHANNELS_STATE: 'load channels state',
  CHANGE_ALL_RATE_PERCENTS: 'General rate percent',
  CHANGE_ALL_RATE_PERCENTS_MODAL_TITLE: 'Specify the rate that will apply to all channels',
  FETCH_CONTRACT_CHANNELS_STATE_CONFIRMATION:
    'Are you sure you want to download the status of the channels at the current time?',

  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.is_change_youtube_channels]: 'Channels',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_FEEDS]: 'Feeds',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_VIDEO_RATE_PERCENT]: 'Rate percent',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_EXPIRES_AT]: 'Expires at',

  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.WISH]: 'Wish',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.PROBLEM]: 'Problem',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.QUESTION]: 'Question',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.OPEN]: 'Open',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.CLOSED]: 'Closed',

  ['ADS_REQUEST_STATUS_' + AdsStatus.IN_WORK]: 'In work',
  ['ADS_REQUEST_STATUS_' + AdsStatus.REJECTED]: 'Rejected',
  ['ADS_REQUEST_STATUS_' + AdsStatus.DONE]: 'Confirmed',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.IN_WORK]: 'In work',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.COMPLETE]: 'Complete',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.REJECT]: 'Reject',
  ['CONTRACT_STATUS_' + ContractStatus.ACTIVE]: 'Active',
  ['CONTRACT_STATUS_' + ContractStatus.EXPIRED]: 'Expired',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.IN_WORK]: 'In work',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.REJECTED]: 'Rejected',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.APPROVED]: 'Approved',
  ['TRANSACTION_STATUS_' + TransactionStatus.CONFIRMED]: 'Confirmed',
  ['TRANSACTION_STATUS_' + TransactionStatus.REJECTED]: 'Rejected',
  ['TRANSACTION_STATUS_' + TransactionStatus.WAITING_CONFIRM]: 'Waiting confirm',
  ['TRANSACTION_STATUS_' + TransactionStatus.CANCELED]: 'Canceled',

  ['ARTICLE_STATUS_' + ArticleStatus.DRAFT]: 'Draft',
  ['ARTICLE_STATUS_' + ArticleStatus.PUBLISHED]: 'Publish',

  ['VIDEO_STATUS_' + VideoStatus.DRAFT]: 'Draft',
  ['VIDEO_STATUS_' + VideoStatus.WAITING_PREPARING]: 'Waiting',
  ['VIDEO_STATUS_' + VideoStatus.PREPARING]: 'Preparing',
  ['VIDEO_STATUS_' + VideoStatus.PREPARING_ERROR]: 'Failed to prepare file',
  ['VIDEO_STATUS_' + VideoStatus.READY_TO_PUBLISH]: 'Ready to publish',
  ['VIDEO_STATUS_' + VideoStatus.PUBLISHED]: 'Published',

  ['VIDEO_ACTION_' + VideoActionType.CREATE]: 'Video created',
  ['VIDEO_ACTION_' + VideoActionType.DELETE]: 'Video deleted',
  ['VIDEO_ACTION_' + VideoActionType.UPDATE]: 'Video updated',
  ['VIDEO_ACTION_' + VideoActionType.SEND_TO_PUBLISH]: 'Video sent for publication',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_CREATE_WATERMARKED_FILES]: 'The system has generated files with watermarks',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_UPDATE_WATERMARKED_FILES]:
    'The administrator has updated the watermarked files',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_CONFIRM_PUBLISH]: 'The administrator confirmed the publication',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_CREATE_SUP_AGREEMENT]: 'Created additional sup agreement',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_REJECT_PUBLISH]: 'The administrator rejected the publication',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_FAULT_WHEN_CREATE_WATERMARKED_FILES]:
    'An error occurred while generating watermark files',

  ['CONTRACTOR_TYPE_' + ContractorType.LEGAL]: 'Legal entity',
  ['CONTRACTOR_TYPE_' + ContractorType.INDIVIDUAL]: 'Individual entity',
  ['CONTRACTOR_TYPE_' + ContractorType.ENTREPRENEUR]: 'Entrepreneur entity',

  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES]:
    'Requires the filling of details',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.APPROVED]: 'Approved',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.REJECTED]: 'Rejected',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM]: 'Waiting moderate',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS]: 'Correcting notes required',

  ['NOTE_mb-10' + ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM]:
    'Your information has been sent for verification and is awaiting moderation by the administrator',
  ['NOTE_UPDATE_CONTRACTOR_' + ContractorDataValidationStatus.REJECTED]: 'The administrator rejected the data changes',
  ['NOTE_UPDATE_CONTRACTOR_' + ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS]:
    'Remarks need to be corrected, for more information go <redirect>here</redirect>',

  ['FEED_' + Feed.YANDEX_ZEN]: 'Yandex Zen',
  ['FEED_' + Feed.YOUTUBE]: 'Youtube',
  ['FEED_' + Feed.BEELINE]: 'Beeline',
  ['FEED_' + Feed.RUTUBE]: 'Rutube',
  ['FEED_' + Feed.MTS]: 'MTS',
  ['FEED_' + Feed.OK]: 'OK',
  ['FEED_' + Feed.VK]: 'VK',
  ['FEED_' + Feed.TVIGLE]: 'Tvigle',
  ['FEED_' + Feed.MEGAFON]: 'Megafon',

  ['CONTRACT_TYPE_' + ContractType.YOUTUBE_PARTNER]: 'Youtube partner',
  ['CONTRACT_TYPE_' + ContractType.PUBLISH_VIDEO]: 'Publish video',

  ['REPORT_FREQUENCY_' + ReportFrequency.MONTHLY]: 'Monthly',
  ['REPORT_FREQUENCY_' + ReportFrequency.QUARTERLY]: 'Quarterly',

  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_VIDEO_SUMMARY]: 'Ads revenue → Video Summary',
  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_VIDEO_AGGREGATION]: 'Video → Video Report',
  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_RED_LABEL_RAWDATA_VIDEO]: 'Subscription revenue → Video Report',
  YANDEX_ZEN_REPORT: 'Yandex Zen Revenue',
  REPORT_SOURCE: 'Revenue report platform',
  REPORT_SOURCE_PLATFORM: 'Platform',
  ['FINANCIAL_REPORT_SOURCE_' + FinancialReportSource.YOUTUBE]: 'Youtube',
  ['FINANCIAL_REPORT_SOURCE_' + FinancialReportSource.YANDEX_ZEN]: 'Yandex Zen',

  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_WITHDRAWAL]: 'Create a debit transaction',
  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE]:
    'Create transaction for tax refund in Youtube',
  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION]:
    'Create an accrual transaction to our organization',

  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_WITHDRAWAL]:
    'Money from the internal account of the selected counterparty will be transferred to the external account of the counterparty',
  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE]:
    'Money from the internal account of the selected counterparty will be transferred to the internal account of the YouTube counterparty',
  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION]:
    "Money from the selected counterparty's domestic account will be transferred to the domestic account of the counterparty Video Content, LLC or Video Content, Inc",

  ['OPERATION_TYPE_' + TransactionOperationType.INNER_TRANSFER]: 'Internal Transfer',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL]: 'Withdrawal',
  ['OPERATION_TYPE_' + TransactionOperationType.ADJUSTMENT]: 'Adjustment',
  ['OPERATION_TYPE_' + TransactionOperationType.RETURN_YOUTUBE_TAX]: 'Youtube tax refund',
  ['OPERATION_TYPE_' + TransactionOperationType.PAYMENT_FROM_PLATFORM]: 'Payouts from platform',
  ['OPERATION_TYPE_' + TransactionOperationType.PAYMENT_FOR_SERVICE]: 'Payments from StarPro',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL_TO_MCPAY]: 'Withdrawal to MCPay',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL_TO_CACHE]: 'Withdrawal of money to internal account',

  [ReportsProcessingStatus.CREATED]: 'Created',
  [ReportsProcessingStatus.IN_WORK]: 'In work',
  [ReportsProcessingStatus.ERROR]: 'Error',
  [ReportsProcessingStatus.PROCESSED]: 'Processed',

  [DateFormat.DD_MM_YYYY]: 'DD.MM.YYYY',

  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.OPEN]: 'Open',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.CLOSED]: 'Closed',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.OPENING]: 'Opening',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.CLOSING]: 'Closing',

  [`ACCOUNT_TYPE_${FinancialAccountType.INNER}_${Currency.USD}`]: 'Inner dollar account',
  [`ACCOUNT_TYPE_${FinancialAccountType.INNER}_${Currency.RUB}`]: 'Internal ruble account',
  [`ACCOUNT_TYPE_${FinancialAccountType.CACHE}_${Currency.USD}`]: 'Withdrawal account in rubles',
  [`ACCOUNT_TYPE_${FinancialAccountType.CACHE}_${Currency.RUB}`]: 'Withdrawal account in dollars',
  [`ACCOUNT_TYPE_${FinancialAccountType.MC_PAY}_${Currency.USD}`]: 'Withdrawal to mcpay.io account in dollars',

  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.CONTRACT]: 'Contractor',
  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.SUP_AGREEMENT]: 'Supplementary agreement',
  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.FINANCIAL_DETAILS_REPORT]: 'Financial report',

  [`ARTIST_RANK_PERIOD_${ChartMetricArtistRankingPeriod.WEEKLY}`]: 'Past 7 days',
  [`ARTIST_RANK_PERIOD_${ChartMetricArtistRankingPeriod.MONTHLY}`]: 'Past 30 days',
  [`ARTIST_RANK_PERIOD_${ChartMetricArtistRankingPeriod.BIMONTHLY}`]: 'Past 60 days',
  SHORT_REFERRAL: 'Ref.',
  IS_REFERRAL_LINKED_OPERATION: "This operation links with referral",
  IS_REFERRAL_PAYMENT_OPERATION: 'Payment to referral',
  WITH_REFERRAL: 'Referrals only',
  WITHOUT_REFERRAL: 'Authors Only',
} as const;
