import {BaseEntity, EntityId} from '../base/BaseEntity';

export interface ContractorDocument extends BaseEntity {
  contractor_id: EntityId;
  contract_id: EntityId | null;
  sup_agreement_id: EntityId | null;
  title: string;
  type: ContractorDocumentType;
  file_name: string | null;
  file_extension: string | null;
}

export enum ContractorDocumentType {
  CONTRACT = 'CONTRACT',
  SUP_AGREEMENT = 'SUP_AGREEMENT',
  FINANCIAL_DETAILS_REPORT = 'FINANCIAL_DETAILS_REPORT',
}
