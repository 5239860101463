import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ChannelTable} from './channel-table';
import {YoutubeChannelInContract} from '../../../api/contract-api/Contract';
import {
  EntityToEditableRowConverter,
  RowEntity,
} from './contract-page/create-edit-contract-page/edit-contract-block/row';
import {SelectOption} from '../../../components/Inputs/InputMultiSelect';
import {InputText} from '../../../components/Inputs/InputText';
import {useIntl} from '../../../hooks/use-intl';
import {useLocalPaginator} from '../../../hooks/use-local-paginator';
import {useSelectApi} from '../../../hooks/use-select-api';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {PaginationComponent} from '../../../components/pagination';
import {IPaginationInfo} from '../../../api/Paginator';
import {DataSetValidationErrors, prepareDate} from '../../../utils/utils';
import {EntityId, float} from '../../../api/base/BaseEntity';
import {useComponentDidMount} from '../../../hooks/use-component-did-mount';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import cn from 'classnames';
import {ICONS} from '../../../images/images';
import {useContractsApi} from '../../../hooks/apis/use-contracts-api';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {useModalModalSetRatePercents} from '../../../components/modals/set-rate-percent-modal/modal-set-rate-percent';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useLoading} from '../../../hooks/use-loading';
import {prepareChannelDataFromResponse} from './sup-agreements/create-edit-sup-agreement-page';

type Props = {
  channels: YoutubeChannelInContract[];
  onSave?: (items: YoutubeChannelInContract[]) => void;
  privileges?: {canCreate?: boolean; canDelete?: boolean; canEditRatePercent?: boolean; canEdit?: boolean};
  ratePercentsMap?: Record<EntityId, float>;
  validationErrors?: DataSetValidationErrors<YoutubeChannelInContract> | null;
  showAdditionalButtons?: boolean;
};
export const ChannelsBlock: React.FC<Props> = ({
  channels,
  privileges,
  validationErrors,
  ratePercentsMap,
  showAdditionalButtons = false,
  onSave,
}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    channelsStates: false,
  });
  const {fetchSelectValuesByKey, selectsOptions} = useSelectApi();

  const params = useParams<any>();
  const {getContractStateOnDate} = useContractsApi();
  const [initialValues, setInitialValues] = useState<RowEntity<YoutubeChannelInContract>[]>(
    EntityToEditableRowConverter.convertAll(channels),
  );
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showSetRatePercentsModal} = useModalModalSetRatePercents();

  const [filter, setFilter] = useState<string | null>(null);
  const [selectCreateYoutubeChannel, setSelectCreateYoutubeChannel] = useState<SelectOption | null>(null);

  const filteredItems = useMemo<RowEntity<YoutubeChannelInContract>[]>(() => {
    if (filter == null || filter === '') {
      return initialValues;
    }

    return initialValues.filter(item => {
      const appliedFilter = filter?.toLocaleLowerCase() ?? '';
      return (
        item.y_channel_title.toLocaleLowerCase().includes(appliedFilter) ||
        item.y_channel_id.toString().toLocaleLowerCase().includes(appliedFilter)
      );
    });
  }, [initialValues, filter]);

  const availableSelectOptions = useMemo(() => {
    if (selectsOptions?.youtubeChannels == null) {
      return null;
    }

    return selectsOptions?.youtubeChannels?.filter(s => !initialValues.some(v => (v.y_channel_id as any) == s.value));
  }, [initialValues, selectsOptions?.youtubeChannels]);

  const {currentPageItems, currentPaginationInfo, getPageData, totalPagesCount} = useLocalPaginator(filteredItems, 10);

  useEffect(() => {
    if (selectsOptions?.youtubeChannels == null) {
      fetchSelectValuesByKey('youtubeChannels').then();
    }
  }, []);

  const isComponentDidMount = useComponentDidMount();
  // При изменении массива отправляем состояние в родительский компонент.
  useEffect(() => {
    if (onSave != null && isComponentDidMount) {
      onSave(initialValues.filter(c => !c.isDeleted));
    }
  }, [initialValues]);

  useEffect(() => {
    getPageData({page: 1}).then();
  }, [filter]);

  const handleFetchContractChannelsState = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (await showConfirmActionModal(intl.formatMessage({id: 'FETCH_CONTRACT_CHANNELS_STATE_CONFIRMATION'}))) {
        try {
          startLoading('channelsStates');
          const {item} = await getContractStateOnDate(params?.contractId as any, prepareDate(new Date()) as any);
          if (item != null) {
            setInitialValues(() => prepareChannelDataFromResponse(item.youtube_channels ?? []));
          }
        } catch (e) {
          toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        } finally {
          stopLoading('channelsStates');
        }
      }
    },
    [totalPagesCount],
  );

  const handleUpdateAllRatePercents = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const result = await showSetRatePercentsModal();
      if (result.reason === CloseModalReason.OK && result.payload?.ratePercent != null) {
        setInitialValues(
          [...initialValues].map(v => {
            const x = v;
            x.rate_percent = result.payload?.ratePercent as any;
            return x;
          }),
        );
      }
    },
    [initialValues],
  );

  const handleAddChannel = useCallback(
    (selectOption: SelectOption) => {
      setInitialValues(prev => [
        ...(prev ?? []),
        EntityToEditableRowConverter.convert(
          {
            y_channel_id: selectOption.value,
            y_channel_title: selectOption.label,
            is_referral: false,
            rate_percent: ratePercentsMap?.[selectOption.value] ?? 100,
          },
          {isCreated: true},
        ),
      ]);
      getPageData({page: totalPagesCount + 1}).then();
    },
    [totalPagesCount, ratePercentsMap],
  );

  const handleChangeSelectCreatedItem = useCallback(
    (value: number | string | null) => {
      const selectOption = selectsOptions?.youtubeChannels?.find(f => f.value === value) ?? null;
      setSelectCreateYoutubeChannel(selectOption);

      // HACK позволяющий избежать дерганья интерфейса
      if (selectOption != null) {
        setTimeout(() => handleAddChannel(selectOption), 0);
      }
    },
    [selectsOptions?.youtubeChannels, handleAddChannel],
  );

  const handleSaveRow = useCallback(
    (item: RowEntity<YoutubeChannelInContract>) => {
      const findIndex = initialValues.findIndex(c => c.y_channel_id == item.y_channel_id);
      if (findIndex == null || findIndex === -1) {
        return false;
      }

      if (item.isDeleted) {
        setInitialValues(prevState => [
          ...((prevState ?? []).slice(0, findIndex) ?? []),
          ...((prevState ?? []).slice(findIndex + 1) ?? []),
        ]);
      } else {
        setInitialValues(prevState => [
          ...(prevState ?? []).slice(0, findIndex),
          {
            ...item,
          },
          ...(prevState ?? []).slice(findIndex + 1),
        ]);
      }

      return true;
    },
    [initialValues],
  );

  return (
    <>
      {showAdditionalButtons && (
        <Toolbar
          items={[
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'FETCH_CONTRACT_CHANNELS_STATE'}),
              className: cn('btn btn-light-success'),
              loading: loadings.channelsStates,
              disabled: loadings.channelsStates,
              icon: loadings.channelsStates ? ICONS.SINGER : ICONS.RESET,
              onClick: e => handleFetchContractChannelsState(e),
            },
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'CHANGE_ALL_RATE_PERCENTS'}),
              className: cn('btn btn-light-success'),
              disabled: loadings.channelsStates,
              icon: ICONS.EDIT,
              onClick: e => handleUpdateAllRatePercents(e),
            },
          ]}
        />
      )}

      <InputText
        placeholder={intl.formatMessage({id: 'SEARCH'})}
        value={filter}
        classNames={'my-2 w-100'}
        onChange={e => setFilter(e.currentTarget.value)}
      />
      <ChannelTable
        channels={currentPageItems}
        privileges={privileges}
        validationErrors={validationErrors}
        onSave={handleSaveRow}
      />
      {(privileges?.canCreate === true || privileges?.canCreate == null) && (
        <div className={'d-inline-flex w-100 my-2 align-items-center'}>
          <InputSelect
            className={'w-100 mr-2'}
            isLoading={selectsOptions?.youtubeChannels == null}
            placeholder={intl.formatMessage({id: 'ADD'})}
            value={selectCreateYoutubeChannel?.value}
            options={availableSelectOptions ?? []}
            onChange={handleChangeSelectCreatedItem}
          />
        </div>
      )}

      {currentPaginationInfo != null && (
        <div className={'my-2'}>
          <PaginationComponent
            threshold={2}
            paginator={currentPaginationInfo as IPaginationInfo}
            onChange={page => getPageData({page})}
          />
        </div>
      )}
    </>
  );
};
