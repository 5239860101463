export enum FinancialReportSource {
  YOUTUBE = 'YOUTUBE',
  YANDEX_ZEN = 'YANDEX_ZEN',
}

export interface FinancialBulkReports {
  id: number;
  created_at: string;
  month: number;
  year: number;
  errorDescription: string | null;
  status: ReportsProcessingStatus;
  [REPORT_FILE_NAME.YANDEX_ZEN_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.YOUTUBE_RED_LABEL_RAWDATA_VIDEO_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.YOUTUBE_VIDEO_SUMMARY_FILE_NAME]: string | null;
  [REPORT_FILE_NAME.YOUTUBE_VIDEO_AGGREGATION_FILE_NAME]: string | null;
}

export enum FinancialReportType {
  YOUTUBE_VIDEO_AGGREGATION = 'YOUTUBE_VIDEO_AGGREGATION', // Видео -> Отчет о видео
  YOUTUBE_VIDEO_SUMMARY = 'YOUTUBE_VIDEO_SUMMARY', // Доход от рекламы -> Сводный отчет о видео
  YOUTUBE_RED_LABEL_RAWDATA_VIDEO = 'YOUTUBE_RED_LABEL_RAWDATA_VIDEO', // Доход от подписки -> Отчет о видео
  YANDEX_ZEN = 'YANDEX_ZEN', // Отчет Яндекс Дзен
}

export enum REPORT_FILE_NAME {
  YOUTUBE_VIDEO_SUMMARY_FILE_NAME = 'YOUTUBE_VIDEO_SUMMARY_FILE_NAME',
  YOUTUBE_VIDEO_AGGREGATION_FILE_NAME = 'YOUTUBE_VIDEO_AGGREGATION_FILE_NAME',
  YOUTUBE_RED_LABEL_RAWDATA_VIDEO_FILE_NAME = 'YOUTUBE_RED_LABEL_RAWDATA_VIDEO_FILE_NAME',
  YANDEX_ZEN_FILE_NAME = 'YANDEX_ZEN_FILE_NAME',
}

export enum ReportsProcessingStatus {
  CREATED = 'CREATED',
  IN_WORK = 'IN_WORK',
  ERROR = 'ERROR',
  PROCESSED = 'PROCESSED',
}
