import {useCallback, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Subscription} from '../../api/DTOs/Subscription';
import {Artist} from '../../api/DTOs/Artist';
import {User} from '../../api/DTOs/User';
import {EntityId} from '../../api/base/BaseEntity';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';

export type SubscriptionRelations = {
  user: User;
  artist: Artist;
};

export function useSubscriptionApi() {
  const {current: params} = useRef<EntityApiHookParams>({
    prefixUrl: 'subscriptions',
    isAdmin: false,
  });
  const entityApi = useEntityApi<Subscription, SubscriptionRelations>(params);
  return {
    ...entityApi,
    ...useAdditionalRoutes(params),
  };
}

export function useAdditionalRoutes(params: EntityApiHookParams) {
  const api = useBaseEndpointApi();
  const getByArtistId = useCallback(async (artistId: EntityId) => {
    return await api.get<BaseItemResponse<Subscription, SubscriptionRelations>>(
      `${params.prefixUrl}/artist/${artistId}`,
    );
  }, []);

  return {
    getByArtistId,
  };
}
