import {Dispatch, useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Contract, ContractState} from '../../api/contract-api/Contract';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {DateAsString, EntityId} from '../../api/base/BaseEntity';
import {IResponseBase} from '../../api/response-contracts/base-response';
import {ValidationErrorsType} from '../../utils/utils';
import {isValidationException} from '../../utils/api-utils';
import {Contractor} from '../../api/contractor-api/Contractor';
import {SupAgreement} from '../../api/DTOs/SupAgreement';
import {BaseGetEntityResponse} from '../../api/base/base-crud-api-response';
import config from '../../../configs/config';
import {ContractualDocument} from './use-contractual-documents-api';

export type ContractRelation = {
  supAgreements: Array<SupAgreement>;
  ourContractor: Contractor;
  contractor: Contractor;
  contractualDocumentFiles: Array<ContractualDocument>;
};

export type ContractPrivileges = {
  can_edit: boolean;
  can_delete: boolean;
};

export function useContractsApi() {
  const config = useRef<EntityApiHookParams>({
    prefixUrl: 'contracts',
    isAdmin: false,
    uploadConfig: {
      signedUrlRoute: 'docs-pre-signed-url',
    },
  });
  const api = useEntityApi<Contract, ContractRelation, ContractPrivileges>(config.current);
  return {
    ...api,
    ...useAdditionalRoutes(config.current, api.setValidationErrors),
  };
}

const useAdditionalRoutes = (params: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) => {
  const api = useBaseEndpointApi();
  const registerConfirm = async (id: EntityId) => {
    return (await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-confirm`)).data;
  };

  const registerReject = async (id: EntityId, reject_reason: string) => {
    try {
      return (
        await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-reject`, {
          fields: {
            reject_reason,
          },
        })
      ).data;
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  const registerNeedRequirements = async (id: EntityId, requirements: string) => {
    try {
      return (
        await api.post<IResponseBase>(`${params.prefixUrl}/${id}/register-need-fix-requirements`, {
          fields: {
            requirements,
          },
        })
      ).data;
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  const getContractStateOnDate = async (id: EntityId, date: DateAsString | Date) => {
    try {
      const res = await api.get<BaseGetEntityResponse<ContractState|null>>(
        `${params.prefixUrl}/${id}/state-on-date?date=${date}`,
      );
      return res.data;
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  const generateContractTemplate = async (id: EntityId) => {
    try {
      return await api.getFile(`${config.BASE_API_URL}/${params.prefixUrl}/${id}/generate-template`);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  return {
    registerConfirm,
    registerReject,
    registerNeedRequirements,
    getContractStateOnDate,
    generateContractTemplate,
  };
};
