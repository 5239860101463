import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {isEqual, trimStringWithEllipsis} from '../../utils/utils';
import {useIntl} from '../../hooks/use-intl';
import {usePagesConfiguration} from '../../../configs/pages-configuration-context';
import {PageConfiguration} from '../../../configs/pages-configuration';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapSizes} from '../../../styles/bootstap-colors';
import {STRING} from '../../consts';

interface IBreadcrumbsContext {
  routes: Array<any>;
  excludePaths: Array<string>;
  needFetchBreadcrumbsParams: boolean;
  breadcrumbsParams: Array<string | number | null | undefined>;

  setBreadcrumb(
    breadcrumbParams: string | number | Array<string | null | number | undefined> | null | undefined,
    maxLength?: number,
  ): void;

  resetBreadcrumb(): void;
}

// @ts-ignore
const BreadcrumbsContext = createContext<IBreadcrumbsContext>();

export const BreadcrumbsProvider = ({children}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const {pagesConfigurationsWithFlattenGroups: pagesConfigurations} = usePagesConfiguration();
  const [breadcrumbParams, setBreadcrumbParams] = useState<Array<string | number | null | undefined>>([]);

  const getFlatConfigurations = (configuration: PageConfiguration): Array<PageConfiguration> => {
    const elements: Array<PageConfiguration> = [];
    if (configuration.depends) {
      configuration.depends.forEach(ch => {
        elements.push(...getFlatConfigurations(ch));
      });
    }

    const hasPrivileges = configuration.available;
    if (hasPrivileges) {
      elements.push(configuration);
    }
    return elements;
  };

  const staticRoutes = useMemo(
    () => [
      ...pagesConfigurations.pages
        .reduce((acc, conf) => {
          return [...acc, ...getFlatConfigurations(conf)];
        }, [] as PageConfiguration[])
        .filter(conf => conf?.breadcrumbs === undefined || conf?.breadcrumbs?.isDynamic === false)
        .map(conf => {
          return {
            path: conf.to,
            breadcrumb: intl.formatMessage({
              id: conf?.breadcrumbs?.title ?? (conf.showInAside ? conf.title : 'ERROR'),
            }),
          };
        }),
      {path: '*/videos', breadcrumb: intl.formatMessage({id: 'VIDEOS'})},
      {path: '*/create', breadcrumb: intl.formatMessage({id: 'CREATE'})},
      {path: '*/edit', breadcrumb: intl.formatMessage({id: 'EDIT'})},
      {path: '*/create-sup-agreements', breadcrumb: intl.formatMessage({id: 'CREATE_SUP_AGREEMENT'})},
    ],
    [pagesConfigurations],
  );

  const dynamicRoutes = useMemo(() => {
    return [
      ...pagesConfigurations.pages
        .reduce((acc, conf) => {
          return [...acc, ...getFlatConfigurations(conf)];
        }, [] as PageConfiguration[])
        .filter(conf => conf?.breadcrumbs?.isDynamic === true)
        .map(conf => {
          const routeNestedLevel = conf.to.replaceAll(/[^:]/gi, STRING.EMPTY).length;
          return {
            path: conf.to,
            breadcrumb:
              breadcrumbParams[routeNestedLevel - 1]?.toString() ??
              (() => <Svg spin src={ICONS.SPINNER} size={BootstrapSizes.MD} />),
          };
        }),
    ];
  }, [breadcrumbParams, pagesConfigurations]);

  const excludesPaths = useMemo(() => ['/admin'], []);
  const routes = useMemo(() => [...staticRoutes, ...dynamicRoutes], [dynamicRoutes]);
  const staticRoutesPaths = useMemo(() => [...staticRoutes.map(r => r.path)], [staticRoutes]);

  useEffect(() => {
    const currentRouteIsStatic = staticRoutesPaths.includes(history.location.pathname);
    if (currentRouteIsStatic) {
      resetBreadcrumbs();
    }
  }, [history.location.pathname]);

  const resetBreadcrumbs = useCallback(() => {
    setBreadcrumbParams([null]);
  }, [setBreadcrumbParams]);

  const value: IBreadcrumbsContext = {
    routes: routes,
    excludePaths: excludesPaths,
    needFetchBreadcrumbsParams: breadcrumbParams?.length === 0 || breadcrumbParams.some(param => param == null),
    breadcrumbsParams: breadcrumbParams,
    setBreadcrumb(params, maxLength) {
      const paramsAsArray = Array.isArray(params) ? params : [params];
      if (!isEqual(paramsAsArray, breadcrumbParams)) {
        if (maxLength !== undefined) {
          setBreadcrumbParams(paramsAsArray.map(p => trimStringWithEllipsis(p?.toString() ?? '', maxLength)));
        } else {
          setBreadcrumbParams(paramsAsArray);
        }
      }
    },
    resetBreadcrumb: resetBreadcrumbs,
  };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

export const useCustomBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};
