import React from 'react';
import cn from 'classnames';
import {BootstrapColors} from '../../../styles/bootstap-colors';

type Size = 'lg' | 'sm';

interface InputSwitchProps {
  id?: string;
  disabled?: boolean;
  checked?: boolean;
  size: Size;
  type?: 'outline';
  color: BootstrapColors;
  className?: string;
  labelClassName?: string;
  labels?: [string, string];

  onChange(e?: React.SyntheticEvent): void;
}

export const InputSwitch = ({
  id,
  checked,
  size,
  color,
  type,
  onChange,
  className,
  labelClassName,
  labels,
  disabled,
}: InputSwitchProps) => {
  return (
    <div className={cn('d-flex align-items-center', className)}>
      {labels && labels.length > 0 && labels[0].length > 0 && (
        <span className={cn('mr-2 font-weight-bolder', labelClassName)}>{labels[0]}</span>
      )}
      <span
        className={cn(`switch switch-${color} switch-${size}`, {
          ['switch-outline']: type === 'outline',
        })}>
        <label>
          <input id={id} type='checkbox' checked={checked} disabled={disabled} onChange={onChange} />
          <span />
        </label>
      </span>
      {labels && labels.length > 1 && (
        <span className={cn('ml-2 font-weight-bolder', labelClassName)}>{labels[1]}</span>
      )}
    </div>
  );
};
