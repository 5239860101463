import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {User, UserType} from '../../../api/DTOs/User';
import {InputText} from '../../Inputs/InputText';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {PartialNullable} from '../../../../types/types';
import {ProfileInformationFields} from '../../../pages/profile/profile-information-fields';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {AllSelectOptions, SelectOptionsLoadings} from '../../../hooks/use-select-api';

export type CreateUserFormFields = User & {password_confirmation: string; password: string};

interface IModalCreateUserProps {
  visible: boolean;
  fieldsData: PartialNullable<CreateUserFormFields>;
  error?: string | null;
  validationErrors?: ValidationErrorsType<CreateUserFormFields>;
  updateFields: UpdateFields<CreateUserFormFields>;
  selectOptionsLoading: SelectOptionsLoadings;
  selectOptions?: AllSelectOptions;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateUser: React.FC<IModalCreateUserProps> = ({
  visible,
  error,
  fieldsData,
  updateFields,
  validationErrors,
  selectOptionsLoading,
  selectOptions,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CREATE_USER'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ProfileInformationFields
                fieldsData={fieldsData}
                validationErrors={validationErrors}
                updateFields={updateFields}
                options={selectOptions}
                selectOptionsLoading={selectOptionsLoading}
              />

              <ValidateErrorWrapper message={getValidationErrorMessage('password', validationErrors)}>
                <InputText
                  required
                  value={fieldsData.password}
                  type={'password'}
                  label={intl.formatMessage({id: 'PASSWORD'})}
                  hasError={!!getValidationErrorMessage('password', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => updateFields({password: e.currentTarget.value})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('password_confirmation', validationErrors)}>
                <InputText
                  required
                  value={fieldsData.password_confirmation}
                  type={'password'}
                  label={intl.formatMessage({id: 'PASSWORD_CONFIRMATION'})}
                  hasError={!!getValidationErrorMessage('password_confirmation', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => updateFields({password_confirmation: e.currentTarget.value})}
                />
              </ValidateErrorWrapper>

              <InputCheckbox
                label={intl.formatMessage({id: 'ADMIN_PRIVILEGES'})}
                value={fieldsData.type === UserType.ADMIN}
                onChange={() =>
                  updateFields({type: fieldsData.type === UserType.ADMIN ? UserType.USER : UserType.ADMIN})
                }
              />
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
