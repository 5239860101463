import React from 'react';
import './InputSelect.scss';
import {LabelWithDescription} from './LabelWithDescription';
import {useIntl} from '../../hooks/use-intl';
import AsyncSelect from 'react-select/async';
import {SelectOptions} from './InputSelect';
import {SelectOption} from './InputMultiSelect';

interface Props {
  placeholder?: string;
  id?: string;
  label?: string;
  descriptionLabel?: string;
  ariaLabel?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  value?: string | number | null;
  loadOptions: (inputValue: string, callback: (options: SelectOptions) => void) => Promise<SelectOptions> | void;
  defaultValue?: string | number | null;
  defaultOptions?: SelectOptions | boolean;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  noOptionMessage?: string;
  formatOptionLabel?: (option: SelectOption, metadata: {context: 'value' | 'menu'}) => React.ReactNode;

  onChange(value: string | number | null, id: string | undefined): void;
}

export const InputAsyncSelect = ({
  id,
  defaultValue,
  defaultOptions,
  isClearable,
  descriptionLabel,
  isLoading,
  placeholder,
  ariaLabel,
  onChange,
  className,
  hasError,
  loadOptions,
  label,
  value,
  formatOptionLabel,
  required,
  disabled,
  noOptionMessage,
}: Props) => {
  const onChangeInternal = (e: any) => {
    onChange(e?.value ?? null, id);
  };

  const intl = useIntl();
  return (
    <div className={className + ' select-container'}>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <AsyncSelect
        cacheOptions
        defaultValue={
          (defaultValue && Array.isArray(defaultOptions) && defaultOptions?.filter(item => item.value == value)) || null
        }
        defaultOptions={defaultOptions}
        classNamePrefix={'react-select'}
        aria-label={ariaLabel}
        loadOptions={loadOptions}
        noOptionsMessage={() => noOptionMessage ?? intl.formatMessage({id: 'NOT_FOUND'})}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        isDisabled={disabled}
        menuPosition={'fixed'}
        onChange={onChangeInternal}
        styles={{
          control: styles => ({
            ...styles,
            ...(hasError
              ? {
                  borderColor: '#DC3545 !important',
                  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
                }
              : {}),
          }),
        }}
      />
    </div>
  );
};
