import {Link} from 'react-router-dom';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import SVG from 'react-inlinesvg';

const AUTH_BACKGROUND = require('../../../images/backgrounds/auth-background.jpg');
const APP_LOGO = require('../../../images/svg/starpro-logo.svg');

export const AuthAside = () => {
  return (
    <div
      className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
      style={{
        backgroundImage: `url(${AUTH_BACKGROUND})`,
      }}>
      <div className='d-flex flex-row-fluid flex-column justify-content-between'>
        <Link to='/' className='flex-column-auto mt-5 pb-lg-0 pb-10'>
          <SVG alt='logo' width='100%' className='max-h-100px' src={APP_LOGO} />
        </Link>

        <div className='flex-column-fluid d-flex flex-column justify-content-center text-center'>
          <h3 className='font-size-h1 mb-5 text-white'>
            <FormattedMessage id='AUTH.GENERAL.WELCOME' />
          </h3>
          <p className='font-size-h3 font-weight-lighter text-white opacity-80'>
            <FormattedMessage id='AUTH.GENERAL.WELCOME_SUBTITLE' />
          </p>
        </div>
      </div>
    </div>
  );
};
