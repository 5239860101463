import React from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import './modal-play-video.scss';

interface IModalPlayVideoProps {
  visible: boolean;
  title: string | null;
  url: string | null;
  as: 'video' | 'frame' | null;

  onHide(): void;
}

export const ModalPlayVideo: React.FC<IModalPlayVideoProps> = ({visible, url, title, as, onHide}) => {
  const renderVideo = () => {
    if (as === 'video') {
      return <video controls className={'w-100'} src={url || ''} />;
    }

    if (as === 'frame') {
      return <iframe frameBorder='0' scrolling='no' width='100%' height='100%' src={url || ''} />;
    }

    return null;
  };
  return (
    <>
      <BaseModal size={'xl'} visible={visible} onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>{title}</Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <div className={'d-flex justify-content-center video-container'}>{renderVideo()}</div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
