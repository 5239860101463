import React, {createContext, PropsWithChildren, useCallback, useContext} from 'react';
import {useIntl} from '../../../hooks/use-intl';
import {BaseModal, ModalBodySlot} from '../base-modal/base-modal';
import {useAuth} from '../../../hooks/use-auth';
import {ILLUSTRATIONS} from '../../../images/images';
import {useHistory} from 'react-router-dom';
import {CommonRoutes} from '../../../../configs/routes';

// @ts-ignore
const ModalFillContractorFieldsAlert = createContext<any>();

export const ModalFillContractorFieldsAlertProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const history = useHistory();
  const {visibleFillContractorFieldsAlert, changeVisibleFillContractorFieldsAlert} = useAuth();

  const handleSuccessClick = useCallback(() => {
    changeVisibleFillContractorFieldsAlert(false);
    history.push(CommonRoutes.getMyOrganizationEditRoute());
  }, []);

  const handleHideClick = useCallback(() => {
    changeVisibleFillContractorFieldsAlert(false);
    history.goBack();
  }, []);

  return (
    <ModalFillContractorFieldsAlert.Provider value={{}}>
      {children}
      <BaseModal
        hideHeader
        backdrop={'static'}
        hideButtonTitle={intl.formatMessage({id: 'GO_BACK'})}
        successButtonTitle={intl.formatMessage({id: 'GO'})}
        visible={visibleFillContractorFieldsAlert}
        onSuccess={handleSuccessClick}
        onHide={handleHideClick}>
        <ModalBodySlot>
          <p className={'font-size-h4 font-weight-bolder m-0'}>
            {intl.formatMessage({id: 'SOME_ACTIONS_UNAVAILABLE'})}
          </p>
          <p className={'text-muted font-size-lg mb-0 mt-4'}>
            {intl.formatMessage({id: 'NEED_FILL_CONTRACTOR_FIELDS'})}
          </p>
          <div className='text-center'>
            <img style={{maxWidth: '70%'}} src={ILLUSTRATIONS.CONTRACTS} alt='Question Picture' />
          </div>
        </ModalBodySlot>
      </BaseModal>
    </ModalFillContractorFieldsAlert.Provider>
  );
};

export const useModalCreateEditInviteUserRequest = () => {
  return useContext(ModalFillContractorFieldsAlert);
};
