import {useIntl} from '../../../../hooks/use-intl';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../../base-modal/CloseModalEvent';
import {PartialNullable} from '../../../../../types/types';
import {EXCEPTION_TYPE} from '../../../../api/exceptions/BaseException';
import {ValidationException} from '../../../../api/exceptions/ValidationException';
import {useAdvancedState} from '../../../../hooks/use-advanced-state';
import {ApiRequestException} from '../../../../api/axios-instance';
import {
  CreateReturnYoutubeTaxToYoutubeContractorFields,
  useTransactionsApi,
} from '../../../../hooks/apis/use-transactions-api';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {EntityId} from '../../../../api/base/BaseEntity';
import {ModalCreateReturnTaxToYoutubeTransaction} from './modal-create-return-youtube-tax-transaction';
import {YoutubeChannelsApi} from '../../../../api/youtube-channels-api';

export type PartOfContractorForCreateTransaction = Pick<Contractor, 'id' | 'calculated_name'>;

interface ContextProps {
  showCreateReturnTaxToYoutubeTransactionModal(
    partOfContractor?: PartOfContractorForCreateTransaction,
  ): Promise<CloseModalEvent<EntityId>>;
}

// @ts-ignore
const ModalCreateTransactionContext = React.createContext<ContextProps>();

let closeResolver: ((data: CloseModalEvent<EntityId>) => unknown) | null = null;
export const ModalCreateReturnTaxToYoutubeTransactionProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const api = useTransactionsApi();
  const channelApi = new YoutubeChannelsApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [entity, , updateEntityFields, resetEntity] = useAdvancedState<
    PartialNullable<CreateReturnYoutubeTaxToYoutubeContractorFields>
  >({}, api.setValidationErrors);

  useEffect(() => {
    if (entity.y_channel_id) {
      channelApi.getChannelInfo(entity.y_channel_id).then(res => {
        updateEntityFields({contractor_id: res.data.relations?.contractor?.id});
      });
    }
  }, [entity.y_channel_id]);

  const showModal = async (partOfContractor?: PartOfContractorForCreateTransaction) => {
    setVisible(true);
    if (partOfContractor) {
      updateEntityFields({contractor_id: partOfContractor.id});
    }

    return new Promise<CloseModalEvent<EntityId>>(resolve => {
      fetchSelectValuesByKey('contractors');
      fetchSelectValuesByKey('youtubeChannels');
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    api.setValidationErrors(null);
    resetEntity();
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      api.setValidationErrors(null);
      const result = await api.createTransactionReturnYoutubeTaxToYoutube({
        ...entity,
        period_month: entity?.period_month != null ? entity.period_month + 1 : null,
      });
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        api.setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const contextValue: ContextProps = {
    showCreateReturnTaxToYoutubeTransactionModal: showModal,
  };

  return (
    <ModalCreateTransactionContext.Provider value={contextValue}>
      {children}
      <ModalCreateReturnTaxToYoutubeTransaction
        visible={visible}
        loadings={{
          channels: optionsLoadings.youtubeChannels,
          contractors: optionsLoadings.contractors,
          contracts: false,
        }}
        error={error}
        channels={selectsOptions.youtubeChannels}
        validationErrors={api.validationErrors}
        contractors={selectsOptions.contractors}
        entity={entity}
        onChange={updateEntityFields}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalCreateTransactionContext.Provider>
  );
};

export const useModalCreateReturnTaxToYoutubeTransaction = (): ContextProps => {
  return useContext(ModalCreateTransactionContext);
};
