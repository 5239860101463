import React from 'react';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';
import {PartialNullable, UpdateEntity} from '../../../../../../../types/types';
import {Separator} from '../../../../../../components/separator';
import {InputCheckbox} from "../../../../../../components/Inputs/InputCheckbox";

type Props = {
  validationErrors: ValidationErrorsType<Contractor>;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateEntity<Contractor>;
};

export const EntrepreneurFields: React.FC<Props> = ({contractor, validationErrors, updateFields}) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('entrepreneur_name_last', validationErrors)}>
        <InputText
          required
          value={contractor?.entrepreneur_name_last}
          label={intl.formatMessage({id: 'LAST_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_last', validationErrors)}
          onChange={e => updateFields({entrepreneur_name_last: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LAST_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('entrepreneur_name_first', validationErrors)}>
            <InputText
              required
              value={contractor?.entrepreneur_name_first}
              label={intl.formatMessage({id: 'FIRST_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_first', validationErrors)}
              onChange={e => updateFields({entrepreneur_name_first: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('entrepreneur_name_middle', validationErrors)}>
            <InputText
              value={contractor?.entrepreneur_name_middle}
              label={intl.formatMessage({id: 'MIDDLE_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_middle', validationErrors)}
              onChange={e => updateFields({entrepreneur_name_middle: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>

      <Separator className={'my-5'}/>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('entrepreneur_inn', validationErrors)}>
            <InputText
              mask={'999999999999'}
              value={contractor?.entrepreneur_inn}
              label={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_inn', validationErrors)}
              onChange={e => updateFields({entrepreneur_inn: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'TAX_PAYER_NUMBER'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>

        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('entrepreneur_ogrnip', validationErrors)}>
            <InputText
              value={contractor?.entrepreneur_ogrnip}
              label={intl.formatMessage({id: 'OGRNIP'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_ogrnip', validationErrors)}
              onChange={e => updateFields({entrepreneur_ogrnip: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'OGRNIP'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>
      <div>
        <div style={{marginBottom: '10px'}}>
          <InputCheckbox
            label={intl.formatMessage({id: 'VAT_PAYER'})}
            value={contractor.vat != null}
            onChange={() => updateFields({
              vat_payer: !(contractor.vat_payer ?? false),
              vat: null
            })}
          />
        </div>

        <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('vat', validationErrors)}>
          <InputText
            value={contractor?.vat != null ? (contractor?.vat * 100).toString() : null}
            type={'number'}
            inputMode={'numeric'}
            disabled={!contractor.vat_payer}
            step={1}
            minNumber={0}
            maxNumber={100}
            hasError={!!getValidationErrorMessage<Contractor>('vat', validationErrors)}
            onChange={e => {
              updateFields({vat: e.currentTarget.value / 100})
            }}
            placeholder={intl.formatMessage({id: 'VAT'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>
      </div>
      <div>
        <ValidateErrorWrapper message={getValidationErrorMessage('entrepreneur_address', validationErrors)}>
          <InputText
            value={contractor?.entrepreneur_address}
            label={intl.formatMessage({id: 'ADDRESS'})}
            hasError={!!getValidationErrorMessage('entrepreneur_address', validationErrors)}
            onChange={e => updateFields({entrepreneur_address: e.currentTarget.value})}
            placeholder={intl.formatMessage({id: 'ADDRESS'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>
      </div>
    </>
  );
}
