import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {Artist} from '../../../api/DTOs/Artist';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {ImageUploader} from '../../image-uploader';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {PartialNullable} from '../../../../types/types';
import {AttachmentMetadata} from '../../file-uploader';
import {InputSelect, SelectOptions} from '../../Inputs/InputSelect';
import {Preloader} from '../../preloader';
import {ImageRatios, validateRatioOfSide} from '../../../utils/file-utils';

interface IModalProps {
  openType: 'CREATE' | 'EDIT';
  loadings: Record<'modal' | 'preview' | 'contractors', boolean>;
  metadata: AttachmentMetadata | null;
  onChangeArtistImage: (image: File | null, metadata: AttachmentMetadata | null) => void;
  visible: boolean;
  errors?: string | null;
  contractors?: SelectOptions;
  validationErrors?: ValidationErrorsType;
  entity: PartialNullable<Artist>;
  canSelectContractor: boolean;
  onChange: (key: keyof Artist, value: any) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateEditArtist: React.FC<IModalProps> = ({
  openType,
  loadings,
  metadata,
  onChangeArtistImage,
  visible,
  errors,
  entity,
  canSelectContractor,
  contractors,
  onChange,
  validationErrors,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => {
    return (
      <>
        <form className={'form'}>
          <AlertCustom
            text={errors || ''}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
            visible={errors != undefined}
          />

          <div className='form-group'>
            {loadings.preview ? (
              <Preloader />
            ) : (
              <ImageUploader
                showPreview
                descriptionLabel={intl.formatMessage({id: 'ARTIST_IMAGE_UPLOAD_NOTE'})}
                acceptFileExtension={['.jpeg', '.jpg']}
                previewUrl={metadata?.url ?? null}
                dropdownZoneClassName={'mb-5'}
                label={intl.formatMessage({id: 'ARTIST_LOGO'})}
                onUploadImage={onChangeArtistImage}
                validateFile={file => validateRatioOfSide(file, ImageRatios.RATIO_1_1)}
              />
            )}

            <ValidateErrorWrapper message={getValidationErrorMessage('name', validationErrors)}>
              <InputText
                required
                value={entity.name}
                label={intl.formatMessage({id: 'NAME'})}
                hasError={!!getValidationErrorMessage('name', validationErrors)}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => onChange('name', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>

            {canSelectContractor && (
              <>
                <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
                  <InputSelect
                    required
                    isLoading={loadings.contractors}
                    value={entity.contractor_id}
                    placeholder={''}
                    label={intl.formatMessage({id: 'CONTRACTOR'})}
                    options={contractors ?? []}
                    hasError={!!getValidationErrorMessage('contractor_id', validationErrors)}
                    onChange={value => onChange('contractor_id', value)}
                  />
                </ValidateErrorWrapper>
              </>
            )}
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={`${openType}_ARTIST`} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>{loadings.modal ? <Preloader /> : renderForm()}</ModalBodySlot>
      </BaseModal>
    </>
  );
};
