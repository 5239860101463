import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {CloseModalEvent, CloseModalReason, OpenModalPayload} from '../base-modal/CloseModalEvent';
import {ModalCreateEditInviteUser} from './invite-user-modal';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {useIntl} from '../../../hooks/use-intl';
import {PartialNullable} from '../../../../types/types';
import {useUserApi} from '../../../hooks/apis/use-user-api';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {UserType} from '../../../api/DTOs/User';
import {useSelectApi} from '../../../hooks/use-select-api';
import {useAuth} from '../../../hooks/use-auth';
import {EntityId} from '../../../api/base/BaseEntity';

export type InviteUserRequest = {
  email: string;
  user_type: UserType;
  contractor_id: EntityId | null;
  y_channel_id: string | null;
  allow_access_to_finance: boolean;
};

interface IModalCreateEditInviteUserRequestProps {
  modalCreateEditInviteUserRequestVisible: boolean;

  showCreateEditInviteUserRequestModal(payload: OpenModalPayload<InviteUserRequest>): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateEditInviteUserRequestContext = createContext<IModalCreateEditInviteUserRequestProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalCreateEditInviteUserRequestProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const api = useUserApi();
  const selectApi = useSelectApi();

  const {isAdmin} = useAuth();

  const [error, setError] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [entity, setEntity, updateEntityFields] = useAdvancedState<PartialNullable<InviteUserRequest>>(
    {
      allow_access_to_finance: false,
    },
    api.setValidationErrors,
  );
  const [, setOpenPayload] = useState<OpenModalPayload<InviteUserRequest> | null>(null);

  useEffect(() => {
    if (visible) {
      const Promises = [selectApi.fetchSelectValuesByKey('youtubeChannels', {active_contract: 'HAS_CONTRACT'})];
      if (isAdmin) {
        Promises.push(selectApi.fetchSelectValuesByKey('contractors'));
      }

      Promise.all(Promises).then().finally();
    }
  }, [visible]);

  const showModal = async (openPayload: OpenModalPayload<InviteUserRequest>) => {
    setVisible(true);
    setOpenPayload(openPayload);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setEntity({allow_access_to_finance: false});
    setOpenPayload(null);
    setError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    setError(null);
    api.setValidationErrors(null);
    try {
      await api.sendInviteToEmail(entity);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalCreateEditInviteUserRequestProps = {
    modalCreateEditInviteUserRequestVisible: visible,
    showCreateEditInviteUserRequestModal: showModal,
  };

  return (
    <ModalCreateEditInviteUserRequestContext.Provider value={value}>
      {children}
      <ModalCreateEditInviteUser
        visible={visible}
        errors={error}
        showContractorSelect={isAdmin}
        channels={selectApi.selectsOptions?.youtubeChannels}
        contractors={selectApi.selectsOptions?.contractors}
        entity={entity}
        validationErrors={api.validationErrors}
        onChange={(key, value) => updateEntityFields({[key]: value})}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditInviteUserRequestContext.Provider>
  );
};

export const useModalCreateEditInviteUserRequest = () => {
  return useContext(ModalCreateEditInviteUserRequestContext);
};
