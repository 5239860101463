import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {MailingsApi} from '../../../api/mailing-api/mailings-api';
import {IMailingDto} from '../../../api/mailing-api/IMailingDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {Preloader} from '../../../components/preloader';
import SVG from 'react-inlinesvg';
import {useModalChooseUsers} from '../../../components/modals/choose-users/modal-choose-user-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {toast} from 'react-toastify';
import {IMailingPreviewDto} from '../../../api/mailing-api/IMailingPreviewDto';
import {useFitIframe} from '../../../hooks/use-fit-iframe';
import {AdminRoutes} from '../../../../configs/routes';
import {ErrorStub} from '../../../components/error-stub';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {AppLocale} from '../../../../localization';
import {ICONS} from '../../../images/images';

export const MailingPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const mailingId = Number(match.params.id);

  const api = new MailingsApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mailing, setMailing] = useState<IMailingDto | null>(null);
  const [previews, setPreviews] = useState<IMailingPreviewDto | null>(null);

  const {showChooseUsersModal} = useModalChooseUsers();
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {showConfirmActionModal} = useModalConfirmAction();

  const [ruPreviewRef, handleRuPreviewLoaded, ruFrameContentHeight] = useFitIframe();
  const [enPreviewRef, handleEnPreviewLoaded, enFrameContentHeight] = useFitIframe();

  useEffect(() => {
    fetchMailing();
  }, []);

  useEffect(() => {
    if (mailing) {
      fetchPreviews();
    }
  }, [mailing]);

  useEffect(() => {
    if (mailing?.subject_en || mailing?.subject_ru) {
      const localizedSubject =
        intl.locale === AppLocale.EN && mailing.subject_en ? mailing.subject_en : mailing.subject_ru;
      setBreadcrumb(localizedSubject, 40);
    }
  }, [mailing?.subject_en, mailing?.subject_ru]);

  const fetchPreviews = async () => {
    try {
      const result = await api.getPreview(mailing as IMailingDto);
      setPreviews(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  const sendMailing = async (usersIds: Array<ISelectValueDto>) => {
    try {
      setLoading(true);
      setError(null);
      await api.sendMailing(
        mailingId,
        usersIds.map(u => u.id),
      );
      toast.success(intl.formatMessage({id: 'SUCCESS_SEND_MAILING'}));
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  const fetchMailing = async () => {
    try {
      setLoading(true);
      setError(null);
      const result = await api.getMailing(mailingId);
      setMailing(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = async () => {
    history.push(AdminRoutes.getEditMailingRoute(mailingId));
  };

  const handleSendClick = async () => {
    const result = await showChooseUsersModal();
    if (result.reason === CloseModalReason.OK && result.payload) {
      await sendMailing(result.payload);
    }
  };

  const handleDeleteClick = async () => {
    if (!(await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_MAILING'})))) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      await api.deleteMailing(mailingId);
      history.push(AdminRoutes.getMailingsRoute());
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderPreviews = () => {
    if (!previews) {
      return (
        <div>
          <Preloader />
        </div>
      );
    }

    return (
      <>
        <div className={'mb-5'}>
          <div className={'font-size-h4 font-weight-boldest mb-2'}>
            <FormattedMessage id={'PREVIEW'} /> (RU)
          </div>
          {previews.preview_ru ? (
            <div className={'d-flex'} style={{height: ruFrameContentHeight}}>
              <iframe
                ref={ruPreviewRef}
                onLoad={handleRuPreviewLoaded}
                frameBorder={'none'}
                width={'100%'}
                srcDoc={previews?.preview_ru || ''}
              />
            </div>
          ) : (
            <div className={'text-center'}>
              <FormattedMessage id={'EMPTY'} />
            </div>
          )}
        </div>

        {mailing?.multilingual && (
          <div>
            <div className={'font-size-h4 font-weight-boldest mb-2'}>
              <FormattedMessage id={'PREVIEW'} /> (EN)
            </div>
            <div className={'d-flex'} style={{height: enFrameContentHeight}}>
              <iframe
                ref={enPreviewRef}
                onLoad={handleEnPreviewLoaded}
                frameBorder={'none'}
                width={'100%'}
                srcDoc={previews?.preview_en || ''}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div>
        <button
          className='btn btn-sm font-weight-bolder mr-5 text-uppercase btn-light-success'
          onClick={handleSendClick}>
          <span className={`svg-icon svg-icon-light-success svg-icon-2x`}>
            <SVG src={ICONS.SEND} />
          </span>
          <FormattedMessage id={'SEND'} />
        </button>
        <button
          className='btn btn-sm font-weight-bolder mr-5 text-uppercase btn-light-danger'
          onClick={handleDeleteClick}>
          <span className={`svg-icon svg-icon-light-danger svg-icon-2x`}>
            <SVG src={ICONS.DELETE} />
          </span>
          <FormattedMessage id={'DELETE'} />
        </button>
        <button className='btn btn-sm font-weight-bolder text-uppercase btn-secondary' onClick={handleEditClick}>
          <span className={`svg-icon svg-icon-secondary svg-icon-2x`}>
            <SVG src={ICONS.EDIT} />
          </span>
          <FormattedMessage id={'EDIT'} />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex'}>
            <div className={'flex-grow-1'}>
              <div className={'d-flex align-items-center justify-content-between flex-wrap'}>
                <div>
                  <div className={'d-flex'}>
                    <span className={'font-size-h4 font-weight-boldest'}>
                      <FormattedMessage id={'SUBJECT'} />: {mailing?.subject_ru}{' '}
                      {mailing?.subject_en ? ' / ' + mailing.subject_en : ''}{' '}
                    </span>
                  </div>
                  {mailing?.multilingual && (
                    <div className={'font-size-h5 font-size-sm-h6 font-weight-boldest mt-3 text-muted'}>
                      <FormattedMessage id={'MULTILINGUAL'} />
                    </div>
                  )}
                  <div className={'font-size-h5 font-size-sm-h6 font-weight-boldest mt-3 text-muted'}>
                    <FormattedMessage id={'TYPE'} />:{' '}
                    <span>
                      <FormattedMessage id={mailing?.content_type} />
                    </span>
                  </div>
                </div>

                <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
                  <div className={'d-flex flex-wrap align-items-center py-2'}>
                    <div className={'d-flex align-items-center'}>
                      <div className={'d-flex justify-content-center flex-column mr-6'}>
                        <div className={'font-weight-bolder'}>
                          <FormattedMessage id={'CREATED_AT'} />
                        </div>
                        <span className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                          {intl.formatDate(mailing?.created_at, {month: 'long', year: 'numeric', day: 'numeric'})}
                        </span>
                      </div>
                      <div className={'d-flex justify-content-center flex-column'}>
                        <div className={'font-weight-bolder'}>
                          <FormattedMessage id={'UPDATED_AT'} />
                        </div>
                        <div className={'btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bolder'}>
                          {mailing?.updated_at
                            ? intl.formatDate(mailing.updated_at, {
                                month: 'long',
                                year: 'numeric',
                                day: 'numeric',
                              })
                            : 'Not Available'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'separator separator-solid my-7'} />
              {renderButtons()}
              <div className={'separator separator-solid my-7'} />
              {renderPreviews()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
