import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../components/default-table';
import {AdminRoutes} from '../../../../configs/routes';
import {IMailingDto, MailingContentType} from '../../../api/mailing-api/IMailingDto';
import SVG from 'react-inlinesvg';
import {BadgeCustom} from '../../../components/badge-custom';

interface IUsersTableProps {
  mailings: Array<IMailingDto>;
}

const ICONS = {
  CHECK: require('../../../images/svg/Check.svg'),
};

export const MailingsTable = ({mailings}: IUsersTableProps) => {
  const intl = useIntl();
  const data = mailings;
  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder';
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IMailingDto) => br.id,
        Cell: ({value}: {value: number}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyMailingRoute(value)}>{value}</Link>
            </div>
          );
        },
      },
      {
        id: 'Subject',
        Header: intl.formatMessage({id: 'SUBJECT'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IMailingDto) => br,
        Cell: ({value}: {value: IMailingDto}) => {
          const subjects = value.subject_ru + (value.subject_en ? ' / ' + value.subject_en : '');
          return <div className={defaultClassName}>{subjects}</div>;
        },
      },
      {
        id: 'language',
        Header: intl.formatMessage({id: 'MULTILINGUAL'}),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: IMailingDto) => br.multilingual,
        Cell: ({value}: {value: MailingContentType}) => {
          return (
            <div className={defaultClassName}>
              {value ? (
                <span className='svg-icon svg-icon-success mr-1'>
                  <SVG src={ICONS.CHECK} />
                </span>
              ) : (
                '—'
              )}
            </div>
          );
        },
      },
      {
        id: 'contentType',
        Header: intl.formatMessage({id: 'TYPE'}),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: IMailingDto) => br.content_type,
        Cell: ({value}: {value: MailingContentType}) => {
          const colorVariant = value === MailingContentType.HTML ? 'primary' : 'secondary';
          return (
            <div className={defaultClassName}>
              <BadgeCustom variant={colorVariant}>
                <FormattedMessage id={value} />
              </BadgeCustom>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        accessor: (br: IMailingDto) => br.created_at,
        Cell: ({value}: {value: string}) => {
          return (
            <div className={defaultClassName}>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        accessor: (br: IMailingDto) => br.updated_at,
        Cell: ({value}: {value: string}) => {
          return (
            <div className={defaultClassName}>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
