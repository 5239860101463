import React from 'react';
import Chart from 'react-apexcharts';
import {CustomFormatter} from '../../../localization/custom-formatter';

export type StackedColumnsBarItem = {
  name: string;
  data: Array<number>;
};

export type Props = {
  height?: number | string;
  categories: Array<string>;
  series: Array<StackedColumnsBarItem>;
  barColors?: Array<string>;
  formatters?: {
    tooltip?: (num: number) => string;
    dataLabels?: (val: string | number | number[]) => string;
  };
};

export const StackedColumnsChart: React.FC<Props> = ({series, categories, formatters, barColors, height}) => {
  return (
    <Chart
      type={'bar'}
      options={{
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        fill: barColors ? {colors: barColors} : {},
        tooltip: {
          y: {
            formatter:
              formatters?.tooltip ??
              function (val) {
                return CustomFormatter.formatMoney(val);
              },
          },
        },
        dataLabels: {
          formatter:
            formatters?.dataLabels ??
            function (val) {
              return CustomFormatter.formatMoney(val as string);
            },
        },
        legend: {
          position: 'right',
          fontWeight: 'bold',
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              fontWeight: 'bolder',
            },
          },
        },
      }}
      height={height}
      series={series}
    />
  );
};
