import {Currency, TransactionStatus} from './Transaction';
import {BaseEntity, EntityId, float} from '../base/BaseEntity';
import {Feed} from './Feed';
import {FinancialReportType} from '../financial-report-api/FinancialBulkReports';

export enum TransactionOperationType {
  INNER_TRANSFER = 'INNER_TRANSFER',
  PAYMENT_FROM_PLATFORM = 'PAYMENT_FROM_PLATFORM',
  RETURN_YOUTUBE_TAX = 'RETURN_YOUTUBE_TAX',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_TO_CACHE = 'WITHDRAWAL_TO_CACHE',
  WITHDRAWAL_TO_MCPAY = 'WITHDRAWAL_TO_MCPAY',
  ADJUSTMENT = 'ADJUSTMENT',
  PAYMENT_FOR_SERVICE = 'PAYMENT_FOR_SERVICE',
}

export enum McPayRequestStatus {
  WAITING_SEND_TO_MC_PAY = 0,
  SUCCESS = 2,
  ERROR = 3,
}

export interface TransactionOperation extends BaseEntity {
  transaction_id: EntityId;
  transactionStatus: TransactionStatus;
  type: TransactionOperationType;
  from_account_id: EntityId;
  from_contractor_id: EntityId;
  from_contractor_name: string;
  from_value: float;
  from_currency: Currency;
  to_account_id: EntityId;
  to_contractor_id: EntityId;
  to_contractor_name: string;
  to_value: float;
  to_currency: Currency;
  localized_description: string | null;
  platform: Feed | null;
  period_month: number | null;
  period_year: number | null;

  y_fin_report_id: EntityId | null;
  y_fin_report_type: FinancialReportType | null;

  contract_id: EntityId | null;
  rate_percent: float | null;
  y_channel_id: EntityId | null;
  y_channel_title: string | null;

  video_id: EntityId | null;
  video_title: string | null;

  mc_pay_request_id: string | null;
  is_referral: boolean;
}
