import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseBase} from '../response-contracts/base-response';
import {ISupportTicketDto, SupportTicketType} from './ISupportTicketDto';
import {ISingleSupportTicketResponse, ISupportTicketsResponse} from './ISupportTicketsResponse';

export class SupportTicketsApi extends BaseEndpoint {
  public async createTicket(
    topic: string,
    message: string,
    type: SupportTicketType,
  ): Promise<IResponseBase<{item: ISupportTicketDto}>> {
    const response = await this.post<any>(`${config.BASE_API_URL}/tickets`, {
      fields: {
        topic,
        message,
        type,
      },
    });
    return response.data;
  }

  public async closeTicket(ticketId: number): Promise<IResponseBase> {
    const response = await this.post<any>(`${config.BASE_API_URL}/tickets/${ticketId}/close`, {});
    return response.data;
  }

  public async getTickets(filters: IQueryParams): Promise<IResponseBase<ISupportTicketsResponse>> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<any>(`${config.BASE_API_URL}/tickets?${query}`);
    return response.data;
  }

  public async getTicket(id: number): Promise<IResponseBase<ISingleSupportTicketResponse>> {
    const response = await this.get<any>(`${config.BASE_API_URL}/tickets/${id}`);
    return response.data;
  }

  public async sendMessage(id: number, message: string): Promise<any> {
    const response = await this.post<any>(`${config.BASE_API_URL}/tickets/${id}`, {
      fields: {
        message,
      },
    });
    return response.data;
  }
}
