import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseItemResponse, BaseItemsResponse} from '../../api/base/base-crud-api-response';
import {useCallback, useRef} from 'react';
import {EntityApiHookParams} from '../use-entity-api';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {PaymentRequest, PaymentRequestDestination} from '../../api/DTOs/PaymentRequest';
import {Currency} from '../../api/DTOs/Transaction';
import {EntityId} from '../../api/base/BaseEntity';
import {PartialNullable} from '../../../types/types';
import {User} from '../../api/DTOs/User';
import {FinancialAccount} from '../../api/DTOs/FinancialAccount';

export type CreatePaymentRequestFields = {
  value: number | string;
  currency: Currency;
  destination: PaymentRequestDestination;
};

export type PaymentRequestsRelation = {
  user: User;
  account: FinancialAccount;
};

export type PaymentRequestPrivileges = {
  can_perform_payment_request: boolean;
  can_reject_payment_request: boolean;
};

export function usePaymentsRequestsApi() {
  const config = useRef({prefixUrl: 'payment-requests', isAdmin: false});
  return useRoutes(config.current);
}

function useRoutes(conf: EntityApiHookParams) {
  const api = useBaseEndpointApi();
  const getAllAvailablePaymentRequests = useCallback(async (params?: IQueryParams) => {
    return await api.get<BaseItemsResponse<PaymentRequest, PaymentRequestsRelation>>(
      `${conf.prefixUrl}?${api.applyFilters(params)}`,
    );
  }, []);

  const get = useCallback(async (id: EntityId) => {
    return await api.get<BaseItemResponse<PaymentRequest, PaymentRequestsRelation, PaymentRequestPrivileges>>(
      `${conf.prefixUrl}/${id}`,
    );
  }, []);

  const create = useCallback(async (payload: PartialNullable<CreatePaymentRequestFields>) => {
    return await api.post<BaseItemResponse<PaymentRequest, PaymentRequestsRelation, PaymentRequestPrivileges>>(
      `${conf.prefixUrl}`,
      {
        fields: payload,
      },
    );
  }, []);

  const perform = useCallback(async (id: EntityId) => {
    return await api.post<BaseItemResponse<PaymentRequest>>(`${conf.prefixUrl}/${id}/perform`);
  }, []);

  const reject = useCallback(async (id: EntityId, reject_reason: string) => {
    return await api.post<BaseItemResponse<PaymentRequest>>(`${conf.prefixUrl}/${id}/reject`, {
      fields: {
        reject_reason,
      },
    });
  }, []);

  return {
    create,
    getAllAvailablePaymentRequests,
    get,
    perform,
    reject,
  };
}
