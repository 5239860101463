import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAdvancedState} from '../../../../hooks/use-advanced-state';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {useLoading} from '../../../../hooks/use-loading';
import {useSubAgreementsApi} from '../../../../hooks/apis/use-sub-agreements-api';
import {Card, CardBody, CardFooter, CardTitle} from '../../../../components/card';
import {useIntl} from '../../../../hooks/use-intl';
import {ValidateErrorWrapper} from '../../../../components/Inputs/ValidateErrorWrapper';
import {ARRAY} from '../../../../consts';
import {
  convertFlatValidationErrorToArrayable,
  getValidationErrorMessage,
  prepareDate,
  tryGetDateOrNull,
} from '../../../../utils/utils';
import {InputDatepicker} from '../../../../components/Inputs/InputDatepicker';
import {FeedsBlock} from '../../../videos/feeds/feeds-block';
import {Feed} from '../../../../api/DTOs/Feed';
import {Contract, VideoInContract, YoutubeChannelInContract} from '../../../../api/contract-api/Contract';
import {useContractsApi} from '../../../../hooks/apis/use-contracts-api';
import {AvailableChangesCheckboxes} from './available-changes-checkboxes';
import {Preloader} from '../../../../components/preloader';
import {InputRange} from '../../../../components/Inputs/InputRange';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {ICONS} from '../../../../images/images';
import {isValidationException} from '../../../../utils/api-utils';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {InputCheckbox} from '../../../../components/Inputs/InputCheckbox';
import {PartialNullable} from '../../../../../types/types';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {SupAgreementChannels} from './sup-agreement-channels';
import {SupAgreementVideos} from './sup-agreement-videos';
import {EntityToEditableRowConverter} from '../contract-page/create-edit-contract-page/edit-contract-block/row';
import {AdminRoutes} from '../../../../../configs/routes';

export const prepareChannelDataToRequest = (data: Array<YoutubeChannelInContract>) => {
  const dataWithoutTableMetaData = EntityToEditableRowConverter.removeTableFields(data);
  dataWithoutTableMetaData.forEach(channel => {
    if (channel.rate_percent != null) {
      channel.rate_percent = channel.rate_percent / 100;
    }
  });

  return dataWithoutTableMetaData;
};

export const prepareChannelDataFromResponse = (data: Array<YoutubeChannelInContract>) => {
  const dataWithTableMetaData = EntityToEditableRowConverter.convertAll(data ?? [], {isCreated: true});
  dataWithTableMetaData.forEach(channel => {
    if (channel.rate_percent != null) {
      channel.rate_percent = channel.rate_percent * 100;
    }
  });

  return dataWithTableMetaData;
};

export const CreateEditSupAgreementPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {supAgreementId, contractId} = useParams<{contractId: string; supAgreementId?: string}>();
  const {current: isEditMode} = useRef<boolean>(!!supAgreementId);
  const api = useSubAgreementsApi();
  const contractsApi = useContractsApi();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  const [loadings, startLoading, stopLoading] = useLoading({
    fetchSupAgreement: isEditMode,
    fetchContract: !!contractId,
    fetchContractState: false,
    submit: false,
  });

  const [contract, setContract] = useState<Contract | null>(null);
  const [fields, setFields, updateFields] = useAdvancedState<SupAgreement>(
    {
      contract_id: contractId,
      started_at: prepareDate(new Date()),
      is_signed: false,
      is_change_feeds: false,
      is_change_expires_at: false,

      is_add_videos: false,
      is_delete_videos: false,
      is_change_video_rate_percent: false,

      is_add_youtube_channels: false,
      is_change_youtube_channels: false,
      is_delete_youtube_channels: false,
    },
    api.setValidationErrors,
  );

  useEffect(() => {
    if (isEditMode) {
      setBreadcrumb([
        contract?.composite_number ?? null,
        fields?.number ? `${intl.formatMessage({id: 'SUP_AGREEMENT'})}: №${fields?.number}` : null,
      ]);
    } else {
      setBreadcrumb(contract?.composite_number ?? null);
    }
  }, [contract?.composite_number, fields?.number]);

  const validationDataSets = useMemo(() => {
    return convertFlatValidationErrorToArrayable<SupAgreement, YoutubeChannelInContract | VideoInContract>(
      api.validationErrors,
      ['add_youtube_channels', 'change_youtube_channels', 'delete_youtube_channels', 'add_videos', 'delete_videos'],
    );
  }, [api.validationErrors]);

  useEffect(() => {
    if (supAgreementId != null) {
      startLoading('fetchSupAgreement');
      api
        .get(supAgreementId)
        .then(res => {
          setContract(res.relations?.contract);
          setFields({
            ...res.item,
            add_youtube_channels: prepareChannelDataFromResponse(res.item.add_youtube_channels ?? []),
            change_youtube_channels: prepareChannelDataFromResponse(res.item.change_youtube_channels ?? []),
            delete_youtube_channels: prepareChannelDataFromResponse(res.item.delete_youtube_channels ?? []),
          });
        })
        .finally(() => stopLoading('fetchSupAgreement'));
    }
  }, []);

  useEffect(() => {
    if (contractId) {
      startLoading('fetchContract');
      contractsApi
        .get(contractId)
        .then(res => setContract(res.item))
        .finally(() => stopLoading('fetchContract'));
    }
  }, [contractId]);

  const handleSave = async () => {
    try {
      startLoading('submit');
      const fieldsWithFormattedDate: PartialNullable<SupAgreement> = {
        ...fields,
        change_youtube_channels: fields.is_change_youtube_channels
          ? prepareChannelDataToRequest(fields.change_youtube_channels ?? [])
          : [],
        add_youtube_channels: fields.is_add_youtube_channels
          ? prepareChannelDataToRequest(fields.add_youtube_channels ?? [])
          : [],
        delete_youtube_channels: fields.is_delete_youtube_channels
          ? prepareChannelDataToRequest(fields.delete_youtube_channels ?? [])
          : [],
        add_videos: fields.is_add_videos ? EntityToEditableRowConverter.removeTableFields(fields.add_videos ?? []) : [],
        delete_videos: fields.is_delete_videos
          ? EntityToEditableRowConverter.removeTableFields(fields.delete_videos ?? [])
          : [],
        started_at: prepareDate(tryGetDateOrNull(fields.started_at)),
      };
      const createdSupAgreement = supAgreementId
        ? await api.update(supAgreementId, fieldsWithFormattedDate)
        : await api.create(fieldsWithFormattedDate);

      // После редактирования нужно вернуть пользователя в карточку доп. соглашения.
      history.replace(AdminRoutes.getSpecifySupAgreementRoute(contractId, createdSupAgreement.id));
    } catch (e) {
      if (!isValidationException(e as ApiRequestException)) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submit');
    }
  };

  const handleSwitchFeed = (feedId: Feed, status: boolean) => {
    if (status) {
      updateFields({change_feeds: [...(fields.change_feeds || []), feedId]});
    } else {
      updateFields({change_feeds: fields.change_feeds?.filter(f => f !== feedId)});
    }
  };

  return (
    <>
      <Card>
        <CardTitle>
          {intl.formatMessage({id: supAgreementId ? 'EDIT_SUP_AGREEMENT' : 'CREATE_SUP_AGREEMENT'})}
        </CardTitle>
        <CardBody>
          <form className={'form'}>
            <div className='form-group'>
              <div className={'row'}>
                {loadings.fetchSupAgreement || loadings.fetchContract ? (
                  <Preloader />
                ) : (
                  <>
                    <div className={'col-sm-12 col-md-6'}>
                      <ValidateErrorWrapper message={getValidationErrorMessage('started_at', api.validationErrors)}>
                        <InputDatepicker
                          required
                          hasError={!!getValidationErrorMessage('started_at', api.validationErrors)}
                          value={fields.started_at}
                          label={intl.formatMessage({id: 'SUP_AGREEMENT_STARTED_AT'})}
                          className={'mb-2'}
                          onChange={date => updateFields({started_at: prepareDate(date)})}
                        />
                      </ValidateErrorWrapper>

                      <ValidateErrorWrapper message={getValidationErrorMessage('is_signed', api.validationErrors)}>
                        <InputCheckbox
                          label={intl.formatMessage({id: 'IS_SIGNED'})}
                          value={fields.is_signed ?? false}
                          onChange={() => updateFields(prevState => ({is_signed: !prevState.is_signed}))}
                        />
                      </ValidateErrorWrapper>

                      <AvailableChangesCheckboxes
                        validationErrors={api.validationErrors}
                        availableChanges={contract?.available_changes ?? ARRAY.EMPTY}
                        supAgreement={fields}
                        updateFields={updateFields}
                      />
                    </div>

                    <div className={'col-sm-12 col-md-6'}>
                      <SupAgreementChannels
                        supAgreement={fields}
                        onUpdate={updateFields}
                        validationErrors={api.validationErrors}
                        dataSetValidationErrors={validationDataSets}
                      />

                      <SupAgreementVideos
                        supAgreement={fields}
                        validationErrors={api.validationErrors}
                        dataSetValidationErrors={validationDataSets}
                        onUpdate={updateFields}
                      />

                      {fields.is_change_video_rate_percent && (
                        <InputRange
                          required
                          tooltipLabel={v => intl.formatNumber(v, {style: 'percent'})}
                          label={`${intl.formatMessage({id: 'RATE_PERCENT'})}: ${intl.formatNumber(
                            fields.change_video_rate_percent ?? 0,
                            {
                              style: 'percent',
                            },
                          )}`}
                          className={'mb-7'}
                          value={fields.change_video_rate_percent ?? 0}
                          onChange={value => updateFields({change_video_rate_percent: value})}
                        />
                      )}

                      {fields.is_change_expires_at && (
                        <InputDatepicker
                          required
                          value={fields.change_expires_at}
                          hasError={!!getValidationErrorMessage('change_expires_at', api.validationErrors)}
                          label={intl.formatMessage({id: 'CONTRACT_EXPIRES_AT'})}
                          className={'mb-7'}
                          onChange={date => updateFields({change_expires_at: prepareDate(date)})}
                        />
                      )}

                      {fields.is_change_feeds && (
                        <>
                          <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'FEEDS'})}</h3>
                          <ValidateErrorWrapper
                            message={getValidationErrorMessage('change_feeds', api.validationErrors)}>
                            <FeedsBlock selectedFeeds={fields.change_feeds ?? []} onSelectFeed={handleSwitchFeed} />
                          </ValidateErrorWrapper>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter>
          <Toolbar
            items={[
              {
                title: intl.formatMessage({id: 'SAVE'}),
                type: 'BUTTON',
                icon: ICONS.DOWNLOAD,
                className: 'btn font-weight-bolder text-uppercase btn-light-primary',
                onClick: handleSave,
              },
            ]}
          />
        </CardFooter>
      </Card>
    </>
  );
};
