import React, {useState} from 'react';
import {useIntl} from '../../hooks/use-intl';
import {toast} from 'react-toastify';
import {ValidateErrorWrapper} from '../../components/Inputs/ValidateErrorWrapper';
import {ApiRequestException} from '../../api/axios-instance';
import {InputText} from '../../components/Inputs/InputText';
import {BootstrapColors} from '../../../styles/bootstap-colors';
import {AlertCustom} from '../../modules/Auth/component/alert';
import {FormattedMessage} from 'react-intl';
import {useUserApi} from '../../hooks/apis/use-user-api';
import {useAdvancedState} from '../../hooks/use-advanced-state';
import {User} from '../../api/DTOs/User';
import {getValidationErrorMessage} from '../../utils/utils';
import {isValidationException} from '../../utils/api-utils';
import {InputPhone} from '../../components/Inputs/InputPhone';
import {AppLocale} from '../../../localization';
import {useAuth} from '../../hooks/use-auth';
import {Toolbar} from '../../components/card-toolbar/Toolbar';

export const ChangeUserInformation: React.FC = () => {
  const intl = useIntl();
  const {user} = useAuth();
  const api = useUserApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fields, , updateFields] = useAdvancedState<User>(user, api.setValidationErrors);

  const handleUpdate = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setError(null);
      await api.updateSelfFields(fields);
      toast.success(intl.formatMessage({id: 'SUCCESS_UPDATE_DATA'}));
    } catch (e) {
      const err = e as ApiRequestException;
      if (!isValidationException(e)) setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header py-3'}>
          <div className='card-title'>
            <h3 className='card-label font-weight-bolder text-dark'>
              <FormattedMessage id={'INFO'} />
            </h3>
          </div>
        </div>
        <div className={`card-body`}>
          <form className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp pb-0 mb-0'>
            <AlertCustom
              text={error}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != null}
            />

            <ValidateErrorWrapper message={getValidationErrorMessage('name_last', api.validationErrors)}>
              <InputText
                required
                name={'name_last'}
                type={'text'}
                disabled={loading}
                value={fields.name_last}
                hasError={!!getValidationErrorMessage('name_last', api.validationErrors)}
                onChange={e => updateFields({name_last: e.currentTarget.value})}
                label={intl.formatMessage({id: 'LAST_NAME'})}
                placeholder={intl.formatMessage({id: 'LAST_NAME'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('name_first', api.validationErrors)}>
              <InputText
                required
                name={'name_first'}
                type={'text'}
                disabled={loading}
                value={fields.name_first}
                hasError={!!getValidationErrorMessage('name_first', api.validationErrors)}
                onChange={e => updateFields({name_first: e.currentTarget.value})}
                label={intl.formatMessage({id: 'FIRST_NAME'})}
                placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('name_middle', api.validationErrors)}>
              <InputText
                required
                name={'name_middle'}
                type={'text'}
                disabled={loading}
                value={fields.name_middle}
                hasError={!!getValidationErrorMessage('name_middle', api.validationErrors)}
                onChange={e => updateFields({name_middle: e.currentTarget.value})}
                label={intl.formatMessage({id: 'MIDDLE_NAME'})}
                placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('position', api.validationErrors)}>
              <InputText
                name={'position'}
                type={'text'}
                disabled={loading}
                value={fields.position}
                hasError={!!getValidationErrorMessage('position', api.validationErrors)}
                onChange={e => updateFields({position: e.currentTarget.value})}
                label={intl.formatMessage({id: 'POSITION'})}
                placeholder={intl.formatMessage({id: 'POSITION'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('phone', api.validationErrors)}>
              <InputPhone
                required
                disabled={loading}
                country={intl.locale === AppLocale.RU ? 'ru' : 'us'}
                label={intl.formatMessage({id: 'PHONE_NUMBER'})}
                value={fields.phone ?? null}
                hasError={!!getValidationErrorMessage('phone', api.validationErrors)}
                onChange={(value, data, event, formattedValue) =>
                  updateFields({
                    phone: formattedValue.replace(' ', ''),
                    phone_country: data.countryCode,
                  })
                }
              />
            </ValidateErrorWrapper>

            <Toolbar
              className='form-group d-flex flex-wrap flex-center mb-0 pb-0'
              items={[
                {
                  loading: loading,
                  disabled: loading,
                  type: 'BUTTON',
                  title: intl.formatMessage({id: 'SAVE'}),
                  className: 'btn btn-light-primary btn-sm font-weight-bold',
                  onClick: handleUpdate,
                },
              ]}
            />
          </form>
        </div>
      </div>
    </>
  );
};
