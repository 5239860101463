import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {AccountApi} from '../../../../api/account-api';
import {FinancialAccount} from '../../../../api/DTOs/FinancialAccount';
import {ApiRequestException} from '../../../../api/axios-instance';
import {Preloader} from '../../../../components/preloader';
import {ErrorStub} from '../../../../components/error-stub';
import {TransactionOperation} from '../../../../api/DTOs/TransactionOperation';
import {toast} from 'react-toastify';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {IPaginationInfo} from '../../../../api/Paginator';
import {PaginationComponent} from '../../../../components/pagination';
import {ContractorInfo} from '../../../../components/contractor-info';
import {TransactionsActionsToolbar} from '../../../../components/card-toolbar/transactions-actions-toolbar';
import {useLoading} from '../../../../hooks/use-loading';
import {FinancialOperationsTable} from '../../transactions/financial-operations-table';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';

export const FinancialAccountPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{accountId: string}>();
  const accountId = match.params.accountId;

  const api = new AccountApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    operations: true,
  });

  const [account, setAccount] = useState<FinancialAccount | null>(null);
  const [operations, setOperations] = useState<Array<TransactionOperation>>([]);
  const [contractor, setContractor] = useState<Contractor | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    if (contractor && account) {
      setBreadcrumb([
        contractor.calculated_name,
        intl.formatMessage({id: `ACCOUNT_TYPE_${account.type}_${account.currency}`}),
      ]);
    }
  }, [contractor, account]);

  useEffect(() => {
    Promise.all([fetchAccount(), fetchOperations()]).then();
  }, []);

  const fetchAccount = async () => {
    try {
      startLoading('page');
      setError(null);
      const result = await api.getFinancialAccount(Number(accountId));
      setAccount(result.data.item);
      setContractor(result.data.relations.contractor);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchOperations = async (page?: number) => {
    try {
      startLoading('operations');
      const result = await api.getSpecifyAccountTransactionOperations(Number(accountId), {page: page});
      setOperations(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('operations');
    }
  };

  const handlePageChange = async (page: number) => {
    await fetchOperations(page);
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (account === null) {
    return null;
  }

  const renderOperationsBlock = () => {
    return (
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <FormattedMessage id={'ACCOUNT_OPERATIONS'} />
          </div>
        </div>
        <div className={`card-body`}>
          {loadings.operations ? (
            <Preloader />
          ) : (
            <FinancialOperationsTable transactionsOperations={operations} transactionsOperationsRelations={null} />
          )}
        </div>
        <div className={'card-footer'}>
          {paginationInfo && (
            <PaginationComponent threshold={4} paginator={paginationInfo} onChange={handlePageChange} />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'BALANCE'} />:{' '}
                <FormattedNumber value={account.balance} currency={'USD'} style={'currency'} />
              </p>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'UNCONFIRMED_BALANCE'} />:{' '}
                <FormattedNumber value={account.waitingConfirmBalance} currency={'USD'} style={'currency'} />
              </p>
              <p className={'font-size-h5 font-weight-bolder text-muted'}>
                <FormattedMessage id={'TYPE'} />:{' '}
                <span>{intl.formatMessage({id: `ACCOUNT_TYPE_${account.type}_${account.currency}`})}</span>
              </p>
            </div>
            <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
              <div className={'d-flex flex-wrap align-items-center py-2'}>
                <div className={'d-flex align-items-center flex-wrap'}>
                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'CREATED_AT'} />
                    </div>
                    <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                      {intl.formatDate(account.created_at, {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      })}
                    </span>
                  </div>

                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'UPDATED_AT'} />
                    </div>
                    <div className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                      {account.updated_at
                        ? intl.formatDate(account.updated_at, {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                          })
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'separator separator-solid my-7'} />
          <TransactionsActionsToolbar
            filters={{account_id: Number(accountId)}}
            partOfContractor={{
              id: account.contractorId,
              calculated_name: account.contractorName,
            }}
          />
          <div className={'separator separator-solid my-7'} />
          {<ContractorInfo contractor={contractor} />}
        </div>
      </div>

      {renderOperationsBlock()}
    </>
  );
};
