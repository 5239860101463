import React from 'react';

export type Renderer = string | number | JSX.Element | React.ReactNode | React.FunctionComponent | React.Component;
export type UnboxPromise<T extends Promise<any>> = T extends Promise<infer U> ? U : never;

export type UpdateEntity<T> = (
  props:
    | {[P in keyof T]?: T[P]}
    | ((prevState: PartialNullable<T>) => {[P in keyof PartialNullable<T>]?: PartialNullable<T>[P]}),
) => void;

export type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null;
};

export type ComplexNullable<T> = {
  [P in keyof T]: T[P] | null;
};

export function getEnumKeys(props: Record<string, any>): Array<string> {
  return Object.keys(props);
}

export function getEnumValues(props: Record<string, any>): Array<string> {
  return Object.values(props);
}
