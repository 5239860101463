import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {InputText} from '../../../components/Inputs/InputText';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import './registration.scss';

type Props = {
  error: string | null;
  email: string | null;
  loading: boolean;
  onChange: () => void;
  onClose: () => void;
  onSendCode: (confirmationCode: string) => void;
  onSendCodeToMailAgain: (e: any) => void;
};

const EMPTY_INPUT_OUTPUT = '';
const CONFIRMATION_CODE_LENGTH = 6;

export const RegistrationConfirmation: React.FC<Props> = ({
  error,
  email,
  loading,
  onSendCode,
  onClose,
  onChange,
  onSendCodeToMailAgain,
}) => {
  const codeRef = useRef<any>([]);
  const [confirmationCode, setConfirmationCode] = useState<Record<string, string | null>>({});
  const [enableSendCodeAgain, setEnableSendCodeAgain] = useState(false);

  useEffect(() => {
    if (
      Object.keys(confirmationCode).length === CONFIRMATION_CODE_LENGTH &&
      !Object.values(confirmationCode).some(item => item === null)
    ) {
      onSendCode(Object.values(confirmationCode).join(''));
    }
  }, [confirmationCode]);

  useEffect(() => {
    if (error !== null) {
      if (codeRef.current[CONFIRMATION_CODE_LENGTH - 1]) {
        setTimeout(() => {
          codeRef.current[CONFIRMATION_CODE_LENGTH - 1].focus();
        }, 0);
      }
    }
  }, [error]);

  useEffect(() => {
    if (codeRef.current[0]) {
      codeRef.current[0].focus();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEnableSendCodeAgain(true);
    }, 10000);
  }, []);

  const handlePasteCode = (event: React.ClipboardEvent) => {
    const pasteText = event.clipboardData.getData('text/plain').trim();
    if (pasteText.length === CONFIRMATION_CODE_LENGTH && !isNaN(parseInt(pasteText))) {
      setConfirmationCode(pasteText.split('').reduce((acc, num, index) => ({...acc, [index]: num}), {}));
    }
  };

  const handleCodeChange = (e: SyntheticEvent<any>) => {
    const value = e.currentTarget.value;
    if (value !== EMPTY_INPUT_OUTPUT && isNaN(parseInt(value))) {
      return;
    }

    onChange();
    const index = Number(e.currentTarget.id);
    setConfirmationCode(prevState => ({...prevState, [index]: value === EMPTY_INPUT_OUTPUT ? null : value}));
    if (value === EMPTY_INPUT_OUTPUT && confirmationCode[index] === null) {
      if (index > 0) {
        codeRef.current[index - 1].focus();
      }
    } else if (value !== EMPTY_INPUT_OUTPUT) {
      if (index < CONFIRMATION_CODE_LENGTH - 1) {
        codeRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const index = Number(e.currentTarget.id);
    if (e.key === 'Backspace' && e.currentTarget.value === EMPTY_INPUT_OUTPUT) {
      e.preventDefault();
      e.stopPropagation();
      if (index - 1 >= 0) {
        setConfirmationCode({...confirmationCode, [index - 1]: null});
        codeRef.current[index - 1].focus();
      }
    }
  };

  const handleReturnToRegistrationClick = () => {
    setConfirmationCode({});
    codeRef.current = [];
    setEnableSendCodeAgain(false);
    onClose();
  };

  return (
    <>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='REGISTRATION_CODE_CONFIRMATION' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage
            id='REGISTRATION_CODE_CONFIRMATION_DESC'
            values={{
              email: () => email,
            }}
          />
        </p>
      </div>
      <div className={'d-flex justify-content-center min-h-85px input-number-hide-decoration registration-code'}>
        {[0, 1, 2, 3, 4, 5].map(index => {
          return (
            <InputText
              key={index}
              disabled={loading}
              value={confirmationCode[index] ?? null}
              id={index.toString()}
              maxLength={1}
              type={'text'}
              pattern={'[0-9]'}
              inputMode={'numeric'}
              innerRef={r => (codeRef.current[index] = r)}
              classNames={'min-w-10px max-w-60px h-65px hide-caret mx-2'}
              onChange={handleCodeChange}
              onPaste={handlePasteCode}
              onKeyDown={handleKeyDown}
            />
          );
        })}
      </div>
      <div className={'d-flex justify-content-center'}>
        <ValidateErrorWrapper message={error} />
      </div>
      <div className={'d-flex flex-column text-center mt-3'}>
        <button className={'text-hover-primary btn btn-link cursor-pointer'} onClick={handleReturnToRegistrationClick}>
          <FormattedMessage id={'BACK_TO_REGISTER'} />
        </button>
        {enableSendCodeAgain && (
          <button className={'text-hover-primary btn btn-link cursor-pointer'} onClick={onSendCodeToMailAgain}>
            <FormattedMessage id={'CODE_DIDNT_COME'} /> <FormattedMessage id={'SEND_AGAIN'} />.
          </button>
        )}
      </div>
      <div className={'mt-5'}>{loading && <span className='spinner spinner-primary' />}</div>
    </>
  );
};
