import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalRejectReason} from './modal-reject-reason';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {AdvertisementsApi} from '../../../api/advertisement-api/advertisements-api';
import {ValidationException} from '../../../api/exceptions/ValidationException';

interface IModalRejectReasonAdsRequestContext {
  modalRejectReasonAdsRequestVisible: boolean;

  showRejectReasonAdsRequestModal(requestId: number): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalRejectReasonAdsRequestContext = createContext<IModalRejectReasonAdsRequestContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalRejectReasonAdsRequestProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new AdvertisementsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<number | null>(null);
  const [reason, setReason] = useState<string>('');

  const showModal = async (reqId: number) => {
    setRequestId(reqId);
    setVisible(true);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setReason('');
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    if (requestId === null) {
      throw new Error('Request Id should be not null');
    }

    try {
      setError(null);
      await api.rejectAdvertisementsRequest(requestId, reason);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalRejectReasonAdsRequestContext = {
    modalRejectReasonAdsRequestVisible: visible,
    showRejectReasonAdsRequestModal: showModal,
  };

  return (
    <ModalRejectReasonAdsRequestContext.Provider value={value}>
      {children}
      <ModalRejectReason
        error={error}
        reason={reason}
        visible={visible}
        validationErrors={validationErrors}
        onReasonChange={r => setReason(r)}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalRejectReasonAdsRequestContext.Provider>
  );
};

export const useModalRejectReasonAdsRequest = () => {
  return useContext(ModalRejectReasonAdsRequestContext);
};
