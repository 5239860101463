import React, {useCallback, useState} from 'react';
import {Preloader} from '../../components/preloader';
import {PaginationComponent} from '../../components/pagination';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {useDeepCompareEffect} from '../../hooks/use-deep-compare-effect';
import {useIntl} from '../../hooks/use-intl';
import {useLoading} from '../../hooks/use-loading';
import {useAccountsApi} from '../../hooks/apis/use-accounts-api';
import {EntityId} from '../../api/base/BaseEntity';
import {toast} from 'react-toastify';
import {FinancialOperationsTable} from '../admin/transactions/financial-operations-table';
import {RevenuesMarketingBlock} from '../marketing-blocks/revenues-marketing-block';
import {useLocalPaginator} from '../../hooks/use-local-paginator';
import {TransactionOperation} from '../../api/DTOs/TransactionOperation';

type Props = {
  accountId: EntityId | null;
};

export const Operations: React.FC<Props> = ({accountId}) => {
  const intl = useIntl();
  const api = useAccountsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    operations: true,
  });
  const [operations, setOperations] = useState<Array<TransactionOperation>>([]);
  const {currentPageItems, currentPaginationInfo, getPageData} = useLocalPaginator(operations);

  useDeepCompareEffect(() => {
    fetchOperations().then();
  }, [accountId]);

  const fetchOperations = useCallback(
    async (filters?: IQueryParams) => {
      if (!accountId) {
        return;
      }
      try {
        startLoading('operations');
        const result = await api.getMyOperations(accountId, filters);
        setOperations(result.data.items);
      } catch (e) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      } finally {
        stopLoading('operations');
      }
    },
    [accountId],
  );

  return (
    <>
      {!loadings.operations && operations?.length == 0 ? (
        <RevenuesMarketingBlock />
      ) : (
        <>
          {loadings.operations ? (
            <Preloader />
          ) : (
            <FinancialOperationsTable
              currentAccountId={accountId ?? undefined}
              transactionsOperations={currentPageItems}
              transactionsOperationsRelations={null}
            />
          )}

          {currentPaginationInfo && (
            <PaginationComponent
              threshold={4}
              paginator={currentPaginationInfo}
              onChange={pageNumber => getPageData({page: pageNumber})}
            />
          )}
        </>
      )}
    </>
  );
};
