import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import { FinancialAccount, McPayBalance } from "../../api/DTOs/FinancialAccount";
import {useCallback, useRef} from 'react';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import config from '../../../configs/config';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import { BaseItemResponse, BaseItemsResponse } from "../../api/base/base-crud-api-response";
import {EntityId} from '../../api/base/BaseEntity';

export function useAccountsApi() {
  const config = useRef({prefixUrl: 'accounts', isAdmin: false});
  return {
    ...useEntityApi<FinancialAccount>(config.current),
    ...useAdditionalAccountsEndpoints(config.current),
  };
}

export function useAdditionalAccountsEndpoints(conf: EntityApiHookParams) {
  const api = useBaseEndpointApi();

  const getAccountOperations = useCallback(async (accountId: EntityId, params?: IQueryParams) => {
    return await api.get<BaseItemsResponse<any>>(
      `${conf.prefixUrl}/${accountId}/operations?${api.applyFilters(params)}`,
    );
  }, []);

  const getMyMCPayBalance = useCallback(async () => {
    return await api.get<BaseItemResponse<McPayBalance>>(`my-accounts/mc_pay_balance`);
  }, []);

  const getMyOperations = useCallback(async (accountId: EntityId, params?: IQueryParams) => {
    return await api.get<BaseItemsResponse<any>>(`my-accounts/${accountId}/operations?${api.applyFilters(params)}`);
  }, []);

  const getMyAccounts = useCallback(async () => {
    return await api.get<BaseItemsResponse<FinancialAccount>>(`my-accounts`);
  }, []);

  const downloadReport = useCallback(async (params?: IQueryParams) => {
    return await api.getFile(`${config.BASE_API_URL}/${conf.prefixUrl}/excel-report?${api.applyFilters(params)}`);
  }, []);

  return {
    downloadReport,
    getMyAccounts,
    getMyOperations,
    getMyMCPayBalance,
    getAccountOperations,
  };
}
