import React from 'react';
import SVG from 'react-inlinesvg';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import './widget.scss';
import {WidgetBase} from './widget-base';

interface IWidgetProps {
  icon?: string | (() => JSX.Element);
  title: string;
  value: number | string | (() => JSX.Element);
  style?: string;
  className?: string;

  onClick?: () => void;
}

export const WidgetSecond: React.FC<IWidgetProps> = ({icon, value, title, className, style, onClick}) => {
  const renderIcon = () => {
    if (!icon) {
      return null;
    }

    return (
      <div className={'mr-6'}>
        {typeof icon === 'function' ? (
          icon()
        ) : (
          <span className={`svg-icon svg-icon-${style} svg-icon-4x`}>
            <SVG src={icon} />
          </span>
        )}
      </div>
    );
  };

  return (
    <WidgetBase className={className} onClick={onClick}>
      <div className='card-body d-flex align-items-center'>
        <div className='d-flex align-items-center p-2'>
          {renderIcon()}
          <div className='d-flex flex-column'>
            <div className={`text-hover-primary font-weight-bolder font-size-h5 mb-3`}>
              <FormattedMessage id={title} />
            </div>
            {typeof value === 'function' ? (
              value()
            ) : (
              <div className={`font-weight-boldest font-size-h4`}>
                <FormattedNumber value={Number(value)} />
              </div>
            )}
          </div>
        </div>
      </div>
    </WidgetBase>
  );
};
