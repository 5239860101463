import React from 'react';
import PhoneInput from 'react-phone-input-2';
import {LabelWithDescription} from './LabelWithDescription';
import {useIntl} from '../../hooks/use-intl';
import {AppLocale} from '../../../localization';

import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/style.css';
import './InputPhone.scss';
import cn from 'classnames';

type Props = {
  country?: string;
  onlyCountries?: Array<string>;
  label?: string;
  descriptionLabel?: string;
  disabled?: boolean;
  required?: boolean;
  value: string | null;
  hasError?: boolean;
  onChange: (
    value: string,
    data: {
      name: string;
      dialCode: string;
      countryCode: string;
      format: string;
    },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => void;
};

export const InputPhone: React.FC<Props> = ({
  value,
  required,
  label,
  disabled,
  descriptionLabel,
  country,
  onlyCountries,
  hasError,
  onChange,
}) => {
  const intl = useIntl();
  return (
    <>
      <LabelWithDescription required={required} label={label} description={descriptionLabel} />
      <PhoneInput
        localization={intl.locale === AppLocale.RU ? ru : undefined}
        disabled={disabled}
        onlyCountries={onlyCountries}
        specialLabel={''}
        inputClass={cn('phone-control', {'is-invalid': hasError})}
        country={country}
        countryCodeEditable={false}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
