import React from 'react';
import {ValidateErrorWrapper} from '../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../components/Inputs/InputText';
import {getValidationErrorMessage, ValidationErrorsType} from '../../utils/utils';
import {InputPhone} from '../../components/Inputs/InputPhone';
import {AppLocale} from '../../../localization';
import {User} from '../../api/DTOs/User';
import {PartialNullable} from '../../../types/types';
import {useIntl} from '../../hooks/use-intl';
import {UpdateFields} from '../../hooks/use-advanced-state';
import {InputSelect} from "../../components/Inputs/InputSelect";
import {AllSelectOptions, SelectOptionsLoadings} from "../../hooks/use-select-api";

type Props = {
  selectOptionsLoading?: SelectOptionsLoadings;
  options?: AllSelectOptions;
  fieldsData: PartialNullable<User>;
  validationErrors: ValidationErrorsType<User>;
  updateFields: UpdateFields<User>;
};

export const ProfileInformationFields: React.FC<Props> = (
  {
    fieldsData,
    validationErrors,
    selectOptionsLoading,
    options,
    updateFields
  }) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper message={getValidationErrorMessage('name_first', validationErrors)}>
        <ValidateErrorWrapper message={getValidationErrorMessage('name_last', validationErrors)}>
          <InputText
            required
            value={fieldsData.name_last}
            label={intl.formatMessage({id: 'LAST_NAME'})}
            hasError={!!getValidationErrorMessage('name_last', validationErrors)}
            classNames={'form-control py-3 px-6 mr-2'}
            onChange={e => updateFields({name_last: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>

        <InputText
          required
          value={fieldsData.name_first}
          label={intl.formatMessage({id: 'FIRST_NAME'})}
          hasError={!!getValidationErrorMessage('name_first', validationErrors)}
          classNames={'form-control py-3 px-6 mr-2'}
          onChange={e => updateFields({name_first: e.currentTarget.value})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage('name_middle', validationErrors)}>
        <InputText
          value={fieldsData.name_middle}
          label={intl.formatMessage({id: 'MIDDLE_NAME'})}
          hasError={!!getValidationErrorMessage('name_middle', validationErrors)}
          classNames={'form-control py-3 px-6 mr-2'}
          onChange={e => updateFields({name_middle: e.currentTarget.value})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage('email', validationErrors)}>
        <InputText
          required
          value={fieldsData.email}
          label={intl.formatMessage({id: 'EMAIL'})}
          hasError={!!getValidationErrorMessage('email', validationErrors)}
          classNames={'form-control py-3 px-6 mr-2'}
          onChange={e => updateFields({email: e.currentTarget.value})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
        <InputSelect
          isClearable
          isLoading={selectOptionsLoading?.contractors}
          value={fieldsData.contractor_id}
          options={options?.contractors ?? []}
          label={intl.formatMessage({id: 'CONTRACTOR'})}
          placeholder={''}
          onChange={(value) => updateFields({contractor_id: value})}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage('phone', validationErrors)}>
        <InputPhone
          country={intl.locale === AppLocale.RU ? 'ru' : 'us'}
          label={intl.formatMessage({id: 'PHONE_NUMBER'})}
          value={fieldsData.phone ?? null}
          onChange={(value, data, event, formattedValue) =>
            updateFields({
              phone: formattedValue.replace(' ', ''),
              phone_country: data.countryCode,
            })
          }
        />
      </ValidateErrorWrapper>
    </>
  );
};
