import {EffectCallback, useCallback, useEffect, useRef} from 'react';
import LodashIsEqual from 'lodash.isequal';
import {useComponentDidMount} from './use-component-did-mount';
import {CALLBACK} from '../consts';

function deepCompareEquals(a: any, b: any) {
  return LodashIsEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback: EffectCallback, dependencies: any[], skipFirstRender = false) {
  const componentDidMount = useComponentDidMount();
  useEffect(
    (skipFirstRender && componentDidMount) || !skipFirstRender ? callback : CALLBACK.EMPTY,
    dependencies.map(useDeepCompareMemoize),
  );
}

export function useDeepCompareCallback(callback: any, dependencies: any[]) {
  return useCallback(callback, dependencies.map(useDeepCompareMemoize));
}
