import React, {CSSProperties} from 'react';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

interface IErrorStubProps {
  error: string | null;
  style?: CSSProperties;
  className?: string;
}

const BACKGROUND = require('../images/backgrounds/error-background.jpg');

export const ErrorStub: React.FC<IErrorStubProps> = ({error, style, className}) => {
  return (
    <>
      <div className='d-flex flex-column flex-root h-100'>
        <div
          className='d-flex flex-row-fluid bgi-size-cover bgi-position-center'
          style={{
            backgroundImage: `url(${BACKGROUND})`,
          }}>
          <div className='container d-flex flex-row-fluid flex-column justify-content-md-center p-12'>
            <h1 className='font-weight-boldest text-info mt-10 mt-md-0 mb-12' style={{fontSize: '7rem'}}>
              <FormattedMessage id={'OOPS'} />!
            </h1>
            <p className={cn('font-weight-bolder font-size-h6 max-w-800px', className)} style={style}>
              {error}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
