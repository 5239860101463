import React, {PropsWithChildren, useMemo} from 'react';
import {Steps} from './steps';
import {FormattedMessage} from 'react-intl';

type Props<T> = {
  active: T;
};

export function Wizard<T>({active, children}: PropsWithChildren<Props<T>>) {
  const steps = useMemo<Array<{title: string; match: T[]}>>(() => {
    return (children as Array<JSX.Element>).map(ch => ch.props);
  }, []);

  const renderProgress = () => {
    return (
      <div className={'wizard wizard-3 mb-5'}>
        <div className={'wizard-nav'}>
          <div className={'wizard-steps'}>
            {steps.map((step, index) => {
              return (
                <div
                  key={step.title}
                  className='wizard-step cursor-pointer'
                  data-wizard-type='step'
                  data-wizard-state={step.match.includes(active) ? 'current' : null}>
                  <div className='wizard-label'>
                    <h3 className='wizard-title'>
                      <span>{index + 1}.</span>
                      <FormattedMessage id={step.title} />
                    </h3>
                    <div className='wizard-bar' />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderProgress()}
      <Steps active={active}>{children}</Steps>
    </>
  );
}
