import React from 'react';
import {PartialNullable} from '../../../../../types/types';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {LabelWithDescription} from '../../../../components/Inputs/LabelWithDescription';
import {useIntl} from '../../../../hooks/use-intl';
import {prepareDate} from '../../../../utils/utils';
import {InputCheckbox} from '../../../../components/Inputs/InputCheckbox';

type Props = {
  contractor: PartialNullable<Contractor>;
};

export const PassportInfo: React.FC<Props> = ({contractor}) => {
  const intl = useIntl();
  const renderPaymentRequisitesItem = (title: string, value?: string | number | null) => {
    if (!value) {
      return null;
    }

    return (
      <div className={'mb-3'}>
        <LabelWithDescription className={{label: 'mb-0'}} label={intl.formatMessage({id: title})}/>
        <div>{value}</div>
      </div>
    );
  };

  return (
    <div>
      <div className={'d-flex flex-wrap'}>
        <div className={'mr-8'}>{renderPaymentRequisitesItem('LAST_NAME', contractor?.individual_name_last)}</div>
        <div className={'mr-8'}>{renderPaymentRequisitesItem('FIRST_NAME', contractor?.individual_name_first)}</div>
        <div className={'mr-8'}>{renderPaymentRequisitesItem('MIDDLE_NAME', contractor?.individual_name_middle)}</div>
      </div>

      <div className={'d-flex flex-wrap'}>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem('JURISDICTION_INDIVIDUAL', contractor?.jurisdiction)}
        </div>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem('PASSPORT_NUMBER', contractor?.individual_passport_number)}
        </div>
      </div>

      {renderPaymentRequisitesItem('PASSPORT_REGISTRATION_ADDRESS', contractor?.individual_registration_address)}
      {renderPaymentRequisitesItem('PASSPORT_ISSUED_BY', contractor?.individual_passport_issued_by)}

      <div className={'d-flex flex-wrap'}>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem(
            'PASSPORT_ISSUED_DATE',
            prepareDate(new Date(contractor?.individual_passport_issued_date || 0), 'DD.MM.YYYY'),
          )}
        </div>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem('PASSPORT_DIVISION_CODE', contractor?.individual_passport_division_code)}
        </div>
      </div>

      {renderPaymentRequisitesItem('TAX_PAYER_NUMBER', contractor?.individual_inn)}
      {renderPaymentRequisitesItem('SNILS', contractor?.individual_snils)}
      <InputCheckbox
        label={intl.formatMessage({id: 'SELF_EMPLOYED'})}
        disabled
        value={contractor?.individual_is_self_employed ?? false}
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        onChange={() => {
        }}
      />
    </div>
  );
};
