import React from 'react';
import {FormattedMessage} from 'react-intl';

const BACKGROUND = require('../images/backgrounds/not-found.jpg');

export const NotFoundPage: React.FC<any> = () => {
  return (
    <>
      <div className='d-flex flex-column flex-root h-100'>
        <div
          className='d-flex flex-row-fluid bgi-size-cover bgi-position-center'
          style={{
            backgroundImage: `url(${BACKGROUND})`,
          }}>
          <div className='container d-flex flex-row-fluid flex-column justify-content-md-center p-12'>
            <h1 className='font-weight-boldest text-white mt-10 mt-md-0 mb-12' style={{fontSize: '7rem'}}>
              <FormattedMessage id={'OOPS'} />!
            </h1>
            <p className='font-weight-boldest display-4 text-white'>
              <FormattedMessage id={'PAGE_NOT_FOUND'} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
