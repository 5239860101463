import React, {useMemo} from 'react';
import {ValidateErrorWrapper} from '../../../../../components/Inputs/ValidateErrorWrapper';
import {
  convertFlatValidationErrorToArrayable,
  getValidationErrorMessage,
  prepareDate,
  ValidationErrorsType,
} from '../../../../../utils/utils';
import {InputSelect, SelectOptions} from '../../../../../components/Inputs/InputSelect';
import {getEnumKeys, PartialNullable, UpdateEntity} from '../../../../../../types/types';
import {
  Contract,
  ContractType,
  ReportFrequency,
  VideoInContract,
  YoutubeChannelInContract,
} from '../../../../../api/contract-api/Contract';
import {InputDatepicker} from '../../../../../components/Inputs/InputDatepicker';
import {CurrencyPicker} from '../../../../../components/currency-picker';
import {useIntl} from '../../../../../hooks/use-intl';
import {FeedsBlock} from '../../../../videos/feeds/feeds-block';
import {Feed} from '../../../../../api/DTOs/Feed';
import {AlertCustom} from '../../../../../modules/Auth/component/alert';
import {BootstrapColors} from '../../../../../../styles/bootstap-colors';
import {InputText} from '../../../../../components/Inputs/InputText';
import {CALLBACK} from '../../../../../consts';
import {InputCheckbox} from '../../../../../components/Inputs/InputCheckbox';
import {InputRange} from '../../../../../components/Inputs/InputRange';
import {ChannelsBlock} from '../../channels-block';
import {VideosBlock} from '../../video-block';
import {SupAgreement} from '../../../../../api/DTOs/SupAgreement';

type Props = {
  showAlert?: boolean;
  validationErrors: ValidationErrorsType<Contract>;
  fields: PartialNullable<Contract>;
  updateFields: UpdateEntity<PartialNullable<Contract>>;
  contractors: SelectOptions;
  loadingContractors: boolean;
  ourContractors: SelectOptions;
  loadingOurContractors: boolean;
};
export const CreateEditContractForm: React.FC<Props> = ({
  showAlert,
  fields,
  updateFields,
  validationErrors,
  contractors,
  loadingContractors,
  ourContractors,
  loadingOurContractors,
}) => {
  const intl = useIntl();

  const handleSwitchFeed = (feedId: Feed, status: boolean) => {
    if (status) {
      updateFields({change_feeds: [...(fields.change_feeds || []), feedId]});
    } else {
      updateFields({change_feeds: fields.change_feeds?.filter(f => f !== feedId)});
    }
  };

  const validationDataSets = useMemo(() => {
    return convertFlatValidationErrorToArrayable<SupAgreement, YoutubeChannelInContract | VideoInContract>(
      validationErrors,
      ['add_youtube_channels', 'change_youtube_channels', 'delete_youtube_channels', 'add_videos', 'delete_videos'],
    );
  }, [validationErrors]);

  return (
    <>
      <form className={'form'}>
        <div className='form-group'>
          <div className={'row'}>
            <div className={'col-sm-12 col-md-6'}>
              {showAlert && (
                <AlertCustom
                  visible={true}
                  type={BootstrapColors.LIGHT_INFO}
                  iconClassName={'svg-icon-info'}
                  text={intl.formatMessage({id: 'CONTRACT_NUMBER_BE_AUTOGENERATED'})}
                />
              )}

              {fields.composite_number && (
                <ValidateErrorWrapper message={null}>
                  <InputText
                    disabled
                    label={intl.formatMessage({id: 'CONTRACT_NUMBER'})}
                    value={fields.composite_number}
                    classNames={'form-control py-3 px-6'}
                    onChange={CALLBACK.EMPTY}
                  />
                </ValidateErrorWrapper>
              )}

              <ValidateErrorWrapper message={getValidationErrorMessage('type', validationErrors)}>
                <InputSelect
                  required
                  isClearable
                  label={intl.formatMessage({id: 'TYPE'})}
                  placeholder={''}
                  hasError={!!getValidationErrorMessage('type', validationErrors)}
                  className={'mb-2'}
                  value={fields.type}
                  options={getEnumKeys(ContractType).map(type => ({
                    value: type,
                    label: intl.formatMessage({id: `CONTRACT_TYPE_${type}`}),
                  }))}
                  onChange={value => updateFields({type: value as ContractType})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('our_contractor_id', validationErrors)}>
                <InputSelect
                  required
                  isLoading={loadingOurContractors}
                  isClearable
                  label={intl.formatMessage({id: 'IS_OUR_COMPANY'})}
                  placeholder={''}
                  hasError={!!getValidationErrorMessage('our_contractor_id', validationErrors)}
                  className={'mb-2'}
                  value={fields.our_contractor_id}
                  options={ourContractors}
                  onChange={value => updateFields({our_contractor_id: value})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
                <InputSelect
                  id={'contractor_id'}
                  required
                  isClearable
                  label={intl.formatMessage({id: 'CONTRACTOR'})}
                  placeholder={''}
                  isLoading={loadingContractors}
                  hasError={!!getValidationErrorMessage('contractor_id', validationErrors)}
                  className={'mb-2'}
                  value={fields.contractor_id}
                  options={contractors}
                  onChange={value => updateFields({contractor_id: value})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('report_frequency', validationErrors)}>
                <InputSelect
                  required
                  isClearable
                  label={intl.formatMessage({id: 'REPORT_FREQUENCY'})}
                  placeholder={''}
                  hasError={!!getValidationErrorMessage('report_frequency', validationErrors)}
                  className={'mb-2'}
                  value={fields.report_frequency}
                  options={getEnumKeys(ReportFrequency).map(reportFrequency => ({
                    value: reportFrequency,
                    label: intl.formatMessage({id: `REPORT_FREQUENCY_${reportFrequency}`}),
                  }))}
                  onChange={value => updateFields({report_frequency: value as ReportFrequency})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('started_at', validationErrors)}>
                <InputDatepicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode={'select'}
                  hasError={!!getValidationErrorMessage('started_at', validationErrors)}
                  value={fields.started_at}
                  required
                  label={intl.formatMessage({id: 'CONTRACT_START_DATE'})}
                  className={'mb-2'}
                  onChange={date => updateFields({started_at: prepareDate(date)})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('expires_at', validationErrors)}>
                <InputDatepicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode={'select'}
                  hasError={!!getValidationErrorMessage('expires_at', validationErrors)}
                  value={fields.expires_at}
                  required
                  label={intl.formatMessage({id: 'CONTRACT_END_DATE'})}
                  className={'mb-2'}
                  onChange={date => updateFields({expires_at: prepareDate(date)})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('is_signed', validationErrors)}>
                <InputCheckbox
                  label={intl.formatMessage({id: 'IS_SIGNED'})}
                  value={fields.is_signed ?? false}
                  onChange={() => updateFields(prevState => ({is_signed: !prevState.is_signed}))}
                />
              </ValidateErrorWrapper>

              <CurrencyPicker
                label={intl.formatMessage({id: 'CURRENCY'})}
                currency={fields.currency}
                onChange={value => updateFields({currency: value})}
                validationError={getValidationErrorMessage('currency', validationErrors)}
              />

              {fields.type === ContractType.PUBLISH_VIDEO && (
                <ValidateErrorWrapper
                  message={getValidationErrorMessage('change_video_rate_percent', validationErrors)}
                  className={'mt-5'}>
                  <InputRange
                    required
                    tooltipLabel={v => intl.formatNumber(v, {style: 'percent'})}
                    label={`${intl.formatMessage({id: 'RATE_PERCENT'})}: ${intl.formatNumber(
                      fields.change_video_rate_percent ?? 0,
                      {
                        style: 'percent',
                      },
                    )}`}
                    value={fields.change_video_rate_percent ?? 0}
                    onChange={value => updateFields({change_video_rate_percent: value})}
                  />
                </ValidateErrorWrapper>
              )}
            </div>

            <div className={'col-sm-12 col-md-6'}>
              {fields.type === ContractType.YOUTUBE_PARTNER && (
                <>
                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'YOUTUBE_CHANNELS'})}</h3>
                  <AlertCustom
                    text={getValidationErrorMessage('add_youtube_channels', validationErrors)}
                    type={BootstrapColors.LIGHT_DANGER}
                    iconClassName={'svg-icon-danger'}
                  />
                  <ChannelsBlock
                    validationErrors={validationDataSets.add_youtube_channels ?? null}
                    channels={fields.add_youtube_channels ?? []}
                    onSave={items => updateFields({add_youtube_channels: items})}
                  />

                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'FEEDS'})}</h3>
                  <ValidateErrorWrapper message={getValidationErrorMessage('change_feeds', validationErrors)}>
                    <FeedsBlock selectedFeeds={fields.change_feeds ?? []} onSelectFeed={handleSwitchFeed} />
                  </ValidateErrorWrapper>
                </>
              )}

              {fields.type === ContractType.PUBLISH_VIDEO && (
                <>
                  <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'VIDEOS'})}</h3>
                  <AlertCustom
                    text={getValidationErrorMessage('add_videos', validationErrors)}
                    type={BootstrapColors.LIGHT_DANGER}
                    iconClassName={'svg-icon-danger'}
                  />
                  <VideosBlock
                    videos={fields.add_videos ?? []}
                    validationErrors={validationDataSets.add_videos ?? null}
                    onSave={items => updateFields({add_videos: items})}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
