import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {SupAgreement} from '../../api/DTOs/SupAgreement';
import {EntityId} from '../../api/base/BaseEntity';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Dispatch, useRef} from 'react';
import {ValidationErrorsType} from '../../utils/utils';
import {isValidationException} from '../../utils/api-utils';
import {ContractorDocument} from '../../api/DTOs/ContractorDocument';
import config from '../../../configs/config';

export type ContractorDocumentsRelations = {
  supAgreements: SupAgreement;
};

export const useContractorDocumentsApi = () => {
  const conf = useRef({prefixUrl: 'contractor-documents', isAdmin: false});
  const entityApi = useEntityApi<ContractorDocument, ContractorDocumentsRelations>(conf.current);

  return {
    ...entityApi,
    ...useAdditionalRoutes(conf.current, entityApi.setValidationErrors),
  };
};

function useAdditionalRoutes(conf: EntityApiHookParams, setValidationErrors: Dispatch<ValidationErrorsType>) {
  const api = useBaseEndpointApi();

  const downloadDocument = async (contractorDocumentId: EntityId) => {
    try {
      return await api.getFile(`${config.BASE_API_URL}/${conf.prefixUrl}/${contractorDocumentId}/download`);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
      throw e;
    }
  };

  return {
    downloadDocument,
  };
}
