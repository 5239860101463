import {IQueryParams} from '../DTOs/IFilterDtos';
import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {BaseGetEntitiesResponse, BaseGetEntityResponse, BasePostResponse} from './base-crud-api-response';
import {EntityId} from './BaseEntity';
import {PartialNullable} from '../../../types/types';

export class BaseCrudApi<
  T,
  EntityRelations = unknown | undefined,
  EntityPrivileges = unknown | undefined,
> extends BaseEndpoint {
  private readonly prefixUrl: string;

  constructor({isAdmin, prefixUrl}: any) {
    super();
    this.prefixUrl = isAdmin ? 'admin/' + prefixUrl : prefixUrl;
  }

  public async getEntities<AggregationValues>(
    params?: IQueryParams,
  ): Promise<BaseGetEntitiesResponse<T, EntityRelations, AggregationValues, EntityPrivileges>> {
    const queries = this.applyQueryParams(params);
    const response = await this.get(`${config.BASE_API_URL}/${this.prefixUrl}?${queries}`);
    return response.data;
  }

  public async getEntityById(entityId: EntityId): Promise<BaseGetEntityResponse<T, EntityRelations, EntityPrivileges>> {
    const response = await this.get(`${config.BASE_API_URL}/${this.prefixUrl}/${entityId}`);
    return response.data;
  }

  public async createEntity(
    entityFields: PartialNullable<T>,
  ): Promise<BasePostResponse<T, EntityRelations, EntityPrivileges>> {
    const response = await this.post(`${config.BASE_API_URL}/${this.prefixUrl}/`, {
      fields: {
        ...entityFields,
      },
    });
    return response.data;
  }

  public async updateEntity(
    entityId: EntityId,
    entityFields: PartialNullable<T>,
  ): Promise<BasePostResponse<T, EntityRelations, EntityPrivileges>> {
    const response = await this.post(`${config.BASE_API_URL}/${this.prefixUrl}/${entityId}`, {
      fields: {
        ...entityFields,
      },
    });
    return response.data;
  }

  public async deleteEntity(entityId: EntityId): Promise<BasePostResponse<T, EntityRelations, EntityPrivileges>> {
    const response = await this.delete(`${config.BASE_API_URL}/${this.prefixUrl}/${entityId}`);
    return response.data;
  }
}
