import React, {useEffect, useState} from 'react';
import {useIntl} from '../../../hooks/use-intl';
import {BaseListPage} from '../../base/base-list-page';
import {useLoading} from '../../../hooks/use-loading';
import {Table} from '../../../components/table';
import {TableUtils} from '../../../components/table-utils';
import {McPayAuthState, useMcPayStatesApi} from '../../../hooks/apis/useMcPayStatesApi';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {FormattedDate, FormattedTime} from 'react-intl';

export const McPayAuthStatesPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = useMcPayStatesApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [processes, setProcesses] = useState<McPayAuthState[]>([]);

  useEffect(() => {
    startLoading('page');
    api
      .getAuthStates()
      .then(res => {
        setProcesses(res.data.items);
      })
      .finally(() => stopLoading('page'));
  }, []);

  return (
    <BaseListPage pageTitle={intl.formatMessage({id: 'BACKGROUND_PROCESSES'})} loading={loadings.page}>
      <Table
        data={processes ?? []}
        columns={[
          {
            id: 'id',
            Header: 'ID',
            maxWidth: 100,
            forceApplySize: true,
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                <Link to={AdminRoutes.getMcPayAuthStateRoute(value.id)}>{value.id}</Link>,
              );
            },
          },
          {
            id: 'state',
            Header: 'State',
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(intl.formatMessage({id: 'MC_PAY_AUTH_STATE_' + value.state}));
            },
          },
          {
            id: 'user',
            Header: 'User',
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                <>
                  {value?.user != null ? (
                    <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.user.id)}>{value?.user?.email}</Link>
                  ) : null}
                </>,
              );
            },
          },
          {
            id: 'error',
            Header: 'Error',
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(<>{value.error_message ?? 'N/A'}</>);
            },
          },
          {
            id: 'Time',
            Header: 'Started At',
            maxWidth: 150,
            forceApplySize: true,
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                <>
                  <FormattedDate value={value.created_at} /> <FormattedTime value={value.created_at} />
                </>,
              );
            },
          },
        ]}
      />
    </BaseListPage>
  );
};
