import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {IApplicationStore} from '../../redux/rootReducer';
import {User, UserType} from '../api/DTOs/User';
import {Contractor} from '../api/contractor-api/Contractor';
import {Privileges} from '../api/response-contracts/auth-response-contracts';
import {useCallback} from 'react';
import {
  LogoutAction,
  SetContractorAction,
  SetFillContractorFieldsAlertVisible,
  SetPrivilegesAction,
  SetUserAction,
} from '../modules/Auth';

export function useAuth() {
  const dispatch = useDispatch();
  const currentUser = useSelector<IApplicationStore, User>(state => {
    return state.auth.user as NonNullable<User>;
  }, shallowEqual);

  const currentContractor = useSelector<IApplicationStore, Contractor | null>(state => {
    return state.auth.contractor;
  }, shallowEqual);

  const privileges = useSelector<IApplicationStore, Privileges>(state => {
    return state.auth.privileges as NonNullable<Privileges>;
  }, shallowEqual);

  const setCurrentContractor = useCallback(
    (contractor: Contractor | null) => {
      dispatch(SetContractorAction(contractor));
    },
    [dispatch],
  );

  const setCurrentUser = useCallback(
    (user: User | null) => {
      dispatch(SetUserAction(user));
    },
    [dispatch],
  );

  const setCurrentGlobalPrivileges = useCallback(
    (privileges: Privileges | null) => {
      dispatch(SetPrivilegesAction(privileges));
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(LogoutAction());
  }, [dispatch]);

  const visibleFillContractorFieldsAlert = useSelector<IApplicationStore, boolean>(state => {
    return state.auth.needShowAlertForFillContractorFields;
  }, shallowEqual);

  const changeVisibleFillContractorFieldsAlert = useCallback(
    (visible: boolean) => {
      dispatch(SetFillContractorFieldsAlertVisible(visible));
    },
    [dispatch],
  );

  return {
    isAdmin: currentUser?.type === UserType.ADMIN,
    isArtist: currentUser?.type === UserType.ARTIST,
    isUser: currentUser?.type === UserType.USER,
    user: currentUser,
    contractor: currentContractor,
    setCurrentContractor,
    setCurrentGlobalPrivileges,
    visibleFillContractorFieldsAlert,
    changeVisibleFillContractorFieldsAlert,
    setCurrentUser,
    privileges,
    logout,
  };
}
