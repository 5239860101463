import React, {useMemo} from 'react';
import SVG from 'react-inlinesvg';
import {FormattedNumber, useIntl} from 'react-intl';
import {IYoutubeVideoDto} from '../../../api/DTOs/IYoutubeVideoDto';
import {DefaultTable} from '../../../components/default-table';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {ExternalRoutes} from '../../../../configs/routes';
import {ImageWithPreloader} from '../../../components/image-with-preloader';
import {IMAGES} from '../../../images/images';

const ICONS = {
  VISIBLE: require('../../../images/svg/Visible.svg'),
  COMMENTS: require('../../../images/svg/comments.svg'),
  DISLIKE: require('../../../images/svg/Dislike.svg'),
  LIKE: require('../../../images/svg/Like.svg'),
};

interface IYoutubeVideosTableProps {
  videos: Array<IYoutubeVideoDto>;
}

export const YoutubeVideosTable = ({videos}: IYoutubeVideosTableProps) => {
  const intl = useIntl();
  const data = videos;
  const columns = useMemo(() => {
    return [
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        width: 100,
        accessor: (br: IYoutubeVideoDto) => br,
        Cell: ({value}: {value: IYoutubeVideoDto}) => {
          return (
            <a target={'_blank'} href={ExternalRoutes.getExternalYoutubeVideoRoute(value.id)} rel='noreferrer'>
              <ImageWithPreloader
                containerHeight={'100px'}
                errorStub={IMAGES.VIDEO_PREVIEW_STUB}
                imageProps={{
                  src: tryGetPreviewOrStub(value.thumbnails).url as string,
                  alt: value.title,
                  className: 'w-100 rounded',
                }}
              />
            </a>
          );
        },
      },
      {
        id: 'Header',
        Header: '',
        maxWidth: 300,
        accessor: (br: IYoutubeVideoDto) => br,
        Cell: ({value}: {value: IYoutubeVideoDto}) => {
          return (
            <div>
              <a target={'_blank'} href={ExternalRoutes.getExternalYoutubeVideoRoute(value.id)} rel='noreferrer'>
                <span className={'font-weight-bolder'}>{value.title}</span>
              </a>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'LIKES'}),
        maxWidth: 100,
        accessor: (br: IYoutubeVideoDto) => br.likeCount,
        Cell: ({value}: {value: string}) => {
          return (
            <div>
              <span className='svg-icon svg-icon-lg svg-icon-success'>
                <SVG src={ICONS.LIKE} />
              </span>
              <span className={'font-weight-bolder'}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'DISLIKES'}),
        maxWidth: 100,
        accessor: (br: IYoutubeVideoDto) => br.dislikeCount,
        Cell: ({value}: {value: number}) => {
          return (
            <div>
              <span className='svg-icon svg-icon-lg svg-icon-danger svg-icon-flip-mirror mr-1'>
                <SVG src={ICONS.DISLIKE} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'VIEWS'}),
        maxWidth: 100,
        accessor: (br: IYoutubeVideoDto) => br.viewCount,
        Cell: ({value}: {value: number}) => {
          return (
            <div>
              <span className='svg-icon svg-icon-lg svg-icon-primary mr-1'>
                <SVG src={ICONS.VISIBLE} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'COMMENTS'}),
        maxWidth: 100,
        accessor: (br: IYoutubeVideoDto) => br.commentCount,
        Cell: ({value}: {value: number}) => {
          return (
            <div>
              <span className='svg-icon svg-icon-lg svg-icon-secondary mr-1'>
                <SVG src={ICONS.COMMENTS} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
