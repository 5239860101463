import React, {useEffect, useState} from 'react';
import {usePartnerApi} from '../../../hooks/apis/use-partner-api';
import {Feed} from '../../../api/DTOs/Feed';
import {getEnumKeys} from '../../../../types/types';
import {FEED_ICONS_MAP} from '../../../api/DTOs/FeedIconsMap';
import {useIntl} from '../../../hooks/use-intl';
import {Table} from '../../../components/table';
import {CommonRoutes} from '../../../../configs/routes';
import {BaseListPage} from '../../base/base-list-page';
import {useLoading} from '../../../hooks/use-loading';
import {TableUtils} from '../../../components/table-utils';

export const FeedsIntegrationPage: React.FC = () => {
  const api = usePartnerApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    hashes: true,
  });
  const [partnerHashes, setPartnerHashes] = useState<Record<Feed, string>>();
  const intl = useIntl();
  useEffect(() => {
    startLoading('hashes');
    api
      .getPartnerList()
      .then(res => setPartnerHashes(res.data))
      .finally(() => stopLoading('hashes'));
  }, []);

  return (
    <BaseListPage hideFooter pageTitle={intl.formatMessage({id: 'PARTNER_INTEGRATION'})} loading={loadings.hashes}>
      <Table
        data={getEnumKeys(Feed)}
        columns={[
          {
            id: 'Logo',
            Header: intl.formatMessage({id: 'TITLE'}),
            maxWidth: 100,
            forceApplySize: true,
            Cell: ({value}) => {
              return <img alt={value} width={60} src={FEED_ICONS_MAP[value as Feed]} />;
            },
          },
          {
            id: 'Title',
            Cell: ({value}) => {
              const hash = partnerHashes?.[value as Feed];
              if (!hash) {
                return null;
              }
              return TableUtils.renderBaseContent(
                <a
                  className={'font-weight-bolder'}
                  target={'_blank'}
                  href={CommonRoutes.getPartnerRoute(hash)}
                  rel='noreferrer'>
                  {intl.formatMessage({id: `FEED_${value}`})}
                </a>,
              );
            },
          },
        ]}
      />
    </BaseListPage>
  );
};
