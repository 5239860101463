import React, {CSSProperties} from 'react';
import {LabelWithDescription} from './LabelWithDescription';
import cn from 'classnames';

interface InputCustomProps {
  value?: string | number | null;
  name?: string;
  height?: number;
  label?: string;
  descriptionLabel?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  required?: boolean;
  hasError?: boolean;
  defaultValue?: string;
  disabled?: boolean;

  onChange(e: React.SyntheticEvent<any>): any;
}

export const InputTextArea = ({
  className,
  height,
  name,
  hasError,
  onChange,
  placeholder,
  label,
  descriptionLabel,
  value,
  defaultValue,
  style,
  disabled,
  required,
}: InputCustomProps) => {
  const errorClassName = hasError ? 'is-invalid' : '';
  const inputClassName = `input-text ${errorClassName} ${className}`;
  const props = defaultValue != undefined || defaultValue != null ? {defaultValue: defaultValue} : {value: value || ''};
  return (
    <>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <textarea
        disabled={disabled}
        style={style}
        placeholder={placeholder}
        className={cn(inputClassName, {[`h-${height}px`]: height})}
        name={name}
        onChange={onChange}
        {...props}
      />
    </>
  );
};
