import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalRejectReason, ModalRejectReasonConfig} from './modal-reject-reason';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {EntityId} from '../../../api/base/BaseEntity';
import {useTransactionsApi} from '../../../hooks/apis/use-transactions-api';

type ShowModalPayload = {
  transactionId: EntityId;
  type: 'CANCEL' | 'REJECT';
  modalConfiguration?: ModalRejectReasonConfig;
};

interface IModalRejectReasonTransactionContext {
  modalRejectReasonTransactionVisible: boolean;

  showRejectReasonTransactionModal(arg: ShowModalPayload): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalRejectReasonTransactionContext = createContext<IModalRejectReasonTransactionContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalRejectReasonTransactionProvider = ({children}: any) => {
  const intl = useIntl();

  const api = useTransactionsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [config, setConfig] = useState<ShowModalPayload | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const showModal = async (payload: ShowModalPayload) => {
    setConfig(payload);
    setVisible(true);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setReason('');
    setError(null);
    setValidationError(null);
    setConfig(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    if (config === null || config.transactionId === null) {
      throw new Error('Request Id should be not null');
    }

    try {
      setError(null);
      if (config.type === 'REJECT') {
        await api.rejectTransaction(config.transactionId, reason);
      } else if (config.type === 'CANCEL') {
        await api.cancelTransaction(config.transactionId, reason);
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`Unknown operation type ${config.type}`);
      }

      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalRejectReasonTransactionContext = {
    modalRejectReasonTransactionVisible: visible,
    showRejectReasonTransactionModal: showModal,
  };

  return (
    <ModalRejectReasonTransactionContext.Provider value={value}>
      {children}
      <ModalRejectReason
        error={error}
        reason={reason}
        visible={visible}
        validationErrors={validationErrors}
        modalConfiguration={config?.modalConfiguration}
        onReasonChange={r => setReason(r)}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalRejectReasonTransactionContext.Provider>
  );
};

export const useModalRejectReasonTransaction = () => {
  return useContext(ModalRejectReasonTransactionContext);
};
