import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {InviteUserRequest} from './create-edit-invite-user-request-modal-context';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {getEnumKeys, PartialNullable} from '../../../../types/types';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {InputSelect, SelectOptions} from '../../Inputs/InputSelect';
import {UserType} from '../../../api/DTOs/User';

interface IModalProps {
  visible: boolean;
  showContractorSelect: boolean;
  errors?: string | null;
  channels?: SelectOptions;
  contractors?: SelectOptions;
  validationErrors?: ValidationErrorsType<InviteUserRequest>;
  entity: PartialNullable<InviteUserRequest>;
  onChange: (key: keyof InviteUserRequest, value: any) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateEditInviteUser: React.FC<IModalProps> = ({
  visible,
  errors,
  entity,
  channels,
  showContractorSelect,
  contractors,
  onChange,
  validationErrors,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={handleOkClick} onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>
          <FormattedMessage id={'INVITE_USER'} />
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <form className={'form'}>
          <AlertCustom
            text={errors || ''}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
            visible={errors !== null}
          />

          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('email', validationErrors)}>
              <InputText
                required
                value={entity.email}
                label={intl.formatMessage({id: 'EMAIL'})}
                hasError={!!getValidationErrorMessage('email', validationErrors)}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => onChange('email', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>
            <ValidateErrorWrapper message={getValidationErrorMessage('user_type', validationErrors)}>
              <InputSelect
                required
                value={entity.user_type}
                label={intl.formatMessage({id: 'TYPE'})}
                hasError={!!getValidationErrorMessage('user_type', validationErrors)}
                isClearable={false}
                ariaLabel={'arial-label'}
                onChange={value => onChange('user_type', value as UserType)}
                options={getEnumKeys(UserType)
                  .filter(type => type !== UserType.ADMIN)
                  .map(type => ({
                    value: type,
                    label: intl.formatMessage({id: type}),
                  }))}
                placeholder={intl.formatMessage({id: 'USER_TYPE'})}
              />
            </ValidateErrorWrapper>

            {entity.user_type === UserType.ARTIST && (
              <ValidateErrorWrapper message={getValidationErrorMessage('y_channel_id', validationErrors)}>
                <InputSelect
                  required
                  isLoading={channels == null}
                  value={entity.y_channel_id}
                  label={intl.formatMessage({id: 'CHANNEL'})}
                  hasError={!!getValidationErrorMessage('y_channel_id', validationErrors)}
                  isClearable={false}
                  ariaLabel={'arial-label'}
                  onChange={value => onChange('y_channel_id', value)}
                  options={channels ?? []}
                  placeholder={intl.formatMessage({id: 'YOUTUBE_CHANNEL'})}
                />
              </ValidateErrorWrapper>
            )}

            {entity?.user_type === UserType.USER && (
              <>
                {showContractorSelect && (
                  <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
                    <InputSelect
                      required
                      isLoading={contractors == null}
                      value={entity.contractor_id}
                      label={intl.formatMessage({id: 'CONTRACTOR'})}
                      hasError={!!getValidationErrorMessage('contractor_id', validationErrors)}
                      isClearable={false}
                      ariaLabel={'arial-label'}
                      onChange={value => onChange('contractor_id', value)}
                      options={contractors ?? []}
                      placeholder={intl.formatMessage({id: 'CONTRACTOR'})}
                    />
                  </ValidateErrorWrapper>
                )}
                <ValidateErrorWrapper message={getValidationErrorMessage('allow_access_to_finance', validationErrors)}>
                  <InputCheckbox
                    label={intl.formatMessage({id: 'ALLOW_ACCESS_TO_FINANCE'})}
                    value={entity?.allow_access_to_finance ?? false}
                    onChange={() => onChange('allow_access_to_finance', !(entity?.allow_access_to_finance ?? false))}
                  />
                </ValidateErrorWrapper>
              </>
            )}
          </div>
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
