import {Filter} from '../app/api/DTOs/IFilterDtos';
import config from './config';
import {EntityId} from '../app/api/base/BaseEntity';

export class ExternalRoutes {
  static getReadMoreAboutAgeLimitRoute() {
    return 'https://support.google.com/youtube/answer/9527654?hl=ru';
  }

  static getYoutubeEmbedRoute(videoId: string) {
    return `https://www.youtube.com/embed/${videoId}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com`;
  }

  static getExternalYoutubeVideoRoute(youtubeVideoId: string) {
    return `${config.YOUTUBE_VIDEO_URL}${youtubeVideoId}`;
  }
}

export class CommonRoutes {
  static getUsersManagementPage() {
    return '/users-management';
  }

  static getPartnerRoute(hash: string) {
    return `api/partner/${hash}`;
  }

  static getContractorDocumentsRoute() {
    return '/contractor-documents';
  }

  static getSpecifyUserManagementRoute(userId: string | number) {
    return `${this.getUsersManagementPage()}/${userId}`;
  }

  static getMyOrganizationRoute() {
    return '/my-organization';
  }

  static getFinancialReportsRoute() {
    return '/financial-reports';
  }

  static getMyOrganizationEditRoute() {
    return `${this.getMyOrganizationRoute()}/edit`;
  }
}

export class AdminRoutes {
  static routeGroupPrefix = '/admin';

  static getReportsPeriodsRoute() {
    return `${this.routeGroupPrefix}/reports-periods`;
  }

  static getFilesRoute() {
    return this.routeGroupPrefix + '/files';
  }

  static getGenresRoute() {
    return this.routeGroupPrefix + '/genres';
  }

  static getCountriesRoute() {
    return this.routeGroupPrefix + '/countries';
  }

  static getDashboardRoute() {
    return this.routeGroupPrefix + '/dashboard';
  }

  static getContractorsRoute() {
    return this.routeGroupPrefix + '/contractors';
  }

  static getCreateContractorRoute() {
    return `${this.getContractorsRoute()}/create`;
  }

  static getSpecifyContractorRoute(contractorId: EntityId) {
    return `${this.routeGroupPrefix}/contractors/${contractorId}`;
  }

  static getCreateSupAgreementRoute(contractId: EntityId) {
    return `${this.getSpecifyContractRoute(contractId)}/create-sup-agreements`;
  }

  static getSpecifySupAgreementRoute(contractId: EntityId, agreementId: EntityId) {
    return `${this.getSpecifyContractRoute(contractId)}/${agreementId}`;
  }

  static getEditSupAgreementRoute(contractId: EntityId, agreementId: EntityId) {
    return `${this.getSpecifyContractRoute(contractId)}/${agreementId}/edit`;
  }

  static getEditContractorRoute(contractorId: EntityId) {
    return `${this.routeGroupPrefix}/contractors/${contractorId}/edit`;
  }

  static getContractsRoute() {
    return this.routeGroupPrefix + '/contracts';
  }

  static getSpecifyContractRoute(contractId: string | number) {
    return `${this.routeGroupPrefix}/contracts/${contractId}`;
  }

  static getCreateContractRoute(filter?: Filter) {
    const query = this.applyFilters(filter);
    return `${this.routeGroupPrefix}/contracts/create${query}`;
  }

  static getEditContractRoute(contractId: EntityId) {
    return `${this.routeGroupPrefix}/contracts/${contractId}/edit`;
  }

  static getUserManagementRoute() {
    return '/users-management';
  }

  static getSpecifyUserManagementRoute(userId: string | number) {
    return `/users-management/${userId}`;
  }

  static getSpecifyFinancialAccountsRoute(contractorId: EntityId, accountId: EntityId) {
    return `${this.getSpecifyContractorRoute(contractorId)}/${accountId}`;
  }

  static getPaymentRequestsRoute() {
    return this.routeGroupPrefix + '/payment-requests';
  }

  static getSpecifyPaymentRequestRoute(requestId: string | number) {
    return `${this.routeGroupPrefix}/payment-requests/${requestId}`;
  }

  static getConnectChannelRequestsRoute() {
    return this.routeGroupPrefix + '/connect-youtube-channels-requests';
  }

  static getSpecifyConnectChannelRequestRoute(requestId: string | number) {
    return `${this.routeGroupPrefix}/youtube-channels-requests/${requestId}`;
  }

  static getFinancialOperationsRoute(filters?: Filter) {
    return `${this.routeGroupPrefix}/financial-operations${this.applyFilters(filters)}`;
  }

  static getSpecifyFinancialOperations(id: EntityId) {
    return `${this.getFinancialOperationsRoute()}/${id}`;
  }

  static getSpecifyTransactionRoute(operationId: EntityId, transactionId: EntityId) {
    return `${this.getSpecifyFinancialOperations(operationId)}/${transactionId}`;
  }

  static getUsersActionsRoute() {
    return this.routeGroupPrefix + '/users-actions';
  }

  static getFinancialReportsRoute() {
    return this.routeGroupPrefix + '/import-financial-reports';
  }

  static getUploadFinancialReports() {
    return this.routeGroupPrefix + '/import-financial-reports/upload';
  }

  static getYoutubeIntegrationRoute() {
    return this.routeGroupPrefix + '/youtube-integration';
  }

  static getInstructionsRoute() {
    return this.routeGroupPrefix + '/instructions';
  }

  static getSupportRoute() {
    return this.routeGroupPrefix + '/support';
  }

  static getCreateArticleRoute(chapterId: string | number) {
    // Так как используется в разделе базы знаний используется общий компонент и общие роуты,
    // то не требуется добавлять префикс роутов администратора.
    return `/knowledge-base/${chapterId}/create`;
  }

  static getEditArticleRoute(articleId: string | number) {
    // Так как используется в разделе базы знаний используется общий компонент и общие роуты,
    // то не требуется добавлять префикс роутов администратора.
    return `/knowledge-base/${articleId}/edit`;
  }

  static getFeedsRoute(filters?: Filter) {
    return `${this.routeGroupPrefix}/feeds${this.applyFilters(filters)}`;
  }

  static getSupportTicketRoute(id: number | string) {
    return `${this.routeGroupPrefix}/support/${id}`;
  }

  static getAdvertisementRoute() {
    return this.routeGroupPrefix + '/advertisements';
  }

  static getSpecifyAdvertisementRequestRoute(id: string | number) {
    return `${this.routeGroupPrefix}/advertisements/${id}`;
  }

  static getMailingsRoute() {
    return this.routeGroupPrefix + '/mailings';
  }

  static getCreateMailingRoute() {
    return this.getMailingsRoute() + '/create';
  }

  static getEditMailingRoute(mailingId: string | number) {
    return `${this.getMailingsRoute()}/${mailingId}/edit`;
  }

  static getSpecifyMailingRoute(mailingId: number | string) {
    return `${this.routeGroupPrefix}/mailings/${mailingId}`;
  }

  static getApplicationSettingsRoute() {
    return `/application-settings`;
  }

  static getBackgroundProcessesRoute() {
    return '/background-processes';
  }

  static getMcPayStatesRoute() {
    return '/media-cube-states';
  }

  static getMcPayAuthStateRoute(id: EntityId) {
    return `/media-cube-states/${id}`;
  }

  static getPlatformIntegrationRoute() {
    return '/integrations';
  }

  private static applyFilters(filters: Filter | undefined): string {
    if (!filters) return '';
    return (
      '?' +
      Object.keys(filters)
        .map(key => {
          return `${key}=${filters[key as keyof Filter]}`;
        })
        .join('&')
    );
  }
}

export class Routes {
  static getInviteRegistrationRoute(inviteToken: string) {
    return `/auth/invite/${inviteToken}`;
  }

  static getExternalYoutubeChannelRoute(youtubeChannelId: string) {
    return `${config.YOUTUBE_CHANNEL_URL}/${youtubeChannelId}`;
  }

  static getDashboardRoute() {
    return '/dashboard';
  }

  static getDownloadsRoute() {
    return '/downloads';
  }

  static getDownloadRoute(id: EntityId) {
    return `/downloads/${id}`;
  }

  static getYoutubeVideosRoute() {
    return '/youtube-videos';
  }

  static getVideosRoute(filters?: Filter) {
    return `/videos${this.applyFilters(filters)}`;
  }

  static getSpecifyVideosRoute(id: string | number) {
    return `/videos/${id}`;
  }

  static getUploadVideoRoute() {
    return '/videos/upload';
  }

  static getEditUploadVideoRoute(id: EntityId) {
    return `/videos/${id}/edit`;
  }

  static getYoutubeSpecifyVideoRoute(videoId: EntityId) {
    return `/youtube-videos/${videoId}`;
  }

  static getYoutubeChannelsRoute(filters?: Filter) {
    return `/youtube-channels${this.applyFilters(filters)}`;
  }

  static getYoutubeSpecifyChannelRoute(channelId: string | number) {
    return `/youtube-channels/${channelId}`;
  }

  static getSpecifyChannelVideos(channelId: string, filters?: Filter) {
    return `/youtube-channels/${channelId}/videos${this.applyFilters(filters)}`;
  }

  static getFinancialReportsRoute() {
    return '/financial-reports';
  }

  static getSpecifyFinancialReportRoute(reportId: string) {
    return `/financial-reports/${reportId}`;
  }

  static getRevenuesPageRoute() {
    return '/revenues';
  }

  static getLoginRoute() {
    return '/auth/login';
  }

  static getLogoutRoute() {
    return '/auth/logout';
  }

  static getAuthRoute() {
    return '/auth';
  }

  static getForgotPasswordRoute() {
    return '/auth/forgot-password';
  }

  static getProfileRoute() {
    return '/profile';
  }

  static getNotFoundRoute() {
    return '/not-found';
  }

  static getArtistsRoute() {
    return '/artists';
  }

  static getSpecifyArtistsRoute(id: EntityId) {
    return `/artists/${id}`;
  }

  static getResetPasswordRoute(token: string) {
    return `/auth/reset-password/${token}`;
  }

  static getRegistrationRoute() {
    return '/auth/registration';
  }

  static getSupportRoute() {
    return '/support';
  }

  static getSupportTicketRoute(id: number | string) {
    return '/support/' + id;
  }

  static getUserAgreementRoute() {
    return '/auth/agreement';
  }

  static getUserAgreementContentRoute() {
    return '/static/agreement.html';
  }

  static getAdvertisementsRoute() {
    return '/advertisements';
  }

  static getCreateAdvertisementRequestRoute() {
    return `${this.getAdvertisementsRoute()}/create`;
  }

  static getEditAdvertisementRequestRoute(id: string | number) {
    return `${this.getAdvertisementsRoute()}/${id}/edit`;
  }

  static getSpecifyAdvertisementRequestRoute(id: string | number) {
    return `${this.getAdvertisementsRoute()}/${id}`;
  }

  static getKnowledgeBaseRoute() {
    return '/knowledge-base';
  }

  static getSpecifyArticleRoute(articleId: string | number) {
    return `${this.getKnowledgeBaseRoute()}/${articleId}`;
  }

  static getCreateArticleRoute(chapterId: string | number) {
    return `${this.getKnowledgeBaseRoute}/${chapterId}/create`;
  }

  private static applyFilters(filters: Filter | undefined): string {
    if (!filters) return '';
    return (
      '?' +
      Object.keys(filters)
        .map(key => {
          return `${key}=${filters[key as keyof Filter]}`;
        })
        .join('&')
    );
  }
}
