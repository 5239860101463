import React from 'react';
import {formatBytes} from '../../../utils/utils';
import {AttachmentMetadata} from '../../../components/file-uploader';
import {useIntl} from '../../../hooks/use-intl';
import cn from 'classnames';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {FormattedMessage} from 'react-intl';
import {BootstrapColors, BootstrapSizes} from '../../../../styles/bootstap-colors';

export enum AttachmentType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

type Props = {
  type: AttachmentType;
  metadata: AttachmentMetadata | null;
  toolBar?: {
    showTryAgain?: boolean;
    onDeleteClick: () => void;
    onTryAgainClick: () => void;
    onCancelUploadClick?: () => void;
  };
  uploadInfo?: {
    loading: boolean;
    loadingCancel?: boolean;
    successUpload: boolean;
    errorUpload: boolean;
    progressPercent?: number;
  };
};

export const AttachmentBlock: React.FC<Props> = ({type, metadata, uploadInfo, toolBar}: Props) => {
  const intl = useIntl();

  return (
    <>
      {metadata && (
        <div style={{backgroundColor: '#f3f6f9'}} className={'rounded'}>
          <div className={'d-none d-xl-flex justify-content-center'}>
            {type === AttachmentType.VIDEO && (
              <video
                controls
                src={metadata.url}
                style={{maxWidth: '350px'}}
                className={'max-h-200px bg-dark w-100 overlay-wrapper rounded-top'}
              />
            )}
            {type === AttachmentType.IMAGE && (
              <img
                src={metadata.url}
                style={{border: '2px solid #f3f6f9', maxWidth: '350px'}}
                alt='preview'
                className={'w-100 rounded-top'}
              />
            )}
          </div>

          <div className={'px-3 py-2'}>
            <div className={'font-weight-bolder text-muted mb-0'}>Название файла</div>
            <p>{metadata.filename}</p>
            <div className={'font-weight-bolder text-muted  mb-0'}>Размер</div>
            <p>{formatBytes(metadata.size)}</p>

            {uploadInfo && (
              <>
                <div className={'font-weight-bolder text-muted  mb-0'}>Загрузка</div>
                {type === AttachmentType.VIDEO && uploadInfo.loading && (
                  <div className={'w-100'}>
                    <div className='progress mb-5' style={{height: '20px'}}>
                      <div
                        className={cn('progress-bar bg-primary', {
                          'bg-success': !uploadInfo.loading && uploadInfo?.progressPercent === 100,
                          'progress-bar-animated': uploadInfo.loading,
                          'progress-bar-striped': uploadInfo.loading,
                        })}
                        role='progressbar'
                        style={{width: `${uploadInfo.progressPercent}%`}}
                      />
                      {uploadInfo?.progressPercent === 0 && (
                        <div
                          className='progress-bar bg-secondary progress-bar-animated progress-bar-striped'
                          role='progressbar'
                          style={{width: '100%'}}
                        />
                      )}
                    </div>
                  </div>
                )}
                {type === AttachmentType.IMAGE && uploadInfo.loading && (
                  <div className={'font-weight-bold mb-5'}>
                    <span className={'mr-1'}>Изображение загружается</span>
                    <Svg src={ICONS.SPINNER} spin />
                  </div>
                )}
                {uploadInfo.successUpload && (
                  <p className={'font-weight-bold mb-5'}>{intl.formatMessage({id: `${type}_SUCCESSFUL_UPLOAD`})}</p>
                )}
                {uploadInfo.errorUpload && (
                  <p className={'font-weight-bold mb-5'}>{intl.formatMessage({id: `${type}_FAILED_UPLOAD`})}</p>
                )}
              </>
            )}

            {toolBar && (
              <>
                <div className={'font-weight-bolder text-muted  mb-0'}>Действия</div>
                <Toolbar
                  className={'d-flex'}
                  items={[
                    {
                      visible: uploadInfo?.successUpload,
                      type: 'CUSTOM',
                      render: () => {
                        return (
                          <span
                            className={'font-weight-bolder cursor-pointer text-underline'}
                            onClick={toolBar.onDeleteClick}>
                            <FormattedMessage id={'DELETE'} />
                          </span>
                        );
                      },
                    },
                    {
                      visible: toolBar.showTryAgain,
                      type: 'CUSTOM',
                      render: () => {
                        return (
                          <span
                            className={'font-weight-bolder cursor-pointer text-underline'}
                            onClick={toolBar.onTryAgainClick}>
                            <FormattedMessage id={'TRY_AGAIN'} />
                          </span>
                        );
                      },
                    },
                    {
                      visible: uploadInfo?.loading,
                      type: 'CUSTOM',
                      render: () => {
                        return (
                          <span
                            className={'font-weight-bolder cursor-pointer text-underline'}
                            onClick={toolBar.onCancelUploadClick}>
                            <FormattedMessage id={'CANCEL'} />
                          </span>
                        );
                      },
                    },
                    {
                      visible: uploadInfo?.loadingCancel && type === AttachmentType.VIDEO,
                      loading: uploadInfo?.loadingCancel,
                      type: 'BUTTON',
                      title: intl.formatMessage({id: 'WAIT_CANCEL_UPLOAD_VIDEO_MESSAGE'}),
                      iconSize: BootstrapSizes.MD,
                      iconColor: BootstrapColors.DARK,
                      className: 'btn btn-sm btn-text',
                      onClick: null,
                    },
                  ]}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
