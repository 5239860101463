import {combineReducers} from 'redux';

import {AuthReducer, AuthState} from '../app/modules/Auth';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {UpdateReducer, UpdaterState} from '../updater/updater-redux';

export type IApplicationStore = {
  auth: AuthState;
  updater: UpdaterState;
};

const persistConfig = {
  key: 'auth-storage',
  storage,
  whitelist: ['authToken'],
  hello: 'test',
};

export const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, AuthReducer),
  updater: UpdateReducer,
});
