import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IResponseBase} from '../response-contracts/base-response';
import {FinancialReportType} from './FinancialBulkReports';
import {IQueryParams} from '../DTOs/IFilterDtos';
import {IFinancialPeriodResponse, IFinancialReportsResponse} from './financial-reports-response-contracts';
import {EntityId} from '../base/BaseEntity';

export class FinancialReportApi extends BaseEndpoint {
  public async getReportPeriodsForUpload(): Promise<IFinancialPeriodResponse> {
    const response = await this.get<IFinancialPeriodResponse>(
      `${config.BASE_API_URL}/financial-reports/available-report-periods-for-upload`,
    );
    return response.data;
  }

  public async getReportPeriodsForDownload(): Promise<IFinancialPeriodResponse> {
    const response = await this.get<IFinancialPeriodResponse>(
      `${config.BASE_API_URL}/financial-reports/available-report-periods-for-download`,
    );
    return response.data;
  }

  public async postDetailedExcelReport(contractId: EntityId | null, reportPeriodIds: EntityId[]): Promise<unknown> {
    const response = await this.post(`${config.BASE_API_URL}/financial-reports/detailed-excel-report`, {
      fields: {
        contract_id: contractId,
        report_period_ids: reportPeriodIds,
      },
    });
    return response.data;
  }

  public async postDownloadAccountsBalancesReport(filters?: IQueryParams) {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IFinancialPeriodResponse>(
      `${config.BASE_API_URL}/financial-reports/accounts-balance-excel-report?${query}`,
    );
    return response.data;
  }

  public async sendFinancialReport(
    period: string | null,
    files: {[key: string]: File},
    source: string | null,
  ): Promise<IResponseBase> {
    const formData = new FormData();
    formData.append('fields[report_period_id]', period || '');
    formData.append('fields[source]', source || '');
    Object.keys(files).forEach(key => {
      const type = key as FinancialReportType;
      formData.append(`fields[${type}]`, files[type]);
    });

    const response = await this.postFile(`${config.BASE_API_URL}/financial-reports`, formData);
    return response.data;
  }

  public async getFinancialReports(filters?: IQueryParams): Promise<IFinancialReportsResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IFinancialReportsResponse>(`${config.BASE_API_URL}/financial-reports?${query}`);
    return response.data;
  }

  public async downloadFinancialReport(
    bulkReportId: number,
    fileName: string,
  ): Promise<{fileName: string; content: Blob}> {
    const response = await this.getFile(
      `${config.BASE_API_URL}/financial-reports/${bulkReportId}/${fileName}/download`,
    );
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }

  public async tryAgainProcessBulkReport(bulkReportId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/financial-reports/${bulkReportId}/repeat`,
      {},
    );
    return response.data;
  }
}
