import {BaseEntity, DateAsString, EntityId, float} from '../base/BaseEntity';
import {Currency} from '../DTOs/Transaction';
import {Feed} from '../DTOs/Feed';

export enum ContractType {
  PUBLISH_VIDEO = 'PUBLISH_VIDEO',
  YOUTUBE_PARTNER = 'YOUTUBE_PARTNER',
}

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum ReportFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export enum AvailableContractChange {
  is_change_youtube_channels = 'is_change_youtube_channels',
  is_add_youtube_channels = 'is_add_youtube_channels',
  is_delete_youtube_channels = 'is_delete_youtube_channels',

  IS_CHANGE_FEEDS = 'is_change_feeds',
  IS_CHANGE_EXPIRES_AT = 'is_change_expires_at',

  IS_CHANGE_VIDEOS = 'is_change_videos',
  IS_ADD_VIDEOS = 'is_add_videos',
  IS_DELETE_VIDEOS = 'is_delete_videos',
  IS_CHANGE_VIDEO_RATE_PERCENT = 'is_change_video_rate_percent',
}

export type VideoInContract = {video_id: EntityId; video_title: string};

export type YoutubeChannelInContract = {
  y_channel_id: EntityId;
  y_channel_title: string;
  rate_percent: number;
  is_referral: boolean;
};

export type ContractState = {
  id: EntityId;
  number: number;
  currency: Currency;

  contractor_id: EntityId;
  our_contractor_id: EntityId;

  videos: Array<VideoInContract> | null;
  youtube_channels: Array<YoutubeChannelInContract> | null;
  feeds: Array<Feed> | null;
  percent_rate: float | null;

  started_at: DateAsString;
  expires_at: DateAsString;
};

export interface Contract extends BaseEntity {
  number: number;
  type: ContractType;
  status: ContractStatus;
  report_frequency: ReportFrequency;
  currency: Currency;
  composite_number: string;
  contractor_id: EntityId;
  our_contractor_id: EntityId;
  our_contractor_name: string;
  contractor_name: string;
  started_at: string;
  expires_at: string | null;

  is_signed: boolean;
  our_company_id: EntityId;
  add_videos: Array<VideoInContract>;
  add_youtube_channels: Array<YoutubeChannelInContract>;
  change_feeds: Array<Feed>;
  change_video_rate_percent: number | null;
  available_changes: Array<AvailableContractChange>;
}
