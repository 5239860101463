import React from 'react';
import {useIntl} from '../../../../../../hooks/use-intl';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {UpdateFields} from '../../../../../../hooks/use-advanced-state';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateFields<Contractor>;
};

export const BankFieldsRub: React.FC<Props> = ({contractor, validationErrors, updateFields}) => {
  const intl = useIntl();
  return <>
    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_name', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_name}
        label={intl.formatMessage({id: 'BANK_NAME'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_name', validationErrors)}
        onChange={e => updateFields({rub_bank_name: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_NAME'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_address', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_address}
        label={intl.formatMessage({id: 'BANK_ADDRESS'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_address', validationErrors)}
        onChange={e => updateFields({rub_bank_address: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_ADDRESS'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_number', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_number}
        label={intl.formatMessage({id: 'BANK_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_number', validationErrors)}
        onChange={e => updateFields({rub_bank_number: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('rub_bank_account_number', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_account_number}
        label={intl.formatMessage({id: 'BANK_ACCOUNT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_account_number', validationErrors)}
        onChange={e => updateFields({rub_bank_account_number: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_ACCOUNT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('rub_bank_correspondent_account_number', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_correspondent_account_number}
        label={intl.formatMessage({id: 'BANK_CORRESPONDENT_ACCOUNT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_correspondent_account_number', validationErrors)}
        onChange={e => updateFields({rub_bank_correspondent_account_number: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_CORRESPONDENT_ACCOUNT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_inn', validationErrors)}>
      <InputText
        value={contractor?.rub_bank_inn}
        label={intl.formatMessage({id: 'BANK_INN'})}
        hasError={!!getValidationErrorMessage<Contractor>('rub_bank_inn', validationErrors)}
        onChange={e => updateFields({rub_bank_inn: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_INN'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>
  </>;
};
