import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useIntl} from '../../../../hooks/use-intl';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../../configs/routes';
import {useLoading} from '../../../../hooks/use-loading';
import {useSubAgreementsApi} from '../../../../hooks/apis/use-sub-agreements-api';
import {IQueryParams} from '../../../../api/DTOs/IFilterDtos';

export enum SupAgreementAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
}

export type HandleSupAgreementActionPayload =
  | HandleSupAgreementCreateAction
  | HandleSupAgreementEditAction
  | HandleDownloadSupAgreementAction
  | HandleSupAgreementDeleteAction;

export type HandleSupAgreementCreateAction = {
  type: SupAgreementAction.CREATE;
};

export type HandleSupAgreementEditAction = {
  type: SupAgreementAction.EDIT;
  entity: SupAgreement;
};

export type HandleSupAgreementDeleteAction = {
  type: SupAgreementAction.DELETE;
  entity: SupAgreement;
  afterAction?:
    | {
        type: 'redirect';
        to: string;
      }
    | {
        type: 'fetch';
        cb?: (params: IQueryParams) => Promise<any>;
      };
};

export type HandleDownloadSupAgreementAction = {
  type: SupAgreementAction.DOWNLOAD;
  entity: SupAgreement;
};

export function useSupAgreementActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useSubAgreementsApi();
  const history = useHistory();
  const [loadings, startLoading, stopLoading] = useLoading({
    generateTemplate: false,
    delete: false,
  });

  const handle = async (payload: HandleSupAgreementActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case SupAgreementAction.EDIT: {
          history.push(AdminRoutes.getEditSupAgreementRoute(payload.entity.contract_id, payload.entity.id));
          break;
        }
        case SupAgreementAction.DELETE: {
          startLoading('delete');
          await api.remove(payload.entity.id);
          toast.success(intl.formatMessage({id: 'SUP_AGREEMENT_SUCCESS_DELETED'}));
          if (payload.afterAction?.type === 'redirect') {
            history.push(payload.afterAction.to);
          } else if (payload.afterAction?.type === 'fetch') {
            (payload.afterAction.cb ?? fetch)?.({});
          }
          break;
        }
        case SupAgreementAction.DOWNLOAD: {
          startLoading('generateTemplate');
          if (!payload.entity?.id) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error();
          }

          const res = await api.generateContractTemplate(payload.entity?.id);
          saveAs(res.content, res.fileName);
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err?.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('delete');
      stopLoading('generateTemplate');
    }
  };
  return {
    handleSupAgreementActions: handle,
    loadings,
  };
}
