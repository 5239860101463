import {ApiRequestException} from "../api/axios-instance";
import {EXCEPTION_TYPE} from "../api/exceptions/BaseException";

export function isApiException(error: Error | ApiRequestException) {
  return !!(error as ApiRequestException).errorType;
}

export function isValidationException(error: Error | ApiRequestException) {
  if (!isApiException(error)) {
    return false;
  }

  return (error as ApiRequestException).errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION;
}
