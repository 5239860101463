import React from 'react';
import {
  FinancialBulkReports,
  FinancialReportType,
  ReportsProcessingStatus,
} from '../../../api/financial-report-api/FinancialBulkReports';
import {FormattedMessage, useIntl} from 'react-intl';
import {FinancialReportsTable} from './financial-reports-table';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {capitalizeFirstLetter} from '../../../utils/utils';
import {BadgeCustom} from '../../../components/badge-custom';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

interface IReportsCardProps {
  reportsRecord: FinancialBulkReports;

  onDownloadClick(bulkReportId: number, reportType: FinancialReportType): any;

  onTryRepeatProcessClick(bulkReportId: number): void;
}

export const ReportsCard: React.FC<IReportsCardProps> = ({reportsRecord, onDownloadClick, onTryRepeatProcessClick}) => {
  const intl = useIntl();

  const handleDownloadClick = (type: FinancialReportType): boolean => {
    return onDownloadClick(reportsRecord.id, type);
  };

  const handleRepeatButtonClick = async () => {
    onTryRepeatProcessClick(reportsRecord.id);
  };

  const renderToolbarButton = () => {
    let colorVariant;
    switch (reportsRecord.status) {
      case ReportsProcessingStatus.CREATED:
        colorVariant = 'secondary';
        break;
      case ReportsProcessingStatus.IN_WORK:
        colorVariant = 'primary';
        break;
      case ReportsProcessingStatus.ERROR:
        colorVariant = 'danger';
        break;
      case ReportsProcessingStatus.PROCESSED:
        colorVariant = 'success';
        break;
      default:
        colorVariant = 'default';
    }

    if (reportsRecord.status === ReportsProcessingStatus.ERROR) {
      return (
        <Toolbar
          items={[
            {
              type: 'DROPDOWN',
              title: intl.formatMessage({id: reportsRecord.status}),
              actions: [{title: intl.formatMessage({id: 'TRY_AGAIN'}), value: '0'}],
              className: 'btn-danger',
              onSelect: handleRepeatButtonClick,
            },
          ]}
        />
      );
    }
    return (
      <>
        <BadgeCustom variant={colorVariant}>
          <FormattedMessage id={reportsRecord.status} />
        </BadgeCustom>
      </>
    );
  };

  const fileNamesWithType = Object.entries(reportsRecord).filter(v => v[0].indexOf('FILE_NAME') != -1 && v[1] != null);

  return (
    <>
      <div className='card card-custom border gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              {capitalizeFirstLetter(
                intl.formatDate(new Date(reportsRecord.year, reportsRecord.month - 1), {
                  month: 'long',
                  year: 'numeric',
                }),
              )}
            </h3>
          </div>
          <div className={'card-toolbar'}>{renderToolbarButton()}</div>
        </div>
        <div className='card-body'>
          <AlertCustom
            text={reportsRecord.errorDescription}
            type={BootstrapColors.LIGHT_DANGER}
            visible={reportsRecord.errorDescription != null}
            iconClassName={'svg-icon-danger'}
          />

          <FinancialReportsTable
            onDownloadClick={handleDownloadClick}
            reports={fileNamesWithType.map(f => {
              return {
                type: f[0].replace('_FILE_NAME', '') as FinancialReportType,
                fileName: f[1] as string,
              };
            })}
          />
        </div>
      </div>
    </>
  );
};
