import React, {useMemo} from 'react';
import objectPath from 'object-path';
import {useHtmlClassService} from '../_core/MetronicLayout';
import {Header} from '../../../app/layout/header/header';
import {HeaderMobile} from '../../../app/layout/header/header-mobile';
import {Aside} from '../../../app/layout/aside/aside';
import {Footer} from './footer/Footer';
import {LayoutInit} from './LayoutInit';
import {ScrollTop} from './extras/ScrollTop';
import cn from 'classnames';
import {useAuth} from '../../../app/hooks/use-auth';
import './Layout.scss';

export const Layout = ({children}: any) => {
  const uiService = useHtmlClassService();
  const {isArtist} = useAuth();
  const layoutProps = useMemo(() => {
    return {
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);

  const renderContent = () => {
    return (
      <div
        id='kt_content'
        className={cn(`content d-flex flex-column flex-column-fluid`, layoutProps.contentCssClasses)}>
        {!layoutProps.contentExtended && (
          <div className='d-flex flex-column-fluid'>
            <div className={layoutProps.contentContainerClasses}>{children}</div>
          </div>
        )}
        {layoutProps.contentExtended && {children}}
      </div>
    );
  };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      <HeaderMobile />
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-row flex-column-fluid page'>
          {layoutProps.asideDisplay && <Aside />}
          <div
            className={cn('d-flex flex-column flex-row-fluid wrapper', {'wrapper-for-artist': isArtist})}
            id='kt_wrapper'>
            <Header />
            {renderContent()}
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
      <LayoutInit />
    </>
  ) : (
    <div className='d-flex flex-column flex-root'>{children}</div>
  );
};
