import React from 'react';
import cn from 'classnames';

type Props = {
  label?: string;
  required?: boolean;
  description?: string;
  className?: {
    label?: string;
    description?: string;
  };
};

export const LabelWithDescription: React.FC<Props> = ({label, description, required, className}) => {
  return (
    <>
      {label && (
        <label
          className={cn(className?.label, 'font-weight-bolder', {
            'mb-0': description,
          })}>
          {label} {required && <span className={'text-danger'}>*</span>}
        </label>
      )}
      {description && (
        <p className={cn('mb-2', className?.description)} style={{color: '#606060'}}>
          {description}
        </p>
      )}
    </>
  );
};
