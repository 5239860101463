import React from 'react';
import SVG from 'react-inlinesvg';
import {FormattedNumber, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {YoutubeChannel} from '../../../api/DTOs/YoutubeChannel';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {YoutubeChannelRelation} from '../../../api/response-contracts/yotutube-channel-contracts';
import {useAuth} from '../../../hooks/use-auth';
import {Table} from '../../../components/table';

const VISIBLE_SVG = require('../../../images/svg/Visible.svg');
const SUBSCRIBER_SVG = require('../../../images/svg/Group.svg');
const VIDEO_LANE = require('../../../images/svg/Video-Lane.svg');

interface IYoutubeChannelsTableProps {
  channels: Array<YoutubeChannel>;
  relations: ManyRelations<YoutubeChannelRelation> | null;
}

export const YoutubeChannelsTable = ({channels, relations}: IYoutubeChannelsTableProps) => {
  const intl = useIntl();
  const {isAdmin} = useAuth();

  return (
    <Table
      data={channels}
      columns={[
        {
          id: 'Title',
          Header: intl.formatMessage({id: 'TITLE'}),
          forceApplySize: true,
          maxWidth: 150,
          minWidth: 50,
          Cell: ({value}) => {
            return (
              <Link to={Routes.getYoutubeSpecifyChannelRoute(value.id)}>
                <img src={tryGetPreviewOrStub(value.thumbnails).url} alt='image' className={'w-100 rounded'} />
              </Link>
            );
          },
        },
        {
          id: 'Header',
          Header: '',
          maxWidth: 250,
          Cell: ({value}) => {
            return (
              <div>
                <Link to={Routes.getYoutubeSpecifyChannelRoute(value.id)}>
                  <span className={'font-weight-bolder'}>{value.title}</span>
                </Link>
              </div>
            );
          },
        },
        {
          id: 'Contract',
          visible: isAdmin,
          Header: intl.formatMessage({id: 'CONTRACT'}),
          Cell: ({value}) => {
            const contract = relations?.contract?.[value.id];
            if (!contract) {
              return null;
            }
            return (
              <>
                <Link to={AdminRoutes.getSpecifyContractRoute(contract.id)}>
                  <span className={'font-weight-bolder'}>{contract.composite_number}</span>
                </Link>
              </>
            );
          },
        },
        {
          id: 'Contractor',
          visible: isAdmin,
          Header: intl.formatMessage({id: 'CONTRACTOR'}),
          Cell: ({value}) => {
            const contract = relations?.contract?.[value.id];
            if (!contract) {
              return null;
            }
            return (
              <>
                <Link to={AdminRoutes.getSpecifyContractorRoute(contract.contractor_id)}>
                  <span className={'font-weight-bolder'}>{contract.contractor_name}</span>
                </Link>
              </>
            );
          },
        },
        {
          id: 'OurContractor',
          visible: isAdmin,
          Header: intl.formatMessage({id: 'IS_OUR_COMPANY'}),
          Cell: ({value}) => {
            const contract = relations?.contract?.[value.id];
            if (!contract) {
              return null;
            }
            return (
              <>
                <Link to={AdminRoutes.getSpecifyContractorRoute(contract.our_contractor_id)}>
                  <span className={'font-weight-bolder'}>{contract.our_contractor_name}</span>
                </Link>
              </>
            );
          },
        },
        {
          Header: intl.formatMessage({id: 'SUBSCRIBERS'}),
          maxWidth: 100,
          Cell: ({value}) => {
            return (
              <div>
                <span className='svg-icon svg-icon-lg svg-icon-success mr-1'>
                  <SVG src={SUBSCRIBER_SVG} />
                </span>
                <span className={'font-weight-bolder'}>
                  <FormattedNumber value={value.subscriberCount} />
                </span>
              </div>
            );
          },
        },
        {
          Header: intl.formatMessage({id: 'VIEWS'}),
          maxWidth: 100,
          Cell: ({value}) => {
            return (
              <div>
                <span className='svg-icon svg-icon-lg svg-icon-primary mr-1'>
                  <SVG src={VISIBLE_SVG} />
                </span>
                <span className={'font-weight-bolder'}>
                  <FormattedNumber value={value.viewCount} />
                </span>
              </div>
            );
          },
        },
        {
          Header: intl.formatMessage({id: 'VIDEOS'}),
          maxWidth: 100,
          Cell: ({value}) => {
            return (
              <div>
                <span className='svg-icon svg-icon-lg svg-icon-secondary mr-1'>
                  <SVG src={VIDEO_LANE} />
                </span>
                <span className={'font-weight-bolder'}>
                  <FormattedNumber value={value.videoCount} />
                </span>
              </div>
            );
          },
        },
      ]}
    />
  );
};
