import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {VideoAction} from './use-video-handler-actions';
import {RowActionButton} from '../../components/table-actions-button';
import {Video, VideoStatus} from '../../api/DTOs/Video';
import {DefaultTable} from '../../components/default-table';
import {useIntl} from '../../hooks/use-intl';
import {Link} from 'react-router-dom';
import {Routes} from '../../../configs/routes';
import {ManyRelations} from '../../api/base/base-crud-api-response';
import {VideoPrivileges, VideoRelations} from '../../hooks/apis/use-videos-api';
import {EntityId} from '../../api/base/BaseEntity';
import {TableUtils} from '../../components/table-utils';
import {VIDEO_STATUS_COLOR_MAP} from './video-page/video-page';
import {BadgeCustom} from '../../components/badge-custom';
import {FEED_ICONS_MAP} from '../../api/DTOs/FeedIconsMap';
import {ImageWithPreloader} from '../../components/image-with-preloader';
import {IMAGES} from '../../images/images';

interface VideosTableProps {
  items: Array<Video>;
  relations: ManyRelations<VideoRelations> | null;
  privileges: Record<EntityId, VideoPrivileges>;
  onAction: (item: Video, action: VideoAction) => Promise<unknown>;
}

export const VideosTable = ({items, relations, privileges, onAction}: VideosTableProps) => {
  const intl = useIntl();
  const data = items;

  const columns = useMemo(() => {
    const defaultClassName = 'd-flex align-items-center w-100 h-100 font-weight-bolder justify-content-start';
    return [
      {
        id: 'Preview',
        forceApplySize: true,
        maxWidth: 120,
        accessor: (br: Video) => br,
        Cell: ({value}: {value: Video}) => {
          let url = '';
          if (
            value.watermarked_image_file_id &&
            relations?.watermarkedImageFile?.[value.watermarked_image_file_id]?.url != null
          ) {
            url = relations?.watermarkedImageFile?.[value.watermarked_image_file_id]?.url as string;
          } else if (value.image_file_id && relations?.imageFile?.[value.image_file_id]?.url != null) {
            url = relations?.imageFile?.[value.image_file_id]?.url as string;
          }

          return (
            <ImageWithPreloader
              containerHeight={'60px'}
              errorStub={IMAGES.VIDEO_PREVIEW_STUB}
              imageProps={{
                src: url,
                className: 'w-100 rounded',
                alt: intl.formatMessage({id: 'PREVIEW'}),
              }}
            />
          );
        },
      },
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 400,
        minWidth: 300,
        accessor: (br: Video) => br,
        Cell: ({value}: {value: Video}) => {
          let artists = '';
          if (relations?.artists) {
            // @ts-ignore
            artists = value.artists_ids.map(a => relations?.artists?.[a]?.name ?? '').join(', ');
          }

          let genres: any[] = [];
          if (relations?.genres) {
            // @ts-ignore
            genres = value.genres_ids.map(a => relations?.genres?.[a]?.name ?? '');
          }
          return (
            <div className={'font-weight-bolder'}>
              <div className={'d-flex align-items-center justify-content-start'}>
                <Link to={Routes.getSpecifyVideosRoute(value.id)}>
                  <span className={'font-weight-boldest'}>{artists}</span> &mdash; {value.title}
                </Link>
              </div>
              <span className={'font-size-sm text-muted'}>{value.code}</span>
              <div className={'mt-2'}>
                {genres.map(g => (
                  <span key={g} className='label label-inline font-weight-bolder mr-1 mb-1'>
                    {g}
                  </span>
                ))}
              </div>
            </div>
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Video) => br.status,
        Cell: ({value}: {value: VideoStatus}) => {
          return TableUtils.renderBaseContent(
            <BadgeCustom style={{whiteSpace: 'pre-wrap'}} variant={VIDEO_STATUS_COLOR_MAP[value]}>
              {intl.formatMessage({id: `VIDEO_STATUS_${value}`})}
            </BadgeCustom>,
          );
        },
      },
      {
        id: 'Feeds',
        Header: intl.formatMessage({id: 'FEEDS'}),
        maxWidth: 80,
        minWidth: 80,
        accessor: (br: Video) => br,
        Cell: ({value}: {value: Video}) => {
          return TableUtils.renderBaseContent(
            <div className={'d-flex flex-wrap'}>
              {value.feeds.map((feed, index) => {
                return (
                  <img
                    key={index}
                    src={FEED_ICONS_MAP[feed]}
                    width={24}
                    height={24}
                    alt={'logo'}
                    className={'rounded my-1 mr-2 ml-0'}
                  />
                );
              })}
            </div>,
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Video) => br,
        Cell: ({value}: {value: Video}) => {
          return (
            <div className={defaultClassName}>
              {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
            </div>
          );
        },
      },
      {
        id: 'Action',
        maxWidth: 50,
        accessor: (br: Video) => br,
        Cell: ({value}: {value: Video}) => {
          const actions = [];
          if (privileges?.[value.id]?.can_edit) {
            actions.push({title: intl.formatMessage({id: 'EDIT'}), value: VideoAction.EDIT});
          }

          if (privileges?.[value.id]?.can_delete) {
            actions.push({title: intl.formatMessage({id: 'DELETE'}), value: VideoAction.DELETE});
          }

          return TableUtils.renderActionButton(
            <RowActionButton rowDto={value} actions={actions} onSelect={onAction} />,
          );
        },
      },
    ];
  }, [relations, items, privileges]);

  if (items.length === 0) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return <DefaultTable data={data} columns={columns} />;
};
