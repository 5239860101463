import React from 'react';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: (props: {[P in keyof Contractor]?: Contractor[P]}) => void;
};

export const SignatoryFields: React.FC<Props> = ({validationErrors, updateFields, contractor}) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('legal_signatory_name_last', validationErrors)}>
        <InputText
          value={contractor?.legal_signatory_name_last}
          label={intl.formatMessage({id: 'LAST_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_last', validationErrors)}
          onChange={e => updateFields({legal_signatory_name_last: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LAST_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('legal_signatory_name_first', validationErrors)}>
            <InputText
              value={contractor?.legal_signatory_name_first}
              label={intl.formatMessage({id: 'FIRST_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_first', validationErrors)}
              onChange={e => updateFields({legal_signatory_name_first: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('legal_signatory_name_middle', validationErrors)}>
            <InputText
              value={contractor?.legal_signatory_name_middle}
              label={intl.formatMessage({id: 'MIDDLE_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_name_middle', validationErrors)}
              onChange={e => updateFields({legal_signatory_name_middle: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>

      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('legal_signatory_position', validationErrors)}>
        <InputText
          value={contractor?.legal_signatory_position}
          label={intl.formatMessage({id: 'LEGAL_SIGNATORY_POSITION'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_position', validationErrors)}
          onChange={e => updateFields({legal_signatory_position: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LEGAL_SIGNATORY_POSITION'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('legal_signatory_acts_by', validationErrors)}>
        <InputText
          value={contractor?.legal_signatory_acts_by}
          label={intl.formatMessage({id: 'LEGAL_SIGNATORY_ACTS_BY'})}
          hasError={!!getValidationErrorMessage<Contractor>('legal_signatory_acts_by', validationErrors)}
          onChange={e => updateFields({legal_signatory_acts_by: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LEGAL_SIGNATORY_ACTS_BY'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
    </>
  );
};
