import React from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {DateFormat, InputDatepicker} from '../../Inputs/InputDatepicker';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {PartialNullable} from '../../../../types/types';
import {UpdateFields} from '../../../hooks/use-advanced-state';

export type ModalChannelTransactionsIncomeReportFormState = {
  start_date: Date;
  end_date: Date;
};

interface IModalProps {
  visible: boolean;
  loading: boolean;
  entity: PartialNullable<ModalChannelTransactionsIncomeReportFormState>;
  updateEntity: UpdateFields<ModalChannelTransactionsIncomeReportFormState>;
  error?: string | null;
  validationErrors?: ValidationErrorsType;

  onHide(): void;

  onOkClick(): any;
}

export const ModalChannelTransactionsIncomeReport: React.FC<IModalProps> = ({
  visible,
  loading,
  entity,
  updateEntity,
  onHide,
  onOkClick,
  validationErrors,
}) => {
  const intl = useIntl();
  return (
    <>
      <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={onOkClick} onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'GENERATE_INCOME_REPORT'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <div className={'py-2'}>
            <ValidateErrorWrapper message={getValidationErrorMessage('start_date', validationErrors)}>
              <InputDatepicker
                required
                selectsStart
                startDate={entity.start_date}
                endDate={entity.end_date}
                showMonthYearPicker
                label={intl.formatMessage({id: 'PERIOD_START_DATE'})}
                selected={entity.start_date}
                format={DateFormat.MM_YYYY}
                onChange={date => updateEntity({start_date: date})}
                hasError={!!getValidationErrorMessage('start_date', validationErrors)}
              />
            </ValidateErrorWrapper>
            <ValidateErrorWrapper message={getValidationErrorMessage('end_date', validationErrors)}>
              <InputDatepicker
                required
                selectsEnd
                showMonthYearPicker
                startDate={entity.start_date}
                popperPlacement={'top-start'}
                endDate={entity.end_date}
                label={intl.formatMessage({id: 'PERIOD_END_DATE'})}
                selected={entity.end_date}
                format={DateFormat.MM_YYYY}
                onChange={date => updateEntity({end_date: date})}
                hasError={!!getValidationErrorMessage('end_date', validationErrors)}
              />
            </ValidateErrorWrapper>
          </div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
