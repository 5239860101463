import {PartialNullable} from '../../../../types/types';

export type OpenModalPayload<T = unknown> = OpenModalCreateEntityPayload<T> | OpenModalEditEntityPayload<T>;

export type OpenModalEditEntityPayload<T> = {
  type: 'EDIT';
  data: {
    entity: PartialNullable<T>;
  };
};

export type OpenModalCreateEntityPayload<T = any> = {
  type: 'CREATE';
  data?: {
    entity: PartialNullable<T>;
  };
};

export interface CloseModalEvent<T> {
  reason: CloseModalReason;
  payload?: T;
}

export enum CloseModalReason {
  OK,
  HIDE,
}
