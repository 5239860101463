import React, {useEffect, useState} from 'react';
import {EntityId} from '../../../api/base/BaseEntity';
import {useVideosApi, VideoRelations} from '../../../hooks/apis/use-videos-api';
import {Video} from '../../../api/DTOs/Video';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {VideoCard} from '../video-card';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {FormattedMessage} from 'react-intl';
import {SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';

type Props = {
  currentVideoId?: EntityId;
  artists_ids: Array<EntityId>;
  artistsAsString: string;
};

const MAX_OTHER_VIDEO_LENGTH = 10;
export const OtherVideos: React.FC<Props> = ({artists_ids, currentVideoId, artistsAsString}) => {
  const api = useVideosApi();
  const [videos, setVideos] = useState<Array<Video>>([]);
  const [relations, setRelations] = useState<ManyRelations<VideoRelations> | null>(null);
  useEffect(() => {
    const fetchVideos = async () => {
      const result = await api.getAll({
        filters: {
          artist_id: artists_ids,
        },
        sort: {
          fields: SortField.CREATED_AT,
          direction: SortDirection.DESC,
        },
      });
      setRelations(result.relations);
      setVideos(result.items.filter(v => v.id !== currentVideoId));
    };
    fetchVideos().then();
  }, []);

  return (
    <Card hideFooter className={{body: 'bg-light'}}>
      <CardTitle>
        <FormattedMessage id={'OTHER_ARTISTS_VIDEOS'} />: <span className={'text-muted'}>{artistsAsString}</span>
      </CardTitle>
      <CardBody>
        {videos.length === 0 ? (
          <div className={'text-center'}>
            <FormattedMessage id={'NOT_FOUND'} />
          </div>
        ) : (
          <div className='row'>
            {videos.slice(0, MAX_OTHER_VIDEO_LENGTH).map(video => {
              let url = null;
              if (
                video.watermarked_image_file_id &&
                relations?.watermarkedImageFile?.[video.watermarked_image_file_id]?.url !== undefined
              ) {
                url = relations?.watermarkedImageFile?.[video.watermarked_image_file_id]?.url as string;
              }

              if (video.image_file_id && relations?.imageFile?.[video.image_file_id]?.url) {
                url = relations?.imageFile?.[video.image_file_id]?.url as string;
              }

              return (
                <div key={video.id} className='col-xxxl-3 col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <VideoCard
                    video={video}
                    artists={video.artists_ids
                      .map(a => relations?.artists?.[a]?.name)
                      .filter(a => !!a)
                      .join(', ')}
                    previewUrl={url}
                  />
                </div>
              );
            })}
          </div>
        )}
      </CardBody>
    </Card>
  );
};
