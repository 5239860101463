import React from 'react';
import {ChangePassword} from '../../modules/Auth/pages/change-password';
import {FormattedMessage} from 'react-intl';
import {ChangeUserInformation} from './change-user-information';

export const ProfilePage: React.FC<any> = () => {
  return (
    <div className={'row'}>
      <div className={'col-md-6'}>
        <ChangeUserInformation />
      </div>
      <div className={'col-md-6'}>
        <ChangePassword />
        <div className={'card card-custom gutter-b'}>
          <div className={'card-header py-3'}>
            <div className='card-title'>
              <h3 className='card-label font-weight-bolder text-dark'>
                <FormattedMessage id={'REVOKE_ACCESS'} />
              </h3>
            </div>
          </div>
          <div className={`card-body`}>
            <FormattedMessage
              id={'REVOKE_ACCESS_DESCRIPTION'}
              values={{
                permissonslink: (str: any) => (
                  <a
                    href={'https://security.google.com/settings/security/permissions'}
                    target={'_blank'}
                    rel='noreferrer'>
                    {str}
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
