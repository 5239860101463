import {MultipartUploadFileToS3Config} from '../hooks/use-entity-api';
import {FilesApi, UploadedPartMetadata} from '../api/file-api/files-api';
import {splitArrayToChunks} from '../utils/utils';
import {ComplexNullable} from '../../types/types';

export enum MultiPartUploadSteps {
  UPLOAD_INITIATING = 'UPLOAD_INITIATING',
  UPLOAD_PARTS = 'UPLOAD_PARTS',
  UPLOAD_COMPLETING = 'UPLOAD_COMPLETING',
  CREATE_FILE_AFTER_UPLOAD = 'CREATE_FILE_AFTER_UPLOAD',
}

type PartUploadResult = {
  status: 'SUCCESS' | 'ERROR';
  data: string | Error;
};

type PartUploadFailedResult = {
  data: Error;
  partNumber: number;
  signedUrl: string;
};

export type MultipartUploaderErrorsPayloads =
  | MultipartUploaderErrorCreateFilePayload
  | MultipartUploaderErrorUploadPartsPayload
  | MultipartUploaderErrorCompleteUploadPayload;

export type MultipartUploaderErrorCreateFilePayload = {
  type: MultiPartUploadSteps.CREATE_FILE_AFTER_UPLOAD;
  fileName: string;
  url: string;
  bucket: string;
  size: number;
};

export type MultipartUploaderErrorUploadPartsPayload = {
  type: MultiPartUploadSteps.UPLOAD_PARTS;
  infoForComplete: {
    fileName: string;
    uploadId: number;
    size: number;
  };
  chunksCount: number;
  partsErrorResults: Array<PartUploadFailedResult>;
  allPartsResults: Array<ComplexNullable<UploadedPartMetadata>>;
};

export type MultipartUploaderErrorCompleteUploadPayload = {
  type: MultiPartUploadSteps.UPLOAD_COMPLETING;
  fileName: string;
  originalFileName: string;
  uploadId: number;
  partsInfo: Array<UploadedPartMetadata>;
  size: number;
};

export class MultipartUploaderErrorUploadParts extends Error {
  public payload: MultipartUploaderErrorUploadPartsPayload;

  constructor(message: string, payload: MultipartUploaderErrorUploadPartsPayload) {
    super(message);
    this.name = 'MultipartUploaderErrorUploadParts';
    this.payload = payload;
  }
}

export class MultipartUploaderErrorCompleteUpload extends Error {
  public payload: MultipartUploaderErrorCompleteUploadPayload;

  constructor(message: string, payload: MultipartUploaderErrorCompleteUploadPayload) {
    super(message);
    this.name = 'MultipartUploaderErrorCompleteUpload';
    this.payload = payload;
  }
}

export class MultipartUploaderErrorCreateFile extends Error {
  public payload: MultipartUploaderErrorCreateFilePayload;

  constructor(message: string, payload: MultipartUploaderErrorCreateFilePayload) {
    super(message);
    this.name = 'MultipartUploaderErrorCreateFile';
    this.payload = payload;
  }
}

export class MultipartUploaderCancelNotify extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MultipartUploaderCancelNotify';
  }
}

export class MultipartUploader {
  private static instance: MultipartUploader | null = null;
  private readonly fileApi: FilesApi;
  private isCancel = false;
  private readonly FILE_CHUNK_SIZE = 10000000;
  private partsNumberWithErrors: Array<number> = [];
  private progress = 0;
  private cancelUploadResolver: ((value: any | PromiseLike<any>) => void) | null = null;

  private constructor() {
    this.fileApi = new FilesApi();
  }

  public static getInstance(): MultipartUploader {
    if (this.instance === null) {
      this.instance = new MultipartUploader();
    }
    return this.instance;
  }

  private static destroyInstance() {
    this.instance = null;
  }

  public cancelUpload(promiseResolver: (value: any | PromiseLike<any>) => void) {
    this.isCancel = true;
    this.cancelUploadResolver = promiseResolver;
    MultipartUploader.destroyInstance();
  }

  public async tryCompleteUpload(
    errorPayload: MultipartUploaderErrorCompleteUploadPayload,
    config: MultipartUploadFileToS3Config,
  ) {
    let retryCount = 5;
    while (retryCount !== 0) {
      try {
        const {
          data: {
            item: {url, bucket},
          },
        } = await this.completeMultipartUpload(config.completeUploadRoute, {
          original_file_name: errorPayload.originalFileName,
          file_name: errorPayload.fileName,
          parts_info: errorPayload.partsInfo,
          upload_id: errorPayload.uploadId,
          size: errorPayload.size,
        });

        this.checkCancelStatus();
        return await this.createNewFile(errorPayload.fileName, errorPayload.size, url, bucket);
      } catch (e) {
        if (e instanceof MultipartUploaderErrorCompleteUpload) {
          retryCount--;
        } else if (e instanceof MultipartUploaderErrorCreateFile || e instanceof MultipartUploaderCancelNotify) {
          throw e;
        }
      }
    }
    throw new MultipartUploaderErrorCompleteUpload('Не удалось завершить выгрузку файла', errorPayload);
  }

  public async tryCreateNewFile(errorPayload: MultipartUploaderErrorCreateFilePayload) {
    let retryCount = 5;
    while (retryCount !== 0) {
      try {
        this.checkCancelStatus();
        return await this.createNewFile(
          errorPayload.fileName,
          errorPayload.size,
          errorPayload.url,
          errorPayload.bucket,
        );
      } catch (e) {
        if (e instanceof MultipartUploaderCancelNotify) {
          throw e;
        }
        retryCount--;
      }
    }

    throw new MultipartUploaderErrorCreateFile('Не удалось создать файл', errorPayload);
  }

  public async tryUploadFaultedParts(
    file: File,
    errorPayload: MultipartUploaderErrorUploadPartsPayload,
    config: MultipartUploadFileToS3Config,
    tickCb: (progress: number) => void,
  ) {
    let successfulCount = errorPayload.allPartsResults.length - errorPayload.partsErrorResults.length;
    let allResults: Array<ComplexNullable<UploadedPartMetadata>> = [...errorPayload.allPartsResults];
    const fatalErrors: Array<PartUploadFailedResult> = [];
    for (const errorResult of errorPayload.partsErrorResults) {
      this.checkCancelStatus();
      const result = await this.tryUploadPartAgain(
        errorResult.partNumber,
        errorPayload.chunksCount,
        errorResult.signedUrl,
        file,
      );
      if (result.status === 'SUCCESS') {
        successfulCount++;
        tickCb(successfulCount / allResults.length);
        allResults = [
          ...allResults.slice(0, errorResult.partNumber - 1),
          {
            ETag: result.data as string,
            PartNumber: errorResult.partNumber,
          },
          ...allResults.slice(errorResult.partNumber),
        ];
      } else {
        fatalErrors.push(errorResult);
      }
    }

    this.checkCancelStatus();
    if (fatalErrors.length === 0) {
      const {
        data: {
          item: {url, bucket},
        },
      } = await this.completeMultipartUpload(config.completeUploadRoute, {
        original_file_name: file.name,
        file_name: errorPayload.infoForComplete.fileName,
        upload_id: errorPayload.infoForComplete.uploadId,
        size: errorPayload.infoForComplete.size,
        parts_info: allResults as Array<UploadedPartMetadata>,
      });
      this.checkCancelStatus();
      return await this.createNewFile(file.name, errorPayload.infoForComplete.size, url, bucket);
    } else {
      throw new MultipartUploaderErrorUploadParts('Не удалось загрузить файл', {
        type: MultiPartUploadSteps.UPLOAD_PARTS,
        allPartsResults: allResults,
        partsErrorResults: fatalErrors,
        chunksCount: errorPayload.chunksCount,
        infoForComplete: {
          fileName: errorPayload.infoForComplete.fileName,
          uploadId: errorPayload.infoForComplete.uploadId,
          size: errorPayload.infoForComplete.size,
        },
      });
    }
  }

  public async uploadToFileStorage(
    file: File,
    config: MultipartUploadFileToS3Config,
    tickCb: (progress: number) => void,
  ) {
    const FILE_SIZE = file.size;
    const FILE_EXTENSION = file.name.split('.').pop() || '';
    const CHUNKS_COUNT = Math.floor(FILE_SIZE / this.FILE_CHUNK_SIZE) + 1;

    const {upload_id, file_name, urls} = await this.initiateUpload(config, {
      file_name: file.name,
      extension: FILE_EXTENSION,
      counts: CHUNKS_COUNT,
    });

    let allResults = await this.startUploadAllParts(file, CHUNKS_COUNT, urls as string[], tickCb);
    const fatalErrors: Array<PartUploadFailedResult> = [];
    for (const partsNumber of this.partsNumberWithErrors) {
      const result = await this.tryUploadPartAgain(
        partsNumber,
        CHUNKS_COUNT,
        (urls as string[])[partsNumber - 1],
        file,
      );
      if (result.status === 'SUCCESS') {
        allResults = [
          ...allResults.slice(0, partsNumber - 1),
          {
            ETag: result.data as string,
            PartNumber: partsNumber,
          },
          ...allResults.slice(partsNumber),
        ];
      } else {
        fatalErrors.push({
          data: result.data as Error,
          partNumber: partsNumber,
          signedUrl: (urls as string[])[partsNumber - 1],
        });
      }
    }

    this.progress = 0;
    this.partsNumberWithErrors = [];
    this.checkCancelStatus();
    if (fatalErrors.length === 0) {
      const {
        data: {
          item: {url, bucket},
        },
      } = await this.completeMultipartUpload(config.completeUploadRoute, {
        original_file_name: file.name,
        file_name,
        upload_id,
        parts_info: allResults as Array<UploadedPartMetadata>,
        size: file.size,
      });
      this.checkCancelStatus();
      return await this.createNewFile(file.name, file.size, url, bucket);
    } else {
      throw new MultipartUploaderErrorUploadParts('Не удалось загрузить файл', {
        type: MultiPartUploadSteps.UPLOAD_PARTS,
        allPartsResults: allResults,
        partsErrorResults: fatalErrors,
        chunksCount: CHUNKS_COUNT,
        infoForComplete: {
          fileName: file_name,
          uploadId: upload_id,
          size: file.size,
        },
      });
    }
  }

  private async initiateUpload(
    configuration: MultipartUploadFileToS3Config,
    payload: {extension: string; file_name: string; counts: number},
  ) {
    const {
      data: {
        item: {upload_id, urls, file_name},
      },
    } = await this.fileApi.startMultipartUpload(configuration.initiateUploadRoute, payload);
    return {upload_id, urls, file_name};
  }

  private async startUploadAllParts(
    file: File,
    chunksCount: number,
    signedUrlsForParts: Array<string>,
    cb: (progress: number) => void,
  ): Promise<Array<ComplexNullable<UploadedPartMetadata>>> {
    const tick = (promise: Promise<PartUploadResult>) => {
      promise.then(res => {
        if (res.status === 'SUCCESS') {
          this.progress++;
          cb(this.progress / chunksCount);
        }
      });
      return promise;
    };

    const blobsWithSignedUrl: Array<{blob: Blob; url: string}> = [];
    for (let index = 1; index < chunksCount + 1; index++) {
      blobsWithSignedUrl.push({
        blob: this.getBlobByOrderPartNumber(index, chunksCount, file),
        url: signedUrlsForParts[index - 1],
      });
    }

    this.checkCancelStatus();
    const resolvedPromises: Array<PartUploadResult> = [];
    const chunks = splitArrayToChunks(blobsWithSignedUrl, 5);
    for (const chunk of chunks) {
      const chunkPromises: Array<Promise<PartUploadResult>> = [];
      this.checkCancelStatus();
      for (const blobWithUrl of chunk) {
        chunkPromises.push(this.partUpload(blobWithUrl.url, blobWithUrl.blob));
      }
      resolvedPromises.push(...(await Promise.all(chunkPromises.map(tick))));
    }

    return resolvedPromises.map((partUploadResult, index) => {
      if (partUploadResult.status === 'SUCCESS') {
        return {
          ETag: (partUploadResult.data as string).replaceAll('"', ''),
          PartNumber: index + 1,
        };
      } else {
        this.partsNumberWithErrors = [...this.partsNumberWithErrors, index + 1];
        return {
          ETag: null,
          PartNumber: index + 1,
        };
      }
    });
  }

  private async tryUploadPartAgain(partNumber: number, chunksCount: number, signedUrl: string, file: File) {
    const blob = this.getBlobByOrderPartNumber(partNumber, chunksCount, file);
    let retryCount = 5;
    let result: PartUploadResult | null = null;
    while (retryCount !== 0) {
      result = await this.partUpload(signedUrl, blob);
      if (result.status === 'ERROR') {
        retryCount--;
      } else {
        break;
      }
    }

    return result as PartUploadResult;
  }

  private getBlobByOrderPartNumber(partNumber: number, chunksCount: number, file: File): Blob {
    const startCursor = (partNumber - 1) * this.FILE_CHUNK_SIZE;
    const endCursor = partNumber * this.FILE_CHUNK_SIZE;
    return partNumber < chunksCount ? file.slice(startCursor, endCursor) : file.slice(startCursor);
  }

  private async partUpload(partSignedUrl: string, blob: File | Blob): Promise<PartUploadResult> {
    try {
      const eTag = await this.fileApi.partUploadFileToS3BySignedUrl(partSignedUrl, blob);
      return {status: 'SUCCESS', data: eTag};
    } catch (e) {
      return {status: 'ERROR', data: e};
    }
  }

  private async completeMultipartUpload(
    url: string,
    payload: {
      original_file_name: string;
      file_name: string;
      upload_id: number;
      parts_info: Array<UploadedPartMetadata>;
      size: number;
    },
  ) {
    try {
      return await this.fileApi.completeMultipartUpload(url, payload);
    } catch (e) {
      throw new MultipartUploaderErrorCompleteUpload('Не удалось завершить выгрузку видео', {
        type: MultiPartUploadSteps.UPLOAD_COMPLETING,
        originalFileName: payload.file_name,
        fileName: payload.file_name,
        uploadId: payload.upload_id,
        partsInfo: payload.parts_info,
        size: payload.size,
      });
    }
  }

  private async createNewFile(originalFileName: string, size: number, url: string, bucket: string) {
    try {
      const extension = originalFileName.split('.').pop() || '';
      const {
        data: {item: createdFile},
      } = await this.fileApi.createFile({
        original_file_name: originalFileName,
        extension,
        url,
        bucket,
        size,
      });
      return createdFile;
    } catch (e) {
      throw new MultipartUploaderErrorCreateFile('Не удалось создать файл', {
        type: MultiPartUploadSteps.CREATE_FILE_AFTER_UPLOAD,
        fileName: originalFileName,
        url,
        bucket,
        size,
      });
    }
  }

  private checkCancelStatus(): void | never {
    if (this.isCancel) {
      if (this.cancelUploadResolver !== null) {
        this.cancelUploadResolver(null);
      }
      throw new MultipartUploaderCancelNotify('Операция была успешно отменена');
    }
  }
}
