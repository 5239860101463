import {EntityId} from '../../../api/base/BaseEntity';

export enum TransactionAction {
  CONFIRM = 'CONFIRM',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  CANCEL = 'CANCEL',
  REJECT = 'REJECT',
  INCOME_REPORT = 'INCOME_REPORT',
  CREATE_WITHDRAWAL = 'CREATE_WITHDRAWAL',
  DOWNLOAD_ACCOUNTS_BALANCE_REPORT = 'DOWNLOAD_ACCOUNTS_BALANCE_REPORT',
  CREATE_TO_OUR_ORGANIZATION = 'CREATE_TO_OUR_ORGANIZATION',
  CREATE_YOUTUBE_TAX_TO_YOUTUBE = 'CREATE_YOUTUBE_TAX_TO_YOUTUBE',
}

export type HandleTransactionActionPayload =
  | HandleTransactionCancel
  | HandleTransactionConfirm
  | HandleTransactionReject
  | HandleCreateTransaction
  | HandleDownloadTransactionReport
  | HandleDownloadChannelIncomeReport;

export type HandleCreateTransaction =
  | HandleTransactionCreateWithdrawal
  | HandleTransactionCreateYoutubeTaxToYoutube
  | HandleTransactionCreateToOurOrganization;

export type HandleDownloadTransactionReport = {
  type: TransactionAction.DOWNLOAD_REPORT | TransactionAction.DOWNLOAD_ACCOUNTS_BALANCE_REPORT;
};

export type HandleDownloadChannelIncomeReport = {
  type: TransactionAction.INCOME_REPORT;
};

export type HandleTransactionCancel = {
  type: TransactionAction.CANCEL;
  data: {
    entityId: EntityId;
  };
};

export type HandleTransactionReject = {
  type: TransactionAction.REJECT;
  data: {
    entityId: EntityId;
  };
};

export type HandleTransactionConfirm = {
  type: TransactionAction.CONFIRM;
  data: {
    entityId: EntityId;
  };
};

export type HandleTransactionCreateYoutubeTaxToYoutube = {
  type: TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE;
  data?: {
    contractorId: EntityId;
    contractorName: string;
  };
};

export type HandleTransactionCreateWithdrawal = {
  type: TransactionAction.CREATE_WITHDRAWAL;
  data?: {
    contractorId: EntityId;
    contractorName: string;
  };
};

export type HandleTransactionCreateToOurOrganization = {
  type: TransactionAction.CREATE_TO_OUR_ORGANIZATION;
  data?: {
    contractorId: EntityId;
    contractorName: string;
  };
};
