import React, {JSXElementConstructor} from 'react';
import {getValidationErrorMessage, ValidationErrorsType} from '../../utils/utils';
import {ValidateErrorWrapper} from './ValidateErrorWrapper';

type ComponentProps<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> = React.ComponentProps<T>;
type Props<DTO, T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> = {
  dto: DTO;
  dtoKey: keyof DTO;
  validationsErrors: ValidationErrorsType;
  // beforeChange?: (value: Parameters<ComponentProps<T>['onChange']>) => DTO[];
  // onChange?: UpdateFields<DTO>;

  visible?: boolean;
  component: T;
  innerProps: Omit<ComponentProps<T>, 'hasError'>;
};

export function DtoFieldInput<DTO, T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>({
  component,
  dtoKey,
  validationsErrors,
  innerProps,
  visible,
}: Props<DTO, T>) {
  if (visible != undefined && !visible) {
    return null;
  }
  return (
    <ValidateErrorWrapper message={getValidationErrorMessage(dtoKey, validationsErrors as any)}>
      {React.createElement(component, {
        ...innerProps,
        hasError: !!getValidationErrorMessage(dtoKey, validationsErrors as any),
      })}
    </ValidateErrorWrapper>
  );
}
