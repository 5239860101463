import React from 'react';
import {PartialNullable} from '../../../../../types/types';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {LabelWithDescription} from '../../../../components/Inputs/LabelWithDescription';
import {LocalizationKey, useIntl} from '../../../../hooks/use-intl';

type Props = {
  contractor: PartialNullable<Contractor>;
};

export const BankInfo: React.FC<Props> = ({contractor}) => {
  const intl = useIntl();
  const renderPaymentRequisitesItem = (title: LocalizationKey, value?: string | number | null) => {
    if (!value) {
      return null;
    }

    return (
      <div className={'mb-3'}>
        <LabelWithDescription className={{label: 'mb-0'}} label={intl.formatMessage({id: title})} />
        <div>{value}</div>
      </div>
    );
  };

  return (
    <div>
      <div>{renderPaymentRequisitesItem('BANK_NAME', contractor?.rub_bank_name)}</div>
      <div className={'d-flex flex-wrap'}>
        <div className={'mr-8'}>{renderPaymentRequisitesItem('BANK_NUMBER', contractor?.rub_bank_number)}</div>
        <div>{renderPaymentRequisitesItem('BANK_INN', contractor?.rub_bank_inn)}</div>
      </div>

      <div className={'d-flex flex-wrap'}>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem(
            'BANK_CORRESPONDENT_ACCOUNT_NUMBER',
            contractor?.rub_bank_correspondent_account_number,
          )}
        </div>
        <div className={'mr-8'}>
          {renderPaymentRequisitesItem('BANK_ACCOUNT_NUMBER', contractor?.rub_bank_account_number)}
        </div>
      </div>
      <div>{renderPaymentRequisitesItem('ACCOUNT_CURRENCY', contractor?.bank_account_currency)}</div>
    </div>
  );
};
