import React from 'react';
import SVG from 'react-inlinesvg';
import {WidgetBase} from './widget-base';

interface IWidgetProps {
  icon?: string;
  value: number | string;
  subtitle: string;
  style?: string;
  className?: string;

  onClick?: () => void;
}

export const WidgetFirst: React.FC<IWidgetProps> = ({icon, value, style, subtitle, className, onClick}) => {
  return (
    <>
      <WidgetBase className={className} onClick={onClick}>
        <div className={'card-body'}>
          {icon && (
            <span className={`svg-icon svg-icon-3x svg-icon-${style} ml-n2`}>
              <SVG src={icon} />
            </span>
          )}
          <div className={'font-size-h2 mt-3'}>{value}</div>
          <div className={'text-muted font-weight-bolder font-size-lg mt-1'}>
            <span>{subtitle}</span>
          </div>
        </div>
      </WidgetBase>
    </>
  );
};
