import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {PaymentRequest, PaymentRequestDestination, PaymentRequestStatus} from '../../../api/DTOs/PaymentRequest';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Preloader} from '../../../components/preloader';
import {useModalRejectReasonPaymentRequest} from '../../../components/modals/reject-reason/modal-reject-reason-payment-request-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {ErrorStub} from '../../../components/error-stub';
import {FinancialAccount} from '../../../api/DTOs/FinancialAccount';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {AdminRoutes} from '../../../../configs/routes';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {PaymentRequestPrivileges, usePaymentsRequestsApi} from '../../../hooks/apis/use-payments-requests-api';
import {User} from '../../../api/DTOs/User';
import {ICONS} from '../../../images/images';
import cn from 'classnames';
import {useLoading} from '../../../hooks/use-loading';

export const PaymentRequestPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();

  const api = usePaymentsRequestsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    action: false,
  });
  const [error, setError] = useState<string | null>(null);

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [account, setAccount] = useState<FinancialAccount | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [privileges, setPrivileges] = useState<PaymentRequestPrivileges | null>(null);

  const {showRejectReasonModal} = useModalRejectReasonPaymentRequest();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchPaymentRequest().then();
  }, []);

  useEffect(() => {
    if (paymentRequest) {
      setBreadcrumb(
        `${paymentRequest.user_name} - ${CustomFormatter.formatMoney(paymentRequest.value, paymentRequest.currency)}`,
      );
    }
  }, [paymentRequest]);

  const fetchPaymentRequest = async () => {
    try {
      startLoading('page');
      const result = await api.get(match.params.id);
      setAccount(result.data.relations.account);
      setUser(result.data.relations.user);
      setPrivileges(result.data.privileges);
      setPaymentRequest(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const handlePerformClick = async () => {
    try {
      startLoading('action');
      await api.perform(match.params.id);
      toast.success(intl.formatMessage({id: "SUCCESS_CONFIRM_PAYMENT_REQUEST"}));
      await fetchPaymentRequest();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('action');
    }
  };

  const handleRejectClick = async () => {
    const result = await showRejectReasonModal(Number(match.params.id));
    if (result.reason === CloseModalReason.OK) {
      await fetchPaymentRequest();
    }
  };

  const renderAccountInfo = () => {
    if (!account) {
      return null;
    }

    return (
      <>
        <div className={'font-size-h4 font-weight-boldest my-3'}>
          <FormattedMessage id={'ACCOUNT'} />
        </div>
        <div className={'row'}>
          <div className={'col-xl-4 col-md-12'}>
            <div className={'d-flex align-items-center mr-5 my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>
                  <FormattedMessage id={'BALANCE'} />
                </span>
                <span className={'font-size-h5'}>
                  <FormattedNumber value={account.balance} style={'currency'} currency={account.currency} />
                </span>
              </div>
            </div>
          </div>

          <div className={'col-xl-4 col-md-12'}>
            <div className={'d-flex align-items-center mr-5 my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>
                  <FormattedMessage id={'CONTRACTOR'} />
                </span>
                <Link to={AdminRoutes.getSpecifyContractorRoute(account.contractorId)}>
                  <span className={'font-size-h5'}>{account.contractorName}</span>
                </Link>
              </div>
            </div>
          </div>
          <div className={'col-xl-4 col-md-12'}>
            <div className={'d-flex align-items-center mr-5 my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>
                  <FormattedMessage id={'UPDATED_AT'} />
                </span>
                <span className={'font-size-h5'}>
                  {intl.formatDate(account.updated_at) + ' ' + intl.formatTime(account.updated_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (paymentRequest === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  const statusTextStyle =
    paymentRequest.status === PaymentRequestStatus.REJECT
      ? 'text-danger'
      : paymentRequest.status === PaymentRequestStatus.COMPLETE
      ? 'text-success'
      : 'text-primary';

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-1'}>
                <FormattedMessage id={'SUM'} />
                :&nbsp;
                <FormattedNumber value={paymentRequest.value} style={'currency'} currency={paymentRequest.currency} />
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-1'}>
                <FormattedMessage id={'EMAIL'} />: <span>{user?.email}</span>
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <FormattedMessage id={'PAYMENT_REQUEST_DESTINATION'} />:{' '}
                <span
                  className={cn({
                    'text-info': paymentRequest.destination === PaymentRequestDestination.TO_MCPAY,
                    'text-primary': paymentRequest.destination === PaymentRequestDestination.BY_REQUISITES,
                  })}>
                  <FormattedMessage id={'PAYMENT_REQUEST_DESTINATION_' + paymentRequest.destination} />
                </span>
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <FormattedMessage id={'STATUS'} />:{' '}
                <span className={statusTextStyle}>
                  <FormattedMessage id={'PAYMENT_REQUEST_STATUS_' + paymentRequest.status} />
                </span>
              </p>
              {paymentRequest.reject_reason && (
                <p className={'font-size-h6 text-muted'}>{paymentRequest.reject_reason}</p>
              )}
            </div>
            <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
              <div className={'d-flex flex-wrap align-items-center py-2'}>
                <div className={'d-flex align-items-center flex-wrap'}>
                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'CREATED_AT'} />
                    </div>
                    <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                      {intl.formatDate(paymentRequest.created_at, {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      })}
                    </span>
                  </div>

                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'UPDATED_AT'} />
                    </div>
                    <div className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                      {paymentRequest.updated_at
                        ? intl.formatDate(paymentRequest.updated_at, {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                          })
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderAccountInfo()}
          <div className={'separator separator-solid my-7'} />
          <Toolbar
            items={[
              {
                title: intl.formatMessage({id: 'PERFORM'}),
                type: 'BUTTON',
                loading: loadings.action,
                disabled: !privileges?.can_perform_payment_request || loadings.action,
                icon: ICONS.CHECK,
                className: 'btn btn-light-success font-weight-bolder',
                onClick: handlePerformClick,
              },
              {
                title: intl.formatMessage({id: 'REJECT'}),
                type: 'BUTTON',
                icon: ICONS.CROSS,
                disabled: !privileges?.can_reject_payment_request || loadings.action,
                className: 'btn btn-light-danger font-weight-bolder',
                onClick: handleRejectClick,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
