import React from 'react';
import {Toggler} from '../../../../components/toggler';
import {Svg} from '../../../../images/svg';
import {ICONS} from '../../../../images/images';
import cn from 'classnames';

type Props = {
  defaultToggleState?: boolean;
  title: string;
  className?: {content?: string; container?: string};
};

export const ContractorInfoBlock: React.FC<Props> = ({title, defaultToggleState, className, children}) => {
  return (
    <>
      <Toggler
        defaultValue={defaultToggleState ?? false}
        className={{content: cn(className?.content), container: cn(className?.container)}}
        renderToggle={(toggle, toggleValue) => (
          <h6 className={'text-muted font-weight-bolder d-flex align-items-center'}>
            {title}
            <Svg className={'ml-1'} src={toggleValue ? ICONS.MINUS : ICONS.PLUS} />
          </h6>
        )}>
        {children}
      </Toggler>
    </>
  );
};
