export interface IMailingDto {
  id: number;
  subject_ru: string;
  multilingual: boolean;
  subject_en: string | null;
  content_type: MailingContentType;
  markdown_ru: string | null;
  markdown_en: string | null;
  html_ru: string | null;
  html_en: string | null;
  created_at: string;
  updated_at: string;
}

export enum MailingContentType {
  HTML = 'HTML',
  MARKDOWN = 'MARKDOWN',
}
