import React, {createContext} from 'react';
import {ModalEditUserProvider} from '../edit-user/modal-edit-user-context';
import {ModalCreateUserProvider} from '../create-user/modal-create-user-context';
import {ModalPaymentRequestProvider} from '../create-payment-request-modal/payment-request-modal-context';
import {ModalPlayVideoProvider} from '../play-video/modal-play-video-context';
import {ModalRejectReasonTransactionProvider} from '../reject-reason/modal-reject-reason-transaction-context';
import {ModalEditContractorProvider} from '../edit-contractor/modal-edit-contractor-context';
import {ModalRejectReasonConnectRequestProvider} from '../reject-reason/modal-reject-reason-connect-request-context';
import {ModalRejectReasonPaymentRequestProvider} from '../reject-reason/modal-reject-reason-payment-request-context';
import {ModalCreateSupportTicketProvider} from '../create-support-ticket-modal/modal-create-support-ticket-context';
import {ModalChooseUsersProvider} from '../choose-users/modal-choose-user-context';
import {ModalPreviewHtmlProvider} from '../preview-html-content/modal-preview-html-content-context';
import {ModalRejectReasonAdsRequestProvider} from '../reject-reason/modal-reject-reason-ads-request-context';
import {ModalCreateEditChapterProvider} from '../create-edit-chapter-modal/modal-create-edit-chapter-context';
import {ModalCreateEditGenreProvider} from '../create-edit-genre/create-edit-genre-context';
import {ModalCreateEditCountryProvider} from '../create-edit-country-modal/create-edit-country-context';
import {ModalCreateEditArtistProvider} from '../create-edit-artist-modal/create-edit-artist-modal-context';
import {ModalCreateEditInviteUserRequestProvider} from '../invite-user/create-edit-invite-user-request-modal-context';
import {ModalUploadFilesProvider} from '../../../pages/videos/video-page/upload-watermarked-file-modal-context';
import {ModalRejectReasonProvider} from '../reject-reason/modal-reject-reason-base-context';
import {ModalDownloadFileProvider} from '../../../pages/downloads/download-file-modal-context';
import {ModalCreateWithdrawalTransactionProvider} from '../create-transaction/create-withdrawal-transaction/modal-create-withdrawal-transaction-context';
import {ModalCreateToOurOrganizationTransactionProvider} from '../create-transaction/create-to-our-organization-transaction/modal-create-to-our-organization-transaction-context';
import {ModalCreateReturnTaxToYoutubeTransactionProvider} from '../create-transaction/create-return-youtube-tax-transaction/modal-create-return-youtube-tax-transaction-context';
import {ModalSetYandexZenChannelIdProvider} from '../../../pages/youtube-channel/channel-page/modals/modal-set-yandex-zen-channel-id-context';
import {ModalFillContractorFieldsAlertProvider} from '../fill-contractor-fields-alert-modal/fill-contractor-fields-modal-context';
import {ModalChannelTransactionsIncomeReportProvider} from '../channel-transaction-income-report-modal/modal-channel-transactions-income-report-context';
import {ModalSetChartMetricProvider} from '../set-chartmetric-id-modal/modal-set-chartmetric-context';
import {ModalLinkChannelWithArtistProvider} from '../../../pages/youtube-channel/channel-page/modals/modal-link-channel-with-artist';
import {ModalAdminPaymentRequestProvider} from '../create-admin-payment-request-modal/admin-payment-request-modal-context';
import {ModalSetRatePercentsProvider} from '../set-rate-percent-modal/modal-set-rate-percent';

const ModalsContext = createContext({});

export const ModalsProvider = ({children}: any) => {
  return (
    <ModalsContext.Provider value={{}}>
      <ModalEditContractorProvider>
        <ModalCreateUserProvider>
          <ModalEditUserProvider>
            <ModalPaymentRequestProvider>
              <ModalRejectReasonPaymentRequestProvider>
                <ModalRejectReasonConnectRequestProvider>
                  <ModalPlayVideoProvider>
                    <ModalRejectReasonTransactionProvider>
                      <ModalRejectReasonAdsRequestProvider>
                        <ModalCreateSupportTicketProvider>
                          <ModalChooseUsersProvider>
                            <ModalPreviewHtmlProvider>
                              <ModalCreateEditChapterProvider>
                                <ModalCreateEditGenreProvider>
                                  <ModalCreateEditCountryProvider>
                                    <ModalCreateEditArtistProvider>
                                      <ModalCreateEditInviteUserRequestProvider>
                                        <ModalRejectReasonProvider>
                                          <ModalUploadFilesProvider>
                                            <ModalDownloadFileProvider>
                                              <ModalCreateWithdrawalTransactionProvider>
                                                <ModalCreateToOurOrganizationTransactionProvider>
                                                  <ModalCreateReturnTaxToYoutubeTransactionProvider>
                                                    <ModalSetYandexZenChannelIdProvider>
                                                      <ModalFillContractorFieldsAlertProvider>
                                                        <ModalChannelTransactionsIncomeReportProvider>
                                                          <ModalSetChartMetricProvider>
                                                            <ModalLinkChannelWithArtistProvider>
                                                              <ModalAdminPaymentRequestProvider>
                                                                <ModalSetRatePercentsProvider>
                                                                  {children}
                                                                </ModalSetRatePercentsProvider>
                                                              </ModalAdminPaymentRequestProvider>
                                                            </ModalLinkChannelWithArtistProvider>
                                                          </ModalSetChartMetricProvider>
                                                        </ModalChannelTransactionsIncomeReportProvider>
                                                      </ModalFillContractorFieldsAlertProvider>
                                                    </ModalSetYandexZenChannelIdProvider>
                                                  </ModalCreateReturnTaxToYoutubeTransactionProvider>
                                                </ModalCreateToOurOrganizationTransactionProvider>
                                              </ModalCreateWithdrawalTransactionProvider>
                                            </ModalDownloadFileProvider>
                                          </ModalUploadFilesProvider>
                                        </ModalRejectReasonProvider>
                                      </ModalCreateEditInviteUserRequestProvider>
                                    </ModalCreateEditArtistProvider>
                                  </ModalCreateEditCountryProvider>
                                </ModalCreateEditGenreProvider>
                              </ModalCreateEditChapterProvider>
                            </ModalPreviewHtmlProvider>
                          </ModalChooseUsersProvider>
                        </ModalCreateSupportTicketProvider>
                      </ModalRejectReasonAdsRequestProvider>
                    </ModalRejectReasonTransactionProvider>
                  </ModalPlayVideoProvider>
                </ModalRejectReasonConnectRequestProvider>
              </ModalRejectReasonPaymentRequestProvider>
            </ModalPaymentRequestProvider>
          </ModalEditUserProvider>
        </ModalCreateUserProvider>
      </ModalEditContractorProvider>
    </ModalsContext.Provider>
  );
};
