import React from 'react';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor, Currency} from '../../../../../../api/contractor-api/Contractor';
import {useIntl} from '../../../../../../hooks/use-intl';
import {CurrencyPicker} from '../../../../../../components/currency-picker';
import {BankFieldsRub} from './bank-fields-rub';
import {UpdateFields} from '../../../../../../hooks/use-advanced-state';
import {BankFieldsUsd} from './bank-fields-usd';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateFields<Contractor>;
};

export const BankFields: React.FC<Props> = ({validationErrors, updateFields, contractor}) => {
  const intl = useIntl();
  return (
    <>
      <CurrencyPicker
        label={intl.formatMessage({id: 'ACCOUNT_CURRENCY'})}
        currency={contractor?.bank_account_currency}
        onChange={value => updateFields({bank_account_currency: value})}
        validationError={getValidationErrorMessage<Contractor>('bank_account_currency', validationErrors)}
      />

      {contractor?.bank_account_currency &&
        (contractor.bank_account_currency === Currency.USD
            ? <BankFieldsUsd contractor={contractor} updateFields={updateFields} validationErrors={validationErrors} />
            : <BankFieldsRub contractor={contractor} updateFields={updateFields} validationErrors={validationErrors} />
        )
      }
    </>
  );
};
