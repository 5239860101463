import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

import {UpdateFields} from '../../../../hooks/use-advanced-state';
import {CreateWithdrawalTransactionsFields} from '../../../../hooks/apis/use-transactions-api';
import {PartialNullable} from '../../../../../types/types';
import {InputSelect, SelectOptions} from '../../../Inputs/InputSelect';
import {ValidateErrorWrapper} from '../../../Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {InputTextArea} from '../../../Inputs/InputTextArea';
import {InputText} from '../../../Inputs/InputText';
import {AlertCustom} from '../../../../modules/Auth/component/alert';
import {DtoFieldInput} from '../../../Inputs/DtoFieldInput';
import {useIntl} from '../../../../hooks/use-intl';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../../base-modal/base-modal';
import {CurrencyPicker} from '../../../currency-picker';
import {TransactionAction} from '../../../../pages/admin/transactions/transaction-action-types';
import {FinancialAccount} from '../../../../api/DTOs/FinancialAccount';
import {FormattedMessage} from 'react-intl';
import {CustomFormatter} from '../../../../../localization/custom-formatter';

type Props = {
  visible: boolean;
  error?: string | null;
  validationErrors?: ValidationErrorsType;
  contractors: SelectOptions;
  accounts: Array<FinancialAccount>;
  loadings: {
    contractors: boolean;
    accounts: boolean;
  };

  entity: PartialNullable<CreateWithdrawalTransactionsFields>;
  onChange: UpdateFields<CreateWithdrawalTransactionsFields>;

  onHide(): void;
  onOkClick(): any;
};

export const ModalCreateWithdrawalTransaction: React.FC<Props> = ({
  loadings,
  visible,
  entity,
  contractors,
  accounts,
  error,
  validationErrors,
  onChange,
  onOkClick,
  onHide,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const renderBalance = (account?: FinancialAccount) => {
    if (account == null) {
      return;
    }

    return (
      <p className={'font-weight-bolder mb-2'} key={account.id}>
        <>
          <FormattedMessage id={'BALANCE'} />: {CustomFormatter.formatMoney(account.balance, account.currency)}
          <br />
          <FormattedMessage id={'UNCONFIRMED_BALANCE'} />:{' '}
          {CustomFormatter.formatMoney(account.waitingConfirmBalance, account.currency)}
          {account.description && (
            <>
              <FormattedMessage id='DESCRIPTION' />: {account.description}
            </>
          )}
        </>
      </p>
    );
  };

  return (
    <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={handleOkClick} onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>
          {intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_WITHDRAWAL})}
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <div className={'font-weight-bolder mb-5'}>
          <FormattedMessage id={`CREATE_TRANSACTION_DESCRIPTION_${TransactionAction.CREATE_WITHDRAWAL}`} />
        </div>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={BootstrapColors.LIGHT_DANGER}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <div className='form-group'>
            <DtoFieldInput
              dto={entity}
              dtoKey={'contractor_id'}
              component={InputSelect}
              validationsErrors={validationErrors}
              innerProps={{
                required: true,
                placeholder: '',
                isClearable: true,
                isLoading: loadings.contractors,
                label: intl.formatMessage({id: 'CONTRACTOR'}),
                className: 'mb-2',
                value: entity.contractor_id,
                options: contractors,
                onChange: value => onChange({contractor_id: value}),
              }}
            />

            <div className={'mt-3 mb-6'}>
              {loadings.accounts ? (
                <span className='ml-2 spinner spinner-secondary' />
              ) : (
                accounts?.map(ac => renderBalance(ac))
              )}
            </div>

            <ValidateErrorWrapper message={getValidationErrorMessage('value', validationErrors)}>
              <InputText
                required
                type={'number'}
                defaultValue={0}
                label={`${intl.formatMessage({id: 'SUM'})} ${entity?.currency ? `(${entity.currency})` : ''}`}
                hasError={!!getValidationErrorMessage('value', validationErrors)}
                classNames={'form-control'}
                onChange={e => onChange({value: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>

            <CurrencyPicker
              validationError={getValidationErrorMessage('currency', validationErrors)}
              currency={entity.currency}
              label={intl.formatMessage({id: 'CURRENCY'})}
              onChange={currency => onChange({currency})}
            />

            <ValidateErrorWrapper message={getValidationErrorMessage('description', validationErrors)}>
              <InputTextArea
                required
                defaultValue={''}
                label={intl.formatMessage({id: 'DESCRIPTION'})}
                hasError={!!getValidationErrorMessage('description', validationErrors)}
                className={'form-control'}
                onChange={e => onChange({description: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
