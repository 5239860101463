import React, {useCallback} from 'react';
import {RowEntity} from './contract-page/create-edit-contract-page/edit-contract-block/row';
import {VideoInContract} from '../../../api/contract-api/Contract';
import {UpdateEntity} from '../../../../types/types';
import {EntityTable} from '../../../components/tables/entity-table';
import cn from 'classnames';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';
import {DataSetValidationErrors} from '../../../utils/utils';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';

type Props = {
  videos: RowEntity<VideoInContract>[];
  onSave: (item: RowEntity<VideoInContract>) => boolean;
  privileges?: {canDelete?: boolean; canEdit?: boolean};
  validationErrors?: DataSetValidationErrors<VideoInContract> | null;
};
export const VideoTable: React.FC<Props> = ({videos, privileges, validationErrors, onSave}) => {
  const handleSave = useCallback(
    (item: RowEntity<VideoInContract>, updateState: UpdateEntity<RowEntity<VideoInContract>>) => {
      onSave({...item, isEdit: false});
      updateState({isEdit: false});
    },
    [onSave],
  );

  const handleDelete = useCallback(
    (item: RowEntity<VideoInContract>) => {
      onSave({...item, isDeleted: true});
    },
    [onSave],
  );

  return (
    <EntityTable
      entityKey={'video_id'}
      items={videos}
      columns={[
        {
          id: 'Videos',
          headerDefinition: {
            title: 'VIDEOS',
            width: '90%',
          },
          value: (rowState, updateState, setState, index) => {
            const errors =
              validationErrors?.[index]?.filter(v => v.fieldKey === 'video_id').map(v => v.message) ?? null;
            return (
              <ValidateErrorWrapper message={errors} className={'mb-0'}>
                <Link to={Routes.getSpecifyVideosRoute(rowState.video_id)} target={'__blank'}>
                  {rowState.video_title}
                </Link>
              </ValidateErrorWrapper>
            );
          },
        },
        {
          id: 'Actions',
          headerDefinition: {
            title: null,
          },
          visible: privileges?.canEdit == null || privileges.canEdit,
          className: cn('bg-white'),
          value: (state, updateState) => {
            return (
              <div className={'d-flex justify-content-center align-items-center'}>
                {state.isEdit ? (
                  <>
                    {state.isDeleted ? (
                      <Svg
                        src={ICONS.RESET}
                        color={BootstrapColors.INFO}
                        // @ts-ignore
                        onClick={() => updateState({isDeleted: false})}
                      />
                    ) : (
                      (privileges == undefined || privileges.canDelete == undefined || privileges.canDelete) && (
                        <Svg
                          src={ICONS.BIN}
                          color={BootstrapColors.DANGER}
                          // @ts-ignore
                          onClick={() => handleDelete(state)}
                        />
                      )
                    )}
                    <Svg
                      src={ICONS.CHECK}
                      color={BootstrapColors.SUCCESS}
                      onClick={() => handleSave(state, updateState)}
                    />
                  </>
                ) : (
                  (privileges == undefined || privileges.canEdit == undefined || privileges.canEdit) && (
                    // @ts-ignore
                    <Svg src={ICONS.EDIT} onClick={() => updateState({isEdit: true})} />
                  )
                )}
              </div>
            );
          },
        },
      ]}
    />
  );
};
