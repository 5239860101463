import React from 'react';
import {Card, CardBody} from '../../components/card';
import cn from 'classnames';
import {useIntl} from '../../hooks/use-intl';

export const VideoHistoryMarketingBlock: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <Card
        hideFooter
        hideHeader
        className={{card: 'm-0', body: 'p-0 d-flex align-items-center justify-content-center'}}
        style={{boxShadow: 'none'}}>
        <CardBody>
          <div className={cn('card-px text-center')}>
            <h2 className='font-weight-boldest mb-5'>
              {intl.formatMessage({id: 'VIDEO_HISTORY_NOT_FOUND_MARKETING_TITLE'})}
            </h2>
            <p className='text-gray-400 font-weight-bolder font-size-lg p-0' style={{whiteSpace: 'pre-wrap'}}>
              {intl.formatMessage({id: 'VIDEO_HISTORY_NOT_FOUND_MARKETING_DESCRIPTION'})}
            </p>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
