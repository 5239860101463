import {Currency} from './Transaction';
import {BaseEntity, EntityId, float} from '../base/BaseEntity';

export interface FinancialAccount extends BaseEntity {
  description: string | null;
  contractorId: EntityId;
  contractorName: string;

  type: FinancialAccountType;
  currency: Currency;
  balance: float;
  waitingConfirmBalance: float;
  withdrawalAvailableBalance: float;
}

export type McPayBalance = {
  balance: number;
  realBalance: number;
  creditBalance: number;
};

export enum FinancialAccountType {
  INNER = 'INNER',
  CACHE = 'CACHE',
  MC_PAY = 'MC_PAY',
}
