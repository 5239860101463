import React, {useMemo} from 'react';
import {TransactionOperation} from '../../../api/DTOs/TransactionOperation';
import {Table, TableColumn} from '../../../components/table';
import {useIntl} from '../../../hooks/use-intl';
import {TableUtils} from '../../../components/table-utils';
import {AdminRoutes} from '../../../../configs/routes';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {TransactionOperationRelation} from '../../../hooks/apis/use-transactions-operation-api';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {capitalizeFirstLetter, getDateFromMonthAndYear} from '../../../utils/utils';
import {Currency, TransactionStatus} from '../../../api/DTOs/Transaction';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';
import {useAuth} from '../../../hooks/use-auth';
import {LinkWithClickControl} from '../../../components/link-with-click-control';
import {EntityId} from '../../../api/base/BaseEntity';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons/faX';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {faRegistered} from '@fortawesome/free-solid-svg-icons';

interface ITransactionTableProps {
  currentAccountId?: EntityId;
  transactionsOperations: Array<TransactionOperation>;
  transactionsOperationsRelations: ManyRelations<TransactionOperationRelation> | null;
}

const GroupedColors: Array<BootstrapColors> = [
  BootstrapColors.INFO,
  BootstrapColors.PRIMARY,
  BootstrapColors.SUCCESS,
  BootstrapColors.SECONDARY,
  BootstrapColors.LIGHT_DANGER,
];

export const FinancialOperationsTable = ({transactionsOperations, currentAccountId}: ITransactionTableProps) => {
  const {
    privileges: {can_manage_accounts, can_manage_transactions},
  } = useAuth();
  const intl = useIntl();

  const cols = useMemo<Array<TableColumn<TransactionOperation>>>(() => {
    return [
      {
        id: 'id',
        Header: intl.formatMessage({id: 'ID'}),
        visible: can_manage_transactions,
        maxWidth: 65,
        minWidth: 50,
        forceApplySize: true,
        Cell: ({value}) => {
          return TableUtils.renderBaseContent(
            <LinkWithClickControl
              disabled={!can_manage_accounts}
              to={AdminRoutes.getSpecifyFinancialOperations(value.id)}>
              {value.id}
            </LinkWithClickControl>,
          );
        },
      },
      {
        id: 'Sum',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUM'})),
        visible: !can_manage_accounts,
        maxWidth: 110,
        minWidth: 110,
        forceApplySize: true,
        Cell: ({value}) => {
          if (!currentAccountId) {
            return 'N/A';
          }

          let sum: {value: number; currency: Currency} = {value: 0, currency: Currency.RUB};
          if (value.from_account_id === currentAccountId) {
            sum = {value: value.from_value, currency: value.from_currency};
          } else if (value.to_account_id === currentAccountId) {
            sum = {value: value.to_value, currency: value.to_currency};
          }

          return TableUtils.renderNumericContent(
            <span
              className={cn('font-weight-boldest', {
                'text-success': sum.value > 0,
                'text-danger': sum.value < 0,
              })}>
              {CustomFormatter.customFormatMoney({
                sum: sum.value,
                currency: sum.currency,
                signDisplay: 'always',
              })}
            </span>,
          );
        },
      },
      {
        id: 'From',
        Header: intl.formatMessage({id: 'FROM'}),
        visible: can_manage_accounts,
        maxWidth: 250,
        minWidth: 200,
        Cell: ({value}) => {
          return TableUtils.renderBaseContent(
            <div className={'d-flex flex-column'}>
              <LinkWithClickControl
                disabled={!can_manage_accounts}
                to={AdminRoutes.getSpecifyContractorRoute(value.from_contractor_id)}>
                {value.from_contractor_name}
              </LinkWithClickControl>
              <span
                className={cn('font-weight-boldest', {
                  'text-success': value.from_value > 0,
                  'text-danger': value.from_value < 0,
                })}>
                {CustomFormatter.customFormatMoney({
                  sum: value.from_value,
                  currency: value.from_currency,
                  signDisplay: 'always',
                })}
              </span>
            </div>,
          );
        },
      },
      {
        id: 'To',
        Header: intl.formatMessage({id: 'TO'}),
        maxWidth: 250,
        minWidth: 200,
        visible: can_manage_accounts,
        Cell: ({value}) => {
          return TableUtils.renderBaseContent(
            <div className={'d-flex flex-column'}>
              <LinkWithClickControl
                disabled={!can_manage_accounts}
                to={AdminRoutes.getSpecifyContractorRoute(value.to_contractor_id)}>
                {value.to_contractor_name}
              </LinkWithClickControl>
              <span
                className={cn('font-weight-boldest', {
                  'text-success': value.to_value > 0,
                  'text-danger': value.to_value < 0,
                })}>
                {CustomFormatter.customFormatMoney({
                  sum: value.to_value,
                  currency: value.to_currency,
                  signDisplay: 'always',
                })}
              </span>
            </div>,
          );
        },
      },
      {
        id: 'Description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        width: can_manage_accounts ? 200 : 500,
        maxWidth: 500,
        minWidth: 200,
        Cell: ({value: {localized_description}}) => {
          return TableUtils.renderBaseContent(localized_description ?? '');
        },
      },
      {
        id: 'Referral',
        Header: intl.formatMessage({id: 'SHORT_REFERRAL'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: TransactionOperation) => br.is_referral,
        Cell: ({value}) => {
          // noinspection RequiredAttributes
          return TableUtils.renderBaseContent(
            <>
              {value.is_referral && (
                <OverlayTrigger
                  placement='top-end'
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id={value.id.toString()}>
                      <Popover.Title as='h3' className={'text-primary font-weight-bolder'}>
                        <FormattedMessage id={'IS_REFERRAL_LINKED_OPERATION'} />
                      </Popover.Title>
                    </Popover>
                  }>
                  <div>
                    <FontAwesomeIcon icon={faRegistered} size={'xl'} className={'text-primary'} />
                  </div>
                </OverlayTrigger>
              )}
            </>,
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: TransactionOperation) => br.transactionStatus,
        Cell: ({value}) => {
          let colorVariant;
          let icon;
          switch (value.transactionStatus) {
            case TransactionStatus.CANCELED:
              icon = faBan;
              colorVariant = 'warning';
              break;
            case TransactionStatus.CONFIRMED:
              icon = faCheck;
              colorVariant = 'success';
              break;
            case TransactionStatus.WAITING_CONFIRM:
              icon = faClock;
              colorVariant = 'primary';
              break;
            case TransactionStatus.REJECTED:
              icon = faX;
              colorVariant = 'danger';
              break;
          }
          return TableUtils.renderBaseContent(
            <>
              <FontAwesomeIcon icon={icon} className={`text-${colorVariant}`} size={'xl'} />
            </>,
          );
        },
      },
      {
        id: 'Period',
        Header: intl.formatMessage({id: 'PERIOD'}),
        visible: can_manage_accounts,
        maxWidth: 100,
        minWidth: 100,
        Cell: ({value: {period_month, period_year}}) => {
          if (!period_month || !period_year) {
            return null;
          }

          return TableUtils.renderBaseContent(
            capitalizeFirstLetter(
              CustomFormatter.formatDateWithFullMonthAndYear(getDateFromMonthAndYear(period_month - 1, period_year)),
            ),
          );
        },
      },
      {
        id: 'CreatedAt',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'CREATED_AT'})),
        maxWidth: 100,
        minWidth: 100,
        Cell: ({value: {created_at}}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatDateTimeWithFullMonth(created_at));
        },
      },
      {
        id: 'Indicator',
        maxWidth: 40,
        minWidth: 40,
        visible: can_manage_accounts,
        forceApplySize: true,
        Cell: ({value: {transaction_id}}) => {
          const transactionId = transaction_id as number;
          let parts = 1;
          if (transactionId > GroupedColors.length) {
            parts = Math.ceil(transactionId / GroupedColors.length);
          }
          const maxIndexInPart = GroupedColors.length * parts;
          const offset = maxIndexInPart - transactionId;
          const index = GroupedColors.length - offset - 1;
          return TableUtils.renderBaseContent(
            <span className={cn('fa fa-circle icon-xl', {[`text-${GroupedColors[index]}`]: true})} />,
          );
        },
      },
    ];
  }, []);

  return <Table data={transactionsOperations} columns={cols} />;
};
