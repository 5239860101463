import {CreatedFile} from '../../api/file-api/files-api';
import {PartialNullable} from '../../../types/types';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../api/axios-instance';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {useBaseListPage} from '../base/base-list-page-context';
import {EntityId} from '../../api/base/BaseEntity';
import {useIntl} from '../../hooks/use-intl';
import {useFilesApi} from '../../hooks/apis/use-files-api';

export enum CreatedFileAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
}

export type HandleCreatedFileActionPayload =
  | HandleCreatedFileEditAction
  | HandleCreatedFileDeleteAction
  | HandleCreatedFileDownloadAction;

export type HandleCreatedFileEditAction = {
  type: CreatedFileAction.EDIT;
  entity: PartialNullable<CreatedFile>;
};

export type HandleCreatedFileDownloadAction = {
  type: CreatedFileAction.DOWNLOAD;
  entity: PartialNullable<CreatedFile>;
};

export type HandleCreatedFileDeleteAction = {
  type: CreatedFileAction.DELETE;
  entityId: EntityId;
};

export function useCreatedFileActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useFilesApi();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleCreatedFileActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case CreatedFileAction.DELETE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_FILE'}))) {
            try {
              await api.remove(payload.entityId);
              toast.success(intl.formatMessage({id: 'FILE_SUCCESS_DELETED'}));
              fetch?.(appliedQueryParams);
            } catch (e) {
              if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
                toast.error(intl.formatMessage({id: 'FILE_FAILED_DELETED'}));
              }
            }
          }
          break;
        }
        case CreatedFileAction.DOWNLOAD: {
          window.open(payload?.entity?.url || '');
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    handleCreatedFileActions: handle,
  };
}
