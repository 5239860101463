import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

interface IModalPaymentRequestProps {
  visible: boolean;
  errors?: string | null;
  validationErrors?: ValidationErrorsType;
  title: string | null;

  onChangeTitle(name: string): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateEditGenre: React.FC<IModalPaymentRequestProps> = ({
  title,
  visible,
  errors,
  validationErrors,
  onChangeTitle,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    try {
      setLoading(true);
      await onOkClick();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTitle = (e: any) => {
    onChangeTitle(e.currentTarget.value);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'GENRE'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={errors || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={errors != undefined}
            />

            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('name', validationErrors)}>
                <InputText
                  required
                  value={title}
                  label={intl.formatMessage({id: 'TITLE'})}
                  hasError={!!getValidationErrorMessage('name', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={handleChangeTitle}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
