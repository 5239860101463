import React from 'react';
import {useIntl} from 'react-intl';
import {Filter} from '../../api/DTOs/IFilterDtos';
import {Contractor} from '../../api/contractor-api/Contractor';
import {Toolbar} from './Toolbar';
import {useTransactionActionHandler} from '../../pages/admin/transactions/use-transactions-action-handler';
import {TransactionAction} from '../../pages/admin/transactions/transaction-action-types';

interface IProps {
  filters: Filter;
  partOfContractor: Pick<Contractor, 'id' | 'calculated_name'> | null;
}

const ICONS = {
  DOWNLOAD: require('../../images/svg/Download.svg'),
  DOLLAR: require('../../images/svg/Dollar.svg'),
  MONEY: require('../../images/svg/Money.svg'),
};

export const TransactionsActionsToolbar: React.FC<IProps> = ({partOfContractor}) => {
  const intl = useIntl();
  const {handleTransactionAction} = useTransactionActionHandler();
  const handleAction = async (
    action:
      | TransactionAction.CREATE_TO_OUR_ORGANIZATION
      | TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE
      | TransactionAction.CREATE_WITHDRAWAL,
  ) => {
    const data = partOfContractor
      ? {
          contractorId: partOfContractor.id,
          contractorName: partOfContractor.calculated_name,
        }
      : undefined;
    await handleTransactionAction({type: action, data: data});
  };
  return (
    <>
      <Toolbar
        items={[
          {
            title: intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE}),
            type: 'BUTTON',
            icon: ICONS.DOLLAR,
            className: 'btn btn-light-primary font-weight-bolder',
            onClick: () => handleAction(TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE),
          },
          {
            title: intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_WITHDRAWAL}),
            type: 'BUTTON',
            icon: ICONS.DOLLAR,
            className: 'btn btn-light-primary font-weight-bolder',
            onClick: () => handleAction(TransactionAction.CREATE_WITHDRAWAL),
          },
          {
            title: intl.formatMessage({id: 'CREATE_TRANSACTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION}),
            type: 'BUTTON',
            icon: ICONS.MONEY,
            className: 'btn btn-light-primary font-weight-bolder',
            onClick: () => handleAction(TransactionAction.CREATE_TO_OUR_ORGANIZATION),
          },
        ]}
      />
    </>
  );
};
