import {BaseEndpoint} from '../base-endpoint';
import {
  ResponseKnowledgeBaseArticle,
  ResponseKnowledgeBaseArticles,
  ResponseKnowledgeBaseChapter,
  ResponseKnowledgeBaseChapters,
  ResponseKnowledgeBaseSimplifiedArticles,
} from './knowledge-base-response-contracts';
import config from '../../../configs/config';
import {IChapterDto} from './IChapterDto';
import {IResponseBase} from '../response-contracts/base-response';
import {IArticleDto} from './IArticleDto';
import {IQueryParams} from '../DTOs/IFilterDtos';

export class KnowledgeBaseApi extends BaseEndpoint {

  constructor() {
    super();
  }

  public async createChapter(data: Pick<IChapterDto, 'title_ru'>): Promise<ResponseKnowledgeBaseChapter> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/chapters`, {
      fields: {
        ...data,
      },
    });
    return response.data;
  }

  public async getChapters(): Promise<ResponseKnowledgeBaseChapters> {
    const response = await this.get(`${config.BASE_API_URL}/knowledge-base/chapters`);
    return response.data;
  }

  public async updateChapter(
    chapterId: number,
    data: Pick<IChapterDto, 'title_ru'>,
  ): Promise<ResponseKnowledgeBaseChapter> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/chapters/${chapterId}`, {
      fields: {
        ...data,
      },
    });
    return response.data;
  }

  public async deleteChapter(chapterId: number): Promise<IResponseBase> {
    const response = await this.delete(`${config.BASE_API_URL}/knowledge-base/chapters/${chapterId}`);
    return response.data;
  }

  public async updateChapterPosition(chapterId: number, position: number): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/chapters/${chapterId}/change-position`, {
      fields: {
        position,
      },
    });
    return response.data;
  }

  public async createArticle(
    data: Partial<
      Pick<IArticleDto, 'title_ru' | 'title_en' | 'content_en' | 'content_ru' | 'chapter_id' | 'status'> & {
        chapter_id: number;
      }
    >,
  ): Promise<ResponseKnowledgeBaseArticle> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/articles`, {
      fields: {
        ...data,
      },
    });
    return response.data;
  }

  public async getArticles(params?: IQueryParams): Promise<ResponseKnowledgeBaseArticles> {
    const query = this.applyQueryParams(params);
    const response = await this.get(`${config.BASE_API_URL}/knowledge-base/articles?${query}`);
    return response.data;
  }

  public async getArticle(articleId: number): Promise<IResponseBase<{item: IArticleDto}>> {
    const response = await this.get(`${config.BASE_API_URL}/knowledge-base/articles/${articleId}`);
    return response.data;
  }

  public async getSimplifiedArticles(params?: IQueryParams): Promise<ResponseKnowledgeBaseSimplifiedArticles> {
    const query = this.applyQueryParams(params);
    const response = await this.get(`${config.BASE_API_URL}/knowledge-base/articles/simplified?${query}`);
    return response.data;
  }

  public async updateArticle(
    articleId: number,
    data: Pick<IArticleDto, 'title_ru' | 'title_en' | 'content_ru' | 'content_en'> & {chapter_id: number},
  ): Promise<ResponseKnowledgeBaseArticle> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/articles/${articleId}`, {
      fields: {
        ...data,
      },
    });

    return response.data;
  }

  public async deleteArticle(articleId: number): Promise<IResponseBase> {
    const response = await this.delete(`${config.BASE_API_URL}/knowledge-base/articles/${articleId}`);
    return response.data;
  }

  public async updateArticlePosition(articleId: number, position: number): Promise<ResponseKnowledgeBaseArticles> {
    const response = await this.post(`${config.BASE_API_URL}/knowledge-base/articles/${articleId}/change-position`, {
      fields: {
        position,
      },
    });
    return response.data;
  }
}
