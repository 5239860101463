import React, {PropsWithChildren} from 'react';
import SVG from 'react-inlinesvg';
import './alert.scss';
import cn from 'classnames';
import {BootstrapColors, BootstrapSizes} from '../../../../styles/bootstap-colors';

interface AlertProps {
  text: string | null | React.ReactNode;
  type: BootstrapColors;
  visible?: boolean;

  icon?: string;
  className?: string;
  iconClassName?: string;
  dismissible?: boolean;
  iconSize?: BootstrapSizes;

  onClose?(): void;
}

const ICONS = {
  CHECK: require('../../../images/svg/Check.svg'),
  WARNING: require('../../../images/svg/Warning.svg'),
  INFO: require('../../../images/svg/Info-circle.svg'),
};

export const AlertCustom: React.FC<PropsWithChildren<AlertProps>> = ({
  text,
  type,
  visible,
  children,
  icon,
  className,
  dismissible,
  iconClassName,
  iconSize,
  onClose,
}) => {
  if ((visible != null && !visible) || text == null) return null;

  const getIcon = () => {
    if (icon) return icon;
    switch (type) {
      case 'danger':
      case 'warning':
      case BootstrapColors.LIGHT_DANGER:
      case 'light-warning':
        return ICONS.WARNING;
      case 'light-info':
        return ICONS.INFO;
      case 'success':
      case 'light-success':
        return ICONS.CHECK;
      default:
        return ICONS.INFO;
    }
  };

  const selectedIcon = getIcon();
  return (
    <div className={cn('alert alert-custom fade show', className, `alert-${type}`)} role='alert'>
      {selectedIcon && (
        <div className='alert-icon'>
          <span
            className={cn('svg-icon', iconClassName, {
              'svg-icon-3x': !iconSize,
              [`svg-icon-${iconSize}`]: iconSize,
            })}>
            <SVG src={selectedIcon} />
          </span>
        </div>
      )}
      <div className='alert-text font-weight-bolder save-line-and-tabulation' style={{wordBreak: 'break-word'}}>
        {text} {children}
      </div>
      {dismissible && (
        <div className='alert-close'>
          <button onClick={onClose} type='button' className='close' data-dismiss='alert' aria-label='Close'>
            <span aria-hidden='true'>
              <i className='ki ki-close' />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
