import {BaseEntity, DateAsString, EntityId} from '../base/BaseEntity';

export interface ReportPeriod extends BaseEntity {
  id: EntityId;
  year: number;
  month: number;
  is_end_of_quarter: boolean;
  reportPeriodStatus: ReportPeriodStatus;
  started_at: DateAsString;
  expires_at: DateAsString;
}

export enum ReportPeriodStatus {
  OPEN = 'OPEN',
  OPENING = 'OPENING',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
}
