import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {AuthPage} from './modules/Auth';
import {IApplicationStore} from '../redux/rootReducer';
import BasePage from './base-page';
import {StringParam, useQueryParam} from 'use-query-params';
import {ApiRequestException} from './api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';
import {Routes} from '../configs/routes';
import {useUpdater} from '../updater/use-updater';
import {PagesConfigurationsProvider} from '../configs/pages-configuration-context';
import {Layout} from '../_metronic/layout/components/Layout';
import {BreadcrumbsProvider} from './components/breadcrumbs/breadcrumbs-context';
import {useAuthApi} from './hooks/apis/auth-api';
import {Logout} from './modules/Auth/pages/logout';

export function AuthRouter() {
  const intl = useIntl();
  const history = useHistory();

  const api = useAuthApi();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [redirectUrlParam] = useQueryParam('redirect_url', StringParam);
  const isAuthorized = useSelector<IApplicationStore, boolean>(({auth}) => auth.user != null, shallowEqual);

  useUpdater();

  useEffect(() => {
    const fetchData = async () => {
      await fetchCsrfToken();
    };
    fetchData().then();
  }, []);

  const fetchCsrfToken = async () => {
    try {
      await api.loadCsrfToCookie();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const renderLayoutWithBasePage = () => {
    return (
      <PagesConfigurationsProvider>
        <BreadcrumbsProvider>
          <Layout>
            <BasePage />
          </Layout>
        </BreadcrumbsProvider>
      </PagesConfigurationsProvider>
    );
  };

  const renderAuthPage = () => {
    return (
      <Route path={Routes.getAuthRoute()}>
        <AuthPage redirectUrl={redirectUrl} />
      </Route>
    );
  };

  const redirectToBasePage = () => {
    setTimeout(() => {
      setRedirectUrl(null);
    }, 3000);
    return <Redirect from={Routes.getAuthRoute()} to={redirectUrl || redirectUrlParam || '/'} />;
  };

  const redirectToAuthLogin = () => {
    if (
      !redirectUrl &&
      !history.location.pathname.includes(Routes.getLoginRoute()) &&
      !history.location.pathname.includes(Routes.getLogoutRoute()) &&
      !history.location.pathname.includes(Routes.getAuthRoute())
    ) {
      setRedirectUrl(history.location.pathname);
    }

    return <Redirect to={Routes.getAuthRoute()} />;
  };

  return (
    <Switch>
      <Route path={Routes.getLogoutRoute()} component={Logout} />
      {isAuthorized ? redirectToBasePage() : renderAuthPage()}
      {isAuthorized ? renderLayoutWithBasePage() : redirectToAuthLogin()}
    </Switch>
  );
}
