import React, {useEffect, useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../../api/Paginator';
import {useIntl} from 'react-intl';
import {ContractorsApi} from '../../../../api/contractor-api/contractors-api';
import {Contractor, ContractorType} from '../../../../api/contractor-api/Contractor';
import {ContractorsTable} from './contractors-table';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useLoading} from '../../../../hooks/use-loading';
import {Filter, FilterType} from '../../../../components/filters/filters';
import {FilterBuilder} from '../../../../components/filters/filter-builder';
import {BaseListPage} from '../../../base/base-list-page';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {ICONS} from '../../../../images/images';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../../configs/routes';
import {getEnumKeys} from '../../../../../types/types';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const ContractorsPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = new ContractorsApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [contractors, setContractors] = useState<Array<Contractor>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_requests_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'calculated_name',
          placeholder: intl.formatMessage({id: 'NAME'}),
        },
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'type',
          options: getEnumKeys(ContractorType).map(type => ({
            value: type,
            label: intl.formatMessage({id: `CONTRACTOR_TYPE_${type}`}),
          })),
          placeholder: intl.formatMessage({id: 'CONTRACTOR_TYPE'}),
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);

  useEffect(() => {
    fetchSelectValuesByKey('users').then();
  }, []);

  const fetchContractors = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getContractors(filters);
      setContractors(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      sortsConfig={sortConfig}
      filtersConfig={filterConfig}
      toolbarConfig={[
        {
          type: 'BUTTON',
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: () => history.push(AdminRoutes.getCreateContractorRoute()),
          title: intl.formatMessage({id: 'CREATE_CONTRACTOR'}),
        },
      ]}
      pagination={{info: paginationInfo}}
      fetchData={fetchContractors}>
      <ContractorsTable contractors={contractors} />
    </BaseListPage>
  );
};
