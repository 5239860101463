import React, {useEffect, useMemo, useState} from 'react';
import {ValidateErrorWrapper} from '../../../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../utils/utils';
import {InputGroupRadio} from '../../../../../components/Inputs/InputGroupRadio';
import {Contractor, ContractorType, Currency, Jurisdiction} from '../../../../../api/contractor-api/Contractor';
import {IndividualFields} from './individual-fields/individual-fields';
import {LegalFields} from './legal-fields/legal-fields';
import {Separator, SeparatorType} from '../../../../../components/separator';
import {BankFields} from './bank-fields/bank-fields';
import {useIntl} from '../../../../../hooks/use-intl';
import {PartialNullable} from '../../../../../../types/types';
import {EntrepreneurFields} from './entrepreneur-fields/entrepreneur-fields';
import {EntrepreneurFieldsEn} from './entrepreneur-fields/entrepreneur-fields-en';
import {UpdatedFields, UpdateFields} from '../../../../../hooks/use-advanced-state';
import {useTransliterateApi} from '../../../../../hooks/apis/use-transliterate-api';
import {useThrottledCallback} from 'use-debounce';
import {IndividualFieldsEn} from './individual-fields/individual-fields-en';
import {LegalFieldsEn} from './legal-fields/legal-fields-en';
import {SignatoryFields} from './legal-fields/signatory-fields';
import {SignatoryFieldsEn} from './legal-fields/signatory-fields-en';
import {InputText} from '../../../../../components/Inputs/InputText';
import {InputSelect} from '../../../../../components/Inputs/InputSelect';
import {BankFieldsTransliterated} from './bank-fields/bank-fields-transliterated';
import {useLoading} from '../../../../../hooks/use-loading';
import {ApiRequestException} from '../../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {IJurisdictionDto} from '../../../../../api/contractor-api/IJurisdictionDto';
import {ContractorsApi} from '../../../../../api/contractor-api/contractors-api';

type Props = {
  hideCode?: boolean;
  contractor: PartialNullable<Contractor>;
  validationErrors: ValidationErrorsType<Contractor>;
  updateFields: UpdateFields<Contractor>;
};

const TRANSLITERATE_FIELDS_KEYS: Array<keyof Contractor> = [
  'entrepreneur_name_last',
  'entrepreneur_name_first',
  'entrepreneur_name_middle',
  'entrepreneur_address',

  'individual_name_first',
  'individual_name_middle',
  'individual_name_last',
  'individual_registration_address',

  'legal_signatory_name_last',
  'legal_signatory_name_middle',
  'legal_signatory_name_first',
  'legal_address',
  'legal_name',

  'rub_bank_name',
  'rub_bank_address',
];

export const ContractorFields: React.FC<Props> = ({hideCode, validationErrors, updateFields, contractor}) => {
  const intl = useIntl();
  const transliterateApi = useTransliterateApi();
  const contractorsApi = new ContractorsApi();
  const [loadingJurisdictions, startLoadingJurisdictions, stopLoadingJurisdictions] = useLoading({
    jurisdictions: true,
  });

  const [jurisdictions, setJurisdictions] = useState<Array<IJurisdictionDto>>([]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchJurisdictions();
  }, []);

  const fetchJurisdictions = async () => {
    try {
      startLoadingJurisdictions('jurisdictions');
      const response = await contractorsApi.getJurisdictions();
      setJurisdictions(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoadingJurisdictions('jurisdictions');
    }
  };

  const contractorTypeRadios = useMemo(
    () => [
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.LEGAL}),
        value: ContractorType.LEGAL,
      },
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.INDIVIDUAL}),
        value: ContractorType.INDIVIDUAL,
      },
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.ENTREPRENEUR}),
        value: ContractorType.ENTREPRENEUR,
      },
    ],
    [],
  );

  const transliterate = useThrottledCallback(async (updatedFields: UpdatedFields<Contractor>) => {
    const translatedFields: PartialNullable<Contractor> = {};
    for (const key of Object.keys(updatedFields)) {
      if (TRANSLITERATE_FIELDS_KEYS.includes(key as keyof Contractor)) {
        try {
          // @ts-ignore
          translatedFields[`${key}_en`] = (
            await transliterateApi.transliterate(updatedFields[key as keyof Contractor] as string)
          ).data.item;
        } catch (e) {
          // @ts-ignore
          translatedFields[`${key}_en`] = null;
        }
      }
    }

    updateFields(translatedFields);
  }, 2000);

  const handleEdit: UpdateFields<Contractor> = async updatedFields => {
    updateFields(updatedFields);
    if (!(updatedFields instanceof Function)) {
      await transliterate(updatedFields);
    }
  };

  return (
    <>
      <ValidateErrorWrapper className={'mb-2'} message={getValidationErrorMessage('type', validationErrors)}>
        <InputGroupRadio
          required
          label={intl.formatMessage({id: 'CONTRACTOR_TYPE'})}
          selected={contractor?.type ?? null}
          items={contractorTypeRadios}
          onChange={value => handleEdit({type: value as ContractorType})}
        />
      </ValidateErrorWrapper>
      <form className={'row max-w-1200px mt-5'}>
        <div className={'col-md-12 col-xl-6'}>
          <ValidateErrorWrapper message={getValidationErrorMessage('jurisdiction', validationErrors)}>
            <InputSelect
              value={contractor?.jurisdiction}
              label={intl.formatMessage({id: 'JURISDICTION'})}
              hasError={!!getValidationErrorMessage('jurisdiction', validationErrors)}
              isClearable={false}
              isLoading={loadingJurisdictions.jurisdictions}
              ariaLabel={'arial-label'}
              onChange={value => handleEdit({jurisdiction: value as Jurisdiction})}
              options={jurisdictions.map(p => ({value: p.value, label: p.title}))}
              placeholder={intl.formatMessage({id: 'JURISDICTION'})}
            />
          </ValidateErrorWrapper>
          {(hideCode == null || !hideCode) && (
            <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('code', validationErrors)}>
              <InputText
                required
                value={contractor?.code}
                label={intl.formatMessage({id: 'CONTRACTOR_CODE'})}
                descriptionLabel={intl.formatMessage({id: 'CONTRACTOR_CODE_HELP_TEXT'})}
                hasError={!!getValidationErrorMessage('code', validationErrors)}
                onChange={e => updateFields({code: e.currentTarget.value})}
                placeholder={intl.formatMessage({id: 'CODE'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>
          )}

          {contractor?.type === ContractorType.ENTREPRENEUR && (
            <>
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {intl.formatMessage({id: 'INFO_ABOUT_ENTREPRENEUR'})}
              </h3>
              <EntrepreneurFields
                validationErrors={validationErrors}
                contractor={contractor}
                updateFields={handleEdit}
              />
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {`${intl.formatMessage({id: 'INFO_ABOUT_ENTREPRENEUR'})} (${intl.formatMessage({
                  id: 'TRANSLIT',
                })})`}
              </h3>
              <EntrepreneurFieldsEn
                validationErrors={validationErrors}
                contractor={contractor}
                updateFields={handleEdit}
              />
            </>
          )}

          {contractor?.type === ContractorType.INDIVIDUAL && (
            <>
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {intl.formatMessage({id: 'INFO_ABOUT_INDIVIDUAL'})}
              </h3>
              <IndividualFields validationErrors={validationErrors} contractor={contractor} updateFields={handleEdit} />
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {`${intl.formatMessage({id: 'INFO_ABOUT_INDIVIDUAL'})} (${intl.formatMessage({
                  id: 'TRANSLIT',
                })})`}
              </h3>
              <IndividualFieldsEn
                validationErrors={validationErrors}
                contractor={contractor}
                updateFields={handleEdit}
              />
            </>
          )}

          {contractor?.type === ContractorType.LEGAL && (
            <>
              <h3 className={'my-5 text-muted font-weight-boldest'}>{intl.formatMessage({id: 'INFO_ABOUT_LEGAL'})}</h3>
              <LegalFields validationErrors={validationErrors} contractor={contractor} updateFields={handleEdit} />
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {intl.formatMessage({id: 'INFO_ABOUT_LEGAL_SIGNATORY'})}
              </h3>
              <SignatoryFields validationErrors={validationErrors} contractor={contractor} updateFields={handleEdit} />
              <Separator lineType={SeparatorType.SOLID} className={'my-10'} />
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {`${intl.formatMessage({id: 'INFO_ABOUT_LEGAL'})} (${intl.formatMessage({
                  id: 'TRANSLIT',
                })})`}
              </h3>
              <LegalFieldsEn validationErrors={validationErrors} contractor={contractor} updateFields={handleEdit} />
              <h3 className={'my-5 text-muted font-weight-boldest'}>
                {`${intl.formatMessage({id: 'INFO_ABOUT_LEGAL_SIGNATORY'})} (${intl.formatMessage({
                  id: 'TRANSLIT',
                })})`}
              </h3>
              <SignatoryFieldsEn
                validationErrors={validationErrors}
                contractor={contractor}
                updateFields={handleEdit}
              />
            </>
          )}
        </div>
        <div className={'col-1 d-flex justify-content-center'}>
          <Separator position={'vertical'} />
        </div>
        <div className={'col-md-12 col-xl-5'}>
          <h3 className={'my-5 text-muted font-weight-boldest'}>{intl.formatMessage({id: 'INFO_ABOUT_BANK'})}</h3>
          <BankFields validationErrors={validationErrors} contractor={contractor} updateFields={handleEdit} />
          {
            // Поля транслитерации выводятся только, если выбранная валюта является Рублем.
            contractor?.bank_account_currency === Currency.RUB && (
              <>
                <Separator lineType={SeparatorType.SOLID} className={'my-10'} />
                <h3 className={'my-5 text-muted font-weight-boldest'}>
                  {`${intl.formatMessage({id: 'INFO_ABOUT_BANK'})} (${intl.formatMessage({
                    id: 'TRANSLIT',
                  })})`}
                </h3>
                <BankFieldsTransliterated
                  validationErrors={validationErrors}
                  contractor={contractor}
                  updateFields={handleEdit}
                />
              </>
            )
          }
        </div>
      </form>
    </>
  );
};
