import {useBaseEndpointApi} from "../use-base-endpoint-api";
import {useCallback} from "react";
import {BaseItemsResponse} from "../../api/base/base-crud-api-response";

export type BackgroundProcess = {
  argv: string[];
  command: string;
  commandLine: string;
  processId: number;
  startTime: string;
  user: string;
}

export function useBackgroundProcessesApi() {
  const api = useBaseEndpointApi();

  const getBackgroundProcesses = useCallback(async () => {
    return await api.get<BaseItemsResponse<BackgroundProcess>>(
      `background-processes`,
    );
  }, []);

  return {
    getBackgroundProcesses,
  };
}
