import React, {Dispatch, useMemo} from 'react';
import {PartialNullable, Renderer} from '../../../../../../../types/types';
import {UpdateFields, useAdvancedState} from '../../../../../../hooks/use-advanced-state';
import {ColumnDefinition} from '../../../../../../components/tables/entity-table';
import _ from 'lodash';
import {ValidationErrorsType} from '../../../../../../utils/utils';

export type RowProps<T> = {
  item: T;
  index: number;
  className?: string | ((rowState: T, index: number) => string);
  columnDefinitions: ColumnDefinition<T>[];
};

export type RowEntity<T> = RowFields<T> & T;

type RowFields<T> = {
  isDeleted: boolean;
  isChanged: boolean;
  isCreated: boolean;
  isEdit: boolean;
  validationErrors: ValidationErrorsType | null;
  prevState: T;
};

export type RowColumnDefinition<T> = {
  id?: string;
  visible?: boolean;
  className?: string | ((rowState: RowEntity<T>) => string);
  style?: React.CSSProperties;
  value: (
    rowState: RowEntity<T>,
    updateState: UpdateFields<RowEntity<T>>,
    setState: Dispatch<PartialNullable<RowEntity<T>>>,
  ) => Renderer;
};

export class EntityToEditableRowConverter {
  public static convert<T>(item: T, params?: Partial<RowFields<T>>): RowEntity<T> {
    return {
      isChanged: false,
      isCreated: false,
      isDeleted: false,
      isEdit: false,
      validationErrors: null,
      prevState: item,
      ...item,
      ...(params ?? {}),
    };
  }

  public static convertAll<T>(items: T[], params?: Partial<RowFields<T>>): RowEntity<T>[] {
    return items.map(item => this.convert(item, params));
  }

  public static removeTableFields<T>(items: T[]) {
    return _.cloneDeep(items).map(i => {
      ['isChanged', 'isCreated', 'isDeleted', 'isEdit', 'prevState'].forEach(key => {
        delete i[key as keyof T];
      });

      return i;
    });
  }
}

export function Row<T>(props: RowProps<T>) {
  const [state, setState, updateState] = useAdvancedState<T>(props.item);
  const rowClassName = useMemo(() => {
    let className;
    if (props.className != null) {
      className = typeof props.className === 'string' ? props.className : props.className(state as any, props.index);
    }

    return className;
  }, [props.className, state]);
  return (
    <tr className={rowClassName}>
      {props.columnDefinitions
        .filter(columnDefinition => columnDefinition.visible == null || columnDefinition.visible)
        .map(columnDefinition => {
          let className;
          if (columnDefinition.className != null) {
            className =
              typeof columnDefinition.className === 'string'
                ? columnDefinition.className
                : columnDefinition.className(state as RowEntity<T>);
          }
          return (
            <td key={columnDefinition.id} className={className} style={columnDefinition.style}>
              {columnDefinition.value(state as RowEntity<T>, updateState, setState, props.index)}
            </td>
          );
        })}
    </tr>
  );
}
