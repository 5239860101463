import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Contract, ContractState, ContractType} from '../../../../api/contract-api/Contract';
import {FormattedMessage} from 'react-intl';
import {ErrorStub} from '../../../../components/error-stub';
import {Preloader} from '../../../../components/preloader';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {AdminRoutes} from '../../../../../configs/routes';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {ContractPrivileges, ContractRelation, useContractsApi} from '../../../../hooks/apis/use-contracts-api';
import {useIntl} from '../../../../hooks/use-intl';
import {SupAgreementsTable} from '../sup-agreements/sup-agreements-table';
import cn from 'classnames';
import {ContractInformation} from './contract-information/contract-information';
import {ICONS} from '../../../../images/images';
import {ARRAY} from '../../../../consts';
import {toast} from 'react-toastify';
import {useLoading} from '../../../../hooks/use-loading';
import {saveAs} from 'file-saver';
import {useSupAgreementActionHandler} from '../sup-agreements/use-sup-agreement-actions-handler';
import {ContractualDocuments} from './contractual-documents';
import {ContractualDocumentType} from '../../../../hooks/apis/use-contractual-documents-api';
import {FinancialReportsDocuments} from '../../../../components/documents/financial-reports-documents';
import {ChannelsBlock} from '../channels-block';
import {VideosBlock} from '../video-block';
import {isValidationException} from '../../../../utils/api-utils';
import {prepareDate, tryGetDateOrNull} from '../../../../utils/utils';
import {TabsComponent} from '../../../../components/tabs-component';
import {EmptyListState} from '../../../../components/empty-list-state';
import {GenerateDetailedExcelReportModal} from '../../../../components/modals/generate-detailed-excel-report/generate-detailed-excel-report';
import {prepareChannelDataFromResponse} from '../sup-agreements/create-edit-sup-agreement-page';

export const ContractPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const contractId = match.params.id;

  const api = useContractsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    fetchContract: true,
    fetchContractState: true,
    generateTemplate: false,
    delete: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [contractState, setContractState] = useState<ContractState | null>(null);
  const [generateDetailedExcelReportModalVisible, setGenerateDetailedExcelReportModalVisible] = useState(false);

  const [contract, setContract] = useState<Contract | null>(null);
  const [privileges, setPrivileges] = useState<ContractPrivileges | null>(null);
  const [relations, setRelations] = useState<ContractRelation | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    const fetchAll = async () => {
      await fetchContract();
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchAll();
  }, []);

  useEffect(() => {
    if (contract?.composite_number) {
      setBreadcrumb(contract.composite_number);
    }
  }, [contract?.composite_number]);

  useEffect(() => {
    fetchUpdateContractState().then();
  }, [contractId]);

  const fetchContract = async () => {
    try {
      startLoading('fetchContract');
      const result = await api.get(contractId);
      setRelations(result.relations);
      setPrivileges(result.privileges);
      setContract({
        ...result.item,
        add_youtube_channels: prepareChannelDataFromResponse(result.item.add_youtube_channels ?? []),
      });
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('fetchContract');
    }
  };

  const fetchUpdateContractState = async () => {
    try {
      startLoading('fetchContractState');
      const {item} = await api.getContractStateOnDate(contractId, prepareDate(new Date()) as string);
      if (item != null) {
        setContractState({...item, youtube_channels: prepareChannelDataFromResponse(item.youtube_channels ?? [])});
      }
    } catch (e) {
      if (!isValidationException(e as ApiRequestException)) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('fetchContractState');
    }
  };

  const handleGenerateTemplateClick = async () => {
    try {
      startLoading('generateTemplate');
      const res = await api.generateContractTemplate(contractId);
      saveAs(res.content, res.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('generateTemplate');
    }
  };

  const handleDeleteClick = async () => {
    try {
      startLoading('delete');
      await api.remove(contractId);
      history.goBack();
    } catch (e) {
      toast.error((e as ApiRequestException).errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('delete');
    }
  };

  const {handleSupAgreementActions} = useSupAgreementActionHandler(fetchContract);

  if (loadings.fetchContract) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex'}>
            <div className={'flex-grow-1'}>
              <div className={'d-flex align-items-center justify-content-between flex-wrap'}>
                <div>
                  <div className={'d-flex'}>
                    <span className={'font-size-h4 font-weight-boldest'}>
                      <FormattedMessage id={'CONTRACT_NUMBER'} />: {contract?.composite_number}
                    </span>
                  </div>
                  <div
                    className={cn('font-weight-bolder', {
                      'text-success': contract?.is_signed,
                      'text-danger': !contract?.is_signed,
                    })}>
                    {intl.formatMessage({id: contract?.is_signed ? 'IS_SIGNED' : 'IS_NOT_SIGNED'})}
                  </div>
                  <Toolbar
                    className={'mt-5'}
                    items={[
                      {
                        type: 'BUTTON',
                        loading: loadings.generateTemplate,
                        title: intl.formatMessage({id: 'DOWNLOAD_DOCX_CONTRACT_TEMPLATE'}),
                        icon: ICONS.DOWNLOAD,
                        className: 'btn btn-light-success btn-sm font-weight-bolder text-uppercase',
                        onClick: handleGenerateTemplateClick,
                      },
                      {
                        type: 'BUTTON',
                        title: intl.formatMessage({id: 'DETAILED_EXCEL_REPORT'}),
                        icon: ICONS.CONTRACTS,
                        className: 'btn btn-light-success btn-sm font-weight-bolder text-uppercase',
                        onClick: () => setGenerateDetailedExcelReportModalVisible(true),
                      },
                      {
                        type: 'BUTTON',
                        disabled: privileges?.can_edit === false,
                        title: intl.formatMessage({id: 'EDIT'}),
                        icon: ICONS.EDIT,
                        className: 'btn btn-secondary btn-sm font-weight-bolder text-uppercase',
                        onClick: () => history.push(AdminRoutes.getEditContractRoute(contractId)),
                      },
                      {
                        type: 'BUTTON',
                        loading: loadings.delete,
                        disabled: privileges?.can_delete === false,
                        title: intl.formatMessage({id: 'DELETE'}),
                        icon: ICONS.BIN,
                        className: 'btn btn-light-danger btn-sm font-weight-bolder text-uppercase',
                        onClick: handleDeleteClick,
                      },
                    ]}
                  />
                </div>

                <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
                  <div className={'d-flex flex-wrap align-items-center py-2'}>
                    <div className={'d-flex align-items-center'}>
                      <div className={'d-flex justify-content-center flex-column mr-6'}>
                        <div className={'font-weight-bolder'}>
                          <FormattedMessage id={'CONTRACT_START_DATE'} />
                        </div>
                        <span className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                          {intl.formatDate(contract?.started_at, {month: 'long', year: 'numeric', day: 'numeric'})}
                        </span>
                      </div>
                      <div className={'d-flex justify-content-center flex-column'}>
                        <div className={'font-weight-bolder'}>
                          <FormattedMessage id={'CONTRACT_END_DATE'} />
                        </div>
                        <div className={'btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bolder'}>
                          {contract?.expires_at
                            ? intl.formatDate(contract.expires_at, {
                                month: 'long',
                                year: 'numeric',
                                day: 'numeric',
                              })
                            : 'Not Available'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'separator separator-solid my-7'} />

              <div className={'row'}>
                <div className={'col-12 col-xl-8'}>
                  {relations && contract && (
                    <ContractInformation
                      contractor={relations.contractor}
                      ourContractor={relations.ourContractor}
                      contract={contract}
                    />
                  )}
                </div>
              </div>

              <div className={'separator separator-solid my-7'} />

              <div className={'font-size-h4 font-weight-boldest'}>
                <FormattedMessage id={'SUP_AGREEMENTS'} />
              </div>
              <Toolbar
                items={[
                  {
                    title: intl.formatMessage({id: 'CREATE_SUP_AGREEMENT'}),
                    type: 'BUTTON',
                    icon: ICONS.PLUS,
                    className: 'btn btn-sm my-2 font-weight-bolder text-uppercase btn-light-success',
                    onClick: () => history.push(AdminRoutes.getCreateSupAgreementRoute(contractId)),
                  },
                ]}
              />
              <SupAgreementsTable
                onAction={async (supAgreement, action) => {
                  await handleSupAgreementActions({
                    type: action,
                    entity: supAgreement,
                    afterAction: {type: 'fetch'},
                  });
                }}
                contractId={contractId}
                supAgreements={relations?.supAgreements ?? ARRAY.EMPTY}
              />

              <div className={'separator separator-solid my-7'} />
              <div className={'font-size-h4 font-weight-boldest'}>
                <FormattedMessage id={'CONTRACT_STATE'} />
              </div>

              {contract?.type === ContractType.YOUTUBE_PARTNER && (
                <div className={'my-2'}>
                  {loadings.fetchContractState ? (
                    <Preloader />
                  ) : (
                    <TabsComponent
                      tabs={[
                        {
                          key: 'CurrentState',
                          value: (
                            <ChannelsBlock
                              channels={contractState?.youtube_channels ?? []}
                              privileges={{canEdit: false, canCreate: false, canEditRatePercent: false}}
                            />
                          ),
                          title: intl.formatMessage({id: 'CURRENT_CONTRACT_STATE'}),
                        },
                        {
                          key: 'InitialState',
                          value: (
                            <ChannelsBlock
                              channels={contract?.add_youtube_channels ?? []}
                              privileges={{canEdit: false, canCreate: false, canEditRatePercent: false}}
                            />
                          ),
                          title: intl.formatMessage({id: 'INITIAL_CONTRACT_STATE'}),
                        },
                      ]}
                    />
                  )}
                </div>
              )}
              {contract?.type === ContractType.PUBLISH_VIDEO && (
                <div className={'my-2'}>
                  {loadings.fetchContractState ? (
                    <Preloader />
                  ) : (
                    <TabsComponent
                      tabs={[
                        {
                          key: 'CurrentState',
                          value:
                            contractState != null &&
                            (contractState.expires_at == null ||
                              (tryGetDateOrNull(contractState.expires_at) as Date).getDate() <= Date.now()) ? (
                              <VideosBlock
                                videos={contractState?.videos ?? []}
                                privileges={{canEdit: false, canCreate: false}}
                              />
                            ) : (
                              <EmptyListState
                                className={cn('font-size-lg font-weight-boldest text-danger')}
                                text={intl.formatMessage({id: 'CONTRACT_IS_EXPIRED'})}
                              />
                            ),
                          title: intl.formatMessage({id: 'CURRENT_CONTRACT_STATE'}),
                        },
                        {
                          key: 'InitialState',
                          value: (
                            <VideosBlock
                              videos={contract?.add_videos ?? []}
                              privileges={{canEdit: false, canCreate: false}}
                            />
                          ),
                          title: intl.formatMessage({id: 'INITIAL_CONTRACT_STATE'}),
                        },
                      ]}
                    />
                  )}
                </div>
              )}

              <div className={'separator separator-solid my-7'} />

              <div className={'font-size-h4 font-weight-boldest mb-5'}>
                <FormattedMessage id={'CONTRACTUAL_DOCUMENTS'} />
              </div>

              {contract && (
                <ContractualDocuments
                  type={ContractualDocumentType.CONTRACT}
                  parent={contract}
                  documents={relations?.contractualDocumentFiles ?? []}
                />
              )}

              <FinancialReportsDocuments type={'contract'} contract_id={contractId} />
            </div>
          </div>
        </div>
      </div>
      <GenerateDetailedExcelReportModal
        contractId={contractId}
        visible={generateDetailedExcelReportModalVisible}
        onHide={() => setGenerateDetailedExcelReportModalVisible(false)}
      />
    </>
  );
};
