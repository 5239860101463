import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {EntityId} from '../../../api/base/BaseEntity';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useBaseListPage} from '../../base/base-list-page-context';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {useEntityApi} from '../../../hooks/use-entity-api';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {Country} from '../../../api/DTOs/Country';
import {useModalCreateEditCountry} from '../../../components/modals/create-edit-country-modal/create-edit-country-context';
import {PartialNullable} from '../../../../types/types';

export enum CountryAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type HandleCountryActionPayload =
  | HandleCountryCreateAction
  | HandleCountryEditAction
  | HandleCountryDeleteAction;
export type HandleCountryCreateAction = {
  type: CountryAction.CREATE;
};

export type HandleCountryEditAction = {
  type: CountryAction.EDIT;
  country: PartialNullable<Country>;
};

export type HandleCountryDeleteAction = {
  type: CountryAction.DELETE;
  countryId: EntityId;
};

export function useCountryActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useEntityApi<Country>({prefixUrl: 'countries', isAdmin: false});
  const {showCreateEditCountryModal} = useModalCreateEditCountry();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleCountryActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case CountryAction.CREATE: {
          const modalResult = await showCreateEditCountryModal({type: 'CREATE'});
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case CountryAction.EDIT: {
          const modalResult = await showCreateEditCountryModal({
            type: 'EDIT',
            data: {entity: payload.country},
          });
          if (modalResult.reason === CloseModalReason.OK) {
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case CountryAction.DELETE: {
          if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_COUNTRY'}))) {
            try {
              await api.remove(payload.countryId);
              fetch?.(appliedQueryParams);
            } catch (e) {
              if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
                toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
              }
            }
          }
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    handleCountryActions: handle,
  };
}
