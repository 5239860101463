declare global {
  interface ObjectConstructor {
    isNullOrUndefined(obj: any): boolean;
  }
}
Object.isNullOrUndefined = function (obj: any) {
  return obj === null || obj === undefined;
};

export {};
