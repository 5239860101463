import {EntityId} from '../../api/base/BaseEntity';
import {useContractorsApi} from '../../hooks/apis/use-contractors-api';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../api/axios-instance';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {useBaseListPage} from '../base/base-list-page-context';
import {useIntl} from '../../hooks/use-intl';
import {useModalRejectReason} from '../../components/modals/reject-reason/modal-reject-reason-base-context';

export enum ModerateRegistrationAction {
  CONFIRM = 'CONFIRM',
  REJECT = 'REJECT',
  NEED_FIX_REQUIREMENTS = 'NEED_FIX_REQUIREMENTS',
}

export type HandleRegistrationModerateActionPayload = {
  type: ModerateRegistrationAction;
  contractorId: EntityId;
};

export function useRegistrationModerateActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useContractorsApi();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showRejectReasonModal} = useModalRejectReason();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async ({type, contractorId}: HandleRegistrationModerateActionPayload): Promise<any> => {
    try {
      switch (type) {
        case ModerateRegistrationAction.CONFIRM: {
          const modalResult = await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_APPROVE_REGISTRATION'}));
          if (modalResult) {
            await api.registerConfirm(contractorId);
            toast.success(intl.formatMessage({id: 'APPROVE_REGISTRATION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case ModerateRegistrationAction.REJECT: {
          const modalResult = await showRejectReasonModal({
            onSubmit: rejectReason => api.registerReject(contractorId, rejectReason),
          });
          if (modalResult.reason === CloseModalReason.OK) {
            toast.success(intl.formatMessage({id: 'REJECT_REGISTRATION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case ModerateRegistrationAction.NEED_FIX_REQUIREMENTS: {
          const modalResult = await showRejectReasonModal({
            modalConfiguration: {
              title: intl.formatMessage({id: 'DESCRIBE_REQUIREMENTS_REASON'}),
              reasonInputLabel: intl.formatMessage({id: 'REQUIREMENTS'}),
              reasonInputKey: 'requirements',
            },
            onSubmit: requirements => api.registerNeedRequirements(contractorId, requirements),
          });
          if (modalResult.reason === CloseModalReason.OK) {
            toast.success(intl.formatMessage({id: 'REQUEST_FIX_REQUIREMENTS_REGISTRATION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };
  return {
    handleActions: handle,
  };
}
