const dev = {
  BASE_API_URL: '/api',
  GOOGLE_OAUTH_ADMIN_API_URL: '/api/admin_google_oauth2_form',
  GOOGLE_OAUTH_API_URL: '/api/google_oauth2_form',
  YOUTUBE_CHANNEL_URL: 'https://www.youtube.com/channel',
  YOUTUBE_VIDEO_URL: 'https://www.youtube.com/watch?v=',
  MC_PAY_URL: 'https://mp-stage.mediacube.dev',
};

const production = {
  BASE_API_URL: '/api',
  GOOGLE_OAUTH_ADMIN_API_URL: '/api/admin_google_oauth2_form',
  GOOGLE_OAUTH_API_URL: '/api/google_oauth2_form',
  YOUTUBE_CHANNEL_URL: 'https://www.youtube.com/channel',
  YOUTUBE_VIDEO_URL: 'https://www.youtube.com/watch?v=',
  MC_PAY_URL: 'https://mcpay.io',
};

const config = process.env.NODE_ENV === 'production' ? production : dev;

export default {
  ...config,
};
