import moment from 'moment';
import {IYoutubeThumbnailsDto} from '../api/DTOs/IYoutubeThumbnailsDto';
import {IYoutubeThumbnailDto} from '../api/DTOs/IYoutubeThumbnailDto';
import LodashIsEqual from 'lodash.isequal';
import LodashIsObject from 'lodash.isobject';
import LodashIsEmpty from 'lodash.isempty';

export function tryGetPreviewOrStub(previews: IYoutubeThumbnailsDto | null | undefined): IYoutubeThumbnailDto {
  const stub = {height: 180, width: 320, url: 'https://via.placeholder.com/320x180?text=NOT+FOUND'};

  if (!previews) {
    return stub;
  }

  if (previews.medium) {
    return previews.medium;
  } else if (previews.high) {
    return previews.high;
  } else if (previews.default) {
    return previews.default;
  }

  return stub;
}

export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function trimStringWithEllipsis(str: string, length: number): string {
  if (str.length > length) {
    return str.trim().substring(0, Math.min(str.length, length)) + '...';
  }
  return str;
}

export function prepareDate(date: Date | undefined | null, format = 'YYYY-MM-DD'): string | null {
  if (date == null) {
    return null;
  }
  const d = moment(date);
  return d.isValid() ? d.format(format) : null;
}

export function tryGetDateOrNull(date: string | undefined | null): Date | null {
  if (!date) {
    return null;
  }

  const d = moment(date);
  return d.isValid() ? d.toDate() : null;
}

export function getDateFromMonthAndYear(month: number, year: number) {
  return new Date(year, month);
}

export function getDateWithStartDay(date: Date | null): Date | null {
  const d = moment(date);
  return d.isValid() ? new Date(d.year(), d.month(), 1) : null;
}

export function getDateWithEndDay(date: Date | null): Date | null {
  const d = moment(date);
  return d.isValid() ? new Date(d.year(), d.month(), d.daysInMonth()) : null;
}

export type ValidationErrorsType<T = Record<string, any>> =
  | {[key in keyof Partial<T>]: Array<string> | Array<Record<string, string>> | string}
  | null
  | undefined;

export type DataSetValidationError<T> = {
  fullKey: string;
  index: number | string;
  fieldKey: keyof T;
  message: string;
};

export type DataSetValidationErrors<T> = Record<number, DataSetValidationError<T>[]>;
export type DataSetValidationErrorsMap<T, Y> = Record<keyof T, DataSetValidationErrors<Y>>;

export function getValidationErrorMessage<T = Record<string, any>>(
  key: keyof T | Array<keyof T>,
  validationErrors: ValidationErrorsType<T>,
): null | string | Array<string> {
  if (!validationErrors) {
    return null;
  }
  const keys = Array.isArray(key) ? key : [key];
  const validationMessage = keys
    .filter(inputKey => inputKey in validationErrors)
    .map(inputKey => validationErrors[inputKey]);

  if (validationMessage.length === 0) {
    return null;
  } else if (validationMessage.length === 1) {
    return (validationMessage as Array<string>)[0];
  } else {
    return (validationMessage as Array<string>).flat(2);
  }
}

export function convertFlatValidationErrorToArrayable<T, Y>(
  validationErrors: ValidationErrorsType<T>,
  keys: (keyof T)[],
): Record<keyof T, Record<number, DataSetValidationError<Y>[]>> {
  if (validationErrors == null) {
    return {} as Record<keyof T, Record<number, DataSetValidationError<Y>[]>>;
  }

  const needKeysMap: Record<keyof T, Record<number, DataSetValidationError<Y>[]>> = {} as any;
  keys.forEach(key => {
    Object.entries(validationErrors as Record<any, any>).forEach(([errorFullKey, errValue]) => {
      const [errObjectKey, errArrayIndex, errFieldKey] = errorFullKey.split('.');
      if (errObjectKey.startsWith(key as any)) {
        if (errValue == null) {
          return;
        }
        const res: DataSetValidationError<any> = {
          fullKey: errorFullKey,
          index: errArrayIndex,
          fieldKey: errFieldKey,
          message: errValue?.[0],
        };
        if (errObjectKey in needKeysMap) {
          if (errArrayIndex in needKeysMap[key]) {
            needKeysMap[key][errArrayIndex as any] = [...needKeysMap[key][errArrayIndex as any], res];
          } else {
            needKeysMap[key][errArrayIndex as any] = [res];
          }
        } else {
          needKeysMap[key] = {
            [errArrayIndex as any]: [res],
          };
        }
      }
    });
  });

  return needKeysMap;
}

export function changeArrayPosition<T>(fromIndex: number, toIndex: number, array: Array<T>): Array<T> {
  const copyArrayForImmutable = [...array];
  copyArrayForImmutable.splice(fromIndex, 1);
  copyArrayForImmutable.splice(toIndex, 0, array[fromIndex]);
  return copyArrayForImmutable;
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function splitArrayToChunks<T>(arr: Array<T>, chunkSize: number): T[][] {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function isEqual(value: any, compareWith: any) {
  return LodashIsEqual(value, compareWith);
}

export function isObject(value: any) {
  return LodashIsObject(value);
}

export function isEmpty(value: any) {
  return LodashIsEmpty(value);
}

type MatchCase<T, R = any> = [T, R];

export function match<T>(value: T, cases: Array<MatchCase<T>>, defaultValue: any = null) {
  let res: any;
  cases.forEach(c => {
    const [condition, result] = c;
    if (condition === value) {
      res = result;
    }
  });

  if (res == null && defaultValue != null) {
    return defaultValue;
  }

  return res;
}
