import React, {useMemo} from 'react';
import {AlertCustom} from '../component/alert';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {User} from '../../../api/DTOs/User';
import {InputText} from '../../../components/Inputs/InputText';
import {InputPhone} from '../../../components/Inputs/InputPhone';
import {AppLocale} from '../../../../localization';
import {InputGroupRadio} from '../../../components/Inputs/InputGroupRadio';
import {ContractorType} from '../../../api/contractor-api/Contractor';
import {InputCheckbox} from '../../../components/Inputs/InputCheckbox';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';
import {FormattedMessage} from 'react-intl';
import {useIntl} from '../../../hooks/use-intl';
import {PartialNullable} from '../../../../types/types';

type Props = {
  error?: string | null;
  validationErrors: ValidationErrorsType;
  fields: PartialNullable<RegistrationFormFields>;
  acceptTerms: boolean;
  hideEmailInput?: boolean;
  hideContractorTypeInput?: boolean;

  onAcceptTerms(acceptStatus: boolean): void;

  onChangeField(payload: {
    [key in keyof PartialNullable<RegistrationFormFields>]: PartialNullable<RegistrationFormFields>[key];
  }): void;
};

export type RegistrationFormFields = PartialNullable<
  {
    password: string;
    password_confirmation: string;
    contractor_type: ContractorType;
    contractor_legal_name: string;
  } & User
>;

export const RegistrationForm: React.FC<Props> = ({
  error,
  fields,
  hideEmailInput,
  hideContractorTypeInput,
  acceptTerms,
  onAcceptTerms,
  onChangeField,
  validationErrors,
}) => {
  const intl = useIntl();
  const contractorTypeRadios = useMemo(
    () => [
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.LEGAL}),
        value: ContractorType.LEGAL,
      },
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.INDIVIDUAL}),
        value: ContractorType.INDIVIDUAL,
      },
      {
        label: intl.formatMessage({id: 'CONTRACTOR_TYPE_' + ContractorType.ENTREPRENEUR}),
        value: ContractorType.ENTREPRENEUR,
      },
    ],
    [],
  );

  return (
    <>
      <form className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
        <AlertCustom
          text={error}
          type={BootstrapColors.LIGHT_DANGER}
          iconClassName={'svg-icon-danger'}
          visible={error != null}
        />

        <ValidateErrorWrapper message={getValidationErrorMessage<User>('name_last', validationErrors)}>
          <InputText
            required
            value={fields.name_last}
            label={intl.formatMessage({id: 'LAST_NAME'})}
            hasError={!!getValidationErrorMessage<User>('name_last', validationErrors)}
            onChange={e => onChangeField({name_last: e.currentTarget.value})}
            placeholder={intl.formatMessage({id: 'LAST_NAME'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <div className={'row'}>
          <div className={'col-6'}>
            <ValidateErrorWrapper message={getValidationErrorMessage<User>('name_first', validationErrors)}>
              <InputText
                required
                value={fields.name_first}
                label={intl.formatMessage({id: 'FIRST_NAME'})}
                hasError={!!getValidationErrorMessage<User>('name_first', validationErrors)}
                onChange={e => onChangeField({name_first: e.currentTarget.value})}
                placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>
          </div>
          <div className={'col-6'}>
            <ValidateErrorWrapper message={getValidationErrorMessage<User>('name_middle', validationErrors)}>
              <InputText
                value={fields.name_middle}
                label={intl.formatMessage({id: 'MIDDLE_NAME'})}
                hasError={!!getValidationErrorMessage<User>('name_middle', validationErrors)}
                onChange={e => onChangeField({name_middle: e.currentTarget.value})}
                placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>
          </div>
        </div>

        <ValidateErrorWrapper message={getValidationErrorMessage<User>('phone', validationErrors)}>
          <InputPhone
            required
            country={intl.locale === AppLocale.RU ? 'ru' : 'us'}
            label={intl.formatMessage({id: 'PHONE_NUMBER'})}
            value={fields.phone ?? null}
            onChange={(value, data, event, formattedValue) =>
              onChangeField({
                phone: formattedValue.replace(' ', ''),
                phone_country: data.countryCode,
              })
            }
          />
        </ValidateErrorWrapper>

        {!hideContractorTypeInput && (
          <>
            <ValidateErrorWrapper message={getValidationErrorMessage('type', validationErrors)}>
              <InputGroupRadio
                required
                label={`${intl.formatMessage({id: 'YOU_REGISTERED_AS'})}:`}
                selected={fields?.contractor_type ?? null}
                items={contractorTypeRadios}
                onChange={value => onChangeField({contractor_type: value as ContractorType | null})}
              />
            </ValidateErrorWrapper>

            {fields.contractor_type === ContractorType.LEGAL && (
              <ValidateErrorWrapper message={getValidationErrorMessage('contractor_legal_name', validationErrors)}>
                <InputText
                  required
                  value={fields.contractor_legal_name}
                  label={intl.formatMessage({id: 'LEGAL_NAME'})}
                  hasError={!!getValidationErrorMessage('contractor_legal_name', validationErrors)}
                  onChange={e => onChangeField({contractor_legal_name: e.currentTarget.value})}
                  classNames={`form-control`}
                />
              </ValidateErrorWrapper>
            )}
          </>
        )}

        <div className={'separator separator-solid my-5'} />

        {!hideEmailInput && (
          <ValidateErrorWrapper message={getValidationErrorMessage('email', validationErrors)}>
            <InputText
              required
              name={'email'}
              type={'email'}
              value={fields.email}
              hasError={!!getValidationErrorMessage('email', validationErrors)}
              onChange={e => {
                onChangeField({email: e.currentTarget.value});
              }}
              label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        )}

        <div className={'row'}>
          <div className={'col-6'}>
            <ValidateErrorWrapper message={getValidationErrorMessage('password', validationErrors)}>
              <InputText
                required
                name={'password'}
                type={'password'}
                value={fields.password}
                hasError={!!getValidationErrorMessage('password', validationErrors)}
                onChange={e => onChangeField({password: e.currentTarget.value})}
                label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>
          </div>

          <div className={'col-6'}>
            <ValidateErrorWrapper message={getValidationErrorMessage('password_confirmation', validationErrors)}>
              <InputText
                required
                name={'password_confirmation'}
                type={'password'}
                label={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                value={fields.password_confirmation}
                hasError={!!getValidationErrorMessage('password_confirmation', validationErrors)}
                onChange={e => onChangeField({password_confirmation: e.currentTarget.value})}
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                classNames={`form-control`}
              />
            </ValidateErrorWrapper>
          </div>
        </div>

        <InputCheckbox
          required
          name='acceptTerms'
          value={acceptTerms}
          label={() => (
            <Link to={Routes.getUserAgreementRoute()} target='_blank' className='mr-1' rel='noopener noreferrer'>
              <FormattedMessage id='AUTH.REGISTER.AGREEMENT' />
            </Link>
          )}
          onChange={() => onAcceptTerms(!acceptTerms)}
        />
      </form>
    </>
  );
};
