import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {
  ISelectResponse,
  IYoutubeChannelResponse,
  IYoutubeChannelsResponse,
  IYoutubeChannelStatisticsResponse,
  IYoutubeVideosResponse,
} from './response-contracts/yotutube-channel-contracts';
import {IQueryParams} from './DTOs/IFilterDtos';
import {EntityId} from './base/BaseEntity';
import {BaseItemResponse, BaseItemsResponse} from './base/base-crud-api-response';
import {Feed} from './DTOs/Feed';
import {FeedRevenue} from './DTOs/ChannelRevenue/FeedRevenue';

export class YoutubeChannelsApi extends BaseEndpoint {
  public async getChannelList(params?: IQueryParams): Promise<IYoutubeChannelsResponse> {
    const query = this.applyQueryParams(params);
    const response = await this.get<IYoutubeChannelsResponse>(`${config.BASE_API_URL}/youtube-channels?${query}`);
    return response.data;
  }

  public async getChannelListForSelect(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/youtube-channels/select`);
    return response.data;
  }

  public async getAvailableRevenueFeeds(youtubeChannelId: string): Promise<BaseItemsResponse<Feed>> {
    const response = await this.get(
      `${config.BASE_API_URL}/youtube-channels/${youtubeChannelId}/available-revenue-feeds`,
    );
    return response.data;
  }

  public async getRevenueByMonth(youtubeChannelId: string, feed: Feed): Promise<BaseItemResponse<FeedRevenue>> {
    const response = await this.get(
      `${config.BASE_API_URL}/youtube-channels/${youtubeChannelId}/${feed}/revenue-by-months`,
    );
    return response.data;
  }

  public async setYandexZenChannelId(
    youtubeChannelId: string,
    yandex_zen_id: string,
  ): Promise<IYoutubeChannelResponse> {
    const response = await this.post<any>(
      `${config.BASE_API_URL}/youtube-channels/${youtubeChannelId}/yandex-zen-channel-id`,
      {
        fields: {
          yandex_zen_id,
        },
      },
    );
    return response.data;
  }

  public async linkWithArtist(youtubeChannelId: string, artist_id: string): Promise<IYoutubeChannelResponse> {
    const response = await this.post<any>(
      `${config.BASE_API_URL}/youtube-channels/${youtubeChannelId}/link-with-artist`,
      {
        fields: {
          artist_id,
        },
      },
    );
    return response.data;
  }

  public async getChannelInfo(channelId: EntityId): Promise<IYoutubeChannelResponse> {
    const response = await this.get<IYoutubeChannelResponse>(`${config.BASE_API_URL}/youtube-channels/${channelId}`);
    return response.data;
  }

  public async getChannelVideos(channelId: string, pageNumber?: number): Promise<IYoutubeVideosResponse> {
    let query = '';
    if (pageNumber) query += '&page=' + pageNumber;

    const response = await this.get<IYoutubeVideosResponse>(
      `${config.BASE_API_URL}/youtube-channels/${channelId}/videos?${query}`,
    );
    return response.data;
  }

  public async getSimpleStatistics(
    channelId: string,
    filters?: IQueryParams,
  ): Promise<IYoutubeChannelStatisticsResponse> {
    const query = this.applyQueryParams(filters);
    const res = await this.get<IYoutubeChannelStatisticsResponse>(
      `${config.BASE_API_URL}/youtube-channels/${channelId}/simpleStatistics?${query}`,
    );
    return res.data;
  }
}
