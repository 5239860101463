import React from 'react';
import {Link} from 'react-router-dom';
import {TransactionOperation} from '../../../../api/DTOs/TransactionOperation';
import {Routes} from '../../../../../configs/routes';
import {useIntl} from '../../../../hooks/use-intl';
import {ValueWithLabel} from '../../../base/base-card-components/value-with-label';
import {CustomFormatter} from '../../../../../localization/custom-formatter';
import cn from 'classnames';

type Props = {
  operation: TransactionOperation;
};

export const YoutubeInfo: React.FC<Props> = ({operation}) => {
  const intl = useIntl();
  return (
    <>
      {[operation.y_fin_report_type, operation.y_channel_id, operation.rate_percent].some(s => !!s) && (
        <div className={'font-size-h4 font-weight-boldest my-3'}>
          <span>YouTube</span>
        </div>
      )}
      <div>
        {operation.y_channel_id && (
          <ValueWithLabel
            value={
              <Link to={Routes.getYoutubeSpecifyChannelRoute(operation.y_channel_id)}>{operation.y_channel_title}</Link>
            }
            title={'CHANNEL'}
            className={cn('mb-0')}
          />
        )}

        {operation.rate_percent && (
          <ValueWithLabel
            title={intl.formatMessage({id: 'RATE_PERCENT'})}
            value={CustomFormatter.formatPercent(operation.rate_percent)}
            className={cn('mb-0')}
          />
        )}

        {operation.y_fin_report_type && (
          <ValueWithLabel
            value={intl.formatMessage({id: 'YOUTUBE_REPORT_TYPE_' + operation.y_fin_report_type})}
            title={'REPORT_TYPE'}
            className={cn('mb-0')}
          />
        )}
      </div>
    </>
  );
};
