import React, {PropsWithChildren} from 'react';
import {injectIntl} from 'react-intl';
import {withI18n} from '../localization';
import {ErrorStub} from './components/error-stub';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsWithChildren<any>, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {hasError: true};
  }

  componentDidUpdate(prevProps: Readonly<React.PropsWithChildren<any>>, prevState: Readonly<State>) {
    if (prevState.hasError) {
      this.setState({hasError: false});
    }
  }

  renderErrorView() {
    return <ErrorStub className={'display-4'} error={this.props.intl.formatMessage({id: 'SOMETHING_WENT_WRONG'})} />;
  }

  render() {
    return this.state.hasError ? this.renderErrorView() : this.props.children;
  }
}

// @ts-ignore
export default injectIntl(withI18n(ErrorBoundary));
