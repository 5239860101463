import React from 'react';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

type Props = {
  text?: string;
  className?: string;
};

export const EmptyListState: React.FC<Props> = ({text, className}) => {
  return <div className={cn('text-center', className)}>{text || <FormattedMessage id={'NOT_FOUND'} />}</div>;
};
