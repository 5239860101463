import React from 'react';
import Select, { createFilter } from "react-select";
import './InputSelect.scss';
import {LabelWithDescription} from './LabelWithDescription';
import {SelectOption} from './InputMultiSelect';
import {SelectKeys} from '../../hooks/use-select-api';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';
import {SelectFeedDto} from '../../hooks/apis/use-feeds-api';
import {useIntl} from '../../hooks/use-intl';

export const mapSelectValuesDTOsToSelectOptions = (
  selectName: SelectKeys,
  allSelectsValues: Record<SelectKeys, Array<ISelectValueDto>>,
): SelectOptions => {
  if (allSelectsValues[selectName]) {
    return allSelectsValues[selectName]?.map(selectValueDto => {
      return {
        label: selectValueDto.title,
        value: selectValueDto.id,
        icon: (selectValueDto as SelectFeedDto).icon_file_url,
      };
    });
  }
  return [];
};
export type SelectOptions<T = string | number> = Array<SelectOption<T>>;
export type SelectsValuesDTOs = Record<SelectKeys, SelectOptions>;

interface Props {
  id?: string;
  label?: string;
  descriptionLabel?: string;
  ariaLabel?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: string | number | null;
  options: SelectOptions;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  noOptionMessage?: string;
  components?: {Option: any; MenuList: any};
  filterOptions?: any;
  captureMenuScroll?: boolean;

  onChange(value: string | number | null, id: string | undefined): void;
}

export const InputSelect = ({
  id,
  options,
  isClearable,
  descriptionLabel,
  isLoading,
  placeholder,
  ariaLabel,
  onChange,
  className,
  hasError,
  captureMenuScroll,
  label,
  value,
  required,
  components,
  disabled,
  noOptionMessage,
}: Props) => {
  const onChangeInternal = (e: any) => {
    onChange(e?.value ?? null, id);
  };

  // Намерено не используется строгое сравнение, чтобы можно было адекватно сравнивать "200" и 200.
  const selectedValue = (value && options?.filter(item => item.value == value)) || null;
  const intl = useIntl();
  return (
    <div className={className + ' select-container'}>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        captureMenuScroll={captureMenuScroll}
        classNamePrefix={'react-select'}
        value={selectedValue}
        aria-label={ariaLabel}
        components={components}
        options={options}
        noOptionsMessage={() => noOptionMessage ?? intl.formatMessage({id: 'NOT_FOUND'})}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPosition={'fixed'}
        onChange={onChangeInternal}
        styles={{
          control: styles => ({
            ...styles,
            ...(hasError
              ? {
                  borderColor: '#DC3545 !important',
                  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
                }
              : {}),
          }),
        }}
      />
    </div>
  );
};
