import {FinancialReportSource, FinancialReportType} from '../../../api/financial-report-api/FinancialBulkReports';

/**
 * Список отчётов, которые доступны для загрузки.
 */
export const ReportsInfo = [
  {
    isRequired: true,
    type: FinancialReportType.YOUTUBE_VIDEO_SUMMARY,
    humanDescription: `YOUTUBE_REPORT_TYPE_${FinancialReportType.YOUTUBE_VIDEO_SUMMARY}`,
    fileMask: 'YouTube_Pladform_TV_M_YYYYMMDD_video_summary_v1-1.csv.gz',
    acceptFileExtension: '.gz',
    reportSource: FinancialReportSource.YOUTUBE,
  },
  {
    isRequired: true,
    type: FinancialReportType.YOUTUBE_VIDEO_AGGREGATION,
    humanDescription: `YOUTUBE_REPORT_TYPE_${FinancialReportType.YOUTUBE_VIDEO_AGGREGATION}`,
    fileMask: 'video_report_StarProAggregation_V_v1-3.csv',
    acceptFileExtension: ['.csv'],
    reportSource: FinancialReportSource.YOUTUBE,
  },
  {
    isRequired: true,
    type: FinancialReportType.YOUTUBE_RED_LABEL_RAWDATA_VIDEO,
    humanDescription: `YOUTUBE_REPORT_TYPE_${FinancialReportType.YOUTUBE_RED_LABEL_RAWDATA_VIDEO}`,
    fileMask: 'YouTube_StarProAggregation_M_YYYYMMDD_YYYYMMDD_red_label_rawdata_video_v1-1.csv.gz',
    acceptFileExtension: '.gz',
    reportSource: FinancialReportSource.YOUTUBE,
  },
  {
    isRequired: true,
    type: FinancialReportType.YANDEX_ZEN,
    humanDescription: 'YANDEX_ZEN_REPORT',
    fileMask: '*.xlsx',
    acceptFileExtension: '.xlsx',
    reportSource: FinancialReportSource.YANDEX_ZEN,
  },
];
