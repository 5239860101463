import React, {CSSProperties, lazy} from 'react';
import './rich-text-editor.scss';
import 'react-quill/dist/quill.snow.css';
import {Preloader} from '../preloader';
import {VideoBlot} from './embeded-plugin';
import {Quill} from 'react-quill';

export type HtmlContent = string;

interface Props {
  content: HtmlContent;
  onChange: (content: HtmlContent, delta: any) => void;

  disabled?: boolean;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
}

const modules = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['link', 'image', 'video'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

Quill.register('formats/video', VideoBlot, true);

const ReactQuill = lazy(() => import('react-quill'));
export const RichTextEditor: React.FC<Props> = ({content, style, className, placeholder, disabled, onChange}) => {
  return (
    <React.Suspense fallback={<Preloader />}>
      <ReactQuill
        readOnly={disabled}
        placeholder={placeholder}
        style={style}
        className={className}
        value={content}
        formats={formats}
        modules={modules}
        theme='snow'
        onChange={onChange}
      />
    </React.Suspense>
  );
};
