import React from 'react';
import cn from 'classnames';

interface InputCustomProps {
  message: string | Array<string> | null;
  className?: string;
  children?: any;
  highlights?: boolean;
}

export const ValidateErrorWrapper: React.FC<InputCustomProps> = ({className, highlights, message, children}) => {
  const renderUnderlineMessage = () => {
    if (!message || message.length === 0) return null;

    if (Array.isArray(message) && message.length !== 1) {
      return (
        <ul className='fv-plugins-message-container pl-5'>
          {message.map((m, index) => {
            return (
              <li key={index} className='fv-help-block'>
                {m}
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <div className={cn('fv-plugins-message-container', className)}>
        <div className='fv-help-block'>{Array.isArray(message) ? message[0] : message}</div>
      </div>
    );
  };

  return (
    <div
      className={cn('form-group fv-plugins-icon-container', className, {
        'border rounded border-danger border-1 p-2': message != null && highlights,
      })}>
      {children && children}
      {renderUnderlineMessage()}
    </div>
  );
};
