import React, {useEffect, useMemo, useState} from 'react';
import {User} from '../../../api/DTOs/User';
import {IPaginationInfo} from '../../../api/Paginator';
import {UsersTable} from './users-table';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {BaseException, EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {BadRequestException} from '../../../api/exceptions/BadRequestException';
import {useModalCreateUser} from '../../../components/modals/create-user/modal-create-user-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useModalEditUser} from '../../../components/modals/edit-user/modal-edit-user-context';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useHistory} from 'react-router-dom';
import {CommonRoutes} from '../../../../configs/routes';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {useBaseListPage} from '../../base/base-list-page-context';
import {useAuth} from '../../../hooks/use-auth';
import {useModalCreateEditInviteUserRequest} from '../../../components/modals/invite-user/create-edit-invite-user-request-modal-context';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {UserPrivileges, UserRelations, useUserApi} from '../../../hooks/apis/use-user-api';
import {ManyPrivileges, ManyRelations} from '../../../api/base/base-crud-api-response';
import {MarketingBlock} from '../../marketing-blocks/marketing-block';
import {ILLUSTRATIONS} from '../../../images/images';
import SVG from 'react-inlinesvg';
import {FormattedMessage} from 'react-intl';
import {useSelectApi} from '../../../hooks/use-select-api';

export enum UserAction {
  DELETE = 'DELETE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  EDIT = 'EDIT',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
}

const ICONS = {
  PLUS: require('../../../images/svg/Plus.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const UsersPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {privileges, isAdmin} = useAuth();
  const api = useUserApi();
  const {optionsLoadings, selectsOptions, fetchSelectValuesByKey} = useSelectApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [alertVisible, setAlertVisible] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [users, setUsers] = useState<Array<User>>([]);
  const [usersPrivileges, setPrivileges] = useState<ManyPrivileges<UserPrivileges> | null>(null);
  const [usersRelations, setRelations] = useState<ManyRelations<UserRelations> | null>(null);

  const {showCreateUserModal} = useModalCreateUser();
  const {showEditUserModal} = useModalEditUser();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showCreateEditInviteUserRequestModal} = useModalCreateEditInviteUserRequest();
  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'users_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'email',
          placeholder: intl.formatMessage({id: 'EMAIL'}),
        },
        {
          type: FilterType.TEXT,
          name: 'user_name',
          placeholder: intl.formatMessage({id: 'USER_NAME'}),
        },
        {
          visible: privileges?.can_manage_contractors,
          type: FilterType.SELECT,
          name: 'contractor_id',
          selectKey: 'contractors',
          placeholder: intl.formatMessage({id: 'CONTRACTOR'}),
        },
        {
          type: FilterType.SELECT,
          name: 'mc_pay',
          placeholder: intl.formatMessage({id: 'MC_PAY'}),
          options: [
            {
              value: 'HAS',
              label: intl.formatMessage({id: 'FOR_MC_PAY'}),
            },
            {
              value: 'HAS_NOT',
              label: intl.formatMessage({id: 'NOT_FOR_MC_PAY'}),
            },
          ],
        },
      ],
    });
  }, [optionsLoadings, selectsOptions, privileges]);
  const {appliedQueryParams} = useBaseListPage();

  useEffect(() => {
    if (privileges.can_manage_contractors) {
      fetchSelectValuesByKey('contractors').then();
    }
  }, [privileges]);

  const fetchUsers = async (opt?: IQueryParams) => {
    try {
      setLoading(true);
      setActionError(null);
      setAlertVisible(false);
      const result = await api.getAll(opt);
      setRelations(result.relations);
      setPrivileges(result.privileges);
      setPaginationInfo(result.paginator);
      setUsers(result.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const doAction = async (confirmMessage: string, cb: () => Promise<void>) => {
    if (await showConfirmActionModal(intl.formatMessage({id: confirmMessage}))) {
      await cb();
      await fetchUsers(appliedQueryParams);
      setAlertVisible(true);
    }
  };

  const handleCreateUserClick = async () => {
    const result = await showCreateUserModal();
    if (result.reason === CloseModalReason.OK && result.payload != undefined) {
      history.push(CommonRoutes.getSpecifyUserManagementRoute(result.payload));
    }
  };

  const handleInviteUser = async () => {
    const modalResult = await showCreateEditInviteUserRequestModal({type: 'CREATE'});
    if (modalResult.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'INVITE_WAS_SUCCESS_SENT'}));
    }
  };

  const handleEditUserClick = async (userId: number) => {
    const user = users.find(u => u?.id === userId);
    if (user == undefined) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('User should be exists');
    }
    const result = await showEditUserModal(user);
    if (result.reason === CloseModalReason.OK) {
      await fetchUsers(appliedQueryParams);
      setAlertVisible(true);
    }
  };

  const handleActionSelect = async (userId: number, action: UserAction): Promise<any> => {
    try {
      setActionError(null);
      setAlertVisible(false);
      switch (action) {
        case UserAction.DELETE: {
          await doAction('CONFIRM_DELETE_USER', async () => {
            await api.remove(userId);
          });
          return;
        }
        case UserAction.BLOCK: {
          await doAction('CONFIRM_BLOCK_USER', async () => {
            await api.block(userId);
          });
          return;
        }
        case UserAction.UNBLOCK: {
          await doAction('CONFIRM_UNBLOCK_USER', async () => {
            await api.unblock(userId);
          });
          return;
        }
        case UserAction.RESET_PASSWORD: {
          await doAction('CONFIRM_RESET_PASSWORD_USER', async () => {
            await api.resetPassword(userId);
          });
          break;
        }
        case UserAction.EDIT: {
          await handleEditUserClick(userId);
          break;
        }
        default: {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error();
        }
      }
    } catch (e) {
      if ((e as BaseException).error_type === EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION) {
        setActionError((e as BadRequestException).exception.message);
      } else {
        setActionError(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
      setAlertVisible(true);
    }

    return true;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchUsers}
      renderMarketing={() => (
        <MarketingBlock
          image={ILLUSTRATIONS.INVITE_USER}
          title={intl.formatMessage({id: 'USERS_NOT_FOUND_MARKETING_TITLE'})}
          description={intl.formatMessage({id: 'USERS_NOT_FOUND_MARKETING_DESCRIPTION'})}
          buttons={
            <button onClick={handleInviteUser} className='btn font-weight-boldest text-uppercase btn-light-primary'>
              <span className={`svg-icon svg-icon-light-success svg-icon-2x mr-2`}>
                <SVG src={ICONS.PLUS} />
              </span>
              <FormattedMessage id={'INVITE_USER'} />
            </button>
          }
        />
      )}
      showMarketing={users?.length === 0 && !isAdmin}
      toolbarConfig={[
        {
          visible: privileges.can_create_new_user,
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE_USER'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder btn-light-success',
          onClick: handleCreateUserClick,
        },
        {
          visible: privileges.can_invite_new_user,
          type: 'BUTTON',
          title: intl.formatMessage({id: 'INVITE_USER'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder btn-light-success',
          onClick: handleInviteUser,
        },
      ]}>
      <AlertCustom
        dismissible
        visible={alertVisible}
        type={actionError != null ? BootstrapColors.LIGHT_DANGER : BootstrapColors.LIGHT_SUCCESS}
        iconClassName={actionError != null ? 'svg-icon-danger' : 'svg-icon-success'}
        onClose={() => setAlertVisible(false)}
        text={
          actionError != null
            ? `${intl.formatMessage({id: 'ERROR_COMPLETE_ACTION_WITH_USER'})}: ${actionError}`
            : intl.formatMessage({id: 'SUCCESS_COMPLETE_ACTION_WITH_USER'})
        }
      />
      <UsersTable users={users} privileges={usersPrivileges} relations={usersRelations} onSelect={handleActionSelect} />
    </BaseListPage>
  );
};
