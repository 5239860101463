import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Contract, ContractStatus} from '../../../../api/contract-api/Contract';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';
import {BadgeCustom} from '../../../../components/badge-custom';

interface IContractsTableProps {
  contracts: Array<Contract>;
}

export const ContractsTable = ({contracts}: IContractsTableProps) => {
  const intl = useIntl();
  const data = contracts;

  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder';
    return [
      {
        id: 'Contract_Number',
        Header: intl.formatMessage({id: 'CONTRACT_NUMBER'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Contract) => br,
        Cell: ({value}: {value: Contract}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractRoute(value.id)}>{value.composite_number}</Link>
            </div>
          );
        },
      },
      {
        id: 'Contract_Type',
        Header: intl.formatMessage({id: 'TYPE'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Contract) => br,
        Cell: ({value}: {value: Contract}) => {
          return <div className={defaultClassName}>{intl.formatMessage({id: 'CONTRACT_TYPE_' + value.type})}</div>;
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 300,
        minWidth: 350,
        accessor: (br: Contract) => br,
        Cell: ({value}: {value: Contract}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{value.contractor_name}</Link>
            </div>
          );
        },
      },
      {
        id: 'IS_OUR_COMPANY',
        Header: intl.formatMessage({id: 'IS_OUR_COMPANY'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Contract) => br,
        Cell: ({value}: {value: Contract}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.our_contractor_id)}>
                {value.our_contractor_name}
              </Link>
            </div>
          );
        },
      },
      {
        id: 'Validity period',
        Header: intl.formatMessage({id: 'VALIDITY_PERIOD'}),
        maxWidth: 200,
        minWidth: 200,
        accessor: (br: Contract) => br,
        Cell: ({value}: {value: Contract}) => {
          return (
            <div className={defaultClassName}>
              <span>{intl.formatDate(value.started_at)}</span>&nbsp;-&nbsp;
              {value.expires_at !== null ? <span>{intl.formatDate(value.expires_at)}</span> : <span>N/A</span>}
            </div>
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Contract) => br.status,
        Cell: ({value}: {value: ContractStatus}) => {
          const colorVariant = value === ContractStatus.ACTIVE ? 'success' : 'danger';
          return (
            <div className={defaultClassName}>
              <BadgeCustom variant={colorVariant}>
                <FormattedMessage id={'CONTRACT_STATUS_' + value} />
              </BadgeCustom>
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
