import React from 'react';
import './social-streaming-ranking.scss';
import {Card, CardBody} from '../../../components/card';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import cn from 'classnames';

type Props = {
  title: string;
  rank: number | null;
  diff: number | null;
  diffPostfix?: string;
  diffStyle?: 'decimal' | 'percent' | 'currency' | 'unit' | undefined;
};

export const SocialStreamingRank: React.FC<Props> = ({title, rank, diffPostfix, diffStyle, diff: diffRank}) => {
  return (
    <Card hideFooter hideHeader>
      <CardBody>
        <div className={'font-weight-boldest font-size-lg'}>{title}</div>
        <div className={'d-flex justify-content-between'}>
          <div>
            <div
              className={cn('font-weight-boldest font-size-h3', {
                'text-success': diffRank != null && diffRank > 0,
                'text-danger': diffRank != null && diffRank < 0,
              })}>
              {rank == null ? (
                <FormattedMessage id={'NOT_APPLICABLE'} />
              ) : (
                <>
                  <FormattedNumber value={rank} />{' '}
                  <i
                    className={cn(`fa fa-caret-${diffRank != null && diffRank > 0 ? 'up' : 'down'}`, {
                      'text-success': diffRank != null && diffRank > 0,
                      'text-danger': diffRank != null && diffRank < 0,
                    })}
                  />
                </>
              )}
            </div>
            <div className={'font-weight-bolder font-size-lg'}>
              {diffRank == null ? (
                <FormattedMessage id={'NOT_APPLICABLE'} />
              ) : (
                <>
                  <FormattedNumber
                    value={diffStyle === 'percent' ? diffRank / 100 : diffRank}
                    maximumFractionDigits={2}
                    style={diffStyle}
                    signDisplay={diffRank != 0 ? 'always' : 'never'}
                  />{' '}
                  <span className={'text-lowercase text-muted font-weight-normal'}>{diffPostfix}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
