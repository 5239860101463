import {Feed} from '../api/DTOs/Feed';

export const ICONS = {
  PLUS: require('./svg/Plus.svg'),
  MINUS: require('./svg/Minus.svg'),
  SINGER: require('./svg/Singer.svg'),
  GENRE: require('./svg/Genre.svg'),
  FLAG: require('./svg/Flag.svg'),
  MEDIA: require('./svg/Media.svg'),
  YOUTUBE: require('./svg/Youtube.svg'),
  UPLOADED_FILE: require('./svg/Uploaded-file.svg'),
  USERS: require('./svg/Group.svg'),
  USER: require('./svg/User.svg'),
  DOLLAR: require('./svg/Dollar.svg'),
  WALLET: require('./svg/Wallet3.svg'),
  CONTRACTS: require('./svg/Selected-file.svg'),
  CONTRACTORS: require('./svg/Shield-user.svg'),
  CURSOR: require('./svg/Cursor.svg'),
  CREDIT_CARD: require('./svg/Credit-card.svg'),
  FORWARD: require('./svg/Forward.svg'),
  LAYOUT: require('./svg/Layout.svg'),
  CLIPBOARD: require('./svg/Clipboard.svg'),
  SUPPORT: require('./svg/Headphone.svg'),
  PERCENT: require('./svg/Percent.svg'),
  MAIL: require('./svg/Mail.svg'),
  GLOBE: require('./svg/Globe.svg'),
  EXPLICIT: require('./svg/Explicit.svg'),
  RSS: require('./svg/Rss.svg'),
  SETTINGS: require('./svg/Settings.svg'),
  TERMINAL: require('./svg/Terminal.svg'),

  EDIT: require('./svg/Edit.svg'),
  FILE: require('./svg/File.svg'),
  DOWNLOAD: require('./svg/Download.svg'),
  CHECK: require('./svg/Check.svg'),
  WARNING: require('./svg/Warning.svg'),
  INFO: require('./svg/Info-circle.svg'),
  SPINNER: require('./svg/Spinner.svg'),

  VIEWS: require('./svg/Visible.svg'),
  SUBSCRIBERS: require('./svg/Group.svg'),
  VIDEO_LANE: require('./svg/Video-Lane.svg'),
  CROSS: require('./svg/Close.svg'),

  SEND: require('./svg/Send.svg'),
  DELETE: require('./svg/Close.svg'),
  UP: require('./svg/Up.svg'),

  RESET: require('./svg/Update.svg'),
  BLOCK: require('./svg/Lock.svg'),
  UNBLOCK: require('./svg/Unlock.svg'),

  ARROW: require('./svg/Arrow-right.svg'),
  ANGLE: require('./svg/Angle-right.svg'),
  UNDO: require('./svg/Undo.svg'),

  QUESTION: require('./svg/Question.svg'),

  TIMER: require('./svg/Timer.svg'),
  THUNDER: require('./svg/Thunder.svg'),

  DRAGGABLE: require('./svg/VerticalDots.svg'),
  BIN: require('./svg/Trash.svg'),

  HIDDEN: require('./svg/Hidden.svg'),
  VISIBLE: require('./svg/Visible.svg'),
  COMMENTS: require('./svg/comments.svg'),
  DISLIKE: require('./svg/Dislike.svg'),
  LIKE: require('./svg/Like.svg'),

  SUBSCRIBER: require('./svg/Group.svg'),
  VIDEO: require('./svg/Video-Lane.svg'),
  PLAY: require('./svg/Play.svg'),

  VIDEOS: require('./svg/Video-Lane.svg'),
  MONEY: require('./svg/Money.svg'),
  PICTURE: require('./svg/Picture.svg'),
  SHARE: require('./svg/Share.svg'),
  BOX: require('./svg/Box.svg'),
  DOTS: require('./svg/Dots.svg'),
  MC_PAY: require('./svg/McPay.svg'),

  ['FEED_' + Feed.YOUTUBE]: require('./png/feeds/Youtube.png'),
  ['FEED_' + Feed.BEELINE]: require('./png/feeds/Beeline.png'),
  ['FEED_' + Feed.YANDEX_ZEN]: require('./png/feeds/Yandex-Zen.png'),
  ['FEED_' + Feed.MTS]: require('./png/feeds/MTS.png'),
  ['FEED_' + Feed.RUTUBE]: require('./png/feeds/Rutube.png'),
  ['FEED_' + Feed.VK]: require('./png/feeds/VK.png'),
  ['FEED_' + Feed.OK]: require('./png/feeds/OK.png'),
  ['FEED_' + Feed.MEGAFON]: require('./png/feeds/Megafon.png'),
  ['FEED_' + Feed.TVIGLE]: require('./png/feeds/Tvigle.png'),

  YANDEX_ZEN: require('./svg/yandex_zen.svg'),
};

export const IMAGES = {
  VIDEO_PREVIEW_STUB: require('./png/video_preview_stub.png'),
};

export const ILLUSTRATIONS = {
  CONTRACTS: require('./illustration/Contracts.png'),
  COMPLETE_LIST: require('./illustration/Sketchy-2.png'),
  CREDIT_CARD: require('./illustration/CreditCard.png'),
  MONEY_BOX: require('./illustration/MoneyBox.png'),
  INVITE_USER: require('./illustration/InviteUsers.png'),
  MC_PAY_CARD: require('./illustration/McPayCard.png'),
};

export type IconKey = keyof typeof ICONS;
export type IllustrationKey = keyof typeof ILLUSTRATIONS;
export type ImageKey = IconKey & IllustrationKey;
