import React, {Suspense, useCallback, useEffect} from 'react';
import {Redirect, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import {ModalsProvider} from './components/modals/base-modal/modals-provider';
import ErrorBoundary from './error-boundary';
import {Routes} from '../configs/routes';
import {matchPath} from 'react-router';
import {NotFoundPage} from './pages/not-found-page';
import {ToastContainer} from 'react-toastify';
import {BaseListPageProvider} from './pages/base/base-list-page-context';
import {usePagesConfiguration} from '../configs/pages-configuration-context';
import {PageConfiguration} from '../configs/pages-configuration';
import {ContentRoute} from '../_metronic/layout/components/content/ContentRoute';
import {LayoutSplashScreen} from '../_metronic/layout/_core/MetronicSplashScreen';

// TODO перенести в конфигурацию
// const CreateOrEditMailingPage = lazy(() => import('./pages/admin/mailings/create-or-edit-mailing-page'));

export default function BasePage() {
  const {pagesConfigurationsWithFlattenGroups: pagesConfigurations} = usePagesConfiguration();
  const isRouteMatch = useCallback((actualPath: string, expectedPath: string, exact = false) => {
    return (
      matchPath(actualPath, {
        path: expectedPath,
        exact,
      }) != null
    );
  }, []);

  useEffect(() => {
    //HACK: На форме авторизации задаётся параметр view-порта, который не позволяет зумить инпуты на мобильных устройствах.
    // Тем не менее после прохождения авторизации мы должны предоставить возможность автозума на фокусированные инпуты.
    const viewPortMetaElement: HTMLMetaElement = document.querySelector('meta[name=viewport]') as HTMLMetaElement;
    if (viewPortMetaElement != null) {
      viewPortMetaElement.setAttribute('content', 'width=1280');
    }
  }, []);

  const renderRoutesWithDepends = (configuration: PageConfiguration): JSX.Element[] => {
    const elements: JSX.Element[] = [];

    if (configuration.depends) {
      configuration.depends.forEach(ch => {
        elements.push(...renderRoutesWithDepends(ch));
      });
    }

    if (
      configuration.available &&
      (pagesConfigurations.forceRedirectTo === null ||
        (pagesConfigurations.forceRedirectTo &&
          isRouteMatch(pagesConfigurations.forceRedirectTo, configuration.to, configuration.exact)))
    ) {
      elements.push(
        <ContentRoute
          exact={configuration.exact}
          key={configuration.to}
          path={configuration.to}
          render={configuration?.render}
          component={configuration?.component}
        />,
      );
    }
    return elements;
  };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ModalsProvider>
        <ErrorBoundary>
          <BaseListPageProvider>
            <Switch>
              {pagesConfigurations.pages.map(configuration => {
                return renderRoutesWithDepends(configuration);
              })}
              <Redirect exact from='/' to={pagesConfigurations.redirectRouteFromStartRoute} />
              {pagesConfigurations.forceRedirectTo && <Redirect to={pagesConfigurations.forceRedirectTo} />}
              <ContentRoute path={Routes.getNotFoundRoute()} component={NotFoundPage} />
              <Redirect to={Routes.getNotFoundRoute()} />
            </Switch>
            <ToastContainer />
          </BaseListPageProvider>
        </ErrorBoundary>
      </ModalsProvider>
    </Suspense>
  );
}
