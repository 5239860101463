import {Reducer} from 'redux';
import {User} from '../../../api/DTOs/User';
import {Contractor} from '../../../api/contractor-api/Contractor';
import {Privileges} from '../../../api/response-contracts/auth-response-contracts';

export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const SET_CONTRACTOR = 'SET_CONTRACTOR';
export const SET_PRIVILEGES = 'SET_PRIVILEGES';
export const SET_ALERT_VISIBLE = 'SET_ALERT_VISIBLE';

export type AuthState = {
  user: User | null;
  contractor: Contractor | null;
  privileges: Privileges | null;
  needShowAlertForFillContractorFields: boolean;
};

export interface IBaseAction {
  type: string;
}

export type ILogoutAction = IBaseAction;

export function LogoutAction(): ILogoutAction {
  return {
    type: LOGOUT,
  };
}

export interface ISetUserAction extends IBaseAction {
  user: User | null;
}

export function SetUserAction(user: User | null): ISetUserAction {
  return {
    type: SET_USER,
    user,
  };
}

export interface ISetAlertVisible extends IBaseAction {
  needShowAlertForFillContractorFields: boolean;
}

export function SetFillContractorFieldsAlertVisible(visible: boolean): ISetAlertVisible {
  return {
    type: SET_ALERT_VISIBLE,
    needShowAlertForFillContractorFields: visible,
  };
}

export interface ISetContractorAction extends IBaseAction {
  contractor: Contractor | null;
}

export function SetContractorAction(contractor: Contractor | null): ISetContractorAction {
  return {
    type: SET_CONTRACTOR,
    contractor,
  };
}

export interface ISetPrivileges extends IBaseAction {
  privileges: Privileges | null;
}

export function SetPrivilegesAction(privileges: Privileges | null): ISetPrivileges {
  return {
    type: SET_PRIVILEGES,
    privileges,
  };
}

const initialAuthState: AuthState = {
  user: null,
  contractor: null,
  privileges: null,
  needShowAlertForFillContractorFields: false,
};

export const AuthReducer: Reducer<AuthState, ILogoutAction | ISetUserAction | ISetContractorAction> = (
  state = initialAuthState,
  action: ISetUserAction | ILogoutAction,
) => {
  switch (action.type) {
    case LOGOUT: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    case SET_USER: {
      const {user} = action as ISetUserAction;
      return {...state, user};
    }

    case SET_CONTRACTOR: {
      const {contractor} = action as ISetContractorAction;
      return {...state, contractor};
    }

    case SET_PRIVILEGES: {
      const {privileges} = action as ISetPrivileges;
      return {...state, privileges};
    }

    case SET_ALERT_VISIBLE: {
      const {needShowAlertForFillContractorFields} = action as ISetAlertVisible;
      return {...state, needShowAlertForFillContractorFields};
    }

    default:
      return state;
  }
};
