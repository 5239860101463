import React, {useEffect, useState} from 'react';
import {BackgroundProcess, useBackgroundProcessesApi} from "../../../hooks/apis/use-background-processes-api";
import {useIntl} from "../../../hooks/use-intl";
import {BaseListPage} from "../../base/base-list-page";
import {useLoading} from "../../../hooks/use-loading";
import {Table} from "../../../components/table";
import {TableUtils} from "../../../components/table-utils";


export const BackgroundProcessesPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = useBackgroundProcessesApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [processes, setProcesses] = useState<BackgroundProcess[]>([]);

  useEffect(() => {
    startLoading('page');
    api.getBackgroundProcesses().then(res => {
      setProcesses(res.data.items);
    }).finally(() => stopLoading('page'));
  }, [])

  return (
    <BaseListPage
      pageTitle={intl.formatMessage({id: 'BACKGROUND_PROCESSES'})}
      loading={loadings.page}>
      <Table data={processes ?? []} columns={[
        {
          id: 'Number',
          Header: 'Proccess ID',
          maxWidth: 150,
          forceApplySize: true,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(value.processId)
          }
        },
        {
          id: 'Line',
          Header: 'Process',
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(value.commandLine)
          }
        },
        {
          id: 'User',
          Header: 'User',
          maxWidth: 150,
          forceApplySize: true,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(value.user)
          }
        },
        {
          id: 'Time',
          Header: 'Started At',
          maxWidth: 150,
          forceApplySize: true,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(value.startTime)
          }
        }
      ]}/>
    </BaseListPage>
  );
};
