import React, {useEffect, useRef} from 'react';
import {useLoading} from '../hooks/use-loading';
import cn from 'classnames';
import {Preloader} from './preloader';

type Props = {
  containerHeight: number | string;
  containerWidth?: number | string;
  errorStub?: string;
  imageProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
};
export const ImageWithPreloader: React.FC<Props> = (props: Props) => {
  const imgRef = useRef<HTMLImageElement & any>();
  const [loading, startLoading, stopLoading] = useLoading({
    fetchImage: true,
  });

  useEffect(() => {
    startLoading('fetchImage');
    const image = new Image();
    image.src = props.imageProps.src as string;
    image.onload = () => {
      if (imgRef.current) {
        imgRef.current.src = props.imageProps.src as string;
        stopLoading('fetchImage');
      }
    };
    image.onerror = () => {
      if (props.errorStub) {
        imgRef.current.src = props.errorStub;
      }
      stopLoading('fetchImage');
    };
  }, [props?.imageProps.src]);

  return (
    <div
      className={cn('d-flex align-items-center')}
      style={{minHeight: props.containerHeight, minWidth: props.containerWidth}}>
      {loading.fetchImage && <Preloader />}
      <img
        ref={imgRef}
        alt={'image'}
        {...props.imageProps}
        className={cn(props.imageProps.className, {
          'd-none': loading.fetchImage,
        })}
      />
    </div>
  );
};
