import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {PaymentRequest, PaymentRequestStatus} from '../../../api/DTOs/PaymentRequest';
import {PaymentRequestsTable} from './payment-requests-table';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {useSelectApi} from '../../../hooks/use-select-api';
import {useIntl} from '../../../hooks/use-intl';
import {usePaymentsRequestsApi} from '../../../hooks/apis/use-payments-requests-api';
import {useModalAdminPaymentRequest} from '../../../components/modals/create-admin-payment-request-modal/admin-payment-request-modal-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {ICONS} from '../../../images/images';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const PaymentRequestsPage: React.FC<any> = () => {
  const intl = useIntl();
  const api = usePaymentsRequestsApi();
  const {fetchSelectValuesByKey, selectsOptions, optionsLoadings} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [paymentRequests, setPaymentRequests] = useState<Array<PaymentRequest>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const {showPaymentRequestModal} = useModalAdminPaymentRequest();
  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'payment_requests_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);

  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          options: [PaymentRequestStatus.COMPLETE, PaymentRequestStatus.REJECT, PaymentRequestStatus.IN_WORK].map(
            s => ({
              value: s,
              label: intl.formatMessage({id: 'PAYMENT_REQUEST_STATUS_' + s}),
            }),
          ),
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);

  useEffect(() => {
    Promise.all([fetchSelectValuesByKey('users')]).then();
  }, []);

  const handleCreatePaymentRequest = useCallback(async () => {
    const modalResult = await showPaymentRequestModal();
    if (modalResult.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_PAYMENT_REQUEST'}));
      fetchPaymentRequests().then();
    }
  }, []);

  const fetchPaymentRequests = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getAllAvailablePaymentRequests(filters);
      setPaymentRequests(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchPaymentRequests}
      toolbarConfig={[
        {
          title: intl.formatMessage({id: 'CREATE'}),
          type: 'BUTTON',
          onClick: handleCreatePaymentRequest,
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
        },
      ]}>
      <PaymentRequestsTable paymentRequests={paymentRequests} />
    </BaseListPage>
  );
};
