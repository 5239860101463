import React from 'react';
import AsideMenuItem from './aside-menu-item/aside-menu-item';
import {useIntl} from 'react-intl';
import {usePagesConfiguration} from '../../../configs/pages-configuration-context';
import {
  BaseAsidePageConfiguration,
  GroupPagesConfiguration,
  PageConfiguration,
} from '../../../configs/pages-configuration';
import AsideMenuGroupItem from './aside-menu-item/aside-menu-group-items';

export function AsideMenuList(props: any) {
  const intl = useIntl();
  const {pagesConfigurations} = usePagesConfiguration();

  const getAllDependsElements = (conf: PageConfiguration): Array<PageConfiguration> => {
    const items: PageConfiguration[] = [conf];
    conf.depends?.forEach(c => {
      items.push(...getAllDependsElements(c));
    });

    return items;
  };

  const renderMenuItemsWithDepends = (configuration: PageConfiguration | GroupPagesConfiguration): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    if (!configuration.available) {
      return elements;
    }

    if ((configuration as GroupPagesConfiguration).pages) {
      const subElements = [] as Array<PageConfiguration>;
      (configuration as GroupPagesConfiguration).pages.forEach(c => {
        subElements.push(...getAllDependsElements(c));
      });

      elements.push(
        <AsideMenuGroupItem
          key={Math.random()}
          title={(configuration as GroupPagesConfiguration).title}
          icon={(configuration as GroupPagesConfiguration).icon}
          disabled={!configuration.available}
          items={
            subElements.filter(
              s => (s.available == undefined || s.available) && s.showInAside,
            ) as Array<BaseAsidePageConfiguration>
          }
        />,
      );
    } else {
      configuration = configuration as PageConfiguration;
      if (configuration.depends) {
        configuration.depends.forEach(ch => {
          elements.push(...renderMenuItemsWithDepends(ch));
        });
      }

      if (!configuration.showInAside) {
        return [<React.Fragment key={Math.random()} />];
      }

      elements.push(
        <AsideMenuItem
          key={Math.random()}
          disabled={!!pagesConfigurations.forceRedirectTo}
          to={configuration.to}
          title={intl.formatMessage({id: configuration.title})}
          icon={configuration.icon}
        />,
      );
    }

    return elements;
  };

  return (
    <>
      <ul className={`menu-nav ${props.layoutProps.ulClasses}`}>
        {pagesConfigurations.pages.map((configuration, index) => {
          return <React.Fragment key={index}>{renderMenuItemsWithDepends(configuration)}</React.Fragment>;
        })}
      </ul>
    </>
  );
}
