import React, {Ref, useCallback} from 'react';
import './InputText.scss';
import cn from 'classnames';
import {LabelWithDescription} from './LabelWithDescription';
import {Toolbar, ToolBarItem} from '../card-toolbar/Toolbar';
import InputMask from 'react-input-mask';

interface InputCustomProps {
  id?: string;
  disableHtmlValidation?: boolean;
  value?: string | number | null;
  defaultValue?: string | number | null;
  name?: string;
  type?: string;
  label?: string;
  descriptionLabel?: string;
  placeholder?: string;
  classNames?: string;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  innerRef?: Ref<any>;
  maxLength?: number;
  maxNumber?: number;
  minNumber?: number;
  pattern?: string;
  step?: number;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  toolbarConfig?: Array<ToolBarItem>;
  mask?: string | Array<string | RegExp>;

  onPaste?(event: React.ClipboardEvent): void;

  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;

  onChange(event: React.SyntheticEvent<any>): void;
}

export const InputText = ({
  id,
  classNames,
  name,
  onChange,
  placeholder,
  type,
  label,
  descriptionLabel,
  value,
  maxLength,
  maxNumber,
  minNumber,
  defaultValue,
  required,
  hasError,
  disabled,
  innerRef,
  pattern,
  step,
  toolbarConfig,
  mask,
  disableHtmlValidation = false,
  inputMode,
  onKeyDown,
  onPaste,
}: InputCustomProps) => {
  const props = defaultValue != undefined || defaultValue != null ? {defaultValue: defaultValue} : {value: value ?? ''};
  const handleChange = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      if (!disableHtmlValidation && !event.currentTarget.validity.valid && type !== 'email') {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      onChange(event);
    },
    [mask, disableHtmlValidation],
  );

  return (
    <>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <div className={'d-flex flex-row'}>
        <InputMask
          mask={mask ?? ''}
          ref={innerRef}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          type={type}
          className={cn('input-text', classNames, {
            'is-invalid': hasError,
          })}
          name={name}
          inputMode={inputMode}
          maxLength={maxLength}
          max={maxNumber}
          min={minNumber}
          step={step}
          pattern={pattern}
          onChange={handleChange}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          {...props}
        />
        {toolbarConfig && <Toolbar className={'d-flex align-items-center'} items={toolbarConfig} />}
      </div>
    </>
  );
};
