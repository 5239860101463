import React, {lazy} from 'react';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {IMailingDto, MailingContentType} from '../../../api/mailing-api/IMailingDto';
import {useIntl} from 'react-intl';
import {InputTextArea} from '../../../components/Inputs/InputTextArea';
import remarkGfm from 'remark-gfm';
import {FilesApi} from '../../../api/file-api/files-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {getValidationErrorMessage} from '../../../utils/utils';
import {Preloader} from '../../../components/preloader';

interface IProps {
  language: 'ru' | 'en';
  contentType: MailingContentType;
  subjectValue?: string | null;
  editorValue?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  onChange(key: keyof IMailingDto, value: any): void;
}

const ReactMarkdown = lazy(() => import('react-markdown'));
const MdEditor = lazy(() => import('react-markdown-editor-lite'));
export const EditMailingForm: React.FC<IProps> = ({
  language,
  contentType,
  editorValue,
  validationErrors,
  subjectValue,
  onChange,
}) => {
  const intl = useIntl();
  const fileApi = new FilesApi();
  const handleChange = (e: any) => {
    onChange(e.currentTarget.id, e.currentTarget.value);
  };

  const handleMarkdownEditorChange = (data: {text: string; html: string}) => {
    onChange(`markdown_${language}`, data.text);
  };

  const handleHtmlEditorChange = (text: string) => {
    onChange(`html_${language}`, text);
  };

  const handleImageUpload = (file: File) => {
    return new Promise(resolve => {
      fileApi
        .uploadPublicFile(file)
        .then(result => {
          resolve(result.data.item.public_url);
        })
        .catch(e => {
          const err = e as ApiRequestException;
          toast.error(err?.errorMessage || e?.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
          resolve(null);
        });
    });
  };

  const renderMdPreview = (md: string) => {
    return (
      <React.Suspense fallback={<Preloader />}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>
      </React.Suspense>
    );
  };

  const renderEditor = () => {
    if (contentType === MailingContentType.MARKDOWN) {
      const errorStyle = validationErrors && validationErrors[`markdown_${language}`] ? '1px solid red' : '';
      return (
        <ValidateErrorWrapper message={getValidationErrorMessage(`markdown_${language}`, validationErrors)}>
          <React.Suspense fallback={<Preloader />}>
            <MdEditor
              value={editorValue || ''}
              renderHTML={renderMdPreview}
              onChange={handleMarkdownEditorChange}
              onImageUpload={handleImageUpload}
              style={{height: '500px', width: '100%', border: errorStyle}}
              config={{
                view: {
                  menu: true,
                  md: true,
                  html: false,
                  fullScreen: true,
                  hideMenu: true,
                },
                syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
              }}
            />
          </React.Suspense>
        </ValidateErrorWrapper>
      );
    } else {
      return (
        <ValidateErrorWrapper message={getValidationErrorMessage(`html_${language}`, validationErrors)}>
          <InputTextArea
            style={{height: '500px'}}
            value={editorValue}
            hasError={!!getValidationErrorMessage(`html_${language}`, validationErrors)}
            className={'form-control'}
            onChange={e => handleHtmlEditorChange(e.currentTarget.value)}
          />
        </ValidateErrorWrapper>
      );
    }
  };

  return (
    <>
      <form className={'form'}>
        <div className='form-group'>
          <ValidateErrorWrapper message={getValidationErrorMessage(`subject_${language}`, validationErrors)}>
            <InputText
              id={'subject_' + language}
              required
              hasError={!!getValidationErrorMessage(`subject_${language}`, validationErrors)}
              defaultValue={subjectValue}
              label={`${intl.formatMessage({id: 'SUBJECT'})} (${language})`}
              classNames={'form-control'}
              onChange={handleChange}
            />
          </ValidateErrorWrapper>
        </div>
      </form>
      <div className={'my-5'}>{renderEditor()}</div>
    </>
  );
};
