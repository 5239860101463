import React from 'react';
import {StepProps} from '../../steps';
import {ContractorDataValidationStatus} from '../../../../../api/contractor-api/Contractor';
import {StepWaitingModerate} from './step-waiting-moderate';
import {useAuth} from '../../../../../hooks/use-auth';
import {StepRejectedRegistration} from './step-rejected-registration';

export const StepSecond: React.FC<StepProps<ContractorDataValidationStatus>> = ({title, match}) => {
  const {contractor} = useAuth();
  return (
    <>
      {contractor?.data_validation_status === ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM && (
        <StepWaitingModerate title={title} match={match} />
      )}
      {contractor?.data_validation_status === ContractorDataValidationStatus.REJECTED && (
        <StepRejectedRegistration title={title} match={match} />
      )}
    </>
  );
};
