import React, {useEffect, useState} from 'react';
import {ActiveSidebarSectionInfo, Sidebar} from './sidebar/sidebar';
import {IChapterDto} from '../../../api/knowledge-base-api/IChapterDto';
import {IArticleSimplified} from '../../../api/knowledge-base-api/IArticleDto';
import {KnowledgeBaseApi} from '../../../api/knowledge-base-api/knowledge-base-api';
import {CreateArticleSection} from './create-article-section';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from '../../../hooks/use-intl';
import {Switch} from 'react-router-dom';
import {ArticleSection} from './article-section';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {useLoading} from '../../../hooks/use-loading';
import {AdminRoute} from '../../../admin-route';
import {EditArticleSection} from './edit-article-section';
import {KnowledgeBaseStubSection} from './knowledge-base-stub-section';
import {useAuth} from '../../../hooks/use-auth';
import {ContentRoute} from '../../../../_metronic/layout/components/content/ContentRoute';

export const KnowledgeBasePage: React.FC<any> = () => {
  const intl = useIntl();
  const {isAdmin} = useAuth();
  const api = new KnowledgeBaseApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    sidebar: true,
  });

  const [activeSidebarSectionInfo, setActiveSidebarSectionInfo] = useState<ActiveSidebarSectionInfo | null>(null);
  const [chapters, setChapters] = useState<Array<IChapterDto>>([]);
  const [simplifiedArticles, setSimplifiedArticles] = useState<Array<IArticleSimplified>>([]);

  useEffect(() => {
    fetchSidebarInfo().then();
  }, []);

  const fetchChapters = async () => {
    try {
      const result = await api.getChapters();
      setChapters(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const fetchArticles = async () => {
    try {
      const result = await api.getSimplifiedArticles();
      setSimplifiedArticles(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const fetchSidebarInfo = async () => {
    startLoading('sidebar');
    await fetchChapters();
    await fetchArticles();
    stopLoading('sidebar');
  };

  const renderPageContent = () => {
    return (
      <div className={'flex-lg-row-fluid mb-20 mb-xl-0'}>
        <Switch>
          <AdminRoute
            path={AdminRoutes.getCreateArticleRoute(':id')}
            render={() => (
              <CreateArticleSection
                fetchSidebarInfo={fetchSidebarInfo}
                setActiveSidebarSection={setActiveSidebarSectionInfo}
              />
            )}
          />
          <AdminRoute
            path={AdminRoutes.getEditArticleRoute(':id')}
            render={() => (
              <EditArticleSection
                chapters={chapters}
                fetchSidebarInfo={fetchSidebarInfo}
                setActiveSidebarSection={setActiveSidebarSectionInfo}
              />
            )}
          />
          <ContentRoute
            path={Routes.getSpecifyArticleRoute(':id')}
            render={() => (
              <ArticleSection
                fetchSidebarInfo={fetchSidebarInfo}
                setActiveSidebarSection={setActiveSidebarSectionInfo}
              />
            )}
          />
          <ContentRoute path={Routes.getKnowledgeBaseRoute()} component={KnowledgeBaseStubSection} />
        </Switch>
      </div>
    );
  };

  const renderSidebar = () => {
    if (!loadings.sidebar && chapters.length === 0 && !isAdmin) {
      return null;
    }

    return (
      <div className={'flex-column flex-lg-row-auto w-100 min-h-250px max-w-xl-300px mr-5'}>
        <Sidebar
          loading={loadings.sidebar}
          chapters={chapters}
          articles={simplifiedArticles}
          setActiveSidebarSectionInfo={setActiveSidebarSectionInfo}
          activeSidebarSectionInfo={activeSidebarSectionInfo}
          fetchSidebarInfo={fetchSidebarInfo}
        />
      </div>
    );
  };

  return (
    <div className={'d-flex flex-column flex-md-column-reverse flex-xl-row'}>
      {renderSidebar()}
      {renderPageContent()}
    </div>
  );
};
