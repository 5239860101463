import React, {useEffect, useMemo, useState} from 'react';
import {FilePrivileges, FileRelations, MemoryUsedInfo, useFilesApi} from '../../hooks/apis/use-files-api';
import {CreatedFile} from '../../api/file-api/files-api';
import {toast} from 'react-toastify';
import {useIntl} from '../../hooks/use-intl';
import {useLoading} from '../../hooks/use-loading';
import {BaseListPage} from '../base/base-list-page';
import {CreatedFilesTable} from './file-table';
import {CreatedFileAction, useCreatedFileActionHandler} from './use-file-handler-actions';
import {ManyPrivileges, ManyRelations} from '../../api/base/base-crud-api-response';
import {IPaginationInfo} from '../../api/Paginator';
import {useSelectApi} from '../../hooks/use-select-api';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {IQueryParams, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {WidgetMultiData} from '../../components/dashboard-widgets/widget-multi-data';
import {formatBytes} from '../../utils/utils';

export const FilesPage: React.FC = () => {
  const intl = useIntl();
  const api = useFilesApi();
  const {optionsLoadings, selectsOptions, fetchSelectValuesByKey} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    files: true,
    memoryUsedInfo: true,
  });
  const [memoryUsedInfo, setMemoryUsedInfo] = useState<MemoryUsedInfo | null>(null);
  const [files, setFiles] = useState<Array<CreatedFile>>([]);
  const [relations, setRelations] = useState<ManyRelations<FileRelations> | null>(null);
  const [privileges, setPrivileges] = useState<ManyPrivileges<FilePrivileges> | null>(null);
  const [paginationOptions, setPaginations] = useState<IPaginationInfo | null>(null);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      allSelectValues: selectsOptions,
      loadingSelect: optionsLoadings,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'original_file_name',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USER'}),
        },
        {
          type: FilterType.SELECT,
          name: 'relation_field',
          options: [
            {value: 'video_id', label: intl.formatMessage({id: 'VIDEOS'})},
            {value: 'artist_id', label: intl.formatMessage({id: 'ARTISTS'})},
            {value: 'feed_id', label: intl.formatMessage({id: 'FEEDS'})},
          ],
          placeholder: intl.formatMessage({id: 'RELATION'}),
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);
  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'files-sort',
      defaultOptions: {
        field: SortField.CREATED_AT,
        direction: SortDirection.DESC,
      },
      options: [
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: A-z`,
          value: {field: 'original_file_name', direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: Z-a`,
          value: {field: 'original_file_name', direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'SIZE'})}: 10...1`,
          value: {field: 'size', direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'SIZE'})}: 1...10`,
          value: {field: 'size', direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_FIRST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_LAST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);

  useEffect(() => {
    Promise.all([fetchSelectValuesByKey('users'), fetchMemoryUsedInfo()]).then();
  }, []);

  const fetchMemoryUsedInfo = async () => {
    try {
      startLoading('memoryUsedInfo');
      const result = await api.memoryUsedInfo();
      setMemoryUsedInfo(result.data.item);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('memoryUsedInfo');
    }
  };

  const fetchFiles = async (params?: IQueryParams) => {
    try {
      startLoading('files');
      const result = await api.getAll(params);
      setRelations(result.relations);
      setPrivileges(result.privileges);
      setFiles(result.items);
      setPaginations(result.paginator);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('files');
    }
  };
  const {handleCreatedFileActions} = useCreatedFileActionHandler(fetchFiles);
  const handleActions = async (item: CreatedFile, action: CreatedFileAction) => {
    await handleCreatedFileActions({type: action, entityId: item.id, entity: item});
  };

  return (
    <>
      <WidgetMultiData
        noStretch
        loading={loadings.memoryUsedInfo}
        title={intl.formatMessage({id: 'STORAGE_CAPACITY'})}
        data={[
          {
            title: intl.formatMessage({id: 'FILES_SIZES'}),
            value: memoryUsedInfo?.memoryUsedInBytes ? formatBytes(memoryUsedInfo.memoryUsedInBytes) : 'N/A',
          },
          {
            title: intl.formatMessage({id: 'FILES_COUNT'}),
            value: memoryUsedInfo?.count || 'N/A',
          },
        ]}
      />
      <BaseListPage
        loading={loadings.files}
        fetchData={fetchFiles}
        filtersConfig={filterConfig}
        sortsConfig={sortConfig}
        pagination={{info: paginationOptions}}>
        <CreatedFilesTable items={files} privileges={privileges} relations={relations} onAction={handleActions} />
      </BaseListPage>
    </>
  );
};
