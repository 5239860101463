import React, {useMemo} from 'react';
import {Column, Row} from 'react-table';
import {DefaultTable} from './default-table';

// @ts-ignore
export type TableColumn<T> = Omit<Column<T>, 'Cell'> & {
  forceApplySize?: boolean;
  Cell: (value: {value: T}) => any;
  visible?: boolean;
};
// @ts-ignore
type TypedRow<T> = Row<T>;

export type Props<T> = {
  data: Array<T>;
  columns: Array<TableColumn<T>>;
  renderRowSubComponent?: (row: TypedRow<T>) => JSX.Element;
  emptyText?: string;
};

export function Table<T>({data, columns, emptyText, renderRowSubComponent}: Props<T>) {
  const memoizedColumns = useMemo(() => {
    const availableColumns = columns.filter(c => c.visible == null || c.visible);
    availableColumns.forEach(c => {
      // @ts-ignore
      c.accessor = (cellData: T) => cellData;
    });
    return availableColumns;
  }, []);
  return (
    <DefaultTable
      data={data}
      columns={memoizedColumns as any}
      emptyText={emptyText}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
}
