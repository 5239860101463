import {Currency} from './Transaction';

export interface PaymentRequest {
  id: number;
  status: PaymentRequestStatus;
  reject_reason: string | null;
  user_id: number;
  user_name: string;
  from_account_id: number;

  value: number;
  currency: Currency;
  destination: PaymentRequestDestination;

  created_at: string;
  updated_at: string;
}

export enum PaymentRequestDestination {
  TO_MCPAY = 'TO_MCPAY',
  BY_REQUISITES = 'BY_REQUISITES',
}

export enum PaymentRequestStatus {
  IN_WORK = 'IN_WORK',
  COMPLETE = 'COMPLETE',
  REJECT = 'REJECTED',
}
