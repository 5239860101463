import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {ErrorStub} from '../../../components/error-stub';
import {DashboardApi} from '../../../api/dashboard-api/dashboard-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {Preloader} from '../../../components/preloader';
import {WidgetSecond} from '../../../components/dashboard-widgets/widget-second';
import {IDashboardAdminDataDto} from '../../../api/dashboard-api/IDashboardAdminDataDto';
import {WidgetMultiData} from '../../../components/dashboard-widgets/widget-multi-data';
import {TransactionStatus} from '../../../api/DTOs/Transaction';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {CustomFormatter} from '../../../../localization/custom-formatter';

const ICONS = {
  VIEWS: require('../../../images/svg/Visible.svg'),
  VIDEOS: require('../../../images/svg/Video-Lane.svg'),
  YOUTUBE: require('../../../images/png/youtube_social_icon_red.png'),
  DOLLAR: require('../../../images/svg/Dollar.svg'),
  MONEY: require('../../../images/svg/Money.svg'),
  CHECK: require('../../../images/svg/Check.svg'),
  CROSS: require('../../../images/svg/Close.svg'),
  FORWARD: require('../../../images/svg/Forward.svg'),
  USERS: require('../../../images/svg/Group.svg'),
};

const BACKGROUNDS = {
  HEADER: require('../../../images/backgrounds/bg-10.jpg'),
  USER_MANAGEMENT: require('../../../images/svg/patterns/rhone.svg'),
  TRANSACTIONS_MANAGEMENT: require('../../../images/svg/patterns/taieri.svg'),
  PAYMENTS_MANAGEMENT: require('../../../images/svg/patterns/rhone-2.svg'),
};

export const DashboardAdminPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = new DashboardApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dashboardData, setDashboardData] = useState<IDashboardAdminDataDto | null>(null);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchDashboardsData();
  }, []);

  const fetchDashboardsData = async () => {
    try {
      setLoading(true);
      const result = await api.getAdminDashboard();
      setDashboardData(result.data);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickOnWidget = (redirectTo: string) => {
    history.push(redirectTo);
  };

  if (loading) {
    return <Preloader />;
  }

  if (dashboardData === null || error !== null) {
    return <ErrorStub error={error || intl.formatMessage({id: 'NOT_FOUND'})} />;
  }

  const renderInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-6 col-md-12'}>
          <WidgetSecond
            icon={ICONS.CROSS}
            title={intl.formatMessage({id: 'DASHBOARD_ERRORS_COUNT'})}
            value={dashboardData.errorsCountToday}
            style={'danger'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUsersActionsRoute())}
          />
        </div>
        <div className={'col-xl-6 col-md-12'}>
          <WidgetSecond
            icon={ICONS.DOLLAR}
            title={intl.formatMessage({id: 'DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT'})}
            value={dashboardData.transactionWaitingConfirmCount}
            style={'success'}
            onClick={() =>
              handleClickOnWidget(AdminRoutes.getFinancialOperationsRoute({status: TransactionStatus.WAITING_CONFIRM}))
            }
          />
        </div>
      </div>
    );
  };

  const renderHelloBlock = () => {
    return (
      <div
        className={'card card-custom gutter-b bgi-no-repeat bgi-size-cover'}
        style={{backgroundImage: `url('${BACKGROUNDS.HEADER}')`, height: '200px'}}>
        <div className={'card-body'}>
          <h1 className={'text-uppercase font-weight-boldest text-white'}>{intl.formatMessage({id: 'DASHBOARD'})}</h1>
        </div>
      </div>
    );
  };

  const renderUsersInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_USERS_COUNT'})}
            value={dashboardData.usersCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'})}
            value={dashboardData.usersRegisteredTodayCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_USERS_CURRENT_MONTH_COUNT'})}
            value={dashboardData.usersRegisteredCurrentMonthCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
      </div>
    );
  };

  const renderChannelsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
            title={intl.formatMessage({id: 'DASHBOARD_CHANNELS_COUNT'})}
            value={dashboardData.youtubeChannelsCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
            title={intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'})}
            value={dashboardData.youtubeChannelsCreatedTodayCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
            title={intl.formatMessage({id: 'DASHBOARD_WITHOUT_ACTIVE_CONTRACT'})}
            value={dashboardData.youtubeChannelsWithoutActiveContractCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute({active_contract: 'HAS_NO_CONTRACT', status: 'ALL'}))}
          />
        </div>
      </div>
    );
  };

  const renderVideosInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.VIDEOS}
            title={intl.formatMessage({id: 'DASHBOARD_VIDEOS_COUNT'})}
            value={dashboardData.videosCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getVideosRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.VIDEOS}
            title={intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'})}
            value={dashboardData.videosCreatedTodayCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getVideosRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.VIDEOS}
            title={intl.formatMessage({id: 'DASHBOARD_WITHOUT_ACTIVE_CONTRACT'})}
            value={dashboardData.videosWithoutActiveContractCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(Routes.getVideosRoute({active_contract: 'HAS_NO_CONTRACT'}))}
          />
        </div>
      </div>
    );
  };

  const renderRequestsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-12'}>
          <WidgetMultiData
            title={intl.formatMessage({id: 'PAYMENT_REQUESTS'})}
            data={[
              {
                title: intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'}),
                value: dashboardData.paymentRequestsTodayCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_IN_WORK_COUNT'}),
                value: dashboardData.paymentRequestsInWorkCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM'}),
                value: CustomFormatter.formatMoney(dashboardData.paymentRequestsInWorkSum),
              },
            ]}
            onClick={() => handleClickOnWidget(AdminRoutes.getPaymentRequestsRoute())}
          />
        </div>
      </div>
    );
  };

  const renderAdvertisementRequestsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-12'}>
          <WidgetMultiData
            title={intl.formatMessage({id: 'ADVERTISEMENT_REQUESTS'})}
            data={[
              {
                title: intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'}),
                value: dashboardData.advertisementRequestsTodayCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_IN_WORK_COUNT'}),
                value: dashboardData.advertisementRequestsInWorkCount,
              },
            ]}
            onClick={() => handleClickOnWidget(AdminRoutes.getPaymentRequestsRoute())}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHelloBlock()}
      <div className={'mt-n35 px-5'}>
        {renderInfo()}
        {renderChannelsInfo()}
        {renderVideosInfo()}
        {renderUsersInfo()}
        {renderRequestsInfo()}
        {renderAdvertisementRequestsInfo()}
      </div>
    </>
  );
};
