import {InputSelect, SelectOptions} from '../Inputs/InputSelect';
import {QueryParamConfig, UrlUpdateType} from 'use-query-params';
import React from 'react';
import cn from 'classnames';
import {InputText} from '../Inputs/InputText';
import {useDebouncedCallback} from 'use-debounce';
import {InputDatepickerRange} from '../Inputs/InputDatepickerRange';
import {tryGetDateOrNull} from '../../utils/utils';
import {DateFormat, InputDatepicker} from '../Inputs/InputDatepicker';
import {SelectKeys} from '../../hooks/use-select-api';

export enum FilterType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  DATE = 'DATE',
  PERIOD = 'PERIOD',
  SLIDER = 'SLIDER',
  COMPOSITE = 'COMPOSITE',
  VIRTUAL = 'VIRTUAL',
}

export type ManuallyQueryConfig<T, Y = T> = {
  name: string;
  type: QueryParamConfig<T, Y>;
  clearableDefaultValue?: boolean;
};

export type BaseFilter = {
  name: string;
  type: FilterType;
  loading?: boolean;
  className?: string;
  placeholder?: string;
  queryConfig?: ManuallyQueryConfig<any>;
  /**
   * Извлечь поля сложного значения.
   * @example
   * extractFields = false;
   * {sort: {transaction_sort: {field:'some', direction:'some'}}}
   *
   * extractFields = true;
   * {sort: {field:'some', direction:'some'}}
   */
  extractFields?: boolean;
};

/**
 *  Номер страницы -- тоже фильтр, однако он виртуальный, ничего рендерить в панели для него не требуется.
 */
export type VirtualFilter = {
  type: FilterType.VIRTUAL;
  name: string;
  queryConfig?: ManuallyQueryConfig<any>;
  extractFields?: boolean;
};

export type TextFilter = {
  type: FilterType.TEXT;
} & BaseFilter;

export type SelectFilter<T = any> = {
  type: FilterType.SELECT;
  selectKey?: SelectKeys;
  loading?: boolean;
  options: SelectOptions<T>;
  clearable?: boolean;
  queryConfig?: ManuallyQueryConfig<T>;
  stringToValue?: (selectedValue: string | number | null) => T | null;
  valueToString?: (value: T | null) => string | null;
} & Omit<BaseFilter, 'queryConfig'>;

export type DateFilter = {
  type: FilterType.DATE;
  format?: DateFormat;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  clearable?: boolean;
} & BaseFilter;

type Period = {startDate: Date | null; endDate: Date | null};
export type DatePeriodFilter = {
  type: FilterType.PERIOD;
  toValue?: (datePeriod: [Date | null, Date | null]) => Period | null;
  fromValue?: (value: Period | null) => string | null;
} & BaseFilter;

export type CompositeFilter = {
  type: FilterType.COMPOSITE;
  items: Array<Filter & {required?: boolean}>;
  queryConfig: ManuallyQueryConfig<{[key: string]: any} | null | undefined>;
} & BaseFilter;

export type Filter = TextFilter | SelectFilter | DateFilter | DatePeriodFilter | CompositeFilter | VirtualFilter;

type Props = {
  items: Array<Filter>;
  filterNameValueMap: Record<string, any>;
  onChange: (filterName: string, filterValue: string | Record<string, any>, urlUpdateType?: UrlUpdateType) => void;
};

const DEFAULT_TEXT_FILTER_VALUE = '';
const DEFAULT_DATE_FILTER_VALUE = null;
export const COMPOSITE_FILTER_SEPARATOR = '|';
export const Filters: React.FC<Props> = ({items, filterNameValueMap, onChange}) => {
  const handleUpdateFilter = (filterValue: any, filterName: string) => {
    const filterNameParts = filterName.split(COMPOSITE_FILTER_SEPARATOR);
    if (filterNameParts.length > 1) {
      const [parentFilterName, subFilterName] = filterNameParts;
      onChange(parentFilterName, {...filterNameValueMap[parentFilterName], [subFilterName]: filterValue});
      return;
    }
    onChange(filterName, filterValue);
  };

  const handleChangeDebouncedText = useDebouncedCallback((id: string, value: string) => {
    handleUpdateFilter(value, id);
  }, 400);

  const handleChangeDateRange = (filterName: string, datePeriod: [Date | null, Date | null]) => {
    if (datePeriod[0] === null && datePeriod[1] === null) {
      handleUpdateFilter(null, filterName);
      return;
    }
    handleUpdateFilter({startDate: datePeriod[0] ?? null, endDate: datePeriod[1] ?? null}, filterName);
  };

  const renderSelect = (filter: SelectFilter, nameValueMap: Record<string, any>) => {
    return (
      <InputSelect
        id={filter.name}
        key={filter.name}
        isClearable={filter.clearable}
        isLoading={filter.loading}
        className={cn(filter.className, 'filter')}
        placeholder={filter.placeholder}
        // @ts-ignore
        options={
          filter.valueToString == null
            ? filter.options
            : filter.options.map(opt => ({
                label: opt.label,
                value: filter.valueToString?.(opt.value) ?? null,
              }))
        }
        value={filter.valueToString ? filter.valueToString(nameValueMap[filter.name]) : nameValueMap[filter.name]}
        onChange={
          filter.stringToValue
            ? (value, id) => handleUpdateFilter(filter.stringToValue?.(value), id as string)
            : handleUpdateFilter
        }
      />
    );
  };

  const renderText = (filter: TextFilter, nameValueMap: Record<string, any>) => {
    return (
      <InputText
        key={filter.name}
        defaultValue={nameValueMap[filter.name] || DEFAULT_TEXT_FILTER_VALUE}
        placeholder={filter.placeholder}
        classNames={cn(filter.className, 'filter')}
        onChange={e => handleChangeDebouncedText(filter.name, e.currentTarget.value)}
      />
    );
  };

  const renderPeriods = (filter: DatePeriodFilter, nameValueMap: Record<string, any>) => {
    return (
      <InputDatepickerRange
        key={filter.name}
        placeholder={filter.placeholder}
        className={cn(filter.className, 'filter')}
        startDate={tryGetDateOrNull(nameValueMap?.[filter.name]?.['startDate']) ?? DEFAULT_DATE_FILTER_VALUE}
        endDate={tryGetDateOrNull(nameValueMap?.[filter.name]?.['endDate']) ?? DEFAULT_DATE_FILTER_VALUE}
        onChange={range => handleChangeDateRange(filter.name, range)}
      />
    );
  };

  const renderDate = (filter: DateFilter, nameValueMap: Record<string, any>) => {
    return (
      <InputDatepicker
        key={filter.name}
        format={filter.format}
        showYearPicker={filter.showYearPicker}
        showMonthYearPicker={filter.showMonthYearPicker}
        clearable={filter.clearable}
        value={tryGetDateOrNull(nameValueMap[filter.name]) ?? DEFAULT_DATE_FILTER_VALUE}
        placeholder={filter.placeholder}
        className={cn(filter.className, 'filter')}
        onChange={date => handleUpdateFilter(date, filter.name)}
      />
    );
  };

  const renderCompositeFilter = (compositeFilter: CompositeFilter, nameValueMap: Record<string, any>) => {
    const subFilterNameValueMap = compositeFilter.items.reduce((acc, subFilter) => {
      if (subFilter.type === FilterType.COMPOSITE) {
        throw new Error('Create nested composite filters unavailable');
      }

      const [, subFilterNameWithoutParentPart] = subFilter.name.split(COMPOSITE_FILTER_SEPARATOR);
      return {
        ...acc,
        [subFilter.name]:
          (nameValueMap[compositeFilter.name] && nameValueMap[compositeFilter.name][subFilterNameWithoutParentPart]) ??
          undefined,
      };
    }, {});
    return (
      <React.Fragment key={compositeFilter.name}>
        {renderAllItem(compositeFilter.items, subFilterNameValueMap)}
      </React.Fragment>
    );
  };

  const renderAllItem = (filterItems: Array<Filter>, dataSource = filterNameValueMap): JSX.Element => {
    return (
      <>
        {filterItems.map(filter => {
          switch (filter.type) {
            case FilterType.SELECT:
              return renderSelect(filter, dataSource);
            case FilterType.TEXT:
              return renderText(filter, dataSource);
            case FilterType.PERIOD:
              return renderPeriods(filter, dataSource);
            case FilterType.DATE:
              return renderDate(filter, dataSource);
            case FilterType.COMPOSITE:
              return renderCompositeFilter(filter, dataSource);
            default:
              return <></>;
          }
        })}
      </>
    );
  };

  return <>{renderAllItem(items)}</>;
};
