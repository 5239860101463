import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {YoutubeChannelsApi} from '../../../api/youtube-channels-api';
import {Feed} from '../../../api/DTOs/Feed';
import {useLoading} from '../../../hooks/use-loading';
import {FeedRevenue, FeedRevenueByMonth} from '../../../api/DTOs/ChannelRevenue/FeedRevenue';
import cn from 'classnames';
import {useIntl} from '../../../hooks/use-intl';
import {Separator} from '../../../components/separator';
import {StackedColumnsChart} from '../../../components/charts/stacked-columns-chart';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {Table} from '../../../components/table';
import {TableUtils} from '../../../components/table-utils';
import {Preloader} from '../../../components/preloader';

type Props = {
  youtubeChannelId: string;
};

export const ChannelRevenues: React.FC<Props> = ({youtubeChannelId}) => {
  const api = useMemo(() => new YoutubeChannelsApi(), []);
  const intl = useIntl();

  const [loadings, startLoading, stopLoading] = useLoading({
    feed: true,
    data: true,
  });

  const [selectedFeed, setSelectedFeed] = useState<Feed | null>();
  const [feeds, setFeeds] = useState<Array<Feed>>([]);
  const [data, setData] = useState<FeedRevenue | null>(null);

  const categories = useMemo(
    () => data?.items.map(r => CustomFormatter.formatDate_MM_YYYY(new Date(r.year, r.month - 1))) || [],
    [data],
  );

  const getSeriesDataByKey = (key: string) => {
    return (data?.items.map(r => r[key as keyof FeedRevenueByMonth]) as Array<number>) || [];
  };

  const series = useMemo(() => {
    if (!data) {
      return [];
    }

    const result = [];
    if (data.feed_has_advertisement) {
      result.push({
        name: intl.formatMessage({id: 'ADS'}),
        data: getSeriesDataByKey('revenue_advertisement'),
      });
    }

    if (data.feed_has_subscription) {
      result.push({
        name: intl.formatMessage({id: 'SUBSCRIPTION'}),
        data: getSeriesDataByKey('revenue_subscription'),
      });
    }
    return result;
  }, [data]);

  useEffect(() => {
    startLoading('feed');
    api
      .getAvailableRevenueFeeds(youtubeChannelId)
      .then(res => setFeeds(res.data.items))
      .catch(() => intl.formatMessage({id: 'UNEXPECTED_ERROR'}))
      .finally(() => stopLoading('feed'));
  }, [youtubeChannelId]);

  useEffect(() => {
    if (feeds.length > 0) {
      setSelectedFeed(feeds[0]);
    } else {
      setSelectedFeed(null);
    }
  }, [feeds]);

  useEffect(() => {
    if (selectedFeed) {
      fetchRevenueByFeed(selectedFeed).then();
    }
  }, [selectedFeed]);

  const getDataLabel = useCallback(
    (val: number | string | number[]) => {
      return CustomFormatter.formatMoney(val as number, data?.feed_currency);
    },
    [data?.feed_currency],
  );

  const fetchRevenueByFeed = useCallback(async (feed: Feed) => {
    try {
      startLoading('data');
      const res = await api.getRevenueByMonth(youtubeChannelId, feed);
      setData(res.data.item);
    } catch (e) {
      intl.formatMessage({id: 'UNEXPECTED_ERROR'});
    } finally {
      stopLoading('data');
    }
  }, []);

  return (
    <>
      <ul className='nav nav-tabs' style={{border: 'none'}}>
        {feeds.map(feed => {
          return (
            <li key={feed} onClick={() => setSelectedFeed(feed)} className='nav-item cursor-pointer'>
              <div className={cn('nav-link', {active: selectedFeed === feed})}>
                <span className='nav-text font-weight-bolder'>{intl.formatMessage({id: 'FEED_' + feed})}</span>
              </div>
            </li>
          );
        })}
      </ul>
      <div
        style={{
          boxShadow: 'none',
          borderStartStartRadius: '0px',
          // HACK, поскольку нормально borderTop не убирается
          borderLeft: '1px solid #EBEDF3',
          borderRight: '1px solid #EBEDF3',
          borderBottom: '1px solid #EBEDF3',
        }}
        className={'card card-custom gutter-b'}>
        <div className={`card-body position-relative`}>
          {loadings.data ? (
            <Preloader />
          ) : (
            <>
              <StackedColumnsChart
                height={'70%'}
                categories={categories}
                series={series}
                formatters={{dataLabels: getDataLabel, tooltip: getDataLabel}}
              />
              <Separator className={'my-7'} />
              <Table
                data={data?.items ?? []}
                columns={[
                  {
                    id: 'Period',
                    Header: intl.formatMessage({id: 'PERIOD'}),
                    minWidth: 120,
                    Cell: ({value}) => {
                      return TableUtils.renderBaseContent(
                        CustomFormatter.formatDate_MM_YYYY(new Date(value.year, value.month - 1)),
                      );
                    },
                  },
                  {
                    id: 'ADS',
                    visible: data?.feed_has_advertisement,
                    Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'ADS'})),
                    maxWidth: 100,
                    minWidth: 100,
                    Cell: ({value}) => {
                      return TableUtils.renderNumericContent(
                        CustomFormatter.formatMoney(value.revenue_advertisement, data?.feed_currency),
                      );
                    },
                  },
                  {
                    id: 'SUBSCRIPTION',
                    visible: data?.feed_has_subscription,
                    Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUBSCRIPTION'})),
                    maxWidth: 100,
                    minWidth: 100,
                    Cell: ({value}) => {
                      return TableUtils.renderNumericContent(
                        CustomFormatter.formatMoney(value.revenue_subscription, data?.feed_currency),
                      );
                    },
                  },
                  {
                    id: 'TOTAL',
                    Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'TOTAL'})),
                    maxWidth: 100,
                    minWidth: 100,
                    Cell: ({value}) => {
                      return TableUtils.renderNumericContent(
                        CustomFormatter.formatMoney(value.revenue_total, data?.feed_currency),
                      );
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
