import React, {CSSProperties} from 'react';
import {Badge, BadgeProps} from 'react-bootstrap';
import './badge-custom.scss';
import cn from 'classnames';

export const BadgeCustom: React.FC<BadgeProps & {style?: CSSProperties}> = ({
  variant,
  pill,
  className,
  as,
  style,
  children,
}) => {
  return (
    <>
      <Badge as={as} className={cn('font-weight-boldest', className)} style={style} variant={variant} pill={pill}>
        {children}
      </Badge>
    </>
  );
};
