import {IntlShape, useIntl as useIntlInternal} from 'react-intl';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';
import ru from '../../localization/messages/ru';

export type LocalizationKey = Exclude<keyof typeof ru, number>;
type MessageDescriptor = {
  id?: LocalizationKey;
  // eslint-disable-next-line @typescript-eslint/ban-types
  description?: string | object;
  defaultMessage?: string;
};

type IntlShapeWithExcludedFormatMessage = Omit<IntlShape, 'formatMessage'>;

type CustomIntlShapeType = IntlShapeWithExcludedFormatMessage & {
  formatMessage(descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>): string;
  formatMessage(
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn<any>>,
  ): string | React.ReactNodeArray;
};

export function useIntl(): CustomIntlShapeType {
  return useIntlInternal() as CustomIntlShapeType;
}
