import {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import {getPreparedAxiosInstance} from './axios-instance';
import {Filter, IQueryParams} from './DTOs/IFilterDtos';
import {ISelectResponse} from './response-contracts/yotutube-channel-contracts';
import {IResponseBase} from './response-contracts/base-response';

type PostData = Record<string, unknown>;

export class BaseEndpoint {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = getPreparedAxiosInstance();
  }

  public applyQueryParams(params?: IQueryParams): string {
    if (!params) return '';

    const {filters, page, sort, itemsPerPage} = params;
    let queryParamsAsString = '';
    if (page) {
      queryParamsAsString += `&page=${page}`;
    }

    if (itemsPerPage) queryParamsAsString += `&items_per_page=${itemsPerPage}`;

    if (filters) {
      const keys = Object.keys(filters);
      keys.forEach(key => {
        // @ts-ignore
        if (filters[key]) {
          if (Array.isArray(filters[key])) {
            queryParamsAsString += filters[key]
              .map((value: string) => {
                return `&filters[${key}]=${value}`;
              })
              .join('');

            return;
          }
          // TODO подумать куда вынести
          if (key === 'range' && filters.range) {
            queryParamsAsString += `&filters[start_date]=${filters.range.start_date}&filters[end_date]=${filters.range.end_date}`;
          } else {
            // @ts-ignore
            queryParamsAsString += `&filters[${key}]=${filters[key]}`;
          }
        }
      });
    }

    if (sort) {
      if (sort.field) queryParamsAsString += `&sort[field]=${sort.field}`;
      if (sort.direction) queryParamsAsString += `&sort[direction]=${sort.direction}`;
    }

    return queryParamsAsString;
  }

  public getSelectValues(url: string, filters?: Filter): AxiosPromise<ISelectResponse> {
    const query = this.applyQueryParams({filters});
    return this.get<ISelectResponse>(`${url}?${query}`);
  }

  public get<T = IResponseBase>(url: string, config: AxiosRequestConfig = {}): AxiosPromise<T> {
    return this.axiosInstance.get<T>(url, {
      withCredentials: true,
      ...config,
    });
  }

  public getFile(url: string): AxiosPromise {
    return this.axiosInstance.get(url, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  public post<T = IResponseBase>(url: string, data: PostData): AxiosPromise<T> {
    return this.axiosInstance.post<T>(url, data);
  }

  public postFile(url: string, data: FormData | File | Blob): AxiosPromise {
    return this.axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public putFile(url: string, data: FormData | File | Blob): AxiosPromise {
    return this.axiosInstance.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public delete<T = IResponseBase>(url: string): AxiosPromise<T> {
    return this.axiosInstance.delete<T>(url, {});
  }
}
