import React from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import {LabelWithDescription} from './LabelWithDescription';

interface InputRangeProps {
  value: number;
  required?: boolean;
  label?: string;
  step?: number;
  size?: 'sm' | 'lg';
  tooltip?: 'auto' | 'on' | 'off';
  disabled?: boolean;
  descriptionLabel?: string;
  max?: number;
  min?: number;
  className?: string;

  tooltipLabel?(value: number): string;

  onChange(value: number): void;
}

export const InputRange: React.FC<InputRangeProps> = ({
  value,
  step,
  size,
  tooltip,
  required,
  label,
  descriptionLabel,
  tooltipLabel,
  disabled,
  max,
  min,
  className,
  onChange,
}) => {
  const handleChangeValue = (event: React.SyntheticEvent<HTMLInputElement>, value: number) => {
    onChange(value);
  };

  const defaultMaxValue = 1;
  const defaultMinValue = 0;
  const defaultStepValue = 0.01;
  return (
    <>
      {label && <LabelWithDescription required={required} label={label} description={descriptionLabel} />}
      <RangeSlider
        className={className}
        disabled={disabled}
        max={max || defaultMaxValue}
        min={min || defaultMinValue}
        step={step || defaultStepValue}
        value={value}
        size={size}
        tooltip={tooltip}
        tooltipLabel={tooltipLabel}
        onChange={handleChangeValue}
      />
    </>
  );
};
