import React, {PropsWithChildren} from 'react';
import cn from 'classnames';
import {Toolbar, ToolBarItem} from '../../components/card-toolbar/Toolbar';
import {Preloader} from '../../components/preloader';

type Props = {
  pageTitle?: string;
  loading?: boolean;
  toolbarConfig?: Array<ToolBarItem>;
  className?: {
    card?: string;
    cardHeader?: string;
    cardBody?: string;
    cardFooter?: string;
  };
};

export const BaseCardPageView: React.FC<PropsWithChildren<Props>> = ({
  pageTitle,
  toolbarConfig,
  loading,
  className,
  children,
}) => {
  const renderToolbar = () => {
    if (!toolbarConfig) {
      return null;
    }
    return <Toolbar className={'card-toolbar'} items={toolbarConfig} />;
  };

  const renderBody = () => {
    return loading ? <Preloader /> : children;
  };

  return (
    <>
      <div className={cn('card card-custom gutter-b', className?.card)}>
        <div className={cn('card-header', className?.cardHeader)} style={{flexWrap: 'nowrap'}}>
          {pageTitle && <div className={'card-title'}>{pageTitle}</div>}
          {renderToolbar()}
        </div>
        <div className={cn('card-body', className?.cardBody)}>{renderBody()}</div>
        <div className={cn('card-footer', className?.cardFooter)}></div>
      </div>
    </>
  );
};
