import {useLoading} from './use-loading';
import {useIntl} from 'react-intl';
import {SelectApi} from '../api/select-api';
import {useState} from 'react';
import {mapSelectValuesDTOsToSelectOptions, SelectOptions} from '../components/Inputs/InputSelect';
import {ISelectResponse} from '../api/response-contracts/yotutube-channel-contracts';
import {toast} from 'react-toastify';
import {ISelectValueDto} from '../api/DTOs/ISelectValueDto';
import {Filter} from '../api/DTOs/IFilterDtos';

export type SelectKeys =
  | 'accounts'
  | 'addendums'
  | 'users'
  | 'contractors'
  | 'ourContractors'
  | 'youtubeChannels'
  | 'videos'
  | 'artists'
  | 'genres'
  | 'countries'
  | 'reportPeriods'
  | 'selfContracts';

export type SelectOptionsLoadings = Record<SelectKeys, boolean>;
export type AllSelectOptions = Record<SelectKeys, SelectOptions>;

export function useSelectApi() {
  const [loadings, startLoading, stopLoading] = useLoading({} as SelectOptionsLoadings);
  const intl = useIntl();
  const api = new SelectApi();
  const [selectsOptions, setSelectsOptions] = useState<AllSelectOptions>({} as any);

  const fetchSelectValues = async (key: SelectKeys, filters?: Filter) => {
    try {
      startLoading(key);
      // @ts-ignore
      const result = (await api[key](filters)) as ISelectResponse;
      setSelectsOptions(prevState => ({
        ...prevState,
        [key]: mapSelectValuesDTOsToSelectOptions(key, {[key]: result.data.items} as Record<SelectKeys,
          Array<ISelectValueDto>>),
      }));
    } catch (e) {
      toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading(key);
    }
  };
  return {fetchSelectValuesByKey: fetchSelectValues, selectsOptions, optionsLoadings: loadings};
}
