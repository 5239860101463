import {ApiRequestException, getPreparedAxiosInstance} from '../app/api/axios-instance';
import store from '../redux/store';
import {UpdateAction} from './updater-redux';
import {EXCEPTION_TYPE} from '../app/api/exceptions/BaseException';

const UPDATE_MARKER_LINK = '/static/update-marker.{{starpro-client-version-id}}.upd';
const UPDATE_INTERVAL_MS = process.env.NODE_ENV === 'development' ? 10000 : 60 * 1000 * 2;

export async function startCheckingForServerUpdates() {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  if (await checkClientWasUpdated()) {
    store.dispatch(UpdateAction());
  } else {
    setTimeout(() => startCheckingForServerUpdates(), UPDATE_INTERVAL_MS);
  }
}

async function checkClientWasUpdated() {
  return new Promise<boolean>(resolve => {
    const metaTags = Array.from(document.head.getElementsByTagName('meta'));
    const metaTagWithVersionAttribute = metaTags.find(metaTag => {
      const versionId = metaTag.getAttribute('starpro-client-version-id');
      return versionId !== null;
    });

    if (metaTagWithVersionAttribute) {
      const versionId = metaTagWithVersionAttribute.getAttribute('starpro-client-version-id');
      if (versionId === null) {
        console.error('Version not specified');
        return resolve(false);
      }

      getPreparedAxiosInstance()
        .get(UPDATE_MARKER_LINK.replace('{{starpro-client-version-id}}', versionId), {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
        .then(() => resolve(false))
        .catch(error => {
          if ((error as ApiRequestException).errorType === EXCEPTION_TYPE.NETWORK_ERROR) {
            return resolve(false);
          }

          return resolve(true);
        });
    } else {
      return resolve(false);
    }
  });
}
