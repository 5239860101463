import React from 'react';
import {Link} from 'react-router-dom';
import {TransactionOperation} from '../../../../api/DTOs/TransactionOperation';
import {Routes} from '../../../../../configs/routes';
import {ValueWithLabel} from '../../../base/base-card-components/value-with-label';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Video} from '../../../../api/DTOs/Video';

type Props = {
  operation: TransactionOperation;
  video?: Video;
};

export const VideoInfo: React.FC<Props> = ({operation, video}) => {
  return (
    <>
      {[operation.video_id, operation.video_title].some(s => !!s) && (
        <div className={'font-size-h4 font-weight-boldest my-3'}>
          <FormattedMessage id={'VIDEOS'} />
        </div>
      )}
      <div>
        {operation.video_id && (
          <ValueWithLabel
            value={<Link to={Routes.getSpecifyVideosRoute(operation.video_id)}>{operation.video_title}</Link>}
            title={'TITLE'}
            className={cn('mb-0')}
          />
        )}
        {video?.authors && <ValueWithLabel value={video.authors} title={'AUTHORS'} className={cn('mb-0')} />}
        {video?.director && <ValueWithLabel value={video.director} title={'DIRECTOR'} className={cn('mb-0')} />}
      </div>
    </>
  );
};
