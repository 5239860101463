import React from 'react';
import {Table} from '../../../components/table';
import {ReportPeriod} from '../../../api/DTOs/ReportPeriod';
import {ManyPrivileges} from '../../../api/base/base-crud-api-response';
import {ReportsPeriodsPrivileges} from '../../../hooks/apis/use-reports-periods-api';
import {useIntl} from '../../../hooks/use-intl';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {capitalizeFirstLetter, getDateFromMonthAndYear} from '../../../utils/utils';
import {TableUtils} from '../../../components/table-utils';
import {RowActionButton} from '../../../components/table-actions-button';
import {ReportPeriodAction} from './use-reporting-periods-action-handler';
import {BadgeCustom} from '../../../components/badge-custom';
import {REPORT_PERIODS_STATUS_COLOR_MAP} from './reporting-periods-page';

type Props = {
  data: Array<ReportPeriod>;
  privileges: ManyPrivileges<ReportsPeriodsPrivileges>;
  onAction: (period: ReportPeriod, action: ReportPeriodAction) => Promise<unknown>;
};

export const ReportingPeriodsTable: React.FC<Props> = ({data, privileges, onAction}) => {
  const intl = useIntl();
  return (
    <Table
      data={data}
      columns={[
        {
          id: 'Period',
          Header: intl.formatMessage({id: 'PERIOD'}),
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              capitalizeFirstLetter(
                CustomFormatter.formatDateWithFullMonthAndYear(getDateFromMonthAndYear(value.month - 1, value.year)),
              ),
            );
          },
        },
        {
          id: 'Status',
          Header: intl.formatMessage({id: 'STATUS'}),
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <BadgeCustom variant={REPORT_PERIODS_STATUS_COLOR_MAP[value.reportPeriodStatus]}>
                {intl.formatMessage({id: 'REPORT_PERIOD_STATUS_' + value.reportPeriodStatus})}
              </BadgeCustom>,
            );
          },
        },
        {
          id: 'Validity',
          Header: intl.formatMessage({id: 'VALIDITY_PERIOD'}),
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              `${intl.formatDate(value?.started_at)} - ${intl.formatDate(value?.expires_at)}`,
            );
          },
        },
        {
          id: 'ActionButtons',
          Header: '',
          Cell: ({value}) => {
            const actions = [
              {
                title: intl.formatMessage({id: 'CLOSE'}),
                value: ReportPeriodAction.CLOSE,
                disabled: !privileges[value.id].can_close,
              },
              {
                title: intl.formatMessage({id: 'OPEN'}),
                value: ReportPeriodAction.OPEN,
                disabled: !privileges[value.id].can_open,
              },
            ];
            return TableUtils.renderActionButton(
              <RowActionButton rowDto={value} actions={actions} onSelect={onAction} />,
            );
          },
        },
      ]}
    />
  );
};
