import React, {useState} from 'react';
import {useEntityApi} from '../../../hooks/use-entity-api';
import {BaseListPage} from '../../base/base-list-page';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {ICONS} from '../../../images/images';
import {useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {useLoading} from '../../../hooks/use-loading';
import {Country} from '../../../api/DTOs/Country';
import {CountriesTable} from './countries-table';
import {CountryAction, useCountryActionHandler} from './useCountryHandlerActions';

export const CountriesPage: React.FC = () => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    countries: true,
  });
  const api = useEntityApi<Country>({prefixUrl: 'countries', isAdmin: false});
  const [countries, setCountries] = useState<Array<Country>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const fetchCountries = async (params?: IQueryParams) => {
    try {
      startLoading('countries');
      const result = await api.getAll(params);
      setCountries(result.items);
      setPaginationInfo(result.paginator);
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('countries');
    }
  };

  const {handleCountryActions} = useCountryActionHandler(fetchCountries);

  return (
    <>
      <BaseListPage
        pageTitle={intl.formatMessage({id: 'COUNTRIES'})}
        loading={loadings.countries}
        pagination={{info: paginationInfo}}
        fetchData={fetchCountries}
        toolbarConfig={[
          {
            type: 'BUTTON',
            title: intl.formatMessage({id: 'CREATE'}),
            icon: ICONS.PLUS,
            className: 'btn font-weight-bolder text-uppercase btn-light-primary',
            onClick: () => handleCountryActions({type: CountryAction.CREATE}),
          },
        ]}>
        <CountriesTable
          countries={countries}
          onAction={async (country, action) => {
            if (action === CountryAction.EDIT) {
              await handleCountryActions({type: action, country});
            } else if (action === CountryAction.DELETE) {
              await handleCountryActions({type: action, countryId: country.id});
            }
          }}
        />
      </BaseListPage>
    </>
  );
};
