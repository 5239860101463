import React, {SyntheticEvent} from 'react';
import {InputSwitch} from '../../../components/Inputs/InputSwitch';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {getEnumKeys} from '../../../../types/types';
import {Feed} from '../../../api/DTOs/Feed';
import {useIntl} from '../../../hooks/use-intl';

type Props = {
  disabled?: boolean;
  selectedFeeds: Array<Feed>;
  onSelectFeed: (feed: Feed, status: boolean) => void;
};

export const FeedsBlock: React.FC<Props> = ({selectedFeeds, disabled, onSelectFeed}) => {
  const intl = useIntl();
  const handleFeedSwitch = (e: SyntheticEvent<HTMLInputElement>) => {
    onSelectFeed(e.currentTarget.id as Feed, !selectedFeeds.includes(e.currentTarget.id as Feed));
  };

  return (
    <>
      {getEnumKeys(Feed).map(f => {
        return (
          <InputSwitch
            disabled={disabled}
            key={f}
            id={f}
            size={'sm'}
            className={'mb-2'}
            type={'outline'}
            labels={['', intl.formatMessage({id: 'FEED_' + f})]}
            color={BootstrapColors.PRIMARY}
            checked={selectedFeeds.includes(f as Feed)}
            onChange={handleFeedSwitch}
          />
        );
      })}
    </>
  );
};
