import React from 'react';
import {PartialNullable} from '../../../../../types/types';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {LocalizationKey, useIntl} from '../../../../hooks/use-intl';
import {LabelWithDescription} from '../../../../components/Inputs/LabelWithDescription';
import {InputCheckbox} from '../../../../components/Inputs/InputCheckbox';
import {CALLBACK} from '../../../../consts';

type Props = {
  contractor: PartialNullable<Contractor>;
};

export const LegalInfo: React.FC<Props> = ({contractor}) => {
  const intl = useIntl();

  const renderPaymentRequisitesItem = (title: LocalizationKey, value?: string | number | null) => {
    return (
      <div className={'mb-3'}>
        <LabelWithDescription className={{label: 'mb-0'}} label={intl.formatMessage({id: title})} />
        <div>{value ?? 'Нет данных'}</div>
      </div>
    );
  };

  return (
    <div className={'d-flex flex-wrap'}>
      <div className={'mr-10 mb-10'}>
        {renderPaymentRequisitesItem('LEGAL_NAME', contractor?.legal_name)}
        {renderPaymentRequisitesItem('JURISDICTION_LEGAL', contractor?.jurisdiction)}
        {renderPaymentRequisitesItem('LEGAL_OGRN', contractor?.legal_ogrn)}
        {renderPaymentRequisitesItem('TAX_PAYER_NUMBER', contractor?.legal_inn)}
        {renderPaymentRequisitesItem('TAX_PAYER_KPP', contractor?.legal_kpp)}
        {renderPaymentRequisitesItem('LEGAL_ADDRESS', contractor?.legal_address)}
        <InputCheckbox
          disabled
          label={intl.formatMessage({id: 'IS_OUR_COMPANY'})}
          value={contractor?.is_our ?? false}
          onChange={CALLBACK.EMPTY}
        />
      </div>
      <div>
        <h5>{intl.formatMessage({id: 'INFO_ABOUT_LEGAL_SIGNATORY'})}</h5>
        <div className={'d-flex flex-wrap'}>
          <div className={'mr-8'}>
            {renderPaymentRequisitesItem('LAST_NAME', contractor?.legal_signatory_name_last)}
          </div>
          <div className={'mr-8'}>
            {renderPaymentRequisitesItem('FIRST_NAME', contractor?.legal_signatory_name_first)}
          </div>
          <div className={'mr-8'}>
            {renderPaymentRequisitesItem('MIDDLE_NAME', contractor?.legal_signatory_name_middle)}
          </div>
        </div>
        <div>
          {renderPaymentRequisitesItem('LEGAL_SIGNATORY_POSITION', contractor?.legal_signatory_position)}
          {renderPaymentRequisitesItem('LEGAL_SIGNATORY_ACTS_BY', contractor?.legal_signatory_acts_by)}
        </div>
      </div>
    </div>
  );
};
