import {EntityId} from '../api/base/BaseEntity';
import {BaseCrudApi} from '../api/base/base-crud-api';
import {useCallback, useMemo, useState} from 'react';
import {IQueryParams} from '../api/DTOs/IFilterDtos';
import {ValidationErrorsType} from '../utils/utils';
import {ApiRequestException} from '../api/axios-instance';
import {EXCEPTION_TYPE} from '../api/exceptions/BaseException';
import {ValidationException} from '../api/exceptions/ValidationException';
import {FilesApi} from '../api/file-api/files-api';
import {
  MultipartUploader,
  MultipartUploaderErrorCompleteUploadPayload,
  MultipartUploaderErrorCreateFilePayload,
  MultipartUploaderErrorUploadPartsPayload,
  MultiPartUploadSteps,
} from '../services/MultipartUploader';
import {PartialNullable} from '../../types/types';

type UploadFileToS3Config = {
  signedUrlRoute: string;
};

export type MultipartUploadFileToS3Config = {
  initiateUploadRoute: string;
  signedUrlsRoute: string;
  completeUploadRoute: string;
};

export type EntityApiHookParams = {
  isAdmin: boolean;
  prefixUrl: string;
  uploadConfig?: UploadFileToS3Config;
  multipartUploadConfig?: MultipartUploadFileToS3Config;
};

export function useEntityApi<T, EntityRelations = unknown | undefined, EntityPrivileges = unknown | undefined>(
  params: EntityApiHookParams,
) {
  const api = useMemo(() => new BaseCrudApi<T, EntityRelations, EntityPrivileges>(params), []);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType<T>>(null);
  const [uploadConfig] = useState<UploadFileToS3Config | null>(params.uploadConfig ?? null);
  const [multipartUploadConfig] = useState<MultipartUploadFileToS3Config | null>(params.multipartUploadConfig ?? null);

  const get = useCallback(async (entityId: EntityId) => {
    const result = await api.getEntityById(entityId);
    return result.data;
  }, []);

  const getAll = useCallback(async (params?: IQueryParams) => {
    const result = await api.getEntities(params);
    return result.data;
  }, []);

  const update = useCallback(async (entityId: EntityId, entityFields: PartialNullable<T>) => {
    try {
      const result = await api.updateEntity(entityId, entityFields);
      return result.data.item;
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException<T>).error_data.messages);
      }
      throw e;
    }
  }, []);

  const create = useCallback(async (entityFields: PartialNullable<T>) => {
    try {
      const result = await api.createEntity(entityFields);
      return result.data.item;
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException<T>).error_data.messages);
      }
      throw e;
    }
  }, []);

  const remove = useCallback(async (entityId: EntityId) => {
    try {
      const result = await api.deleteEntity(entityId);
      return result.data;
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as ValidationException<T>).error_data.messages);
      }
      throw e;
    }
  }, []);

  const multipartUploadToFileStorage = useCallback(async (file: File, tickCb: (progress: number) => void) => {
    if (!multipartUploadConfig) {
      throw new Error('Should specify multipartUploadConfig when create hook!');
    }
    const multipartUploader = MultipartUploader.getInstance();
    return await multipartUploader.uploadToFileStorage(file, multipartUploadConfig, tickCb);
  }, []);

  const cancelMultipartUpload = useCallback((promiseResolver: (value: any | PromiseLike<any>) => void) => {
    const multipartUploader = MultipartUploader.getInstance();
    multipartUploader.cancelUpload(promiseResolver);
  }, []);

  const tryAgainMultipartUploadToFileStorage = useCallback(
    async (
      file: File,
      errorPayload:
        | MultipartUploaderErrorUploadPartsPayload
        | MultipartUploaderErrorCompleteUploadPayload
        | MultipartUploaderErrorCreateFilePayload,
      tickCb: (progress: number) => void,
    ) => {
      if (!multipartUploadConfig) {
        throw new Error('Should specify multipartUploadConfig when create hook!');
      }
      const multipartUploader = MultipartUploader.getInstance();
      if (errorPayload.type === MultiPartUploadSteps.UPLOAD_PARTS) {
        return await multipartUploader.tryUploadFaultedParts(file, errorPayload, multipartUploadConfig, tickCb);
      } else if (errorPayload.type === MultiPartUploadSteps.UPLOAD_COMPLETING) {
        return await multipartUploader.tryCompleteUpload(errorPayload, multipartUploadConfig);
      } else if (errorPayload.type === MultiPartUploadSteps.CREATE_FILE_AFTER_UPLOAD) {
        return await multipartUploader.tryCreateNewFile(errorPayload);
      } else {
        throw new Error('Unknown errorPayload type');
      }
    },
    [],
  );

  const uploadToFileStorage = useCallback(
    async (file: File, conf?: UploadFileToS3Config) => {
      const currentUploadConfig = conf || uploadConfig;
      if (!currentUploadConfig) {
        throw new Error('Should specify uploadConfig when create hook!');
      }
      const fileApi = new FilesApi();
      const fileExt = file.name.split('.').pop() || '';
      const {
        data: {
          item: {url, bucket},
        },
      } = await fileApi.getS3SignedUrl(currentUploadConfig.signedUrlRoute, fileExt);
      await fileApi.uploadFileToS3BySignedUrl(url, file);
      const contentUrl = url.split('?')[0];
      const {
        data: {item: createdFile},
      } = await fileApi.createFile({
        original_file_name: file.name,
        extension: fileExt,
        url: contentUrl,
        bucket,
        size: file.size,
      });

      return createdFile;
    },
    [uploadConfig],
  );

  return {
    get,
    getAll,
    create,
    update,
    remove,
    uploadToFileStorage,
    multipartUploadToFileStorage,
    cancelMultipartUpload,
    tryAgainMultipartUploadToFileStorage,
    validationErrors,
    setValidationErrors,
  };
}
