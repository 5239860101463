import React from 'react';
import {Link} from 'react-router-dom';
import {Routes} from '../../../configs/routes';
import {Video} from '../../api/DTOs/Video';
import {ImageWithPreloader} from '../../components/image-with-preloader';
import {IMAGES} from '../../images/images';

interface YoutubeVideoCardProps {
  video: Video;
  previewUrl: string | null;
  artists: string;
}

export const VideoCard = ({video, previewUrl, artists}: YoutubeVideoCardProps) => {
  return (
    <div className='gutter-b card-stretch border card card-custom overlay'>
      <div className='card-body p-0'>
        <div className='overlay-wrapper'>
          <Link to={Routes.getSpecifyVideosRoute(video.id)}>
            <ImageWithPreloader
              containerHeight={'100px'}
              errorStub={IMAGES.VIDEO_PREVIEW_STUB}
              imageProps={{src: previewUrl as string, alt: video.title, className: 'w-100 rounded'}}
            />
          </Link>
        </div>
        <div className={'my-1 p-3'}>
          <Link to={Routes.getSpecifyVideosRoute(video.id)}>
            <div className='text-dark-75 font-size-lg font-weight-bolder'>{video.title}</div>
            <div className='text-dark-75 font-size-md font-weight-bolder text-muted'>{artists}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
