import React, {useEffect, useMemo, useState} from 'react';
import {BaseListPage} from '../base/base-list-page';
import {Artist} from '../../api/DTOs/Artist';
import {IQueryParams, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../api/exceptions/BaseException';
import {useIntl} from '../../hooks/use-intl';
import {useLoading} from '../../hooks/use-loading';
import {ArtistsTable} from './artists-table';
import {ArtistAction, useArtistActionHandler} from './useArtistHandlerActions';
import {ICONS} from '../../images/images';
import {ManyPrivileges, ManyRelations} from '../../api/base/base-crud-api-response';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {useSelectApi} from '../../hooks/use-select-api';
import {useAuth} from '../../hooks/use-auth';
import {ArtistPrivileges, ArtistRelation, useArtistsApi} from '../../hooks/apis/use-artists-api';
import {IPaginationInfo} from '../../api/Paginator';

export const ArtistsPage: React.FC = () => {
  const intl = useIntl();
  const api = useArtistsApi();
  const {
    privileges: {can_select_contractor_for_video},
  } = useAuth();
  const {selectsOptions, fetchSelectValuesByKey, optionsLoadings} = useSelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    artists: true,
  });

  const [artists, setArtists] = useState<Array<Artist>>([]);
  const [relations, setRelations] = useState<ManyRelations<ArtistRelation> | null>(null);
  const [privileges, setPrivileges] = useState<ManyPrivileges<ArtistPrivileges> | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'artists-sort',
      defaultOptions: {
        field: SortField.CREATED_AT,
        direction: SortDirection.DESC,
      },
      options: [
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: A-z`,
          value: {field: SortField.NAME, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: Z-a`,
          value: {field: SortField.NAME, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      allSelectValues: selectsOptions,
      loadingSelect: optionsLoadings,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'name',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          selectKey: 'contractors',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
      ],
    });
  }, [selectsOptions, can_select_contractor_for_video, optionsLoadings]);

  useEffect(() => {
    const fetchSelectValues = async () => {
      if (can_select_contractor_for_video) {
        await fetchSelectValuesByKey('contractors');
      }
    };
    fetchSelectValues().then();
  }, []);

  const fetchArtists = async (params?: IQueryParams) => {
    try {
      startLoading('artists');
      const result = await api.getAll(params);
      setArtists(result.items);
      setRelations(result.relations);
      setPrivileges(result.privileges);
      setPaginationInfo(result.paginator);
    } catch (e) {
      if ((e as ApiRequestException).errorType !== EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        toast.error(e.message || e.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('artists');
    }
  };
  const {handleArtistActions} = useArtistActionHandler(fetchArtists);

  const handleTableAction = async (artist: Artist, action: ArtistAction) => {
    switch (action) {
      case ArtistAction.CREATE:
        await handleArtistActions({type: ArtistAction.CREATE});
        break;
      case ArtistAction.DELETE:
        await handleArtistActions({type: ArtistAction.DELETE, entityId: artist.id});
        break;
      case ArtistAction.EDIT:
        await handleArtistActions({type: ArtistAction.EDIT, entity: artist});
        break;
      case ArtistAction.SET_CHARTMETRIC_ID:
        await handleArtistActions({type: ArtistAction.SET_CHARTMETRIC_ID, entity: artist});
    }
  };

  return (
    <BaseListPage
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      loading={loadings.artists}
      pagination={{info: paginationInfo}}
      fetchData={fetchArtists}
      toolbarConfig={[
        {
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-primary',
          onClick: () => handleArtistActions({type: ArtistAction.CREATE}),
        },
      ]}>
      <ArtistsTable items={artists} relations={relations} privileges={privileges} onAction={handleTableAction} />
    </BaseListPage>
  );
};
