import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {MailingsApi} from '../../../api/mailing-api/mailings-api';
import {IMailingDto, MailingContentType} from '../../../api/mailing-api/IMailingDto';
import {InputSwitch} from '../../../components/Inputs/InputSwitch';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {toast} from 'react-toastify';
import {AdminRoutes} from '../../../../configs/routes';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useModalPreviewHtml} from '../../../components/modals/preview-html-content/modal-preview-html-content-context';
import SVG from 'react-inlinesvg';
import {Preloader} from '../../../components/preloader';
import {ErrorStub} from '../../../components/error-stub';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {Spinner} from 'react-bootstrap';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {useLoading} from '../../../hooks/use-loading';
import {EditMailingForm} from './edit-mailing-form';
import 'react-markdown-editor-lite/lib/index.css';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

interface IProps {
  mode: 'create' | 'edit';
}

const ICONS = {
  VIEW: require('../../../images/svg/Visible.svg'),
};

const CreateOrEditMailingPage: React.FC<IProps> = ({mode}) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    params: {id},
  } = useRouteMatch<{id: string}>();

  const api = new MailingsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: mode === 'edit',
    save: false,
    preview: false,
  });

  const [mailing, setMailing] = useState<Partial<IMailingDto>>({
    multilingual: false,
    content_type: MailingContentType.MARKDOWN,
  });

  const {showPreviewHtmlModal} = useModalPreviewHtml();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    const fetchMailing = async () => {
      try {
        startLoading('page');
        setError(null);
        const result = await api.getMailing(Number(id));
        setMailing(result.data.item);
        setBreadcrumb(result.data.item.subject_ru);
      } catch (e) {
        const err = e as ApiRequestException;
        if (err.errorMessage) {
          setError(err.errorMessage);
        } else {
          setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        }
      } finally {
        stopLoading('page');
      }
    };

    if (mode === 'edit') {
      // noinspection JSIgnoredPromiseFromCall
      fetchMailing();
    }
  }, []);

  const updateOrCreateMailing = async () => {
    try {
      startLoading('save');
      const result =
        mode === 'edit'
          ? await api.updateMailing(mailing as IMailingDto)
          : await api.createMailing(mailing as IMailingDto);
      history.push(AdminRoutes.getSpecifyMailingRoute(result.data.item.id));
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as ValidationException).error_data.messages);
      } else {
        toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('save');
    }
  };

  const getPreview = async () => {
    try {
      startLoading('preview');
      const result = await api.getPreview(mailing as IMailingDto);
      return result.data.item;
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('preview');
    }
  };

  const handleChange = (id: keyof IMailingDto, value: any) => {
    updateMailing({[id]: value});
  };

  const handlePreviewClick = async (lang: 'ru' | 'en') => {
    const result = await getPreview();
    await showPreviewHtmlModal(lang == 'ru' ? result?.preview_ru || '' : result?.preview_en || '');
  };

  const updateMailing = (partOfMailing: Partial<IMailingDto>) => {
    setError(null);
    setValidationError(null);
    setMailing({...mailing, ...partOfMailing});
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderForm = (lang: 'ru' | 'en') => {
    const subject = mailing && (mailing[`subject_${lang}` as keyof IMailingDto] as string);
    const editorValue =
      mailing.content_type === MailingContentType.MARKDOWN
        ? mailing[`markdown_${lang}` as keyof IMailingDto]
        : mailing[`html_${lang}` as keyof IMailingDto];

    if (!mailing.content_type) {
      throw new Error('The CONTENT_TYPE must be filled in when editing');
    }

    return (
      <>
        <EditMailingForm
          language={lang}
          subjectValue={subject}
          validationErrors={validationErrors}
          editorValue={editorValue as string}
          contentType={mailing.content_type}
          onChange={handleChange}
        />
        <button
          disabled={!subject || !editorValue || loadings.preview}
          className={'btn btn-secondary btn-sm'}
          onClick={() => handlePreviewClick(lang)}>
          <span className={`svg-icon svg-icon-secondary svg-icon-2x`}>
            <SVG src={ICONS.VIEW} />
          </span>
          <FormattedMessage id={'PREVIEW'} />
        </button>
      </>
    );
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <FormattedMessage id={mode === 'edit' ? 'EDIT_MAILING' : 'CREATE_MAILING'} />
          </div>
          <div className={'card-toolbar'}>
            <InputSwitch
              id={'content_type'}
              size={'sm'}
              color={BootstrapColors.PRIMARY}
              labels={['Markdown', 'HTML']}
              checked={mailing.content_type === MailingContentType.HTML}
              onChange={() =>
                updateMailing({
                  content_type:
                    mailing.content_type === MailingContentType.MARKDOWN
                      ? MailingContentType.HTML
                      : MailingContentType.MARKDOWN,
                })
              }
            />
          </div>
        </div>
        <div className={`card-body`}>
          <InputSwitch
            id={'content_type'}
            checked={mailing.multilingual}
            size={'sm'}
            className={'mb-5'}
            color={BootstrapColors.PRIMARY}
            labels={['', intl.formatMessage({id: 'MULTILINGUAL'})]}
            onChange={() => updateMailing({multilingual: !mailing.multilingual})}
          />
          <div className={'row'}>
            <div className={mailing.multilingual ? 'col-6' : 'col-12'}>{renderForm('ru')}</div>
            {mailing.multilingual && <div className={'col-6'}>{renderForm('en')}</div>}
          </div>
        </div>
        <div className={'card-footer'}>
          <button onClick={updateOrCreateMailing} className={'btn btn-lg btn-primary mr-2'}>
            {loadings.save && <Spinner className={'mr-1'} size={'sm'} animation={'border'} />}
            <FormattedMessage id={'SAVE'} />
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateOrEditMailingPage;
