import {useModalRejectReasonTransaction} from '../../../components/modals/reject-reason/modal-reject-reason-transaction-context';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../../api/axios-instance';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {useBaseListPage} from '../../base/base-list-page-context';
import {useIntl} from '../../../hooks/use-intl';
import {useModalCreateWithdrawalTransaction} from '../../../components/modals/create-transaction/create-withdrawal-transaction/modal-create-withdrawal-transaction-context';
import {useModalCreateToOurOrganizationTransaction} from '../../../components/modals/create-transaction/create-to-our-organization-transaction/modal-create-to-our-organization-transaction-context';
import {useModalCreateReturnTaxToYoutubeTransaction} from '../../../components/modals/create-transaction/create-return-youtube-tax-transaction/modal-create-return-youtube-tax-transaction-context';
import {useCallback, useMemo} from 'react';
import {HandleTransactionActionPayload, TransactionAction} from './transaction-action-types';
import {useTransactionsApi} from '../../../hooks/apis/use-transactions-api';
import {useLoading} from '../../../hooks/use-loading';
import {useTransactionsOperationApi} from '../../../hooks/apis/use-transactions-operation-api';
import {useModalIncomeReport} from '../../../components/modals/channel-transaction-income-report-modal/modal-channel-transactions-income-report-context';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';

export function useTransactionActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const api = useTransactionsApi();
  const operationsApi = useTransactionsOperationApi();
  const financialReportApi = useMemo(() => new FinancialReportApi(), []);
  const [loadings, startLoading, stopLoading] = useLoading({
    report: false,
  });
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showCreateWithdrawalTransactionModal} = useModalCreateWithdrawalTransaction();
  const {showCreateToOurOrganizationTransactionModal} = useModalCreateToOurOrganizationTransaction();
  const {showCreateReturnTaxToYoutubeTransactionModal} = useModalCreateReturnTaxToYoutubeTransaction();
  const {showRejectReasonTransactionModal} = useModalRejectReasonTransaction();
  const {showModalIncomeReport} = useModalIncomeReport();
  const {appliedQueryParams} = useBaseListPage();

  const actionModalShowerMap = useMemo(() => {
    return {
      [TransactionAction.CREATE_WITHDRAWAL]: showCreateWithdrawalTransactionModal,
      [TransactionAction.CREATE_TO_OUR_ORGANIZATION]: showCreateToOurOrganizationTransactionModal,
      [TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE]: showCreateReturnTaxToYoutubeTransactionModal,
    };
  }, [
    showCreateReturnTaxToYoutubeTransactionModal,
    showCreateToOurOrganizationTransactionModal,
    showCreateWithdrawalTransactionModal,
  ]);

  const handle = useCallback(
    async (payload: HandleTransactionActionPayload): Promise<any> => {
      try {
        switch (payload.type) {
          case TransactionAction.CANCEL: {
            const closeModalResult = await showRejectReasonTransactionModal({
              transactionId: payload.data.entityId,
              type: payload.type,
              modalConfiguration: {
                title: 'DESCRIBE_CANCEL_REASON',
                reasonInputKey: 'cancel_reason',
                reasonInputLabel: 'CANCEL_REASON',
              },
            });

            if (closeModalResult.reason === CloseModalReason.OK) {
              toast.success(intl.formatMessage({id: 'CANCEL_TRANSACTION_SUCCESS'}));
              await fetch?.(appliedQueryParams);
            }
            break;
          }
          case TransactionAction.REJECT: {
            const closeModalResult = await showRejectReasonTransactionModal({
              transactionId: payload.data.entityId,
              type: payload.type,
            });
            if (closeModalResult.reason === CloseModalReason.OK) {
              toast.success(intl.formatMessage({id: 'REJECT_TRANSACTION_SUCCESS'}));
              await fetch?.(appliedQueryParams);
            }
            break;
          }

          case TransactionAction.CONFIRM: {
            if (
              await showConfirmActionModal(
                intl.formatMessage({id: 'CONFIRM_TRANSACTION'}),
                intl.formatMessage({id: 'CONFIRM_TRANSACTION_DESCRIPTION'}),
              )
            ) {
              await api.confirmTransaction(payload.data.entityId);
              toast.success(intl.formatMessage({id: 'CONFIRM_TRANSACTION_SUCCESS'}));
              await fetch?.(appliedQueryParams);
            }
            break;
          }
          case TransactionAction.CREATE_TO_OUR_ORGANIZATION:
          case TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE:
          case TransactionAction.CREATE_WITHDRAWAL: {
            const data = payload.data
              ? {
                  calculated_name: payload.data.contractorName,
                  id: payload.data.contractorId,
                }
              : undefined;
            const closeModalResult = await actionModalShowerMap[payload.type](data);
            if (closeModalResult.reason === CloseModalReason.OK && closeModalResult.payload) {
              fetch?.(appliedQueryParams);
            }
            break;
          }
          case TransactionAction.DOWNLOAD_ACCOUNTS_BALANCE_REPORT: {
            await financialReportApi.postDownloadAccountsBalancesReport(appliedQueryParams);
            toast.success(intl.formatMessage({id: 'SUCCESS_START_GENERATE_BALANCE_REPORT'}));
            break;
          }
          case TransactionAction.DOWNLOAD_REPORT: {
            startLoading('report');
            await operationsApi.downloadOperationsReport(appliedQueryParams);
            toast.success(intl.formatMessage({id: 'SUCCESS_START_GENERATE_BALANCE_REPORT'}));
            break;
          }
          case TransactionAction.INCOME_REPORT: {
            const closeModalResult = await showModalIncomeReport();
            if (closeModalResult.reason === CloseModalReason.OK) {
              toast.success(intl.formatMessage({id: 'SUCCESS_UPLOAD_REPORTS'}));
            }
            break;
          }
        }
      } catch (e) {
        const err = e as ApiRequestException;
        if (err.errorMessage) {
          toast.error(err.errorMessage);
        } else {
          toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        }
      } finally {
        stopLoading('report');
      }
    },
    [appliedQueryParams],
  );

  return {
    handleTransactionAction: handle,
    loadings,
  };
}
