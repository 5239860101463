import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {CreatedFile} from '../../api/file-api/files-api';
import {useCallback, useRef} from 'react';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {User} from '../../api/DTOs/User';
import {BaseItemResponse} from '../../api/base/base-crud-api-response';
import {Feed} from '../../api/DTOs/Feed';
import {Artist} from '../../api/DTOs/Artist';
import {Video} from '../../api/DTOs/Video';

export type FileRelations = {
  user: User;
  feed: Feed | null;
  video: Video | null;
  artist: Artist | null;
};

export type FilePrivileges = {
  can_edit: boolean;
  can_delete: boolean;
};

export type MemoryUsedInfo = {
  memoryUsedInBytes: number;
  count: number;
};

export function useFilesApi() {
  const config = useRef({prefixUrl: 'files', isAdmin: false});
  return {
    ...useEntityApi<CreatedFile, FileRelations, FilePrivileges>(config.current),
    ...useAdditionalRoutes(config.current),
  };
}

// TODO вынести сюда логику мультизагрузки
const useAdditionalRoutes = (params: EntityApiHookParams) => {
  const api = useBaseEndpointApi();
  const downloadFile = useCallback(async (fileUrl: string) => {
    return api.getFile(fileUrl);
  }, []);

  const memoryUsedInfo = useCallback(async () => {
    return api.get<BaseItemResponse<MemoryUsedInfo>>(`${params.prefixUrl}/memory-used-info`);
  }, []);

  return {
    downloadFile,
    memoryUsedInfo,
  };
};
