import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {User, UserType} from '../../../api/DTOs/User';
import {Contractor} from '../../../api/contractor-api/Contractor';
import {Preloader} from '../../../components/preloader';
import {useModalEditUser} from '../../../components/modals/edit-user/modal-edit-user-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {AdminRoutes} from '../../../../configs/routes';
import {ContractorInfo} from '../../../components/contractor-info';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {UserPrivileges, useUserApi} from '../../../hooks/apis/use-user-api';
import {ICONS} from '../../../images/images';

export const UserPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();

  const api = useUserApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    signature: true,
    paymentRequisites: true,
  });

  const [user, setUser] = useState<User | null>(null);
  const [privileges, setPrivileges] = useState<UserPrivileges | null>(null);
  const [contractor, setContractor] = useState<Contractor | null>(null);

  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {showEditUserModal} = useModalEditUser();
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    fetchUser().then();
  }, []);

  useEffect(() => {
    if (user?.email) {
      setBreadcrumb(user.email);
    }
  }, [user?.email]);

  const calculatedUserName = useMemo(() => {
    if (!user) {
      return null;
    }

    let name = '';
    if (user.name_first) {
      name += `${user.name_first} `;
    }

    if (user.name_last) {
      name += `${user.name_last} `;
    }

    if (user.name_middle) {
      name += `${user.name_middle} `;
    }

    return name;
  }, [user]);

  const fetchUser = async () => {
    try {
      startLoading('page');
      const result = await api.get(match.params.id);
      setContractor(result.relations.contractor);
      setPrivileges(result.privileges);
      setUser(result.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const handleEditInfoAboutUserClick = async () => {
    if (!user) {
      return;
    }

    const result = await showEditUserModal(user);
    if (result.reason === CloseModalReason.OK) {
      await fetchUser();
    }
  };

  const handleResetPassword = async () => {
    try {
      if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_RESET_PASSWORD_USER'}))) {
        await api.resetPassword(match.params.id);
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleChangeLockStatus = async () => {
    if (!user) {
      return;
    }

    try {
      if (user.blocked_at !== null) {
        if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_UNBLOCK_USER'}))) {
          await api.unblock(match.params.id);
          await fetchUser();
        }
      } else {
        if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_BLOCK_USER'}))) {
          await api.block(match.params.id);
          await fetchUser();
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleDeleteClick = async () => {
    try {
      if (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_USER'}))) {
        await api.remove(match.params.id);
        history.push(AdminRoutes.getUserManagementRoute());
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (user === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  const renderButtons = () => {
    if (!user) {
      return null;
    }

    return (
      <Toolbar
        items={[
          {
            visible: !privileges?.can_update,
            title: intl.formatMessage({id: 'EDIT'}),
            type: 'BUTTON',
            icon: ICONS.EDIT,
            className: 'btn btn-light-success font-weight-bolder',
            onClick: handleEditInfoAboutUserClick,
          },
          {
            visible: privileges?.can_reset_password,
            title: intl.formatMessage({id: 'RESET_PASSWORD'}),
            type: 'BUTTON',
            icon: ICONS.RESET,
            className: 'btn btn-light-primary font-weight-bolder',
            onClick: handleResetPassword,
          },
          {
            visible: user.blocked_at ? privileges?.can_unblock : privileges?.can_block,
            title: user.blocked_at !== null ? intl.formatMessage({id: 'UNBLOCK'}) : intl.formatMessage({id: 'BLOCK'}),
            type: 'BUTTON',
            icon: user.blocked_at !== null ? ICONS.UNBLOCK : ICONS.BLOCK,
            className: 'btn btn-light-warning font-weight-bolder',
            onClick: handleChangeLockStatus,
          },
          {
            visible: privileges?.can_delete,
            title: intl.formatMessage({id: 'DELETE'}),
            type: 'BUTTON',
            icon: ICONS.DELETE,
            className: 'btn btn-light-danger font-weight-bolder',
            onClick: handleDeleteClick,
          },
        ]}
      />
    );
  };

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                {calculatedUserName ? (
                  calculatedUserName
                ) : (
                  <>
                    <FormattedMessage id={'USER_NAME'} />: {intl.formatMessage({id: 'NOT_APPLICABLE'})}
                  </>
                )}
              </p>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'EMAIL'} />: {user.email}
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <FormattedMessage id={'TYPE'} />:{' '}
                <span className={user.type === UserType.ADMIN ? 'text-primary' : 'text-secondary'}>
                  <FormattedMessage id={user.type} />
                </span>
              </p>
              <p className={'font-size-h6 font-weight-bolder mb-0'}>
                <span className={user.hasMcPayCredentials ? 'text-success' : 'text-danger'}>
                  <FormattedMessage id={user.hasMcPayCredentials ? 'FOR_MC_PAY' : 'NOT_FOR_MC_PAY'} />
                </span>
              </p>
            </div>
            <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
              <div className={'d-flex flex-wrap align-items-center py-2'}>
                <div className={'d-flex align-items-center flex-wrap'}>
                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'CREATED_AT'} />
                    </div>
                    <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                      {intl.formatDate(user.created_at, {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      })}
                    </span>
                  </div>

                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'UPDATED_AT'} />
                    </div>
                    <div className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                      {user.updated_at
                        ? intl.formatDate(user.updated_at, {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                          })
                        : 'Not Available'}
                    </div>
                  </div>

                  <div className={'d-flex justify-content-center flex-column'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'BLOCKED_AT'} />
                    </div>
                    <div className={'btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bolder'}>
                      {user.blocked_at
                        ? intl.formatDate(user.blocked_at, {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                          })
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'separator separator-solid my-7'} />
          {renderButtons()}
          {<ContractorInfo contractor={contractor} />}
        </div>
      </div>
    </>
  );
};
