import {Quill} from 'react-quill';
import {vanillaIntl} from '../../../localization';

const BlockEmbed = Quill.import('blots/block/embed');

export class VideoBlot extends BlockEmbed {
  static create(value) {
    if (typeof value === 'string') {
      value = {
        src: value,
        width: window.prompt(vanillaIntl.formatMessage({id: 'SPECIFY_WIDTH'})),
        height: window.prompt(vanillaIntl.formatMessage({id: 'SPECIFY_HEIGHT'})),
      };
    }

    if (!value.src) {
      return;
    }

    let node = super.create();
    this.updateNode(node, value);
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    return node;
  }

  static updateNode(node, value) {
    node.setAttribute('src', value.src);
    node.setAttribute('height', value.height);
    if (value.width) {
      if (value.width.includes('%')) {
        node.setAttribute('style', `width: ${value.width};`);
      } else {
        node.setAttribute('width', `${value.width}`);
      }
    }
  }

  static formats(node) {
    let format = {};
    if (node.hasAttribute('height')) {
      format.height = node.getAttribute('height');
    }
    if (node.hasAttribute('width') || node.hasAttribute('style')) {
      format.width = node.getAttribute('width');
    }
    return format;
  }

  static value(node) {
    const reg = /\d+%/;
    let width;
    if (node.hasAttribute('style') && reg.test(node.getAttribute('style'))) {
      width = reg[Symbol.match](node.getAttribute('style'))[0];
    } else if (node.hasAttribute('width')) {
      width = node.getAttribute('width');
    }
    return {
      src: node.getAttribute('src'),
      height: node.getAttribute('height'),
      width: width,
    };
  }

  format(name, value) {
    super.format(name, value);
  }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
