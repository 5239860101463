import React from 'react';
import {NavLink} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {checkIsActive} from '../../../../_metronic/_helpers';
import {useLocation} from 'react-router';
import {FormattedMessage} from 'react-intl';

const defaultPath = '/';

export interface MenuItemProps {
  to: string;
  title: string;
  exact?: boolean;
  icon?: string;
  disabled?: boolean;
}

const AsideMenuItem: React.FC<MenuItemProps> = ({to, exact, title, icon, disabled}: MenuItemProps) => {
  const location = useLocation();
  const getClassForActiveItem = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url, exact)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const handleClickLink = (e: React.SyntheticEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <li aria-haspopup='true' className={`menu-item ${getClassForActiveItem(to)}`}>
      <NavLink onClick={handleClickLink} className='menu-link' to={to || defaultPath}>
        {icon && icon.length > 0 && (
          <span className='svg-icon menu-icon'>
            <SVG src={icon} />
          </span>
        )}
        <span className='menu-text'>
          <FormattedMessage id={title} />
        </span>
      </NavLink>
    </li>
  );
};

export default AsideMenuItem;
