import {BaseEndpoint} from '../base-endpoint';
import {IResponseBase} from '../response-contracts/base-response';
import config from '../../../configs/config';
import {IDashboardDataDto} from './IDashboardDataDto';
import {IDashboardAdminDataDto} from './IDashboardAdminDataDto';

export class DashboardApi extends BaseEndpoint {
  public async getUserDashboard(): Promise<IResponseBase<IDashboardDataDto>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/dashboard`);
    return response.data;
  }

  public async getAdminDashboard(): Promise<IResponseBase<IDashboardAdminDataDto>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/admin_dashboard`);
    return response.data;
  }
}
