import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {Preloader} from '../../../components/preloader';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {TransactionStatus} from '../../../api/DTOs/Transaction';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {useLoading} from '../../../hooks/use-loading';
import {capitalizeFirstLetter} from '../../../utils/utils';
import {
  TransactionOperationRelation,
  useTransactionsOperationApi,
} from '../../../hooks/apis/use-transactions-operation-api';
import {TransactionOperation, TransactionOperationType} from '../../../api/DTOs/TransactionOperation';
import {STRING} from '../../../consts';
import {YoutubeInfo} from './platform-info/youtube-info';
import {AccountsInfo} from './accounts-info';
import {AdminRoutes} from '../../../../configs/routes';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ICONS} from '../../../images/images';
import {BootstrapSizes} from '../../../../styles/bootstap-colors';
import {VideoInfo} from './platform-info/video-info';
import {saveAs} from 'file-saver';
import {toast} from 'react-toastify';
import {FEED_ICONS_MAP} from '../../../api/DTOs/FeedIconsMap';

export const FinancialOperationPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const financialOperationId = Number(match.params.id);

  const api = useTransactionsOperationApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    action: false,
    downloadPaymentDocuments: false,
  });

  const [operation, setOperation] = useState<TransactionOperation | null>(null);
  const [relation, setRelation] = useState<TransactionOperationRelation | null>(null);

  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchTransactionInfo().then();
  }, []);

  useEffect(() => {
    if (operation) {
      setBreadcrumb(
        `${CustomFormatter.customFormatMoney({
          sum: operation.from_value,
          currency: operation.from_currency,
        })} ${STRING.RIGHT_ARROW} ${operation.to_contractor_name}`,
      );
    }
  }, [operation]);

  const fetchTransactionInfo = async () => {
    try {
      startLoading('page');
      const result = await api.get(financialOperationId);
      setOperation(result.item);
      setRelation(result.relations);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const getPaymentDocuments = async () => {
    try {
      startLoading('downloadPaymentDocuments');
      const res = await api.downloadPaymentDocuments(financialOperationId);
      saveAs(res.content, res.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('downloadPaymentDocuments');
    }
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (operation === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  let statusTextColorVariant;
  switch (operation.transactionStatus) {
    case TransactionStatus.CANCELED:
      statusTextColorVariant = 'warning';
      break;
    case TransactionStatus.CONFIRMED:
      statusTextColorVariant = 'success';
      break;
    case TransactionStatus.WAITING_CONFIRM:
      statusTextColorVariant = 'primary';
      break;
    case TransactionStatus.REJECTED:
      statusTextColorVariant = 'danger';
      break;
  }

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h3 font-weight-boldest mb-3'}>
                <FormattedMessage id={'SUM'} />
                :&nbsp;
                {CustomFormatter.customFormatMoney({sum: operation.from_value, currency: operation.from_currency})}
                <span className={'mx-3'}>{STRING.RIGHT_ARROW}</span>
                {CustomFormatter.customFormatMoney({sum: operation.to_value, currency: operation.to_currency})}
              </p>
              <p className={'font-size-h5 font-weight-bolder mb-1'}>
                <FormattedMessage id={'STATUS'} />:{' '}
                <span className={'text-' + statusTextColorVariant}>
                  <FormattedMessage id={'TRANSACTION_STATUS_' + operation.transactionStatus} />
                </span>
              </p>

              {operation.period_month !== null && operation.period_year !== null && (
                <p className={'font-size-h5 font-weight-bolder mb-1'}>
                  <FormattedMessage id={'PERIOD'} />:{' '}
                  <span>
                    {capitalizeFirstLetter(
                      intl.formatDate(new Date(operation.period_year, operation.period_month - 1), {
                        month: 'long',
                        year: 'numeric',
                      }),
                    )}
                  </span>
                </p>
              )}
              {operation.localized_description && (
                <p className={'font-size-md font-weight-bolder mb-0'}>
                  <FormattedMessage id={'DESCRIPTION'} />: <span>{operation.localized_description}</span>
                </p>
              )}

              {operation.platform && (
                <img
                  src={FEED_ICONS_MAP[operation.platform]}
                  width={48}
                  height={48}
                  alt={'logo'}
                  className={'rounded my-1 mr-2 ml-0'}
                />
              )}
            </div>
            <div>
              <div className={'d-flex justify-content-center flex-column'}>
                <div className={'font-weight-bolder'}>
                  <FormattedMessage id={'CREATED_AT'} />
                </div>
                <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                  {intl.formatDate(operation.created_at, {
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                  })}
                </span>
              </div>
            </div>
          </div>
          <Toolbar
            className={'mt-5 mb-1'}
            items={[
              {
                type: 'LINK',
                to: AdminRoutes.getSpecifyTransactionRoute(operation.id, operation.transaction_id),
                title: intl.formatMessage({id: 'TO_TRANSACTION'}),
                className: 'btn btn-sm btn-light-primary font-weight-bolder text-uppercase',
                icon: ICONS.SHARE,
                iconSize: BootstrapSizes.X1,
              },
              {
                type: 'BUTTON',
                onClick: getPaymentDocuments,
                title: intl.formatMessage({id: 'PAYMENT_DOCUMENTS'}),
                className: 'btn btn-sm btn-text btn-success text-uppercase font-weight-bolder',
                icon: ICONS.DOWNLOAD,
                visible: operation.type == TransactionOperationType.WITHDRAWAL,
                iconSize: BootstrapSizes.X1,
                loading: loadings.downloadPaymentDocuments,
                disabled: loadings.downloadPaymentDocuments,
              },
            ]}
          />
          <div style={{maxWidth: '960px'}} className={'mt-8'}>
            <div className={'font-size-h4 font-weight-boldest my-3'}>
              <FormattedMessage id={'ACCOUNTS'} />
            </div>
            <AccountsInfo fromAccount={relation?.fromAccount ?? null} toAccount={relation?.toAccount ?? null} />
            <YoutubeInfo operation={operation} />
            <VideoInfo operation={operation} />
          </div>
        </div>
      </div>
    </>
  );
};
