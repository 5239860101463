import {EntityId} from '../../../api/base/BaseEntity';
import {useEffect, useState} from 'react';
import {useContractorsApi} from '../../../hooks/apis/use-contractors-api';
import {FinancialAccount, FinancialAccountType} from '../../../api/DTOs/FinancialAccount';
import {useLoading} from '../../../hooks/use-loading';

export function useAccountsInfo(contractorId: EntityId | null | undefined) {
  const [loadings, startLoading, stopLoading] = useLoading({
    accounts: !!contractorId,
  });

  const contractorApi = useContractorsApi();
  const [accounts, setAccounts] = useState<Array<FinancialAccount>>([]);

  useEffect(() => {
    if (contractorId) {
      startLoading('accounts');
      contractorApi
        .get(contractorId)
        .then(res => setAccounts(res.relations.accounts.filter(account => account.type === FinancialAccountType.INNER)))
        .finally(() => {
          stopLoading('accounts');
        });
    } else {
      setAccounts([]);
    }
  }, [contractorId]);

  return {accounts, isAccountLoading: loadings.accounts};
}
