import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason, OpenModalPayload} from '../base-modal/CloseModalEvent';
import {useEntityApi} from '../../../hooks/use-entity-api';
import {EntityId} from '../../../api/base/BaseEntity';
import {Country} from '../../../api/DTOs/Country';
import {ModalCreateEditCountry} from './create-edit-country-modal';
import {PartialNullable} from '../../../../types/types';

interface IModalCreateEditCountryProps {
  modalCreateEditCountryVisible: boolean;

  showCreateEditCountryModal(payload: OpenModalPayload<Country>): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateEditCountryContext = createContext<IModalCreateEditCountryProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalCreateEditCountryProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const api = useEntityApi<Country>({isAdmin: false, prefixUrl: 'countries'});
  const [error, setError] = useState<string | null>();
  const [visible, setVisible] = useState<boolean>(false);

  const [country, setCountry] = useState<PartialNullable<Country>>({});
  const [openPayload, setOpenPayload] = useState<OpenModalPayload<Country> | null>(null);

  const showModal = async (openPayload: OpenModalPayload<Country>) => {
    setVisible(true);
    setOpenPayload(openPayload);
    if (openPayload.type === 'EDIT') {
      setCountry(openPayload.data.entity);
    }
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setCountry({});
    setOpenPayload(null);
    setError(undefined);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    setError(null);
    if (openPayload?.type === 'EDIT') {
      await api.update(country?.id as EntityId, country);
    } else if (openPayload?.type === 'CREATE') {
      await api.create(country);
    }

    if (closeResolver) {
      closeResolver({reason: CloseModalReason.OK});
      closeResolver = null;
    }
    await handleHideModal();
  };

  const value: IModalCreateEditCountryProps = {
    modalCreateEditCountryVisible: visible,
    showCreateEditCountryModal: showModal,
  };

  return (
    <ModalCreateEditCountryContext.Provider value={value}>
      {children}
      <ModalCreateEditCountry
        visible={visible}
        errors={error}
        validationErrors={api.validationErrors}
        country={country}
        onChange={(key, value) => setCountry(prevState => ({...prevState, [key]: value}))}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditCountryContext.Provider>
  );
};

export const useModalCreateEditCountry = () => {
  return useContext(ModalCreateEditCountryContext);
};
