import React, {useEffect, useState} from 'react';
import {UpdateFields, useAdvancedState} from '../../../../../hooks/use-advanced-state';
import {useIntl} from '../../../../../hooks/use-intl';
import {useContractsApi} from '../../../../../hooks/apis/use-contracts-api';
import {useSelectApi} from '../../../../../hooks/use-select-api';
import {CreateEditContractForm} from './create-edit-contract-form';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../../../configs/routes';
import {isValidationException} from '../../../../../utils/api-utils';
import {ApiRequestException} from '../../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {Card, CardBody, CardFooter, CardTitle} from '../../../../../components/card';
import {ICONS} from '../../../../../images/images';
import {Toolbar} from '../../../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../../../hooks/use-loading';
import {useContractorsApi} from '../../../../../hooks/apis/use-contractors-api';
import {SelectOptions} from '../../../../../components/Inputs/InputSelect';
import {Contract} from '../../../../../api/contract-api/Contract';
import {ARRAY} from '../../../../../consts';
import {useLocationQuery} from '../../../../../hooks/use-location-query';
import {prepareChannelDataToRequest} from '../../sup-agreements/create-edit-sup-agreement-page';

export const CreateContractPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const api = useContractsApi();
  const contractorApi = useContractorsApi();
  const [loadings, startLoading, stopLoading] = useLoading({submit: false, ourContractor: true});
  const {selectsOptions, fetchSelectValuesByKey, optionsLoadings} = useSelectApi();
  const [fields, , updateFields] = useAdvancedState<Contract>({is_signed: false}, api.setValidationErrors);
  const [ourContractors, setOurContractors] = useState<SelectOptions>([]);
  const locationQuery = useLocationQuery();

  useEffect(() => {
    if (locationQuery.get('contractorId')) {
      updateFields({contractor_id: locationQuery.get('contractorId')});
    }
  }, [locationQuery]);

  useEffect(() => {
    fetchSelectValuesByKey('contractors').then();
    contractorApi
      .getAll({filters: {is_our: true}})
      .then(res => {
        setOurContractors(res.items.map(c => ({value: c.id, label: c.legal_name as string})));
      })
      .finally(() => stopLoading('ourContractor'));
  }, []);

  useEffect(() => {
    updateFields({add_videos: ARRAY.EMPTY});
  }, [fields.contractor_id]);

  const handleEdit: UpdateFields<Contract> = updatedFields => {
    if (updatedFields instanceof Function) {
      updateFields(updatedFields);
    } else if (updatedFields.contractor_id) {
      updateFields({...updatedFields, add_videos: ARRAY.EMPTY});
    } else {
      updateFields(updatedFields);
    }
  };

  const handleSubmit = async () => {
    try {
      startLoading('submit');
      const createdContract = await api.create({
        ...fields,
        add_youtube_channels: prepareChannelDataToRequest(fields.add_youtube_channels ?? []),
      });
      history.push(AdminRoutes.getSpecifyContractRoute(createdContract.id));
    } catch (e) {
      if (!isValidationException(e as ApiRequestException)) {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submit');
    }
  };

  return (
    <Card>
      <CardTitle>{intl.formatMessage({id: 'CREATE_CONTRACT'})}</CardTitle>
      <CardBody>
        <div style={{maxWidth: '1280px'}}>
          <CreateEditContractForm
            showAlert
            fields={fields}
            updateFields={handleEdit}
            contractors={selectsOptions.contractors}
            loadingContractors={optionsLoadings.contractors}
            validationErrors={api.validationErrors}
            ourContractors={ourContractors}
            loadingOurContractors={loadings.ourContractor}
          />
        </div>
      </CardBody>
      <CardFooter>
        <Toolbar
          items={[
            {
              type: 'BUTTON',
              loading: loadings.submit,
              disabled: loadings.submit,
              title: intl.formatMessage({id: 'SAVE'}),
              className: 'btn btn-light-primary',
              icon: ICONS.DOWNLOAD,
              onClick: handleSubmit,
            },
          ]}
        />
      </CardFooter>
    </Card>
  );
};
