import React from 'react';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: (props: {[P in keyof Contractor]?: Contractor[P]}) => void;
};

export const BankFieldsTransliterated: React.FC<Props> = ({validationErrors, updateFields, contractor}) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_name_en', validationErrors)}>
        <InputText
          value={contractor?.rub_bank_name_en}
          label={intl.formatMessage({id: 'BANK_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('rub_bank_name_en', validationErrors)}
          onChange={e => updateFields({rub_bank_name_en: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'BANK_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>

      <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('rub_bank_address_en', validationErrors)}>
        <InputText
          value={contractor?.rub_bank_address_en}
          label={intl.formatMessage({id: 'BANK_ADDRESS'})}
          hasError={!!getValidationErrorMessage<Contractor>('rub_bank_address_en', validationErrors)}
          onChange={e => updateFields({rub_bank_address_en: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'BANK_ADDRESS'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
    </>
  );
};
