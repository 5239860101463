import {BaseEndpoint} from '../api/base-endpoint';
import {useCallback, useMemo} from 'react';
import config from '../../configs/config';
import {IQueryParams} from '../api/DTOs/IFilterDtos';
import {AxiosRequestConfig} from 'axios';

export function useBaseEndpointApi() {
  const api = useMemo(() => new BaseEndpoint(), []);

  const applyFilters = useCallback((params?: IQueryParams | any) => {
    return api.applyQueryParams(params);
  }, []);

  const get: <T>(url: string, requestConfig?: AxiosRequestConfig) => Promise<T> = useCallback(
    async (url, requestConfig = {}) => {
      const result = await api.get(`${config.BASE_API_URL}/${url}`, requestConfig);
      return result.data as any;
    },
    [],
  );

  const getFile: (url: string) => Promise<{fileName: string; content: Blob}> = useCallback(async url => {
    const response = await api.getFile(`${url}`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }, []);

  const post: <T>(url: string, data?: Record<string, unknown>) => Promise<T> = useCallback(async (url, data = {}) => {
    const result = await api.post(`${config.BASE_API_URL}/${url}`, data);
    return result.data as any;
  }, []);

  return {
    get,
    getFile,
    post,
    applyFilters,
  };
}
