import React, {useEffect, useState} from 'react';
import {KnowledgeBaseApi} from '../../../api/knowledge-base-api/knowledge-base-api';
import {IArticleDto} from '../../../api/knowledge-base-api/IArticleDto';
import {Preloader} from '../../../components/preloader';
import './article-section.scss';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ActiveSidebarSectionInfo} from './sidebar/sidebar';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useIntl} from '../../../hooks/use-intl';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {AppLocale} from '../../../../localization';
import {useAuth} from '../../../hooks/use-auth';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {ICONS} from '../../../images/images';

type Props = {
  fetchSidebarInfo: () => Promise<unknown>;
  setActiveSidebarSection: (data: ActiveSidebarSectionInfo) => void;
};

export const ArticleSection: React.FC<Props> = ({setActiveSidebarSection, fetchSidebarInfo}) => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const matchedArticleId = Number(match.params.id);
  const history = useHistory();
  const {isAdmin} = useAuth();
  const api = new KnowledgeBaseApi();
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState<IArticleDto | null>(null);
  const {showConfirmActionModal} = useModalConfirmAction();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    setLoading(true);
    api
      .getArticle(matchedArticleId)
      .then(result => {
        setActiveSidebarSection({
          type: 'view',
          payload: {articleId: matchedArticleId, chapterId: result.data.item.chapter_id},
        });
        setArticle(result.data.item);
      })
      .catch(e => {
        const err = e as ApiRequestException;
        if (err.errorType === EXCEPTION_TYPE.NOT_FOUND_HTTP_EXCEPTION) {
          history.push(Routes.getKnowledgeBaseRoute());
        } else {
          toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [matchedArticleId]);

  useEffect(() => {
    if (article) {
      setBreadcrumb(intl.locale === AppLocale.RU ? article.title_ru : article.title_en);
    }
  }, [article?.id]);

  const handleDeleteArticle = async () => {
    if (article && (await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_ARTICLE'})))) {
      setActiveSidebarSection({type: 'default', payload: {chapterId: -1}});
      try {
        await api.deleteArticle(article.id);
        await fetchSidebarInfo();
        history.push(Routes.getKnowledgeBaseRoute());
      } catch (e) {
        const err = e as ApiRequestException;
        toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleEditArticle = () => {
    if (!article) {
      return;
    }

    setActiveSidebarSection({
      type: 'edit',
      payload: {
        chapterId: article.chapter_id,
        articleId: article.id,
      },
    });
    history.push(AdminRoutes.getEditArticleRoute(article.id));
  };

  if (article === null || loading) {
    return <Preloader />;
  }

  return (
    <div className={'card card-custom'}>
      <div className={'card-header'}>
        <div className={'card-title'}>{intl.locale === AppLocale.RU ? article.title_ru : article.title_en}</div>
        <Toolbar
          visible={isAdmin}
          className={'card-toolbar'}
          items={[
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'DELETE'}),
              icon: ICONS.DELETE,
              className: 'btn btn-light-danger',
              onClick: handleDeleteArticle,
            },
            {
              type: 'BUTTON',
              title: intl.formatMessage({id: 'EDIT'}),
              icon: ICONS.EDIT,
              className: 'btn btn-light-primary',
              onClick: handleEditArticle,
            },
          ]}
        />
      </div>
      <div className={'card-body article-section'}>
        <div
          className={'html-content'}
          dangerouslySetInnerHTML={{__html: intl.locale === AppLocale.RU ? article.content_ru : article.content_en}}
        />
      </div>
    </div>
  );
};
