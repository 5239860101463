import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {InputNumeric} from '../../Inputs/InputNumeric';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

interface IModalCreateEditProps {
  modalSetRatePercentsVisible: boolean;
  showSetRatePercentsModal: () => Promise<CloseModalEvent<{ratePercent: number | string | null}>>;
}

// @ts-ignore
const ModalSetRatePercentsContext = createContext<IModalCreateEditProps>();

let closeResolver: ((data: CloseModalEvent<{ratePercent: number | string | null}>) => unknown) | null = null;
export const ModalSetRatePercentsProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const [ratePercentValue, setRatePercentValue] = useState<string | number | null>(0);
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = async () => {
    setVisible(true);
    return new Promise<CloseModalEvent<any>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleOkClick = () => {
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.OK, payload: {ratePercent: ratePercentValue}});
      closeResolver = null;
    }

    handleHideModal();
  };

  const handleHideModal = () => {
    setVisible(false);
    setRatePercentValue(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE, payload: {ratePercent: ratePercentValue}});
      closeResolver = null;
    }
  };

  const value: IModalCreateEditProps = {
    modalSetRatePercentsVisible: visible,
    showSetRatePercentsModal: showModal,
  };

  return (
    <ModalSetRatePercentsContext.Provider value={value}>
      {children}
      <BaseModal visible={visible} onSuccess={handleOkClick} onHide={handleHideModal}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CHANGE_ALL_RATE_PERCENTS_MODAL_TITLE'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <InputNumeric
            minNumber={0}
            maxNumber={100}
            step={0.5}
            value={ratePercentValue}
            onChange={setRatePercentValue}
          />
        </ModalBodySlot>
      </BaseModal>
    </ModalSetRatePercentsContext.Provider>
  );
};

export const useModalModalSetRatePercents = () => {
  return useContext(ModalSetRatePercentsContext);
};
