import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {User, UserType} from '../../../api/DTOs/User';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {ValidationErrorsType} from '../../../utils/utils';
import {BootstrapColors} from '../../../../styles/bootstap-colors';
import {PartialNullable} from '../../../../types/types';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {CALLBACK} from '../../../consts';
import {ProfileInformationFields} from '../../../pages/profile/profile-information-fields';
import {AllSelectOptions, SelectOptionsLoadings} from '../../../hooks/use-select-api';

export type EditUserFormFields = PartialNullable<User>;

interface IModalEditUserProps {
  visible: boolean;
  error?: string | null;
  validationErrors?: ValidationErrorsType;
  fields: EditUserFormFields;
  updateFields: UpdateFields<EditUserFormFields>;
  options?: AllSelectOptions;
  selectOptionsLoading?: SelectOptionsLoadings;

  onHide(): void;

  onOkClick(): any;
}

export const ModalEditUser: React.FC<IModalEditUserProps> = ({
  visible,
  fields,
  updateFields,
  error,
  validationErrors,
  options,
  selectOptionsLoading,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'EDIT'})}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'EDIT_USER'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={BootstrapColors.LIGHT_DANGER}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ProfileInformationFields
                fieldsData={fields}
                validationErrors={validationErrors}
                updateFields={updateFields}
                selectOptionsLoading={selectOptionsLoading}
                options={options}
              />

              <InputCheckbox
                disabled
                label={intl.formatMessage({id: 'ADMIN_PRIVILEGES'})}
                value={fields.type === UserType.ADMIN}
                onChange={CALLBACK.EMPTY}
              />
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
