import React from 'react';
import SVG from 'react-inlinesvg';

const ICONS = {
  UP: require('../../../../app/images/svg/Up.svg'),
};

export function ScrollTop() {
  return (
    <div id='kt_scrolltop' className='scrolltop'>
      <span className='svg-icon'>
        <SVG src={ICONS.UP} />
      </span>{' '}
    </div>
  );
}
