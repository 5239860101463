import React from 'react';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {InputText} from '../../../../../../components/Inputs/InputText';
import {useIntl} from '../../../../../../hooks/use-intl';
import {PartialNullable, UpdateEntity} from '../../../../../../../types/types';

type Props = {
  validationErrors: ValidationErrorsType<Contractor>;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateEntity<Contractor>;
};

export const EntrepreneurFieldsEn: React.FC<Props> = ({contractor, validationErrors, updateFields}) => {
  const intl = useIntl();
  return (
    <>
      <ValidateErrorWrapper
        message={getValidationErrorMessage<Contractor>('entrepreneur_name_last_en', validationErrors)}>
        <InputText
          value={contractor?.entrepreneur_name_last_en}
          label={intl.formatMessage({id: 'LAST_NAME'})}
          hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_last_en', validationErrors)}
          onChange={e => updateFields({entrepreneur_name_last_en: e.currentTarget.value})}
          placeholder={intl.formatMessage({id: 'LAST_NAME'})}
          classNames={`form-control`}
        />
      </ValidateErrorWrapper>
      <div className={'row'}>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('entrepreneur_name_first_en', validationErrors)}>
            <InputText
              value={contractor?.entrepreneur_name_first_en}
              label={intl.formatMessage({id: 'FIRST_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_first_en', validationErrors)}
              onChange={e => updateFields({entrepreneur_name_first_en: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'FIRST_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'col-6'}>
          <ValidateErrorWrapper
            message={getValidationErrorMessage<Contractor>('entrepreneur_name_middle_en', validationErrors)}>
            <InputText
              value={contractor?.entrepreneur_name_middle_en}
              label={intl.formatMessage({id: 'MIDDLE_NAME'})}
              hasError={!!getValidationErrorMessage<Contractor>('entrepreneur_name_middle_en', validationErrors)}
              onChange={e => updateFields({entrepreneur_name_middle_en: e.currentTarget.value})}
              placeholder={intl.formatMessage({id: 'MIDDLE_NAME'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>
        </div>
      </div>
      <div>
        <ValidateErrorWrapper message={getValidationErrorMessage('entrepreneur_address_en', validationErrors)}>
          <InputText
            value={contractor?.entrepreneur_address_en}
            label={intl.formatMessage({id: 'ADDRESS'})}
            hasError={!!getValidationErrorMessage('entrepreneur_address_en', validationErrors)}
            onChange={e => updateFields({entrepreneur_address_en: e.currentTarget.value})}
            placeholder={intl.formatMessage({id: 'ADDRESS'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>
      </div>
    </>
  );
};
