import React from 'react';
import {useIntl} from '../../../../../../hooks/use-intl';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../../../../utils/utils';
import {PartialNullable} from '../../../../../../../types/types';
import {Contractor} from '../../../../../../api/contractor-api/Contractor';
import {UpdateFields} from '../../../../../../hooks/use-advanced-state';
import {ValidateErrorWrapper} from '../../../../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../../../../components/Inputs/InputText';

type Props = {
  validationErrors: ValidationErrorsType;
  contractor: PartialNullable<Contractor>;
  updateFields: UpdateFields<Contractor>;
};

export const BankFieldsUsd: React.FC<Props> = ({contractor, validationErrors, updateFields}) => {
  const intl = useIntl();
  return <>
    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('usd_ben_name', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_name}
        label={intl.formatMessage({id: 'BENEFICIARY_NAME'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_name', validationErrors)}
        onChange={e => updateFields({usd_ben_name: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BENEFICIARY_NAME'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper message={getValidationErrorMessage<Contractor>('usd_ben_bank_account', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_bank_account}
        label={intl.formatMessage({id: 'BENEFICIARY_ACCOUNT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_bank_account', validationErrors)}
        onChange={e => updateFields({usd_ben_bank_account: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BENEFICIARY_ACCOUNT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_ben_bank_transit_account', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_bank_transit_account}
        label={intl.formatMessage({id: 'BENEFICIARY_TRANSIT_ACCOUNT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_bank_transit_account', validationErrors)}
        onChange={e => updateFields({usd_ben_bank_transit_account: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BENEFICIARY_TRANSIT_ACCOUNT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_ben_bank_name', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_bank_name}
        label={intl.formatMessage({id: 'BANK_NAME'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_bank_name', validationErrors)}
        onChange={e => updateFields({usd_ben_bank_name: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_NAME'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_ben_bank_swift', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_bank_swift}
        label={intl.formatMessage({id: 'SWIFT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_bank_swift', validationErrors)}
        onChange={e => updateFields({usd_ben_bank_swift: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'SWIFT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_ben_bank_address', validationErrors)}>
      <InputText
        value={contractor?.usd_ben_bank_address}
        label={intl.formatMessage({id: 'BANK_ADDRESS'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_ben_bank_address', validationErrors)}
        onChange={e => updateFields({usd_ben_bank_address: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'BANK_ADDRESS'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_intermediate_name', validationErrors)}>
      <InputText
        value={contractor?.usd_intermediate_name}
        label={intl.formatMessage({id: 'INTERMEDIARY_NAME'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_intermediate_name', validationErrors)}
        onChange={e => updateFields({usd_intermediate_name: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'INTERMEDIARY_NAME'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_intermediate_swift', validationErrors)}>
      <InputText
        value={contractor?.usd_intermediate_swift}
        label={intl.formatMessage({id: 'INTERMEDIARY_SWIFT'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_intermediate_swift', validationErrors)}
        onChange={e => updateFields({usd_intermediate_swift: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'INTERMEDIARY_SWIFT'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>

    <ValidateErrorWrapper
      message={getValidationErrorMessage<Contractor>('usd_intermediate_account', validationErrors)}>
      <InputText
        value={contractor?.usd_intermediate_account}
        label={intl.formatMessage({id: 'INTERMEDIARY_ACCOUNT_NUMBER'})}
        hasError={!!getValidationErrorMessage<Contractor>('usd_intermediate_account', validationErrors)}
        onChange={e => updateFields({usd_intermediate_account: e.currentTarget.value})}
        placeholder={intl.formatMessage({id: 'INTERMEDIARY_ACCOUNT_NUMBER'})}
        classNames={`form-control`}
      />
    </ValidateErrorWrapper>
  </>;
};
