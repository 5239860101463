import React, {createContext, useContext, useEffect, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/BaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {CreateUserFormFields, ModalCreateUser} from './modal-create-user';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ValidationException} from '../../../api/exceptions/ValidationException';
import {EntityId} from '../../../api/base/BaseEntity';
import {useUserApi} from '../../../hooks/apis/use-user-api';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {UserType} from '../../../api/DTOs/User';
import {useSelectApi} from '../../../hooks/use-select-api';

interface IModalCreateUserContext {
  modalCreateUserVisible: boolean;

  showCreateUserModal(): Promise<CloseModalEvent<EntityId>>;
}

// @ts-ignore
const ModalCreateUserContext = createContext<IModalCreateUserContext>();

let closeResolver: ((data: CloseModalEvent<EntityId>) => any) | null = null;
export const ModalCreateUserProvider = ({children}: any) => {
  const intl = useIntl();

  const api = useUserApi();
  const {fetchSelectValuesByKey, selectsOptions, optionsLoadings} = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const [user, setUser, updateUserFields] = useAdvancedState<CreateUserFormFields>(
    {type: UserType.USER},
    api.setValidationErrors,
  );

  useEffect(() => {
    if (visible) {
      fetchSelectValuesByKey('contractors').then();
    }
  }, [visible]);

  const showModal = async () => {
    setVisible(true);
    return new Promise<CloseModalEvent<EntityId>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setUser({});
    setError(null);
    api.setValidationErrors(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      const createdUser = await api.create(user);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: createdUser.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        api.setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalCreateUserContext = {
    modalCreateUserVisible: visible,
    showCreateUserModal: showModal,
  };

  return (
    <ModalCreateUserContext.Provider value={value}>
      {children}
      <ModalCreateUser
        visible={visible}
        error={error}
        fieldsData={user}
        selectOptionsLoading={optionsLoadings}
        selectOptions={selectsOptions}
        updateFields={updateUserFields}
        validationErrors={api.validationErrors}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateUserContext.Provider>
  );
};

export const useModalCreateUser = () => {
  return useContext(ModalCreateUserContext);
};
