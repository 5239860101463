import {vanillaIntl as Intl} from './I18nProvider';
import {Currency} from '../app/api/DTOs/Transaction';

export class CustomFormatter {
  public static formatMoney(sum: number | string | undefined | null, currency = 'USD', stub = 'N/A') {
    if (sum === null || sum == undefined) {
      return stub;
    }

    return Intl.formatNumber(Number(sum), {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });
  }

  public static customFormatMoney({
                                    currency = Currency.USD,
                                    sum,
                                    stub = 'N/A',
                                    signDisplay = 'never',
                                    maximumFractionDigits,
                                  }: {
    sum: number;
    currency?: Currency;
    signDisplay?: 'always' | 'never';
    stub?: string;
    maximumFractionDigits?: number,
  }) {
    if (sum === null || sum == undefined) {
      return stub;
    }

    if (maximumFractionDigits == null) {
      if (Math.abs(sum) < 0.000099) {
        maximumFractionDigits = 5;
      } else if (Math.abs(sum) < 0.00099) {
        maximumFractionDigits = 4;
      } else if (Math.abs(sum) < 0.0099) {
        maximumFractionDigits = 3;
      }
    }


    return Intl.formatNumber(Number(sum), {
      style: 'currency',
      currency,
      signDisplay,
      maximumFractionDigits,
    });
  }

  public static formatPercent(sum: number | string | undefined | null, maximumFractionDigits = 0) {
    if (sum === null || sum == undefined) {
      return 'N/A';
    }

    return Intl.formatNumber(Number(sum), {style: 'percent', maximumFractionDigits});
  }

  public static formatDateTimeWithFullMonth(date: string | undefined | null) {
    if (!date) {
      return 'N/A';
    }

    return Intl.formatDate(date, {month: 'long', day: 'numeric', minute: 'numeric', hour: 'numeric'});
  }

  public static formatDateWithFullMonthAndYear(date: string | Date | null | undefined) {
    if (!date) {
      return 'N/A';
    }

    return Intl.formatDate(date, {month: 'long', year: 'numeric'});
  }

  public static formatDateWithFullMonth(date: string | Date | undefined | null) {
    if (!date) {
      return 'N/A';
    }

    return Intl.formatDate(date, {month: 'long', day: 'numeric'});
  }

  public static formatDate_MM_YYYY(date: string | undefined | Date | null) {
    if (!date) {
      return 'N/A';
    }

    return Intl.formatDate(date, {month: 'long', year: 'numeric'});
  }
}
