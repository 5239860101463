import {useRef} from 'react';
import {EntityApiHookParams, useEntityApi} from '../use-entity-api';
import {Video, VideoHistoryRecord} from '../../api/DTOs/Video';
import {FileDto} from '../../api/file-api/IFileDto';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseItemResponse, BaseItemsResponse} from '../../api/base/base-crud-api-response';
import {Artist} from '../../api/DTOs/Artist';
import {Contractor} from '../../api/contractor-api/Contractor';
import {Country} from '../../api/DTOs/Country';
import {Genre} from '../../api/DTOs/Genre';
import {User} from '../../api/DTOs/User';
import {Feed} from '../../api/DTOs/Feed';
import {EntityId} from '../../api/base/BaseEntity';
import {PartialNullable} from '../../../types/types';
import { Contract } from "../../api/contract-api/Contract";

export type VideoRelations = {
  artists: Array<Artist>;
  contractor: Contractor;
  activeContract: Contract;
  countries: Array<Country>;
  genres: Array<Genre>;
  feeds: Array<Feed>;
  user?: User;

  originalVideoFile: FileDto;
  watermarkedVideoFile: FileDto | null;

  imageFile: FileDto | null;
  watermarkedImageFile: FileDto | null;
};

export type VideoHistoryRelation = {
  user: User;
  video: Video;
};

export type VideoPrivileges = {
  can_edit: boolean;
  can_ready_to_publish: boolean;
  can_update_watermarked_files: boolean;
  can_delete: boolean;
  can_reject_publish: boolean;
  can_confirm_publish: boolean;
  can_set_youtube_video_id: boolean;
};

export function useVideosApi() {
  const {current: config} = useRef({
    prefixUrl: 'videos',
    isAdmin: false,
    uploadConfig: {
      signedUrlRoute: 'video-image-pre-signed-url',
    },
    multipartUploadConfig: {
      initiateUploadRoute: 'video-start-multipart-upload-pre-signed-url',
      signedUrlsRoute: 'video-upload-part-pre-signed-url',
      completeUploadRoute: 'video-complete-multipart-upload',
    },
  });
  return {
    ...useEntityApi<Video, VideoRelations, VideoPrivileges>(config),
    ...useAdvancedVideosApi(config),
  };
}

export function useAdvancedVideosApi(params: EntityApiHookParams) {
  const baseApi = useBaseEndpointApi();
  const getAutoCodeFields = async () => {
    const res = await baseApi.get<BaseItemResponse<Array<keyof Video>>>(`${params.prefixUrl}/auto-code-fields`);
    return res.data;
  };

  const generateAutoCode = async (payload: Record<string, string>) => {
    const res = await baseApi.post<BaseItemResponse<{code: string}>>(`${params.prefixUrl}/generate-auto-code`, {
      fields: {
        ...payload,
      },
    });
    return res.data;
  };

  const readyToPublish = async (videoId: EntityId) => {
    const res = await baseApi.post<BaseItemResponse<any>>(`${params.prefixUrl}/${videoId}/ready-to-publish`, {});
    return res.data;
  };

  const confirmPublication = async (videoId: EntityId) => {
    const res = await baseApi.post<BaseItemResponse<any>>(`${params.prefixUrl}/${videoId}/confirm-publish`, {});
    return res.data;
  };

  const setYoutubeVideoId = async (videoId: EntityId, youtubeVideoId: string) => {
    const res = await baseApi.post<BaseItemResponse<any>>(`${params.prefixUrl}/${videoId}/youtube-video-id`, {
      fields: {
        y_video_id: youtubeVideoId,
      },
    });
    return res.data;
  };

  const rejectPublication = async (videoId: EntityId, reject_reason: string) => {
    const res = await baseApi.post<BaseItemResponse<any>>(`${params.prefixUrl}/${videoId}/reject-publish`, {
      fields: {
        reject_reason,
      },
    });
    return res.data;
  };

  const updateWatermarkedFiles = async (
    videoId: EntityId,
    payload: PartialNullable<Record<'watermarked_image_file_id' | 'watermarked_video_file_id', EntityId>>,
  ) => {
    const res = await baseApi.post<BaseItemResponse<any>>(`${params.prefixUrl}/${videoId}/update-watermarked-files`, {
      fields: {
        ...payload,
      },
    });
    return res.data;
  };

  const getActionHistory = async (videoId: EntityId) => {
    const res = await baseApi.get<BaseItemsResponse<VideoHistoryRecord, VideoHistoryRelation>>(
      `${params.prefixUrl}/${videoId}/actions-history`,
    );
    return res.data;
  };

  return {
    generateAutoCode,
    getAutoCodeFields,
    getActionHistory,
    readyToPublish,
    rejectPublication,
    setYoutubeVideoId,
    confirmPublication,
    updateWatermarkedFiles,
  };
}
