import React from 'react';
import {useToggle} from '../hooks/use-toggle';
import cn from 'classnames';

type Props = {
  defaultValue: boolean;
  renderToggle: string | ((toggleValue: () => void, value: boolean) => JSX.Element);
  className?: { container?: string; content?: string };
};

export const Toggler: React.FC<Props> = ({children, className, defaultValue, renderToggle}) => {
  const [value, toggleValue] = useToggle(defaultValue);
  return (
    <>
      <div className={cn('cursor-pointer', className?.container)} onClick={toggleValue}>
        {typeof renderToggle === 'function' ? (
          renderToggle(toggleValue, value)
        ) : (
          <span className={'mb-10'}>{renderToggle}</span>
        )}
      </div>
      <div className={cn(className?.content, {'d-none': !value})}>{children}</div>
    </>
  );
};
