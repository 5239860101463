import {ApiRequestException} from './axios-instance';
import {EXCEPTION_TYPE} from './exceptions/BaseException';

export function isApiException(error: Error | ApiRequestException) {
  return !!(error as ApiRequestException).errorType;
}

export function isValidationException(error: Error | ApiRequestException) {
  if (!isApiException(error)) {
    return false;
  }

  return (error as ApiRequestException).errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION;
}

export function isNotFoundException(error: Error | ApiRequestException) {
  if (!isApiException(error)) {
    return false;
  }

  return (error as ApiRequestException).errorType === EXCEPTION_TYPE.NOT_FOUND_HTTP_EXCEPTION;
}
