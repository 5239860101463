import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {UserActionInSystem} from '../../../api/user-api/UserActionInSystem';
import {UserAction} from './user-action';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {useUserApi} from '../../../hooks/apis/use-user-api';
import {useSelectApi} from '../../../hooks/use-select-api';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const UsersActionsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = useUserApi();
  const selectApi = useSelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [usersActions, setUsersActions] = useState<Array<UserActionInSystem>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'user_actions_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: selectApi.optionsLoadings,
      allSelectValues: selectApi.selectsOptions,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status_code',
          options: [200, 204, 400, 401, 500].map(s => ({label: s.toString(), value: s.toString()})),
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
      ],
    });
  }, [selectApi.selectsOptions, selectApi.optionsLoadings]);

  useEffect(() => {
    selectApi.fetchSelectValuesByKey('users').then();
  }, []);

  const fetchUsersActions = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getUsersActions(filters);
      setUsersActions(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const renderUsersActions = () => {
    if (usersActions.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <>
        <div className={'row'}>
          {usersActions.map(action => {
            return (
              <div key={action.id} className={'col-12'}>
                <UserAction action={action} />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      className={{cardBody: 'bg-light'}}
      fetchData={fetchUsersActions}>
      {renderUsersActions()}
    </BaseListPage>
  );
};
