import {useIntl} from '../../../../hooks/use-intl';
import React, {PropsWithChildren, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../../base-modal/CloseModalEvent';
import {PartialNullable} from '../../../../../types/types';
import {EXCEPTION_TYPE} from '../../../../api/exceptions/BaseException';
import {ValidationException} from '../../../../api/exceptions/ValidationException';
import {useAdvancedState} from '../../../../hooks/use-advanced-state';
import {ApiRequestException} from '../../../../api/axios-instance';
import {CreateWithdrawalTransactionsFields, useTransactionsApi} from '../../../../hooks/apis/use-transactions-api';
import {useSelectApi} from '../../../../hooks/use-select-api';
import {ModalCreateWithdrawalTransaction} from './modal-create-withdrawal-transaction';
import {Contractor} from '../../../../api/contractor-api/Contractor';
import {EntityId} from '../../../../api/base/BaseEntity';
import {useAccountsInfo} from '../use-accounts-info';

export type PartOfContractorForCreateTransaction = Pick<Contractor, 'id' | 'calculated_name'>;

interface ContextProps {
  showCreateWithdrawalTransactionModal(
    partOfContractor?: PartOfContractorForCreateTransaction,
  ): Promise<CloseModalEvent<EntityId>>;
}

// @ts-ignore
const ModalCreateTransactionContext = React.createContext<ContextProps>();

let closeResolver: ((data: CloseModalEvent<EntityId>) => unknown) | null = null;
export const ModalCreateWithdrawalTransactionProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const api = useTransactionsApi();
  const {fetchSelectValuesByKey, optionsLoadings, selectsOptions} = useSelectApi();
  const [error, setError] = useState<string | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [entity, setEntity, updateEntityFields] = useAdvancedState<PartialNullable<CreateWithdrawalTransactionsFields>>(
    {},
    api.setValidationErrors,
  );
  const {accounts, isAccountLoading} = useAccountsInfo(entity.contractor_id);

  const showModal = async (partOfContractor?: PartOfContractorForCreateTransaction) => {
    setVisible(true);
    if (partOfContractor) {
      updateEntityFields({contractor_id: partOfContractor.id});
    }

    return new Promise<CloseModalEvent<EntityId>>(resolve => {
      fetchSelectValuesByKey('contractors');
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    api.setValidationErrors(null);
    setEntity({});
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      api.setValidationErrors(null);
      const result = await api.createTransactionWithdrawal(entity);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        api.setValidationErrors((err.innerException as ValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const contextValue: ContextProps = {
    showCreateWithdrawalTransactionModal: showModal,
  };

  return (
    <ModalCreateTransactionContext.Provider value={contextValue}>
      {children}
      <ModalCreateWithdrawalTransaction
        visible={visible}
        loadings={{contractors: optionsLoadings.contractors, accounts: isAccountLoading}}
        error={error}
        accounts={accounts}
        validationErrors={api.validationErrors}
        contractors={selectsOptions.contractors}
        entity={entity}
        onChange={updateEntityFields}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalCreateTransactionContext.Provider>
  );
};

export const useModalCreateWithdrawalTransaction = (): ContextProps => {
  return useContext(ModalCreateTransactionContext);
};
