import React from 'react';
import {FormattedDate, FormattedTime, useIntl} from 'react-intl';
import {User} from '../../../api/DTOs/User';
import {UserAction} from './users-page';
import {Link} from 'react-router-dom';
import {AdminRoutes, CommonRoutes} from '../../../../configs/routes';
import {ActionButton} from '../../../components/table-actions-button';
import {EntityId} from '../../../api/base/BaseEntity';
import {TableUtils} from '../../../components/table-utils';
import {ManyPrivileges, ManyRelations} from '../../../api/base/base-crud-api-response';
import {UserPrivileges, UserRelations} from '../../../hooks/apis/use-user-api';
import {Table} from '../../../components/table';
import {useAuth} from '../../../hooks/use-auth';
import cn from 'classnames';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {BootstrapColors} from '../../../../styles/bootstap-colors';

interface IUsersTableProps {
  users: Array<User>;
  ownerUser?: User | null;
  privileges: ManyPrivileges<UserPrivileges> | null;
  relations: ManyRelations<UserRelations> | null;

  onSelect?(userId: EntityId, action: UserAction): Promise<boolean>;
}

export const UsersTable = ({users, privileges, ownerUser, relations, onSelect}: IUsersTableProps) => {
  const intl = useIntl();
  const {
    privileges: {can_manage_contractors},
  } = useAuth();

  return (
    <Table
      data={users}
      columns={[
        {
          id: 'Email',
          Header: intl.formatMessage({id: 'EMAIL'}),
          maxWidth: 250,
          Cell: ({value}) => {
            const isOwner = ownerUser != null && value.email === ownerUser.email;
            return TableUtils.renderBaseContent(
              <span className={cn({'font-weight-boldest text-underline': isOwner})}>
                <Link to={CommonRoutes.getSpecifyUserManagementRoute(value.id)} className={'mr-2'}>
                  {value.email}
                </Link>
                {isOwner && (
                  <>
                    <span>({intl.formatMessage({id: 'CONTRACTOR_OWNER'})})</span>
                  </>
                )}
              </span>,
            );
          },
        },
        {
          id: 'Name',
          Header: intl.formatMessage({id: 'USER_NAME'}),
          maxWidth: 250,
          Cell: ({value}) => {
            let name = '';
            if (value.name_first) {
              name += `${value.name_first} `;
            }

            if (value.name_last) {
              name += `${value.name_last} `;
            }

            if (value.name_middle) {
              name += `${value.name_middle} `;
            }
            return TableUtils.renderBaseContent(
              name != '' ? (
                <Link to={CommonRoutes.getSpecifyUserManagementRoute(value.id)}>{name}</Link>
              ) : (
                intl.formatMessage({id: 'NOT_APPLICABLE'})
              ),
            );
          },
        },
        {
          id: 'Contractor',
          Header: intl.formatMessage({id: 'CONTRACTOR'}),
          maxWidth: 250,
          minWidth: 200,
          visible: can_manage_contractors && relations != null,
          Cell: ({value}) => {
            if (!value.contractor_id) {
              return TableUtils.renderBaseContent(intl.formatMessage({id: 'NOT_APPLICABLE'}));
            }

            const contractor = relations?.contractor?.[value.contractor_id];
            return TableUtils.renderBaseContent(
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>
                {contractor?.calculated_name}
              </Link>,
            );
          },
        },
        {
          id: 'McPay',
          Header: intl.formatMessage({id: 'MC_PAY'}),
          maxWidth: 100,
          minWidth: 100,
          forceApplySize: true,
          Cell: ({value}) => {
            return (
              <>
                {value.hasMcPayCredentials ? (
                  <Svg src={ICONS.CHECK} color={BootstrapColors.SUCCESS} />
                ) : (
                  <Svg src={ICONS.CROSS} color={BootstrapColors.DANGER} />
                )}
              </>
            );
          },
        },
        {
          Header: intl.formatMessage({id: 'CREATED_AT'}),
          maxWidth: 100,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <>
                <FormattedDate value={value.created_at} /> <FormattedTime value={value.created_at} />
              </>,
            );
          },
        },
        {
          Header: intl.formatMessage({id: 'BLOCKED_AT'}),
          maxWidth: 100,
          Cell: ({value}) => {
            if (value.blocked_at == null) {
              return TableUtils.renderBaseContent(intl.formatMessage({id: 'NOT_APPLICABLE'}));
            }

            return TableUtils.renderBaseContent(
              <>
                <FormattedDate value={value.blocked_at} /> <FormattedTime value={value.blocked_at} />
              </>,
            );
          },
        },
        {
          id: 'Action',
          maxWidth: 50,
          visible: onSelect != null,
          Cell: ({value}) => {
            const currentUserPrivileges = privileges?.[value.id] ?? null;
            return TableUtils.renderActionButton(
              <ActionButton
                actions={[
                  {
                    disabled: !currentUserPrivileges?.can_reset_password,
                    title: intl.formatMessage({id: 'RESET_PASSWORD'}),
                    value: UserAction.RESET_PASSWORD,
                  },
                  {
                    disabled: !currentUserPrivileges?.can_delete,
                    title: intl.formatMessage({id: 'DELETE'}),
                    value: UserAction.DELETE,
                  },
                  {
                    disabled: !currentUserPrivileges?.can_unblock,
                    title: intl.formatMessage({id: 'UNBLOCK'}),
                    value: UserAction.UNBLOCK,
                  },
                  {
                    disabled: !currentUserPrivileges?.can_block,
                    title: intl.formatMessage({id: 'BLOCK'}),
                    value: UserAction.BLOCK,
                  },
                  {
                    disabled: !currentUserPrivileges?.can_update,
                    title: intl.formatMessage({id: 'EDIT'}),
                    value: UserAction.EDIT,
                  },
                ]}
                onSelect={action => {
                  if (onSelect) {
                    return onSelect(value.id, action).then();
                  } else return 1 as any;
                }}
              />,
            );
          },
        },
      ]}
    />
  );
};
