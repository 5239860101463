import React, {CSSProperties} from 'react';
import {Card, CardBody, CardProps} from '../../components/card';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {useIntl} from '../../hooks/use-intl';
import {ICONS} from '../../images/images';
import {EntityId} from '../../api/base/BaseEntity';
import {useLoading} from '../../hooks/use-loading';
import {ContractorDocument} from '../../api/DTOs/ContractorDocument';

type Props = {
  item: ContractorDocument;
  onDownload: (id: EntityId) => Promise<void>;
  style?: CSSProperties;
  cardProps?: Partial<CardProps>;
};

export const ContractorDocumentCard: React.FC<Props> = ({item, style, cardProps, onDownload}) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    downloading: false,
  });

  const handleDownload = async () => {
    startLoading('downloading');
    onDownload(item.id).finally(() => stopLoading('downloading'));
  };

  return (
    <Card key={item.id} style={style} hideFooter hideHeader {...cardProps}>
      <CardBody>
        <div className={'d-flex flex-row justify-content-between align-items-center'}>
          <div className={'d-flex flex-row align-items-center'}>
            <div className={'mr-5'}>
              <span className='symbol symbol-65 symbol-light-success'>
                <span className='symbol-label font-size-h1 font-weight-bold'>{item.title[0]}</span>
              </span>
            </div>
            <div>
              <div className={'font-weight-boldest font-size-lg'}>{item.title}</div>
              <div className={'font-size-sm text-muted'}>
                {intl.formatDate(item.created_at)} {intl.formatTime(item.created_at)}
              </div>
            </div>
          </div>
          <Toolbar
            items={[
              {
                type: 'ICON',
                spin: loadings.downloading,
                icon: loadings.downloading ? ICONS.SPINNER : ICONS.DOWNLOAD,
                className: 'svg-icon-2x cursor-pointer',
                onClick: handleDownload,
              },
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
};
