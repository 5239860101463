import {BaseEndpoint} from './base-endpoint';
import {ISelectResponse} from './response-contracts/yotutube-channel-contracts';
import config from '../../configs/config';
import {Filter, IQueryParams} from './DTOs/IFilterDtos';

export class SelectApi extends BaseEndpoint {
  public async accounts(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/accounts/select`, filters);
    return response.data;
  }

  public async videos(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/videos/select`, filters);
    return response.data;
  }

  public async reportPeriods(filter?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/report-periods/select`, filter);
    return response.data;
  }

  public async addendums(params?: IQueryParams): Promise<ISelectResponse> {
    const queries = this.applyQueryParams(params);
    const response = await this.getSelectValues(`${config.BASE_API_URL}/addendums/select?${queries}`);
    return response.data;
  }

  public async users(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/users/select`, filters);
    return response.data;
  }

  public async contractors(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/contractors/select`, filters);
    return response.data;
  }

  public async ourContractors(): Promise<ISelectResponse> {
    const filters: Filter = {
      is_our: true,
    };
    const response = await this.getSelectValues(`${config.BASE_API_URL}/contractors/select`, filters);
    return response.data;
  }

  public async adminContracts(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/contracts/select`, filters);
    return response.data;
  }

  public async youtubeChannels(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/youtube-channels/select`, filters);
    return response.data;
  }

  public async artists(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/artists/select`, filters);
    return response.data;
  }

  public async genres(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/genres/select`, filters);
    return response.data;
  }

  public async countries(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/countries/select`, filters);
    return response.data;
  }

  public async selfContracts(filters?: Filter): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/contracts/select`, filters);
    return response.data;
  }
}
