import {COMPOSITE_FILTER_SEPARATOR, Filter, FilterType, ManuallyQueryConfig} from './filters';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {QueryParamConfigMap, StringParam, UrlUpdateType, useQueryParams} from 'use-query-params';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {isEqual, isObject} from '../../utils/utils';

const EMPTY_NULL_VALUE = null;
const EMPTY_UNDEFINED_VALUE = undefined;
const EMPTY_OBJECT_VALUE = {};
const EMPTY_STRING_VALUE = '';
const isEmptyValue = (value: any) =>
  value === EMPTY_UNDEFINED_VALUE ||
  value === EMPTY_NULL_VALUE ||
  value === EMPTY_STRING_VALUE ||
  isEqual(value, EMPTY_OBJECT_VALUE);

export const useQueryParamsFilters = (configurations: Array<Filter>) => {
  const tryGetFilterConfigByName = (paramFilterName: string): Filter | null => {
    return configurations.find(filter => filter.name === paramFilterName) ?? null;
  };

  const tryGetFilterQueryConfigFromFilterConfig = (filterConfig: Filter): ManuallyQueryConfig<any> | null => {
    return filterConfig?.queryConfig ?? null;
  };

  const configForQueryLib = useMemo<QueryParamConfigMap>(() => {
    return configurations.reduce((configForQueryLib, filter) => {
      const {name, queryConfig} = filter;
      if (queryConfig) {
        return {...configForQueryLib, [queryConfig.name]: queryConfig.type};
      }

      if (name) {
        return {...configForQueryLib, [name]: StringParam};
      }

      return configForQueryLib;
    }, {});
  }, []);

  // Query-параметры в виде, предоставляемом библиотекой.
  const [queries, setQueries] = useQueryParams({...configForQueryLib});

  // Преобразовать Query-параметры в Пару Ключ-Значение.
  const buildObjectFromQueries = useCallback(() => {
    const applyFilters: Record<string, any> = {};
    const queriesDto: Record<string, any> = {};
    Object.keys(queries).forEach(filterName => {
      if (isEmptyValue(queries[filterName])) {
        return;
      }

      // Проверяем, что это не композитный фильтр
      const config = tryGetFilterConfigByName(filterName);
      if (config === null) {
        return;
      }

      queriesDto[filterName] = queries[filterName];
      if (config.type !== FilterType.COMPOSITE) {
        applyFilters[filterName] = queries[filterName];
      } else {
        // Требуется провести валидацию на обязательность заполненных в составном фильтре полей.
        // Если валидация не пройдена, то фильтр не должен помещаться в массив applyFilters.
        const requiredSubItems = config.items.filter(subFilterConfig => subFilterConfig.required);
        const isComplete = requiredSubItems.every(requiredSubItem => {
          const [, itemKey] = requiredSubItem.name.toString().split(COMPOSITE_FILTER_SEPARATOR);
          return !isEmptyValue(queries[filterName][itemKey]);
        });

        if (isComplete) {
          applyFilters[filterName] = queries[filterName];
        }
      }
    });
    return {
      applyFilters,
      queriesDto,
    };
  }, [queries]);

  // Пара (Название параметра фильтрации) -> (Значение параметра фильтрации)
  const [queriesDto, setQueriesDto] = useState(buildObjectFromQueries().queriesDto);

  // Не все фильтры указанные в строке поиска должны уходить вместе с запросом.
  // Для составных фильтров есть условия по которым определяется попадет ли он в запрос.
  // Содержит все фильтры, которые должны попасть в запрос.
  // (если нет композитных фильтров, то является аналогом queriesDto)
  const [applyFilters, setApplyFilters] = useState(buildObjectFromQueries().applyFilters);

  useEffect(() => {
    if (queries) {
      setQueriesDto(buildObjectFromQueries().queriesDto);
      setApplyFilters(buildObjectFromQueries().applyFilters);
    }
  }, [queries]);

  const updateFilter = (paramFilterName: string, value: any, urlUpdateType: UrlUpdateType) => {
    const filterConfig = tryGetFilterConfigByName(paramFilterName);
    if (filterConfig === null) {
      return;
    }

    let preparedValue = value;
    if (isEmptyValue(value)) {
      preparedValue = EMPTY_UNDEFINED_VALUE;
      const queryConfig = tryGetFilterQueryConfigFromFilterConfig(filterConfig);
      if (queryConfig !== null && queryConfig.clearableDefaultValue !== undefined) {
        preparedValue = queryConfig.clearableDefaultValue ? EMPTY_NULL_VALUE : EMPTY_UNDEFINED_VALUE;
      }
    } else if (filterConfig.type === FilterType.COMPOSITE) {
      const valueWithoutEmptyFields = Object.keys(value).reduce((acc, subFilterKey) => {
        if (isEmptyValue(value[subFilterKey])) {
          return acc;
        }

        return {...acc, [subFilterKey]: value[subFilterKey]};
      }, {});

      preparedValue = isEmptyValue(valueWithoutEmptyFields) ? EMPTY_UNDEFINED_VALUE : valueWithoutEmptyFields;
    }

    setQueries({[paramFilterName]: preparedValue}, urlUpdateType);
  };

  return {
    filters: applyFilters,
    queriesNameValueMap: queriesDto,
    update: updateFilter,
  };
};

export const useFilters = (
  filterConfig: Array<Filter>,
  sortConfig: Array<Filter>,
  nonVisualFilterConfig: Array<Filter>,
) => {
  const {
    filters: applyFilters,
    queriesNameValueMap: filtersQueriesNameValueMap,
    update: updateFilters,
  } = useQueryParamsFilters(filterConfig);
  const {
    filters: applySorts,
    queriesNameValueMap: sortsQueriesNameValueMap,
    update: updateSort,
  } = useQueryParamsFilters(sortConfig);
  const {
    filters: applyVirtualFilters,
    queriesNameValueMap: nonVisualFiltersQueriesNameValueMap,
    update: updateNonVisualFilters,
  } = useQueryParamsFilters(nonVisualFilterConfig);

  const persistentConfigs = useRef([...filterConfig, ...sortConfig, ...nonVisualFilterConfig]);
  const persistentFilterNamesWhichNeedExtractFields = useRef(
    persistentConfigs.current.filter(filter => filter.extractFields).map(filter => filter.name),
  );

  const prepareApplyFilters = (nameValueMap: Record<string, any>) => {
    return Object.keys(nameValueMap).reduce((acc, filterName) => {
      if (
        persistentFilterNamesWhichNeedExtractFields.current.includes(filterName) &&
        isObject(nameValueMap[filterName])
      ) {
        return {...acc, ...nameValueMap[filterName]};
      }
      return {...acc, [filterName]: nameValueMap[filterName]};
    }, {});
  };

  const applyQueryParamsDto = useMemo<IQueryParams>(() => {
    return {
      filters: prepareApplyFilters(applyFilters),
      sort: prepareApplyFilters(applySorts),
      ...prepareApplyFilters(applyVirtualFilters),
    };
  }, [applyFilters, applyVirtualFilters, applySorts]);

  const updateAllFilters = (paramFilterName: string, value: any, urlUpdateType: UrlUpdateType = 'pushIn') => {
    updateSort(paramFilterName, value, urlUpdateType);
    updateFilters(paramFilterName, value, urlUpdateType);
    updateNonVisualFilters(paramFilterName, value, urlUpdateType);
  };

  return {
    filtersQueriesNameValueMap,
    sortsQueriesNameValueMap,
    nonVisualFiltersQueriesNameValueMap,
    applyQueryParamsDto,
    updateAllFilters,
  };
};
