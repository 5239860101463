import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {
  Contractor,
  ContractorDataValidationStatus,
  UPDATE_CONTRACTOR_COLOR_MAP,
} from '../../../../api/contractor-api/Contractor';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';
import {TableUtils} from '../../../../components/table-utils';

interface IContractorsTableProps {
  contractors: Array<Contractor>;
}

export const ContractorsTable = ({contractors}: IContractorsTableProps) => {
  const intl = useIntl();
  const data = contractors;

  const columns = useMemo(() => {
    const defaultClassName = 'font-weight-bolder';
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        forceApplySize: true,
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: Contractor) => br,
        Cell: ({value}: {value: Contractor}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.id)}>{value.id}</Link>
            </div>
          );
        },
      },
      {
        id: 'Name',
        Header: intl.formatMessage({id: 'NAME'}),
        maxWidth: 500,
        minWidth: 350,
        accessor: (br: Contractor) => br,
        Cell: ({value}: {value: Contractor}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.id)}>{value.calculated_name}</Link>
            </div>
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 250,
        minWidth: 150,
        accessor: (br: Contractor) => br.data_validation_status,
        Cell: ({value}: {value: ContractorDataValidationStatus}) => {
          return TableUtils.renderBaseContent(intl.formatMessage({id: 'UPDATE_CONTRACTOR' + value}), {
            className: `text-${UPDATE_CONTRACTOR_COLOR_MAP[value]}`,
          });
        },
      },
      {
        id: 'Type',
        Header: intl.formatMessage({id: 'TYPE'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: Contractor) => br,
        Cell: ({value}: {value: Contractor}) => {
          return (
            <div className={defaultClassName}>
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.id)}>
                {intl.formatMessage({id: 'CONTRACTOR_TYPE_' + value.type})}
              </Link>
            </div>
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Contractor) => br,
        Cell: ({value}: {value: Contractor}) => {
          return (
            <div className={defaultClassName}>
              {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
            </div>
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
