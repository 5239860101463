import React from 'react';
import {ContractorDataValidationStatus} from '../../../../../api/contractor-api/Contractor';
import {FormattedMessage} from 'react-intl';
import {Svg} from '../../../../../images/svg';
import {ICONS} from '../../../../../images/images';
import {BootstrapColors, BootstrapSizes} from '../../../../../../styles/bootstap-colors';
import {useAuth} from '../../../../../hooks/use-auth';

type Props = {
  title: string;
  match: ContractorDataValidationStatus[];
};

export const StepRejectedRegistration: React.FC<Props> = () => {
  const {contractor} = useAuth();
  return (
    <>
      <div className='text-center text-danger'>
        <h2 className='font-weight-boldest mt-10'>
          <FormattedMessage id={'REGISTRATION_REJECTED_NOTICE'} />
        </h2>
        <p className='font-weight-bolder mb-0'>
          <FormattedMessage id={'REGISTRATION_REJECTED_SUB_NOTICE'} />
        </p>
        <div>
          <p className={'font-weight-bolder'}>
            <FormattedMessage id={'REJECT_REASON'} />:{' '}
            <span className={'save-line-and-tabulation mb-10 font-weight-boldest'}>
              {contractor?.data_validation_reject_reason}
            </span>
          </p>
        </div>
      </div>
      <div className='text-center px-4'>
        <Svg size={BootstrapSizes.X9} color={BootstrapColors.DANGER} src={ICONS.CROSS} />
      </div>
    </>
  );
};
