import React from 'react';
import {ChartMetricTopTrack} from '../../../api/DTOs/Chartmetric/CMArtistMetadata';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {useIntl} from '../../../hooks/use-intl';
import {FormattedNumber} from 'react-intl';

type Props = {
  topTracks: Array<ChartMetricTopTrack>;
};
export const ChartMetricTopTracks: React.FC<Props> = ({topTracks}) => {
  const intl = useIntl();
  return (
    <Card hideFooter className={{body: 'bg-light'}}>
      <CardTitle>{intl.formatMessage({id: 'TOP_TRACKS'})}</CardTitle>
      <CardBody>
        {topTracks.map(track => {
          return (
            <Card hideFooter hideHeader key={track.name} className={{body: 'd-flex align-items-center p-4'}}>
              <CardBody>
                <div className='symbol symbol-sm-100 symbol-lg-100'>
                  <img src={track.image_url} alt={track.name} className={'w-100'} />
                </div>
                <div className={'ml-5'}>
                  <div className={'font-size-h5 font-weight-boldest'}>{track.artist_track_name}</div>
                  <div className={'font-size-md font-weight-bolder'}>{track.album[0].name}</div>
                  <div className={'d-flex mt-2'}>
                    <table className={'mr-10'}>
                      <thead>
                        <tr>
                          <th>
                            <i className={'fab fa-youtube text-danger'} /> YouTube
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {track.latest.youtube_likes != null ? (
                                <FormattedNumber value={track.latest.youtube_likes} notation={'compact'} />
                              ) : (
                                intl.formatMessage({id: 'NOT_APPLICABLE'})
                              )}
                              <i className={'fa fa-thumbs-up ml-2'} />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>
                              {track.latest.youtube_views != null ? (
                                <FormattedNumber value={track.latest.youtube_views} notation={'compact'} />
                              ) : (
                                intl.formatMessage({id: 'NOT_APPLICABLE'})
                              )}
                              <i className={'fa fa-eye ml-2'} />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <i className={'fab fa-spotify text-success'} /> Spotify
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {track.latest.spotify_plays != null ? (
                                <FormattedNumber value={track.latest.spotify_plays} notation={'compact'} />
                              ) : (
                                intl.formatMessage({id: 'NOT_APPLICABLE'})
                              )}
                              <i className={'fa fa-play-circle ml-2'} />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span title={intl.formatMessage({id: 'POPULARITY'})}>
                              {track.latest.spotify_popularity != null ? (
                                <>
                                  <FormattedNumber value={track.latest.spotify_popularity} notation={'compact'} /> / 100
                                </>
                              ) : (
                                intl.formatMessage({id: 'NOT_APPLICABLE'})
                              )}
                              <i className={'fa fa-chart-bar ml-2'} />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          );
        })}
      </CardBody>
    </Card>
  );
};
