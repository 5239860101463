import React, {useCallback, useState} from 'react';
import {EntityId} from '../../../api/base/BaseEntity';
import {useVideosApi, VideoHistoryRelation} from '../../../hooks/apis/use-videos-api';
import {VIDEO_ACTION_COLOR_MAP, VideoHistoryRecord} from '../../../api/DTOs/Video';
import {BaseListPage} from '../../base/base-list-page';
import {useLoading} from '../../../hooks/use-loading';
import {useIntl} from '../../../hooks/use-intl';
import {ManyRelations} from '../../../api/base/base-crud-api-response';
import {toast} from 'react-toastify';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import cn from 'classnames';
import {VideoHistoryMarketingBlock} from '../../marketing-blocks/video-history-marketing-block';

type Props = {
  videoId: EntityId;
};
export const VideoHistoryBlock: React.FC<Props> = ({videoId}) => {
  const intl = useIntl();
  const api = useVideosApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    history: true,
  });

  const [history, setHistory] = useState<Array<VideoHistoryRecord>>([]);
  const [relations, setRelations] = useState<ManyRelations<VideoHistoryRelation> | null>(null);
  const fetchHistory = useCallback(async () => {
    try {
      startLoading('history');
      const result = await api.getActionHistory(videoId);
      setHistory(result.items);
      setRelations(result.relations);
    } catch (e) {
      toast.error(e.message);
    } finally {
      stopLoading('history');
    }
  }, []);

  return (
    <>
      <BaseListPage
        loading={loadings.history}
        pageTitle={intl.formatMessage({id: 'ACTIVITY_HISTORY'})}
        fetchData={fetchHistory}>
        <div className='timeline timeline-2'>
          <div className='timeline-bar' />
          {history?.length === 0 && <VideoHistoryMarketingBlock />}
          {history?.map(historyRecord => {
            return (
              <div key={historyRecord.id} className='timeline-item'>
                <div className={cn('timeline-badge', `bg-${VIDEO_ACTION_COLOR_MAP[historyRecord.action]}`)} />
                <div className='timeline-content'>
                  <div className={'d-flex align-items-center justify-content-between'}>
                    <span className='mr-3 font-weight-bolder'>
                      {intl.formatMessage({id: `VIDEO_ACTION_${historyRecord.action}`})}{' '}
                      {historyRecord.user_id && (
                        <span className={'text-muted font-weight-normal'}>
                          (
                          {relations?.user?.[historyRecord.user_id].is_system
                            ? intl.formatMessage({id: 'SYSTEM'})
                            : relations?.user?.[historyRecord.user_id].email}
                          )
                        </span>
                      )}
                    </span>
                    <span className='text-muted text-right'>
                      {CustomFormatter.formatDateTimeWithFullMonth(historyRecord.created_at)}
                    </span>
                  </div>
                  {historyRecord.comment && (
                    <div className={'text-muted font-size-sm mt-1'}>{historyRecord.comment}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </BaseListPage>
    </>
  );
};
