import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {IYoutubeTokenInfoResponse} from './response-contracts/administrate-response-contracts';

export class AdministrateApi extends BaseEndpoint {
  public async getYoutubeAuthTokenInfo(): Promise<IYoutubeTokenInfoResponse> {
    const response = await this.get<IYoutubeTokenInfoResponse>(`${config.BASE_API_URL}/admin_google_oauth2_token`);
    return response.data;
  }
}
