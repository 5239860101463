import React from 'react';
import {ReportsUploadPage} from './reports-upload-page';
import {Switch} from 'react-router-dom';
import {ReportsDownloadPage} from './reports-download-page';
import {AdminRoutes} from '../../../../configs/routes';
import {ContentRoute} from '../../../../_metronic/layout/components/content/ContentRoute';

export const FinancialReportsPage: React.FC<any> = () => {
  const renderContent = () => {
    return (
      <>
        <div className='col-xxl-12'>
          <Switch>
            <ContentRoute path={AdminRoutes.getUploadFinancialReports()} component={ReportsUploadPage} />
            <ContentRoute path={AdminRoutes.getFinancialReportsRoute()} component={ReportsDownloadPage} />
          </Switch>
        </div>
      </>
    );
  };

  return <>{renderContent()}</>;
};
