import React from 'react';
import {AttachmentMetadata, FileUploader} from './file-uploader';
import './image-uploader.scss';
import {FormattedMessage} from 'react-intl';
import {Svg} from '../images/svg';

type Props = {
  previewUrl: string | null;
  required?: boolean;
  acceptFileExtension?: string | string[];
  label?: string;
  descriptionLabel?: string;
  showPreview?: boolean;
  onUploadImage: (image: File | null, metadata: AttachmentMetadata | null) => void;
  dropdownZoneClassName?: string;
  validateFile?: (file: File) => Promise<any>;
};

export const ImageUploader: React.FC<Props> = ({
  previewUrl,
  onUploadImage,
  acceptFileExtension,
  label,
  descriptionLabel,
  dropdownZoneClassName,
  showPreview,
  required,
  validateFile,
}) => {
  const handleImageUpload = async (file: File | null, metadata: AttachmentMetadata | null) => {
    onUploadImage(file, metadata);
  };

  const renderImagePreview = () => {
    if (!showPreview || !previewUrl) {
      return null;
    }
    if (previewUrl.split('.').pop() === 'svg') {
      return (
        <div className={'image-uploader-preview-container'}>
          <Svg src={previewUrl} />
        </div>
      );
    }

    return (
      <div className={'image-uploader-preview-container'}>
        <img className={'image-uploader-preview'} src={previewUrl} alt={'image'} />
        <div className={'image-uploader-preview-overlay'}>
          <span className={'cursor-pointer'} onClick={() => handleImageUpload(null, null)}>
            <FormattedMessage id={'DELETE'} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderImagePreview()}
      <FileUploader
        required={required}
        label={label}
        descriptionLabel={descriptionLabel}
        acceptFileExtension={acceptFileExtension}
        className={dropdownZoneClassName}
        onSelect={handleImageUpload}
        validateFile={validateFile}
      />
    </>
  );
};
