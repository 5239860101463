import React, {useEffect, useMemo, useState} from 'react';
import {PartialNullable, UpdateEntity} from '../../../../../types/types';
import {SupAgreement} from '../../../../api/DTOs/SupAgreement';
import {useIntl} from '../../../../hooks/use-intl';
import {AvailableContractChange, VideoInContract} from '../../../../api/contract-api/Contract';
import cn from 'classnames';
import {VideosBlock} from '../video-block';
import {DataSetValidationErrorsMap, getValidationErrorMessage, ValidationErrorsType} from '../../../../utils/utils';
import {BootstrapColors} from '../../../../../styles/bootstap-colors';
import {AlertCustom} from '../../../../modules/Auth/component/alert';

type Props = {
  supAgreement: PartialNullable<SupAgreement>;
  onUpdate?: UpdateEntity<PartialNullable<SupAgreement>>;
  viewModeEnable?: boolean;
  validationErrors?: ValidationErrorsType<SupAgreement>;
  dataSetValidationErrors?: DataSetValidationErrorsMap<SupAgreement, VideoInContract>;
};

export const SupAgreementVideos: React.FC<Props> = ({
  supAgreement,
  viewModeEnable,
  validationErrors,
  dataSetValidationErrors,
  onUpdate,
}) => {
  const intl = useIntl();
  const [currentEditChannelMode, setEditChannelMode] = useState<AvailableContractChange | null>(null);

  const tabs = useMemo(() => {
    return [
      {
        key: AvailableContractChange.IS_ADD_VIDEOS,
        hasErrors: dataSetValidationErrors?.add_videos != null || validationErrors?.add_videos != null,
        count: supAgreement?.add_videos?.length ?? 0,
      },
      {
        key: AvailableContractChange.IS_DELETE_VIDEOS,
        hasErrors: dataSetValidationErrors?.delete_videos != null || validationErrors?.delete_videos != null,
        count: supAgreement?.delete_videos?.length ?? 0,
      },
    ].filter(s => supAgreement[s.key as keyof SupAgreement]);
  }, [supAgreement, validationErrors]);

  useEffect(() => {
    if (tabs.length != 0) {
      if (currentEditChannelMode == null) {
        setEditChannelMode(tabs[0].key);
      }

      if (currentEditChannelMode != null && tabs.find(s => s.key === currentEditChannelMode) == null) {
        setEditChannelMode(tabs[0].key);
      }
    }
  }, [tabs, currentEditChannelMode, supAgreement]);

  return (
    <>
      {tabs.length > 0 && (
        <>
          <ul className='nav nav-tabs' style={{border: 'none'}}>
            {tabs.map(a => {
              return (
                <li key={a.key} onClick={() => setEditChannelMode(a.key)} className='nav-item cursor-pointer'>
                  <div
                    className={cn('nav-link', {
                      active: currentEditChannelMode === a.key,
                    })}>
                    <span
                      className={cn('nav-text font-weight-bolder', {
                        'text-danger': a.hasErrors,
                      })}>
                      {intl.formatMessage({id: 'SUP_AGREEMENTS_CHANGES_' + a.key})} ({a.count})
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            style={{
              boxShadow: 'none',
              borderStartStartRadius: '0px',
              // HACK, поскольку нормально borderTop не убирается
              borderLeft: '1px solid #EBEDF3',
              borderRight: '1px solid #EBEDF3',
              borderBottom: '1px solid #EBEDF3',
              borderTop: '1px solid #EBEDF3',
              padding: '2rem',
            }}>
            {supAgreement.is_add_videos && currentEditChannelMode === AvailableContractChange.IS_ADD_VIDEOS && (
              <>
                <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'VIDEOS'})}</h3>
                <AlertCustom
                  text={getValidationErrorMessage('add_videos', validationErrors)}
                  type={BootstrapColors.LIGHT_DANGER}
                  iconClassName={'svg-icon-danger'}
                />
                <VideosBlock
                  validationErrors={dataSetValidationErrors?.add_videos ?? null}
                  videos={supAgreement.add_videos ?? []}
                  privileges={{
                    canDelete: viewModeEnable == null || !viewModeEnable,
                    canEdit: viewModeEnable == null || !viewModeEnable,
                    canCreate: viewModeEnable == null || !viewModeEnable,
                  }}
                  onSave={items => onUpdate?.({add_videos: items})}
                />
              </>
            )}

            {supAgreement.is_delete_videos && currentEditChannelMode === AvailableContractChange.IS_DELETE_VIDEOS && (
              <>
                <h3 className={'font-weight-bolder text-muted'}>{intl.formatMessage({id: 'VIDEOS'})}</h3>
                <AlertCustom
                  text={getValidationErrorMessage('delete_videos', validationErrors)}
                  type={BootstrapColors.LIGHT_DANGER}
                  iconClassName={'svg-icon-danger'}
                />
                <VideosBlock
                  validationErrors={dataSetValidationErrors?.delete_videos ?? null}
                  videos={supAgreement.delete_videos ?? []}
                  privileges={{
                    canDelete: viewModeEnable == null || !viewModeEnable,
                    canEdit: viewModeEnable == null || !viewModeEnable,
                    canCreate: viewModeEnable == null || !viewModeEnable,
                  }}
                  onSave={items => onUpdate?.({delete_videos: items})}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
