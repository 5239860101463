import React, {useLayoutEffect} from 'react';
import {Card, CardBody, CardTitle, CardToolbar} from '../../components/card';
import {ContractorDataValidationStatus, ContractorType} from '../../api/contractor-api/Contractor';
import {useIntl} from '../../hooks/use-intl';
import {useAuth} from '../../hooks/use-auth';
import {LegalInfo} from '../admin/contractors/contractor-page/legal-info';
import {EntrepreneurInfo} from '../admin/contractors/contractor-page/entrepreneur-info';
import {PassportInfo} from '../admin/contractors/contractor-page/passport-info';
import {BankInfo} from '../admin/contractors/contractor-page/bank-info';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {CommonRoutes} from '../../../configs/routes';
import {ICONS} from '../../images/images';
import {AlertCustom} from '../../modules/Auth/component/alert';
import {BootstrapColors} from '../../../styles/bootstap-colors';
import {useHistory} from 'react-router-dom';

export const MyOrganizationPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {contractor} = useAuth();

  useLayoutEffect(() => {
    if (contractor?.data_validation_status === ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES) {
      history.push(CommonRoutes.getMyOrganizationEditRoute());
    }
  }, []);

  return (
    <Card>
      <CardTitle>{intl.formatMessage({id: 'MY_ORGANIZATION'})}</CardTitle>
      <CardToolbar>
        <Toolbar
          items={[
            {
              type: 'LINK',
              to: CommonRoutes.getMyOrganizationEditRoute(),
              icon: ICONS.EDIT,
              title: intl.formatMessage({id: 'EDIT'}),
              className: 'btn btn-secondary text-uppercase font-weight-bolder',
            },
          ]}
        />
      </CardToolbar>
      <CardBody>
        <div style={{maxWidth: '1150px'}}>
          <AlertCustom
            text={intl.formatMessage(
              {id: 'NOTE_UPDATE_CONTRACTOR_' + contractor?.data_validation_status},
              {
                redirect: str => (
                  <a
                    target={'_blank'}
                    href={CommonRoutes.getMyOrganizationEditRoute()}
                    className={'text-underline'}
                    rel='noreferrer'>
                    {str}
                  </a>
                ),
              },
            )}
            type={BootstrapColors.LIGHT_INFO}
            visible={contractor?.data_validation_status != ContractorDataValidationStatus.APPROVED}
          />
          <div className={'row'}>
            <div className={'col-12 col-lg-6'}>
              <h4 className={'text-muted font-weight-bolder d-flex align-items-center mb-5'}>
                {intl.formatMessage({id: 'INFO_ABOUT_ORGANIZATION'})}
              </h4>
              {contractor?.type === ContractorType.LEGAL && <LegalInfo contractor={contractor || {}} />}
              {contractor?.type === ContractorType.LEGAL && <EntrepreneurInfo contractor={contractor || {}} />}
              {contractor?.type === ContractorType.INDIVIDUAL && <PassportInfo contractor={contractor || {}} />}
            </div>

            <div className={'col-12 col-lg-6'}>
              <h4 className={'text-muted font-weight-bolder d-flex align-items-center mb-5'}>
                {intl.formatMessage({id: 'INFO_ABOUT_BANK'})}
              </h4>
              {contractor && <BankInfo contractor={contractor || {}} />}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
