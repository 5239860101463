import React from 'react';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import {AuthContent} from '../component/content';
import {AuthAside} from '../component/aside';

interface AuthPageProps {
  redirectUrl?: string | null;
}

export function AuthPage({redirectUrl}: AuthPageProps) {
  return (
    <div className='d-flex flex-column flex-root'>
      <div
        id='kt_login'
        className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'>
        <AuthAside />
        <AuthContent redirectUrl={redirectUrl} />
      </div>
    </div>
  );
}
